<template lang="pug">
v-btn.my-2(outlined, color="secondary", @click.stop="download()", small)
  v-icon(small, left) mdi-download
  span CSV
</template>

<script>
import moment from "moment-timezone";
import _ from "underscore";
import { mapGetters } from "vuex";
import CSV from "/libs/utils/CSV";

export default {
  props: ["punchcards", "showWage"],
  computed: {
    ...mapGetters("hr", ["persons"]),
  },
  methods: {
    download() {
      const filters = this.$options.filters;
      const csv = new CSV();
      csv.addRow([
        "",
        "Date",
        "Employee",
        "ID",
        "Role",
        "Clock In",
        "Clock Out",
        "Hours",
        "Rate",
        "Wage",
        "Cash Tip",
      ]);
      _.each(this.punchcards, (card) => {
        if (!card.status) return;
        const weekday = moment(card.clockIn).format("ddd");
        const date = moment(card.clockIn).format("M/D/YYYY");
        var clockIn = filters.datetime(card.clockIn);
        var clockOut = "-";
        var hours = "-";
        var pay = "-";
        if (card.clockOut > 0) {
          clockOut = filters.datetime(card.clockOut);
          hours = filters.number(card.hours, 2);
          pay = filters.currency(card.rate * card.hours);
        }
        csv.addRow([
          weekday,
          date,
          card.serverName,
          this.getEmployID(card.server),
          card.role,
          clockIn,
          clockOut,
          hours,
          filters.currency(card.rate),
          pay,
          filters.currency(card.cashTip),
        ]);
      });
      csv.save("timecards");
    },
    getEmployID(serverId) {
      const found = this.persons?.find((o) => o._id == serverId);
      return found?.employeeID || "";
    },
  },
};
</script>
