<template lang="pug">
v-dialog(v-model="dialog", width="700", scrollable)
  template(v-slot:activator="{ on }")
    IconBtn(@click="open()", title="Upload", icon="mdi-upload")
  v-card
    v-card-title Upload Loyalty Member
    v-card-text
      div Please ensure the CSV file has the following columns and the 1st row is the header:
      ul
        li First Name
        li Last Name
        li Phone
        li Email (optional)
        li DOB (Date of Birth, optional)
        li Points
        li Number of Visits
        li Last Visit (optional)
        li First Visit (optional)
      v-btn(@click="downloadSample()", text, color="secondary", small) Download Sample
      v-row
        v-col(cols="4")
          v-select(v-model="delimiter", :items="delimiters", label="Delimiter")
      input(type="file", accept=".csv", @change="upload($event)")
      .caption Please upload a CSV file.
    simple-table(
      v-if="valid",
      style="max-height: 300px !important; overflow-y: auto"
    )
      thead
        tr
          th
          th(v-for="(item, index) in header", :key="index") {{ item }}
          th
      tbody
        tr(v-for="(item, index) in items", :key="index")
          td {{ index + 1 }}
          td(v-for="(value, index) in item", :key="index") {{ value }}
          td
            .link-box
              v-btn(@click="remove(index)", icon, small, color="error")
                v-icon(small) mdi-delete
    v-card-actions(v-if="valid")
      v-btn(@click="submit", color="secondary", :loading="loading") Upload
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import CSV from "/libs/utils/CSV";

export default {
  data() {
    return {
      dialog: false,
      delimiter: ",",
      delimiters: [",", ";", "|"],
      header: [
        "First Name",
        "Last Name",
        "Phone",
        "Email",
        "DOB",
        "Points",
        "Number of Visits",
        "Last Visit",
        "First Visit",
      ],
      items: [],
      indices: {
        first_name: -1,
        last_name: -1,
        phone: -1,
        email: -1,
        dob: -1,
        points: -1,
        visits: -1,
        last_visit: -1,
        first_visit: -1,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz", "pos"]),
    ...mapGetters("hr", ["persons"]),
    valid() {
      return this.items.length > 0;
    },
  },
  methods: {
    ...mapActions(["setPOS"]),
    open() {
      this.dialog = true;
    },
    downloadSample() {
      // create sample data
      const csv = new CSV();
      const items = [
        [
          "John",
          "Doe",
          "5055555555",
          "john@doe.com",
          "01/01/1990",
          "100",
          "10",
          "01/01/2021",
          "01/01/2020",
        ],
      ];
      csv.addRow(this.header);
      items.forEach((row) => csv.addRow(row));
      csv.save("sample");
    },
    upload(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const lines = text.split("\n");
        if (!lines?.length) return;
        const header = lines[0].split(this.delimiter);
        const items = lines.slice(1).map((line) => {
          const values = line.split(this.delimiter);
          return header.reduce((obj, key, index) => {
            obj[key] = values[index];
            return obj;
          }, {});
        });
        // this.header = header;
        // filter items with no values
        this.items = items.filter((item) =>
          Object.values(item).some((value) => value)
        );
        // find indices
        this.indices.first_name = this.getIndex("first name");
        this.indices.last_name = this.getIndex("last name");
        this.indices.phone = this.getIndex("phone");
        this.indices.email = this.getIndex("email");
        this.indices.dob = this.getIndex("dob");
        this.indices.points = this.getIndex("points");
        this.indices.visits = this.getIndex("number of visits");
        this.indices.last_visit = this.getIndex("last visit");
        this.indices.first_visit = this.getIndex("first visit");
      };
      reader.readAsText(file);
    },
    remove(index) {
      this.items.splice(index, 1);
    },
    async submit() {
      if (!this.biz?._id) return;
      let members = [];
      const now = Date.now();
      for (const item of this.items) {
        const first_name = this.getValue(item, this.indices.first_name);
        const last_name = this.getValue(item, this.indices.last_name);
        const phone = this.getValue(item, this.indices.phone);
        const email = this.getValue(item, this.indices.email);
        const dob = this.getValue(item, this.indices.dob);
        const points = this.getValue(item, this.indices.points, "0");
        const visits = this.getValue(item, this.indices.visits, "0");
        const last_visit = this.getValue(item, this.indices.last_visit);
        const first_visit = this.getValue(item, this.indices.first_visit);

        let bd;
        if (dob) {
          const m = moment(dob, "MM/DD/YYYY");
          if (m.isValid()) {
            bd = { mm: m.month() + 1, dd: m.date() + 1 };
          }
        }
        const balance = Math.round(parseFloat(points));
        const count = Math.round(parseFloat(visits));
        const last = moment(last_visit, "MM/DD/YYYY")?.valueOf() || now;
        const created = moment(first_visit, "MM/DD/YYYY")?.valueOf() || now;

        const person = {
          name: {
            first: first_name,
            last: last_name,
          },
          phone: phone?.replace(/\D+/g, ""),
          email: email?.toLowerCase().trim(),
          bd,
          balance: balance,
          amount: balance,
          count,
          last,
          created,
          biz: this.biz._id,
        };
        if (!person.phone) continue;
        members.push(person);
      }
      this.loading = true;
      try {
        // split members into chunks
        const chunkSize = 50;
        let count = 0;
        let total = Math.ceil(members.length / chunkSize);
        while (members.length) {
          count++;
          this.$toast.info(`Processing chunk ${count} of ${total}`, {
            timeout: 20000,
          });
          const chunk = members.splice(0, chunkSize);
          await this.processItems({ items: chunk });
        }
        this.$emit("refresh");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async processItems({ items }) {
      const result = await this.$api.member.custom("upload", {
        items,
        bizId: this.biz._id,
      });
      this.$toast.success(result.message);
    },
    getIndex(value) {
      return this.header.findIndex((o) => o.toLowerCase().trim() === value);
    },
    getValue(item, index, defaultValue = "") {
      if (index < 0 || !item) return defaultValue;
      const values = Object.values(item);
      if (values.length > index) return values[index].trim();
      return defaultValue;
    },
  },
};
</script>