<template lang="pug">
div
  .d-flex.flex-row.justify-end.mb-3
    IconBtn(@click="create()", title="Add", icon="mdi-plus")
  v-row(wrap)
    v-col(
      cols="12",
      sm="6",
      md="4",
      v-for="(item, index) in combos",
      :key="index"
    )
      ComboCard(:combo="item")
  Form
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Form from "./Form";
import ComboCard from "./ComboCard";

export default {
  components: { Form, ComboCard },
  computed: {
    ...mapGetters(["biz", "combos"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setCombos"]),
    async load() {
      if (!this.biz || !this.biz._id) return;
      const params = { criteria: { biz: this.biz._id } };
      const result = await this.$api.menu.combo.list(params);
      this.setCombos(result);
    },
    create() {
      if (!this.biz) return;
      let data = {
        biz: this.biz._id,
        name: "",
        items: [{ dishes: [], type: 0, amount: 0, pct: 0, reduce: 0 }],
      };
      EventBus.$emit("edit-combo", data);
    },
  },
};
</script>
