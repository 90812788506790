<template lang="pug">
IconBtn(@click="download()", title="PDF", icon="mdi-printer")
</template>

<script>
import jsPDF from "jspdf";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  name: "DailyReportDownload",
  props: ["report"],
  computed: { ...mapGetters(["biz"]) },
  methods: {
    download() {
      if (!this.report || !this.biz) return;
      const currency = this.$options.filters.currency;
      const bizName = this.biz.name;
      let doc = new jsPDF({ format: "letter" });
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      let x = 19;
      let y = 25;
      addTitle(this.report.date);
      doc.setFontSize(10);
      setCounts(this.report.counts);
      setSales(this.report.sales);
      setPayments(this.report.payments);
      doc.save("daily_sales_" + this.report.date + ".pdf");

      function setCounts(data) {
        const items = [
          { name: "Closed", amount: data.closed, currency: false },
          { name: "Open", amount: data.open, currency: false },
          { name: "Canceled", amount: data.canceled, currency: false },
          {
            name: "Total Orders",
            amount: data.total,
            fontWeight: "bold",
            required: true,
            currency: false,
          },
        ];
        addItems(items);
      }

      function setSales(data) {
        const items = [
          { name: "Net Sales", amount: data.net },
          { name: "Tax", amount: data.tax },
          { name: "Payment Deduction", amount: -data.deduction },
          { name: "Tip", amount: data.tip },
          { name: "Cash Discount Adjustment", amount: data.adjustment },
          { name: "Fee (in-store)", amount: data.fee },
          { name: "Third Party", amount: data.third_party },
          { name: "Gift Sold", amount: data.gift },
          { name: "Selflane Charges", amount: data.selflane },
          {
            name: "Total Receivables",
            amount: data.total,
            fontWeight: "bold",
            required: true,
          },
        ];
        addItems(items);
      }
      function setPayments(data) {
        const items = [
          { name: "Cash", amount: data.cash },
          { name: "Credit Card", amount: data.cc },
          { name: "Stripe", amount: data.stripe },
          { name: "Check", amount: data.check },
          { name: "Gift Redeemed", amount: data.gc },
          { name: "External Card", amount: data.excc },
          { name: "Selflane Online", amount: data.online },
          { name: "Reward", amount: data.reward },
          {
            name: "Total Received",
            amount: data.total,
            fontWeight: "bold",
            required: true,
          },
        ];
        addItems(items);
      }

      function addItems(items) {
        addLine();
        items.forEach((item) => {
          if (item.amount || item.total || item.required) {
            const isCurrency = item.currency != false;
            let amount = item.amount || item.total;
            amount = isCurrency ? currency(amount) : String(amount);
            addTableLine(item.name, amount || "", item.fontWeight || "normal");
          }
        });
      }
      function addTableLine(left, right, fontWeight = "normal") {
        doc.setFont("helvetica", fontWeight);
        doc.text(left, x, y);
        nextCell(150);
        doc.text(right, x, y);
        nextLine();
      }

      function addTitle(date) {
        doc.setFontSize(12);
        doc.setTextColor(255, 109, 50);
        doc.text("Sales Report - " + bizName, x, y);
        doc.setTextColor(0, 0, 0);
        nextLine(10);
        doc.text("Date: " + date, x, y);
        nextLine();
        doc.text("Created: " + moment().format("M/D/YYYY hh:mm A"), x, y);
        nextLine();
      }
      function addLine() {
        doc.line(19, y, pageWidth - 19, y);
        nextLine();
      }
      function nextLine(height) {
        if (height) y += height;
        else y += 5;
        x = 19;
        if (y + 10 > pageHeight) {
          doc.addPage();
          y = 25;
        }
      }
      function nextCell(width) {
        if (width) x += width;
        else x += 40;
      }
    },
  },
};
</script>
