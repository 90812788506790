<template lang="pug">
.line-box
  .caption.sl-secondary-text.nowrap {{ title }}
  .subtitle-2.detail-box(:class="color") {{ detail }}
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    detail: { type: String, default: null },
    color: { type: String, default: "" },
  },
};
</script>

<style lang="sass" scoped>
.line-box
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: top
  gap: 12px
.detail-box
  max-width: 600px
  text-align: right
</style>
