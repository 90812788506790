<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn(@click="open()", color="error", x-small, depressed, v-on="on") Deactivate
  v-card(v-if="user")
    v-card-title Deactivate a User
    v-form(@submit.prevent="submit")
      v-card-text
        p Are you sure you want to deactivate <b>{{ user.first_name }} {{ user.last_name }}</b>?
        v-select(
          v-model="inactive_reason",
          :items="inactiveReasons",
          label="Inactive Reason",
          required
        )
        v-text-field(v-model="inactive_comments", label="Comments (Optional)")
      v-card-actions
        v-btn(
          block,
          color="error",
          type="submit",
          :loading="loading",
          :disabled="!inactive_reason"
        ) Deactivate
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    biz: { type: Object, required: true },
    user: { type: Object, required: true },
  },
  data() {
    return {
      dialog: false,
      inactive_reason: "",
      inactive_comments: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters("seven", ["inactiveReasons"]),
  },
  methods: {
    open() {
      if (!this.inactiveReasons?.length) this.loadReasons();
    },
    async submit() {
      if (!this.biz) return this.$toast.error("No business selected");
      if (!this.inactive_reason) {
        return this.$toast.error("Inactive reason is required");
      }
      this.loading = true;
      try {
        const params = {
          bizId: this.biz._id,
          userId: this.user.id,
          inactive_reason: this.inactive_reason,
          inactive_comments: this.inactive_comments,
        };
        const result = await this.$api.seven.user.custom("deactivate", params);
        this.$toast.success("User deactivated");
        this.dialog = false;
        this.$store.dispatch("seven/updateUser", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async loadReasons() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      try {
        const result = await this.$api.seven.company.custom(
          "listInactiveReasons",
          params
        );
        this.$store.dispatch("seven/setInactiveReasons", result);
      } catch (e) {
        this.$store.dispatch("seven/setInactiveReasons", []);
        // fail silently
      }
    },
  },
};
</script>