<template lang="pug">
v-container.orange.lighten-1(fluid)
  v-container.mt-5(style="max-width: 800px")
    .brandtitle Selflane Point of Sale
    .brandcontent We're a restaurant solutions company built for you. We help with everything from point-of-sale integration to online ordering services. No gimmicks, just good business.
    .ipad.mt-3
      v-carousel(hide-controls)
        v-carousel-item(
          v-for="(item, i) in posItems",
          :key="i",
          :src="item",
          contain
        )
    .iphone
      v-carousel(hide-controls)
        v-carousel-item(
          v-for="(item, i) in iosItems",
          :key="i",
          :src="item",
          contain
        )
</template>

<script>
export default {
  name: "Brand",
  data() {
    return {
      iosItems: [
        "https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/ios/ios1.png",
        "https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/ios/ios2.png",
        "https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/ios/ios3.png",
        "https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/ios/ios4.png",
        "https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/ios/ios5.png",
      ],
      posItems: [
        "https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/pos/pos1.png",
        "https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/pos/pos2.png",
        "https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/pos/pos3.png",
        "https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/pos/pos4.png",
        "https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/pos/pos5.png",
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.brandtitle
  text-align: center
  font-size: 60px
  font-family: Helvetica, "Trebuchet MS", Verdana, sans-serif
  font-weight: 500
  color: #ffffff

.brandcontent
  text-align: center
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif
  font-size: 20px
  font-weight: 500
  color: grey

.ipad
  width: 755px
  max-width: 100%

.iphone
  border-width: 2px
  border-style: solid
  border-color: #757575
  border-radius: 24px
  background-color: #eeeeee
  height: 510px
  width: 242px
  padding: 3px 3px 3px 3px
  margin-left: auto
  margin-right: auto
  margin-top: 36px
.v-carousel
  border-radius: 24px
</style>
