<template lang="pug">
tr(@click="edit()", role="button", :class="color")
  td
  td {{ item.role }}
  td {{ item.clockIn | datetime("M/D h:mm A") }}
  td {{ item.clockOut | datetime("M/D h:mm A") }}
  td {{ item.hours | hours(isDecimal) }}
  td(v-if="showWage") {{ item.rate | currency }}
  td(v-if="showWage")
    span(v-if="item.status") {{ (item.hours * item.rate) | currency }}
    span(v-else) Canceled
  td(v-if="showWage") {{ item.cashTip | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["item", "isDecimal", "showWage"],
  computed: {
    color() {
      if (!this.item.status) return "red--text";
      else if (!this.item.clockOut) return "green--text";
      else if (this.item.hours > 10) return "orange--text";
      else return "";
    },
  },
  methods: {
    edit() {
      EventBus.$emit("edit-punchcard", this.item);
    },
  },
};
</script>
