<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Pay Invoice
      v-spacer
      .subtitle-2(v-if="invoice") Total: {{ invoice.payment.total | currency }}
    v-card-text
      v-btn.text-capitalize(
        @click="payByCreditCard()",
        :loading="loading",
        color="secondary",
        block
      ) Pay by Credit Card
</template>

<script>
import { mapActions } from "vuex";
import Balance from "/libs/components/Stripe/Balance";

export default {
  components: { Balance },
  data() {
    return {
      loading: false,
      dialog: false,
      invoice: null,
    };
  },
  methods: {
    ...mapActions(["updatePurchase"]),
    open(invoice) {
      if (!invoice) return;
      this.invoice = invoice;
      this.dialog = true;
    },
    async payByCreditCard() {
      const id = this.invoice?._id;
      if (!id) return;
      this.loading = true;
      try {
        const result = await this.$api.bizOrder.custom("pay", { id });
        this.updatePurchase(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
