const state = {
  influencers: []
};

const getters = {
  influencers: state => state.influencers
};

const actions = {
  setInfluencers: ({ commit }, data) => { commit("setInfluencers", data); },
};

const mutations = {
  setInfluencers(state, data) {
    state.influencers = data;
  },
};

export default { state, getters, actions, mutations };