<template lang="pug">
.s-vstack
  h3 Access Rules
  v-card(flat, :loading="loading")
    v-toolbar(flat, dense)
      .subtitle-2 Functions need Manager Approval
    v-divider
    v-list.py-0(dense)
      Cell(
        v-for="item in items",
        :key="item.name",
        :name="item.name",
        :title="item.title",
        @toggle="toggle"
      )
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Cell from "./Cell";

export default {
  components: { Cell },
  data() {
    return {
      loading: false,
      items: [
        { title: "Edit POS App Settings", name: "settings" },
        { title: "Apply Vouchers", name: "voucher" },
        { title: "Redeem Member Points", name: "memberRedeem" },
        { title: "Customize Items/Modifiers", name: "customItem" },
        { title: "Transfer Order", name: "transfer" },
      ],
    };
  },
  computed: { ...mapGetters(["pos"]) },
  methods: {
    ...mapActions(["setPOS"]),
    async toggle(name, value) {
      if (!name) return;
      this.loading = true;
      try {
        const key = `manage.${name}`;
        const action = { $set: { [key]: value } };
        const result = await this.$api.pos.put(this.pos?._id, { action });
        this.setPOS(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
