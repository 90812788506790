<template lang="pug">
NavGroups(:items="items", :access="access")
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus";
import Items from "/libs/json/EnterpriseNav.json";

export default {
  data() {
    return {
      access: {},
      items: JSON.parse(JSON.stringify(Items)),
    };
  },
  computed: {
    ...mapGetters("entp", ["enterprise"]),
  },
  mounted() {
    this.load();
    EventBus.$on("enterprise:changed", this.load);
  },
  destroyed() {
    EventBus.$off("enterprise:changed", this.load);
  },
  methods: {
    async load() {
      if (!this.enterprise || !this.$auth.user()) {
        this.access = {};
        return;
      }
      try {
        const params = {
          criteria: {
            enterprise: this.enterprise._id,
            user: this.$auth.user()._id,
          },
        };
        const result = await this.$api.entp.access.list(params);
        if (result?.length) {
          this.access = result[0];
        } else {
          this.access = {};
        }
      } catch (e) {
        this.access = {};
      }
    },
  },
};
</script>
