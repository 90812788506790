<template lang="pug">
Page(title="Transactions", :items="items", :doc="doc")
</template>

<script>
export default {
  data() {
    return {
      doc: "https://docs.selflane.com/biz/owner/transactions/",
      items: [
        { title: "Transactions", url: "/txn/store" },
        { title: "Batches", url: "/txn/batch" },
      ],
    };
  },
};
</script>
