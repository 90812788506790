<template lang="pug">
simple-table
  slot(name="header")
  tbody
    AmountTableRow(
      v-for="(item, index) in items",
      :key="index + '-row'",
      :name="item.name",
      :amount="item.amount || item.total",
      :bold="item.bold",
      :mandatory="item.required",
      :button="item.click",
      :currency="item.filter != 'number'",
      @click="handleClick"
    )
  slot
</template>

<script>
export default {
  props: ["items"],
  methods: {
    handleClick(item) {
      if (item.click) this.$emit("click");
    },
  },
};
</script>
