<template lang="pug">
v-btn-toggle(v-model="content", color="secondary", rounded, dense)
  v-btn(
    v-for="(item, index) in items",
    :key="index",
    :value="item.value",
    small
  )
    span.text-notransform {{ item.text }}
</template>

<script>
export default {
  name: "HhmmPicker",
  props: ["value"],
  data() {
    return {
      content: this.value,
      items: [
        { value: false, text: "HH:MM" },
        { value: true, text: "Decimal" },
      ],
    };
  },
  watch: {
    value() {
      this.content = this.value;
    },
    content() {
      this.$emit("input", this.content);
    },
  },
};
</script>
