import Balance from './Balance';
import Dashboard from './Dashboard';
import devices from './Devices/route';
import Expense from './Expense';
import gift from './Gift/route';
import sales from './Sales/route';
import salesOnline from './SalesOnline/route';
import reservations from './reservations/route';

export default [
  { path: '/dashboard', component: Dashboard, meta: { auth: true } },
  ...devices,
  ...sales,
  ...salesOnline,
  ...gift,
  ...reservations,
  { path: '/expense', component: Expense, meta: { auth: true } },
  { path: '/balance', component: Balance, meta: { auth: true } }
]