<template lang="pug">
.s-vstack
  Settings
  v-row(wrap, justify="center")
    v-col(cols="12", md="10")
      .title Customer Receipt Graphics (optional)
    v-col(cols="12", sm="6", md="5")
      BizName
    v-col(cols="12", sm="6", md="5")
      BottomPic
</template>

<script>
import BizName from "./BizName";
import BottomPic from "./BottomPic";
import Settings from "./Settings";

export default {
  components: { Settings, BizName, BottomPic },
};
</script>
