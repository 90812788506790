<!--
  Navigation Groups filtered depending on the user's role
-->
<template lang="pug">
div
  div(v-if="!block")
    v-list-group(
      v-for="(item, index) in filtered",
      v-model="item.active",
      :key="index + 'list'",
      :prepend-icon="item.action",
      no-action
    )
      template(v-slot:activator)
        v-list-item-title {{ item.title }}
      Cell(v-for="subItem in item.items", :item="subItem", :key="subItem.name")
  GroupTiles(:groups="filtered", v-else)
</template>

<script>
import _ from "underscore";
import Cell from "./Cell";

export default {
  components: { Cell },
  props: {
    items: { type: Array, default: () => [] },
    access: { type: Object, required: true },
    block: { type: Boolean, default: false },
  },
  computed: {
    filtered() {
      if (!this.access) return [];
      const privilege = this.access.privilege;
      let clone = JSON.parse(JSON.stringify(this.items));
      return _.chain(clone)
        .each((group) => {
          group.items = _.filter(group.items, (o) => {
            if (privilege < 3) return o.priv >= privilege;
            else if (privilege == 4) {
              return this.access.items.some((item) => item.name == o.name);
            }
          });
        })
        .filter((group) => {
          if (privilege < 3) return group.priv >= privilege;
          return group.items?.length;
        })
        .value();
    },
  },
};
</script>
