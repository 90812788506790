<template lang="pug">
div(v-if="hasUser")
  .pa-3
    BizLogo
  InvoiceGuard
    .s-vstack
      StripeAccountGuard
</template>

<script>
import InvoiceGuard from "./InvoiceGuard";
import StripeAccountGuard from "./StripeAccountGuard";

export default {
  name: "AccessGuard",
  components: { InvoiceGuard, StripeAccountGuard },
  computed: {
    hasUser() {
      return this.$auth.check();
    },
  },
  methods: {},
};
</script>