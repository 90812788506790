<template lang="pug">
.my-box
  Language
  Social
</template>

<script>
import Language from "./Language";
import Social from "./Social";
export default {
  components: { Language, Social },
};
</script>

<style lang="sass" scoped>
.my-box
  display: flex
  justify-content: space-between
  align-items: baseline
  gap: 24px
</style>
