<template lang="pug">
tr(@click="submit", role="button")
  th Gift Card
  td.text-right
    v-progress-circular(v-if="loading", indeterminate, color="secondary")
    v-icon(v-else-if="status", color="green") mdi-check
    v-icon(v-else, color="grey lighten-2") mdi-check
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz"]),
    status() {
      return this.biz?.orderType?.gc?.status;
    },
  },
  methods: {
    async submit() {
      if (!this.biz) return;
      const status = !(this.status == true);
      this.loading = true;
      try {
        const action = { $set: { "orderType.gc": { status: status } } };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.$toast.success("Saved");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
