<template lang="pug">
div
  .subtitle-2.success--text(v-if="resent") Resent success. If you can't receive a code properly, please consider use Google Login to sign in directly. No code or password is needed.
  v-btn(
    v-else,
    rounded,
    outlined,
    x-small,
    color="secondary",
    @click.stop="handleResend",
    :loading="loading"
  ) Resend Code
</template>

<script>
export default {
  props: {
    email: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      resent: false,
    };
  },
  methods: {
    handleResend() {
      if (!this.email) return;
      this.loading = true;
      const params = { email: this.email };
      try {
        this.axios.post("/users/resendVerification", params);
        this.resent = true;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>