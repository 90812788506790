<template lang="pug">
v-data-table(
  :items="sorted",
  :headers="headers",
  mobile-breakpoint=0,
  @click:row="showInvoice",
  role="button"
)
  template(v-slot:item.created="{ item }")
    span {{ item.created | date }}
  template(v-slot:item.payment.total="{ item }")
    span {{ item.payment.total | currency }}
  template(v-slot:item.action="{ item }")
    .d-flex.flex-row.justify-end
      Pay.mr-3(:item="item")
      Download(:item="item")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Download from "./Download";
import Pay from "./Pay";

export default {
  name: "InvoiceList",
  components: { Pay, Download },
  props: {
    items: { type: Array, required: true },
  },
  data() {
    return {
      headers: [
        { text: "Date", value: "created" },
        { text: "Amount", value: "payment.total", align: "end" },
        { text: "", value: "action", align: "end" },
      ],
    };
  },
  computed: {
    sorted() {
      return this.items?.toSorted((a, b) => b.created - a.created);
    },
  },
  methods: {
    showInvoice(item) {
      EventBus.$emit("show-orginvoice", item._id);
    },
    showPay(item) {
      EventBus.$emit("show-pay-orginvoice", item);
    },
  },
};
</script>
