<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download", v-if="valid")
</template>

<script>
import CSV from "/libs/utils/CSV";
import { mapGetters } from "vuex";

export default {
  props: ["items"],
  computed: {
    ...mapGetters(["biz"]),
    valid() {
      return this.items && this.items.length;
    },
  },
  methods: {
    download() {
      if (!this.items || !this.items.length) return;
      const csv = new CSV();
      csv.addRow(["ID", "Time", "Server", "Value", "Reason"]);
      const datetime = this.$options.filters.datetime;
      const currency = this.$options.filters.currency;
      this.items.forEach((o) => {
        csv.addRow([
          o.orderNumber,
          datetime(o.needed),
          o.server,
          currency(o.amount),
          o.reason,
        ]);
      });
      csv.addRow([this.biz.name]);
      csv.save("void_orders");
    },
  },
};
</script>
