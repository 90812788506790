<template lang="pug">
div
  div(v-if="stripeBanks && stripeBanks.length")
    v-list.py-0(dense, two-line)
      Cell(v-for="(item, index) in stripeBanks", :key="index", :item="item")
    v-card-text
      .caption Need to deposit to a different bank account? Please add a new account and delete the unwanted one.
  .pa-3.text-center.subtitle-2(v-else) Please Add a Bank Account
</template>

<script>
import { mapGetters } from "vuex";
import Cell from "./Cell";

export default {
  components: { Cell },
  computed: { ...mapGetters(["stripeBanks"]) },
};
</script>
