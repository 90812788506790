<template lang="pug">
v-card.my-3(outlined)
  v-toolbar(flat, dense)
    .subtitle-2 Ingredients
    v-spacer
    IconBtn(@click="download()", title="CSV", icon="mdi-download")
  v-card-text.py-1
    span Stats on number of ingredients being used in selected period: {{ period }}
  v-data-table(
    :headers="headers",
    :items="ingredients",
    sort-by="name",
    mobile-breakpoint="0",
    dense
  )
    template(v-slot:item.price="{ item }")
      span {{ item.price | currency }}
</template>

<script>
import CSV from "/libs/utils/CSV";
import { mapGetters } from "vuex";

export default {
  props: {
    ingredients: { type: Array, default: null },
    period: { type: String, default: "" }, // YYYY-MM-DD to YYYY-MM-DD
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Unit of Measurement", value: "uom" },
        { text: "Price/Unit", value: "price" },
        { text: "Quantity", value: "quantity", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    download() {
      if (!this.ingredients) return;
      const csv = new CSV();
      csv.addRow([this.biz.name]);
      csv.addRow([
        "Ingredient",
        "Unit of Measurement",
        "Price/Unit",
        "Quantity",
      ]);
      this.ingredients.forEach((item) => {
        csv.addRow([item.name, item.uom, item.price, item.quantity]);
      });
      csv.save(`ingredients-stats-${this.period}`);
    },
  },
};
</script>
