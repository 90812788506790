<template lang="pug">
v-dialog(v-model="dialog", width="600", scrollable)
  v-form(@submit.prevent="submit")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Edit Prefixes
        v-spacer
        v-btn.text-notransform(
          outlined,
          small,
          @click.stop="more()",
          color="secondary"
        )
          v-icon(small) mdi-plus
          span Item
      simple-table
        thead
          tr
            th Name
            th Kitchen Name
            th Scale Factor
            th
        tbody
          tr(
            v-for="(item, index) in prefixes",
            :key="index",
            draggable="true",
            @dragstart="drag(index, $event)",
            @dragend="dragend($event)",
            @drop="drop(index, $event)",
            @dragover.prevent=""
          )
            td
              v-text-field(
                v-model.trim="item.name",
                hide-details,
                single-line,
                dense
              )
            td
              v-text-field(
                v-model.trim="item.kName",
                hide-details,
                single-line,
                dense
              )
            td
              v-text-field(
                type="number",
                v-model="item.factor",
                hide-details,
                single-line,
                prefix="X",
                dense
              )
            td.text-right
              .d-flex.flex-row.align-center
                v-icon.mr-3(color="error", small, @click.stop="less(index)") mdi-delete
                v-icon(small) mdi-menu
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "MatrixModifierPrefixesForm",
  data() {
    return {
      names: "",
      prefixes: [],
      dialog: false,
      loading: false,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    EventBus.$on("edit-matrix-modifier-prefixes", this.edit);
  },
  destroyed() {
    EventBus.$off("edit-matrix-modifier-prefixes", this.edit);
  },
  methods: {
    ...mapActions(["setMatrixModifier"]),
    more() {
      this.prefixes.push({ name: "", kName: "", factor: 0 });
    },
    less(index) {
      if (index < this.prefixes.length && index >= 0) {
        this.prefixes.splice(index, 1);
      }
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: function (index) {
      if (!this.prefixes) return;
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.prefixes.length) return;
      if (this.draggingIndex < 0 || this.draggingIndex >= this.prefixes.length)
        return;
      let prefixes = JSON.parse(JSON.stringify(this.prefixes));
      let tmp = JSON.parse(JSON.stringify(prefixes[this.draggingIndex]));
      prefixes.splice(this.draggingIndex, 1);
      prefixes.splice(index, 0, tmp);
      this.prefixes = prefixes;
    },
    async submit() {
      if (!this.biz) return;
      this.prefixes = this.prefixes.filter((o) => o.name);
      const found = this.prefixes.find((o) => o.factor < 0);
      if (found) {
        this.$toast.error(
          found.name + " scale factor must be greater than or equal to 0"
        );
        return;
      }
      this.loading = true;
      const params = {
        criteria: { _id: this.biz._id },
        action: { $set: { prefixes: this.prefixes } },
      };
      try {
        const data = await this.$api.matrixModifier.update(params);
        this.setMatrixModifier(data);
        this.dialog = false;
      } catch (e) {
        this.errors = [e.response?.data || e.message];
      }
      this.loading = false;
    },
    edit(data) {
      this.prefixes = JSON.parse(JSON.stringify(data));
      if (!this.prefixes || !this.prefixes.length) {
        this.prefixes = [{ name: "", kName: "", factor: 0 }];
      }
      this.errors = [];
      this.dialog = true;
    },
  },
};
</script>
