<template lang="pug">
v-dialog(v-model="dialog", width="900")
  template(v-slot:activator="{ on }")
    v-btn(icon, v-on="on")
      v-icon(small) mdi-bell
  v-card
    div(v-for="(item, index) in items", :key="index")
      MDArticle(
        :title="item.title",
        :time="item.start",
        :content="item.content"
      )
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import MDArticle from "/libs/components/MDArticle";

export default {
  components: { MDArticle },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["announcements"]),
    items() {
      return _.sortBy(this.announcements, "start").reverse();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setAnnouncements"]),
    async load() {
      const now = Date.now();
      const params = {
        criteria: { start: { $lt: now }, readers: "biz_owner" },
        limit: 20,
      };
      const res = await this.axios.post("/announcement/list", params);
      this.setAnnouncements(res.data);
    },
  },
};
</script>