<template lang="pug">
div
  v-btn(to="/member/manage", flat, color="primary")
    v-icon.fa.fa-arrow-left(left, small)
    span Back
  v-layout(justify-center)
    Detail
</template>

<script>
import Detail from "./Detail";
import { mapActions } from "vuex";
export default {
  components: { Detail },
  props: ["memberId"],
  mounted() {
    this.load();
  },
  destroyed() {
    this.setMember(null);
  },
  methods: {
    ...mapActions(["setMember"]),
    async load() {
      if (!this.memberId) return;
      try {
        const params = { criteria: { _id: this.memberId } };
        const member = await this.$api.member.retrieve(params);
        this.setMember(member);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>