<template lang="pug">
youtube(:video-id="videoId" :player-width="width" :player-height="width / 16 * 9" :player-vars="playerVars" :mute='mute')
</template>

<script>
export default {
  data() {
    return {
      videoId: "r1t0eYRti5E",
      mute: false,
      playerVars: { autoplay: 0, rel: 0 }
    };
  },
  computed: {
    width() {
      return window.innerWidth;
    }
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    }
  }
};
</script>
