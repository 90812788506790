
import BookedTables from './booked_tables';
import Dashboard from './dashboard';
import Main from './index';

export default [
  {
    path: '/reservations', component: Main, meta: { auth: true },
    children: [
      { path: '', redirect: 'dashboard' },
      { path: 'dashboard', component: Dashboard },
      { path: 'booked', component: BookedTables }
    ]
  },
]
