<template lang="pug">
v-dialog(v-model="dialog", width="640")
  template(v-slot:activator="{ on }")
    v-btn.text-capitalize(outlined, v-on="on") Watch Tutorial
  youtube(
    :video-id="videoId",
    :player-vars="playerVars",
    :mute="mute",
    @ready="ready"
  )
</template>

<script>
export default {
  data() {
    return {
      player: null,
      dialog: false,
      videoId: "2nKZiLPinfc",
      mute: false,
      playerVars: { autoplay: 0, rel: 0 },
    };
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.pause();
      }
    },
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
  },
};
</script>
