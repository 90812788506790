<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";
import { mapGetters } from "vuex";

export default {
  name: "MenuDownload",
  computed: { ...mapGetters(["biz"]) },
  methods: {
    download() {
      if (!this.biz) return;
      const currency = this.$options.filters.currency;
      const csv = new CSV();
      csv.addRow(["Name", "Price", "Menu", "Course", "Description"]);
      for (const dish of this.biz.dishes) {
        const name = dish.name;
        const price = currency(dish.price);
        const menu = this.getMenuNames(dish.menus);
        const course = this.getCourseName(dish.course);
        const description = dish.description;
        csv.addRow([name, price, menu, course, description]);
      }
      csv.save("menu_dish_items");
    },
    getMenuNames(ids) {
      if (!ids) return "";
      return this.biz.menus
        .filter((o) => ids.includes(o._id))
        .map((o) => o.name)
        .toSorted()
        .join(", ");
    },
    getCourseName(id) {
      if (!id) return "";
      return this.biz.courses?.find((c) => c._id == id)?.name || "";
    },
  },
};
</script>
