<template lang='pug'>
div(v-if="item")
  .my-row
    span.text-capitalize {{ item.method }}
    span(v-if="item.brand") {{ item.brand }}
    span(v-if="item.last4") ...{{ item.last4 }}
    span {{ item.amount | currency }}
    span(v-if="approve") {{ approve }}
    span(v-if="item.time") {{ item.time | time }}
    span(v-if="item.device") {{ item.device.name }}
    v-btn(
      @click="refund",
      v-if="isStripe && isLessThan24Hours",
      x-small,
      color="secondary",
      outlined
    ) Refund
  ClickToCopy(:text="item.transId", v-if="item.transId")
</template>

<script>
export default {
  props: ["item"],
  computed: {
    approve() {
      return this.item?.approve?.substring(0, 10);
    },
    isStripe() {
      return this.item?.method === "stripe";
    },
    isLessThan24Hours() {
      const cutoff = Date.now() - 48 * 60 * 60 * 1000;
      return this.item?.time && this.item.time > cutoff;
    },
  },
  methods: {
    refund() {
      this.$emit("refund", this.item);
    },
  },
};
</script>

<style lang="sass" scoped>
.my-row
  display: flex
  flex-wrap: wrap
  gap: 6px
  row-gap: 4px
  align-items: center
</style>