import Main from './index.vue'
import Dashboard from './Dashboard/index.vue'
import Manage from './Manage/index.vue'
import Tickets from './Tickets/index.vue'

export default [{
  path: '/events', component: Main, meta: { auth: true },
  children: [
    { path: '', redirect: 'dashboard' },
    { path: 'dashboard', component: Dashboard },
    { path: 'manage', component: Manage },
    { path: 'tickets', component: Tickets }
  ]
}]