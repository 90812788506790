<template lang="pug">
v-dialog(v-model="dialog", max-width="550")
  template(v-slot:activator="{ on }")
    IconBtn(@click="open()", v-on="on", title="Add", icon="mdi-plus")
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Add Tables
      v-spacer
      IconBtn(@click="more()", title="More Table", icon="mdi-plus")
    v-form(@submit.prevent="submit")
      simple-table
        thead
          tr
            th ID
            th Min
            th Max
            th Room
            th
        tbody
          tr(v-for="(table, index) in tables", :key="index", align="center")
            td
              v-text-field(
                label="ID",
                v-model="table.id",
                hide-details,
                single-line
              )
            td
              v-select(
                label="Min",
                v-model="table.min_capacity",
                :items="capacity_items",
                hide-details,
                single-line
              )
            td
              v-select(
                label="Max",
                v-model="table.max_capacity",
                :items="capacity_items",
                hide-details,
                single-line
              )
            td
              v-combobox(
                label="Room",
                v-model="table.room",
                :items="rooms",
                hide-details,
                single-line
              )
            td
              v-btn(
                icon,
                small,
                color="error",
                v-if="tables.length > 1",
                @click="less(index)"
              )
                v-icon(small) mdi-close
      v-card-text
        v-btn.mt-3(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      tables: [],
      dialog: false,
      errors: [],
      loading: false,
      capacity_items: _.range(1, 21),
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    rooms() {
      if (!this.biz) return ["main"];
      const data = _.chain(this.biz.tables).pluck("room").uniq().value();
      if (data?.length) return data;
      else return ["main"];
    },
  },
  methods: {
    reset() {
      this.tables = [];
      this.dialog = false;
      this.errors = [];
    },
    open() {
      if (this.tables && this.tables.length > 0) return;
      const room = _.first(this.rooms) || "main";
      this.tables = [{ room, id: "", min_capacity: 2, max_capacity: 4 }];
    },
    less(index) {
      this.tables.splice(index, 1);
    },
    more() {
      const last = _.last(this.tables);
      if (last) {
        let clone = JSON.parse(JSON.stringify(last));
        clone.id = "";
        if (last.id) {
          const digit = parseInt(last.id.slice(-1));
          if (!isNaN(digit)) {
            const newDigit = digit + 1;
            clone.id = last.id.slice(0, -1) + String(newDigit);
          }
          this.tables.push(clone);
        } else {
          this.$toast.error("Please enter ID for the last table first");
        }
      }
    },
    async submit() {
      if (!this.biz) return;
      this.errors = [];
      /// validate
      _.each(this.tables, (table) => {
        if (!table.id) {
          this.errors.push("ID is required");
        } else {
          const some = this.biz.tables.some(
            (o) => o._id !== table._id && o.id === table.id
          );
          if (some) this.errors.push("ID is existing");
          if (!table.room) this.errors.push(table.id + ": Select a room ");
        }
        if (
          !table.min_capacity ||
          !table.max_capacity ||
          table.max_capacity < table.min_capacity
        ) {
          this.errors.push(
            table.id + ": Min/Max is required; Max should be greater than min"
          );
        }
      });
      if (this.errors.length > 0) return;
      try {
        const action = { $push: { tables: { $each: this.tables } } };
        const data = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", data);
        this.reset();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
