<template lang="pug">
v-container.my-3(fluid, grid-list-md)
  .title.text-center.grey--text.text--darken-1 OUR
  .display-2.text-center.orange--text.text--lighten-1.my-3 CLIENTS
  v-layout(wrap, justify-center)
    v-hover(v-for="biz in bizs", :key="biz._id")
      v-card.mx-2.mb-3(
        slot-scope="{ hover }",
        :class="`elevation-${hover ? 12 : 2}`",
        :href="biz.url",
        width="240",
        max-width="100%"
      )
        v-img.blue-grey.lighten-5(
          :src="biz.logo",
          alt="...",
          height="180",
          contain
        )
        .pa-2
          .body-2.text-truncate {{ biz.name }}
          .caption {{ biz.address.city }}, {{ biz.address.state }}
  .text-center.my-5
    v-btn(href="https://selflane.com") See {{ totalCount - bizs.length }} More Clients
</template>

<script>
export default {
  name: "Clients",
  data() {
    return {
      bizs: [],
      totalCount: 0,
    };
  },
  mounted() {
    this.loadBizs();
    this.countBizs();
  },
  methods: {
    async loadBizs() {
      const params = {
        criteria: {
          "status.byAdmin": 1,
          "status.byUser": 1,
          "stats.fav": { $gte: 5 },
        },
        select: "name address logo photos url",
      };
      this.bizs = await this.$api.biz.list(params);
      this.bizs.forEach((o) => {
        if (o.logo) o.logo = "https://media.selflane.com/" + o.logo;
        if (o.url) o.url = "https://selflane.com/bizs/" + o.url;
      });
    },
    countBizs() {
      const params = {
        criteria: { "status.byAdmin": 1, "status.byUser": 1 },
      };
      const baseURL = process.env.VUE_APP_MAIN_API;
      this.axios.post(baseURL + "/bizs/count", params).then((response) => {
        if (response && response.data) this.totalCount = response.data.count;
      });
    },
  },
};
</script>
