<template lang="pug">
v-layout(column, align-center, v-if="posEditable")
  v-form(@submit.prevent="submit")
    v-card(max-width="500", width="100%", flat, :loading="loading")
      v-toolbar(flat, dense)
        .subtitle-2 Custom To-go Types
      v-card-text
        div If Close with ExCard is on, the system would close that order with ExCard payment automatically.
      simple-table
        thead
          tr
            th Name
            th Auto-close
            th
        tbody
          tr(v-for="(item, index) in posEditable.togo", :key="index")
            td
              v-text-field(v-model.trim="item.name", hide-details, single-line)
            td
              v-switch(
                color="secondary",
                v-model="item.close",
                hide-details,
                dense
              )
            td.text-right(valign="bottom")
              v-btn(icon, small, @click.stop="moveup(index)")
                v-icon mdi-menu-up
              v-btn(icon, small, @click.stop="movedown(index)")
                v-icon mdi-menu-down
              v-btn(icon, small, @click.stop="remove(index)", color="error")
                v-icon(small) mdi-delete
      v-card-text
        v-btn.text-capitalize(text, small, color="secondary", @click="more()") Add More
        v-btn.text-capitalize(
          text,
          small,
          color="secondary",
          @click="setDefault()"
        ) Reset to Default
      v-card-actions(v-if="dirty")
        v-btn.white--text(color="secondary", type="submit", :loading="loading") Save
        v-spacer
        v-btn(text, @click="cancel()") Cancel
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      headers: [
        { text: "Name", sortable: false },
        { text: "Close with ExCard", sortable: false },
        { text: "", sortable: false },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["pos", "posEditable"]),
    dirty() {
      return JSON.stringify(this.posEditable) !== JSON.stringify(this.pos);
    },
  },
  methods: {
    ...mapActions(["setPOS", "resetEditable"]),
    more() {
      if (!this.posEditable.togo || !this.posEditable.togo.length) {
        this.posEditable.togo = [{ name: "", close: false }];
      } else {
        this.posEditable.togo.push({ name: "", close: false });
      }
    },
    remove(index) {
      if (!this.posEditable.togo || !this.posEditable.togo.length) return;
      if (index < 0 || index >= this.posEditable.togo.length) return;
      this.posEditable.togo.splice(index, 1);
    },
    moveup(index) {
      if (index < this.posEditable.togo.length && index > 0) {
        const tmp = JSON.parse(JSON.stringify(this.posEditable.togo[index]));
        this.posEditable.togo.splice(index, 1);
        this.posEditable.togo.splice(index - 1, 0, tmp);
      }
    },
    movedown(index) {
      if (index < this.posEditable.togo.length - 1 && index >= 0) {
        const tmp = JSON.parse(JSON.stringify(this.posEditable.togo[index]));
        this.posEditable.togo.splice(index, 1);
        this.posEditable.togo.splice(index + 1, 0, tmp);
      }
    },
    setDefault() {
      this.posEditable.togo = [
        { name: "Call-in", close: false },
        { name: "Walk-in", close: false },
        { name: "Grubhub", close: false },
        { name: "DoorDash", close: false },
        { name: "UberEats", close: false },
        { name: "Postmates", close: false },
      ];
    },
    async submit() {
      this.loading = true;
      this.posEditable.togo = _.chain(this.posEditable.togo)
        .each((o) => (o.name = o.name.trim()))
        .reject((o) => !o.name)
        .value();
      try {
        const action = { $set: { togo: this.posEditable.togo } };
        const result = await this.$api.pos.put(this.pos?._id, { action });
        this.setPOS(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    cancel() {
      this.resetEditable();
    },
  },
};
</script>
