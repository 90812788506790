<template lang="pug">
div
  .d-flex.flex-row.align-center
    .title Sales Stats
    v-spacer
    DateRangePicker(
      v-model="date_range",
      @select="changeRange()",
      :loading="loading",
      :monthSelector="true"
    )
  ItemizedSales.my-3(:report="result.report", :period="period", v-if="result")
  GroupedModifiers(:report="result.report", :period="period", v-if="result")
  Ingredients(
    :ingredients="result.ingredients",
    :period="period",
    v-if="result"
  )
  Discounts.my-3(:items="discounts", :period="period")
  DiscountedItems.my-3(:report="dicountedItems", :period="period")
</template>

<script>
import ItemizedSales from "./ItemizedSales/index";
import GroupedModifiers from "./GroupedModifiers";
import Ingredients from "./Ingredients";
import Discounts from "./Discounts";
import DiscountedItems from "./DiscountedItems/index";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  components: {
    ItemizedSales,
    GroupedModifiers,
    Ingredients,
    Discounts,
    DiscountedItems,
  },
  data() {
    return {
      date_range: [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      beginDate: null,
      endDate: null,
      result: null,
      range: null,
      discounts: null,
      dicountedItems: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    begin() {
      if (!this.beginDate) return null;
      return moment(this.beginDate).startOf("day").valueOf();
    },
    end() {
      if (!this.endDate) return null;
      return moment(this.endDate).endOf("day").valueOf();
    },
    period() {
      return `${moment(this.beginDate).format("YYYY-MM-DD")} to ${moment(
        this.endDate
      ).format("YYYY-MM-DD")}`;
    },
  },
  mounted() {
    this.changeRange();
  },
  methods: {
    changeRange() {
      this.beginDate = this.date_range[0];
      this.endDate = this.date_range[1];
      const diff = moment(this.endDate).unix() - moment(this.beginDate).unix();
      if (diff < 0) {
        this.$toast.error("End date should be later than begin date");
        return;
      }
      if (diff / 60 / 60 / 24 > 40) {
        this.$toast.error(
          "The start date to end date should be less than 40 days"
        );
        return;
      }
      this.load();
    },
    async load() {
      if (!this.biz) return;
      if (!this.begin || !this.end) return;
      this.loading = true;
      const params = { bizId: this.biz._id, begin: this.begin, end: this.end };
      this.result = await this.$api.order.custom("stats/dishes", params);
      await this.loadDiscount();
      await this.loadDiscountDishes();
      this.loading = false;
    },
    async loadDiscount() {
      if (!this.biz) return;
      if (!this.begin || !this.end) return;
      const params = { bizId: this.biz._id, begin: this.begin, end: this.end };
      this.discounts = await this.$api.order.custom("stats/discount", params);
    },
    async loadDiscountDishes() {
      if (!this.biz) return;
      if (!this.begin || !this.end) return;
      const params = { bizId: this.biz._id, begin: this.begin, end: this.end };
      this.dicountedItems = await this.$api.order.custom(
        "stats/discountedDishes",
        params
      );
    },
  },
};
</script>