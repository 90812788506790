<template lang="pug">
div(v-if="result")
  v-row.mb-3(dense, wrap)
    v-col(cols="12", sm="6", md="4")
      Orders(:items="ops")
    v-col(cols="12", sm="6", md="4")
      Sales2(:items="ops")
    v-col(cols="12", sm="6", md="4")
      Payments(:items="ops")
    Cards(:items="ops")
    CashAndTip(:items="ops")
    Discount(:items="ops")
    ProfitLoss(:items="ops")
  v-row(dense, wrap)
    v-col(cols="12", sm="6", md="4")
      Sales.mb-3(:data="result.sales")
    v-col(cols="12", sm="6", md="4")
      PaymentsDetails(:data="result.payments")
    v-col(cols="12", sm="6", md="4")
      PayInOut.mb-3(:data="result.cashdrawer")
      CreditReturn.mb-3(:data="result.creditreturn")
      PointReport.mb-3(:report="pointearnReport", title="Points Earned")
      PointReport.mb-3(:report="pointredeemReport", title="Points Redeemed")
  TogoBreakdown.my-3(:data="result.sales.odbreakdown")
  Balances.my-3(:data="result.balances")
  Download2(:items="ops", :csv="false", :daily="false")
    Download(:report="result")
</template>

<script>
import { mapGetters } from "vuex";
import Orders from "/libs/components/Order/Performance/DaysReport/Orders";
import Sales2 from "/libs/components/Order/Performance/DaysReport/Sales";
import Payments from "/libs/components/Order/Performance/DaysReport/Payments";
import Cards from "/libs/components/Order/Performance/DaysReport/Cards";
import CashAndTip from "/libs/components/Order/Performance/DaysReport/CashAndTip";
import Discount from "/libs/components/Order/Performance/DaysReport/Discount";
import ProfitLoss from "/libs/components/Order/Performance/DaysReport/ProfitLoss";
import Sales from "./Sales/index";
import PaymentsDetails from "./PaymentsDetails";
import Balances from "./Balances";
import PayInOut from "./PayInOut";
import CreditReturn from "./CreditReturn";
import Download from "./Download";
import Download2 from "/libs/components/Order/Performance/DaysReport/Download";
import PointReport from "@/components/Customer/Member/Point/Report/index";

export default {
  name: "BizSalesReport",
  components: {
    Orders,
    Sales2,
    Payments,
    Cards,
    CashAndTip,
    Discount,
    ProfitLoss,
    Sales,
    PaymentsDetails,
    Balances,
    PayInOut,
    CreditReturn,
    Download,
    Download2,
    PointReport,
  },
  props: ["result", "pointearnReport", "pointredeemReport"],
  computed: {
    ...mapGetters(["biz"]),
    ops() {
      if (this.result?.ops) return [this.result.ops];
      return [];
    },
  },
};
</script>
