<template lang="pug">
v-row
  v-col(cols="12", md="6", v-for="(item, index) in bizservices", :key="index")
    Cell(:item="item")
</template>

<script>
import Cell from "./Cell";
import { mapGetters } from "vuex";

export default {
  components: { Cell },
  computed: {
    ...mapGetters(["bizservices"]),
  },
};
</script>
