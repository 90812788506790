<template lang="pug">
.my-box.my-1
  div
    .company-copyright &copy; {{ new Date().getFullYear() }} Selflane.com
    .version v{{ appVersion }}
  .s-vstack
    .link-box.link-box-right
      a.link-item.large(href="https://docs.selflane.com", target="_blank") Documentation
      a.link-item.large(href="https://help.selflane.com", target="_blank") Help Center
    .link-box.link-box-right
      a.link-item(
        v-for="item in items",
        :key="item.title",
        :href="item.href",
        target="_blank"
      ) {{ item.title }}
</template>

<script>
import packageInfo from "@/../package.json";

export default {
  data() {
    return {
      appVersion: packageInfo.version,
      items: [
        { title: "Privacy Policy", href: "https://next.selflane.com/privacy" },
        {
          title: "Terms of Service",
          href: "https://next.selflane.com/terms-of-service",
        },
        {
          title: "Merchant Agreement",
          href: "https://next.selflane.com/merchant-agreement",
        },
        {
          title: "Brand Resources",
          href: "https://next.selflane.com/brand-resources",
        },
        { title: "News", href: "https://next.selflane.com/press" },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.my-box
  display: flex
  justify-content: space-between
  align-items: baseline
  gap: 24px

.company-copyright
  color: #a4a4a4
  font-weight: 500
  font-size: 14px
  white-space: nowrap

.version
  font-size: 12px
  color: #a4a4a4
</style>
