<template lang="pug">
v-dialog(v-model="dialog", persistent, max-width="600px")
  template(v-slot:activator="{ on }")
    v-btn(color="error", x-small, v-on="on", @click="open", outlined) Disconnect
  v-card
    v-card-title
      v-icon.mr-2 mdi-alert-circle
      span Disconnect 7Shifts Integration
    v-card-text
      p Are you sure you want to disconnect 7Shifts integration?
      p This may affect your business operations and data syncing. Please only proceed if you are sure.
      v-switch(
        v-model="confirm",
        label="I understand the consequences",
        color="secondary"
      )
    v-card-actions
      v-btn(
        color="error",
        text,
        @click="disconnect",
        :disabled="!confirm || loading"
      )
        span Disconnect
      v-btn(text, @click="dialog = false")
        span Cancel
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      confirm: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    open() {
      this.confirm = false;
      this.dialog = true;
    },
    async disconnect() {
      this.loading = true;
      try {
        if (!this.biz?._id) throw new Error("Invalid business ID");
        const result = await this.$api.service.account.custom(
          "seven/disconnect",
          { bizId: this.biz._id }
        );
        this.$store.dispatch("updateBizService", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>