const state = {
  matrixmodifier: null
}

const getters = {
  matrixmodifier: state => state.matrixmodifier,
}

const actions = {
  setMatrixModifier: ({
    commit
  }, matrixmodifier) => {
    commit('setMatrixModifier', matrixmodifier)
  }
}

const mutations = {
  setMatrixModifier(state, matrixmodifier) {
    state.matrixmodifier = matrixmodifier
  }
}

export default { state, getters, actions, mutations }