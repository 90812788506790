<template lang="pug">
v-btn(
  color="secondary",
  small,
  text,
  @click="restore(item)",
  :loading="loading"
) Restore
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  methods: {
    async restore() {
      this.loading = true;
      const params = { bizId: this.item.biz, backupId: this.item._id };
      try {
        const biz = await this.$api.bizBackup.custom("restore", params);
        this.$store.dispatch("setBiz", biz);
        this.$emit("restored");
        this.$toast.success("Menu restored");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.$emit("restored");
      this.loading = false;
    },
  },
};
</script>