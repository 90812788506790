<template lang="pug">
v-btn(
  icon,
  color="error",
  small,
  v-if="visible",
  @click="remove",
  :loading="loading"
)
  v-icon(small) mdi-delete
</template>

<script>
export default {
  props: {
    throttle: { type: Object, default: null },
  },
  data() {
    return { loading: false };
  },
  computed: {
    visible() {
      return this.throttle?._id;
    },
  },
  methods: {
    async remove() {
      this.loading = true;
      try {
        const result = await this.$api.b.throttle.delete(this.throttle._id);
        this.$store.dispatch("removeBizThrottle", result);
        this.$emit("deleted");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>