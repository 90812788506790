<template lang="pug">
v-list-item
  v-list-item-content
    v-list-item-title ... {{ item.last4 }}
      v-chip.ml-3(
        v-if="item.default_for_currency",
        small,
        dark,
        color="accent"
      ) default
    v-list-item-subtitle {{ item.bank_name }}
      span.ml-1(v-if="item.status != 'new'") ({{ item.status }})
  v-list-item-action
    v-layout(v-if="!item.default_for_currency")
      SetDefaultBank(:bankAccount="item")
      DeleteBank(:bankAccount="item")
</template>

<script>
import DeleteBank from "./DeleteBank";
import SetDefaultBank from "./SetDefaultBank";

export default {
  components: { SetDefaultBank, DeleteBank },
  props: ["item"],
};
</script>
