<template lang="pug">
v-card(flat)
  v-toolbar(dense, flat)
    .subtitle-2 Create a Business Merchant-Service Account
  v-card-text
    AccountLinkCreate.mb-4(:bizId="bizId")
    Footnote
</template>

<script>
import { mapGetters } from "vuex";
import Footnote from "./Footnote";

export default {
  components: { Footnote },
  computed: {
    ...mapGetters(["biz"]),
    bizId() {
      return this.biz?._id;
    },
  },
};
</script>
