const state = {
  departments: [],
  roles: [],
  users: [],
  inactiveReasons: []
}

const getters = {
  departments: state => state.departments,
  roles: state => state.roles,
  users: state => state.users,
  inactiveReasons: state => state.inactiveReasons,
}

const actions = {
  setDepartments({ commit }, data) { commit('setDepartments', data) },
  addDepartment({ commit }, data) { commit('addDepartment', data) },
  updateDepartment({ commit }, data) { commit('updateDepartment', data) },
  removeDepartment({ commit }, data) { commit('removeDepartment', data) },
  setRoles({ commit }, data) { commit('setRoles', data) },
  addRole({ commit }, data) { commit('addRole', data) },
  updateRole({ commit }, data) { commit('updateRole', data) },
  removeRole({ commit }, data) { commit('removeRole', data) },
  setUsers({ commit }, data) { commit('setUsers', data) },
  addUser({ commit }, data) { commit('addUser', data) },
  updateUser({ commit }, data) { commit('updateUser', data) },
  removeUser({ commit }, data) { commit('removeUser', data) },
  setInactiveReasons({ commit }, data) { commit('setInactiveReasons', data) },
}

const mutations = {
  setDepartments(state, data) {
    state.departments = data
  },
  addDepartment(state, data) {
    state.departments.push(data)
  },
  updateDepartment(state, data) {
    state.departments = state.departments.map(o => {
      if (o.id === data.id) return data
      return o
    })
  },
  removeDepartment(state, data) {
    state.departments = state.departments.filter(o => o.id !== data.id)
  },
  setRoles(state, data) {
    state.roles = data
  },
  addRole(state, data) {
    state.roles.push(data)
  },
  updateRole(state, data) {
    state.roles = state.roles.map(o => {
      if (o.id === data.id) return data
      return o
    })
  },
  removeRole(state, data) {
    state.roles = state.roles.filter(o => o.id !== data.id)
  },
  setUsers(state, data) {
    state.users = data
  },
  addUser(state, data) {
    state.users.push(data)
  },
  updateUser(state, data) {
    state.users = state.users.map(o => {
      if (o.id === data.id) return data
      return o
    })
  },
  removeUser(state, data) {
    state.users = state.users.filter(o => o.id !== data.id)
  },
  setInactiveReasons(state, data) {
    state.inactiveReasons = data
  },
}

export default { namespaced: true, state, getters, actions, mutations }