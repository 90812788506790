<template lang="pug">
Page(:title="title", :items="items", :doc="doc", :loading="loading")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      title: "Payments",
      doc: "https://docs.selflane.com/merchant-services/",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz", "stripeAccount"]),
    items() {
      if (this.stripeAccount) {
        return [
          { title: "Home", url: "/payments/home" },
          { title: "Online", url: "/payments/online" },
          { title: "Terminal", url: "/payments/terminal" },
          { title: "Settings", url: "/payments/settings" },
        ];
      } else {
        return [
          { title: "Home", url: "/payments/home" },
          { title: "Settings", url: "/payments/settings" },
        ];
      }
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setStripeAccount"]),
    async load() {
      if (!this.biz) {
        this.setStripeAccount();
        this.validatePath();
        return;
      }
      this.loading = true;
      const params = { bizId: this.biz._id };
      try {
        const result = await this.$api.stripe.account.retrieve(params);
        this.setStripeAccount(result);
      } catch (e) {
        this.setStripeAccount();
      }
      this.loading = false;
      this.validatePath();
    },
    validatePath() {
      const path = this.$router.currentRoute.path;
      const found = this.items.find((o) => o.url == path);
      if (!found && path != "/payments" && this.biz) {
        this.$router.push("/payments");
      }
    },
  },
};
</script>
