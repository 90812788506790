<template lang="pug">
v-card(v-if="biz", flat)
  v-toolbar(dense, flat)
    .subtitle-2 Business Licenses
    v-spacer
    Create
  List
  ViewDocument
</template>

<script>
import { mapGetters } from "vuex";
import Create from "./Create";
import List from "./List";
import ViewDocument from "./ViewDocument";

export default {
  components: { Create, List, ViewDocument },
  computed: { ...mapGetters(["biz"]) },
};
</script>
