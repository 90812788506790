<template lang="pug">
Page(title="Discounts", :items="items", :doc="doc")
</template>

<script>
export default {
  data() {
    return {
      doc: "https://docs.selflane.com/biz/settings/discounts.html",
      items: [
        { title: "Special Pricing", url: "/discount/scheduled" },
        { title: "Voucher", url: "/discount/voucher" },
        { title: "Combo", url: "/discount/combo" },
      ],
    };
  },
};
</script>
