<template lang="pug">
Page(title="Profile", :items="items", :doc="doc")
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      doc: "https://docs.selflane.com/biz/settings/basic.html",
    };
  },
  computed: {
    ...mapGetters(["biz", "domain"]),
    items() {
      let list = [
        { title: "Settings", url: "/profile/settings" },
        { title: "Hours", url: "/profile/hours" },
        { title: "Reservation", url: "/profile/reservation" },
        { title: "Delivery", url: "/profile/delivery" },
      ];
      if (this.domain) {
        list.push({ title: "Domain", url: "/profile/domain" });
      }
      return list;
    },
  },
  mounted() {
    EventBus.$on("switch-biz", this.loadDomain);
    this.loadDomain();
  },
  destroyed() {
    EventBus.$off("switch-biz");
  },
  methods: {
    async loadDomain() {
      if (!this.biz?._id) {
        this.$store.dispatch("setDomain", null);
        return;
      }
      const criteria = { biz: this.biz._id };
      const result = await this.$api.b.domain
        .retrieve({ criteria })
        .catch(() => null);
      this.$store.dispatch("setDomain", result);
    },
  },
};
</script>
