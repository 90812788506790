<template lang="pug">
PartnerPage(title="Dev Issues", :doc="doc")
  IconBtn(@click="create", title="Create", icon="add")
  List.my-3(:items="issues")
  Form
</template>

<script>
import List from "/libs/components/Dev/Issue/List";
import Form from "/libs/components/Dev/Issue/Form";
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  components: { List, Form },
  data() {
    return {
      doc: "https://docs.selflane.com/partner/service-desk/issues.html",
    };
  },
  computed: {
    ...mapGetters("dev", ["issues"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const user = this.$auth.user()?._id;
      if (!user) return;
      try {
        const criteria = {};
        const result = await this.$api.dev.issue.list({ criteria });
        this.$store.dispatch("dev/setIssues", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load issues");
      }
    },
    create() {
      const data = {
        title: "",
        description: "",
        tags: [],
      };
      EventBus.$emit("edit-issue", data);
    },
  },
};
</script>