<template lang="pug">
v-card(flat, height="100%")
  simple-table
    thead
      AmountTableRow(
        name="Total Receivables",
        :amount="total",
        bold,
        mandatory
      )
    tbody
      AmountTableRow(name="Net Sales", :amount="net")
      AmountTableRow(name="Tax", :amount="tax")
      AmountTableRow(name="Tip", :amount="tip")
      AmountTableRow(name="Fee (in-store)", :amount="fee")
      AmountTableRow(name="Third Party", :amount="third_party")
      AmountTableRow(name="Gift Sold", :amount="gift")
      AmountTableRow(name="Cash Discount Adjustment", :amount="adjustment")
      AmountTableRow(name="Deduction", :amount="deduction")
      AmountTableRow(name="Selflane Charges", :amount="selflane")
</template>

<script>
export default {
  props: ["items"],
  computed: {
    total() {
      return this.items?.reduce((a, b) => a + b.sales.total, 0);
    },
    net() {
      return this.items?.reduce((a, b) => a + b.sales.net, 0);
    },
    tax() {
      return this.items?.reduce((a, b) => a + b.sales.tax, 0);
    },
    tip() {
      return this.items?.reduce((a, b) => a + b.sales.tip, 0);
    },
    fee() {
      return this.items?.reduce((a, b) => a + b.sales.fee, 0);
    },
    third_party() {
      return this.items?.reduce((a, b) => a + b.sales.third_party, 0);
    },
    gift() {
      return this.items?.reduce((a, b) => a + b.sales.gift, 0);
    },
    adjustment() {
      return this.items?.reduce((a, b) => a + b.sales.adjustment, 0);
    },
    deduction() {
      return this.items?.reduce((a, b) => a + b.sales.deduction, 0);
    },
    selflane() {
      return this.items?.reduce((a, b) => a + b.sales.selflane, 0);
    },
  },
};
</script>