<template lang="pug">
v-dialog(v-model="dialog", max-width="400px")
  v-card(v-if="orders")
    v-toolbar(dense, flat)
      .subtitle-2 Cancel {{ orders.length }} orders
      v-spacer
      .subtitle-2 {{ total | currency }}
    v-card-text(v-if="!hasOnlineOrder")
      span In canceling these orders, you agree to refund the customer if there was any payment associated with this orders.
      v-form(@submit.prevent="submit")
        v-text-field(v-model="reason", label="Reason", required, autofocus)
        v-row(justify="space-between")
          v-btn(text, @click="dialog = false") Cancel
          v-btn(
            type="submit",
            text,
            color="secondary",
            :disabled="!reason.length",
            :loading="loading"
          ) Proceed
    v-card-text(v-else)
      .red--text.subtitle-2 Not Allowed. There are online orders in the selected items. You can only cancel online orders one by one.
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapActions } from "vuex";

export default {
  name: "CancelMultipleOrders",
  data() {
    return {
      dialog: false,
      orders: [],
      reason: "",
      loading: false,
    };
  },
  computed: {
    total() {
      return this.orders.reduce((a, b) => a + b.payment.total, 0);
    },
    hasOnlineOrder() {
      return this.orders.includes((o) => o.orderer.type == 0);
    },
  },
  mounted() {
    EventBus.$on("cancel-multiple-orders", this.open);
  },
  destroyed() {
    EventBus.$off("cancel-multiple-orders", this.open);
  },
  methods: {
    ...mapActions(["updateManyOrders"]),
    open(data) {
      if (!data) return;
      this.orders = data;
      this.reason = "";
      this.dialog = true;
    },
    async submit() {
      if (!this.orders?.length || !this.reason) return;
      this.loading = true;
      const params = {
        orderIds: this.orders.map((o) => o._id),
        reason: this.reason,
      };
      try {
        await this.axios.post("/orders/cancelMultiple", params);
        this.reloadOrders();
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async reloadOrders() {
      if (!this.orders || !this.reason) return;
      const params = {
        criteria: {
          _id: { $in: _.pluck(this.orders, "_id") },
        },
        select: "orderer needed created orderNumber status payment",
      };
      const { data } = await this.axios.post("/orders/list", params);
      this.updateManyOrders(data);
    },
  },
};
</script>