<template lang="pug">
simple-table(v-if="weekly_specials && weekly_specials.length")
  thead
    tr
      th Discount %
      th Day(s)
      th Name
      th.text-right End
      th
  tbody
    tr(
      v-for="(item, index) in weekly_specials",
      :key="index",
      @click="open(item)",
      role="button"
    )
      th {{ item.pct }}% off
      td {{ item.days | day(true) }}
      td {{ item.name }}
      td.text-right {{ item.end | date }}
      td.text-right
        v-btn(icon, @click.stop="handleDelete(item)")
          v-icon(small, color="error") mdi-delete
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["weekly_specials", "biz"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setWeeklySpecials", "removeWeeklySpecial"]),
    async load() {
      if (!this.biz) return;
      const params = {
        criteria: { biz: this.biz._id },
      };
      const res = await this.axios.post("/discount/weeklySpecial/list", params);
      this.setWeeklySpecials(res.data);
    },
    open(item) {
      EventBus.$emit("edit-weekly-special", item);
    },
    handleDelete(item) {
      confirm("Do you want to delete this special offer?") &&
        this.deleteOne(item);
    },
    async deleteOne(item) {
      const params = {
        weeklySpecialId: item._id,
      };
      await this.axios.post("/discount/weeklySpecial/deleteOne", params);
      this.removeWeeklySpecial(item);
    },
  },
};
</script>
