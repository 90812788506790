<template lang="pug">
.s-vstack
  .d-flex.flex-row.align-center
    h2 Manage Gallery
    v-spacer
    IconBtn(@click="addGalleryItem", title="Item", icon="mdi-plus")
  simple-table(v-if="domain")
    tbody
      tr(
        v-for="(item, key) in domain.gallery",
        :key="key",
        draggable="true",
        @dragstart="drag(key, $event)",
        @drop="drop(key, $event)",
        @dragend="dragend($event)",
        @dragover.prevent=""
      )
        td
          GalleryItem(:item="item")
        td.text-right
          DeleteOne(:item="item", :domain="domain")
  Form(ref="form", :domain="domain")
</template>

<script>
import { mapGetters } from "vuex";
import DeleteOne from "./DeleteOne";
import Form from "./Form";
import GalleryItem from "./GalleryItem";

export default {
  components: { DeleteOne, GalleryItem, Form },
  computed: {
    ...mapGetters(["biz", "domain"]),
  },
  methods: {
    addGalleryItem() {
      this.$refs.form.open();
    },
    drag(index, event) {
      event.dataTransfer.setData("index", index);
      event.target.style.opacity = 0.5;
    },
    dragend(event) {
      event.target.style.opacity = 1;
    },
    async drop(index, event) {
      event.preventDefault();
      const fromIndex = event.dataTransfer.getData("index");
      const gallery = [...this.domain.gallery];
      const [removed] = gallery.splice(fromIndex, 1);
      gallery.splice(index, 0, removed);
      await this.updateGallery(gallery);
    },
    async updateGallery(gallery) {
      try {
        const result = await this.$api.b.domain.update({
          criteria: { _id: this.domain._id },
          action: { $set: { gallery: gallery } },
        });
        this.$toast.success("Gallery updated.");
        this.$store.dispatch("setDomain", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>