<template lang="pug">
div
  v-card(flat)
    v-toolbar(flat, dense)
      .subtitle-2 Cash Discount/Surcharge Program
    v-card-text
      p In this setup, you have the option to give a discount to customers when they are paying with cash or add a surcharge (name varies) when paying with a credit card.
      p Please note that Selflane is offering this setup only as a software option for your business. You need to consult with your accountant or lawyer to understand if the choice you made complies with your local laws and regulations.
      p The incorrect practice may cause legal and financial challenges to your business.
      p Program is available for POS version 4.62+
    v-list(dense)
      v-list-item(v-for="(item, index) in items", :key="index")
        v-list-item-content
          v-list-item-title {{ item.title }}
          v-list-item-subtitle {{ item.text }}
    Form
</template>

<script>
import Form from "./Form";

export default {
  components: { Form },
  data() {
    return {
      items: [
        {
          title: "True-Cash Discount",
          text: "Customers get a discount from the menu price when paying with cash",
        },
        {
          title: "Non-Cash Adjustment",
          text: "Customers pay an extra from the menu cash-price when paying with credit card. You may need to present both cash and card price on the menu.",
        },
        {
          title: "Surcharging",
          text: "Customers pay an extra from the menu price when paying with credit card.",
        },
        {
          title: "Service fee",
          text: "Customers pay an extra fee when paying with credit card.",
        },
        {
          title: "Convenience fee",
          text: "Customers pay an extra fee when paying with credit card.",
        },
        {
          title: "Dual Pricing",
          text: "Customers pay a different price when paying with cash or credit card. Credit card price is calculated by adding a percentage to the cash price.",
        },
      ],
    };
  },
};
</script>