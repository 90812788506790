<template lang="pug">
v-dialog(v-model="dialog", width="550")
  template(v-slot:activator="{ on }")
    IconBtn(
      icon="mdi-printer",
      title="Printing Setups",
      color="secondary",
      @click="open()",
      v-on="on"
    )
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Printing Groups
      v-spacer
      v-btn.text-notransform(
        outlined,
        small,
        color="secondary",
        @click.stop="more()"
      )
        v-icon(left, small) mdi-plus
        span Item
    v-divider
    PrintGroupsAll
    v-divider
    v-form(@submit.prevent="save")
      simple-table
        thead
          tr
            th Name
            th Kitchen Station
            th
        tbody
          tr(v-for="(item, index) in items", :key="index")
            td
              v-text-field(
                dense,
                v-model.trim="item.name",
                hide-details,
                single-line,
                @keydown.down="more()"
              )
            td
              v-select(
                dense,
                v-model="item.target",
                :items="printItems",
                item-text="title",
                hide-details
              )
            td
              v-btn(
                icon,
                small,
                @click.stop="handleRemove(index)",
                tabindex="-1"
              )
                v-icon(color="red") mdi-close
      v-card-text
        v-btn(type="submit", block, color="secondary", :loading="loading") Save
</template>

<script>
import { mapGetters } from "vuex";
import PrintGroupsAll from "./PrintGroupsAll";

export default {
  components: { PrintGroupsAll },
  data() {
    return {
      dialog: false,
      loading: false,
      items: [],
    };
  },
  computed: { ...mapGetters(["biz", "printItems"]) },
  methods: {
    open() {
      if (!this.biz) return;
      this.items = JSON.parse(JSON.stringify(this.biz.printingGroups));
      if (!this.items || !this.items.length) {
        this.items = [{ name: "", target: 0 }];
      }
    },
    more() {
      this.items.push({ name: "", target: 0 });
    },
    /// Remove a group
    /// Give warning if the group has assigned dishes
    handleRemove(index) {
      const item = this.items[index];
      if (item._id) {
        const filtered = this.biz.dishes?.filter(
          (o) => o.printingGroup == item._id
        );
        if (filtered?.length) {
          const message =
            "Are you sure to remove this printing group? Items assigned to this group will not print together anymore.";
          confirm(message) && this.less(index);
          return;
        }
      }
      this.less(index);
    },
    less(index) {
      this.items.splice(index, 1);
    },
    async save() {
      if (!this.biz) return;
      this.loading = true;
      const items = this.items.filter((o) => o.name);
      const action = { $set: { printingGroups: items } };
      try {
        const data = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
