<template lang="pug">
div
  v-card(outlined)
    v-toolbar(flat, dense)
      .subtitle-2 Open Influencer Program
      v-spacer
      v-btn.text-notransform(outlined, small, @click="open()", v-if="openPlan")
        span Edit
        v-icon(small, right) mdi-pencil
      v-btn.text-notransform(outlined, small, @click="open()", v-else)
        span Add
        v-icon(small, right) mdi-plus
    v-card-text
      .text-center(v-if="openPlan")
        .title Discount:
          span.ml-2(v-if="openPlan.type == 'pct'") {{ -openPlan.pct }}%
          span.ml-2(v-else) {{ -openPlan.fix | currency }}
        .title Expires on {{ openPlan.expiry | date }}
        v-alert(color="error", dark, v-if="isExpired") Expired
      div In this program, any influencers are allowed to promote your business from their channels and bring you sales. They will earn commission on the promoted sales.
  Create
</template>

<script>
import { mapGetters } from "vuex";
import Create from "./Create";
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";

export default {
  components: { Create },
  computed: {
    ...mapGetters(["biz", "influencerPlans"]),
    openPlan() {
      return this.influencerPlans?.find((o) => o.is_open);
    },
    isExpired() {
      return this.openPlan && this.openPlan.expiry < Date.now();
    },
  },
  methods: {
    open() {
      let plan = this.openPlan;
      if (!plan) {
        plan = {
          biz: this.biz._id,
          type: "fix",
          pct: 5,
          fix: 5,
          minimum: 10,
          limit: 100,
          commission_rate: 5,
          is_open: true,
          expiry: moment().add(12, "months").format("YYYY-MM-DD"),
        };
      }
      EventBus.$emit("edit-influencer-open-plan", plan);
    },
  },
};
</script>
