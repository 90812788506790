<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import _ from "underscore";
import FileSaver from "file-saver";
import Papa from "papaparse";

export default {
  name: "DailyReportDownload",
  props: ["report"],
  methods: {
    setCounts(csvData) {
      if (!this.report) return;
      const counts = this.report.counts;
      csvData.push([""]);
      csvData.push(["Total", counts.total]);
      csvData.push(["Closed", counts.closed]);
      if (counts.open > 0) csvData.push(["Open", counts.open]);
      if (counts.canceled > 0) csvData.push(["Canceled", counts.canceled]);
    },
    setSales(csvData) {
      if (!this.report) return;
      const data = this.report.sales;
      const currency = this.$options.filters.currency;
      csvData.push([""]);
      csvData.push(["Total Sales", currency(data.total)]);
      data.breakdown.forEach((item) => {
        csvData.push([
          "",
          "",
          "",
          "Net Sales / " + item.name,
          "",
          currency(item.total),
        ]);
      });
      data.typebreakdown.forEach((item) => {
        csvData.push([
          "",
          "",
          "",
          "Net Sales / " + item.name,
          "",
          currency(item.total),
        ]);
      });
      csvData.push(["Net Sales", currency(data.net)]);
      const found = data.typebreakdown.find((o) => o.name == "dinein");
      if (data.people && found) {
        csvData.push(["Total Guests", currency(data.people)]);
        csvData.push([
          "Net Dine In/Guest",
          currency(found.total / data.people),
        ]);
      }
      if (data.tax_items && data.tax_items.length) {
        _.each(data.tax_items, (item) => {
          let note = "";
          if (item.type == 1) note = "Inclusive";
          csvData.push([
            "",
            "",
            "",
            item.name,
            item.percentage + "%",
            currency(item.value),
            note,
          ]);
        });
      }
      csvData.push(["Tax", currency(data.tax)]);
      if (data.gift > 0) csvData.push(["Gift Sales", currency(data.gift)]);
      if (data.fee > 0) csvData.push(["Fee", currency(data.fee)]);
      if (data.tip > 0) csvData.push(["Tip", currency(data.tip)]);
      if (data.adjustment > 0)
        csvData.push(["Adjustment", currency(data.adjustment)]);
      if (data.selflane > 0)
        csvData.push(["Selflane Charges", currency(data.selflane)]);
    },
    setPayments(csvData) {
      if (!this.report) return;
      const data = this.report.payments;
      const currency = this.$options.filters.currency;
      csvData.push([""]);
      csvData.push(["Total Paid", currency(data.total)]);
      const items = [
        { name: "Cash", amount: data.cash },
        { name: "Credit Card", amount: data.cc },
        { name: "Stripe", amount: data.stripe },
        { name: "Check", amount: data.check },
        { name: "Gift Redeemed", amount: data.gc },
        { name: "External Card", amount: data.excc },
        { name: "Selflane Online", amount: data.online },
        { name: "Reward", amount: data.reward },
      ];
      items.forEach((item) => {
        if (item.amount) {
          csvData.push([item.name, currency(item.amount)]);
        }
      });
      csvData.push(["Total Tip", currency(data.totaltip)]);
      if (data.cctip > 0) {
        csvData.push(["CC Tip", currency(data.cctip)]);
      }
      if (data.stripetip > 0) {
        csvData.push(["Stripe Tip", currency(data.stripetip)]);
      }
      if (data.checktip > 0)
        csvData.push(["Check Tip", currency(data.checktip)]);
      if (data.gctip > 0) csvData.push(["Gift Tip", currency(data.gctip)]);
      if (data.onlineTip > 0)
        csvData.push(["Online Tip", currency(data.onlineTip)]);
      data.ccbreakdown.forEach((item) => {
        csvData.push([item.name, currency(item.total)]);
      });
      data.exccbreakdown.forEach((item) => {
        csvData.push([item.name, currency(item.total)]);
      });
      data.cashByStation.forEach((item) => {
        csvData.push([item.name, currency(item.total)]);
      });
      csvData.push([
        "Deposit (Cash - Tip)",
        currency(data.cash - data.totaltip),
      ]);
    },
    setBalances(csvData) {
      if (!this.report) return;
      const data = this.report.balances;
      const currency = this.$options.filters.currency;
      csvData.push([""]);
      csvData.push(["Balances by Server", ""]);
      data.forEach((item) => {
        csvData.push([item.name, currency(item.balance)]);
      });
    },
    setPLBreakdown(csvData) {
      if (!this.report) return;
      const data = this.report.plbreakdown;
      const currency = this.$options.filters.currency;
      csvData.push([""]);
      csvData.push(["P&L Breakdown", ""]);
      data.forEach((item) => {
        csvData.push([item.name, currency(item.total)]);
      });
    },
    setDiscounts(csvData) {
      if (!this.report) return;
      const data = this.report.discounts;
      const currency = this.$options.filters.currency;
      csvData.push([""]);
      const total = data.reduce((a, b) => a + b.value, 0);
      csvData.push(["Discounts", currency(total)]);
      data.forEach((item) => {
        const name =
          "#" + item.orderNumber + " -" + item.pctOff + "% " + item.name;
        csvData.push([name, currency(item.value)]);
      });
    },
    setPayInOut(csvData) {
      if (!this.report) return;
      const data = this.report.cashdrawer;
      const currency = this.$options.filters.currency;
      csvData.push([""]);
      csvData.push(["Pay In/Out", currency(data.balance)]);
      data.associates.forEach((item) => {
        csvData.push([item.name, currency(item.total)]);
      });
    },
    download() {
      if (!this.report) return;
      var csvData = [["Date", this.report.date]];
      this.setCounts(csvData);
      this.setSales(csvData);
      this.setPayments(csvData);
      this.setBalances(csvData);
      this.setPLBreakdown(csvData);
      this.setDiscounts(csvData);
      this.setPayInOut(csvData);

      const csv = Papa.unparse(csvData, { header: false });
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(blob, "daily_sales_" + this.report.date + ".csv");
    },
  },
};
</script>
