<template lang="pug">
div
  v-card(outlined)
    v-toolbar(flat, dense)
      .subtitle-2 Sales
      v-spacer
      Download(:report="report", :title="title", :year="year", :sum="sum")
    Table(:report="report", :year="year", :sum="sum")
  OnlineOrdersWithDetail
</template>

<script>
import Download from "./Download";
import Table from "./Table";
import OnlineOrdersWithDetail from "./OnlineOrdersWithDetail/index";

export default {
  components: { Download, Table, OnlineOrdersWithDetail },
  props: ["report", "title", "year"],
  computed: {
    sum() {
      return {
        period: "Sum",
        dish: this.report.reduce((acc, cur) => acc + cur.dish, 0),
        discount: this.report.reduce((acc, cur) => acc + cur.discount, 0),
        gift: this.report.reduce((acc, cur) => acc + cur.gift, 0),
        total: this.report.reduce((acc, cur) => acc + cur.total, 0),
        tax: this.report.reduce((acc, cur) => acc + cur.tax, 0),
        tip: this.report.reduce((acc, cur) => acc + cur.tip, 0),
        fee: this.report.reduce((acc, cur) => acc + cur.fee, 0),
        deduction: this.report.reduce((acc, cur) => acc + cur.deduction, 0),
        selflane: this.report.reduce((acc, cur) => acc + cur.selflane, 0),
        count: this.report.reduce((acc, cur) => acc + cur.count, 0),
        application_fee: this.report.reduce(
          (acc, cur) => acc + cur.application_fee,
          0
        ),
      };
    },
  },
};
</script>
