<template lang="pug">
simple-table(v-if="promotions && promotions.length")
  thead
    tr
      th Title
      th Discount
      th Claimed
      th Start
      th.text-right End
  tbody
    tr(
      v-for="(item, index) in promotions",
      :key="index",
      @click="show(item._id)",
      role="button"
    )
      th {{ item.title }}
      th
        span(v-if="item.type == 'pct'") {{ -item.pct }}%
        span(v-else) {{ -item.fix | currency }}
      td {{ item.claimed }}/{{ item.total }} claimed
      td {{ item.created | date }}
      td.text-right {{ item.expiry | date }}
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  name: "CouponList",
  props: ["promotions"],
  methods: {
    show(couponId) {
      EventBus.$emit("show-coupon-report", couponId);
    },
  },
};
</script>
