<template lang="pug">
simple-table
  thead
    tr
      th Type
      th Count
      th Total
      th.text-right Net
  tbody
    tr(v-for="(item, index) in summary", :key="index")
      th {{ item.type }}
      td {{ item.count }}
      td {{ (item.amount / 100) | currency }}
      td.text-right {{ (item.net / 100) | currency }}
  tfoot
    tr
      td Total
      td {{ total.count }}
      td {{ (total.amount / 100) | currency }}
      td.text-right {{ (total.net / 100) | currency }}
</template>

<script>
import _ from "underscore";

export default {
  props: ["items", "loading"],
  computed: {
    summary() {
      return _.chain(this.items)
        .groupBy("type")
        .map((list, key) => {
          return {
            type: key,
            count: list.length,
            amount: _.reduce(
              list,
              (memo, o) => {
                return memo + o.amount;
              },
              0
            ),
            net: _.reduce(
              list,
              (memo, o) => {
                return memo + o.net;
              },
              0
            ),
          };
        })
        .value();
    },
    total() {
      const count = _.reduce(
        this.summary,
        (memo, item) => {
          return memo + item.count;
        },
        0
      );
      const amount = _.reduce(
        this.summary,
        (memo, item) => {
          return memo + item.amount;
        },
        0
      );
      const net = _.reduce(
        this.summary,
        (memo, item) => {
          return memo + item.net;
        },
        0
      );
      return {
        count: count,
        amount: amount,
        net: net,
      };
    },
  },
};
</script>
