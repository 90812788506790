<template lang="pug">
.my-container
  .logo-container(@click="editLogo")
    v-img(:src="logo", aspect-ratio="1", v-if="logo")
    v-icon(x-large, v-else, color="#f87171") mdi-image-area
  .name-container(@click="editName")
    .name-style {{ biz.name }}
    .short-name-style {{ biz.short_name }}
    v-chip(dark, small, v-if="standing") {{ standing }}
  Form(ref="form", :biz="biz")
  Name(ref="name", :biz="biz")
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";
import Name from "./Name";

export default {
  components: { Form, Name },
  computed: {
    ...mapGetters(["biz"]),
    logo() {
      if (this.biz?.logo) return process.env.VUE_APP_MEDIAHOST + this.biz.logo;
      return null;
    },
    standing() {
      if (this.biz?.standing == "test") return "Test";
      if (this.biz?.standing == "permanently_closed") {
        return "Permanently Closed";
      }
      return null;
    },
  },
  methods: {
    editLogo() {
      this.$refs.form.open();
    },
    editName() {
      this.$refs.name.open();
    },
  },
};
</script>

<style lang="sass" scoped>
.my-container
  display: flex
  flex-direction: row
  align-items: end
  gap: 1rem
.logo-container
  width: 100px
  height: 100px
  overflow: hidden
  display: flex
  align-items: center
  justify-content: center
  border-radius: 12px
  border: 1px solid #cbd5e1
  cursor: pointer
  &:hover
    border-color: #84cc16
.name-container
  cursor: pointer
  &:hover
    text-decoration: underline
.name-style
  font-size: 1.5rem
  font-weight: 600
  // no wrap, single line
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
.short-name-style
  font-size: 0.9rem
  font-weight: 500
  color: #6b7280
  // no wrap, single line
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
</style>