<template lang="pug">
v-card(v-if="data && data.length", flat)
  v-toolbar(flat, dense, dark, color="red")
    .subtitle-2 Over Paid Orders
  simple-table
    tr(v-for="(item, index) in data", :key="index", @click="show(item.id)")
      th \#{{ item.orderNumber }}
      td.text-right {{ item.overPayment | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["data"],
  methods: {
    show(id) {
      if (!id) return;
      EventBus.$emit("show-order-detail", id);
    },
  },
};
</script>
