<template lang="pug">
div
  CreateAccount(v-if="!stripeAccount")
  Account(v-else, ref="account")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import CreateAccount from "./CreateAccount/index";
import Account from "/libs/components/Stripe/Account/index";

export default {
  components: { CreateAccount, Account },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["stripeAccount", "biz"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setStripeAccount"]),
    async load() {
      if (!this.biz) {
        this.setStripeAccount();
        return;
      }
      this.loading = true;
      const params = { bizId: this.biz._id };
      try {
        const result = await this.$api.stripe.account.retrieve(params);
        this.setStripeAccount(result);
      } catch (e) {
        this.setStripeAccount();
      }
      this.loading = false;
    },
  },
};
</script>
