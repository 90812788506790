<template lang='pug'>
.payment
  Item(
    v-for="(item, index) in service_items",
    :key="index",
    :title="item.name",
    :amount="item.value"
  )
  Item(
    title="Subtotal",
    :amount="order.payment.subtotal || order.payment.dish"
  )
  div(v-if="order.payment.tax_items")
    Item(
      v-for="(item, index) in order.payment.tax_items",
      :key="index",
      :title="item.name",
      :amount="item.value"
    )
  Item(v-else, title="Tax", :amount="order.payment.tax")
  Item(title="Gift", :amount="order.payment.gift")
  Item(title="Fee", :amount="order.payment.fee")
  Item(title="Tip", :amount="order.payment.tip")
  Item(
    v-for="(item, index) in order.payment.deduction.items",
    :key="index",
    :title="item.name",
    :amount="item.value",
    :green="true"
  )
  Item(
    v-for="(item, index) in order.payment.adjustment.items",
    :key="index",
    :title="item.name",
    :amount="item.value",
    :green="true"
  )
  Item(title="Selflane Charges", :amount="order.payment.selflane.total")
  Item(title="Total", :amount="order.payment.total", :required="true")
  .sl-dash-divider.my-2
  .body-2
    .d-flex.flex-row.subtitle-2
      div Payments
      v-spacer
      div Total Paid: {{ total_paid | currency }}
    div(v-if="order.splitChecks && order.splitChecks.length > 0")
      div(v-for="(item, index) in order.splitChecks", :key="index")
        span.body-2 Check \#{{ index + 1 }}: {{ item.payment.total | currency }}
        Charges(:payment="item.payment")
        PayOrder(:order="order", :checkId="item._id")
    div(v-else)
      Charges(:payment="order.payment", @refund="refund")
      PayOrder(:order="order")
  p.green--text(v-if="order.note") {{ order.note }}
  v-btn.mt-3(
    v-if="isOnline && order.status == 0",
    @click="confirm()",
    block,
    color="secondary"
  ) Confirm Order
  v-alert.text-center.mt-3(dark, color="success", v-model="confirmed", dense) Confirmed Order. Thanks!
  .caption.mt-2(v-if="isOnline") Need to cancel this order and refund customer? Please go to
    router-link.ml-1(to="/sales/orders") Sales/Orders
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Charges from "./Charges";
import Item from "./Item";
import PayOrder from "./PayOrder";

export default {
  components: { Charges, Item, PayOrder },
  props: ["order"],
  computed: {
    isOnline() {
      return this.order?.orderer?.type == 0;
    },
    confirmed() {
      return this.isOnline && this.order && this.order.status > 0;
    },
    service_items() {
      return this.order.payment?.service?.items;
    },
    total_paid() {
      let total = 0;
      if (this.order.payment.charge?.amount) {
        total += this.order.payment.charge.amount;
      }
      this.order.payment.charges.forEach((charge) => {
        total += charge.amount;
      });
      this.order.splitChecks.forEach((check) => {
        check.payment.charges.forEach((charge) => {
          total += charge.amount;
        });
      });
      return total;
    },
  },
  methods: {
    async confirm() {
      if (!this.isOnline || !this.order.status == 0) return;
      const params = { orderId: this.order._id };
      try {
        const { data } = await this.axios.post(
          "/orders/status/confirm",
          params
        );
        this.$emit("update", data);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async refund(item) {
      EventBus.$emit("void-order-charge", { order: this.order, charge: item });
    },
  },
};
</script>

<style lang="sass" scoped>
.payment
  padding-top: 10px
  padding-left: 30px
  padding-right: 30px
  padding-bottom: 20px

.item
  display: flex
  align-items: center

  &-name
    font-size: 15px
    flex-grow: 1

  &-detail
    font-size: 15px
    font-weight: 500
</style>