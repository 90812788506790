<template lang="pug">
simple-table
  thead
    tr
      th Charge
      th #
      th Amount
      th Fee
      th.text-right Balance
  tbody
    tr(v-for="(item, index) in charges", :key="index")
      td {{ item.method }}
      td {{ item.count }}
      td {{ item.total | currency }}
      td {{ item.fee | currency }}
      td.text-right {{ item.balance | currency }}
  tfoot
    tr
      th Total
      th {{ count }}
      th {{ total | currency }}
      td {{ fee | currency }}
      td.text-right {{ balance | currency }}
</template>

<script>
export default {
  props: ["charges"],
  computed: {
    count() {
      return this.charges?.reduce((a, b) => a + b.count, 0);
    },
    total() {
      return this.charges?.reduce((a, b) => a + b.total, 0);
    },
    fee() {
      return this.charges?.reduce((a, b) => a + b.fee, 0);
    },
    balance() {
      return this.charges?.reduce((a, b) => a + b.balance, 0);
    },
  },
};
</script>