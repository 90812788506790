<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-form(@submit.prevent="handleSave")
    v-card(v-if="throttle")
      v-toolbar(flat, dense)
        .subtitle-2 Edit Throttle | {{ this.throttle.day | day }} | {{ this.throttle.hour }}
        v-spacer
        DeleteOne(:throttle="throttle", @deleted="dialog = false")
      v-card-text
        v-text-field(
          label="Maximum Number of Orders",
          v-model.number="throttle.max_order",
          type="number"
        )
        v-text-field(
          label="Maximum Number of Items",
          v-model.number="throttle.max_item",
          type="number"
        )
      v-card-actions
        v-btn(color="secondary", block, type="submit", :loading="loading") Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus";
import DeleteOne from "./DeleteOne";

export default {
  components: { DeleteOne },
  data() {
    return {
      dialog: false,
      throttle: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz", "biz_throttles"]),
  },
  mounted() {
    EventBus.$on("edit-biz-throttle", this.open);
  },
  destroyed() {
    EventBus.$off("edit-biz-throttle", this.open);
  },
  methods: {
    ...mapActions(["addBizThrottle", "updateBizThrottle", "removeBizThrottle"]),
    open(day, hour) {
      if (!this.biz) return;
      const found = this.biz_throttles.find((o) => {
        return o.day == day && o.hour == hour;
      });
      if (found) {
        this.throttle = JSON.parse(JSON.stringify(found));
      } else {
        this.throttle = {
          biz: this.biz._id,
          day,
          hour,
          max_order: 100,
          max_item: 500,
        };
      }
      this.dialog = true;
    },
    handleSave() {
      if (!this.throttle) return;
      if (this.throttle.max_item < 5 || this.throttle.max_order < 5) {
        this.$toast.error("Must be greater than 5");
        return;
      }
      if (this.throttle._id) this.update();
      else this.create();
    },
    async create() {
      this.loading = true;
      try {
        const result = await this.$api.b.throttle.create(this.throttle);
        this.addBizThrottle(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.response);
      }
      this.loading = false;
    },
    async update() {
      const action = {
        $set: {
          max_order: this.throttle.max_order,
          max_item: this.throttle.max_item,
        },
      };
      this.loading = true;
      try {
        const result = await this.$api.b.throttle.put(
          this.throttle._id,
          action
        );
        this.updateBizThrottle(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.response);
      }
      this.loading = false;
    },
  },
};
</script>