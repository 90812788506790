<template lang="pug">
IconBtn(@click="open", title="Add", icon="mdi-plus")
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  methods: {
    open() {
      EventBus.$emit("edit-weekly-special");
    },
  },
};
</script>
