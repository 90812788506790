<template lang="pug">
simple-table(v-if="report")
  thead
    tr
      th Role
      th REG Hrs
      th OT Hrs
      th.text-right(v-if="showWage") Pay
  tbody
    tr(v-for="(item, index) in report.roles", :key="index")
      td {{ item.name }}
      td {{ item.regHours | hours(isDecimal) }}
      td {{ item.extHours | hours(isDecimal) }}
      td.text-right(v-if="showWage") {{ item.cost | currency }}
  tfoot
    tr
      th Total
      th {{ report.regHours | hours(isDecimal) }}
      th {{ report.extHours | hours(isDecimal) }}
      th.text-right(v-if="showWage") {{ report.cost | currency }}
    tr(v-if="tip")
      th Total Tip
      th
      th(v-if="showWage")
      th.text-right {{ tip | currency }}
</template>

<script>
export default {
  props: {
    report: { required: true },
    tip: { type: Number, default: 0 }, // total tips over the report period
    isDecimal: { type: Boolean, default: false },
    showWage: { type: Boolean, default: true },
  },
};
</script>
