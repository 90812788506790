import * as filters from '@selflane/filters';
import VueAuth from '@websanova/vue-auth';
import axios from 'axios';
import VueMask from 'v-mask';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueClipboard from 'vue-clipboard2';
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import VueSocialSharing from 'vue-social-sharing';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import VueYouTubeEmbed from 'vue-youtube-embed';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '/libs/plugins/vuetify';
const baseURL = process.env.VUE_APP_MAIN_API
// markdown editor
import VMdEditor from '@kangc/v-md-editor';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
// highlightjs
import hljs from 'highlight.js';
import './../libs/style/main.sass';
import RegisterComponents from './main-components';
import '/libs/plugins/mixins';

Vue.use(VueMask);
Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = baseURL
Vue.axios.defaults.withCredentials = true

VMdEditor.use(githubTheme, { Hljs: hljs });
VMdEditor.lang.use('en-US', enUS);
VMdPreview.use(githubTheme, { Hljs: hljs });
Vue.use(VMdEditor);
Vue.use(VMdPreview);

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(Croppa)
Vue.use(VueGtag, {
  config: { id: 'UA-111753270-3', enabled: process.env.NODE_ENV === 'production' },
}, router)
Vue.use(VueYouTubeEmbed)
Vue.use(VueSocialSharing);
Vue.use(VueToast);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false
Vue.router = router
Vue.use(VueAuth, {
  auth: {
    request: function (req, token) {
      this.options.http._setHeaders.call(this, req, {
        Authorization: 'Bearer ' + token
      })
    },
    response: function () { return 'selflane.com' }
  },
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  loginData: {
    url: '/users/login',
    fetchUser: true
  },
  logoutData: {
    url: '/users/logout',
    method: 'get',
    redirect: '/',
    makeRequest: true
  },
  fetchData: {
    url: '/users/get',
    method: 'GET',
    enabled: true
  },
  rememberMe: true,
  refreshData: { enabled: false },
  parseUserData: (data) => { return data },
  googleOauth2Data: {
    params: { redirect_uri: baseURL + '/auth/google/callback' },
    clientId: '533775739257-rt4hjb2mvbpdhsiaskh207n97ou4mv0p.apps.googleusercontent.com'
  }
})

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

RegisterComponents(Vue)

window.axios = Vue.axios

// a clean http instance for 3rd party (googleaip) calls
const axios2 = axios.create({
  withCredentials: false
})
window.axios2 = axios2

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')