<template lang="pug">
v-card-text
  .my-5(v-html="description")
  v-dialog(v-model="dialog", max-width="500px")
    template(v-slot:activator="{ on }")
      v-btn(color="secondary", text, v-on="on", block, @click="open") Make a Request
    v-card(:loading="listing")
      v-card-title Request {{ title }}
      v-card-text(v-if="match")
        div Request has been made at {{ match.created | datetime }}. The status is {{ match.status }}.
        .subtitle-2.my-3 {{ match.comment }}
        div Please wait for our response.
      v-card-text(v-else-if="!listing")
        v-form(@submit.prevent="submit")
          v-textarea(
            v-model="message",
            label="Message",
            rows="2",
            :auto-grow="true",
            :counter="300"
          )
          v-btn(block, color="secondary", type="submit", :loading="loading") Submit
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
export default {
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
  },
  data() {
    return {
      dialog: false,
      message: "",
      requests: [],
      listing: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    match() {
      return this.requests.find((r) => r.title == this.title);
    },
  },
  methods: {
    async open() {
      if (!this.biz) return;
      this.message = "";
      this.loading = true;
      const begin = moment().add(-1, "year").valueOf();
      const criteria = { biz: this.biz._id, created: { $gte: begin } };
      this.requests = await this.$api.b.request.list({ criteria });
      this.loading = false;
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      const params = {
        biz: this.biz._id,
        title: this.title,
        message: this.message,
      };
      try {
        const result = await this.$api.b.request.create(params);
        this.$toast.success("Request is made.");
        this.requests.push(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>