<template lang="pug">
div
  .d-flex.flex-row.align-center.justify-space-between.mb-3
    BizStartHour
    DaySelector(@select="load")
  OrderTable.my-5
  .d-flex.flex-row.my-5
    Download.mr-3
    OrderPaymentList
</template>
<script>
import OrderTable from "@/components/Order/Table/index";
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import Download from "./Download";
import OrderPaymentList from "./OrderPaymentList/index";

export default {
  components: { OrderTable, OrderPaymentList, Download },
  data() {
    return {
      loading: false,
      date: null,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setOrders"]),
    async load(date) {
      this.setOrders([]);
      if (date) {
        this.date = moment(date).format("YYYY-MM-DD");
      }
      if (!this.biz?._id || !this.date) return;
      this.loading = true;
      const params = { bizId: this.biz._id, date: this.date };
      try {
        const result = await this.$api.order.custom("listByDay", params);
        this.setOrders(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
