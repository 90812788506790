<template lang="pug">
.s-vstack
  .link-box
    v-text-field(
      v-model="search",
      label="Search",
      clearable,
      prepend-inner-icon="search",
      hide-details,
      solo,
      dense,
      style="max-width: 200px"
    )
    v-spacer
    MultiForm(:courseId="courseId")
    BulkEdit(:courseId="courseId")
  .d-flex.flex-row.flex-wrap
    v-btn.mb-1.mr-1(
      color="red",
      dark,
      depressed,
      rounded,
      small,
      v-if="nadishes && nadishes.length",
      @click="selectCourse(null)"
    ) N/A
    v-btn.mb-1.mr-1(
      v-for="(item, index) in buttons",
      :key="index",
      depressed,
      rounded,
      dark,
      small,
      :color="item.color",
      @click="selectCourse(item.value)",
      style="text-transform: none"
    ) {{ item.text }}
  v-card(flat)
    simple-table
      thead
        tr
          th Name
          th Limit
          th Image
          th Price
          th Menus
          th Print
          th Tags
          th.text-right Actions
      tbody
        tr(
          v-for="(item, index) in dishes",
          :key="index",
          @click="edit(item)",
          role="button",
          draggable="true",
          @dragstart="drag(index, $event)",
          @dragend="dragend($event)",
          @drop="drop(index, $event)",
          @dragover.prevent=""
        )
          th
            DishName(:item="item")
          th
            DishLimit(:item="item", :biz="biz", :itemCounts="itemCounts")
          td
            DishPicture(:item="item")
          td
            DishPrice(:item="item")
          td
            DishMenus(:item="item")
          td
            DishPrint(:item="item")
          td
            DishTags(:dish="item")
          td.text-right
            .d-flex.flex-row.justify-end
              v-btn(
                icon,
                small,
                @click.stop="editRecipe(item)",
                title="Edit recipe"
              )
                v-icon(small) mdi-chef-hat
              v-btn(
                icon,
                small,
                @click.stop="sortModifiers(item)",
                title="sort modifiers"
              )
                v-icon(small, v-if="item.modifierIDs.length > 0") mdi-format-list-bulleted
                v-icon(small, v-else) mdi-shape-oval-plus
              v-btn(icon, small, @click.stop="clone(item)", title="duplicate")
                v-icon(small, color="grey darken-2") mdi-content-copy
              ToggleOnline(:item="item")
              ToggleThirdParty(:item="item")
              v-btn(icon, small, @click.stop="handleRemove(item)")
                v-icon(small, color="red") mdi-delete
  v-row.py-5(justify="center")
    PrintingGroups
    DishMarkers
    Download
  .body-2 Dishes with price of $0 would only show in POS but not to online customers
  EditDish(ref="editDish")
  SortModifiers(ref="sortModifiers")
  EditRecipe(ref="editRecipe")
  ModifierForm
</template>

<script>
import DishName from "./DishName";
import DishLimit from "./DishLimit";
import DishPicture from "./DishPicture";
import DishPrice from "./DishPrice";
import DishMenus from "./DishMenus";
import DishPrint from "./DishPrint/index";
import EditDish from "./EditDish";
import MultiForm from "./MultiDishForm";
import DishMarkers from "./DishMarkers";
import PrintingGroups from "./PrintingGroups/index";
import SortModifiers from "./SortModifiers";
import EditRecipe from "./EditRecipe/index";
import ToggleOnline from "./ToggleOnline";
import ToggleThirdParty from "./ToggleThirdParty";
import ModifierForm from "./../Modifiers/Form";
import BulkEdit from "./BulkEdit/index.vue";
import Download from "./Download";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: {
    DishName,
    DishLimit,
    DishPicture,
    DishPrice,
    DishMenus,
    DishPrint,
    EditDish,
    MultiForm,
    DishMarkers,
    PrintingGroups,
    SortModifiers,
    EditRecipe,
    ToggleOnline,
    ToggleThirdParty,
    ModifierForm,
    Download,
    BulkEdit,
  },
  data() {
    return {
      search: "",
      buttons: [],
      courseId: null,
      courseIdBeforeSearch: null,
      draggingIndex: null,
      itemCounts: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    nadishes() {
      return this.biz.dishes.filter((o) => {
        if (!o.course) return true;
        return !this.biz.courses.some((c) => c._id === o.course);
      });
    },
    dishes() {
      if (!this.courseId && !this.search) return this.nadishes;
      if (this.search) {
        const regex = RegExp(this.search, "i");
        return this.biz.dishes.filter(
          (o) => regex.test(o.name) || regex.test(o.abbr)
        );
      }
      return this.biz.dishes.filter((o) => o.course == this.courseId);
    },
  },
  mounted() {
    this.loadBiz();
    this.loadModifiers();
    this.loadMenuItemLimits();
    this.loadMenuItemCounts();
    EventBus.$on("switch-biz", this.inReloadBiz);
    EventBus.$on("modifier-edited", this.loadBiz);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.inReloadBiz);
    EventBus.$off("modifier-edited", this.loadBiz);
  },
  methods: {
    ...mapActions(["setBizmodifiers", "setMenuItemLimits"]),
    inReloadBiz() {
      // actions after reload biz
      this.setButtons();
      this.loadModifiers();
      this.loadMenuItemLimits();
      this.loadMenuItemCounts();
    },
    setButtons() {
      if (!this.biz) return [];
      const data = this.biz.courses.map((o) => {
        return { text: o.name, value: o._id, color: "" };
      });
      this.buttons = data;
      if (!data || !data.length) return;
      if (this.courseId) {
        const some = data.some((o) => o.value == this.courseId);
        if (!some) this.courseId = data[0].value;
      } else {
        this.courseId = data[0].value;
      }
      this.selectCourse(this.courseId);
    },
    selectCourse(id) {
      this.courseId = id;
      if (id) this.search = null;
      this.buttons = this.buttons.map((o) => {
        if (o.value == id) {
          o.color = "accent";
        } else {
          o.color = "grey darken-1";
        }
        return o;
      });
    },
    editRecipe(item) {
      this.$refs.editRecipe.open(item);
    },
    sortModifiers(item) {
      this.$refs.sortModifiers.open(item);
    },
    edit(item) {
      this.$refs.editDish.open(item);
    },
    clone(item) {
      const clone = JSON.parse(JSON.stringify(item));
      delete clone._id;
      this.$refs.editDish.open(clone);
    },
    handleRemove(item) {
      confirm("Do you want to delete this dish?") && this.remove(item);
    },
    async remove(item) {
      if (!item?._id || !this.biz) return;
      const action = { $pull: { dishes: { _id: item._id } } };
      const result = await this.$api.biz.put(this.biz._id, action);
      this.$store.dispatch("setBiz", result);
    },
    async loadBiz() {
      if (!this.biz?._id) return;
      const result = await this.$api.biz.retrieve({
        criteria: { _id: this.biz._id },
      });
      this.$store.dispatch("setBiz", result);
      this.setButtons();
    },
    async loadMenuItemCounts() {
      if (!this.biz || !this.biz._id) return;
      // today in YYYYMMDD
      const date = new Date()
        .toISOString()
        .replace(/T.+/, "")
        .replace(/-/g, "");
      const params = { criteria: { biz: this.biz._id, date } };
      this.itemCounts = await this.$api.menu.itemCount.list(params);
    },
    async loadModifiers() {
      if (!this.biz || !this.biz._id) return;
      const params = { criteria: { bizId: this.biz._id } };
      const result = await this.$api.bizModifier.list(params);
      this.setBizmodifiers(result);
    },
    async loadMenuItemLimits() {
      if (!this.biz || !this.biz._id) return;
      const params = { criteria: { biz: this.biz._id } };
      const result = await this.$api.menu.itemLimit.list(params);
      this.setMenuItemLimits(result);
    },
    /// arrange menu
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: async function (index) {
      if (index == this.draggingIndex) return;
      if (!this.biz || !this.dishes) return;
      if (index < 0 || index >= this.dishes.length) return;
      if (this.draggingIndex < 0 || this.draggingIndex >= this.dishes.length)
        return;
      const fromDish = JSON.parse(
        JSON.stringify(this.dishes[this.draggingIndex])
      );
      const toDish = this.dishes[index];
      let dishes = JSON.parse(JSON.stringify(this.biz.dishes));
      let fromIndex = dishes.findIndex((o) => o._id == fromDish._id);
      let toIndex = dishes.findIndex((o) => o._id == toDish._id);
      if (fromIndex == toIndex) return;
      dishes.splice(fromIndex, 1);
      dishes.splice(toIndex, 0, fromDish);
      const action = { $set: { dishes: dishes } };
      const data = await this.$api.biz.put(this.biz._id, action);
      this.$store.dispatch("setBiz", data);
    },
  },
};
</script>

<style lang="sass" scoped>
table.simple-table
  td
    max-width: 500px !important // more space for dish prints
</style>
