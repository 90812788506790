import Payments from './index.vue'
import Home from './Home/index.vue'
import Online from '@/components/Performance/SalesOnline'
import Terminal from './Terminal/index'
import Settings from './Settings/index'

export default [
  {
    path: '/payments', component: Payments, meta: { auth: true },
    children: [
      { path: '', redirect: 'home' },
      { path: 'home', component: Home },
      { path: 'online', component: Online },
      { path: 'terminal', component: Terminal },
      { path: 'settings', component: Settings }
    ]
  }
]