<template lang="pug">
v-btn(@click="download()", color="secondary", outlined, rounded, small)
  span PDF
  v-icon(right, small) mdi-printer
</template>

<script>
import jsPDF from "jspdf";
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  props: ["week", "items"],
  computed: {
    ...mapGetters(["biz"]),
    header() {
      return [this.week, "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    },
  },
  methods: {
    download() {
      if (!this.week || !this.items || !this.biz) return;
      const minutesToTime = this.$options.filters.minutesToTime;
      const bizName = this.biz.name;
      let doc = new jsPDF({
        orientation: "landscape",
        format: "letter",
      });
      let x = 10;
      let y = 13;
      let pageWidth = doc.internal.pageSize.width;
      let pageHeight = doc.internal.pageSize.height;
      let cellWidth = (pageWidth - 20) / 8;

      // add title
      doc.setFontSize(12);
      doc.text("Weekly Schedule: " + bizName, x, y);
      nextLine(5);
      addLine(8);
      addRow(this.header);
      doc.setFontSize(10);
      _.each(this.items, (item) => {
        const name = item.name.trim().slice(0, 13);
        const mon = formatDaySchedule(item.mon);
        const tue = formatDaySchedule(item.tue);
        const wed = formatDaySchedule(item.wed);
        const thu = formatDaySchedule(item.thu);
        const fri = formatDaySchedule(item.fri);
        const sat = formatDaySchedule(item.sat);
        const sun = formatDaySchedule(item.sun);
        addRow([name, mon, tue, wed, thu, fri, sat, sun]);
      });
      doc.save("weekly_schedule.pdf");

      function addRow(list) {
        if (list.length != 8) return;
        const cellSpace = 12;
        if (y + 10 > pageHeight) {
          doc.addPage();
          y = 13;
        }
        let count = 1;
        list.forEach((item) => {
          doc.text(item, x, y);
          x += cellWidth;
          if (Array.isArray(item) && item.length > count) count = item.length;
        });
        if (count > 1) nextLine(cellSpace + (count - 1) * 3);
        else nextLine(cellSpace);
      }
      function formatDaySchedule(data) {
        const items = data.map((o) => {
          return minutesToTime(o.startHH) + "-" + minutesToTime(o.endHH);
        });
        return items;
      }
      function addLine(height) {
        doc.line(0, y, pageWidth, y);
        nextLine(height);
      }
      function nextLine(height) {
        if (height) y += height;
        else y += 10;
        x = 10;
      }
    },
  },
};
</script>
