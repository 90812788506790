<template lang="pug">
.pa-3.s-vstack(v-if="visible")
  CreateAccount(v-if="!accountId")
  UpdateAccount(v-if="show_update")
  TurnOnOnline(v-if="open_online")
</template>

<script>
import CreateAccount from "@/components/Owner/Payments/Settings/Stripe/Account/CreateAccount";
import UpdateAccount from "@/components/Owner/Payments/Settings/Stripe/Account/UpdateAccount";
import { mapGetters } from "vuex";
import TurnOnOnline from "./TurnOnOnline";

export default {
  components: { CreateAccount, UpdateAccount, TurnOnOnline },
  computed: {
    ...mapGetters(["navState", "biz", "useraccess"]),
    isOwner() {
      // if user email ends with selflane.com
      // or is business owner
      const user = this.$auth.user();
      if (!user) return false;
      if (user.email?.endsWith("@selflane.com")) return true;
      return this.useraccess.some(
        (o) => o.user == user._id && o.biz == this.biz._id && o.privilege == 1
      );
    },
    isNoOnline() {
      return (
        this.biz?.standing == "test" ||
        this.biz?.standing == "permanently_closed" ||
        this.biz?.no_online == true
      );
    },
    isOnline() {
      return this.biz?.status?.byAdmin == 1;
    },
    accountId() {
      return this.biz?.payments?.managed_account;
    },
    charges_enabled() {
      return this.biz?.payments?.charges_enabled;
    },
    payouts_enabled() {
      return this.biz?.payments?.payouts_enabled;
    },
    current_deadline() {
      return this.biz?.payments?.current_deadline;
    },
    show_update() {
      if (!this.isOwner) return false;
      if (!this.accountId) return false;
      if (this.current_deadline) return true;
      return false;
    },
    open_online() {
      if (!this.isOwner) return false;
      if (this.isNoOnline) return false;
      if (!this.charges_enabled) return false;
      if (this.isOnline) {
        const orderType = this.biz?.orderType;
        if (orderType?.pickup?.status) return false;
        if (orderType?.delivery?.status) return false;
        if (orderType?.curbside?.status) return false;
        if (orderType?.gc?.status) return false;
        return true;
      }
      return true;
    },
    visible() {
      if (this.navState != "biz") return false;
      if (this.isNoOnline) return false;
      if (!this.isOwner) return false;
      if (this.$route.fullPath == "/payments/settings") return false;
      if (!this.accountId) return true;
      if (this.show_update) return true;
      if (this.open_online) return true;
      return false;
    },
  },
};
</script>