import { render, staticRenderFns } from "./cell.vue?vue&type=template&id=2cb349f3&scoped=true&lang=pug"
import script from "./cell.vue?vue&type=script&lang=js"
export * from "./cell.vue?vue&type=script&lang=js"
import style0 from "./cell.vue?vue&type=style&index=0&id=2cb349f3&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb349f3",
  null
  
)

export default component.exports