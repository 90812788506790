<template lang="pug">
IconBtn(@click="download()", title="JSON", icon="mdi-download")
</template>

<script>
import { mapGetters } from "vuex";
import FileSaver from "file-saver";

export default {
  computed: {
    ...mapGetters(["orders"]),
  },
  methods: {
    download() {
      const data = JSON.stringify(this.orders);
      const blob = new Blob([data], { type: "application/json" });
      try {
        FileSaver.saveAs(blob, "order-data.json");
      } catch (e) {
        alert(e);
      }
    },
  },
};
</script>
