// show first clock in and last clock out
<template lang="pug">
.my-5.body-2
  div(v-if="firstPerson") First Clock In: {{ firstPerson.clockIn | time(timezone) }} <b>{{ firstPerson.serverName }}</b>
  div(v-if="lastPerson") Last Clock Out: {{ lastPerson.clockOut | time(timezone) }} <b>{{ lastPerson.serverName }}</b>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["punchcards", "biz"]),
    timezone() {
      return this.biz?.address?.timezone;
    },
    firstPerson() {
      return _.chain(this.punchcards)
        .reject((o) => !o.status)
        .reject((o) => !o.clockIn)
        .sortBy((o) => o.clockIn)
        .first()
        .value();
    },
    lastPerson() {
      return _.chain(this.punchcards)
        .reject((o) => !o.status)
        .reject((o) => !o.clockOut)
        .sortBy((o) => o.clockOut)
        .last()
        .value();
    },
  },
};
</script>