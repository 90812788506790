<template lang="pug">
v-card(flat)
  v-toolbar(dense, flat)
    .subtitle-2 Suffixes
    v-spacer
    IconBtn(@click="edit()", title="Add", icon="mdi-plus")
  simple-table(v-if="matrixmodifier")
    thead
      tr
        th Group
        th Choices
        th
    tbody
      tr(
        v-for="(item, index) in matrixmodifier.suffixes",
        :key="index",
        draggable="true",
        @click="edit(item)",
        role="button",
        @dragstart="drag(index, $event)",
        @dragend="dragend($event)",
        @drop="drop(index, $event)",
        @dragover.prevent=""
      )
        th
          v-icon.mr-2(small) mdi-menu
          span {{ item.title }}
        td(style="white-space: normal") {{ getItems(item.items) }}
        td.text-right
          v-btn(icon, color="error", @click.stop="handleRemove(item)")
            v-icon(small) mdi-delete
  Form
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Form from "./SuffixesForm";
import _ from "underscore";

export default {
  components: { Form },
  data() {
    return {
      draggingIndex: null,
    };
  },
  computed: {
    ...mapGetters(["matrixmodifier", "biz"]),
  },
  methods: {
    ...mapActions(["setMatrixModifier"]),
    getItems(items) {
      return _.map(items, (o) => {
        let name = o.name;
        if (o.kName) name += " (" + o.kName + ")";
        if (o.price != 0) return name + " $" + o.price;
        return name;
      }).join(", ");
    },
    edit(input) {
      let data = { title: "", items: [] };
      if (input) data = input;
      EventBus.$emit("edit-matrix-modifier-suffixes", data);
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: async function (index) {
      if (index == this.draggingIndex) return;
      if (!this.matrixmodifier || !this.matrixmodifier.suffixes) return;
      if (index < 0 || index >= this.matrixmodifier.suffixes.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.matrixmodifier.suffixes.length
      )
        return;
      let suffixes = JSON.parse(JSON.stringify(this.matrixmodifier.suffixes));
      let tmp = JSON.parse(JSON.stringify(suffixes[this.draggingIndex]));
      suffixes.splice(this.draggingIndex, 1);
      suffixes.splice(index, 0, tmp);
      const params = {
        criteria: { _id: this.biz._id },
        action: { $set: { suffixes: suffixes } },
      };
      const result = await this.$api.matrixModifier.update(params);
      this.setMatrixModifier(result);
    },
    handleRemove(item) {
      confirm("Do you want to remove this item?") && this.remove(item);
    },
    async remove(item) {
      const params = {
        criteria: { _id: this.biz._id },
        action: { $pull: { suffixes: { _id: item._id } } },
      };
      const result = await this.$api.matrixModifier.update(params);
      this.setMatrixModifier(result);
    },
  },
};
</script>
