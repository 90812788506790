<template lang="pug">
PieChart(:chartData="pieData", v-if="items && items.length")
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      colors: [
        "#7682d6",
        "#2cb2b9",
        "#50a948",
        "#ad4d24",
        "#a74ac3",
        "#c5d729",
      ],
    };
  },
  computed: {
    pieData() {
      if (!this.items) return {};
      return {
        labels: this.items.map(
          (o) => `${o.name} (${this.$options.filters.currency(o.total)})`
        ),
        datasets: [
          {
            backgroundColor: this.colors,
            data: this.items.map((o) => o.total),
          },
        ],
      };
    },
  },
};
</script>