<template lang="pug">
div
  .round-picture(v-if="hasPhoto")
    v-img(:src="mediaHost + memberpromo.picture")
    .round-picture-remove
      v-icon(color="red", small, @click="remove()") mdi-delete
  div(v-else)
    croppa(
      v-model="myCroppa",
      :width="560",
      :height="280",
      :quality="2",
      canvas-color="white",
      placeholder="Set Main Image"
    )
    v-btn(@click="upload()", block, color="secondary", :loading="loading") Upload Image
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      loading: false,
      myCroppa: {},
    };
  },
  computed: {
    ...mapGetters(["memberpromo"]),
    hasPhoto() {
      return (
        this.memberpromo &&
        this.memberpromo.picture &&
        this.memberpromo.picture.length > 0
      );
    },
  },
  methods: {
    ...mapActions(["setMemberPromo"]),
    async remove() {
      if (!this.memberpromo?._id) return;
      const photo = this.memberpromo.picture;
      const options = {
        criteria: { _id: this.memberpromo._id },
        action: { $unset: { picture: "" } },
      };
      const result = await this.$api.memberPromo.update(options);
      this.setMemberPromo(result);
      await this.$api.picture.delete({ imagePath: photo });
    },
    upload() {
      if (!this.memberpromo?._id) return;
      const path = this.memberpromo.biz + "-" + this.memberpromo._id;

      this.loading = true;
      this.myCroppa.generateBlob(
        (blob) => {
          if (!blob) {
            this.loading = false;
            this.myCroppa.chooseFile();
            return;
          }
          const fd = new FormData();
          fd.append("file", blob);
          fd.append("path", "public/img/biz/memberpromo-photo/" + path);
          fd.append("acl", "public-read");
          fd.append("name", "upload.jpeg");
          this.$api.picture.create(fd).then((result) => {
            this.myCroppa.remove();
            const files = result.files;
            if (!files?.length) return;
            const params = {
              criteria: { _id: this.memberpromo._id },
              action: { $set: { picture: files[0] } },
            };
            this.$api.memberPromo.update(params).then((member) => {
              setTimeout(() => {
                this.loading = false;
                this.setMemberPromo(member);
              }, 3000);
            });
          });
        },
        "image/jpeg",
        0.8
      );
    },
  },
};
</script>
