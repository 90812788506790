import Main from './index.vue'
import Dashboard from './performance/dashboard'
import admin from './admin/route'
import integration from './integration/route'

export default [
  { path: '/enterprise', component: Main, meta: { auth: true } },
  { path: '/enterprise/performance/dashboard', component: Dashboard, meta: { auth: true } },
  ...admin,
  ...integration,
]