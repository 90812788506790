<template lang="pug">
v-dialog(v-model="dialog", :width="width")
  v-card
    v-card-title
      span {{ title }}
    v-img(:src="photo", contain)
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      width: 500,
      title: "",
      photo: "",
    };
  },
  mounted() {
    EventBus.$on("open-photo-dialog", this.open);
  },
  destroyed() {
    EventBus.$off("open-photo-dialog", this.open);
  },
  methods: {
    open({ width, title, photo }) {
      this.width = width || 500;
      this.title = title || "";
      this.photo = photo || "";
      if (this.photo) {
        this.dialog = true;
      }
    },
  },
};
</script>