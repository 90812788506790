<template lang="pug">
IconBtn(@click="download()", title="PDF", icon="mdi-download")
</template>

<script>
import jsPDF from "jspdf";
import moment from "moment-timezone";
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  name: "ServerReportDownload",
  props: ["items", "isDecimal", "dates"],
  computed: {
    ...mapGetters(["biz"]),
    header() {
      return [
        "Name",
        "EMPL. ID",
        "Week",
        "Role",
        "REG Hrs",
        "OT Hrs",
        "REG Pay (Hr)",
        "OT Pay (Hr)",
        "Total",
      ];
    },
  },
  methods: {
    loadAllPunchcards() {
      this.$emit("load-all-punchcards");
    },
    download() {
      let doc = new jsPDF({ orientation: "landscape", format: "letter" });
      let x = 10;
      let y = 13;
      const firstCellWidth = 45;
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const cellWidth = (pageWidth - 20 - firstCellWidth) / 8;
      // add title
      doc.setFontSize(12);
      doc.text(this.biz.name, x, y);
      nextLine();
      if (this.biz.short_name) {
        doc.text(this.biz.short_name, x, y);
      }
      nextLine();
      doc.text(moment().format("M/D/YYYY hh:mm A"), x, y);
      nextLine();
      doc.text(this.dates, x, y);
      nextLine(4);
      addLine(8);
      doc.setFontSize(10);
      addRow(this.header);
      doc.setFontSize(9);
      const rows = this.getRows();
      _.each(rows, (o) => {
        if (o && o[0] && o[0].length > 1) {
          addLine();
        }
        addRow(o);
      });
      addLine();

      doc.save("payroll.pdf");

      function addRow(list) {
        for (var i = 0; i < list.length; i++) {
          const item = list[i] || "";
          doc.text(item, x, y);
          if (i == 0) x += firstCellWidth;
          else x += cellWidth;
        }
        nextLine();
      }
      function addLine(height) {
        doc.line(10, y, pageWidth - 20, y);
        nextLine(height);
      }
      function nextLine(height) {
        if (height) y += height;
        else y += 5;
        x = 10;
        if (y + 10 > pageHeight) {
          doc.addPage();
          y = 13;
        }
      }
    },
    getRows() {
      const isDecimal = this.isDecimal || false;
      const filters = this.$options.filters;
      let rows = [];
      _.each(this.items, (o) => {
        _.each(o.weekly, (week) => {
          week.itemsByRole.forEach((item, index) => {
            let prefix = ["", ""];
            if (index == 0) prefix = [o.name.slice(0, 20), o.employeeID];
            rows.push(prefix.concat(this.getWeekItems(item, week.week)));
          });
        });
        rows.push([
          "",
          "",
          "Total",
          "",
          filters.hours(o.regHours, isDecimal),
          filters.hours(o.extHours, isDecimal),
          filters.currency(o.regCost),
          filters.currency(o.extCost),
          filters.currency(o.cost),
        ]);
        if (o.tip) {
          rows.push([
            "",
            "",
            "CC Tip",
            "",
            "",
            "",
            "",
            filters.currency(o.tip),
          ]);
        }
        if (o.cashTip > 0) {
          rows.push([
            "",
            "",
            "Cash Tip",
            "",
            "",
            "",
            "",
            filters.currency(o.cashTip),
          ]);
        }
        const totalPay = o.cost + (o.tip || 0) + (o.cashTip || 0);
        rows.push([
          "",
          "",
          "Total",
          "",
          "",
          "",
          "",
          filters.currency(totalPay),
        ]);
        if (o.sales > 0) {
          const pct = (totalPay / o.sales) * 100;
          rows.push([
            "",
            "",
            "Net Sales",
            filters.currency(o.sales),
            filters.number(pct) + "%",
            "",
            "",
            "",
            "",
          ]);
        }
        _.each(o.tax_items, (item) => {
          rows.push([
            "",
            "",
            item.name,
            item.percentage + "%",
            filters.currency(item.value),
            "",
            "",
            "",
            "",
          ]);
        });
      });
      return rows;
    },
    getWeekItems(item, week) {
      const isDecimal = this.isDecimal || false;
      const filters = this.$options.filters;
      const regCost = this.getPayItem(item.regCost, item.regHours);
      const extCost = this.getPayItem(item.extCost, item.extHours);
      return [
        `Week ${week}`,
        item.name,
        filters.hours(item.regHours, isDecimal),
        filters.hours(item.extHours, isDecimal),
        regCost,
        extCost,
        filters.currency(item.cost),
      ];
    },
    getPayItem(cost, hours) {
      if (cost > 0) {
        return `${this.$options.filters.currency(
          cost
        )} (${this.$options.filters.currency(cost / hours)})`;
      } else {
        return "-";
      }
    },
  },
};
</script>
