<template lang="pug">
v-card(v-if="data", flat)
  Table(:items="items")
  Breakdown(:items="data.breakdown")
  Breakdown(:items="data.typebreakdown")
  Table(:items="detail_items")
</template>

<script>
import _ from "underscore";
import Breakdown from "./../../Report/Sales/Breakdown";
import Table from "./../Table";

export default {
  components: { Breakdown, Table },
  props: ["data"],
  computed: {
    items() {
      return [
        { name: "Net Sales", amount: this.data.net },
        { name: "Tax", amount: this.data.tax },
        { name: "Payment Deduction", amount: -this.data.deduction },
        { name: "Tip", amount: this.data.tip },
        { name: "Cash Discount Adjustment", amount: this.data.adjustment },
        { name: "Fee (in-store)", amount: this.data.fee },
        { name: "Third Party", amount: this.data.third_party },
        { name: "Gift Sold", amount: this.data.gift },
        { name: "Selflane Charges", amount: this.data.selflane },
        {
          name: "Total Receivables",
          amount: this.data.total,
          bold: true,
          required: true,
        },
      ];
    },
    perguest() {
      if (!this.data || !this.data.typebreakdown || this.data.people <= 0)
        return 0;
      const found = this.data.typebreakdown.find((o) => o.name == "dinein");
      if (found) return found.total / this.data.people;
      return 0;
    },
    detail_items() {
      let data = [
        { name: "Guests", amount: this.data.people, filter: "number" },
        { name: "Dine-in Sales/Guest", amount: this.perguest },
      ];
      if (this.tax_items) {
        data = data.concat(this.tax_items);
      }
      return data;
    },
    tax_items() {
      return _.map(this.data.tax_items, (item) => {
        return {
          name: `${item.name} ${item.percentage}%`,
          amount: item.value,
        };
      });
    },
  },
};
</script>
