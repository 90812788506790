<template lang="pug">
v-dialog(v-model="dialog", width="400")
  template(v-slot:activator="{ on }")
    v-btn(color="secondary", outlined, v-on="on", @click="load") Restore Menu
  v-card(:loading="loading")
    v-card-title Menu Backup
    v-card-text
      div We automatically back up your menu every day. You can restore your menu to any previous version. It will make a backup of your current menu and replace it with the selected backup.
      v-alert.my-2(v-if="!loading && !items.length", type="info") No backup found.
    simple-table(v-if="items.length")
      thead
        tr
          th Name
          th Created
          th
      tbody
        tr(v-for="(item, index) in items", :key="index")
          td {{ item.copy_name }}
          td {{ item.createdAt | datetime("smart") }}
          td.text-right
            Restore(:item="item", @restored="load")
</template>

<script>
import { mapGetters } from "vuex";
import Restore from "./Restore";

export default {
  components: { Restore },
  data() {
    return {
      dialog: false,
      loading: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    async load() {
      if (!this.biz) return;
      this.loading = true;
      const criteria = { biz: this.biz._id };
      const select = "copy_name biz createdAt";
      this.dialog = true;
      try {
        this.items = await this.$api.bizBackup.list({ criteria, select });
        // sort from newest to oldest
        this.items.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>