<template lang="pug">
v-card(outlined)
  v-toolbar(flat, dense)
    .subtitle-2 Transfers
    v-spacer
    Download(:items="items", :year="year", :total="total")
  Table(:items="items", :total="total")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Download from "./Download";
import Table from "./Table";

export default {
  components: { Table, Download },
  data() {
    return {
      year: new Date().getFullYear(),
      items: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    total() {
      return this.items.reduce((a, b) => a + b.amount / 100, 0);
    },
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load(year) {
      if (year) this.year = year;
      if (!this.biz) return;
      const begin = new Date(this.year, 0, 1).getTime();
      const end = new Date(this.year + 1, 0, 1).getTime();
      const params = {
        criteria: {
          biz: this.biz._id,
          created: { $gte: begin, $lt: end },
        },
      };
      this.items = await this.$api.bizTransfer.list(params);
    },
  },
};
</script>
