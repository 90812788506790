<template lang="pug">
v-card(v-if="data", flat)
  Table(:items="data")
    template(v-slot:header)
      thead
        tr
          th {{ title }}
          th.text-right
            span(v-if="total") {{ total | currency }}
</template>

<script>
import Table from "./Table";

export default {
  components: { Table },
  props: ["data", "title", "total"],
};
</script>
