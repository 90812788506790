<template lang="pug">
v-dialog(v-model="dialog", width="900")
  template(v-slot:activator="{ on }")
    IconBtn(@click="open()", title="Add", icon="mdi-plus", v-on="on")
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Add Dishes
      v-tooltip(right, v-if="dishes.length > 1")
        template(v-slot:activator="{ on }")
          v-btn(@click.stop="reset", icon, small, color="error", v-on="on")
            v-icon(small) mdi-delete-sweep
        span Clear all items
      v-spacer
      v-btn.text-notransform(outlined, small, @click.stop="more()")
        span More Dish
        v-icon(right, small) mdi-plus
    v-divider
    v-form(@submit.prevent="submit")
      simple-table
        thead
          tr
            th Name
            th Price
            th Menu
            th Course
            th Print
            th
        tbody
          tr(
            v-for="(item, index) in dishes",
            :key="index",
            draggable="true",
            @dragstart="drag(index, $event)",
            @dragend="dragend($event)",
            @drop="drop(index, $event)",
            @dragover.prevent=""
          )
            td
              v-text-field(
                v-model.trim="item.name",
                hide-details,
                @keydown.down="more()",
                single-line,
                dense,
                autofocus,
                ref="focus"
              )
            td(style="width: 100px")
              v-text-field(
                type="number",
                min="0",
                v-model="item.price",
                prefix="$",
                hide-details,
                @keydown.down="handleMore($event)",
                single-line,
                dense
              )
            td(style="width: 140px")
              v-select(
                v-model="item.menus",
                :items="menus",
                multiple,
                dense,
                small-chips,
                deletable-chips,
                hide-details,
                single-line,
                tabindex="-1"
              )
            td(style="width: 140px")
              v-select(
                v-model="item.course",
                :items="courses",
                small-chips,
                dense,
                hide-details,
                menu-props="auto",
                single-line,
                tabindex="-1"
              )
            td(style="width: 140px")
              v-btn-toggle(
                dense,
                v-model="item.prints",
                color="secondary",
                multiple
              )
                v-btn(
                  x-small,
                  @click.stop,
                  v-for="(item, index) in printItems",
                  :key="index",
                  :value="item.value",
                  :title="item.title"
                )
                  v-icon(small) {{ item.icon }}
                  span.ml-1(v-if="item.text") {{ item.text }}
            td
              v-icon(
                tabindex="-1",
                color="red",
                v-if="dishes.length > 1",
                @click.stop="less(index)",
                small
              ) mdi-delete
              v-icon(small) mdi-menu
      v-card-text
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
        .red--text(v-for="(error, index) in errors", :key="index + 'index'") {{ error }}
        .body-2.mt-3 Use <kbd>Down Arrow Key</kbd> on your keyboard to quickly add a new line. It copies the same settings for the last item.
        .body-2.mt-3 You can assign a dish to multiple menus and multiple printers but only to one course.
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  name: "MultiDishForm",
  props: {
    courseId: { type: String, default: null },
  },
  data() {
    return {
      dishes: [],
      dialog: false,
      errors: [],
      loading: false,
      headers: [
        { text: "Name", sortable: false },
        { text: "Price", sortable: false },
        { text: "Menu", sortable: false, width: "130" },
        { text: "Course", sortable: false, width: "100" },
        { text: "Print", sortable: false, width: "100" },
        { text: "", sortable: false, width: "80" },
      ],
      draggingIndex: null,
    };
  },
  computed: {
    ...mapGetters(["biz", "printItems"]),
    courses() {
      return (
        this.biz?.courses?.map((o) => ({
          text: o.name,
          value: o._id,
        })) || []
      );
    },
    menus() {
      return (
        this.biz?.menus?.map((o) => ({
          text: o.name,
          value: o._id,
        })) || []
      );
    },
  },
  methods: {
    open() {
      if (!this.dishes || this.dishes.length <= 1) this.reset();
      this.errors = [];
    },
    reset() {
      this.dishes = [
        { name: "", price: 0, course: this.courseId, menus: [], prints: [] },
      ];
      if (this.biz.menus?.length == 1) {
        _.each(this.dishes, (o) => (o.menus = [this.biz.menus[0]._id]));
      }
    },
    less(index) {
      this.dishes.splice(index, 1);
    },
    handleMore(event) {
      event.preventDefault();
      this.more();
    },
    more() {
      this.errors = [];
      const last = _.last(this.dishes);
      if (last) {
        let clone = JSON.parse(JSON.stringify(last));
        clone.name = "";
        this.dishes.push(clone);
      }
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: function (index) {
      if (!this.dishes) return;
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.dishes.length) return;
      if (this.draggingIndex < 0 || this.draggingIndex >= this.dishes.length)
        return;
      let dishes = JSON.parse(JSON.stringify(this.dishes));
      let tmp = JSON.parse(JSON.stringify(dishes[this.draggingIndex]));
      dishes.splice(this.draggingIndex, 1);
      dishes.splice(index, 0, tmp);
      this.dishes = dishes;
    },
    validate() {
      _.each(this.dishes, (dish) => {
        if (!dish.name || dish.name.length === 0)
          this.errors.push("Name is required");
        else {
          const found = this.biz.dishes.find((o) => {
            return o._id !== dish._id && o.name === dish.name;
          });
          if (found) {
            this.errors.push(
              dish.name + ": a dish with the same name is found"
            );
          }
          if (!dish.menus.length)
            this.errors.push(dish.name + ": assign a menu");
          if (!dish.course) this.errors.push(dish.name + ": assign a course");
          if (dish.price < 0)
            this.errors.push(dish.name + ": need a positive price");
        }
      });
    },
    async submit() {
      this.errors = [];
      /// validate
      if (!this.biz) {
        this.errors = ["Invalid data"];
        return;
      }
      this.validate();
      if (this.errors?.length) return;
      this.loading = true;
      try {
        const action = { $push: { dishes: { $each: this.dishes } } };
        const data = await this.$api.biz.put(this.biz._id, action);
        this.dishes = [];
        this.$store.dispatch("setBiz", data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.response);
      }
      this.loading = false;
    },
  },
};
</script>
