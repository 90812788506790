<template lang="pug">
Page(title="Online Promotion", :items="items", :doc="doc")
</template>

<script>
export default {
  data() {
    return {
      doc: "https://docs.selflane.com/biz/marketing/online.html",
      items: [{ title: "Programs", url: "/marketing/online/programs" }],
    };
  },
};
</script>
