<template lang="pug">
v-card(v-if="promoFirst", width="400", outlined)
  v-toolbar(dense, flat)
    .subtitle-2 Discount for 1st Time Customers
  v-card-text
    v-switch(
      v-model="promoFirst.status",
      color="secondary",
      label="Apply Discount",
      dense
    )
    v-row(v-if="promoFirst.status", dense)
      v-col(cols="6")
        v-select(
          label="Percent Discount",
          v-model="promoFirst.pct",
          :items="perc_items"
        )
      v-col(cols="6")
        v-text-field(
          type="number",
          label="Max Applicable Value",
          hint="should be greater than $50",
          v-model="promoFirst.max",
          prefix="$"
        )
  v-card-actions(v-if="dirty")
    v-btn(block, color="secondary", :loading="loading", @click="submit") Save
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  data() {
    return {
      original: null,
      promoFirst: null,
      loading: false,
      perc_items: _.map(_.range(5, 95, 5), (o) => {
        return {
          text: "-" + o + "%",
          value: o,
        };
      }),
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    dirty() {
      return JSON.stringify(this.promoFirst) !== this.original;
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    load() {
      if (!this.biz) return;
      this.original = JSON.stringify(this.biz.promoFirst);
      this.promoFirst = JSON.parse(this.original);
    },
    async submit() {
      if (!this.biz || !this.promoFirst) return;
      if (this.promoFirst.pct < 5) {
        this.promoFirst.pct = 5;
      } else if (this.promoFirst.pct > 90) {
        this.promoFirst.pct = 90;
      }
      if (this.promoFirst.max < 50) {
        this.promoFirst.max = 50;
      }
      if (this.promoFirst.used) {
        delete this.promoFirst.used;
      }
      this.loading = true;
      const action = { $set: { promoFirst: this.promoFirst } };
      const result = await this.$api.biz.put(this.biz._id, action);
      this.loading = false;
      this.$store.dispatch("setBiz", result);
      this.load();
    },
  },
};
</script>
