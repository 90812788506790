<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Pay Invoice
      v-spacer
      .subtitle-2(v-if="invoice") Total: {{ invoice.payment.total | currency }}
    v-card-text
      Balance(ref="balance")
      v-btn.text-capitalize(
        @click="payByTransfer()",
        :loading="loadingPayByTransfer",
        color="secondary",
        block
      ) Pay with Sales Balance
      .text-center.my-5 or
      v-btn.text-capitalize(
        @click="payByCreditCard()",
        :loading="loadingPayByCredit",
        color="secondary",
        block
      ) Pay by Credit Card
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Balance from "/libs/components/Stripe/Balance";

export default {
  components: { Balance },
  data() {
    return {
      loadingPayByCredit: false,
      loadingPayByTransfer: false,
      dialog: false,
      invoice: null,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    managed_account() {
      return this.biz?.payments?.managed_account;
    },
  },
  methods: {
    ...mapActions(["updatePurchase"]),
    open(invoice) {
      if (!invoice) return;
      this.invoice = invoice;
      this.dialog = true;
      this.$refs.balance?.load(this.managed_account);
    },
    async payByTransfer() {
      if (!this.biz || !this.invoice || !this.invoice._id) return;
      this.loadingPayByTransfer = true;
      const invoiceId = this.invoice._id;
      const params = { id: invoiceId };
      try {
        const url = "/bizOrder/payByTransfer";
        const { data } = await this.axios.post(url, params);
        this.updatePurchase(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loadingPayByTransfer = false;
    },
    async payByCreditCard() {
      if (!this.biz || !this.invoice || !this.invoice._id) return;
      this.loadingPayByCredit = true;
      const invoiceId = this.invoice._id;
      const params = { id: invoiceId };
      try {
        const { data } = await this.axios.post("/bizOrder/pay", params);
        this.updatePurchase(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loadingPayByCredit = false;
    },
  },
};
</script>
