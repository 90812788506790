<template lang="pug">
v-dialog(width="500", v-model="dialog", scrollable)
  v-card(v-if="file")
    v-toolbar(flat, dense)
      .subtitle-2 Uploaded File
    v-divider
    v-img(:src="file")
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      file: null,
      dialog: false,
    };
  },
  mounted() {
    EventBus.$on("show-biz-document", this.open);
  },
  destroyed() {
    EventBus.$off("show-biz-document", this.open);
  },
  methods: {
    open(data) {
      this.file = data;
      this.dialog = true;
    },
  },
};
</script>