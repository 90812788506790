<template lang="pug">
v-dialog(v-model="dialog", width="250", @keydown.esc="dialog = false")
  v-card(v-if="item")
    v-form.pa-3(@submit.prevent="submit")
      v-text-field(
        label="Name",
        v-model.trim="item.name",
        autofocus,
        ref="focus"
      )
      v-text-field(
        type="number",
        min="0",
        step="1",
        label="Price",
        v-model="item.price"
      )
      v-btn(block, color="secondary", type="submit") Save
</template>

<script>
export default {
  name: "SingleForm",
  props: ["input"],
  data() {
    return {
      item: null,
      dialog: false,
    };
  },
  watch: {
    input: {
      handler(val) {
        if (!val) return;
        this.item = JSON.parse(JSON.stringify(val));
        this.dialog = true;
        this.$nextTick(() => this.$refs.focus.focus());
      },
      deep: true,
    },
    dialog(val) {
      if (!val) this.$emit("done");
    },
  },
  methods: {
    submit() {
      if (!this.item) return;
      this.item.name = this.item.name.trim();
      this.item.price = Math.ceil(this.item.price);
      if (!this.item.name) return;
      if (this.item.price < 1) return;
      this.$emit("done", this.item);
      this.dialog = false;
    },
  },
};
</script>