<template lang="pug">
v-btn(x-small, color="secondary", @click.stop="download", outlined) PDF
</template>

<script>
import jsPDF from "jspdf";
import _ from "underscore";

export default {
  props: ["item"],
  methods: {
    download() {
      if (!this.item) return;
      const item = this.item;
      const filters = this.$options.filters;

      let doc = new jsPDF({
        format: "letter",
      });
      let x = 10;
      let y = 13;
      let pageHeight = doc.internal.pageSize.height;
      let pageWidth = doc.internal.pageSize.width;
      const ncell = 6;
      const cellWidth = (pageWidth - 20) / ncell;
      doc.setFontSize(12);
      doc.text(item.organization.name, x, y);
      nextLine();
      addLine(8);
      addRow(["Merchant", "", "Period", "Subtotal", "Tax", "Total"]);
      addLine(8);
      _.each(item.items, (o) => {
        const subtotal = filters.currency(o.subtotal);
        const tax = filters.currency(o.tax);
        const total = filters.currency(o.total);
        addRow([o.biz_name.slice(0, 26), "", o.period, subtotal, tax, total]);
      });

      addLine(10);
      addRow([
        "Subtotal",
        "",
        "",
        "",
        "",
        filters.currency(item.payment.subtotal),
      ]);
      addRow(["Tax", "", "", "", "", filters.currency(item.payment.tax)]);
      if (item.payment.discount) {
        addRow([
          "Discount",
          "",
          "",
          "",
          "",
          filters.currency(-item.payment.discount),
        ]);
      }
      addRow(["Total", "", "", "", "", filters.currency(item.payment.total)]);
      addLine(10);
      if (item.payment.charge && item.payment.charge.amount > 0) {
        addRow([
          "Payment",
          "",
          "",
          "",
          "",
          filters.currency(item.payment.charge.amount.total),
        ]);
      } else {
        addRow(["", "", "", "", "", "Not Paid"]);
      }

      doc.save("partner_invoice.pdf");

      function addRow(list) {
        for (var i = 0; i < list.length; i++) {
          const item = list[i] || "";
          doc.text(item, x, y);
          x += cellWidth;
        }
        nextLine();
      }

      function addLine(height) {
        doc.line(10, y, pageWidth - 20, y);
        nextLine(height);
      }
      function nextLine(height) {
        if (height) y += height;
        else y += 5;
        x = 10;
        if (y + 10 > pageHeight) {
          doc.addPage();
          y = 13;
        }
      }
    },
  },
};
</script>