<template lang="pug">
.s-vstack
  Settings
  Overall.mb-3
  List
  Detail
</template>

<script>
import Detail from "./Detail/index";
import List from "./List/index";
import Overall from "./Overall/index";
import Settings from "./Settings";

export default {
  components: { Settings, Overall, List, Detail },
};
</script>