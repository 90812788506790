<template lang="pug">
.d-flex.flex-row.justify-center(v-if="posEditable")
  v-form(@submit.prevent="submit")
    v-card(max-width="500", width="100%", flat)
      v-toolbar(flat, dense)
        .subtitle-2 Printing Settings
      v-divider
      v-subheader Kitchen Printing
      v-list.pa-0(dense)
        v-list-item
          v-list-item-content
            v-list-item-title Kitchen head space
          v-list-item-action
            v-select(
              v-model="posEditable.epson.kex",
              :items="headSpaces",
              style="width: 100px",
              dense,
              hide-details
            )
        v-list-item
          v-list-item-content
            v-list-item-title Kitchen font size
          v-list-item-action
            v-select(
              v-model="posEditable.epson.kitchen_item_height",
              :items="fontsizes",
              style="width: 100px",
              dense,
              hide-details
            )
        v-list-item
          v-list-item-content
            v-list-item-title Kitchen 2 Name
          v-list-item-action
            v-text-field(
              v-model="posEditable.epson.kitchen2",
              style="width: 100px",
              dense,
              hide-details
            )
        v-list-item
          v-list-item-content
            v-list-item-title Kitchen 3 Name
          v-list-item-action
            v-text-field(
              v-model="posEditable.epson.kitchen3",
              style="width: 100px",
              dense,
              hide-details
            )
        v-list-item
          v-list-item-content
            v-list-item-title Kitchen 4 Name
          v-list-item-action
            v-text-field(
              v-model="posEditable.epson.kitchen4",
              style="width: 100px",
              dense,
              hide-details
            )
        v-list-item
          v-list-item-content
            v-list-item-title
              span Graphic Mode
              v-tooltip(right)
                template(v-slot:activator="{ on }")
                  v-icon.ml-2(small, v-on="on") mdi-information
                span Might be slow with impact printer
            v-list-item-subtitle Asian languages (Chinese, Japanese, Korean)
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.epson.kGFX")
        v-list-item
          v-list-item-content
            v-list-item-title
            v-list-item-title Print both language
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.epson.kBothLang")
        v-list-item
          v-list-item-content
            v-list-item-title Print total price
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.printTotalValueInKitchen"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Auto print to bar
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.epson.bar")
        v-list-item
          v-list-item-content
            v-list-item-title Print seat number
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.print_seat_to_kitchen"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Print customer phone & address
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.print_customer_detail_kitchen"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Copy all items to main kitchen printer
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.epson.kIncl")
        v-list-item
          v-list-item-content
            v-list-item-title Print to Kitchen only if Paid
            v-list-item-subtitle Used in Pay & Send
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.is_wait_pay"
            )
      v-subheader Customer Receipts
      v-list.pa-0(dense)
        v-list-item
          v-list-item-content
            v-list-item-title Print on Charge Card
          v-list-item-action
            v-select(
              v-model="receipt_value",
              :items="receipt_options",
              dense,
              hide-details,
              style="width: 200px",
              @change="applyReceiptValue"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Print items in restaurant copy
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.itemsInRestaurantCopy"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Print customer copy on customer-facing display tip adjusted
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.printOnFFTip"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Auto print customer receipts for to-go orders
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.autoPrintTogoCustomer"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Print modifiers' price
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.showModifierPriceInReceipt"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Print modifiers by line
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.showModifierByLine"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Print zero-priced items
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.printZeroPricedItems"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Print Order QRCode
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.print_qrcode"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Print Selflane.com at the bottom
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.print_selflane"
            )
      v-card-text
        v-text-field(
          label="Receipt bottom message",
          v-model="posEditable.message"
        )
      v-card-actions(v-if="dirty")
        v-row
          v-col(cols="8")
            v-btn.white--text(
              block,
              color="secondary",
              type="submit",
              :loading="loading"
            ) Save
          v-col(cols="4")
            v-btn(text, block, @click="cancel()") Cancel
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      headSpaces: [
        { text: "Regular", value: 0 },
        { text: "Extra", value: 1 },
        { text: "Long", value: 2 },
      ],
      fontsizes: [
        { text: "Regular", value: 1 },
        { text: "Large", value: 2 },
      ],
      loading: false,
      receipt_value: 1,
      receipt_options: [
        { text: "Restaurant Copy Only", value: 1 },
        { text: "Both Restaurant and Customer Copy", value: 2 },
        { text: "None", value: 0 },
      ],
    };
  },
  computed: {
    ...mapGetters(["pos", "posEditable"]),
    dirty() {
      return JSON.stringify(this.posEditable) !== JSON.stringify(this.pos);
    },
  },
  mounted() {
    this.setReceiptValue();
  },
  methods: {
    ...mapActions(["setPOS", "resetEditable"]),
    setReceiptValue() {
      if (this.posEditable.epson.noPrintOnCharge) {
        this.receipt_value = 0;
      } else if (this.posEditable.epson.pymtrcpt) {
        this.receipt_value = 2;
      } else {
        this.receipt_value = 1;
      }
    },
    applyReceiptValue() {
      if (this.receipt_value === 0) {
        this.posEditable.epson.noPrintOnCharge = true;
        this.posEditable.epson.pymtrcpt = false;
      } else if (this.receipt_value === 2) {
        this.posEditable.epson.noPrintOnCharge = false;
        this.posEditable.epson.pymtrcpt = true;
      } else {
        this.posEditable.epson.noPrintOnCharge = false;
        this.posEditable.epson.pymtrcpt = false;
      }
    },
    async submit() {
      this.loading = true;
      this.applyReceiptValue();
      try {
        const action = {
          $set: {
            epson: this.posEditable.epson,
            message: this.posEditable.message,
          },
        };
        const result = await this.$api.pos.put(this.pos?._id, { action });
        this.setPOS(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    cancel() {
      this.resetEditable();
      this.setReceiptValue();
    },
  },
};
</script>
