<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="promotion")
    v-toolbar(dense, flat)
      .subtitle-2
        span(v-if="promotion.type == 'pct'") {{ -promotion.pct }}%
        span(v-else) {{ -promotion.fix | currency }}
      v-spacer
      .subtitle-2 {{ promotion.claimed }} Claimed
    v-divider
    v-card-text
      li.body-2(v-if="promotion.chain") Apply to Entire Chain
      li Total Coupons: {{ promotion.total }}
      li Minimum Purchase: {{ promotion.minimum | currency }}
      li Expiry: {{ promotion.expiry | date }}
      ClaimedChart.my-3(:stats="stats")
      SalesChart(:stats="stats")
</template>

<script>
import { EventBus } from "@/event-bus";
import ClaimedChart from "./ClaimedChart";
import SalesChart from "./SalesChart";

export default {
  components: { ClaimedChart, SalesChart },
  data() {
    return {
      dialog: false,
      promotion: null,
      stats: null,
    };
  },
  mounted() {
    EventBus.$on("show-coupon-report", this.load);
  },
  destroyed() {
    EventBus.$off("show-coupon-report", this.load);
  },
  methods: {
    async load(promoId) {
      if (!promoId) return;
      this.dialog = true;
      const params = { criteria: { _id: promoId } };
      try {
        this.promotion = await this.$api.promotion.retrieve(params);
        this.loadStats(promoId);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load promotion");
      }
    },
    async loadStats(promoId) {
      this.stats = null;
      if (!promoId) return;
      const params = { promoId: promoId };
      const { data } = await this.axios.post("/promoclaim/stats", params);
      this.stats = data;
    },
  },
};
</script>
