<template lang="pug">
div
  v-card(flat, v-if="biz")
    v-toolbar(flat, dense)
      .subtitle-2 Pricing
      v-spacer
      IconBtn(@click="create()", title="Add", icon="mdi-plus")
    Table(:items="biz.pricings", @edit="edit")
  Form(ref="form")
</template>

<script>
import Form from "./Form";
import Table from "./Table";

export default {
  components: { Table, Form },
  props: {
    biz: { type: Object, default: null },
  },
  methods: {
    create() {
      this.$refs.form.open();
    },
    edit(item) {
      this.$refs.form.open(item);
    },
  },
};
</script>