<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-card-title Picture for {{ fullname }}
    v-card-text
      v-col(align="center")
        croppa(
          v-model="myCroppa",
          :quality="2",
          :canvas-color="canvas_color",
          initial-size="contain"
        )
    v-card-actions
      v-btn(@click="upload", color="secondary", :loading="loading") Upload
</template>

<script>
export default {
  name: "StaffProfilePictureForm",
  data() {
    return {
      server: null,
      myCroppa: {},
      dialog: false,
      loading: false,
      canvas_color: "#FFFFFF", // background color
    };
  },
  computed: {
    fullname() {
      if (this.server)
        return `${this.server.first_name} ${this.server.last_name}`;
      return "";
    },
  },
  methods: {
    open(server) {
      // this.myCroppa?.remove();
      this.server = server;
      this.dialog = true;
    },
    upload() {
      this.myCroppa.generateBlob(
        (blob) => {
          if (!blob) {
            this.myCroppa.chooseFile();
          } else {
            this.save(blob);
          }
        },
        "image/jpeg",
        0.8
      );
    },
    async save(blob) {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("id", this.server?._id);
        const result = await this.$api.hr.person.custom(
          "uploadPicture",
          formData
        );
        this.$store.dispatch("hr/updatePerson", result);
        this.$toast.success("Picture updated");
        this.myCroppa?.remove();
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update picture");
      }
      this.loading = false;
    },
  },
};
</script>