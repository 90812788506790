<template lang="pug">
Page(title="Regions")
  EnterpriseRegion
</template>

<script>
import EnterpriseRegion from "/libs/components/Enterprise/Region";

export default {
  components: { EnterpriseRegion },
};
</script>
