<template lang="pug">
div
  Create
  List.my-3
  EditForm
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Create from "./Create";
import List from "./List/index.vue";
import EditForm from "./EditForm";
import { EventBus } from "@/event-bus";

export default {
  components: { Create, List, EditForm },
  computed: {
    ...mapGetters(["biz"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setEvents"]),
    async load() {
      if (!this.biz) return;
      const criteria = { biz_id: this.biz._id };
      const events = await this.$api.event.entry.list({ criteria });
      this.setEvents(events);
    },
  },
};
</script>