<template lang="pug">
v-row(wrap, dense)
  v-col(
    cols="12",
    sm="6",
    md="4",
    v-for="(group, gkey) in groupedTables",
    :key="gkey"
  )
    v-card(outlined)
      v-toolbar(dense, flat)
        .subtitle-2.text-capitalize {{ gkey }}
        v-spacer
        v-chip(small, outlined) {{ group.length }}
      simple-table
        thead
          tr
            th ID
            th Capacity
            th 
        tbody
          tr(
            v-for="(item, index) in group",
            @click="edit(item)",
            role="button"
          )
            th {{ item.id }}
            td {{ item.min_capacity }} - {{ item.max_capacity }}
            td.text-right
              v-btn.ml-0(icon, small, @click.stop="toggleOL(item)")
                v-icon.green--text(small, v-if="item.online_reservation") mdi-earth
                v-icon.grey--text.text--lighten-1(small, v-else) mdi-earth
              v-btn.mr-0(icon, small, @click.stop="handleRemove(item)")
                v-icon.red--text(small) mdi-delete
</template>

<script>
import { EventBus } from "@/event-bus";
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz"]),
    groupedTables() {
      if (!this.biz) return [];
      return _.chain(this.biz.tables).sortBy("id").groupBy("room").value();
    },
  },
  methods: {
    edit(item) {
      EventBus.$emit("edit-table", item);
    },
    async toggleOL(item) {
      if (!this.biz) return;
      if (!item?._id) return;
      const value = !(item.online_reservation == true);
      const params = {
        criteria: { _id: this.biz._id, "tables._id": item._id },
        action: { $set: { "tables.$.online_reservation": value } },
      };
      const data = await this.$api.biz.update(params);
      this.$store.dispatch("setBiz", data);
    },
    handleRemove(item) {
      confirm("Do you want to remove this table?") && this.remove(item);
    },
    async remove(item) {
      if (!this.biz || !item?._id) return;
      const params = {
        criteria: { _id: this.biz._id },
        action: { $pull: { tables: { _id: item._id } } },
      };
      const data = await this.$api.biz.update(params);
      this.$store.dispatch("setBiz", data);
    },
  },
};
</script>
