<template lang="pug">
table.info-table
  tbody
    tr(v-for="(item, index) in items", :key="index")
      td
        v-icon(:color="item.color", small) {{ item.icon }}
      td {{ item.text }}
</template>

<script>
const online =
  "Accessible through the online platform, for instance, users have the option to deactivate items associated with alcohol.";
const thirdParty =
  "Sync to third-party platforms. Exclusive for third-party integration.";
export default {
  data() {
    return {
      items: [
        { color: "green", icon: "mdi-earth", text: online },
        { color: "green", icon: "mdi-moped", text: thirdParty },
        {
          color: "green",
          icon: "mdi-format-underline",
          text: "Print with underline. e.g., for a burger with multiple modifiers.",
        },
        {
          icon: "mdi-chef-hat",
          text: "Edit item recipe. Add ingredients.",
        },
        {
          icon: "mdi-format-list-bulleted",
          text: "Sort modifiers for dish. e.g., buns, toppings, temperature modifiers for a burger.",
        },
        {
          icon: "mdi-content-copy",
          text: "Duplicate. Add an item quickly with similar settings.",
        },
        { color: "red", icon: "mdi-delete", text: "Delete" },
      ],
    };
  },
};
</script>
