<template lang="pug">
v-list-item(@click="$emit('toggle', name, !value)")
  v-list-item-content
    v-list-item-title {{ title }}
  v-list-item-action
    v-icon(:color="color", small) {{ icon }}
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  props: {
    title: { type: String, default: "" },
    name: { type: String, required: true },
  },
  computed: {
    ...mapGetters(["pos"]),
    value() {
      return _.get(this.pos?.manage, this.name);
    },
    color() {
      return this.value ? "success" : "gray";
    },
    icon() {
      return this.value ? "mdi-check-circle" : "mdi-minus-circle";
    },
  },
};
</script>
