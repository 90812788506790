<template lang="pug">
div
</template>

<script>
import _ from "underscore";

export default {
  methods: {
    async load() {
      const userId = this.$auth?.user()?._id;
      if (!userId) {
        this.$store.dispatch("setUserAccesses", []);
        return;
      }
      const params = { criteria: { user: userId } };
      const result = await this.$api.b.access.list(params);
      this.$store.dispatch("setUserAccesses", result);
      this.loadBizs(result);
    },
    async loadBizs(accesses) {
      const ids = this.pluck(accesses, "biz");
      if (!ids?.length) {
        this.$store.dispatch("setBizAccounts", []);
        return;
      }
      const params = {
        criteria: { _id: { $in: ids } },
        select: "name short_name address enterprise",
      };
      const result = await this.$api.biz.list(params);
      this.$store.dispatch("setBizAccounts", result);
    },
    // pluck property from given list
    pluck(list, property) {
      return _.chain(list).pluck(property).compact().value();
    },
  },
};
</script>