<template lang="pug">
IconBtn(
  :loading="loading",
  @click="submit()",
  title="Create Stripe Account",
  :outlined="false"
)
</template>

<script>
export default {
  props: { bizId: { type: String, default: null } },
  data() {
    return { loading: false };
  },
  methods: {
    async submit() {
      // get current url
      const href = window.location.href;
      if (!this.bizId) return;
      this.loading = true;
      const params = {
        bizId: this.bizId,
        refresh_url: href,
        return_url: href,
      };
      try {
        const { url } = await this.$api.stripe.accountLink.create(params);
        window.open(url, "_blank");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message || e);
      }
      this.loading = false;
    },
  },
};
</script>
