import event from './Events/route'
import Influencer from './Influencer/index.vue'
import InfluencerSettings from './Influencer/Settings/index.vue'
import Online from './Online/index.vue'
import Programs from './Online/Programs/index.vue'
import Reward from './Reward/index.vue'

export default [
  {
    path: '/marketing/influencer', component: Influencer, meta: { auth: true },
    children: [
      { path: '', redirect: 'settings' },
      { path: 'settings', component: InfluencerSettings }
    ]
  },
  {
    path: '/marketing/online', component: Online, meta: { auth: true },
    children: [
      { path: '', redirect: 'programs', },
      { path: 'programs', component: Programs }
    ]
  },
  { path: '/marketing/reward', component: Reward, meta: { auth: true } },
  ...event
]