<template lang="pug">
v-dialog(v-model="dialog", width="450")
  template(v-slot:activator="{ on }")
    v-list-item(outlined, small, color="secondary", v-on="on")
      span Set Bulk 3rd Party Sync
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Sync to 3rd Party Platforms
    v-divider
    v-form(@submit.prevent="save")
      v-card-text
        Note(:courseId="courseId")
        v-btn-toggle.mb-3(v-model="third_party", color="secondary")
          v-btn(
            @click.stop,
            v-for="(item, index) in items",
            :key="index",
            :value="item.value",
            :title="item.title"
          )
            v-icon(small) {{ item.icon }}
            span.ml-1(v-if="item.text") {{ item.text }}
        v-btn(type="submit", block, color="secondary", :loading="loading") Save
        v-btn.mt-3(block, color="secondary", @click="save(false)") Match Online Setting
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Note from "./Note";

export default {
  components: { Note },
  props: {
    courseId: { type: String, default: null },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      third_party: true,
      items: [
        { text: "On", value: true },
        { text: "Off", value: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    async save(byCourse = true) {
      if (!this.biz) return;
      this.loading = true;
      if (byCourse) {
        _.each(this.biz.dishes, (o) => {
          if (o.course == this.courseId) o.third_party = this.third_party;
        });
      } else {
        _.each(this.biz.dishes, (o) => {
          o.third_party = o.hide ? false : true;
        });
      }
      try {
        const action = { $set: { dishes: this.biz.dishes } };
        const data = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", data);
        this.dialog = false;
        this.third_party = true;
      } catch (e) {
        this.$toast.error(e.response?.data || e.response);
      }
      this.loading = false;
    },
  },
};
</script>
