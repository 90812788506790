<template lang="pug">
v-dialog(v-model="dialog", width="300")
  template(v-slot:activator="{ on }")
    v-btn(v-on="on", text, small, :color="color", @click.stop="open") {{ title }}
  v-card
    v-card-title Set Daily Limit
    v-form(@submit.prevent="save")
      v-card-text
        v-text-field(
          type="number",
          min="0",
          v-model="value",
          hide-details,
          single-line,
          dense
        )
        .caption {{ hint }}
      v-card-actions
        v-btn(color="secondary", text, type="submit", :loading="loading") Save
</template>

<script>
export default {
  props: {
    biz: { type: Object, required: true },
    item: { type: Object, required: true },
    itemCounts: { type: Array, required: true },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      value: 0,
      hint: "Set daily limit for this item. Leave 0 for no limit.",
    };
  },
  computed: {
    daily_limit() {
      return this.item.daily_limit || 0;
    },
    count() {
      return (
        this.itemCounts.find((o) => o.item == this.item._id)?.quantity || 0
      );
    },
    title() {
      if (this.count) {
        return `${this.count}/${this.daily_limit}`;
      }
      return this.daily_limit;
    },
    color() {
      if (this.daily_limit && this.count >= this.daily_limit) {
        return "error";
      }
      return "secondary";
    },
  },
  methods: {
    open() {
      this.value = this.daily_limit;
      this.dialog = true;
    },
    async save() {
      if (!this.biz || !this.item?._id) return;
      const params = {
        criteria: { _id: this.biz._id, "dishes._id": this.item._id },
        action: { $set: { "dishes.$.daily_limit": this.value } },
      };
      this.loading = true;
      try {
        const data = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to save");
      }
      this.loading = false;
    },
  },
};
</script>