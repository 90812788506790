<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card(v-if="item")
    v-card-title {{ title }}
    form(@submit.prevent="save")
      v-card-text
        v-row
          v-col(cols="12")
            DateFieldPicker(v-model="item.date")
          v-col(cols="5")
            TimeSelector(v-model="item.start", title="Start")
          v-col(cols="5")
            TimeSelector(v-model="item.end", title="End")
          v-col(cols="2")
            v-btn(x-small, outlined, @click="allDay") All Day
          v-col(cols="12")
            v-switch(v-model="all_items", label="All Items", color="secondary")
          v-col(cols="12", v-if="!all_items")
            v-select(
              v-model="item.items",
              :items="item_options",
              label="Item",
              multiple,
              hint="Unselect any item to block all items",
              persistent-hint
            )
      v-card-actions
        v-btn(type="submit", color="secondary", :loading="loading") Save
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      item: null,
      all_items: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz", "rsvtsetting"]),
    title() {
      return this.item._id ? "Edit Booked Up" : "Add Booked Up";
    },
    item_options() {
      return this.rsvtsetting.items.map((o) => ({
        text: o.name,
        value: o._id,
      }));
    },
  },
  methods: {
    open(input) {
      if (input) this.item = JSON.parse(JSON.stringify(input));
      else
        this.item = {
          biz: this.biz._id,
          date: moment().format("YYYY-MM-DD"),
          start: 720,
          end: 900,
          items: [],
        };
      this.all_items = !this.item.items?.length;
      this.dialog = true;
    },
    allDay() {
      this.item.start = 0;
      this.item.end = 1440;
    },
    validate() {
      if (!this.item.date) throw new Error("Date is required");
      if (this.item.start == undefined) {
        throw new Error("Start time is required");
      }
      if (this.item.end == undefined) throw new Error("End time is required");
      if (this.item.start >= this.item.end) {
        throw new Error("Start time must be before end time");
      }
      if (this.all_items) this.item.items = [];
    },
    async save() {
      this.loading = true;
      try {
        this.validate();
        if (this.item._id) {
          const action = {
            $set: {
              date: this.item.date,
              start: this.item.start,
              end: this.item.end,
              items: this.item.items,
            },
          };
          const result = await this.$api.rs.bookedUp.put(this.item._id, action);
          this.$store.dispatch("updateBookedup", result);
        } else {
          this.item.biz = this.biz._id;
          const result = await this.$api.rs.bookedUp.create(this.item);
          this.$store.dispatch("addBookedup", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>