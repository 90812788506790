import access from './Access/route'
import transaction from './Transaction/route'
import payments from './Payments/route'
import billing from './Billing/route'
import sync from './Sync/route'
import log from './Log/route'

export default [
  ...access,
  ...transaction,
  ...payments,
  ...billing,
  ...sync,
  ...log
]