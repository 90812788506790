<template lang="pug">
div
  span(v-if="isPaid") Paid
  v-btn(
    v-else,
    x-small,
    color="error",
    @click.stop="pay",
    outlined,
    :loading="loading"
  ) Pay
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return { loading: false };
  },
  computed: {
    isPaid() {
      return this.item?.status == 1;
    },
  },
  methods: {
    ...mapActions(["updateOrgInvoice"]),
    async pay() {
      const invoiceId = this.item?._id;
      if (!invoiceId) return;
      this.loading = true;
      try {
        const result = await this.$api.salesforce.invoice.custom("pay", {
          invoiceId,
        });
        this.updateOrgInvoice(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>