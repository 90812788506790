<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import _ from "underscore";
import CSV from "/libs/utils/CSV";

export default {
  props: ["range", "report"],
  computed: { ...mapGetters(["biz"]) },
  methods: {
    download() {
      if (!this.biz || !this.biz._id) return;
      const dates = this.range
        .map((o) => moment(o).format("ddd L"))
        .join(" ~ ");
      const courses = this.biz.courses;
      const currency = this.$options.filters.currency;

      const csv = new CSV();
      csv.addRow(["Date", dates]);
      _.chain(this.report.sales)
        .groupBy("course")
        .map((o, k) => {
          const amount = o.reduce((acc, cur) => acc + cur.amount, 0);
          let course = courses.find((o) => o._id === k);
          if (course) {
            return { name: course.name, group: course.grp, amount: amount };
          } else {
            return { name: "Other", group: "Other", amount: amount };
          }
        })
        .groupBy("group")
        .each((group, key) => {
          let total = group.reduce((acc, cur) => acc + cur.amount, 0);
          group.forEach((item) => addLine("", item.name, item.amount));
          addLine(key, "", total);
          csv.addNewRow();
        });
      csv.addNewRow();
      addLine("", "Net Sales", this.report.payment.dish);
      addLine("", "Tax", this.report.payment.tax);
      addLine("", "Gift Card Sold", this.report.payment.gift);
      addLine("", "Tips", this.report.payment.tip);
      addLine("", "Restaurant Fee", this.report.payment.fee);
      addLine("", "Cash Discount Adj.", this.report.payment.adjustment);
      addLine("", "Deduction", this.report.payment.deduction);
      addLine("", "Selflane Charges", this.report.payment.selflane);
      addLine("Total Receivable", "", this.report.payment.total);
      csv.addNewRow();
      this.report.charges.items.forEach((charge) => {
        addLine("", charge.title, charge.amount);
      });
      addLine("Total Received", "", this.report.charges.total);
      csv.addNewRow();
      csv.addNewRow();
      if (this.report.orders.open > 0) {
        const note = `There are open orders in this period. The total collected might not be balanced with the total sales.`;
        csv.addRow(["Note", "", note]);
      }
      csv.save("sales");

      function addLine(title, detail, amount) {
        if (amount == 0) return;
        csv.addRow([title, detail, currency(amount)]);
      }
    },
  },
};
</script>
