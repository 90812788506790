<template lang="pug">
v-card(width="500", max-width="95%", outlined)
  v-form(@submit.prevent="submit")
    v-card-text
      p If you have an established business, please enter the name first. We would use Google Search to prefill the entire form for you.
      v-row(dense, wrap)
        v-col(cols="9")
          GoogleAddressField(
            v-model="address",
            :fields="fields",
            placeholder="Enter your business name or address",
            @select="setPlace"
          )
        v-col(cols="3")
          v-text-field(
            label="Suite",
            v-model="address.line2",
            hint="Optional",
            persistent-hint
          )
      v-row(dense, wrap, v-if="hasAddress")
        v-col(cols="9")
          v-text-field(label="Business Name", v-model="name")
        v-col(cols="3")
          PriceLevelPicker(v-model="price_range")
        v-col(cols="6")
          PhoneField(v-model="phone")
        v-col(cols="6")
          v-text-field(label="Website", v-model="website")
      div(v-if="hasAddress")
        v-subheader Weekly Schedule
        v-row(
          dense,
          v-for="(item, windex) in weekdays",
          :key="windex",
          align="center"
        )
          v-col(cols="3")
            v-checkbox(
              v-model="item.status",
              :label="weekday(item.date)",
              hide-details,
              color="secondary",
              dense
            )
          v-col(v-if="item.status")
            v-row(
              dense,
              v-for="(s, sindex) in item.schedule",
              :key="sindex",
              align="end"
            )
              v-col(cols="8")
                TimeRangePicker(v-model="item.schedule[sindex].range")
              v-col(cols="4")
                v-icon.mr-2(
                  small,
                  color="red",
                  v-if="item.schedule.length > 1",
                  @click="removeRange(windex, sindex)"
                ) mdi-close
                v-icon.mr-2(
                  small,
                  color="secondary",
                  v-if="sindex === item.schedule.length - 1",
                  @click="addRange(windex)",
                  title="add new period"
                ) mdi-plus
                v-icon(
                  small,
                  color="secondary",
                  v-if="sindex === item.schedule.length - 1 && windex > 0",
                  @click="copyRange(windex)",
                  title="copy previous day"
                ) mdi-content-copy
      .pl-2.red--text(v-for="(error, index) in errors", :key="index + 'index'") {{ error }}
    v-card-actions
      v-btn(block, color="secondary", type="submit", :loading="loading") Create a Business
</template>

<script>
import _ from "underscore";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      fields: [
        "address_component",
        "geometry",
        "icon",
        "name",
        "opening_hours",
        "formatted_phone_number",
        "place_id",
        "website",
        "type",
        "price_level",
      ],
      name: "",
      address: {},
      price_range: 1,
      website: "",
      phone: "",
      weekdays: [],
      place_id: "",
      loading: false,
      errors: null,
    };
  },
  computed: {
    hasAddress() {
      return this.address?.line1;
    },
  },
  mounted() {
    this.resetWeekday();
  },
  methods: {
    ...mapActions(["setUserAccesses", "addBizAccount", "setNavState"]),
    weekday(date) {
      return this.$options.filters.day(date, true);
    },
    async submit() {
      this.errors = [];
      if (!this.name) {
        this.errors.push("Business name is required");
      }
      if (!this.address?.line1) {
        this.errors.push("Address is required");
      }
      if (this.errors.length > 0) {
        return;
      }
      this.loading = true;
      try {
        const params = {
          name: this.name,
          email: this.$auth.user().email,
          website: this.website,
          price_range: this.price_range,
          phones: [
            { number: this.phone?.replace(/[^\d]/g, ""), countryCode: 1 },
          ],
          schedule: { weekdays: this.weekdays, specialdays: [] },
          address: this.address,
          place_id: this.place_id,
        };
        const biz = await this.$api.biz.create(params);
        this.$store.dispatch("setBiz", biz);
        this.setNavState("biz");
        this.$router.push("/profile");
        this.addBizAccount(biz);
        this.loadAccess();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async loadAccess() {
      if (!this.$auth.user() || !this.$auth.user()._id) {
        return;
      }
      const userId = this.$auth.user()._id;
      const params = { criteria: { user: userId } };
      const result = await this.$api.b.access.list(params);
      this.setUserAccesses(result);
    },
    setPlace(place) {
      if (!place) return;
      if (place.types.includes("establishment")) {
        this.name = place.name;
        this.place_id = place.place_id;
        this.price_level = place.price_level;
      } else {
        this.place_id = "";
      }
      this.phone = place.formatted_phone_number?.replace(/[^\d]/g, "");
      this.website = place.website;
      this.setScheduleWithGooglePlace(place);
    },
    resetWeekday() {
      this.weekdays = _.map([1, 2, 3, 4, 5, 6, 0], (date) => {
        return {
          date: date,
          status: true,
          schedule: [{ range: [480, 1200] }],
        };
      });
    },
    setScheduleWithGooglePlace(place) {
      this.resetWeekday();
      if (!place.opening_hours) return;
      _.each(this.weekdays, (item) => {
        const list = place.opening_hours.periods?.filter(
          (o) => o.open.day === item.date
        );
        if (!list?.length) {
          item.status = false;
          return;
        }
        const schedule = _.map(list, (o) => {
          const begin = o.open.hours * 60 + o.open.minutes;
          const end = o.close.hours * 60 + o.close.minutes;
          return { range: [begin, end] };
        });
        item.status = true;
        item.schedule = schedule;
      });
    },
    addRange(windex) {
      if (windex < this.weekdays.length) {
        this.weekdays[windex].schedule.push({ range: [480, 1200] });
      }
    },
    copyRange(windex) {
      if (windex < this.weekdays.length && windex > 0) {
        this.weekdays[windex].schedule = JSON.parse(
          JSON.stringify(this.weekdays[windex - 1].schedule)
        );
      }
    },
    removeRange(windex, sindex) {
      if (windex < this.weekdays.length) {
        if (sindex < this.weekdays[windex].schedule.length) {
          this.weekdays[windex].schedule.splice(sindex, 1);
        }
      }
    },
  },
};
</script>
