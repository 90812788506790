<template lang="pug">
.my-box
  Status
  Logo
  p Dear [member name],
  Description
  .my-3
    .text-center Please present this 8-digit code to the server.
    .code [1234 5678]
  Expiry
  Photo
  .brand(align="right") Selflane
  .dash-divider
  small This is a marketing email sent by the Membership Program of the restaurant. The program would only send you promotion on occasions like birthday and anniversary. No more than three emails would be sent annually. If you still don't want to receive promotions like this, please click
    a unsubscribe
    span .
</template>

<script>
import Description from "./Description";
import Expiry from "./Expiry";
import Logo from "./Logo";
import Photo from "./Photo";
import Status from "./Status";

export default {
  components: { Status, Logo, Photo, Description, Expiry },
  props: ["promo"],
  data() {
    return {
      defaultText: "",
      expiry: 0,
      logo: null,
    };
  },
  watch: {
    promo: {
      handler(val) {
        if (!val) return;
        if (val.type == 1) {
          this.defaultText = "Welcome new member!";
        } else if (val.type == 2) {
          this.defaultText = "Happy Birthday!";
        } else if (val.type == 3) {
          this.defaultText = "Happy Anniversary!";
        }
        this.expiry = val.expiry;
        this.logo = val.logo;
      },
      deep: true,
    },
  },
  methods: {
    getDefaultText(type) {
      if (type == 1) return "Welcome to ";
    },
  },
};
</script>

<style lang="sass" scoped>
.my-box
  padding: 20px 20px 20px 20px
  background-color: #fff
  border: 1px solid #e9e9e9
  border-radius: 5px
  max-width: 600px !important
  margin-right: auto
  margin-left: auto
  margin-top: 10px

.brand
  color: #f2a241
  font-weight: bold
  font-size: 24px

.dash-divider
  border-bottom: 2px solid #e9e9e9

.code
  text-align: center
  font-size: 20px
  color: #1a237e
  font-weight: 600
</style>
