<template lang="pug">
v-card(v-if="report", flat)
  v-toolbar(flat, dense)
    .subtitle-2 {{ title }}
    v-spacer
    IconBtn(@click="download()", title="CSV", icon="mdi-download")
  .table-box
    simple-table
      thead
        tr
          th(v-for="item in header", :key="item") {{ item }}
      tbody
        tr(v-for="(items, i) in periods", :key="i + 'i'")
          td(v-for="(item, j) in items", :key="j + 'j'") {{ item }}
      tfoot
        tr
          th(v-for="(item, f) in footer", :key="f + 'f'") {{ item }}
</template>

<script>
import moment from "moment-timezone";
import CSV from "/libs/utils/CSV";

export default {
  props: ["report"],
  computed: {
    sum() {
      return {
        period: "Sum",
        total: this.report.reduce((a, b) => a + b.total, 0),
        net: this.report.reduce((a, b) => a + b.net, 0),
        dish: this.report.reduce((a, b) => a + b.dish, 0),
        saved: this.report.reduce((a, b) => a + b.saved, 0),
        tax: this.report.reduce((a, b) => a + b.tax, 0),
        gift: this.report.reduce((a, b) => a + b.gift, 0),
        fee: this.report.reduce((a, b) => a + b.fee, 0),
        tip: this.report.reduce((a, b) => a + b.tip, 0),
        third_party: this.report.reduce((a, b) => a + b.third_party, 0),
        deduction: this.report.reduce((a, b) => a + b.deduction, 0),
        adjustment: this.report.reduce((a, b) => a + b.adjustment, 0),
        selflane: this.report.reduce((a, b) => a + b.selflane, 0),
        count: this.report.reduce((a, b) => a + b.count, 0),
      };
    },
    header() {
      let items = ["Period", "#Sales"];
      if (this.sum.net) items.push("Net Sales");
      if (this.sum.tax) items.push("Tax");
      if (this.sum.gift) items.push("Gift Card");
      if (this.sum.fee) items.push("Fee (in-store)");
      if (this.sum.tip) items.push("Tip");
      if (this.sum.third_party) items.push("3rd Party");
      if (this.sum.deduction) items.push("Deduction");
      if (this.sum.adjustment) items.push("Cash Discount Adjustment");
      if (this.sum.selflane) items.push("Selflane Charges");
      items.push("Total Receivables");
      return items;
    },
    periods() {
      const currency = this.$options.filters.currency;
      return this.report?.map((o) => {
        let arr = [this.time(o), o.count];
        if (this.sum.net) arr.push(o.net);
        if (this.sum.tax) arr.push(o.tax);
        if (this.sum.gift) arr.push(o.gift);
        if (this.sum.fee) arr.push(o.fee);
        if (this.sum.tip) arr.push(o.tip);
        if (this.sum.third_party) arr.push(o.third_party);
        if (this.sum.deduction) arr.push(o.deduction);
        if (this.sum.adjustment) arr.push(o.adjustment);
        if (this.sum.selflane) arr.push(o.selflane);
        arr.push(o.total);
        arr.forEach((item, i) => {
          if (i > 1) arr[i] = currency(item);
        });
        return arr;
      });
    },
    footer() {
      let items = ["Sum", this.sum.count];
      if (this.sum.net) items.push(this.sum.net);
      if (this.sum.tax) items.push(this.sum.tax);
      if (this.sum.gift) items.push(this.sum.gift);
      if (this.sum.fee) items.push(this.sum.fee);
      if (this.sum.tip) items.push(this.sum.tip);
      if (this.sum.third_party) items.push(this.sum.third_party);
      if (this.sum.deduction) items.push(this.sum.deduction);
      if (this.sum.adjustment) items.push(this.sum.adjustment);
      if (this.sum.selflane) items.push(this.sum.selflane);
      items.push(this.sum.total);
      items.forEach((item, i) => {
        if (i > 1) items[i] = this.$options.filters.currency(item);
      });
      return items;
    },
    title() {
      if (!this.report?.length) return "";
      let period = "Weekly";
      if (this.report[0].month != undefined) period = "Monthly";
      const year = this.report[this.report.length - 1].year;
      return `${period} Sales Report ${year}`;
    },
  },
  methods: {
    time(item) {
      if (item.month != undefined) {
        return this.$options.filters.month(item.month, true);
      } else {
        const begin = moment()
          .year(item.year)
          .isoWeek(item.week)
          .startOf("isoWeek")
          .format("MMM D");
        const end = moment()
          .year(item.year)
          .isoWeek(item.week)
          .endOf("isoWeek")
          .format("MMM D");
        return begin + " ~ " + end;
      }
    },
    download() {
      const csv = new CSV();
      csv.addRow(this.header);
      this.periods.forEach((o) => csv.addRow(o));
      csv.addRow(this.footer);
      csv.save(this.title);
    },
  },
};
</script>

<style lang="sass" scoped>
.table-box
  max-height: 300px
  overflow-y: scroll
</style>
