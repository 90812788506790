<template lang="pug">
v-row(wrap)
  v-col(
    cols="12",
    sm="4",
    md="3",
    v-for="(item, index) in plans",
    :key="index"
  )
    Cell(:plan="item")
</template>

<script>
import Cell from "./Cell";

export default {
  components: { Cell },
  props: ["plans"],
};
</script>
