<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-card-title Profile Picture
    v-card-text
      v-col(align="center")
        croppa(
          v-model="myCroppa",
          :quality="2",
          :canvas-color="canvas_color",
          initial-size="contain"
        )
    v-card-actions
      v-btn(@click="upload", color="secondary", :loading="loading") Upload
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      myCroppa: {},
      dialog: false,
      loading: false,
      canvas_color: "#FFFFFF", // background color
    };
  },
  computed: {
    ...mapGetters(["salesPerson"]),
  },
  methods: {
    ...mapActions(["updateSalesPerson"]),
    open() {
      // this.myCroppa?.remove();
      this.dialog = true;
    },
    upload() {
      this.myCroppa.generateBlob(
        (blob) => {
          if (!blob) {
            this.myCroppa.chooseFile();
          } else {
            this.save(blob);
          }
        },
        "image/jpeg",
        0.8
      );
    },
    async save(blob) {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("id", this.salesPerson._id);
        const result = await this.$api.salesforce.person.custom(
          "updateProfilePicture",
          formData
        );
        this.updateSalesPerson(result);
        this.$toast.success("Profile picture updated");
        this.myCroppa?.remove();
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update picture");
      }
      this.loading = false;
    },
  },
};
</script>