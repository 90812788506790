<template lang="pug">
WeekChart(:title="title", :weekData="weekData", :loading="loading")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

import WeekChart from "./WeekChart";

export default {
  components: { WeekChart },
  props: {
    title: { type: String, default: "" },
    bizId: { type: String, required: true },
    url: { type: String, required: true },
  },
  data() {
    return {
      weekData: [],
      loading: false,
    };
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.bizId) return;
      this.loading = true;
      const params = { bizId: this.bizId };
      try {
        const { data } = await this.axios.post(this.url, params);
        let dataOne = _.pluck(data.thisWeek, "net");
        let dataTwo = _.pluck(data.lastWeek, "net");
        this.weekData = [dataOne, dataTwo];
        this.$emit("loaded", this.weekData);
      } catch (e) {
        //
      }
      this.loading = false;
    },
  },
};
</script>
