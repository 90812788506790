<template lang="pug">
div
  .link-box
    v-chip(color="info", label, small, v-if="item.add_tax")
      v-icon(small, left) mdi-label
      span Add Tax
    v-chip(color="info", label, small, v-if="item.add_tip")
      v-icon(small, left) mdi-label
      span Allow Tip
  .subtitle-2 {{ item.price | currency }}/person
  v-md-preview.prose(:text="item.description")
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
};
</script>