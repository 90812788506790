<template lang="pug">
div
  v-select(
    :items="locations",
    item-text="name",
    item-value="id",
    v-model="selected"
  )
  v-btn(
    color="secondary",
    outlined,
    block,
    @click="connect",
    :disabled="!selected",
    :loading="loading"
  ) Select Location
</template>

<script>
export default {
  props: {
    locations: { type: Array, required: true },
    loading: { type: Boolean, default: false },
  },
  data() {
    return { selected: null };
  },
  methods: {
    connect() {
      const location = this.locations.find((l) => l.id === this.selected);
      this.$emit("select", location);
    },
  },
};
</script>