<template lang="pug">
v-dialog(v-model="dialog", width="400")
  template(v-slot:activator="{ on }")
    v-btn(v-on="on", color="error", outlined, small) Create a Test Order
  v-card(v-if="biz")
    v-toolbar(flat, dense)
      .subtitle-2 Create a Testing Online Order
    v-card-text
      span Do you want to send a testing online order to the business? You can use this to test printer setup, etc.
    v-card-actions
      v-spacer
      v-btn(color="secondary", :loading="loading", @click="send()") Send
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    async send() {
      if (!this.biz) return;
      this.loading = true;
      const params = { bizId: this.biz._id };
      try {
        await this.axios.post("/orders/createTest", params);
        this.$toast.success("Test order sent");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
