<template lang="pug">
v-row(wrap)
  v-col(
    cols="12",
    sm="6",
    md="4",
    lg="3",
    v-for="(group, index) in groups",
    :key="index + 'group'"
  )
    v-card(height="100%", flat)
      v-card-subtitle {{ group.title }}
      v-list(dense)
        v-list-item(
          v-for="item in group.items",
          :key="item.title",
          :to="item.to"
        )
          v-list-item-content
            v-list-item-title {{ item.title }}
</template>

<script>
export default {
  props: {
    groups: { type: Array, default: () => [] },
  },
};
</script>
