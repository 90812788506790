<template lang="pug">
tr(:class="color", @click="$emit('click')", role="button")
  td Week {{ week }}
  td {{ role.name }}
  td.text-right {{ role.regHours | hours(isDecimal) }}
  td.text-right {{ role.extHours | hours(isDecimal) }}
  td.text-right(v-if="showWage")
    span(v-if="role.regCost") {{ role.regCost | currency }}
    span(v-else) -
  td.text-right(v-if="showWage")
    span(v-if="role.extCost") {{ role.extCost | currency }}
    span(v-else) -
  td.text-right(v-if="showWage")
    span(v-if="role.cost") {{ role.cost | currency }}
    span(v-else) -
</template>

<script>
export default {
  props: ["role", "week", "isDecimal", "showWage"],
  computed: {
    color() {
      if (this.role.extHours > 0) return "orange--text";
      return "";
    },
  },
};
</script>
