<template lang="pug">
Page(title="Sales", :items="items", :doc="doc")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Home", url: "/sales/home" },
        { title: "Daily Report", url: "/sales/daily" },
        { title: "Monthly Report", url: "/sales/monthly" },
        { title: "Orders", url: "/sales/orders" },
        { title: "Actions", url: "/sales/actions" },
        { title: "Returns", url: "/sales/returns" },
        { title: "Pay In/Out", url: "/sales/payinout" },
        { title: "Stats", url: "/sales/stats" },
      ],
      doc: "https://docs.selflane.com/biz/performance/sales.html",
    };
  },
};
</script>
