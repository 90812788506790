<template lang="pug">
div
  v-progress-linear(indeterminate, v-if="loading", color="secondary")
  .d-flex.flex-row.align-center.justify-space-between.mb-3
    BizStartHour
    DaySelector(@select="load")
  Chart(
    :salesByHours="salesByHours",
    :showWage="showWage",
    :date="date",
    :laborReport="weekReport"
  )
  StartEnd
  LaborReport(ref="report", max-width="100%", default-mode="employees")
  List
</template>

<script>
import moment from "moment-timezone";
import { EventBus } from "@/event-bus.js";
import { mapGetters, mapActions } from "vuex";
import Chart from "./Chart";
import StartEnd from "./StartEnd";
import List from "@/components/HR/Punchcard/List/index";

export default {
  components: { Chart, List, StartEnd },
  data() {
    return {
      date: null,
      salesByHours: [],
      loading: false,
      weekReport: null,
    };
  },
  computed: {
    ...mapGetters(["biz", "useraccess", "pos"]),
    access() {
      if (!this.$auth.check() || !this.biz) return null;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      return this.useraccess.find((o) => o.user == userId && o.biz == bizId);
    },
    showWage() {
      return this.access?.privilege == 1 || this.access?.access_wage;
    },
    startDay() {
      return this.pos?.startDay || 0;
    },
    startHour() {
      return this.pos?.startHour || 0;
    },
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setPunchcards"]),
    async load(date) {
      if (date) this.date = date;
      if (!this.biz || !this.date) return;

      this.loading = true;
      const beginDate = moment(this.date).format("YYYY-MM-DD");
      const endDate = beginDate;
      const url = "report/salesByHour";
      const params = { bizId: this.biz._id, beginDate, endDate };
      this.salesByHours = await this.$api.order.custom(url, params);
      await this.getWeekReport();
      await this.loadPunchcards();
      this.$refs.report?.load(params);
      this.loading = false;
    },
    // load labor report for the week with dates that are before the selected date
    // if the selected date is the first day of the week, the report should be empty
    async getWeekReport() {
      // startDay is the default start day of the week
      // 1. find the start and end dates of the range
      const date = moment(this.date);
      let start = moment(this.date).day(this.startDay);
      if (start.isAfter(date)) start = start.subtract(1, "week");
      const beginDate = start.format("YYYY-MM-DD");
      let end = moment(this.date).subtract(1, "day");
      const endDate = end.format("YYYY-MM-DD");
      try {
        if (end.isSameOrAfter(start)) {
          const params = { bizId: this.biz._id, beginDate, endDate };
          this.weekReport = await this.$api.punchcard.custom(
            "laborReport",
            params
          );
        } else {
          this.weekReport = null; // no report if the selected date is the first day of the week
        }
      } catch (e) {
        this.weekReport = null;
      }
    },
    async loadPunchcards() {
      // shift begin and end by startHour
      const begin = moment(this.date)
        .startOf("day")
        .add(this.startHour, "hours")
        .valueOf();
      const end = moment(this.date)
        .endOf("day")
        .add(this.startHour, "hours")
        .valueOf();
      const criteria = {
        biz: this.biz._id,
        clockIn: { $gte: begin, $lte: end },
      };
      const result = await this.$api.punchcard.list({ criteria });
      this.setPunchcards(result);
    },
  },
};
</script>
