<template lang="pug">
v-row(wrap, v-if="items && items.length")
  v-col(cols="12", sm="6", v-for="(group, index) in groups", :key="index")
    StandardChart(:input="group", chartType="bar", maxWidth="100%")
</template>

<script>
export default {
  props: ["items", "year"],
  computed: {
    groups() {
      return [
        this.getData("orderValue", "Net Sales"),
        this.getData("expenseValue", "Expenses"),
        this.getData("payrollValue", "Payroll"),
        this.getData("balance", "Balance"),
        this.getData("nguests", "Guests Traffic", false),
      ];
    },
  },
  methods: {
    getData(property, title, isCurrency = true) {
      return {
        title: `${title} (${this.year})`,
        labels: this.items.map((o) => o.period),
        values: this.items.map((o) => o[property]),
        isCurrency,
      };
    },
  },
};
</script>
