<template lang="pug">
.s-vstack
  Doc
  v-divider
  Hours(:biz="biz", @updated="updated")
  v-divider
  Throttle
</template>

<script>
import Doc from "./Doc";
import Hours from "/libs/components/Biz/Hours";
import Throttle from "./Throttle";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  components: { Doc, Hours, Throttle },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    updated($event) {
      this.$store.dispatch("setBiz", $event);
    },
    async load() {
      if (!this.biz?._id) return;
      const result = await this.$api.biz.retrieve({
        criteria: { _id: this.biz._id },
      });
      this.$store.dispatch("setBiz", result);
    },
  },
};
</script>
