<template lang="pug">
.my-5
  .title.my-3.text-center Quick Order Action
  v-row(justify="center")
    v-card(v-if="order", width="95%", max-width="500")
      v-card-title
      v-card-text
        OrderHeader(:order="order")
      Items(:order="order")
      Payment(:order="order", @update="update")
</template>

<script>
import Items from "./Items";
import Payment from "./Payment";

export default {
  name: "OrderDetailPublic",
  components: { Items, Payment },
  props: ["orderId"],
  data() {
    return {
      order: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.orderId) return;
      const params = { criteria: { _id: this.orderId } };
      const res = await this.axios.post("/orders/retrieve", params);
      this.order = res.data;
    },
    update(data) {
      this.order = data;
    },
  },
};
</script>
