<template lang="pug">
v-col(:cols="cols", :sm="sm", :md="md")
  v-card(flat, height="100%")
    simple-table
      tbody
        AmountTableRow(
          name="Received Cash",
          :amount="totalCash",
          bold,
          mandatory
        )
        AmountTableRow(
          v-for="(item, index) in chargesCash",
          :key="index + 'cash'",
          :name="item.name",
          :amount="item.amount"
        )
        AmountTableRow(
          name="Received Tip",
          :amount="totalTip",
          bold,
          mandatory
        )
        AmountTableRow(
          v-for="(item, index) in chargesTip",
          :key="index + 'tip'",
          :name="item.name",
          :amount="item.tip"
        )
        AmountTableRow(
          name="Deposit (Cash - Tip)",
          :amount="diff",
          bold,
          mandatory
        )
</template>

<script>
import _ from "underscore";

export default {
  props: {
    items: { type: Array, required: true },
    cols: { type: Number, default: 12 },
    md: { type: Number, default: 4 },
    sm: { type: Number, default: 6 },
  },
  computed: {
    diff() {
      const cash = this.items?.reduce((a, b) => a + b.charge.cash, 0);
      const tip = this.items?.reduce((a, b) => a + b.charge.tip_total, 0);
      return cash - tip;
    },
    totalCash() {
      return this.items?.reduce((a, b) => a + b.charge.cash, 0);
    },
    chargesCash() {
      const items = this.items.flatMap((o) => o.charge.stations);
      return _.chain(items)
        .groupBy("method")
        .map((value, sta) => {
          const amount = value.reduce((a, b) => a + b.amount, 0);
          const name = `Station ${sta}`;
          return { name, amount };
        })
        .sortBy("name")
        .value();
    },
    totalTip() {
      return this.items?.reduce((a, b) => a + b.charge.tip_total, 0);
    },
    chargesTip() {
      const items = this.items.flatMap((o) => o.charge.items);
      return _.chain(items)
        .groupBy("method")
        .map((value) => {
          const tip = value.reduce((a, b) => a + b.tip, 0);
          const name = value[0].title;
          return { name, tip };
        })
        .sortBy("name")
        .value();
    },
  },
};
</script>