const state = {
  servershifts: []
}

const getters = {
  servershifts: state => state.servershifts
}

const actions = {
  setServershifts: ({ commit }, data) => { commit('setServershifts', data) },
  updateServershift: ({ commit }, data) => { commit('updateServershift', data) },
  addServershift: ({ commit }, data) => { commit('addServershift', data) },
  removeServershift: ({ commit }, data) => { commit('removeServershift', data) }
}

const mutations = {
  setServershifts(state, data) {
    state.servershifts = data
  },
  updateServershift(state, data) {
    state.servershifts = state.servershifts.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addServershift(state, data) {
    state.servershifts.push(data)
  },
  removeServershift(state, data) {
    state.servershifts = state.servershifts.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }