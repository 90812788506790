<template lang="pug">
div(v-if="report")
  v-card.my-3(outlined)
    v-toolbar(flat, dense)
      .subtitle-2 Itemized Sales Stats
      v-spacer
      Download(:report="report", :period="period")
    v-card-text.py-1
      span Stats on items categorized in P&L Groups and Courses in selected period: {{ period }}
    .table-box
      table
        thead
          tr
            th P&L Group
            th Course
            th Item
            th Quantity
            th Amount
            th Selected Modifier * Quantity
        tbody(v-for="(courseGroup, cindex) in report", :key="cindex")
          tr(
            v-for="(dish, dindex) in courseGroup.dishes",
            :key="dindex",
            :class="{ 'cell-top-border': dindex == 0 }"
          )
            th
              span(v-if="dindex == 0") {{ courseGroup.course.grp }}
            th
              span(v-if="dindex == 0") {{ courseGroup.course.name }}
            td {{ dish.name }}
            td {{ dish.quantity }}
            td {{ dish.amount | currency }}
            td
              ul
                li(v-for="(modifier, mindex) in dish.modifiers", :key="mindex") {{ modifier.name }} &times; {{ modifier.quantity }}
</template>

<script>
import Download from "./Download";

export default {
  name: "ItemizedSales",
  components: { Download },
  props: {
    report: { type: Array, default: null },
    period: { type: String, default: "" }, // YYYY-MM-DD to YYYY-MM-DD
  },
};
</script>

<style lang="sass" scoped>
.table-box
  max-height: 350px
  overflow: auto
  padding: 16px
  table
    border-collapse: collapse
    table-layout: fixed
    min-width: 100%
    border-bottom: solid thin #e0e0e0
    tr
      vertical-align: top
    tr.cell-top-border
      border-top: solid thin #e0e0e0
    th
      text-align: left
      font-size: 12px
    td
      padding-right: 30px
      font-size: 13px
      white-space: nowrap
</style>