const state = {
  purchases: []
}

const getters = {
  purchases: state => state.purchases
}

const actions = {
  setPurchases: ({ commit }, data) => { commit('setPurchases', data) },
  updatePurchase: ({ commit }, data) => { commit('updatePurchase', data) }
}

const mutations = {
  setPurchases(state, data) {
    state.purchases = data
  },
  updatePurchase(state, data) {
    state.purchases = state.purchases.map(o => {
      if (o._id === data._id) return data
      return o
    })
  }
}

export default { state, getters, actions, mutations }