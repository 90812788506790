<template lang="pug">
PartnerPage(title="Contacts", :doc="doc")
  List(:items="contacts")
</template>

<script>
import List from "/libs/components/CRM/Contact/List";

import { mapGetters } from "vuex";

export default {
  components: { List },
  data() {
    return {
      doc: "https://docs.selflane.com/partner/crm/contacts/",
    };
  },
  computed: {
    ...mapGetters(["salesOrganization"]),
    ...mapGetters("crm", ["contacts"]),
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      const organization = this.salesOrganization._id;
      if (!organization) {
        this.$store.dispatch("crm/setContacts", []);
        return;
      }
      try {
        const criteria = { organization };
        const result = await this.$api.crm.contact.list({ criteria });
        this.$store.dispatch("crm/setContacts", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load contacts");
      }
    },
  },
};
</script>