<template lang="pug">
div
</template>

<script>
import _ from "underscore";

export default {
  methods: {
    async load() {
      const userId = this.$auth?.user()?._id;
      if (!userId) {
        this.$store.dispatch("entp/setAccesses", []);
        return;
      }
      const params = { criteria: { user: userId } };
      const result = await this.$api.entp.access.list(params);
      this.$store.dispatch("entp/addAccesses", result);
      this.loadEnterprises(result);
    },
    async loadEnterprises(accesses) {
      const ids = this.pluck(accesses, "enterprise");
      if (!ids?.length) {
        this.$store.dispatch("entp/setEnterprises", []);
        return;
      }
      const params = { criteria: { _id: { $in: ids } } };
      const result = await this.$api.entp.enterprise.list(params);
      this.$store.dispatch("entp/setEnterprises", result);
    },
    // pluck property from given list
    pluck(list, property) {
      return _.chain(list).pluck(property).compact().value();
    },
  },
};
</script>