<template lang="pug">
div
  .d-flex.flex-row.justify-center 
    NewCustomer.mb-10
  CouponIsland.mb-10
  WeeklySpecial.mb-10
</template>

<script>
import NewCustomer from "./NewCustomer";
import CouponIsland from "./CouponIsland";
import WeeklySpecial from "./WeeklySpecial";

export default {
  components: { NewCustomer, CouponIsland, WeeklySpecial },
  data() {
    return {
      items: [
        {
          title: "Influencer",
          url: "/marketing/online/influencer",
        },
        {
          title: "Coupon Island",
          url: "/marketing/online/programs",
        },
      ],
    };
  },
};
</script>
