<template lang="pug">
div(v-if="$auth.check()")
  v-stepper(v-model="step", v-if="step == 1 || (step < 4 && isOwner)")
    v-stepper-header
      v-stepper-step(:complete="step > 1", step="1") Create Business
      v-stepper-step(:complete="step > 2", step="2") Menu
      v-stepper-step(:complete="step > 3", step="3") Taxpayer Information
    v-stepper-items
      v-stepper-content(step="1")
        v-row(justify="center")
          CreateBiz
      v-stepper-content(step="2")
        MenuGuide
        Menu
      v-stepper-content(step="3")
        Taxpayer
  NavBlocks(v-else)
  Guides
</template>

<script>
import CreateBiz from "@/components/Biz/CreateBiz";
import MenuGuide from "./MenuGuide";
import Menu from "@/components/Settings/Menu/index.vue";
import Taxpayer from "@/components/Owner/Payments/index.vue";
import NavBlocks from "./NavBlocks";
import { mapGetters } from "vuex";
import Guides from "@/components/SelflaneServices/Online/Guides/index.vue";

export default {
  components: { CreateBiz, MenuGuide, Menu, Taxpayer, NavBlocks, Guides },
  computed: {
    ...mapGetters(["biz", "useraccess"]),
    hasMenu() {
      return (
        this.biz.menus &&
        this.biz.menus.length > 0 &&
        this.biz.courses &&
        this.biz.courses.length > 0 &&
        this.biz.dishes &&
        this.biz.dishes.length > 0
      );
    },
    hasManagedAccount() {
      return this.biz?.payments?.managed_account;
    },
    access() {
      if (!this.$auth.check() || !this.biz) return null;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      return this.useraccess.find((o) => o.user == userId && o.biz == bizId);
    },
    isOwner() {
      return this.access?.privilege == 1;
    },
    step() {
      if (!this.biz) return 1;
      else if (!this.hasMenu) {
        return 2;
      } else if (!this.hasManagedAccount) {
        return 3;
      } else {
        return 9999;
      }
    },
  },
};
</script>
