<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";
import moment from "moment-timezone";

export default {
  props: {
    report: { type: Array, required: true },
    title: { type: String, required: true },
    year: { type: Number, required: true },
    sum: { type: Object, required: true },
  },
  methods: {
    download() {
      if (!this.report) return;
      const currency = this.$options.filters.currency;
      const csv = new CSV();
      csv.addRow([
        "Period",
        "#Sales",
        "Subtotal",
        "Gift",
        "Tax",
        "Tip",
        "Discount",
        "Other",
        "Selflane Charges",
        "Total",
        "Proessing Fee",
        "Balance",
      ]);
      this.report.forEach((item) => {
        csv.addRow([
          this.time(item),
          item.count,
          currency(item.dish),
          currency(item.gift),
          currency(item.tax),
          currency(item.tip),
          currency(item.deduction),
          currency(item.fee),
          currency(item.selflane),
          currency(item.total),
          currency(item.application_fee - item.selflane),
          currency(item.total - item.application_fee),
        ]);
      });
      csv.addRow([
        this.sum.period,
        this.sum.count,
        currency(this.sum.dish),
        currency(this.sum.gift),
        currency(this.sum.tax),
        currency(this.sum.tip),
        currency(this.sum.deduction),
        currency(this.sum.fee),
        currency(this.sum.selflane),
        currency(this.sum.total),
        currency(this.sum.application_fee - this.sum.selflane),
        currency(this.sum.total - this.sum.application_fee),
      ]);
      csv.save(this.year + " " + this.title);
    },
    time(item) {
      if (item.month) {
        return this.$options.filters.month(item.month);
      } else {
        const begin = moment()
          .year(this.year)
          .week(item.week + 1)
          .startOf("week")
          .format("MMM D");
        const end = moment()
          .year(this.year)
          .week(item.week + 1)
          .endOf("week")
          .format("MMM D");
        return begin + " - " + end;
      }
    },
  },
};
</script>
