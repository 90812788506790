<template lang="pug">
div
  Events.mb-5(:event-id="event_id", @select="loadSales")
  Table(:tickets="tickets", :loading="loading")
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions, mapGetters } from "vuex";
import Events from "./../Dashboard/List";
import Table from "./Table";

export default {
  components: { Events, Table },
  data() {
    return {
      tickets: [],
      event_id: null,
      loading: false,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.loadEvents();
    EventBus.$on("switch-biz", this.loadEvents);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.loadEvents);
  },
  methods: {
    ...mapActions(["setEvents"]),
    async loadEvents() {
      this.tickets = [];
      if (!this.biz) return;
      const criteria = { biz_id: this.biz._id };
      let event;
      try {
        const events = await this.$api.event.entry.list({ criteria });
        this.setEvents(events);
        event = this.getDefaultEvent(data);
      } catch (e) {
        //
      }
      this.loadSales(event?._id);
    },
    getDefaultEvent(events) {
      if (!events?.length) return;
      const now = Date.now();
      const filtered = events
        .filter((event) => event.event_time.begin > now)
        .sort((a, b) => a.event_time.begin - b.event_time.begin);
      if (filtered?.length) return filtered[0];
      return events[events.length - 1];
    },
    async loadSales(id) {
      if (id) this.event_id = id;
      if (!this.event_id) return;
      this.loading = true;
      const criteria = { event_id: this.event_id, status: { $ne: false } };
      try {
        this.tickets = await this.$api.event.ticket.list({ criteria });
      } catch (e) {
        //
      }
      this.loading = false;
    },
  },
};
</script>