<template lang="pug">
IconBtn(
  @click="recreate()",
  title="Recreate",
  icon="mdi-sync",
  :loading="loading"
)
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["month"],
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    async recreate() {
      if (!this.biz || !this.biz._id) return;
      this.loading = true;
      const params = { bizId: this.biz._id, month: this.month };
      try {
        const { data } = await this.axios.post("/bizOps/recreate", params);
        this.$toast.success(data);
        this.$emit("done");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
