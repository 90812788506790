const state = {
  refunds: []
}

const getters = {
  refunds: state => state.refunds
}

const actions = {
  setRefunds: ({ commit }, data) => { commit('setRefunds', data) },
  updateRefund: ({ commit }, data) => { commit('updateRefund', data) },
  addRefund: ({ commit }, data) => { commit('addRefund', data) }
}

const mutations = {
  setRefunds(state, data) {
    state.refunds = data
  },
  updateRefund(state, data) {
    state.refunds = state.refunds.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addRefund(state, data) {
    state.refunds.unshift(data)
  }
}

export default { state, getters, actions, mutations }