<template lang="pug">
v-card(v-if="item", flat, height="100%")
  v-toolbar(dense, flat, :color="toolbarColor", dark)
    .subtitle-2 {{ item.name }} | {{ " " }}
      span.text-capitalize {{ item.status }}
    v-spacer
    v-btn.mr-2(@click.stop="$emit('edit')", icon, small)
      v-icon(small) mdi-pencil
    MovePosition(:setting="setting", :item="item", :change="-1")
    MovePosition(:setting="setting", :item="item", :change="1")
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        v-btn(v-on="on", icon, small)
          v-icon(small) mdi-dots-vertical
      v-list
        v-list-item(@click="confirmDelete()")
          v-list-item-title Delete
  v-card-text
    .s-vstack
      PriceDescription(:item="item")
      Capacity(:item="item")
      Availability(:item="item")
      .subtitle-2.info--text Bookable Window: {{ item.days_in_advance_min }} to {{ item.days_in_advance_max }} days
</template>

<script>
import Availability from "./Availability";
import Capacity from "./Capacity";
import MovePosition from "./MovePosition";
import PriceDescription from "./PriceDescription";

export default {
  components: { MovePosition, PriceDescription, Capacity, Availability },
  props: {
    setting: { type: Object, default: null },
    item: { type: Object, default: null },
  },
  computed: {
    toolbarColor() {
      return this.item.status == "active" ? "info" : "error";
    },
  },
  methods: {
    edit() {
      this.$emit("edit");
    },
    confirmDelete() {
      confirm(
        "Are you sure you want to delete this item? Change status to PAUSED might be a better option."
      ) && this.$emit("delete");
    },
  },
};
</script>
