<template lang="pug">
Page(title="In-Store Rewards", :doc="doc")
  template(v-slot:header)
    CreateForm
  List
</template>
  
<script>
import CreateForm from "./CreateForm";
import List from "./List";

export default {
  components: { CreateForm, List },
  data() {
    return {
      doc: "https://docs.selflane.com/biz/marketing/in-store.html",
    };
  },
};
</script>
