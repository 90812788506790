const state = {
  stripePersons: []
}

const getters = {
  stripePersons: state => state.stripePersons
}

const actions = {
  setStripePersons: ({
    commit
  }, stripePersons) => {
    commit('setStripePersons', stripePersons)
  },
  updateStripePerson: ({
    commit
  }, stripePerson) => {
    commit('updateStripePerson', stripePerson)
  },
  addStripePerson: ({
    commit
  }, stripePerson) => {
    commit('addStripePerson', stripePerson)
  },
  removeStripePerson: ({
    commit
  }, stripePerson) => {
    commit('removeStripePerson', stripePerson)
  }
}

const mutations = {
  setStripePersons(state, stripePersons) {
    state.stripePersons = stripePersons
  },
  updateStripePerson(state, stripePerson) {
    state.stripePersons = state.stripePersons.map(person => {
      if (person.id === stripePerson.id) return stripePerson
      return person
    })
  },
  addStripePerson(state, stripePerson) {
    state.stripePersons.push(stripePerson)
  },
  removeStripePerson(state, stripePerson) {
    state.stripePersons = state.stripePersons.filter(person => person.id !== stripePerson.id)
  }
}

export default { state, getters, actions, mutations }