<template lang="pug">
div
  v-card(flat)
    v-toolbar(flat, dense)
      .subtitle-2 Point Vouchers
      v-chip.ml-2(small, outlined, v-if="pointvouchers") {{ pointvouchers.length }}
      v-spacer
      IconBtn(@click="create()", title="Add", icon="mdi-plus")
    simple-table
      tbody
        tr(
          v-for="(item, index) in pointvouchers",
          :key="index",
          @click="edit(item)",
          role="button"
        )
          td {{ item.name }}
          td {{ item.pts }} PTS
          td {{ item.amount | currency }}
          td.text-right
            DeleteOne(:item="item")
  Form(:input="toEdit", @done="closeForm")
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";
import Form from "./Form";
import DeleteOne from "./DeleteOne";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Form, DeleteOne },
  data() {
    return { toEdit: null };
  },
  computed: { ...mapGetters(["biz", "pointvouchers"]) },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setPointVouchers"]),
    async load() {
      if (!this.biz || !this.biz._id) return;
      const params = { criteria: { biz: this.biz._id } };
      const result = await this.$api.pointVoucher.list(params);
      this.setPointVouchers(result);
    },
    getCourseNames(ids) {
      if (!ids || !this.biz) return "";
      let names = [];
      _.each(ids, (id) => {
        const course = this.biz.courses?.find((o) => o._id === id);
        if (course) names.push(course.name);
      });
      return names.sort().join(", ");
    },
    edit(item) {
      this.toEdit = item;
    },
    create() {
      if (!this.biz?._id) return;
      this.toEdit = {
        biz: this.biz._id,
        name: "",
        amount: 5,
        pts: 100,
      };
    },
    closeForm() {
      this.toEdit = null;
    },
  },
};
</script>
