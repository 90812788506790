<template lang="pug">
Page(
  :avatar="avatar",
  :name="name",
  :profile_url="profile_url",
  :prefix="prefix",
  :title="title",
  :doc="doc"
)
  template(v-slot:header)
    slot(name="header")
  router-view(v-if="!loading")
  slot(name="content")
  slot
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    greeting: { type: Boolean, default: true },
    title: { type: String, default: null },
    items: { type: Array, default: () => [] },
    doc: { type: String, default: null },
    loading: Boolean,
    description: { type: String, default: null },
  },
  data() {
    return {
      prefix: "Selflane Partner",
    };
  },
  computed: {
    ...mapGetters(["salesPerson"]),
    avatar() {
      if (!this.greeting) return null;
      const pic = this.salesPerson?.profile_picture;
      if (pic) return process.env.VUE_APP_MEDIAHOST + pic;
      return null;
    },
    name() {
      if (!this.greeting) return null;
      return this.salesPerson?.first_name;
    },
    profile_url() {
      if (!this.greeting) return null;
      return "/partner/person";
    },
  },
};
</script>