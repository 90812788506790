<template lang="pug">
simple-table
  thead
    tr
      th Date
      th Type
      th.text-right Status
  tbody
    tr(
      v-for="(item, index) in bizDocuments",
      :key="index",
      @click="handleView(item)",
      role="button"
    )
      td {{ item.created | date }}
      td {{ item.file_type }}
      td.text-right {{ getStatus(item.status) }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["bizDocuments", "biz"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBizDocuments"]),
    async load() {
      if (!this.biz) return;
      const params = { criteria: { biz: this.biz._id } };
      const result = await this.$api.b.document.list(params);
      this.setBizDocuments(result);
    },
    getStatus(value) {
      if (value == 0) {
        return "Created";
      } else if (value == -1) {
        return "Rejected";
      } else if (value == 1) {
        return "Accepted";
      }
    },
    handleView(item) {
      const path = "https://media.selflane.com/";
      EventBus.$emit("show-biz-document", path + item.file_path);
    },
  },
};
</script>

