<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Time
        th Amount
        th Name
        th Last4
        th Auth
    tbody
      tr(
        v-for="(item, index) in result",
        :key="index",
        @click="showOrder(item.order)",
        role="button"
      )
        td {{ item.created | datetime("MMM D, h:mm A") }}
        td {{ item.amount | currency }}
        td {{ item.name }}
        td {{ item.last4 }}
        td {{ item.approve }}
  v-card-actions
    Download(:result="result")
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Download from "./Download";

export default {
  components: { Download },
  props: ["range"],
  data: () => ({
    result: null,
  }),
  computed: {
    ...mapGetters(["biz"]),
  },
  watch: {
    range: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.range) return;
      const options = {
        criteria: {
          biz: this.biz._id,
          created: {
            $gt: this.range.begin,
            $lte: this.range.end,
          },
        },
      };
      this.result = null;
      const res = await this.axios.post("/creditReturn/list", options);
      this.result = res.data;
    },
    showOrder(orderId) {
      if (!orderId) return;
      EventBus.$emit("show-order-detail", orderId);
    },
  },
};
</script>
