<template lang='pug'>
.item(v-if="amount || required", :class="{ 'green--text': green }")
  .item-name {{ title }}
  .item-detail {{ amount | currency }}
</template>

<script>
export default {
  props: ["title", "amount", "required", "green"],
};
</script>


<style lang="sass" scoped>
.item
  font-size: 13px
  display: flex
  align-items: center

  &-name
    flex-grow: 1

  &-detail
    font-weight: 500
</style>