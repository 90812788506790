<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on", @click="open", title="Add", icon="mdi-plus")
  v-card(v-if="biz && promotion")
    v-toolbar(dense, flat)
      .subtitle-2 Create a Promotion for {{ biz.name }}
    v-divider
    v-card-text
      v-form
        v-switch(
          v-model="applyToChain",
          color="secondary",
          label="Apply Promotion to the Entire Chain",
          v-if="chain",
          dense
        )
        v-row(dense)
          v-col(cols="4")
            v-select(v-model="promotion.type", :items="types", label="Type")
          v-col(cols="4")
            div(v-if="promotion.type == 'pct'")
              v-text-field(
                v-model="promotion.pct",
                label="Discount %",
                suffix="%"
              )
            div(v-else)
              PriceField(v-model.number="promotion.fix", label="Discount $")
          v-col(cols="4")
            v-text-field(v-model="promotion.total", label="Total Coupons")
          v-col(cols="4")
            PriceField(
              v-model.number="promotion.minimum",
              label="Minimum Purchase"
            )
          v-col(cols="4", v-if="promotion.type == 'pct'")
            PriceField(
              v-model.number="promotion.limit",
              label="Limit per User"
            )
        v-text-field(
          v-model="promotion.title",
          label="Title",
          placeholder="optional"
        )
        v-textarea(
          v-model="promotion.desc",
          label="Description",
          placeholder="optional",
          counter="280",
          rows="2"
        )
        .text-center.my-3
          .subtitle-2 Select Expiration Date
          v-date-picker.my-3(
            v-model="expiry",
            :min="minDate",
            color="secondary",
            no-title
          )
          v-checkbox(
            v-model="agreed",
            color="secondary",
            label="I understand that once a promotion is created it cannot be edited or canceled."
          )
          .body-2 
        v-btn(
          block,
          color="secondary",
          :loading="loading",
          :disabled="!agreed",
          @click="save"
        ) Create Promotion
</template>

<script>
import moment from "moment-timezone";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["chain", "show"],
  data() {
    return {
      dialog: false,
      agreed: false,
      loading: false,
      minDate: moment().format("YYYY-MM-DD"),
      promotion: null,
      expiry: null,
      applyToChain: true,
      types: [
        { text: "Fixed", value: "fix" },
        { text: "Percentage", value: "pct" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    ...mapActions(["addPromotion"]),
    open() {
      if (!this.biz) return;
      this.promotion = {
        biz: this.biz._id,
        name: this.biz.name,
        logo: this.biz.logo,
        country: this.biz.address.country,
        states: [this.biz.address.state],
        chain: null,
        type: "fix",
        pct: 5,
        fix: 5,
        minimum: 10,
        limit: 100,
        total: 500,
        title: "",
        desc: "",
        instore: false,
        expiry: null,
      };
      this.expiry = moment().add(3, "months").format("YYYY-MM-DD");
    },
    async save() {
      if (!this.biz) return;
      if (this.promotion.type == "pct") {
        if (this.promotion.pct < 5 || this.promotion.pct > 50) {
          return this.$toast.error("Discount should be between 5% and 50%");
        }
      }
      if (this.promotion.total < 50) {
        return this.$toast.error("Total coupons should be greater than 50");
      }
      if (this.promotion.minimum < 5) {
        return this.$toast.error("Minimum purchase should be greater than $5");
      }
      if (this.promotion.minimum > 100) {
        return this.$toast.error("Minimum purchase should be less than $100");
      }
      this.loading = true;
      this.promotion.biz = this.biz._id;
      if (this.applyToChain && this.chain)
        this.promotion.chain = this.chain._id;
      this.promotion.expiry = moment(this.expiry).endOf("day").valueOf();
      try {
        const result = await this.$api.promotion.create(this.promotion);
        this.addPromotion(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
