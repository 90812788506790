<template lang="pug">
div
  v-row.mb-3(dense, wrap)
    v-col(cols="12", sm="6", md="4")
      Orders(:items="items")
    v-col(cols="12", sm="6", md="4")
      Sales(:items="items")
    v-col(cols="12", sm="6", md="4")
      Payments(:items="items")
    Cards(:items="items")
    ProfitLoss(:items="items")
  .d-flex.flex-row.justify-end
    Download(:items="items")
</template>

<script>
import Cards from "./Cards";
import Download from "./Download";
import Orders from "./Orders";
import Payments from "./Payments";
import ProfitLoss from "./ProfitLoss";
import Sales from "./Sales";

export default {
  components: { Orders, Sales, Payments, Cards, ProfitLoss, Download },
  props: ["items"],
};
</script>