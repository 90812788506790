<template lang="pug">
v-card(flat, height="100%")
  simple-table
    thead
      AmountTableRow(
        name="Total Orders",
        :amount="total",
        bold,
        mandatory,
        :currency="false"
      )
    tbody
      AmountTableRow(name="Closed", :amount="closed", :currency="false")
      AmountTableRow(
        name="Open",
        :amount="open",
        :currency="false",
        @click="showOpen",
        role="button",
        button
      )
      AmountTableRow(
        name="Canceled",
        :amount="canceled",
        :currency="false",
        @click="showCanceled",
        role="button",
        button
      )
</template>

<script>
import { EventBus } from "@/event-bus";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
const select = "orderer needed created orderNumber status payment";

export default {
  props: ["items"],
  computed: {
    ...mapGetters(["biz"]),
    total() {
      return this.items?.reduce((a, b) => a + b.orders.total, 0);
    },
    open() {
      return this.items?.reduce((a, b) => a + b.orders.open, 0);
    },
    closed() {
      return this.items?.reduce((a, b) => a + b.orders.closed, 0);
    },
    canceled() {
      return this.items?.reduce((a, b) => a + b.orders.canceled, 0);
    },
    begin() {
      if (this.items?.length) {
        return moment(this.items[0].date).valueOf();
      }
      return 0;
    },
    end() {
      if (this.items?.length) {
        return moment(this.items[this.items.length - 1].date)
          .endOf("day")
          .valueOf();
      }
      return 0;
    },
  },
  methods: {
    showOpen() {
      if (!this.begin || !this.biz) return;
      const params = {
        criteria: {
          "seller.id": this.biz._id,
          needed: { $gte: this.begin, $lte: this.end },
          status: { $gte: 0, $lt: 3 },
        },
        select,
      };
      EventBus.$emit("show-orders-dialog", params);
    },
    showCanceled() {
      if (!this.begin || !this.biz) return;
      const params = {
        criteria: {
          "seller.id": this.biz._id,
          needed: { $gte: this.begin, $lte: this.end },
          status: -1,
        },
        select,
      };
      EventBus.$emit("show-orders-dialog", params);
    },
  },
};
</script>