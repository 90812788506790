<template lang="pug">
div
  UserAccess
  v-navigation-drawer(
    v-model="drawer",
    app,
    fixed,
    v-if="$auth.check()",
    :clipped="$vuetify.breakpoint.lgAndUp",
    width="250"
  )
    NavDrawer(:title="title")
  v-app-bar(
    app,
    dark,
    flat,
    color="primary",
    :clipped-left="$vuetify.breakpoint.lgAndUp"
  )
    v-app-bar-nav-icon(@click="drawer = !drawer", v-if="$auth.check()")
    v-btn(icon, @click="toHome()")
      v-icon $selflane
    .title.hidden-xs-only.ml-2 {{ title }}
    v-spacer
    CorpSearchBtn
    NavSelector
    Announcement
  CorpSearch
</template>

<script>
import UserAccess from "./UserAccess";
import NavSelector from "./NavSelector";
import NavDrawer from "./NavDrawer";
import CorpSearchBtn from "./CorpSearchBtn";
import CorpSearch from "./CorpSearch";
import Announcement from "./Announcement";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: {
    UserAccess,
    NavDrawer,
    NavSelector,
    CorpSearchBtn,
    CorpSearch,
    Announcement,
  },
  data() {
    return { drawer: true };
  },
  computed: {
    ...mapGetters(["navState"]),
    title() {
      if (this.navState == "partner") return "Selflane Partner";
      else if (this.navState == "enterprise") return "Selflane Enterprise";
      else return "Selflane Business";
    },
  },
  mounted() {
    if (this.navState == "create") {
      this.drawer = false;
    }
    EventBus.$on("start-create-biz", this.onStart);
  },
  destroyed() {
    EventBus.$off("start-create-biz", this.onStart);
  },
  methods: {
    onStart() {
      this.drawer = false;
    },
    toHome() {
      const path = this.$router.history.current.path;
      if (this.navState == "partner") {
        if (path != "/partner") this.$router.push("/partner");
      } else if (this.navState == "enterprise") {
        if (path != "/enterprise") this.$router.push("/enterprise");
      } else if (this.navState == "create") {
        if (path != "/create") this.$router.push("/create");
      } else {
        if (path != "/") this.$router.push("/");
      }
    },
  },
};
</script>
