<template lang="pug">
v-list-item(@click="logout")
  v-list-item-action
    v-icon mdi-logout
  v-list-item-content
    v-list-item-title.body-2 Sign Out
    v-list-item-subtitle.caption {{ $auth.user().email }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  methods: {
    logout() {
      this.$auth.logout();
      EventBus.$emit("logout");
    },
  },
};
</script>
