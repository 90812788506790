<template lang="pug">
v-list-item-action
  v-icon(v-if="item.connected", color="success", small) mdi-check
  v-btn(
    v-else,
    color="secondary",
    x-small,
    @click="connect(item)",
    :loading="loading"
  ) Connect
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
    biz: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async connect(item) {
      this.loading = true;
      const params = {
        bizId: this.biz._id,
        url: item.url,
        method: item.method || "post",
        event: item.event,
      };
      try {
        const result = await this.$api.seven.webhook.create(params);
        this.$toast.success("Webhook connected.");
        this.$emit("connected", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>