<!--
  Set user access on login and logout.
  Access includes: user-level, chain-level, enterprise-level, and corp-level (selflane team).
-->

<template lang="pug">
div
</template>

<script>
import { EventBus } from "@/event-bus";
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "biz",
      "useraccess",
      "bizAccounts",
      "userChainAccess",
      "corpaccess",
      "salesPersons",
    ]),
    ...mapGetters("entp", ["enterprises", "regions", "accesses"]),
    userId() {
      return this.$auth?.user()?._id;
    },
    /// if the user is a Selflane Corp user
    isSelflaneAccount() {
      const user = this.$auth.user();
      return user?.email?.includes("@selflane.com");
    },
    /// check if user has access to the current biz
    hasAccess() {
      if (!this.biz) return false;
      // check corp access
      if (this.corpaccess && this.corpaccess.user == this.userId) return true;

      // check user access
      if (this.bizAccounts) {
        return this.bizAccounts.some((o) => o._id == this.biz._id);
      }
      // check enterprise access
      const enterprise = this.enterprises?.find((o) =>
        o.bizs.includes(this.biz._id)
      );
      if (enterprise) {
        const myAccess = this.accesses?.find(
          (o) => o.enterprise == enterprise._id && o.user == this.userId
        );
        if (myAccess.privilege == 1) return true;
        if (myAccess.privilege == 2) {
          const region = this.regions?.find(
            (o) =>
              o.enterprise == enterprise._id &&
              o.bizs.includes(this.biz._id) &&
              myAccess.regions.includes(o._id)
          );
          if (region) return true;
        }
      }
      return false;
    },
  },
  watch: {
    userId() {
      this.load();
    },
  },
  mounted() {
    this.reset();
    this.load();
    EventBus.$on("login", this.load);
    EventBus.$on("logout", this.reset);
  },
  destroyed() {
    EventBus.$off("login", this.load);
    EventBus.$off("logout", this.reset);
  },
  methods: {
    ...mapActions([
      "setUserAccesses",
      "setUserChainAccesses",
      "setBizAccounts",
      "addBizAccounts",
      "setChainAccounts",
      "setCorpAccess",
      "setSalesPersons",
      "setSalesOrganizations",
    ]),
    async load() {
      if (!this.userId) {
        this.reset();
        return;
      }
      // 1) load user access
      try {
        const params = { criteria: { user: this.userId } };
        const result = await this.$api.b.access.list(params);
        this.setUserAccesses(result);
      } catch (e) {
        //
      }
      // 2) load corp access
      if (this.isSelflaneAccount) {
        try {
          const params = { user_id: this.userId };
          const result = await this.$api.corp.access.retrieve(params);
          this.setCorpAccess(result);
        } catch (e) {
          this.setCorpAccess();
        }
      } else {
        this.setCorpAccess();
      }
      // 3) load partner access
      try {
        const params = { criteria: { user: this.userId } };
        const result = await this.$api.salesforce.person.list(params);
        this.setSalesPersons(result);
      } catch (e) {
        //
      }
      // 4) load sales organizations
      try {
        const ids = this.pluck(this.salesPersons, "organization");
        if (ids?.length) {
          const params = { criteria: { _id: { $in: ids } } };
          const result = await this.$api.salesforce.organization.list(params);
          this.setSalesOrganizations(result);
        }
      } catch (e) {
        //
      }
      this.loadBizs();
    },
    // Biz accounts are the bizs that the user has access to
    async loadBizs() {
      const ids = this.pluck(this.useraccess, "biz");
      if (!ids?.length) {
        this.$store.dispatch("setBiz", null);
        this.setBizAccounts([]);
        return;
      }
      const params = {
        criteria: { _id: { $in: ids } },
        select: "name short_name address enterprise",
      };
      const result = await this.$api.biz.list(params);
      this.setBizAccounts(result);
      this.initBiz();
      this.loadChainAccess();
      this.loadEnterpriseAccesses();
    },
    // init biz to the first biz in the list
    // only if the current biz is not in the list
    async initBiz() {
      if (this.hasAccess) return;
      if (!this.bizAccounts?.length) return;
      const item = _.chain(this.bizAccounts).sortBy("name").first().value();
      if (!item?._id) return;
      try {
        const result = await this.$api.biz.retrieve({
          criteria: { _id: item._id },
        });
        this.$store.dispatch("setBiz", result);
        const service = await this.$api.service.account.retrieve({
          criteria: { biz: result._id },
        });
        this.$store.dispatch("setBizService", service);
        EventBus.$emit("switch-biz");
      } catch (e) {
        //
      }
    },
    async loadChainAccess() {
      if (!this.userId) {
        this.setUserChainAccesses([]);
        return;
      }
      const params = { criteria: { user: this.userId } };
      const result = await this.$api.chain.access.list(params);
      this.setUserChainAccesses(result);
      this.loadChains();
    },
    async loadChains() {
      if (!this.userChainAccess) return;
      const ids = this.pluck(this.userChainAccess, "chain");
      if (!ids || !ids.length) {
        this.setChainAccounts([]);
        return;
      }
      const params = { criteria: { _id: { $in: ids } } };
      const result = await this.$api.chain.account.list(params);
      this.setChainAccounts(result);
    },
    async loadEnterpriseAccesses() {
      if (!this.userId) {
        this.$store.dispatch("entp/setAccesses", []);
        return;
      }
      const params = { criteria: { user: this.userId } };
      const result = await this.$api.entp.access.list(params);
      this.$store.dispatch("entp/addAccesses", result);
      this.loadEnterprises(result);
    },
    async loadEnterprises(accesses) {
      const ids = this.pluck(accesses, "enterprise");
      if (!ids?.length) {
        this.$store.dispatch("entp/setEnterprises", []);
        return;
      }
      const params = { criteria: { _id: { $in: ids } } };
      const result = await this.$api.entp.enterprise.list(params);
      this.$store.dispatch("entp/setEnterprises", result);
      // load regions
      const enterpriseIds = result.map((o) => o._id);
      const params2 = { criteria: { enterprise: { $in: enterpriseIds } } };
      const regions = await this.$api.entp.region.list(params2);
      this.$store.dispatch("entp/setRegions", regions);
      // load enterprise bizs
      let bizIds = [];
      this.enterprises?.forEach((enterprise) => {
        const access = this.accesses?.find(
          (o) => o.enterprise == enterprise._id && o.user == this.userId
        );
        if (access.privilege == 1) {
          bizIds.push(...enterprise.bizs);
        } else if (access.privilege == 2) {
          const regions = this.regions?.filter((o) =>
            access.regions.includes(o._id)
          );
          bizIds.push(...regions.flatMap((o) => o.bizs));
        }
      });
      bizIds = _.uniq(bizIds);
      if (!bizIds?.length) return;
      const params3 = { criteria: { _id: { $in: bizIds } } };
      const result3 = await this.$api.biz.list(params3);
      this.addBizAccounts(result3);
    },
    // pluck property from given list
    pluck(list, property) {
      return _.chain(list).pluck(property).compact().value();
    },
    reset() {
      this.setUserAccesses([]);
      this.setBizAccounts([]);
      this.setUserChainAccesses([]);
      this.setChainAccounts([]);
      this.$store.dispatch("entp/setEnterprises", []);
      this.$store.dispatch("entp/setAccesses", []);
    },
  },
};
</script>