<template lang="pug">
v-btn(
  @click="handleReopen()",
  :disabled="!visible",
  color="secondary",
  outlined,
  small,
  :loading="loading"
)
  span.text-notransform Reopen
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order"],
  data() {
    return { loading: false };
  },
  computed: {
    visible() {
      return this.order && this.order.status == 3;
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async handleReopen() {
      if (!this.order || !this.order._id) return;
      this.loading = true;
      const params = { orderId: this.order._id };
      try {
        const res = await this.axios.post("/orders/reopen", params);
        this.updateOrder(res.data);
        this.$toast.success("Order reopened.");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>