<template lang="pug">
v-footer(dark, padless)
  v-card.navy(flat, width="100%")
    v-container.py-1
      Ending
      v-divider.my-1
      LangSocial
</template>

<script>
import LangSocial from "./LangSocial/index";
import Ending from "./Ending";

export default {
  name: "Footer",
  components: { LangSocial, Ending },
};
</script>
