<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="log")
    simple-table
      thead
        tr
          th Time
          th Action
      tbody
        tr(v-for="(item, index) in log", :key="index")
          td {{ item.time | datetime }}
          td(style="white-space: normal") {{ item.text }}
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      log: [],
      dialog: false,
    };
  },
  mounted() {
    EventBus.$on("show-punchcard-log", this.open);
  },
  destroyed() {
    EventBus.$off("show-punchcard-log", this.open);
  },
  methods: {
    open(log) {
      this.log = log;
      this.dialog = true;
    },
  },
};
</script>