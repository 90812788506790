<template lang="pug">
v-card(v-if="data", flat)
  Table(:items="data.associates")
    template(v-slot:header)
      thead
        tr
          th Pay In/Out
          th.text-right {{ data.balance | currency }}
</template>

<script>
import Table from "./Table";

export default {
  components: { Table },
  props: ["data"],
};
</script>
