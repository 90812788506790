<template lang="pug">
v-card(flat)
  v-toolbar(dense, flat)
    .subtitle-2 Self Delivery
    v-spacer
    Form
  simple-table(v-if="biz.orderType.delivery.status")
    tbody
      tr
        th Delivery Fee
        td.text-right {{ biz.orderType.delivery.price | currency }}
      tr(v-if="zoneStatus")
        td Zone
        td
      tr(v-else)
        th Max Radius
        td.text-right {{ biz.orderType.delivery.radius }} {{ unit }}
      tr
        th Minimum Purchase
        td.text-right {{ biz.orderType.delivery.minimum_purchase | currency }}
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";

export default {
  name: "SelfDeliv",
  components: { Form },
  computed: {
    ...mapGetters(["biz"]),
    zoneStatus() {
      return this.biz?.zone?.status;
    },
    isCanada() {
      return this.biz?.address?.country == "CA";
    },
    unit() {
      if (this.isCanada) return "km";
      return "miles";
    },
  },
};
</script>
