<template lang="pug">
v-menu(
  open-on-hover,
  offset-y,
  v-if="biz.printingGroups && biz.printingGroups.length"
)
  template(v-slot:activator="{ on }")
    v-btn.text-truncate(
      outlined,
      v-on="on",
      x-small,
      style="width: 80px",
      @click.stop,
      color="secondary",
      :loading="loading"
    ) {{ printingGroup }}
      v-icon(right) mdi-menu-down
  v-card(max-height="400")
    v-toolbar(dense, flat)
      .subtitle-2 Printing Group
    v-divider
    v-list.py-0(dense)
      v-list-item(
        v-for="(item, index) in biz.printingGroups",
        :key="index",
        @click="save(item._id)"
      )
        v-list-item-content
          v-list-item-title {{ item.name }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz"]),
    printingGroup() {
      const found = this.biz.printingGroups?.find(
        (o) => o._id == this.item.printingGroup
      );
      return found?.name || "";
    },
  },
  methods: {
    async save(groupId) {
      if (!this.biz || !this.item?._id) return;
      const params = {
        criteria: { _id: this.biz._id, "dishes._id": this.item._id },
        action: { $set: { "dishes.$.printingGroup": groupId } },
      };
      this.loading = true;
      const data = await this.$api.biz.update(params);
      this.$store.dispatch("setBiz", data);
      this.loading = false;
    },
  },
};
</script>
