<template lang="pug">
div
  .biz-info(v-if="item.address") {{ item.address.name }} &bull; {{ item.address | address }}
    v-btn.mx-3(icon, small, color="secondary", @click.stop="open")
      v-icon(small) mdi-pencil
  v-dialog(v-model="dialog", width="500")
    v-card
      v-card-title Edit Address
      v-form(@submit.prevent="save")
        v-card-text
          GoogleAddressField(
            v-model="address",
            placeholder="Enter business name or address"
          )
          v-text-field(
            label="Location Name",
            placeholder="",
            v-model="address.name"
          )
        v-card-actions
          v-btn(color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  data() {
    return {
      dialog: false,
      address: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions(["updateEvent"]),
    open() {
      this.address = JSON.parse(JSON.stringify(this.item.address));
      this.dialog = true;
    },
    async save() {
      if (!this.item || !this.item._id) return;
      if (!this.address) return;
      this.loading = true;
      const params = {
        event_id: this.item._id,
        action: { $set: { address: this.address } },
      };
      try {
        const res = await this.axios.post("/event/entries/update", params);
        this.updateEvent(res.data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.biz-info
  font-size: 13px
  color: #9496A6
</style>