<template lang="pug">
div
  List.mb-5(:event-id="event_id", @select="select")
  Report(:report="report", :event-id="event_id", v-if="report && !loading")
</template>

<script>
import { EventBus } from "@/event-bus";
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";
import List from "./List/index.vue";
import Report from "./Report";

export default {
  components: { List, Report },
  data() {
    return {
      report: null,
      event_id: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  mounted() {
    this.loadEvents();
    EventBus.$on("switch-biz", this.loadEvents);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.loadEvents);
  },
  methods: {
    ...mapActions(["setEvents"]),
    async select(event_id) {
      if (this.event_id == event_id) return;
      this.event_id = event_id;
      this.loadSales();
    },
    async loadEvents() {
      if (!this.biz) return;
      this.report = null;
      const params = {
        criteria: { biz_id: this.biz._id },
        select: "title items created",
      };
      try {
        const result = await this.$api.event.entry.list(params);
        this.setEvents(result);
        const event = _.last(result);
        this.event_id = event?._id;
        this.loadSales();
      } catch (e) {
        //
      }
    },
    async loadSales() {
      if (!this.event_id) return;
      this.loading = true;
      const params = { event_id: this.event_id };
      try {
        const { data } = await this.axios.post("/event/report/sales", params);
        this.report = data;
      } catch (e) {
        //
      }
      this.loading = false;
    },
  },
};
</script>