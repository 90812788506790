<template lang="pug">
Page(title="Subscription & Billing", :doc="doc")
  Payment.mb-10
  YearSelector(@select="select")
  MonthlyBill.my-10(:year="year")
  Purchases.my-10(:year="year")
  .body-2.my-5 Service provided to you are charged on the first day of next month. For example, if you used basic plan service on January 2018, the invoice will be sent on February 1st, 2018. If you have a valid credit card on profile, the invoice will be automatically billed to the card. Otherwise, you would have to manually pay the bill by click on the pay button along with the bill. All charge is due till the start of next billing cycle. Failure to pay bill would incur a late fee of $10.00 in the next invoice.
</template>

<script>
import Payment from "./Payment/index";
import MonthlyBill from "./MonthlyBill/index";
import Purchases from "./Purchases/index";

export default {
  components: { Payment, MonthlyBill, Purchases },
  data() {
    return {
      year: null,
      doc: "https://docs.selflane.com/biz/owner/billing/",
    };
  },
  methods: {
    select(year) {
      this.year = year;
    },
  },
};
</script>
