const state = {
  announcements: []
};

const getters = {
  announcements: state => state.announcements
};

const actions = {
  setAnnouncements: ({ commit }, data) => { commit("setAnnouncements", data); },
  addAnnouncement: ({ commit }, data) => { commit("addAnnouncement", data); },
  updateAnnouncement: ({ commit }, data) => { commit("updateAnnouncement", data); },
  removeAnnouncement: ({ commit }, data) => { commit("removeAnnouncement", data); },
};

const mutations = {
  setAnnouncements(state, data) {
    state.announcements = data;
  },
  addAnnouncement(state, data) {
    state.announcements.push(data);
  },
  updateAnnouncement(state, data) {
    state.announcements = state.announcements.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  removeAnnouncement(state, data) {
    state.announcements = state.announcements.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };