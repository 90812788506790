<template lang="pug">
Page(title="Users & Access")
  Access(ref="access")
  v-btn(to="/resetPWD", outlined, color="secondary") Reset My Password
</template>

<script>
import { EventBus } from "@/event-bus";
import Access from "/libs/components/Biz/Access/index";

export default {
  components: { Access },
  mounted() {
    this.$refs.access.load();
    EventBus.$on("switch-biz", this.$refs.access.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.$refs.access.load);
  },
};
</script>
