<template lang="pug">
Page(title="Reservations", :items="items", :doc="doc")
</template>

<script>
export default {
  data() {
    return {
      doc: "https://docs.selflane.com/biz/performance/reservations/",
      items: [
        { title: "Dashboard", url: "/reservations/dashboard" },
        { title: "Booked Tables", url: "/reservations/booked" },
      ],
    };
  },
};
</script>
