<template lang="pug">
v-dialog(width="400", v-model="dialog")
  template(v-slot:activator="{ on }")
    v-btn(
      @click="importRole",
      color="secondary",
      x-small,
      depressed,
      v-on="on"
    ) Import
  v-card(v-if="job")
    v-card-title Import a Role
    v-form(@submit.prevent="submit")
      v-card-text
        v-btn-toggle(v-model="create", dense)
          v-btn(:value="false") Merge
          v-btn(:value="true") Create New
        v-text-field(v-model="job.name", label="Name", required, v-if="create")
        v-select(
          v-model="job._id",
          :items="jobs",
          item-text="name",
          item-value="_id",
          label="Select Role",
          v-else
        )
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    biz: { type: Object, required: true },
    role: { type: Object, required: true },
  },
  data() {
    return {
      dialog: false,
      job: null,
      create: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["pos"]),
    jobs() {
      return this.pos?.jobs?.filter((o) => !o.external_id);
    },
  },
  methods: {
    ...mapActions(["setPOS"]),
    async importRole() {
      this.job = JSON.parse(JSON.stringify(this.role));
      this.job.external_id = this.role.id;
      this.job.department_id = this.role.department_id;
    },
    async submit() {
      if (!this.job.name) return this.$toast.error("Name is required");
      if (this.create) delete this.job._id;
      else {
        if (!this.job._id) return this.$toast.error("Please select a role");
        const found = this.jobs.find((o) => o._id === this.job._id);
        if (found) {
          this.job.order = found.order;
          this.job.manage = found.manage;
          this.job.name = found.name;
        }
      }
      this.loading = true;
      try {
        const params = { bizId: this.pos.biz, job: this.job };
        if (this.job._id) {
          const data = await this.$api.posJob.update(params);
          this.setPOS(data);
        } else {
          const data = await this.$api.posJob.create(params);
          this.setPOS(data);
        }
        this.$toast.success("Role imported successfully");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>