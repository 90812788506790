<template lang="pug">
div
  v-card.my-3(flat)
    simple-table
      thead
        tr
          th Name
          th Address
          th.text-right City
      tbody
        tr(v-for="biz in items", :key="biz._id")
          td
            v-checkbox(
              v-model="selected",
              :value="biz._id",
              color="secondary",
              dense,
              :label="biz.name",
              hide-details
            )
          td {{ biz.address | address("street") }}
          td.text-right {{ biz.address.city }}, {{ biz.address.state }}
  v-row.my-3(v-if="selected.length > 0", wrap)
    v-col(cols="12", sm="6")
      Hours(:items="selected", :schedule="biz.schedule")
    v-col(cols="12", sm="6")
      v-btn(color="secondary", block, outlined, @click="handlePushMenu") Push Menus to Selected Bizs
    v-col(cols="12", sm="6")
      v-btn(color="secondary", block, outlined, @click="handlePushTable") Push Tables to Selected Bizs
    v-col(cols="12", sm="6")
      v-btn(color="secondary", block, outlined, @click="handlePushLogo") Push Logo/Cates to Selected Bizs
    v-col(cols="12", sm="6")
      v-btn(color="secondary", block, outlined, @click="handlePushPOS") Push POS Settings to Selected Bizs
  p(v-for="(item, index) in log", :key="index") {{ item }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Hours from "./Hours";

export default {
  components: { Hours },
  data() {
    return {
      selected: [],
      log: [],
    };
  },
  computed: {
    ...mapGetters(["biz", "useraccess", "bizAccounts"]),
    items() {
      const bizIds = this.useraccess
        .filter((o) => o.privilege == 1 && o.biz != this.biz._id)
        .map((o) => o.biz);
      return _.chain(this.bizAccounts)
        .filter((o) => bizIds.includes(o._id))
        .sortBy("name")
        .sortBy((o) => {
          return o.address?.city || "";
        })
        .value();
    },
  },
  methods: {
    handlePushLogo() {
      if (!this.selected || this.selected.length == 0) return;
      const clone = JSON.parse(JSON.stringify(this.selected));
      this.log = [];
      const message =
        "Do you really want to push the logo of " +
        this.biz.name +
        " to the selected businesses?";
      confirm(message) && this.pushLogo(clone);
    },
    async pushLogo(list) {
      const bizId = list.shift();
      if (!bizId) return;
      const name = this.items?.find((o) => o._id === bizId)?.name || "";
      try {
        this.log.push("Push logo to " + name);
        await this.axios.post("/bizs/sync/logo", {
          from: this.biz._id,
          to: bizId,
        });
        this.log.push(" ... Done");
        if (list.length > 0) {
          await this.wait(50);
          await this.pushLogo(list);
        }
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    handlePushMenu() {
      if (!this.selected || this.selected.length == 0) return;
      const clone = JSON.parse(JSON.stringify(this.selected));
      this.log = [];
      const message =
        "Do you really want to push the menu/course/dish/modifier of " +
        this.biz.name +
        " to the selected businesses?";
      confirm(message) && this.pushMenu(clone);
    },
    async pushMenu(list) {
      const bizId = list.shift();
      if (!bizId) return;
      const params = {
        criteria: { _id: bizId },
        action: {
          $set: {
            menus: this.biz.menus,
            courses: this.biz.courses,
            dishes: this.biz.dishes,
          },
        },
      };
      const name = this.items?.find((o) => o._id === bizId)?.name || "";
      this.log.push("Push menu/course/dish/modifier to " + name);
      const params_modifier = { from: this.biz._id, to: bizId };
      try {
        await this.axios.post("/bizs/sync/modifiers", params_modifier);
      } catch (e) {
        //
      }
      try {
        await this.axios.post("/bizs/sync/matrixModifier", params_modifier);
      } catch (e) {
        //
      }
      try {
        await this.axios.post("/bizs/update", params);
        this.log.push(" ... Done");
        if (list.length > 0) {
          await this.wait(50);
          await this.pushMenu(list);
        }
      } catch (e) {
        this.log.push("Failed");
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    handlePushTable() {
      if (!this.selected || this.selected.length == 0) return;
      const clone = JSON.parse(JSON.stringify(this.selected));
      this.log = [];
      const message =
        "Do you really want to push the tables of " +
        this.biz.name +
        " to the selected businesses?";
      confirm(message) && this.pushTable(clone);
    },
    async pushTable(list) {
      const bizId = list.shift();
      if (!bizId) return;
      const params = {
        criteria: { _id: bizId },
        action: { $set: { tables: this.biz.tables } },
      };
      const name = this.items?.find((o) => o._id === bizId)?.name || "";
      this.log.push("Push tables to " + name);
      try {
        await this.axios.post("/bizs/update", params);
        this.log.push(" ... Done");
        if (list.length > 0) {
          await this.wait(50);
          await this.pushTable(list);
        }
      } catch (e) {
        this.log.push("Failed");
        this.$toast.error(e.response?.data || e.message);
      }
    },
    handlePushPOS() {
      if (!this.selected || this.selected.length == 0) return;
      const clone = JSON.parse(JSON.stringify(this.selected));
      this.log = [];
      const message =
        "Do you really want to push the pos of " +
        this.biz.name +
        " to the selected businesses?";
      confirm(message) && this.pushPOS(clone);
    },
    async pushPOS(list) {
      const bizId = list.shift();
      if (!bizId) return;
      const name = this.items?.find((o) => o._id === bizId)?.name || "";
      try {
        this.log.push("Push pos to " + name);
        const params = { from: this.biz._id, to: bizId };
        await this.axios.post("/bizs/sync/pos", params);
        this.log.push(" ... Done");
        if (list.length > 0) {
          await this.wait(50);
          await this.pushPOS(list);
        }
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
