<template lang="pug">
v-dialog(v-model="dialog", max-width="400")
  v-card(v-if="role")
    v-card-title Delete a Role
    v-card-text
      div Do you really want to remove this role <b>{{ role.name }}</b>?
    v-card-actions
      v-btn(text, @click="dialog = false") Cancel
      v-btn(text, color="error", @click="remove", :loading="loading") Remove
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      role: null,
      loading: false,
    };
  },
  computed: { ...mapGetters(["pos"]) },
  methods: {
    ...mapActions(["setPOS"]),
    handleRemove(role) {
      this.role = role;
      this.dialog = true;
    },
    async remove() {
      if (!this.role) return;
      this.loading = true;
      const params = { bizId: this.pos.biz, jobId: this.role._id };
      try {
        const data = await this.$api.posJob.delete(params);
        this.setPOS(data);
        this.$emit("removed");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
