<template lang="pug">
v-dialog(v-model="dialog", width="400")
  template(v-slot:activator="{ on }")
    v-btn(small, icon, color="secondary", v-on="on")
      v-icon(small) mdi-information
  v-card
    v-card-title What is Service Item
    v-card-text
      p A Service Item is a product or service that is automatically attached to all qualified orders. It can be configured as either a percentage or a fixed amount charge.
      p For instance, if you require a pre-tax percentage fee to be applied to all orders, you can create a service item with a percentage value.
      p Alternatively, if you need to add a fixed amount charge, such as a utensil fee for all to-go orders, you can create a service item with a specific fixed amount.
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
};
</script>