<template lang="pug">
div
  .title Chowly Integration
  .body-2 You can sync menu to third party platforms via Chowly at anytime.
  List.my-3
</template>

<script>
import List from "./List/index";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { List },
  computed: {
    ...mapGetters(["useraccess"]),
    bizIds() {
      return this.useraccess?.filter((o) => o.privilege == 1).map((o) => o.biz);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBizServices"]),
    async load() {
      if (!this.bizIds?.length) return;
      let params = {
        criteria: { biz: { $in: this.bizIds } },
      };
      const result = await this.$api.service.account.list(params);
      const filtered = result
        .filter((o) => !o.ended && o.third_party?.status)
        .sort((a, b) => a.name.localeCompare(b.name));
      this.setBizServices(filtered);
    },
  },
};
</script>
