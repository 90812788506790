
const state = {
  serverchecklists: []
}

const getters = {
  serverchecklists: state => state.serverchecklists
}

const actions = {
  setServerChecklists: ({ commit }, data) => { commit('setServerChecklists', data) },
  updateServerChecklist: ({ commit }, data) => { commit('updateServerChecklist', data) },
  removeServerChecklist: ({ commit }, data) => { commit('removeServerChecklist', data) }
}

const mutations = {
  setServerChecklists(state, data) {
    state.serverchecklists = data
  },
  updateServerChecklist(state, data) {
    const some = state.serverchecklists.some(o => o._id === data._id)
    if (some) {
      state.serverchecklists = state.serverchecklists.map(o => {
        if (o._id === data._id) return data
        return o
      })
    } else {
      state.serverchecklists.push(data)
    }
  },
  removeServerChecklist(state, data) {
    state.serverchecklists = state.serverchecklists.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }