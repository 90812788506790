<template lang="pug">
span
  v-btn.ml-1(
    icon,
    small,
    @click="open()",
    v-if="transfer.notes && transfer.notes.length"
  )
    v-icon(color="success", small) mdi-note
  v-dialog(v-model="dialog", width="500")
    v-card(v-if="transfer")
      v-toolbar(flat, dense)
        .subtitle-2 Notes
      v-card-text
        p(v-for="(item, index) in transfer.notes", :key="index") {{ item.content }}
</template>

<script>
export default {
  props: ["transfer"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>