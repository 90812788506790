<template lang="pug">
v-card(v-if="summary", outlined)
  v-toolbar(flat, dense)
    .subtitle-2 {{ summary.total }} Hrs total
    v-spacer
    PrintPDF(:week="calendarDate", :items="weekly") 
  v-simple-table
    thead.blue.lighten-2
      tr
        th.d-flex.flex-row.align-center
          v-btn(icon, small, @click="handlePrevious()")
            v-icon(color="white") mdi-menu-left
          .white--text {{ calendarDate }}
          v-btn(icon, small, @click="handleNext()")
            v-icon(color="white") mdi-menu-right
        th.white--text Mon
        th.white--text Tue
        th.white--text Wed
        th.white--text Thu
        th.white--text Fri
        th.white--text Sat
        th.white--text Sun
    tbody
      tr(v-for="(item, index) in weekly", :key="index")
        td
          div {{ item.name }}
          .grey--text {{ item.total }} Hrs
        Day(:day="item.mon", @edit="$emit('edit', $event)")
        Day(:day="item.tue", @edit="$emit('edit', $event)")
        Day(:day="item.wed", @edit="$emit('edit', $event)")
        Day(:day="item.thu", @edit="$emit('edit', $event)")
        Day(:day="item.fri", @edit="$emit('edit', $event)")
        Day(:day="item.sat", @edit="$emit('edit', $event)")
        Day(:day="item.sun", @edit="$emit('edit', $event)")
      tr
        th Total
        th {{ summary.mon }} Hrs {{ summary.monPeople }} P.
        th {{ summary.tue }} Hrs {{ summary.tuePeople }} P.
        th {{ summary.wed }} Hrs {{ summary.wedPeople }} P.
        th {{ summary.thu }} Hrs {{ summary.thuPeople }} P.
        th {{ summary.fri }} Hrs {{ summary.friPeople }} P.
        th {{ summary.sat }} Hrs {{ summary.satPeople }} P.
        th {{ summary.sun }} Hrs {{ summary.sunPeople }} P.
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import _ from "underscore";
import { mapGetters } from "vuex";
import Day from "./Day";
import PrintPDF from "./PrintPDF";

export default {
  components: { PrintPDF, Day },
  data() {
    return {
      loading: false,
      calendarStart: parseInt(moment().startOf("isoWeek").format("YYYYMMDD")),
      weekly: null,
      summary: null,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    calendarDate() {
      if (this.calendarStart) {
        return moment(this.calendarStart, "YYYYMMDD").format("MMM D");
      }
      return "";
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
    EventBus.$on("edit-server-shift", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
    EventBus.$off("edit-server-shift", this.load);
  },
  methods: {
    handleNext() {
      this.calendarStart = moment(this.calendarStart, "YYYYMMDD")
        .add(1, "w")
        .format("YYYYMMDD");
      this.load();
    },
    handlePrevious() {
      this.calendarStart = moment(this.calendarStart, "YYYYMMDD")
        .subtract(1, "w")
        .format("YYYYMMDD");
      this.load();
    },
    handleEmail() {
      const message =
        "Email Shift Schedule. If the emails for staff are set, we can send the selected weekly schedule to them.";
      confirm(message) && this.email();
    },
    email() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id, startDay: this.calendarStart };
      this.axios.post("/serverShift/emailWeekly", params);
    },
    async load() {
      if (!this.biz) return;
      this.loading = true;
      const params = { bizId: this.biz._id, startDay: this.calendarStart };
      const res = await this.axios.post("/serverShift/weekly", params);
      this.loading = false;
      this.weekly = res.data;
      this.summarizeWeekly();
    },
    summarizeWeekly() {
      const data = this.weekly;
      this.summary = {
        mon: getTotalHrs(data, "mon"),
        tue: getTotalHrs(data, "tue"),
        wed: getTotalHrs(data, "wed"),
        thu: getTotalHrs(data, "thu"),
        fri: getTotalHrs(data, "fri"),
        sat: getTotalHrs(data, "sat"),
        sun: getTotalHrs(data, "sun"),
        monPeople: getPeople(data, "mon"),
        tuePeople: getPeople(data, "tue"),
        wedPeople: getPeople(data, "wed"),
        thuPeople: getPeople(data, "thu"),
        friPeople: getPeople(data, "fri"),
        satPeople: getPeople(data, "sat"),
        sunPeople: getPeople(data, "sun"),
      };
      this.summary.total =
        this.summary.mon +
        this.summary.tue +
        this.summary.wed +
        this.summary.thu +
        this.summary.fri +
        this.summary.sat +
        this.summary.sun;
      function getTotalHrs(data, day) {
        const result = _.chain(data)
          .pluck(day)
          .flatten()
          .reduce((memo, o) => {
            return memo + (o.endHH - o.startHH);
          }, 0)
          .value();
        return Math.round((result / 60) * 10) / 10;
      }
      function getPeople(data, day) {
        return _.chain(data)
          .pluck(day)
          .reduce((memo, o) => {
            if (o.length) return memo + 1;
            else return memo;
          }, 0)
          .value();
      }
    },
  },
};
</script>