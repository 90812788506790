<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-toolbar(dense, flat)
      .overline Add Point Items
      v-tooltip(right, v-if="items.length > 1")
        template(v-slot:activator="{ on }")
          v-btn(@click.stop="reset", icon, small, color="error", v-on="on")
            v-icon(small) mdi-delete-sweep
        span Clear all items
      v-spacer
      IconBtn(@click="more()", title="More Item", icon="mdi-plus")
    v-form(@submit.prevent="submit")
      simple-table
        thead
          tr
            th Name
            th Point
            th
        tbody
          tr(v-for="(item, index) in items", :key="index")
            td
              v-text-field(
                v-model.trim="item.name",
                autofocus,
                ref="focus",
                hide-details,
                @keydown.down="more()",
                single-line
              )
            td
              v-text-field(
                type="number",
                min="0",
                step="1",
                v-model="item.price",
                hide-details,
                @keydown.down="handleMore($event)",
                single-line
              )
            td
              v-icon(
                tabindex="-1",
                color="red",
                v-if="items.length > 1",
                @click.stop="less(index)"
              ) mdi-close
      v-card-text
        v-btn(block, color="secondary", type="submit") Save
        .body-2 Use Down Arrow Key on your keyboard to quickly add a new line. It copies the same settings for the last item.
</template>

<script>
import _ from "underscore";

export default {
  name: "MultiForm",
  props: ["input"],
  data() {
    return {
      items: [],
      dialog: false,
    };
  },
  watch: {
    /// input: boolean. tell to open dialog
    input(val) {
      if (!val) return;
      if (!this.items || this.items.length == 0) this.reset();
      this.dialog = true;
    },
    dialog(val) {
      if (!val) this.$emit("done");
    },
  },
  methods: {
    reset() {
      this.items = [{ name: "", price: 10 }];
    },
    less(index) {
      this.items.splice(index, 1);
    },
    handleMore(event) {
      event.preventDefault();
      this.more();
    },
    more() {
      const last = _.last(this.items);
      if (!last) this.reset();
      if (last) {
        this.items.push({ name: "", price: last.price });
      }
    },
    submit() {
      const filtered = _.chain(this.items)
        .map((o) => {
          return {
            name: o.name.trim(),
            price: Math.ceil(o.price),
          };
        })
        .reject((o) => !o.name)
        .value();
      if (!filtered?.length) return;
      this.$emit("done", filtered);
      this.reset();
      this.dialog = false;
    },
  },
};
</script>
