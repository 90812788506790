<template lang="pug">
.my-5
  v-container
    .text-center.my-10
      .display-1.my-3 Setup a Business with Selflane
      .subtitle-2 Get free online ordering for your restaurant today
      .subtitle-2 If you love our service, consider signing up for our Point of Sale
      v-btn.text-capitalize.my-5(
        rounded,
        depressed,
        x-large,
        color="secondary",
        to="/login"
      ) Get Started with Selflane Now
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
#main-body
  background: linear-gradient( 90deg, rgba(236, 243, 248, 1) 0%, rgba(213, 228, 243, 1) 100% )
</style>
