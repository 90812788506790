<template lang="pug">
v-col(:cols="cols", :sm="sm", :md="md", v-if="total")
  v-card.overflow-y-auto(flat, max-height="220px", height="100%")
    simple-table
      thead
        AmountTableRow(
          name="Total Discount",
          :amount="total",
          bold,
          mandatory,
          button,
          @click="open()"
        )
      tbody
        AmountTableRow(
          v-for="(item, index) in groups",
          :key="index",
          :name="item.title",
          :amount="item.amount"
        )
  Dialog
</template>

<script>
import { EventBus } from "@/event-bus";
import _ from "underscore";
import Dialog from "./Dialog";

export default {
  components: { Dialog },
  props: {
    items: { type: Array, default: () => [] },
    cols: { type: Number, default: 12 },
    md: { type: Number, default: 4 },
    sm: { type: Number, default: 6 },
  },
  computed: {
    total() {
      return this.items?.reduce((a, b) => a + b.discount.total, 0);
    },
    groups() {
      const items = this.items.flatMap((o) => o.discount.items);
      return _.chain(items)
        .groupBy("name")
        .map((value, name) => {
          const amount = value.reduce((a, b) => a + b.amount, 0);
          const count = value.length;
          const title = `${count} x ${name}`;
          return { name, amount, title };
        })
        .sortBy("name")
        .value();
    },
  },
  methods: {
    open() {
      const items = this.items.flatMap((o) => o.discount.items);
      EventBus.$emit("discount-dialog", items);
    },
  },
};
</script>