<template lang="pug">
Page(title="Scheduling", :doc="doc")
  template(v-slot:header)
    IconBtn(@click="create", icon="mdi-plus", title="Add")
  Schedule(@edit="edit")
  Shifts.my-3(@edit="edit")
  ShiftForm(:input="shift", @close="closeForm")
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import StaffLoader from "./../StaffLoader";
import Schedule from "./Schedule/index";
import ShiftForm from "./ShiftForm";
import Shifts from "./Shifts";

export default {
  components: { Schedule, Shifts, ShiftForm },
  extends: StaffLoader,
  data() {
    return {
      shift: null,
      doc: "https://docs.selflane.com/biz/employee/scheduling.html",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  destroyed() {
    this.setServershifts([]);
  },
  methods: {
    ...mapActions(["setServershifts"]),
    create() {
      const data = {
        biz: this.biz._id,
        server: null,
        role: null,
        days: [],
        startHH: 480,
        endHH: 1200,
        startDay: parseInt(moment().format("YYYYMMDD")),
        doesEnd: false,
        endDay: null,
        frequency: { n: 1, unit: "week" },
      };
      this.edit(data);
    },
    edit(item) {
      this.shift = item;
    },
    closeForm() {
      this.shift = null;
    },
  },
};
</script>
