<template lang="pug">
tr
  td {{ item.year }}
  td.text-right {{ item.filetype }}
  td.text-right
    IconBtn(
      @click="download(item)",
      title="PDF",
      icon="mdi-download",
      :small="false",
      :x-small="true",
      :loading="loading"
    )
</template>

<script>
import FileSaver from "file-saver";

export default {
  props: ["item"],
  data() {
    return { loading: false };
  },
  methods: {
    async download() {
      if (!this.item) return;
      this.loading = true;
      try {
        const params = { fileid: this.item._id };
        const name = this.item.filetype + "-" + this.item.year + ".pdf";
        const file = await this.axios.post("/tax/taxfile/open", params, {
          responseType: "blob",
        });
        FileSaver.saveAs(file.data, name);
      } catch (e) {
        this.$toast.error("Failed to download file");
      }
      this.loading = false;
    },
  },
};
</script>
