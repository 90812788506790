<template lang="pug">
div
  .d-flex.flex-row.flex-wrap.align-center.mb-1
    v-btn.mr-2.mb-2(
      v-for="(item, index) in types",
      :key="index",
      rounded,
      depressed,
      :class="{ accent: selectedType == item.value }",
      small,
      @click="selectedType = item.value"
    ) {{ item.name }}
    v-spacer
    IconBtn(@click="create()", title="Add", icon="mdi-plus")
  List(:items="selected")
  Doc
  Form
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Form from "./Form";
import List from "./List";
import Doc from "./Doc";

export default {
  components: { Form, List, Doc },
  data() {
    return {
      selectedType: 0,
      types: [
        { name: "Auto", value: 0 },
        // { name: "Manager Approve", value: 1 },
        // { name: "Member/Auto", value: 2 },
        // { name: "Member/Manager Approve", value: 3 },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz", "pricings"]),
    selected() {
      if (!this.pricings) return [];
      return this.pricings;
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setPricings", "removePricing"]),
    async load() {
      if (!this.biz || !this.biz._id) return;
      const params = {
        criteria: { biz: this.biz._id },
      };
      try {
        const result = await this.$api.pricing.list(params);
        this.setPricings(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    create() {
      if (!this.biz || !this.biz._id) return;
      let data = {
        biz: this.biz._id,
        type: this.selectedType,
        date: [],
        start: 480,
        end: 1200,
        menus: [],
        courses: [],
        dishes: [],
        amount: 0,
        isPct: false,
        pct: 0,
        isReduce: false,
        voids: [],
        reduce: 0,
      };
      EventBus.$emit("edit-scheduled-discount", data);
    },
  },
};
</script>
