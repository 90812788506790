import jsPDF from "jspdf";

class PDFGenerator {
  constructor(options = {}) {
    this.titleSize = 12
    this.contentSize = 10
    const { x = 19, y = 25, format = 'letter', orientation = 'portrait' } = options
    this.doc = new jsPDF({ format, orientation });
    this.pageWidth = this.doc.internal.pageSize.width;
    this.pageHeight = this.doc.internal.pageSize.height;
    this.xStart = x;
    this.yStart = y;
    this.x = x;
    this.y = y;
    this.maxWidth = this.pageWidth - 2 * x;
  }
  addTitle(title) {
    if (!title) return;
    this.doc.setFontSize(this.titleSize);
    this.doc.setTextColor(255, 109, 50)
    this.doc.text(title, this.x, this.y);
    this.nextLine(10);
  }
  addItems(items, options = {}) {
    if (!items?.length) return;
    const width = (this.pageWidth - 2 * this.xStart) / items.length;
    items.forEach(item => {
      this.addContent(item, options);
      this.nextCell(width);
    })
    this.nextLine();
  }
  addContent(content, options = {}) {
    const {
      fontName = 'helvetica', fontStyle = 'normal', fontWeight = 'normal',
      fontSize = 10, textColor = '#000000', maxWidth = this.maxWidth
    } = options
    if (!content) return;
    this.doc.setFontSize(fontSize);
    this.doc.setFont(fontName, fontStyle, fontWeight)
    this.doc.setTextColor(textColor)
    this.doc.text(content, this.x, this.y, { maxWidth });
    return this.doc.getTextDimensions(content, { maxWidth, fontSize, fontStyle, fontWeight });
  }
  // draw a line
  addLine(height = 5) {
    this.doc.line(this.xStart, this.y, this.pageWidth - this.xStart, this.y);
    this.nextLine(height);
  }
  // move horizontally
  nextCell(width = 50) {
    this.x += width;
  }
  // move vertically, add a new page if needed
  nextLine(height = 5, bottom = 10) {
    this.y += height;
    this.x = this.xStart;
    if (this.y + bottom > this.pageHeight) {
      this.nextPage();
    }
  }
  // move to the next page
  nextPage() {
    this.doc.addPage();
    this.y = this.yStart;
  }
  save(filename = 'file') {
    this.doc.save(filename + '.pdf');
  }
}

export default PDFGenerator