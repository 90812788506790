<template lang="pug">
v-row(wrap)
  v-col(cols='12' sm='6')
    PointShop
  v-col(cols='12' sm='6')
    PointVoucher
</template>

<script>
import PointShop from "./PointShop/index";
import PointVoucher from "./PointVoucher/index";

export default {
  components: { PointShop, PointVoucher }
};
</script>
