const state = {
  stripeBanks: []
}

const getters = {
  stripeBanks: state => state.stripeBanks
}

const actions = {
  setStripeBanks: ({
    commit
  }, stripeBanks) => {
    commit('setStripeBanks', stripeBanks)
  },
  updateStripeBank: ({
    commit
  }, stripeBank) => {
    commit('updateStripeBank', stripeBank)
  },
  addStripeBank: ({
    commit
  }, stripeBank) => {
    commit('addStripeBank', stripeBank)
  },
  removeStripeBank: ({
    commit
  }, stripeBank) => {
    commit('removeStripeBank', stripeBank)
  }
}

const mutations = {
  setStripeBanks(state, stripeBanks) {
    state.stripeBanks = stripeBanks
  },
  updateStripeBank(state, stripeBank) {
    state.stripeBanks = state.stripeBanks.map(bank => {
      if (bank.id === stripeBank.id) return stripeBank
      if (stripeBank.default_for_currency) {
        bank.default_for_currency = false
      }
      return bank
    })
  },
  addStripeBank(state, stripeBank) {
    state.stripeBanks.push(stripeBank)
  },
  removeStripeBank(state, stripeBank) {
    state.stripeBanks = state.stripeBanks.filter(bank => bank.id !== stripeBank.id)
  }
}

export default { state, getters, actions, mutations }