<template lang="pug">
tr(@click="$emit('click', item)", role="button")
  th
    v-icon(color="info", v-if="store") mdi-circle-small
    span {{ item.customer.name }}
  td {{ item.party_size }}
  td.text-right {{ item.time | time(timezone) }}
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
    timezone: { type: String, default: null },
  },
  computed: {
    store() {
      return this.item?.proxy == "back-office";
    },
  },
};
</script>