<template lang="pug">
tr(v-if="value || detail", :class="{ 'text--secondary': secondary }")
  td {{ title }}
  td.text-right
    span(v-if="value") {{ value | currency }}
    span.ml-1(v-if="detail") {{ detail }}
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    value: { type: Number, default: 0 },
    detail: { type: String, default: "" },
    secondary: Boolean,
  },
};
</script>
