<template lang="pug">
div
  v-btn(block, color="secondary", @click="open()") Edit Member
  v-dialog(v-model="dialog", max-width="500", width="95%")
    v-card(v-if="edit")
      v-container(grid-list-sm)
        v-form(@submit.prevent="submit")
          v-layout
            v-flex(xs6)
              v-text-field(label="First Name", v-model="edit.name.first")
            v-flex(xs6)
              v-text-field(label="Last Name", v-model="edit.name.last")
          v-row(wrap)
            v-col(cols="12", sm="6")
              PhoneField(v-model="edit.phone")
            v-col(cols="12", sm="6")
              v-text-field(label="Email", v-model="edit.email")
          BirthdayPicker(v-model="edit.bd")
          BirthdayPicker(v-model="edit.anniv", label="Anniversary")
          v-btn(block, color="secondary", type="submit") Save Member
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      edit: null,
    };
  },
  computed: {
    ...mapGetters(["member"]),
  },
  methods: {
    ...mapActions(["setMember"]),
    open() {
      if (!this.member) return;
      this.dialog = true;
      this.edit = JSON.parse(JSON.stringify(this.member));
      if (!this.edit.name) {
        this.edit = _.extend(this.edit, {
          name: { first: "", last: "" },
        });
      }
      if (!this.edit.bd) {
        this.edit = _.extend(this.edit, {
          bd: { mm: 0, dd: 0 },
        });
      }
      if (!this.edit.anniv) {
        this.edit = _.extend(this.edit, {
          anniv: { mm: 0, dd: 0 },
        });
      }
    },
    async submit() {
      if (!this.edit || !this.edit._id) return;
      const options = {
        criteria: { _id: this.edit._id },
        action: {
          $set: {
            name: this.edit.name,
            phone: this.edit.phone,
            email: this.edit.email,
            bd: this.edit.bd,
            anniv: this.edit.anniv,
          },
        },
      };
      const member = await this.$api.member.update(options);
      this.setMember(member);
      this.dialog = false;
    },
  },
};
</script>