<template lang="pug">
Page(title="Events", :items="items", :doc="doc")
  OrderDialog
</template>

<script>
import OrderDialog from "./OrderDialog/index";

export default {
  components: { OrderDialog },
  data() {
    return {
      items: [
        { title: "Dashboard", url: "/events/dashboard" },
        { title: "Manage", url: "/events/manage" },
        { title: "Tickets", url: "/events/tickets" },
      ],
      doc: "https://docs.selflane.com/biz/marketing/events.html",
    };
  },
};
</script>
