<template lang="pug">
tbody
  tr.border_bottom
    td {{ group.server }}
    td
    td
    td
    td {{ group.totalHrs | hours(isDecimal) }}
    td(v-if="showWage")
    td(v-if="showWage") {{ group.totalPay | currency }}
    td(v-if="showWage") {{ group.cashTip | currency }}
  tr(
    v-for="(role, rindex) in group.byRole",
    :key="rindex + 'role'",
    :class="{ border_bottom: rindex == group.byRole.length - 1 }"
  )
    td
    td {{ role.name }}
    td
    td
    td {{ role.totalHrs | hours(isDecimal) }}
    td(v-if="showWage")
    td(v-if="showWage") {{ role.totalPay | currency }}
    td(v-if="showWage") {{ role.cashTip | currency }}
  Cell(
    v-for="(item, index) in group.items",
    :key="index + 'cell'",
    :item="item",
    :isDecimal="isDecimal",
    :showWage="showWage"
  )
</template>

<script>
import Cell from "./Cell";

export default {
  components: { Cell },
  props: ["group", "isDecimal", "showWage"],
};
</script>
