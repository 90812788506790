<template lang="pug">
v-card(v-if="report", flat)
  simple-table
    thead
      tr
        th {{ title }}
        td.text-right {{ report.amount | toLocaleString }} pts
    tbody
      tr
        th # Members
        td.text-right(v-if="report.members") {{ report.members.length }}
      tr
        th # Orders
        td.text-right(v-if="report.orders") {{ report.orders.length }}
</template>

<script>
export default {
  props: ["title", "report"],
};
</script>