<template lang="pug">
StandardChart(
  :input="chartData",
  :legend="true",
  :gridLines="true",
  :loading="loading",
  max-width="100%"
)
</template>

<script>
const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default {
  props: {
    title: { type: String, default: "" },
    isCurrency: { type: Boolean, default: true },
    postTick: { type: String, default: "" },
    weekData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: { type: Boolean, default: false },
  },
  computed: {
    chartData() {
      let datasets = [];
      if (this.weekData && this.weekData.length > 1) {
        const setA = this.setData(
          "This Week",
          "#5469D4",
          "#D0E0FC",
          this.weekData[0]
        );
        const bkg = "rgba(0, 0, 0, 0.1)";
        const setB = this.setData(
          "Last Week",
          "#A3ACB9",
          bkg,
          this.weekData[1]
        );
        datasets = [setB, setA];
      }
      return {
        title: this.title,
        labels,
        datasets,
        isCurrency: this.isCurrency,
        postTick: this.postTick,
      };
    },
  },
  methods: {
    setData(label, borderColor, backgroundColor, data) {
      return {
        label,
        borderColor,
        backgroundColor,
        fill: true,
        lineTension: 0,
        pointRadius: 1,
        borderWidth: 2,
        data,
      };
    },
  },
};
</script>
