<template lang="pug">
td
  .cell-container
    input.input-cell(
      :readonly="!editable",
      type="number",
      v-model="content",
      flat,
      :class="{ 'clue-cell': !editable }"
    )
    .possible-text(v-if="cell.value == 0 && !hide_hint") {{ possible }}
</template>

<script>
export default {
  props: ["cell", "hide_hint"],
  data() {
    return {
      content: this.cell.value ? this.cell.value : "",
    };
  },
  computed: {
    editable() {
      return this.cell?.original === 0;
    },
    possible() {
      return this.cell?.possible?.join("");
    },
  },
  watch: {
    content(newval, oldval) {
      if (newval > 9) this.content %= 10;
      if (newval < 1) this.content = "";
      if (newval == oldval) return;
      this.$emit("save", this.content);
    },
    cell: {
      handler(newval) {
        this.content = newval.value ? newval.value : "";
      },
      deep: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.cell-container
  display: flex
  flex-direction: column
  width: 42px
  height: 42px
  text-align: center
.input-cell
  flex: 1
.possible-text
  font-size: 0.5em
  width: 40px
  overflow: hidden
.clue-cell
  text-shadow: 0 0 0 black
input
  color: transparent
  text-shadow: 0 0 0 blue
  width: 100%
  height: 100%
  text-align: center
  font-weight: bold
  border: none
  outline: none
  &:focus
    outline: none
    border: none
    box-shadow: none
    -moz-box-shadow: none
    -webkit-box-shadow: none
    background-color: #bde0fe

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Firefox */
input[type=number]
  -moz-appearance: textfield
</style>