<template lang="pug">
div
  .d-flex.flex-row.align-center.justify-center
    .sl-medal
      h1 {{ net | currency }}
      h2 Net Sales
    .sl-medal
      h1 {{ payroll | currency }}
      h2 Payroll
    .sl-medal(v-if="net > 0")
      h1 {{ (ratio * 100) | number(0) }} %
      h2 Payroll / Sales
  v-row(row, wrap, justify="center")
    v-col(cols="12", md="4")
      StandardChart(:input="salesData", maxWidth="100%", chartType="bar")
    v-col(cols="12", md="4", v-if="showWage")
      StandardChart(
        :input="chartData.hourChart",
        maxWidth="100%",
        chartType="bar",
        :stacked="true",
        :legend="true"
      )
    v-col(cols="12", md="4", v-if="showWage")
      StandardChart(
        :input="chartData.wageChart",
        maxWidth="100%",
        chartType="bar",
        :stacked="true",
        :legend="true"
      )
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import _ from "underscore";

export default {
  props: ["salesByHours", "showWage", "date", "laborReport"],
  computed: {
    ...mapGetters(["punchcards"]),
    net() {
      return this.salesByHours?.subtotal?.reduce((a, b) => a + b, 0);
    },
    payroll() {
      return this.chartData.payroll || 0;
    },
    ratio() {
      if (this.net) return this.payroll / this.net;
      return 0;
    },
    // ['12am' ... '11pm']
    labels() {
      return [...Array(24).keys()].map((hour) =>
        moment()
          .hour(hour + this.startHour)
          .format("ha")
      );
    },
    startHour() {
      return this.salesByHours?.startHour || 0;
    },
    // the ending time for open punchcard
    endOfCard() {
      const endOfDay = moment(this.date).endOf("day").valueOf();
      const now = Date.now();
      return endOfDay < now ? endOfDay : now;
    },
    // [{ server: ID, regHours, extHours }]
    serverHours() {
      return this.laborReport?.servers || [];
    },
    chartData() {
      // construct an array with 24 elements
      let hours = new Array(24).fill(0);
      let extHours = new Array(24).fill(0);
      let wages = new Array(24).fill(0);
      let extWages = new Array(24).fill(0);
      _.chain(this.punchcards)
        .filter((o) => o.status)
        .sortBy("clockIn")
        .groupBy("server")
        .each((cards) => {
          let myRegHours =
            this.serverHours.find((o) => o.id == cards[0].server)?.regHours ||
            0;
          let myExtHours =
            this.serverHours.find((o) => o.id == cards[0].server)?.extHours ||
            0;
          _.each(cards, (card) => {
            let start = moment(card.clockIn);
            const stop = moment(card.clockOut || this.endOfCard);
            while (start.isBefore(stop)) {
              const temp = moment(start);
              if (stop.isBefore(start.endOf("hour"))) {
                start = stop;
              } else {
                start = start.add(1, "hour").startOf("hour");
              }
              // in unit of hour
              const duration = start.diff(temp, "hours", true);
              const index = temp.hour();
              if (myExtHours > 0) {
                extHours[index] += duration;
                extWages[index] += duration * card.rate * 1.5;
                myExtHours += duration;
              } else if (myRegHours + duration > 40) {
                const extDuration = myRegHours + duration - 40;
                const regDuration = duration - extDuration;
                extHours[index] += extDuration;
                extWages[index] += extDuration * card.rate * 1.5;
                hours[index] += regDuration;
                wages[index] += regDuration * card.rate;
                myExtHours += extDuration;
                myRegHours += regDuration;
              } else {
                hours[index] += duration;
                wages[index] += duration * card.rate;
                myRegHours += duration;
              }
            }
          });
        });
      // round result for hours and wages to 2 decimal places
      hours = hours.map((hour) => Math.round(hour * 100) / 100);
      wages = wages.map((wage) => Math.round(wage * 100) / 100);
      // shift data by startHour
      hours = hours.concat(hours.splice(0, this.startHour));
      wages = wages.concat(wages.splice(0, this.startHour));
      let totalRegHours = hours.reduce((a, b) => a + b, 0);
      let totalRegWages = wages.reduce((a, b) => a + b, 0);
      let totalExtHours = extHours.reduce((a, b) => a + b, 0);
      let totalExtWages = extWages.reduce((a, b) => a + b, 0);
      let totalHours = totalRegHours + totalExtHours;
      let totalWages = totalRegWages + totalExtWages;
      totalRegHours = Math.round(totalRegHours * 100) / 100;
      totalRegWages = Math.round(totalRegWages * 100) / 100;
      totalExtHours = Math.round(totalExtHours * 100) / 100;
      totalExtWages = Math.round(totalExtWages * 100) / 100;
      totalHours = Math.round(totalHours * 100) / 100;
      totalWages = Math.round(totalWages * 100) / 100;
      const hourChart = {
        title: `Employee Hours (${totalHours} hrs)`,
        labels: this.labels,
        datasets: [
          {
            label: `Reg (${totalRegHours} hrs)`,
            data: hours,
            backgroundColor: "#7682d6",
            stack: "Stack 0",
          },
          {
            label: `OT (${totalExtHours} hrs)`,
            data: extHours,
            backgroundColor: "#f87979",
            stack: "Stack 0",
          },
        ],
        isCurrency: false,
      };
      const wageChart = {
        title: `Payroll ($${totalWages})`,
        labels: this.labels,
        datasets: [
          {
            label: `Reg ($${totalRegWages})`,
            data: wages,
            backgroundColor: "#7682d6",
            stack: "Stack 0",
          },
          {
            label: `OT ($${totalExtWages})`,
            data: extWages,
            backgroundColor: "#f87979",
            stack: "Stack 0",
          },
        ],
        isCurrency: true,
      };
      return { hourChart, wageChart, payroll: totalWages };
    },
    salesData() {
      return {
        title: "Net Sales",
        labels: this.salesByHours.labels,
        values: this.salesByHours.subtotal,
        isCurrency: true,
      };
    },
  },
};
</script>
