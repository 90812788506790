<template lang="pug">
v-card(v-if="data", flat)
  simple-table
    thead
      tr
        th
          router-link(to="/sales/returns") Credit Return
        th {{ data.count }}
        th.text-right {{ data.amount | currency }}
</template>

<script>
export default {
  props: ["data"],
};
</script>
