<template lang="pug">
div(v-if="courseId && biz") You are editing {{ count }} item
  span(v-if="count > 1") s
  span.ml-1 under the course: {{ courseName }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    courseId: { type: String, default: null },
  },
  computed: {
    ...mapGetters(["biz"]),
    courseName() {
      const found = this.biz.courses.find((o) => o._id == this.courseId);
      return found?.name || "";
    },
    count() {
      return this.biz.dishes?.filter((o) => o.course == this.courseId)?.length;
    },
  },
};
</script>
