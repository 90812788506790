<template lang="pug">
v-dialog(v-model="dialog", max-width="1000")
  v-card(v-if="order")
    v-card-title
    v-card-text.pb-0
      OrderHeader(:order="order")
    v-row(wrap, dense)
      v-col(cols="12", md="6")
        Items(:order="order")
        Payment(:order="order", @update="update")
        v-card-text
          .link-box
            Download(:order="order", :delivOrder="delivOrder")
            CloseOrder(:order="order")
            Reopen(:order="order")
            PartialRefund(:order="order")
          div(v-if="!isCanceled")
            v-divider.my-8
            Cancel(:isOwner="isOwner", :order="order")
      v-col(cols="12", md="6")
        v-card-text
          ActionLogs(:order="order", ref="actionLogs")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Items from "./Items";
import Payment from "./Payment/index";
import Download from "/libs/components/Order/Detail/Download";
import Cancel from "./Cancel";
import CloseOrder from "./CloseOrder";
import Reopen from "./Reopen";
import PartialRefund from "./PartialRefund";
import ActionLogs from "/libs/components/Order/ActionLogs/index";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OrderDetail",
  components: {
    Items,
    Payment,
    Download,
    Cancel,
    CloseOrder,
    Reopen,
    PartialRefund,
    ActionLogs,
  },
  data() {
    return { delivOrder: null, dialog: false };
  },
  computed: {
    ...mapGetters(["biz", "useraccess", "order"]),
    access() {
      if (!this.$auth.check() || !this.biz) return null;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      return this.useraccess?.find((o) => o.user == userId && o.biz == bizId);
    },
    isOwner() {
      return this.access?.privilege == 1;
    },
    isCanceled() {
      return this.order?.status == -1;
    },
  },
  mounted() {
    EventBus.$on("show-order-detail", this.load);
  },
  destroyed() {
    EventBus.$off("show-order-detail", this.load);
  },
  methods: {
    ...mapActions(["setOrder"]),
    async load(orderId) {
      if (!orderId) return this.$toast.error("No order id provided");
      const params = { criteria: { _id: orderId } };
      try {
        const data = await this.$api.order.retrieve(params);
        this.setOrder(data);
        this.loadDelivOrder();
        this.dialog = true;
        await this.wait(100);
        this.$refs.actionLogs.load();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async loadDelivOrder() {
      try {
        const params = { criteria: { order: this.order._id } };
        this.delivOrder = await this.$api.delivOrder.retrieve(params);
      } catch (e) {
        this.delivOrder = null;
      }
    },
    update(data) {
      this.order = data;
    },
    async wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>
