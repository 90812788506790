<template lang="pug">
simple-table
  thead
    tr
      th
      th(v-for="(item, index) in days", :key="index + 'd'") {{ item | day(true) }}
  tbody
    tr(v-for="(item, index) in hours", :key="index + 'h'")
      td {{ item.hour_human }}
      td(
        v-for="(day, index) in item.days",
        :key="index + 'hd'",
        role="button",
        @click.stop="edit(day.day, item.hour)"
      )
        span(v-if="day.max_order") {{ day.max_order }}/{{ day.max_item }}
</template>

<script>
import _ from "underscore";
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      days: [1, 2, 3, 4, 5, 6, 0],
    };
  },
  computed: {
    ...mapGetters(["biz_throttles"]),
    hours() {
      const days = this.days;
      return _.map(_.range(0, 24), (hour) => {
        let hour_human;
        if (hour == 0) {
          hour_human = "12 AM";
        } else if (hour == 12) {
          hour_human = "12 PM";
        } else if (hour < 12) {
          hour_human = hour + " AM";
        } else {
          hour_human = (hour % 12) + " PM";
        }
        const items = _.map(days, (o) => {
          const found = this.biz_throttles.find(
            (item) => item.day == o && item.hour == hour
          );
          const max_order = found?.max_order;
          const max_item = found?.max_item;
          return { day: o, max_order, max_item };
        });
        return { hour, hour_human, days: items };
      });
    },
  },
  methods: {
    edit(day, hour) {
      EventBus.$emit("edit-biz-throttle", day, hour);
    },
  },
};
</script>
