<template lang="pug">
v-card(flat, v-if="id", :loading="loading")
  v-toolbar(flat, dense)
    .subtitle-2 Company Owner
    v-spacer
    AccountLinkUpdate(:accountId="id", :compact="true")
  List
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";
import List from "./List";

export default {
  components: { List },
  props: {
    id: { type: String, default: null }, // stripe connected account id
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    id() {
      this.load();
    },
  },
  mounted() {
    this.load();
    EventBus.$on("reload-stripe-persons", this.load);
  },
  destroyed() {
    EventBus.$off("reload-stripe-persons", this.load);
  },
  methods: {
    ...mapActions(["setStripePersons"]),
    async load() {
      if (!this.id) {
        this.setStripePersons([]);
        return;
      }
      this.loading = true;
      const params = { accountId: this.id };
      try {
        const result = await this.$api.stripe.person.list(params);
        this.setStripePersons(result);
      } catch (e) {
        this.setStripePersons([]);
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
