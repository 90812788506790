<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Payment Method
    v-spacer
    Edit(@done="load")
  StripeSources(:id="id", v-if="id", ref="sources")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Edit from "./Edit";
import StripeSources from "/libs/components/Stripe/Sources";

export default {
  components: { Edit, StripeSources },
  computed: {
    ...mapGetters(["salesOrganization"]),
    id() {
      return this.salesOrganization?.payments?.customer;
    },
  },
  mounted() {
    EventBus.$on("switch-partner", this.load);
  },
  destroyed() {
    EventBus.$off("switch-partner", this.load);
  },
  methods: {
    async load() {
      this.$refs.sources.load();
    },
  },
};
</script>
