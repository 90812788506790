<template lang="pug">
StandardChart(
  :input="chartData",
  chartType="bar",
  max-width="100%",
  :loading="loading"
)
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      organization: null, // ID of the organization
      loading: false,
      results: [],
    };
  },
  computed: {
    chartData() {
      // stack results by month, fill in missing months
      // months = array of month numbers
      // label with [Jan, Feb, Mar, ...]
      const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      const labels = months.map((month) => {
        const date = new Date(this.year, month - 1);
        return date.toLocaleString("default", { month: "short" });
      });
      // fill in missing months and group by type and fill in missing types
      const values = months.map((month) => {
        return (
          this.results.find((result) => result.month === month)?.count || 0
        );
      });

      return { title: `${this.year} Service Log Trends`, labels, values };
    },
  },
  methods: {
    async load({ year, organization }) {
      this.loading = true;
      if (year) this.year = year;
      this.organization = organization;
      const params = { year: this.year, organization: this.organization };
      try {
        this.results = await this.$api.crm.serviceLog.custom("yearly", params);
      } catch (e) {
        console.log(e?.response?.data);
      }
      this.loading = false;
    },
  },
};
</script>