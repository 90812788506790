<template lang="pug">
IconBtn(@click="generatePDF()", v-if="result", icon="mdi-download", title="PDF")
</template>

<script>
import jsPDF from "jspdf";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  props: ["result", "range"],
  computed: {
    ...mapGetters(["biz"]),
    total_net_sales() {
      if (!this.result) return 0;
      return this.result.tax_items.reduce((acc, cur) => acc + cur.net_sales, 0);
    },
    total_tax() {
      if (!this.result) return 0;
      return this.result.tax_items.reduce((acc, cur) => acc + cur.value, 0);
    },
  },
  methods: {
    generatePDF() {
      if (!this.biz) return;
      const currency = this.$options.filters.currency;
      const bizName = this.biz.name;
      const timeWindow =
        moment(this.range.begin).format("MMM D, YYYY") +
        " - " +
        moment(this.range.end).format("MMM D, YYYY");
      let doc = new jsPDF({
        orientation: "landscape",
        format: "letter",
      });
      let x = 19;
      let y = 25;
      let pageWidth = doc.internal.pageSize.width;
      let cellWidth = (pageWidth - 20) / 4;

      addTitle();
      /// header
      doc.text("Name", x, y);
      nextCell(50);
      doc.text("Tax Rate", x, y);
      nextCell();
      doc.text("Net Sales", x, y);
      nextCell();
      doc.text("Tax", x, y);
      nextLine();
      // Tax Exempt
      if (this.result.tax_exempt && this.result.tax_exempt.count > 0) {
        doc.text("Tax Exempt", x, y);
        nextCell(50);
        doc.text("0%", x, y);
        nextCell();
        doc.text(currency(this.result.tax_exempt.dish), x, y);
        nextCell();
        doc.text("", x, y);
        nextLine();
      }
      /// body
      this.result.tax_items.forEach((item) => {
        doc.text(`${item.name}`, x, y);
        nextCell(50);
        doc.text(item.percentage + "%", x, y);
        nextCell();
        doc.text(`${currency(item.net_sales)}`, x, y);
        nextCell();
        doc.text(currency(item.value), x, y);
        nextLine();
      });
      // /// sum
      doc.text("Total", x, y);
      nextCell(50);
      doc.text("", x, y);
      nextCell();
      doc.text(currency(this.total_net_sales), x, y);
      nextCell();
      doc.text(currency(this.total_tax), x, y);
      nextLine();
      const suffix = moment(this.range.begin).format("YYYY_MM");
      doc.save("gross_receipts_tax_" + suffix + ".pdf");

      function addTitle() {
        x = 19;
        y = 25;
        doc.setTextColor(242, 162, 65);
        doc.text("Gross Receipts Tax Report - " + bizName, x, y);
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        nextLine();
        doc.text(timeWindow, x, y);
        nextLine();
        const now = moment().format("MMM D, YYYY");
        doc.text("Produced on " + now, x, y);
        nextLine();
        doc.line(0, y, pageWidth, y);
        nextLine();
      }

      function nextLine(height) {
        if (height) y += height;
        else y += 10;
        x = 19;
      }
      function nextCell(width) {
        if (width) x += width;
        else x += cellWidth;
      }
    },
  },
};
</script>
