<template lang="pug">
v-card(flat, v-if="bizservice && isRestaurant")
  v-toolbar(flat, dense)
    .subtitle-2 Apps & Integrations
  v-img.my-3(:src="icon", height="50px", contain)
  Integrated(v-if="hasChowly")
  IntegrationRequest(v-else, :title="title", :description="description")
</template>

<script>
import { mapGetters } from "vuex";
import Integrated from "./Integrated";
import IntegrationRequest from "/libs/components/Biz/IntegrationRequest";

export default {
  components: { Integrated, IntegrationRequest },
  data() {
    return {
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fchowly-logo.svg?alt=media&token=87824168-0083-4a90-8c70-189a3122280d",
      title: "Chowly Integration",
      description:
        "Through our integration with <b>Chowly</b>, we effortlessly synchronize <b>menus</b> and <b>orders</b> with leading third-party marketplaces like <b>UberEats</b>, <b>Grubhub</b>, and <b>DoorDash</b>. By automatically aggregating all marketplace orders in our POS system, your business benefits from substantial labor savings on order processing. Furthermore, the streamlined kitchen operation utilizes the same kitchen language and setup for all orders, enhancing efficiency and organization.",
    };
  },
  computed: {
    ...mapGetters(["bizservice", "biz"]),
    isRestaurant() {
      if (this.biz?.industry && this.biz.industry >= 100) return false;
      return true;
    },
    hasChowly() {
      return this.bizservice?.third_party?.status;
    },
  },
};
</script>