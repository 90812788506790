<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-card-text
      div(v-if="member == 0")
        .sl-row
          v-flex
            .sl-title Basic Member Program
            div Manage members and redeem points
          v-btn(
            @click="submit(1)",
            rounded,
            depressed,
            color="secondary",
            :loading="loading"
          ) Upgrade
        .sl-dash-divider.my-3
      .sl-row
        v-flex
          .sl-title Active Marketing Program
          div Include Basic Member Program features
          div Actively engage with members to increace sales
        v-btn(
          @click="submit(2)",
          rounded,
          depressed,
          color="secondary",
          :loading="loading"
        ) Upgrade
      .sl-dash-divider.my-3
      .caption Upgrade member program would increase your monthly bill. Please check the related document about the usage and price.
      v-btn.text-capitalize(
        href="https://docs.selflane.com/biz/member/",
        target="_blank",
        rounded,
        depressed
      )
        v-icon(left, small) mdi-book
        span Docs
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      bizId: null,
      member: 0, // current level
      nextLevel: 0,
      loading: false,
    };
  },
  mounted() {
    EventBus.$on("upgrade-member", this.load);
  },
  destroyed() {
    EventBus.$off("upgrade-member", this.load);
  },
  methods: {
    load(bizId, member) {
      this.member = member;
      this.bizId = bizId;
      this.dialog = true;
    },
    async submit(nextLevel) {
      if (!nextLevel || !this.bizId) return;
      this.loading = true;
      const params = { bizId: this.bizId, member: nextLevel };
      try {
        const result = await this.$api.service.account.custom("member", params);
        this.$store.dispatch("setBizService", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.response);
      }
      this.loading = false;
    },
  },
};
</script>
