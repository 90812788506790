<template lang="pug">
.s-vstack
  v-card(flat)
    v-toolbar(flat)
      .subtitle-2 Timecards
      v-spacer
      .search-box.mr-2
        v-text-field(
          v-model="searchText",
          label="Search",
          clearable,
          prepend-inner-icon="search",
          hide-details,
          solo,
          dense
        )
      Download(:punchcards="punchcards", v-if="showWage")
    v-data-table(
      :headers="headers",
      :items="punchcards",
      sort-by="clockIn",
      @click:row="edit",
      role="button",
      :search="searchText",
      mobile-breakpoint="0",
      dense
    )
      template(v-slot:item.date="{ item }")
        .d-flex.flex-row
          v-icon.mr-1(x-small, color="green", v-if="item.external_id") mdi-circle-medium
          span {{ item.clockIn | date }}
      template(v-slot:item.photo="{ item }")
        v-avatar
          v-img(:src="item.photo", v-if="item.photo")
      template(v-slot:item.clockIn="{ item }")
        span.nowrap(:class="{ 'green white--text': item.clockOut == 0 }") {{ item.clockIn | time }}
      template(v-slot:item.clockOut="{ item }")
        span(v-if="item.clockOut > 0")
          span(v-if="item.hours > 12") {{ item.clockOut | datetime }}
          span(v-else) {{ item.clockOut | time }}
        span.green.white--text(v-else) Ongoing
      template(v-slot:item.hours="{ item }")
        span(:class="{ orange: item.hours > 10 }") {{ item.hours | number(2) }}
      template(v-slot:item.rate="{ item }")
        span {{ item.rate | currency }}
      template(v-slot:item.wage="{ item }")
        span(v-if="item.status") {{ (item.hours * item.rate) | currency }}
        v-chip(color="error", small, v-else) Canceled
      template(v-slot:item.cashTip="{ item }")
        span {{ item.cashTip | currency }}
      template(v-slot:item.info="{ item }")
        v-btn(
          small,
          icon,
          @click.stop="showLog(item)",
          color="success",
          v-if="item.log?.length"
        )
          v-icon(small) mdi-information
  Actions(:items="punchcards")
  Log
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Actions from "./Actions";
import Download from "./Download";
import Log from "./Log";

export default {
  components: { Download, Log, Actions },
  data() {
    return { searchText: "" };
  },
  computed: {
    ...mapGetters(["punchcards", "biz", "useraccess"]),
    access() {
      if (!this.$auth.check() || !this.biz) return null;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      return this.useraccess?.find((o) => o.user == userId && o.biz == bizId);
    },
    showWage() {
      return this.access?.privilege == 1 || this.access?.access_wage;
    },
    headers() {
      if (this.showWage) {
        return [
          { text: "Date", value: "date", sortable: false },
          { text: "Employee", value: "serverName", sortable: false },
          { text: "Photo", value: "photo", sortable: false },
          { text: "Role", value: "role", sortable: false },
          { text: "Clock In", value: "clockIn" },
          { text: "Clock Out", value: "clockOut" },
          { text: "Hours", value: "hours", sortable: false },
          { text: "Rate", value: "rate", sortable: false },
          { text: "Wage", value: "wage", sortable: false },
          { text: "Cash Tip", value: "cashTip", sortable: false, align: "end" },
          { text: "", value: "info", sortable: false, align: "end" },
        ];
      } else {
        return [
          { text: "Date", value: "date", sortable: false },
          { text: "Employee", value: "serverName", sortable: false },
          { text: "Role", value: "role", sortable: false },
          { text: "Clock In", value: "clockIn" },
          { text: "Clock Out", value: "clockOut" },
          { text: "Hours", value: "hours", sortable: false },
          { text: "Cash Tip", value: "cashTip", sortable: false, align: "end" },
          { text: "", value: "info", sortable: false, align: "end" },
        ];
      }
    },
  },
  methods: {
    edit(data) {
      EventBus.$emit("edit-punchcard", data);
    },
    showLog(item) {
      EventBus.$emit("show-punchcard-log", item.log);
    },
  },
};
</script>

<style lang="sass" scoped>
.search-box
  max-width: 300px
</style>
