<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on")
  StripeCardForm(@saved="saved", :url="url", :params="params")
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      url: "/bizs/payments/createCard",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    params() {
      return { bizId: this.biz?._id };
    },
  },
  methods: {
    async saved(data) {
      this.$store.dispatch("setBiz", data);
      this.dialog = false;
      this.$emit("done");
    },
  },
};
</script>
