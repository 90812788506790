<template lang="pug">
v-card(max-width="400", width="95%", v-if="member")
  v-list(dense)
    v-list-item
      v-list-item-content Name
      v-list-item-content.align-end(v-if="member.name") {{ member.name.first }} {{ member.name.last }}
    v-list-item
      v-list-item-content Phone
      v-list-item-content.align-end
        span {{ member.phone | phone }}
    v-list-item
      v-list-item-content Email
      v-list-item-content.align-end
        span {{ member.email }}
    v-list-item
      v-list-item-content Birthday
      v-list-item-content.align-end(v-if="member.bd && member.bd.mm > 0") {{ member.bd.mm }}/{{ member.bd.dd }}
    v-list-item
      v-list-item-content Wedding Anniversary
      v-list-item-content.align-end(v-if="member.anniv && member.anniv.mm > 0") {{ member.anniv.mm }}/{{ member.anniv.dd }}
    v-card-text
      Form
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  computed: {
    ...mapGetters(["member"]),
  },
};
</script>
