<template lang="pug">
PieChart(:chartData="chartData")
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      colors: [
        "#7682d6",
        "#2cb2b9",
        "#50a948",
        "#ad4d24",
        "#a74ac3",
        "#c5d729",
      ],
    };
  },
  computed: {
    chartData() {
      const items = this.items.toSorted((a, b) => b.total - a.total);
      let labels = items.map((o) => o.title);
      if (labels.length > 5) {
        labels = labels.slice(0, 5);
        labels.push("Others");
      }
      let data = items.map((o) => o.total);
      if (data.length > 5) {
        data = data.slice(0, 5);
        data.push(items.slice(5).reduce((a, b) => a + b.total, 0));
      }
      data = data.map((o) => {
        return Math.round(o * 100) / 100;
      });
      const backgroundColor = this.colors.slice(0, labels.length);
      return {
        labels,
        datasets: [{ backgroundColor, data }],
      };
    },
  },
};
</script>