<template lang="pug">
v-btn(icon, @click="submit()", :loading="loading", title="Make it Default")
  v-icon(small) mdi-star-four-points
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SetDefaultBank",
  props: ["bankAccount"],
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["stripeAccount"]) },
  methods: {
    ...mapActions(["updateStripeBank"]),
    async submit() {
      if (!this.stripeAccount || !this.bankAccount) return;
      this.loading = true;
      const params = {
        accountId: this.stripeAccount.id,
        bankId: this.bankAccount.id,
      };
      try {
        const result = await this.$api.stripe.bankaccount.custom(
          "set_default",
          params
        );
        this.updateStripeBank(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
