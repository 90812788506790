<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Date
        th Source
        th Amount
        th
    tbody
      tr(
        v-for="(item, index) in items",
        :key="index",
        @click="edit(item)",
        role="button"
      )
        td {{ item.date | date }}
        td {{ item.source }}
        td {{ item.total | currency }}
        td.text-right
          v-btn(icon, small, @click.stop="handleRemove(item)")
            v-icon(color="red", small) mdi-delete
    tfoot
      tr
        th Total
        th
        th {{ total | currency }}
        th
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["expenses"]),
    items() {
      return _.sortBy(this.expenses, "date");
    },
    total() {
      return this.expenses.reduce((a, b) => a + b.total, 0);
    },
  },
  methods: {
    ...mapActions(["removeExpense"]),
    edit(item) {
      EventBus.$emit("edit-expense", item);
    },
    handleRemove(item) {
      confirm("Do you want to delete this expense?") && this.remove(item);
    },
    async remove(item) {
      if (!item?._id) return;
      try {
        const result = await this.$api.expense.entry.delete(item._id);
        this.removeExpense(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
