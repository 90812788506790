<template lang="pug">
div(v-if="order")
  div(v-if="order.seller")
    .subtitle-2 {{ order.seller.name }}
    .caption {{ order.seller.address | address }}
    .caption {{ order.seller.phone | phone }}
  v-divider.my-1
  table.clean-table.min-padding.my-1
    tbody
      LineItem(icon="mdi-ticket")
        span \#{{ order.orderNumber }} | {{ platform }} | {{ order.type | orderType }} |
        span.ml-1(:class="{ 'red--text': order.status < 0 }") {{ order.status | orderStatus }}
      LineItem(icon="mdi-clock")
        .link-box
          span {{ order.needed | datetime(null, timezone) }}
          span(v-if="timezone") ({{ timezone }})
          v-icon(small, @click="showCreated = !showCreated") mdi-chevron-down
        span.caption(v-if="showCreated") Created {{ order.created | datetime(null, timezone) }}
      LineItem(icon="mdi-cash")
        span {{ order.payment.total | currency }} (tip: {{ order.payment.tip | currency }})
    tbody(v-if="order.customer")
      LineItem(icon="mdi-account", v-if="order.customer.name")
        span {{ order.customer.name }}
      LineItem(icon="mdi-phone", v-if="order.customer.phone")
        span {{ order.customer.phone | phone }}
      LineItem(icon="mdi-map-marker", v-if="showAddress")
        span {{ order.customer.address | address }}
        span {{ order.customer.address.note }}
      LineItem(icon="mdi-email", v-if="order.customer.email")
        ClickToCopy(:text="order.customer.email")
      LineItem(icon="mdi-room-service", v-if="orderer")
        span {{ orderer }}
      LineItem(icon="mdi-book-cancel", v-if="order.cancelNote")
        span.red--text {{ order.cancelNote }}')
  v-divider.my-1(v-if="!noDivider")
</template>

<script>
import LineItem from "./LineItem";

export default {
  components: { LineItem },
  props: {
    order: { type: Object, required: true },
    noDivider: { type: Boolean, default: false },
  },
  data() {
    return {
      showCreated: false,
    };
  },
  computed: {
    timezone() {
      return this.order?.seller?.address?.timezone;
    },
    platform() {
      if (this.order.platform) return this.order.platform;
      const value = this.order.orderer && this.order.orderer.type;
      if (value === 0) return "Selflane";
      else if (value == 2) return this.order.od;
      else return "POS";
    },
    showAddress() {
      return this.order.type == "delivery";
    },
    orderer() {
      return this.order?.orderer?.name;
    },
  },
};
</script>