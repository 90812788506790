<template lang="pug">
div
  v-chip-group
    v-chip(
      v-for="group in groupedTitles",
      :key="group.title",
      color="grey ",
      text-color="white"
    )
      v-avatar.green.darken-4(left) {{ group.count }}
      .subtitle-2 {{ group.title }}
  v-simple-table.my-4
    thead
      tr
        th Order
        th Actions
    tbody
      tr(
        v-for="(group, order) in groups",
        :key="order",
        @click="showOrder(group.order)",
        style="cursor: pointer"
      )
        th \#{{ group.orderNumber }}
        td
          div(v-for="item in group.items", :key="item._id")
            .subtitle-2 {{ item.title }}
            .caption {{ item.detail }}
            .caption(v-if="item.amount") {{ item.amount | currency }}
            .caption {{ item.created | time }}
</template>

<script>
import _ from "underscore";
import { EventBus } from "@/event-bus";

export default {
  props: {
    items: { type: Array, required: true },
    orders: { type: Array, required: true },
  },
  computed: {
    groups() {
      return _.chain(this.items)
        .sortBy("created")
        .groupBy("order")
        .map((list, id) => {
          const order = this.orders?.find((o) => o._id === id);
          return {
            order: id,
            orderNumber: order?.orderNumber,
            created: order?.created,
            items: list,
          };
        })
        .sortBy("created")
        .value();
    },
    groupedTitles() {
      // group by the first two words of the title
      // map as title, count
      return _.chain(this.items)
        .groupBy((item) => {
          return item.title.split(" ").slice(0, 2).join(" ");
        })
        .map((list, title) => {
          const count = list.length;
          return { title, count };
        })
        .sortBy("title")
        .value();
    },
  },
  methods: {
    showOrder(id) {
      if (!id) return;
      EventBus.$emit("show-order-detail", id);
    },
  },
};
</script>