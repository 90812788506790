<template lang="pug">
v-combobox(
  v-model="content",
  :items="items",
  :hide-details="hideDetails",
  :single-line="singleLine",
  :label="label",
  :dense="dense",
  @change="change"
)
</template>
<script>
/// pick P&L for a course
/// value: String
import { mapGetters } from "vuex";

export default {
  name: "PnlPicker",
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "P & L" },
    singleLine: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
  },
  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    items() {
      let data = [
        "Food",
        "Beverage",
        "Alcohol",
        "Dessert",
        "Merchandise",
        "Service",
        "Retail",
      ];
      if (this.biz?.industry && this.biz.industry >= 100) {
        data = ["Merchandise", "Service", "Retail"];
      }
      if (this.biz?.courses?.length) {
        const pnl = this.biz.courses.map((item) => item.grp).filter((o) => o);
        const set = new Set(pnl);
        set.forEach((o) => {
          if (!data.includes(o)) data.unshift(o);
        });
      }
      return data;
    },
  },
  watch: {
    value() {
      this.content = this.value;
    },
  },
  methods: {
    change() {
      this.$emit("input", this.content);
    },
  },
};
</script>
