<template lang="pug">
div
  div(v-if="!isGoodstanding")
    span.red--text Your merchant account has the following errors:
    span.ml-1 {{ error_str }}
  div(v-else-if="!isOnline") Your account is verified but hasn't been turned online yet.
  .my-3
    router-link.mlink(to="/payments/online") Online Payments
    p View payments processed for online orders
  .my-3
    router-link.mlink(to="/payments/terminal") On-premise Terminal Payments
    p View payments paid with Stripe Terminals
  .my-3
    router-link.mlink(to="/payments/settings") Settings
    p Update merchant information, bank account, and access tax files.
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["stripeAccount", "biz"]),
    isOnline() {
      return this.biz && this.biz.status && this.biz.status.byAdmin;
    },
    errors() {
      return this.stripeAccount?.requirements?.errors;
    },
    isGoodstanding() {
      return !this.errors || !this.errors.length;
    },
    error_str() {
      return _.pluck(this.errors, "reason").join(" ");
    },
  },
};
</script>

<style lang="sass" scoped>
.mlink
  text-decoration: none
  font-size: large
  font-weight: 500
</style>