<template lang="pug">
div
  v-img(max-height="300", contain, :src="poster", v-if="poster")
  .d-flex.flex-row.justify-center.my-3
    Upload(:item="item")
  .d-flex.flex-row.justify-center.my-3
    v-img(max-width="200", contain, :src="background", v-if="background")
  .d-flex.flex-row.justify-center.my-3
    UploadBackground(:item="item")
</template>

<script>
import Upload from "./Upload";
import UploadBackground from "./UploadBackground";

export default {
  components: { Upload, UploadBackground },
  props: ["item"],
  computed: {
    poster() {
      if (this.item && this.item.poster && this.item.poster.length) {
        return "https://media.selflane.com/" + this.item.poster;
      }
      return null;
    },
    background() {
      if (
        this.item &&
        this.item.count_down_bkg &&
        this.item.count_down_bkg.length
      ) {
        return "https://media.selflane.com/" + this.item.count_down_bkg;
      }
      return null;
    },
  },
};
</script>