<template lang="pug">
v-dialog(v-model="dialog", width="500", @keydown.esc="dialog = false")
  v-card(v-if="item")
    v-toolbar(dense, flat)
      .subtitle-2 {{ title }}
    v-card-text
      v-form(@submit.prevent="submit")
        v-row(dense)
          v-col(cols="5")
            v-text-field(label="Name", v-model="item.name", autofocus)
          v-col(cols="7")
            v-select(
              v-model="item.orderTypes",
              multiple,
              :items="order_type_items",
              hint="Leave blank if applicable to all order types",
              persistent-hint,
              label="Applied Order Types"
            )
          v-col(cols="5")
            v-select(v-model="item.type", :items="type_items", label="Type")
          v-col(cols="7")
            v-text-field(
              label="Percentage",
              v-model.number="item.percentage",
              suffix="%",
              v-if="item.type == 0"
            )
            v-text-field(
              label="Amount",
              v-model.number="item.amount",
              prefix="$",
              v-else
            )
          v-checkbox(v-model="item.can_tax_exempt", label="Tax Exemptible")
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";

export default {
  name: "ServiceItemForm",
  props: ["input"],
  data() {
    return {
      item: null,
      dialog: false,
      errors: [],
      loading: false,
      type_items: [
        { text: "Percentage", value: 0 },
        { text: "Fixed Amount", value: 1 },
      ],
      order_type_items: [
        { text: "Delivery", value: "delivery" },
        { text: "Pick up", value: "pickup" },
        { text: "Dine in", value: "dinein" },
        { text: "Drive thru", value: "drive" },
        { text: "Retail", value: "retail" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      return this.item?._id ? "Edit Service Item" : "Add Service Item";
    },
  },
  mounted() {
    EventBus.$on("edit-service-item", this.open);
  },
  destroyed() {
    EventBus.$off("edit-service-item", this.open);
  },
  methods: {
    open(data) {
      this.item = JSON.parse(JSON.stringify(data));
      this.dialog = true;
    },
    async submit() {
      this.errors = [];
      /// validate
      if (!this.biz || !this.item) {
        this.errors = ["Invalid data"];
        return;
      }
      if (this.item.type == 0) {
        if (this.item.percentage <= 0) {
          this.errors.push("Percentage should be a positive number");
        }
        this.item.amount = 0;
      } else {
        if (this.item.amount <= 0) {
          this.errors.push("Amount should be a positive number");
        }
        this.item.percentage = 0;
      }
      this.item.name = this.item.name.trim();
      if (!this.item.name) this.errors.push("Name is required");
      if (this.errors?.length) return;
      this.loading = true;
      let params;
      if (this.item?._id) {
        params = {
          criteria: { _id: this.biz._id, "service_items._id": this.item._id },
          action: { $set: { "service_items.$": this.item } },
        };
      } else {
        params = {
          criteria: { _id: this.biz._id },
          action: { $push: { service_items: this.item } },
        };
      }
      try {
        const result = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
