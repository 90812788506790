<template lang="pug">
div(v-if="result")
  v-row(wrap)
    v-col(cols="12", sm="6", md="4")
      .s-vstack
        Counts(:data="result.counts", @showOpen="showOpen()")
        OverPayment(:data="result.overPayment")
        Sales(:data="result.sales")
        Payments(:data="result.payments")
    v-col(cols="12", sm="6", md="4")
      PaymentsDetails(:data="result.payments")
    v-col(cols="12", sm="6", md="4")
      .s-vstack
        TitledCard(:data="result.plbreakdown", title="P&L Breakdown")
        Discounts(:data="result.discounts")
        PayInOut(:data="result.cashdrawer")
        CreditReturn(:data="result.creditreturn")
        PointReport(:report="pointearn_report", title="Points Earned")
        PointReport(:report="pointredeem_report", title="Points Redeemed")
    v-col(cols="12")
      TogoBreakdown(:data="result.sales.odbreakdown")
    v-col(cols="12")
      Balances(:data="result.balances")
  v-dialog(v-model="openDialog", width="400", v-if="result")
    Opens(:data="result.opens")
  .py-2
    Download(:report="result")
    PrintPDF.ml-2(:report="result")
</template>

<script>
import { mapGetters } from "vuex";
import Counts from "./Counts";
import Sales from "./Sales/index";
import Payments from "./Payments";
import PaymentsDetails from "./PaymentsDetails";
import Balances from "./Balances";
import Discounts from "./Discounts";
import PayInOut from "./PayInOut";
import CreditReturn from "../Report/CreditReturn";
import OverPayment from "./OverPayment";
import Opens from "./Opens";
import Download from "./Download";
import PrintPDF from "./PrintPDF";
import PointReport from "@/components/Customer/Member/Point/Report/index";
import TitledCard from "./TitledCard";

export default {
  name: "BizShiftReport",
  components: {
    Counts,
    Sales,
    Payments,
    PaymentsDetails,
    Balances,
    Discounts,
    PayInOut,
    CreditReturn,
    OverPayment,
    Opens,
    Download,
    PrintPDF,
    PointReport,
    TitledCard,
  },
  props: ["result", "pointearnReport", "pointredeemReport"],
  data() {
    return {
      openDialog: false,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    showOpen() {
      if (this.result) {
        this.openDialog = true;
      }
    },
  },
};
</script>
