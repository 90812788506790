<template lang="pug">
v-dialog(v-model="dialog", width="600")
  v-card(v-if="item")
    v-toolbar(flat, dense)
      .subtitle-2 Pricing
    v-form(@submit.prevent="submit")
      v-card-text
        v-row(wrap)
          v-col(cols="12")
            v-select(
              v-model="item.platform",
              :items="platforms",
              label="Platform"
            )
          v-col(cols="12", sm="6")
            v-checkbox(v-model="item.use_percentage", label="Use Percentage")
          v-col(cols="12", sm="6", v-if="item.use_percentage")
            v-text-field(
              v-model.number="item.percentage",
              label="Percentage",
              prefix="+",
              suffix="%"
            )
          v-col(cols="12", v-else)
            v-text-field(
              v-model.number="item.amount",
              label="Amount",
              prefix="+$"
            )
      v-card-actions
        v-btn(color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      item: null,
      platforms: [
        { text: "Third Parties", value: "third_party" },
        { text: "Selflane", value: "selflane" },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    open(input) {
      if (!input) {
        this.item = {
          platform: "third_party",
          use_percentage: true,
          percentage: 20,
          amount: 0,
        };
      } else {
        this.item = JSON.parse(JSON.stringify(input));
      }
      this.dialog = true;
    },
    validate() {
      if (!this.biz._id) throw new Error("Invalid biz");
      this.item.percentage = Math.round(this.item.percentage); // integer
      this.item.amount = Math.round(this.item.amount * 100) / 100; // 2 decimal places
      if (this.item.use_percentage) {
        if (this.item.percentage < 0 || this.item.percentage > 100) {
          throw new Error("Percentage must be between 0 and 100");
        }
      } else if (this.item.amount <= 0) {
        throw new Error("Amount must be greater than 0");
      }
    },
    async submit() {
      this.loading = true;
      try {
        this.validate();
        const params = { $set: { pricings: [this.item] } };
        const result = await this.$api.biz.put(this.biz._id, params);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.response || e.message);
      }
      this.loading = false;
    },
  },
};
</script>