<!--
  This component is used to search for a business at the corporate level.
  Only available to Selflane Corp users.
-->

<template lang="pug">
v-dialog(width="500", v-model="dialog")
  v-card
    v-card-title Corporate Level Search
    v-card-text
      v-form(@submit.prevent="search")
        v-text-field(
          v-model="pattern",
          label="Search Business",
          placeholder="Enter Business Name",
          hide-details,
          :loading="loading",
          append-icon="search",
          append-icon-cb="search",
          color="secondary"
        )
    BizPicker(:bizs="bizs", @selected="dialog = false", :isSearch="true")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      pattern: "",
      bizs: [],
      loading: false,
    };
  },
  computed: { ...mapGetters(["corpaccess"]) },
  mounted() {
    EventBus.$on("search-biz-corp", this.open);
  },
  destroyed() {
    EventBus.$off("search-biz-corp", this.open);
  },
  methods: {
    ...mapActions(["setNavState"]),
    open() {
      if (this.corpaccess) this.dialog = true;
    },
    async search() {
      this.pattern = this.pattern.trim();
      if (!this.pattern || this.pattern.length < 2) {
        this.$toast.error("Please enter at least 2 characters");
        return;
      }
      this.loading = true;
      const country_states = _.chain(this.corpaccess.country_states)
        .map((o) => {
          let items = o.split("_");
          if (items.length == 2) {
            return { country: items[0], state: items[1] };
          } else {
            return { country: null, state: null };
          }
        })
        .value();
      const countries = _.chain(country_states)
        .pluck("country")
        .flatten()
        .uniq()
        .value();
      const states = _.chain(country_states)
        .pluck("state")
        .flatten()
        .uniq()
        .value();
      const criteria = {
        name: { $regex: this.pattern, $options: "i" },
        "address.country": { $in: countries },
        "address.state": { $in: states },
      };
      const params = {
        criteria: criteria,
        select: "name short_name address",
        limit: 100,
      };
      this.bizs = await this.$api.biz.list(params);
      this.loading = false;
    },
  },
};
</script>