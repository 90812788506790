const one_possible = 1
const one_impossible = 10
const one_missing = 100

class Cell {
  constructor(value, row, column) {
    this.value = value; // 0 for empty cells, 1-9 for filled cells
    this.original = value;
    this.row = row;
    this.column = column;
    this.subgrid = Math.floor(row / 3) * 3 + Math.floor(column / 3);
    this.possible = [];
  }
  setPossible(possible, steps) {
    if (this.value) {
      this.possible = [];
      return
    }
    if (this.possible.length > 0) {
      this.possible = this.possible.filter(x => possible.includes(x));
    } else {
      this.possible = possible;
    }
    if (this.possible.length === 1) {
      this.setValue(this.possible[0], steps, one_possible);
    } else {
      this.possible.sort((a, b) => a - b);
    }
  }
  setImpossible(impossible, steps) {
    if (this.value) {
      return
    }
    this.possible = this.possible.filter(x => !impossible.includes(x));
    if (this.possible.length === 1) {
      this.setValue(this.possible[0], steps, one_impossible);
    } else {
      this.possible.sort((a, b) => a - b);
    }
  }
  setValue(value, steps, difficulty = one_missing, list) {
    this.value = value;
    this.possible = [];
    if (steps) steps.push({ row: this.row, column: this.column, value, difficulty })
    if (list) list.splice(list.indexOf(value), 1);
  }
  isSuperSet(other) {
    if (this.value || other.value) return false;
    return this.possible.length >= other.possible.length && other.possible.every(x => this.possible.includes(x));
  }
}

export default Cell;