<template lang="pug">
Page(title="Create a Business")
  .d-flex.flex-row.justify-center.my-10
    CreateBiz
  Guides
</template>

<script>
import CreateBiz from "@/components/Biz/CreateBiz";
import Guides from "@/components/SelflaneServices/Online/Guides/index.vue";
import { EventBus } from "@/event-bus";

export default {
  components: { CreateBiz, Guides },
  mounted() {
    this.$store.dispatch("setNavState", "create");
    EventBus.$emit("start-create-biz");
  },
};
</script>
