<template lang="pug">
Page(title="Reviews", :doc="doc")
  template(slot="header")
    v-chip(small) {{ reviews.length }}
  List(:reviews="reviews")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import List from "/libs/components/Review/List";

export default {
  components: { List },
  data() {
    return {
      doc: "https://docs.selflane.com/biz/customer/review.html",
    };
  },
  computed: {
    ...mapGetters(["reviews", "useraccess"]),
    bizIds() {
      return this.useraccess?.filter((o) => o.privilege == 1).map((o) => o.biz);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setReviews"]),
    async load() {
      if (!this.bizIds?.length) return;
      const criteria = { biz: { $in: this.bizIds } };
      try {
        const result = await this.$api.review.list({ criteria });
        this.setReviews(result);
      } catch (e) {
        //
      }
    },
  },
};
</script>
