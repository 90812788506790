const state = {
  pointvouchers: []
}

const getters = {
  pointvouchers: state => state.pointvouchers
}

const actions = {
  setPointVouchers: ({ commit }, data) => { commit('setPointVouchers', data) },
  updatePointVoucher: ({ commit }, data) => { commit('updatePointVoucher', data) },
  addPointVoucher: ({ commit }, data) => { commit('addPointVoucher', data) },
  removePointVoucher: ({ commit }, data) => { commit('removePointVoucher', data) }
}

const mutations = {
  setPointVouchers(state, data) {
    state.pointvouchers = data
  },
  updatePointVoucher(state, data) {
    state.pointvouchers = state.pointvouchers.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addPointVoucher(state, data) {
    state.pointvouchers.push(data)
  },
  removePointVoucher(state, data) {
    state.pointvouchers = state.pointvouchers.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }