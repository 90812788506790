<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card
    v-form(@submit.prevent="submit")
      v-toolbar(flat)
        .subtitle-2 Pay with Virtual Terminal
        v-spacer
        v-btn(
          type="submit",
          :loading="loading",
          :disabled="!complete",
          color="secondary"
        ) Save
      v-divider
      v-card-text
        div {{ note }}
        .title.my-3 Amount: {{ amount }} {{ currency }}
        v-switch(v-model="confirm", label="Confirm", color="secondary")
        StripeElements(
          :stripe-key="stripeKey",
          :instance-options="instanceOptions",
          :elements-options="elementsOptions",
          #default="{ elements }",
          ref="elms"
        )
          StripeElement(
            type="card",
            :elements="elements",
            :options="cardOptions",
            ref="card",
            @change="complete = $event.complete",
            @ready="handleReady"
          )
        .caption.mt-5
          span.mr-1 Secured by
          a(href="http://www.stripe.com", target="_blank") Stripe
          span .
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { StripeElement, StripeElements } from "vue-stripe-elements-plus";
import { mapActions } from "vuex";

export default {
  components: { StripeElements, StripeElement },
  data() {
    return {
      note: "Please note that you are paying with a virtual terminal. Please make sure you have had consent from the card holder before processing this payment. The card information will not be saved. This should only be used as a last resort. For example, when your POS system is down.",
      dialog: false,
      confirm: false,
      complete: false,
      stripeKey: process.env.VUE_APP_STRIPE,
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      cardOptions: {
        // reactive
        // remember about Vue 2 reactivity limitations when dealing with options
        value: {
          postalCode: "",
        },
        classes: {
          base: "stripe-card",
          complete: "stripe-card.complete",
        },
        // https://stripe.com/docs/stripe.js#element-options
      },
      amount: 100,
      currency: "usd",
      managed_account: null,
      orderId: null,
      bizId: null,
      checkId: null,
      loading: false,
    };
  },
  mounted() {
    EventBus.$on("open-virtual-terminal", this.open);
  },
  destroyed() {
    EventBus.$off("open-virtual-terminal", this.open);
  },
  methods: {
    ...mapActions(["updateOrder"]),
    handleReady() {
      const cardComponent = this.$refs.card;
      if (!cardComponent) return;
      // Get stripe element
      const cardElement = cardComponent.stripeElement;
      if (cardElement) cardElement.focus();
    },
    open({ amount, currency, managed_account, orderId, bizId, checkId }) {
      this.amount = amount;
      this.currency = currency;
      this.managed_account = managed_account;
      this.orderId = orderId;
      this.bizId = bizId;
      this.checkId = checkId;

      if (!this.amount || !this.currency || !this.managed_account) return;
      if (!this.orderId) {
        this.$toast.error("No order id");
        return;
      }
      if (!this.bizId) {
        this.$toast.error("No biz id");
        return;
      }
      this.dialog = true;
      this.confirm = false;
    },
    async submit() {
      if (!this.amount || !this.currency || !this.managed_account) return;
      if (!this.orderId) return;
      if (!this.confirm) {
        this.$toast.error("Please confirm");
        return;
      }
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      // ref in template
      if (this.loading) return; // prevent double entry
      this.loading = true;
      const groupComponent = this.$refs.elms;
      const cardComponent = this.$refs.card;
      // Get stripe element
      const cardElement = cardComponent.stripeElement;

      const data = await groupComponent.instance.createToken(cardElement);
      if (data && data.token && data.token.id) {
        await this.save(data.token);
        cardElement.clear();
      } else if (data.message) {
        this.$toast.error(data.message);
      }
      this.loading = false;
    },
    async save(token) {
      this.loading = true;
      const params = {
        amount: Math.round(this.amount * 100),
        currency: this.currency,
        source: token.id,
        managed_account: this.managed_account,
        metadata: {
          bizId: this.bizId,
          orderId: this.orderId,
          checkId: this.checkId,
          method: "virtual terminal",
        },
      };
      try {
        const result = await this.$api.stripe.paymentIntent.create(params);
        const charge = {
          method: "stripe",
          base: result.amount / 100,
          amount: result.amount / 100,
          currency: result.currency,
          transId: result.id,
          entry: "virtual terminal",
        };
        let url = "/orders/charge";
        let params2 = { orderId: this.orderId, charge };
        if (this.checkId) {
          url = "/orders/chargeCheck";
          params2 = { orderId: this.orderId, checkId: this.checkId, charge };
        }
        const { data } = await this.axios.post(url, params2);
        this.updateOrder(data);
        this.$toast.success("Payment successful");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style>
.stripe-card {
  width: 100%;
  border: 1px solid grey;
  border-radius: 4px;
  background-color: white;
  padding: 10px 12px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.stripe-card.complete {
  border-color: green;
}
</style>