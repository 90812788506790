<template lang="pug">
v-dialog(v-model="dialog", max-width="800px")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Activities
    v-simple-table(dense)
      thead
        tr
          th Type
          th Date
          th.text-right Points
      tbody
        tr(
          v-for="item in items",
          :key="item._id",
          @click="showOrder(item)",
          role="button"
        )
          td {{ item.type }}
          td {{ item.created | date({ dynamic: true }) }}
          td.text-right {{ item.type == "Earned" ? "+" : "-" }} {{ item.amount }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
export default {
  data() {
    return {
      dialog: false,
      earned: [],
      redeemed: [],
    };
  },
  computed: {
    items() {
      let data = this.earned.map((item) => ({ type: "Earned", ...item }));
      data.push(this.redeemed.map((item) => ({ type: "Redeemed", ...item })));
      data.sort((a, b) => b.created - a.created);
      return data;
    },
  },
  methods: {
    async open(memberId) {
      if (!memberId) return;
      try {
        this.earned = await this.$api.m.pointearn.list({
          criteria: { member: memberId },
        });
        this.redeemed = await this.$api.m.pointredeem.list({
          criteria: { member: memberId },
        });
        this.dialog = true;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    showOrder(item) {
      if (!item.order) return;
      EventBus.$emit("show-order-detail", item.order);
    },
  },
};
</script>