<template lang="pug">
v-card(v-if="items", flat)
  simple-table
    thead
      tr
        th Date/Time
        th Price
        th Dishes
        th Courses
        th Menus
        th Excludes
        th 
    tbody
      tr(
        v-for="(item, index) in items",
        :key="index",
        @click="edit(item)",
        role="button"
      )
        td
          div(v-if="item.type == 0")
            span {{ item.date | day(true) }}
            br
            span {{ item.start | minutesToTime }} - {{ item.end | minutesToTime }}
          div(v-else) Anytime
        td
          span(v-if="item.isPct") {{ -item.pct }}%
            span.ml-1(v-if="item.pct > 0") off
            span.ml-1(v-else) extra
          span(v-else-if="item.isReduce") {{ -item.reduce | currency }}
            span.ml-1(v-if="item.reduce > 0") off
            span.ml-1(v-else) extra
          span(v-else) {{ item.amount | currency }} fixed
        td(v-html="$options.filters.dishNames(item.dishes, biz)")
        td {{ getCourseNames(item.courses) }}
        td {{ getMenuNames(item.menus) }}
        td {{ getExcludes(item.voids) }}
        td.text-right
          v-btn(icon, small, @click.stop="handleRemove(item)")
            v-icon.red--text(small) mdi-delete
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  props: { items: { type: Array, required: true } },
  data() {
    return {
      orderTypes: [
        { text: "Pick up", value: 1 },
        { text: "Delivery", value: 2 },
        { text: "Dine In", value: 0 },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    ...mapActions(["removePricing"]),
    getMenuNames(ids) {
      if (!ids || !this.biz) return "";
      let names = [];
      _.each(ids, (id) => {
        const menu = this.biz.menus?.find((o) => o._id === id);
        if (menu) names.push(menu.name);
      });
      return names.sort().join(", ");
    },
    getCourseNames(ids) {
      if (!ids || !this.biz) return "";
      let names = [];
      _.each(ids, (id) => {
        const course = this.biz.courses?.find((o) => o._id === id);
        if (course) names.push(course.name);
      });
      return names.sort().join(", ");
    },
    getExcludes(items) {
      return items.map((o) => this.getVoidName(o)).join(", ");
    },
    getVoidName(item) {
      const found = this.orderTypes?.find((o) => o.value == item);
      return found?.text || "";
    },
    edit(item) {
      EventBus.$emit("edit-scheduled-discount", item);
    },
    handleRemove(item) {
      const message = "Do you want to remove this scheduled price?";
      confirm(message) && this.remove(item);
    },
    async remove(item) {
      if (!item?._id) return;
      try {
        const result = await this.$api.pricing.delete(item._id);
        this.removePricing(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
