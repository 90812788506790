<template lang="pug">
v-btn(icon, small, color="error", @click.stop="deleteItem", :loading="loading")
  v-icon(small) mdi-delete
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  methods: {
    async deleteItem() {
      const id = this.item?._id;
      if (!id) return;
      try {
        this.loading = true;
        await this.$api.rs.bookedUp.delete(id);
        this.$store.dispatch("removeBookedup", this.item);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>