<template lang="pug">
v-card(v-if="data", flat)
  v-toolbar(flat, dense)
    Info
  Breakdown(:items="data.breakdown")
  Breakdown(:items="typebreakdown")
  Table(:items="detail_items")
</template>

<script>
import Info from "./Info";
import Breakdown from "./Breakdown";
import Table from "./../Table";

export default {
  components: { Info, Breakdown, Table },
  props: ["data"],
  computed: {
    items() {
      return [
        { name: "Net Sales", amount: this.data.net },
        { name: "Tax", amount: this.data.tax },
        { name: "Payment Deduction", amount: -this.data.deduction },
        { name: "Tip", amount: this.data.tip },
        { name: "Cash Discount Adjustment", amount: this.data.adjustment },
        { name: "Fee (in-store)", amount: this.data.fee },
        { name: "Third Party", amount: this.data.third_party },
        { name: "Gift Sold", amount: this.data.gift },
        { name: "Selflane Charges", amount: this.data.selflane },
        {
          name: "Total Receivables",
          amount: this.data.total,
          bold: true,
          required: true,
        },
      ];
    },
    typebreakdown() {
      const orderType = this.$options.filters.orderType;
      return this.data.typebreakdown.map((item) => {
        return {
          name: orderType(item.name),
          total: item.total,
        };
      });
    },
    perguest() {
      if (!this.data?.typebreakdown || this.data.people <= 0) return 0;
      const found = this.data.typebreakdown.find((o) => o.name == "dinein");
      return found ? found.total / this.data.people : 0;
    },
    detail_items() {
      let data = [
        { name: "Guests", amount: this.data.people, filter: "number" },
        { name: "Dine-in Sales/Guest", amount: this.perguest },
      ];
      if (this.service_items) {
        data = data.concat(this.service_items);
      }
      if (this.tax_items) {
        data = data.concat(this.tax_items);
      }
      return data;
    },
    service_items() {
      return this.data.service_items?.map((item) => {
        return { name: item.name, amount: item.total };
      });
    },
    tax_items() {
      return this.data.tax_items.map((item) => {
        let name = item.name;
        if (item.percentage) {
          name = `${item.name} ${item.percentage}%`;
        }
        return { name, amount: item.value };
      });
    },
  },
};
</script>
