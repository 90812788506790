const state = {
  memberpromo: null
}

const getters = {
  memberpromo: state => state.memberpromo
}

const actions = {
  setMemberPromo: ({
    commit
  }, memberpromo) => {
    commit('setMemberPromo', memberpromo)
  }
}

const mutations = {
  setMemberPromo(state, memberpromo) {
    if (!memberpromo.roles) memberpromo.roles = []
    state.memberpromo = memberpromo
  }
}

export default { state, getters, actions, mutations }