<template lang="pug">
v-app
  Nav
  v-main(style="background-color: #f0f2f5")
    AccessGuard
    router-view(v-if="visible")
  Footer
  LoadPOS
  OrderDetail
  VoidCharge
  InvoiceDetail
  OrdersDialog
  PhotoDialog
  VirtualTerminal
</template>

<script>
import AccessGuard from "@/components/AccessGuard";
import OrderDetail from "@/components/Order/Detail/index";
import OrdersDialog from "@/components/Performance/Sales/Home/OrdersDialog.vue";
import { mapGetters } from "vuex";
import Footer from "./components/Footer/index";
import Nav from "./components/Nav/index";
import LoadPOS from "./components/Settings/POS/LoadPOS";
import PhotoDialog from "/libs/components/Basic/PhotoDialog";
import InvoiceDetail from "/libs/components/Billing/Invoice/Detail";
import VoidCharge from "/libs/components/Order/VoidCharge";
import VirtualTerminal from "/libs/components/Stripe/VirtualTerminal";

export default {
  name: "App",
  components: {
    AccessGuard,
    Nav,
    Footer,
    LoadPOS,
    OrderDetail,
    InvoiceDetail,
    OrdersDialog,
    VoidCharge,
    PhotoDialog,
    VirtualTerminal,
  },
  computed: {
    ...mapGetters(["navState", "biz", "invoices"]),
    // block access to all biz pages if the business has unpaid invoices
    visible() {
      if (this.navState != "biz") return true;
      if (!this.biz?._id) return true;
      const unpaid = this.invoices?.find(
        (i) =>
          i.biz?.id == this.biz._id && i.status != 1 && !i.billing_organization
      );
      if (unpaid) return false;
      return true;
    },
  },
};
</script>
