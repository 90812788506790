<template lang="pug">
Page(title="Gift Cards", :items="items", :doc="doc")
</template>

<script>
export default {
  data() {
    return {
      doc: "https://docs.selflane.com/biz/performance/gift-card",
      items: [
        { title: "Home", url: "/gift/home" },
        { title: "eGift", url: "/gift/egift" },
      ],
    };
  },
};
</script>
