<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn.my-2(color="secondary", v-on="on", @click="open", small) Manage Webhook
  v-card(:loading="loading")
    v-card-title Manage Webhook
    v-card-text
      p Webhooks allow us to get the latest data you have entered in 7Shifts. With that, you can edit the data either in Selflane or 7Shifts.
    v-list(dense, v-if="!loading")
      v-list-item(v-for="item in mapped_items", :key="item.event")
        v-list-item-content
          v-list-item-title {{ item.name }}
        Connect(:item="item", :biz="biz", @connected="add")
</template>

<script>
import { mapGetters } from "vuex";
import Connect from "./Connect";

export default {
  components: { Connect },
  data() {
    return {
      dialog: false,
      loading: false,
      webhooks: [], // established webhooks, load from server
      items: [
        {
          name: "Location Created",
          event: "location.created",
          url: "https://api-pub.selflane.com/7shifts/location/create",
        },
        {
          name: "Location Modified",
          event: "location.modified",
          url: "https://api-pub.selflane.com/7shifts/location/update",
        },
        {
          name: "Location Deleted",
          event: "location.deleted",
          url: "https://api-pub.selflane.com/7shifts/location/delete",
        },
        {
          name: "Department Created",
          event: "department.created",
          url: "https://api-pub.selflane.com/7shifts/department/create",
        },
        {
          name: "Department Modified",
          event: "department.modified",
          url: "https://api-pub.selflane.com/7shifts/department/update",
        },
        {
          name: "Department Deleted",
          event: "department.deleted",
          url: "https://api-pub.selflane.com/7shifts/department/delete",
        },
        {
          name: "Role Created",
          event: "role.created",
          url: "https://api-pub.selflane.com/7shifts/role/create",
        },
        {
          name: "Role Modified",
          event: "role.modified",
          url: "https://api-pub.selflane.com/7shifts/role/update",
        },
        {
          name: "Role Deleted",
          event: "role.deleted",
          url: "https://api-pub.selflane.com/7shifts/role/delete",
        },
        {
          name: "User Created",
          event: "user.created",
          url: "https://api-pub.selflane.com/7shifts/user/create",
        },
        {
          name: "User Modified",
          event: "user.modified",
          url: "https://api-pub.selflane.com/7shifts/user/update",
        },
        {
          name: "User deactivated",
          event: "user.deactivated",
          url: "https://api-pub.selflane.com/7shifts/user/deactivate",
        },
        {
          name: "User reactivated",
          event: "user.reactivated",
          url: "https://api-pub.selflane.com/7shifts/user/reactivate",
        },
        {
          name: "Time Punch Created",
          event: "time_punch.created",
          url: "https://api-pub.selflane.com/7shifts/time_punch/create",
        },
        {
          name: "Time Punch Edited",
          event: "time_punch.edited",
          url: "https://api-pub.selflane.com/7shifts/time_punch/update",
        },
        {
          name: "Time Punch Deleted",
          event: "time_punch.deleted",
          url: "https://api-pub.selflane.com/7shifts/time_punch/delete",
        },
        {
          name: "Authorization Revoked",
          event: "authorization.revoked",
          url: "https://api-pub.selflane.com/7shifts/authorization/revoke",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    mapped_items() {
      return this.items.map((item) => {
        const webhook = this.webhooks.find((o) => o.event === item.event);
        return {
          ...item,
          connected: !!webhook,
        };
      });
    },
  },
  methods: {
    async open() {
      await this.loadWebhooks();
    },
    async loadWebhooks() {
      const bizId = this.biz?._id;
      if (!bizId) return;
      this.loading = true;
      try {
        this.webhooks = await this.$api.seven.webhook.list({ bizId });
      } catch (e) {
        //
      }
      this.loading = false;
    },
    add(item) {
      this.webhooks.push(item);
    },
  },
};
</script>