<template lang="pug">
span {{ menus }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(["biz"]),
    menus() {
      if (!this.item?.menus) return "";
      return _.chain(this.biz.menus)
        .filter((o) => this.item.menus.indexOf(o._id) != -1)
        .pluck("name")
        .value()
        .join(", ");
    },
  },
};
</script>
