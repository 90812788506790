<template lang="pug">
v-data-table(
  :headers="headers",
  :items="items",
  :search="search",
  dense,
  mobile-breakpoint="0"
)
  template(v-slot:top)
    .d-flex.flex-row.align-center.px-4
      v-text-field.mr-4(v-model="search", label="Search", clearable)
      CSVDownload(:items="csvData", file-name="product_mix_by_course")
  template(v-slot:item.amount="{ item }")
    span {{ item.amount | currency }}
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import CSVDownload from "@/components/basic/CSVDownload";

export default {
  components: { CSVDownload },
  props: {
    data: { type: Array, required: true },
    dateRange: { type: String, required: true },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Course", value: "name" },
        { text: "Quantity", value: "quantity" },
        { text: "Total", value: "amount", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    items() {
      return _.chain(this.data)
        .reject((o) => !o.course)
        .groupBy("course")
        .map((o, k) => {
          const name = this.getCourseName(k);
          const quantity = o.reduce((acc, cur) => acc + cur.quantity, 0);
          const amount = o.reduce((acc, cur) => acc + cur.amount, 0);
          return { name, quantity, amount };
        })
        .sortBy("amount")
        .reverse()
        .value();
    },
    csvData() {
      if (!this.items || !this.items.length || !this.biz) return [];
      const currency = this.$options.filters.currency;
      let data = [_.pluck(this.headers, "text")];
      _.each(this.items, (item) => {
        data.push([item.name, item.quantity, currency(item.amount)]);
      });
      data.push([]); // spacing row
      data.push([this.dateRange]);
      data.push([this.biz.name]);
      return data;
    },
  },
  methods: {
    getCourseName(val) {
      const found = this.biz.courses.find((o) => o._id == val);
      return found?.name || "N/A";
    },
  },
};
</script>
