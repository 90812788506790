<template lang="pug">
v-card.mb-10(v-if="posEditable", flat)
  v-form(@submit.prevent="submit")
    v-subheader Acceptable Methods
    v-list.pa-0(dense)
      v-list-item
        v-list-item-content
          v-list-item-title Credit Card (PAX Terminal)
        v-list-item-action
          v-switch(color="secondary", v-model="posEditable.payment.creditcard")
      v-list-item
        v-list-item-content
          v-list-item-title Stripe Reader
        v-list-item-action
          v-switch(color="secondary", v-model="posEditable.payment.stripe")
      v-list-item
        v-list-item-content
          v-list-item-title External Card Reader
        v-list-item-action
          v-switch(color="secondary", v-model="posEditable.payment.excard")
      v-list-item
        v-list-item-content
          v-list-item-title Check
        v-list-item-action
          v-switch(color="secondary", v-model="posEditable.payment.check")
      v-list-item
        v-list-item-content
          v-list-item-title Gift Certificate
        v-list-item-action
          v-switch(color="secondary", v-model="posEditable.payment.gift_cert")
    v-divider
    v-subheader Gratuity
    v-list.pa-0(dense)
      v-list-item
        v-list-item-content
          v-list-item-title Allow gratuity
        v-list-item-action
          v-switch(color="secondary", v-model="posEditable.tip.allowed")
    v-list.pa-0(dense)
      v-list-item
        v-list-item-content
          v-list-item-title Include Tax in Calculation
        v-list-item-action
          v-switch(color="secondary", v-model="posEditable.tip.tax")
    v-list.pa-0(dense, v-if="posEditable.tip.allowed")
      v-list-item
        v-list-item-content
          v-list-item-title Allow auto gratuity
        v-list-item-action
          v-switch(color="secondary", v-model="posEditable.tip.auto")
      div(v-if="posEditable.tip.auto")
        v-list-item
          v-list-item-content
            v-list-item-title Number of guests threshold
          v-list-item-action
            v-text-field(
              type="number",
              v-model="posEditable.tip.min",
              style="width: 100px",
              min="0",
              hide-details
            )
        v-list-item
          v-list-item-content
            v-list-item-title Auto gratuity percentage
          v-list-item-action
            v-text-field(
              type="number",
              v-model="posEditable.tip.pct",
              suffix="%",
              style="width: 100px",
              min="0",
              max="100",
              hide-details
            )
        v-list-item
          v-list-item-content
            v-list-item-title Allow additional tip
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.tip.add")
      v-list-item
        v-list-item-content
          v-list-item-title Separate server & kitchen tip
        v-list-item-action
          v-switch(color="secondary", v-model="posEditable.tip.doubleLine")
      v-list-item
        v-list-item-content
          v-list-item-title Suggest Tip
        v-list-item-action
          v-switch(color="secondary", v-model="posEditable.tip.sug")
      div(v-if="posEditable.tip.sug")
        v-list-item
          v-list-item-content
            v-list-item-title Tip-line Style
          v-list-item-action
            v-row(dense)
              v-col
                v-select(
                  v-model="posEditable.tip.sugStyle",
                  style="width: 120px",
                  :items="sugStyleItems",
                  hide-details
                )
        v-list-item
          v-list-item-content
            v-list-item-title Tip Tier 1
          v-list-item-action
            v-row(dense)
              v-col
                v-text-field(
                  v-model="posEditable.tip.tier1Name",
                  style="width: 120px",
                  placeholder="Name",
                  hide-details
                )
              v-col
                v-text-field(
                  v-model="posEditable.tip.tier1",
                  style="width: 60px",
                  suffix="%",
                  hide-details
                )
        v-list-item
          v-list-item-content
            v-list-item-title Tip Tier 2
          v-list-item-action
            v-row(dense)
              v-col
                v-text-field(
                  v-model="posEditable.tip.tier2Name",
                  style="width: 120px",
                  placeholder="Name",
                  hide-details
                )
              v-col
                v-text-field(
                  v-model="posEditable.tip.tier2",
                  style="width: 60px",
                  suffix="%",
                  hide-details
                )
        v-list-item
          v-list-item-content
            v-list-item-title Tip Tier 3
          v-list-item-action
            v-row(dense)
              v-col
                v-text-field(
                  v-model="posEditable.tip.tier3Name",
                  style="width: 120px",
                  placeholder="Name",
                  hide-details
                )
              v-col
                v-text-field(
                  v-model="posEditable.tip.tier3",
                  style="width: 60px",
                  suffix="%",
                  hide-details
                )
      v-list-item
        v-list-item-content
          v-list-item-title Front Display Tip Screen
        v-list-item-action
          v-switch(color="secondary", v-model="posEditable.tip.front_tip")
      v-list-item(v-if="posEditable.tip.front_tip")
        v-list-item-content
          v-list-item-title Front Display Suggested Tip Rate
        v-list-item-action
          .d-flex.flex-row.align-center.wrap
            v-chip-group.mr-5(
              active-class="secondary--text",
              v-model="posEditable.tip.default_ff_tip_rate"
            )
              v-chip(
                close,
                v-for="item in posEditable.tip.ffChoices",
                :key="item",
                :value="item",
                @click:close="handleRemoveChoice(item)"
              ) {{ item }}%
            v-text-field(
              v-model.number="new_choice",
              label="New Choice",
              hide-details,
              style="width: 120px",
              :append-icon="'mdi-check'",
              @click:append="handleAddChoice"
            )
    v-divider
    v-subheader Tip Sharing
      v-spacer
      v-btn.text-notransform(color="secondary", icon, @click="more()")
        v-icon mdi-plus
    simple-table
      thead
        tr
          th Name
          th Rate
          th 
      tbody
        tr(
          v-for="(item, index) in posEditable.tip.sharing",
          :key="index",
          draggable="true",
          @dragstart="drag(index, $event)",
          @dragend="dragend($event)",
          @drop="drop(index, $event)",
          @dragover.prevent=""
        )
          td
            v-text-field(v-model="item.name", hide-details)
          td
            v-text-field(v-model="item.rate", hide-details, suffix="%")
          td.text-right
            v-btn(icon, color="error", @click="less(index)")
              v-icon(small) mdi-delete
            v-icon(small) mdi-menu
    v-card-actions(v-if="dirty")
      v-row
        v-col(cols="8")
          v-btn.white--text(
            block,
            color="secondary",
            type="submit",
            :loading="loading"
          ) Save
        v-col(cols="4")
          v-btn(text, block, @click="cancel()") Cancel
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";
export default {
  data() {
    return {
      loading: false,
      sugStyleItems: [
        { text: "Compact", value: 0 },
        { text: "Informative", value: 1 },
      ],
      new_choice: null,
    };
  },
  computed: {
    ...mapGetters(["pos", "posEditable"]),
    dirty() {
      return JSON.stringify(this.posEditable) !== JSON.stringify(this.pos);
    },
  },
  methods: {
    ...mapActions(["setPOS", "resetEditable"]),
    handleRemoveChoice(value) {
      this.posEditable.tip.ffChoices = _.reject(
        this.posEditable.tip.ffChoices,
        (o) => o == value
      );
    },
    handleAddChoice() {
      if (this.new_choice == undefined) return;
      const value = Math.round(this.new_choice);
      if (value < 0 || value > 50) return;
      let items = JSON.parse(JSON.stringify(this.posEditable.tip.ffChoices));
      items.push(value);
      items = _.chain(items).sortBy().uniq().value();
      if (items.length > 5) {
        alert(
          "The maximum choice is 5 options. Please remove some choices first"
        );
        return;
      }
      this.posEditable.tip.ffChoices = JSON.parse(JSON.stringify(items));
    },
    async submit() {
      if (this.posEditable.tip.auto) {
        if (this.posEditable.tip.min < 0) this.posEditable.tip.min = 5;
        if (this.posEditable.tip.pct < 0 || this.posEditable.tip.pct > 100) {
          this.posEditable.tip.pct = 15;
        }
        if (this.posEditable.tip.tier1 < 0) this.posEditable.tip.tier1 = 0;
        if (this.posEditable.tip.tier2 < 0) this.posEditable.tip.tier2 = 0;
        if (this.posEditable.tip.tier3 < 0) this.posEditable.tip.tier3 = 0;
      }
      this.loading = true;
      try {
        const action = {
          $set: {
            payment: this.posEditable.payment,
            tip: this.posEditable.tip,
          },
        };
        const result = await this.$api.pos.put(this.pos?._id, { action });
        this.setPOS(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    cancel() {
      this.resetEditable();
    },
    more() {
      if (this.posEditable.tip.sharing) {
        this.posEditable.tip.sharing.push({ name: "", rate: 0 });
      } else {
        this.posEditable.tip.sharing = [{ name: "", rate: 0 }];
      }
    },
    less(index) {
      if (index > 0 && index < this.posEditable.tip.sharing.length) {
        this.posEditable.tip.sharing.splice(index, 1);
      }
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: function (index) {
      if (!this.posEditable.tip.sharing) return;
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.posEditable.tip.sharing.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.posEditable.tip.sharing.length
      )
        return;
      let sharing = JSON.parse(JSON.stringify(this.posEditable.tip.sharing));
      let tmp = JSON.parse(JSON.stringify(sharing[this.draggingIndex]));
      sharing.splice(this.draggingIndex, 1);
      sharing.splice(index, 0, tmp);
      this.posEditable.tip.sharing = sharing;
    },
  },
};
</script>
