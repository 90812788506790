<template lang="pug">
.s-vstack
  v-alert(type="warning", :value="allManager") {{ notes }}
  Departments(:biz="biz", :bizservice="bizservice")
  Table(:biz="biz", :bizservice="bizservice")
  Approval
</template>

<script>
import Table from "./Table";
import Approval from "./Approval";
import Departments from "./Departments";
import { mapGetters } from "vuex";

export default {
  components: { Table, Approval, Departments },
  data() {
    return {
      notes:
        "All roles have access to manager actions, including the ability to cancel orders, apply discounts, and open the cash drawer.",
    };
  },
  computed: {
    ...mapGetters(["pos", "biz", "bizservice"]),
    allManager() {
      return this.pos?.jobs?.every((job) => !job.manage);
    },
  },
};
</script>
