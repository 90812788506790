<template lang="pug">
div
  IconBtn(
    icon="mdi-plus",
    title="Add Time Period",
    @click="addSchedule",
    v-if="!content.length"
  )
  v-row(v-for="(item, index) in content", :key="index")
    v-col(cols="10")
      TimeRangePicker(v-model="item.range", @changed="change")
    v-col(cols="2")
      .d-flex.flex-row.justify-end
        v-btn(
          small,
          icon,
          color="red",
          @click="content.splice(index, 1)",
          v-if="index != 0"
        )
          v-icon(small) mdi-delete
        v-btn(
          small,
          icon,
          color="secondary",
          @click="addSchedule",
          v-if="index == content.length - 1"
        )
          v-icon(small) mdi-plus
</template>
<script>
/// pick schedule [Range]
/// input value should be [{Range: [Int, Int]}]

export default {
  name: "SchedulePicker",
  props: {
    value: { type: Array, required: true },
  },
  data() {
    return {
      content: this.value || [{ range: [480, 1200] }],
    };
  },
  watch: {
    value() {
      this.content = this.value || [{ range: [480, 1200] }];
    },
  },
  methods: {
    addSchedule() {
      let start = 480;
      let end = 1200;
      if (this.content.length > 0) {
        start = this.content[this.content.length - 1].range[1] + 60;
        if (start >= 1440) {
          end = start + 60;
        } else {
          end = start + 300;
        }
      }
      this.content.push({ range: [start, end] });
      this.change();
    },
    change() {
      this.content = this.content?.sort((a, b) => a.range[0] - b.range[0]);
      this.$emit("input", this.content);
    },
  },
};
</script>
