<template lang="pug">
v-card(flat)
  v-toolbar(dense, flat)
    .subtitle-2 Update Stripe Account
    v-spacer
    v-btn(icon, small, color="green", :loading="loading", @click.stop="load()")
      v-icon(small) sync
  v-card-text
    AccountLinkUpdate.mb-4(:accountId="accountId")
    Footnote
</template>

<script>
import { mapGetters } from "vuex";
import Footnote from "./Footnote";

export default {
  components: { Footnote },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz"]),
    accountId() {
      return this.biz?.payments?.managed_account;
    },
  },
  methods: {
    async load() {
      if (!this.biz._id) return;
      this.loading = true;
      try {
        const result = await this.$api.biz.custom("updateStripeAccount", {
          bizId: this.biz._id,
        });
        this.$store.dispatch("setBiz", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
