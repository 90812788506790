<template lang="pug">
Page(title="Setup Guide")
  v-col(align="center")
    v-btn(
      outlined,
      rounded,
      small,
      color="secondary",
      href="https://shop.selflane.com",
      target="_blank"
    ) Use Selflane POS
    OnlineSetup.my-5
    URL
</template>

<script>
import URL from "@/components/Settings/Profile/Settings/URL";
import OnlineSetup from "./OnlineSetup";

export default {
  components: { OnlineSetup, URL },
};
</script>
