<template lang="pug">
div(v-if="payouts?.length")
  v-data-table(
    :items="payouts",
    :headers="headers",
    dense,
    mobile-breakpoint=0,
    :footer-props="{ itemsPerPageOptions: [20, 50, -1] }",
    @click:row="handleShow",
    role="button"
  )
    template(v-slot:item.created="{ item }")
      span {{ (item.created * 1000) | date }}
    template(v-slot:item.arrival_date="{ item }")
      span {{ (item.arrival_date * 1000) | date }}
    template(v-slot:item.amount="{ item }")
      span {{ (item.amount / 100) | currency }}
  .d-flex.flex-row.pa-3
    .body-2 {{ payouts.length }} payouts in year {{ year }} with total {{ total | currency }}
    v-spacer
    IconBtn(
      @click="download()",
      title="CSV",
      icon="mdi-download",
      :small="false",
      :x-small="true"
    )
  Transactions
</template>

<script>
import { EventBus } from "@/event-bus";
import Transactions from "./Transactions/index";
import CSV from "/libs/utils/CSV";

export default {
  components: { Transactions },
  props: {
    payouts: { type: Array, required: true },
    year: { type: Number, required: true },
  },
  data() {
    return {
      headers: [
        { text: "Created", value: "created" },
        { text: "Arrive Date", value: "arrival_date" },
        { text: "Amount", value: "amount" },
        { text: "Status", value: "status", align: "end" },
      ],
    };
  },
  computed: {
    total() {
      return this.payouts.reduce((acc, cur) => acc + cur.amount / 100, 0);
    },
  },
  methods: {
    handleShow(item) {
      EventBus.$emit("show-payout-transactions", item);
    },
    download() {
      if (!this.payouts) return;
      const currency = this.$options.filters.currency;
      const date = this.$options.filters.date;
      const csv = new CSV();
      csv.addRow(["Created", "Arrive Date", "Amount", "Status"]);
      this.payouts.forEach((item) => {
        csv.addRow([
          date(item.created * 1000),
          date(item.arrival_date * 1000),
          currency(item.amount / 100),
          item.status,
        ]);
      });
      csv.addRow(["Total", "", currency(this.total), ""]);
      csv.save(this.year + " Payouts");
    },
  },
};
</script>
