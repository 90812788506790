<template lang="pug">
v-card(v-if="result", flat, :loading="loading")
  simple-table
    thead
      tr
        th Name
        th Tax Rate
        th Net Sales
        th.text-right Tax
    tbody
      tr(v-if="result.tax_exempt && result.tax_exempt.count > 0")
        td Tax Exempt
        td 0%
        td {{ result.tax_exempt.dish | currency }}
        td.text-right $0
      tr(v-for="(item, rindex) in result.tax_items", :key="rindex")
        td {{ item.name }}
        td {{ item.percentage }}%
        td {{ item.net_sales | currency }}
        td.text-right {{ item.value | currency }}
      tr
        th Total
        th
        th {{ net_sales | currency }}
        th.text-right {{ tax | currency }}
</template>

<script>
export default {
  props: ["result", "loading"],
  computed: {
    net_sales() {
      return this.result?.tax_items?.reduce((a, b) => a + b.net_sales, 0);
    },
    tax() {
      return this.result?.tax_items?.reduce((a, b) => a + b.value, 0);
    },
  },
};
</script>
