<template lang="pug">
v-dialog(v-model="dialog", width="450")
  template(v-slot:activator="{ on }")
    v-list-item(outlined, small, color="secondary", v-on="on")
      span Set Bulk Printer
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Set All Items Printer
    v-divider
    v-form(@submit.prevent="save")
      v-card-text
        Note(:courseId="courseId")
        v-btn-toggle.mb-3(v-model="prints", color="secondary", multiple)
          v-btn(
            @click.stop,
            v-for="(item, index) in printItems",
            :key="index",
            :value="item.value",
            :title="item.title"
          )
            v-icon(small) {{ item.icon }}
            span.ml-1(v-if="item.text") {{ item.text }}
        v-btn(type="submit", block, color="secondary", :loading="loading") Save
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Note from "./Note";

export default {
  components: { Note },
  props: {
    courseId: { type: String, default: null },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      prints: [],
    };
  },
  computed: {
    ...mapGetters(["biz", "printItems"]),
  },
  methods: {
    async save() {
      if (!this.biz) return;
      this.loading = true;
      _.each(this.biz.dishes, (o) => {
        if (o.course == this.courseId) o.prints = this.prints;
      });
      try {
        const action = { $set: { dishes: this.biz.dishes } };
        const data = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", data);
        this.prints = [];
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
