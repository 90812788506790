<template lang="pug">
div(v-if="shifts?.length")
  .subtitle-2.my-3 Shifts
  v-tabs(v-model="tab", centered, grow, color="primary")
    v-tab(v-for="(item, index) in sorted", :key="index") {{ item.shift.created | time }}
      span.ml-1(v-if="item.shift.station") (Sta. {{ item.shift.station }})
    v-tab-item(
      v-for="(item, index) in sorted",
      :key="index",
      style="background-color: #ced5d9"
    )
      ShiftReport.pa-3(:result="item.report")
</template>

<script>
import ShiftReport from "./ShiftReport";

export default {
  components: { ShiftReport },
  props: ["shifts"],
  computed: {
    sorted() {
      return this.shifts?.toSorted((a, b) => a.shift.created - b.shift.created);
    },
  },
};
</script>
