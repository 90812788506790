<template lang="pug">
v-card(v-if="data", flat)
  Table(:items="tip_items")
    template(v-slot:header)
      thead
        tr
          th Tip
          th.text-right {{ data.totaltip | currency }}
  Table(:items="data.ccbreakdown")
    template(v-slot:header)
      thead
        tr
          th Credt card transitions
          th.text-right {{ data.ncc }}
  div(v-if="data.exccbreakdown && data.exccbreakdown.length")
    Table(:items="data.exccbreakdown")
      template(v-slot:header)
        thead
          tr
            th Excard
            th
  Table(:items="data.cashByStation")
    template(v-slot:header)
      thead
        tr
          th Cash
          th
  div(v-if="data.giftbreakdown && data.giftbreakdown.length")
    Table(:items="data.giftbreakdown")
      template(v-slot:header)
        thead
          tr
            th Gift Redeemed
            th
  Table(:items="data.chargesByStation")
    template(v-slot:header)
      thead
        tr
          th Stations
          th
    tfoot
      tr
        td Deposit (Cash - Tip)
        td.text-right {{ (data.cash - data.totaltip) | currency }}
</template>

<script>
import Table from "./Table";

export default {
  components: { Table },
  props: ["data"],
  computed: {
    tip_items() {
      return [
        { name: "CC Tip", amount: this.data.cctip },
        { name: "Stripe Tip", amount: this.data.stripetip },
        { name: "Check Tip", amount: this.data.checktip },
        { name: "External Card Tip", amount: this.data.excctip },
        { name: "Gift Tip", amount: this.data.gctip },
        { name: "Online Tip", amount: this.data.onlineTip },
      ];
    },
  },
};
</script>
