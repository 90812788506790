<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";

export default {
  props: ["items"],
  methods: {
    download() {
      if (!this.items) return;
      const currency = this.$options.filters.currency;
      const datetime = this.$options.filters.datetime;
      const csv = new CSV();
      csv.addRow(["Date", "Total", "Fees", "Net", "Type"]);
      this.items.forEach((item) => {
        csv.addRow([
          datetime(item.created * 1000),
          currency(item.amount / 100),
          currency(item.fee / 100),
          currency(item.net / 100),
          item.type,
        ]);
      });
      csv.save("Payout Transactions");
    },
  },
};
</script>
