<template lang="pug">
PartnerPage(title="Service Logs", :doc="doc")
  .link-box
    YearSelector(@select="load")
    IconBtn(@click="create", title="Create", icon="add")
  List.my-3(:items="service_logs")
  Form
</template>

<script>
import List from "/libs/components/CRM/ServiceLog/List";
import Form from "/libs/components/CRM/ServiceLog/Form";
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  components: { List, Form },
  data() {
    return {
      doc: "https://docs.selflane.com/partner/crm/service-logs/",
    };
  },
  computed: {
    ...mapGetters(["salesOrganization"]),
    ...mapGetters("crm", ["service_logs"]),
  },
  methods: {
    async load(year) {
      const organization = this.salesOrganization?._id;
      if (!organization) return;

      // get unix timestamp of the year
      const begin = new Date(year, 0).getTime();
      const end = new Date(year + 1, 0).getTime();
      const params = {
        criteria: { organization, created: { $gte: begin, $lt: end } },
      };
      try {
        const result = await this.$api.crm.serviceLog.list(params);
        this.$store.dispatch("crm/setServiceLogs", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load service logs");
      }
    },
    create() {
      EventBus.$emit("edit-service-log");
    },
  },
};
</script>