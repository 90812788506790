<template lang="pug">
div
  v-data-table(
    :items="sorted",
    :headers="headers",
    mobile-breakpoint=0,
    @click:row="showInvoice",
    role="button"
  )
    template(v-slot:item.period.month="{ item }")
      span {{ item.period.year }}/{{ item.period.month }}
    template(v-slot:item.payment.total="{ item }")
      span {{ item.payment.total | currency }}
    template(v-slot:item.action="{ item }")
      v-btn.mr-3(
        x-small,
        outlined,
        color="error",
        @click.stop="showPay(item)",
        v-if="!item.payment.charge?.id"
      ) pay
      Download(:item="item")
  Pay
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Download from "./Download";
import Pay from "./Pay";

export default {
  name: "InvoiceList",
  components: { Pay, Download },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Month", value: "period.month", sortable: false },
        { text: "Amount", value: "payment.total", align: "end" },
        { text: "", value: "action", align: "end" },
      ],
    };
  },
  computed: {
    sorted() {
      return this.items?.toSorted((a, b) => {
        return b.period.year - a.period.year || b.period.month - a.period.month;
      });
    },
  },
  methods: {
    showInvoice(item) {
      EventBus.$emit("show-invoice", item._id);
    },
    showPay(item) {
      EventBus.$emit("show-pay-invoice", item);
    },
  },
};
</script>
