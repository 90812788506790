<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="biz && weeklySpecial")
    v-toolbar(dense, flat)
      .subtitle-2 {{ title }} a Weekly Special
    v-divider
    v-card-text
      v-form(@submit.prevent="save")
        v-row(dense)
          v-col(cols="6")
            v-text-field(label="Title", v-model="weeklySpecial.name")
          v-col(cols="6")
            v-text-field(
              v-model="weeklySpecial.pct",
              label="Discount Percentage",
              suffix="% off"
            )
        v-text-field(label="Description", v-model="weeklySpecial.description")
        v-select(
          v-model="weeklySpecial.days",
          :items="weekdays",
          label="Days",
          multiple,
          color="secondary"
        )
        .text-center.my-3
          .subtitle-2 Set Expiration Date
          v-date-picker.my-3(
            v-model="expiry",
            :min="minDate",
            color="secondary",
            no-title
          )
        v-btn(block, color="secondary", :loading="loading", type="submit") Save
      .mt-2
      div We don't charge any fee on weekly special discounts
      div It will apply to any orders on the selected days; with no limit on customers' ordering history.
</template>

<script>
import moment from "moment-timezone";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      minDate: moment().format("YYYY-MM-DD"),
      weeklySpecial: null,
      expiry: null,
      weekdays: [
        { text: "Monday", value: 1 },
        { text: "Tuesday", value: 2 },
        { text: "Wednesday", value: 3 },
        { text: "Thursday", value: 4 },
        { text: "Friday", value: 5 },
        { text: "Saturday", value: 6 },
        { text: "Sunday", value: 0 },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      if (this.weeklySpecial && this.weeklySpecial._id) return "Edit";
      else return "Create";
    },
  },
  mounted() {
    EventBus.$on("edit-weekly-special", this.open);
  },
  destroyed() {
    EventBus.$off("edit-weekly-special", this.open);
  },
  methods: {
    ...mapActions(["addWeeklySpecial", "updateWeeklySpecial"]),
    open(data) {
      if (!this.biz) return;
      if (data) {
        this.weeklySpecial = JSON.parse(JSON.stringify(data));
        this.expiry = moment(data.end).format("YYYY-MM-DD");
      } else {
        this.weeklySpecial = {
          biz: this.biz._id,
          pct: 5,
          name: "",
          description: "",
          expiry: null,
        };
        this.expiry = moment().add(3, "months").format("YYYY-MM-DD");
      }
      this.dialog = true;
    },
    save() {
      /// validate
      if (
        !this.weeklySpecial.pct ||
        this.weeklySpecial.pct < 5 ||
        this.weeklySpecial > 80
      ) {
        alert("Discount percentage should be between 5% to 80%");
        return;
      }
      if (!this.weeklySpecial.name) {
        alert("Name is required");
        return;
      }
      if (!this.weeklySpecial?.days?.length) {
        alert("Day is required");
        return;
      }
      this.weeklySpecial.days.sort();
      if (this.weeklySpecial._id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      if (!this.biz) return;
      this.loading = true;
      this.weeklySpecial.end = moment(this.expiry).endOf("day").valueOf();
      try {
        const url = "/discount/weeklySpecial/create";
        const { data } = await this.axios.post(url, this.weeklySpecial);
        this.addWeeklySpecial(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async update() {
      if (!this.biz || !this.weeklySpecial._id) return;
      this.loading = true;
      this.weeklySpecial.end = moment(this.expiry).endOf("day").valueOf();
      const params = {
        criteria: { _id: this.weeklySpecial._id },
        action: { $set: this.weeklySpecial },
      };
      try {
        const url = "/discount/weeklySpecial/update";
        const { data } = await this.axios.post(url, params);
        this.updateWeeklySpecial(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
