<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Program
        th Start
        th End
        th 
    tbody
      tr(v-for="(item, index) in rewards", :key="index")
        td
          div(v-if="item.tiers.length > 0")
            div(v-for="(t, index) in item.tiers", :key="index") {{ t.pct }}% {{ t.span }} {{ t.spanUnit }}
          div(v-else) {{ item.pct }}% {{ item.span }} {{ item.spanUnit }}
        td {{ item._id | datetime }}
        td
          span(v-if="item.ended > 0") {{ item.ended | datetime }}
          span(v-else) Live
        td.text-right
          v-btn.mx-0(
            v-if="item.ended === 0",
            color="error",
            outlined,
            rounded,
            small,
            @click.stop="handleRetire(item)"
          ) Retire
  v-card-text
    div Reward program can be set to attract repeating customers. Reward is based on spendings before tax and tip. Spending on gift cards is also excluded. One reward can only be used for one sale. If the reward exceeds sales value, it will only apply a discount equals to the sales value.
</template>

  
<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      headers: [
        { text: "Program", sortable: false },
        { text: "Start", sortable: false },
        { text: "End", sortable: false },
        { text: "", sortable: false },
      ],
      toEdit: null,
    };
  },
  computed: {
    ...mapGetters(["biz", "rewards"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setRewards", "updateReward"]),
    load() {
      if (!this.biz || !this.biz._id) return;
      const options = {
        criteria: { biz: this.biz._id },
      };
      this.axios.post("/reward/list", options).then((response) => {
        this.setRewards(_.sortBy(response.data, (o) => -o.created));
      });
    },
    handleRetire(item) {
      const message = "Do you want to retire this reward program?";
      confirm(message) && this.retire(item);
    },
    async retire(item) {
      if (!item || !item._id) return;
      const params = { rewardId: item._id };
      try {
        const { data } = await this.axios.post("/reward/retire", params);
        this.updateReward(data);
        this.$toast.success("Reward program retired.");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
