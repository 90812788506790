<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="program")
    v-toolbar(flat)
      .subtitle-2 Edit Gift Card Program
    v-card-text
      v-form(@submit.prevent="save()")
        PriceField(
          v-model.number="program.minimum_purchase",
          label="Minimum Purchase"
        )
        v-switch(
          v-model="program.discount.status",
          color="secondary",
          label="Discount"
        )
        div(v-if="program.discount.status")
          v-row(dense)
            v-col(cols="6")
              v-text-field(
                v-model.number="program.discount.pct",
                prefix="-",
                suffix="%",
                label="Percentage Discount",
                placeholder="10"
              )
            v-col(cols="6")
              PriceField(
                v-model.number="program.discount.amount",
                label="Minimum Qualified Purchase"
              )
          v-text-field(
            v-model="program.discount.desc",
            label="Short Description",
            placeholder="Celebrate Holiday(optional)"
          )
        v-btn(type="submit", block, color="secondary") Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      program: null,
    };
  },
  computed: {
    ...mapGetters(["biz", "giftProgram"]),
  },
  mounted() {
    EventBus.$on("edit-gift-program", this.open);
  },
  destroyed() {
    EventBus.$off("edit-gift-program", this.open);
  },
  methods: {
    ...mapActions(["setGiftProgram"]),
    open() {
      this.program = JSON.parse(JSON.stringify(this.giftProgram));
      this.dialog = true;
    },
    async save() {
      if (!this.program || !this.program._id) return;
      if (this.program.discount.status) {
        if (!this.program.discount.pct || this.program.discount.pct <= 0) {
          return this.$toast.error("Please enter a percentage discount value.");
        } else {
          this.program.discount.pct =
            Math.round(this.program.discount.pct * 10) / 10;
        }
      }
      const params = {
        criteria: { _id: this.program._id },
        action: { $set: this.program },
      };
      const url = "/gift/program/update";
      const { data } = await this.axios.post(url, params);
      this.setGiftProgram(data);
      this.dialog = false;
    },
  },
};
</script>