<template lang="pug">
div
  v-btn-toggle.mb-3(rounded, color="secondary", v-model="unit")
    v-btn.text-capitalize(small, value="monthly") Monthly
    v-btn.text-capitalize(small, value="weekly") Weekly
  Weekly(v-if="unit == 'weekly'", :year="year")
  Monthly(v-if="unit == 'monthly'", :year="year")
</template>

<script>
import Monthly from "./Monthly";
import Weekly from "./Weekly";

export default {
  components: { Weekly, Monthly },
  props: ["year"],
  data() {
    return {
      unit: "monthly",
    };
  },
};
</script>
