<template lang="pug">
div
  v-card.my-5(flat, :loading="loading")
    v-toolbar(flat, dense)
      .subtitle-2 Menus
      v-spacer
      IconBtn(@click="create()", title="Add", icon="mdi-plus")
    simple-table
      thead
        tr
          th Name
          th Hours
          th.nowrap # Items
          th 
      tbody
        tr(
          v-for="(item, index) in biz.menus",
          :key="index",
          @click="edit(item)",
          role="button",
          draggable="true",
          @dragstart="drag(index, $event)",
          @dragend="dragend($event)",
          @drop="drop(index, $event)",
          @dragover.prevent=""
        )
          th
            .d-flex.flex-row
              v-icon.mr-2(x-small) mdi-menu
              div
                .nowrap {{ item.name }}
                .caption.text--secondary {{ item.description }}
              v-chip.ml-2(v-if="item.ooo", color="error", small) 86'd
              v-chip.ml-2(v-if="item.catering", color="secondary", small) Catering
          td
            div(v-if="item.selectedTime")
              .nowrap {{ item.date | day(true) }}
              .nowrap {{ item | dayschedule(false) }}
            span(v-else) Store Hours
          td {{ countDish(item._id) }}
          td
            .d-flex.flex-row.justify-end
              ToggleOnline(:item="item")
              ToggleThirdParty(:item="item")
              DeleteOne(:item="item")
  .body-2 In the hierarchical menu system, <b>menus</b> occupy the topmost level of the hierarchy and generally outline the various categories of offerings provided, such as <b>Food</b>, <b>Drink</b>, <b>Lunch</b>, <b>Dinner</b>, <b>Happy Hour</b>, and <b>Bar</b>.
  Form
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./MenuForm";
import ToggleOnline from "./ToggleOnline";
import ToggleThirdParty from "./ToggleThirdParty";
import DeleteOne from "./DeleteOne";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Form, ToggleOnline, ToggleThirdParty, DeleteOne },
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    countDish(menuId) {
      return this.biz?.dishes?.filter((o) =>
        o.menus.some((id) => id === menuId)
      )?.length;
    },
    create() {
      const data = {
        name: "",
        description: "",
        ooo: false,
        controlled: false,
        third_party: true,
        catering: false,
      };
      this.edit(data);
    },
    edit(item) {
      EventBus.$emit("edit-menu", item);
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: async function (index) {
      if (index == this.draggingIndex) return;
      if (!this.biz || !this.biz.menus) return;
      if (index < 0 || index >= this.biz.menus.length) return;
      if (this.draggingIndex < 0 || this.draggingIndex >= this.biz.menus.length)
        return;
      this.loading = true;
      let menus = JSON.parse(JSON.stringify(this.biz.menus));
      let tmp = JSON.parse(JSON.stringify(menus[this.draggingIndex]));
      menus.splice(this.draggingIndex, 1);
      menus.splice(index, 0, tmp);
      const action = { $set: { menus } };
      const data = await this.$api.biz.put(this.biz._id, action);
      this.$store.dispatch("setBiz", data);
      this.loading = false;
    },
  },
};
</script>
