<template lang="pug">
v-dialog(v-model="dialog", max-width="500px")
  v-card
    v-card-title Upload Gallery Item
    form(@submit.prevent="generateBlob")
      v-card-text
        croppa(
          v-model="myCroppa",
          :width="450",
          :height="300",
          :quality="3",
          initial-size="cover"
        )
      v-card-actions
        v-btn(type="submit", color="secondary", :loading="loading") Save
</template>

<script>
export default {
  props: {
    domain: { type: Object, required: true },
  },
  data() {
    return {
      dialog: false,
      myCroppa: null,
      loading: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    generateBlob() {
      this.myCroppa.generateBlob((blob) => {
        if (!blob) {
          this.myCroppa.chooseFile();
          return;
        }
        this.submit(blob);
      }, "image/jpeg");
    },
    async submit(blob) {
      this.loading = true;
      try {
        const domainId = this.domain?._id;
        if (!domainId) {
          throw new Error("Invalid domain id.");
        }
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("domainId", domainId);
        const result = await this.$api.b.domain.custom(
          "uploadGallery",
          formData
        );
        await this.pause(500);
        this.$toast.success("Item uploaded.");
        this.$store.dispatch("setDomain", result);
        this.myCroppa.remove();
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async pause(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>