<template lang="pug">
v-btn(:href="url", target="_blank", color="secondary", small, outlined)
  span.text-notransform Pricing
</template>

<script>
export default {
  data() {
    return {
      url: "https://next.selflane.com/products/pricing",
    };
  },
};
</script>
