<template lang="pug">
v-switch(
  v-model="memberpromo.status",
  @change="save(memberpromo.status)",
  label="Status",
  :loading="loading"
)
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      status: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["memberpromo"]),
  },
  methods: {
    ...mapActions(["setMemberPromo"]),
    async save(status) {
      if (!this.memberpromo) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.memberpromo._id },
        action: { $set: { status: status } },
      };
      try {
        const result = await this.$api.memberPromo.update(params);
        this.setMemberPromo(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
