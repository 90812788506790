import Dashboard from './Dashboard/index.vue'
import Main from './index.vue'
import Manage from './Manage/index.vue'
import Marketing from './Marketing/index.vue'
import Point from './Point/index.vue'
import Profile from './Profile/index.vue'

export default [{
  path: '/member',
  component: Main,
  meta: { auth: true },
  children: [
    { path: '', redirect: 'dashboard', },
    { path: 'dashboard', component: Dashboard },
    { path: 'manage', component: Manage },
    { path: 'manage/:memberId', component: Profile, props: true, },
    { path: 'points', component: Point },
    { path: 'active_marketing', component: Marketing }
  ]
}]