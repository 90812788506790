<template lang="pug">
v-dialog(v-model="dialog")
  v-card(v-if="grouped")
    v-toolbar(dense, flat)
      .subtitle-2 Time Card Report
      v-spacer
      CSV.mr-3(:grouped="grouped", :isDecimal="isDecimal")
      PDF(:grouped="grouped", :isDecimal="isDecimal")
    simple-table
      thead
        tr
          th Employee
          th Role
          th Clock In
          th Clock Out
          th Hrs
          th(v-if="showWage") Rate
          th(v-if="showWage") Pay
          th(v-if="showWage") Cash Tip
      ServerBlock(
        v-for="(group, gindex) in grouped",
        :key="gindex",
        :group="group",
        :isDecimal="isDecimal",
        :showWage="showWage"
      )
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";
import CSV from "./CSV";
import PDF from "./PDF";
import ServerBlock from "./ServerBlock";
import { EventBus } from "@/event-bus.js";

export default {
  components: { CSV, PDF, ServerBlock },
  props: ["isDecimal", "showWage"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["punchcards"]),
    ...mapGetters("hr", ["persons"]),
    grouped() {
      const data = _.chain(this.punchcards)
        .groupBy("server")
        .map((list, serverId) => {
          const last = _.last(list);
          const byRole = _.chain(list)
            .groupBy("role")
            .map((o, name) => {
              const totalHrs = this.getTotalHrs(o);
              const totalPay = this.getTotalPay(o);
              const cashTip = this.getTotalCashTip(o);
              return {
                name,
                list: _.sortBy(o, "clockIn"),
                totalHrs,
                totalPay,
                cashTip,
              };
            })
            .sortBy("name")
            .value();
          const totalHrs = this.getTotalHrs(list);
          const totalPay = this.getTotalPay(list);
          const cashTip = this.getTotalCashTip(list);
          return {
            server: last.serverName,
            serverId,
            employeeID: this.getEmployeeID(serverId),
            byRole,
            totalHrs,
            totalPay,
            cashTip,
            items: _.sortBy(list, "clockIn"),
          };
        })
        .sortBy("server")
        .value();
      return data;
    },
  },
  mounted() {
    EventBus.$on("show-punchcard-dialog", this.show);
  },
  destroyed() {
    this.setPunchcards([]);
    EventBus.$off("show-punchcard-dialog", this.show);
  },
  methods: {
    ...mapActions(["setPunchcards"]),
    /// sort by clockin, then server name, then by day
    getEmployeeID(serverId) {
      const found = this.persons.find((o) => o._id == serverId);
      return found?.employeeID || "";
    },
    show() {
      this.dialog = true;
    },
    getTotalHrs(items) {
      return items.filter((o) => o.status).reduce((a, b) => a + b.hours, 0);
    },
    getTotalPay(items) {
      return items
        .filter((o) => o.status)
        .reduce((a, b) => a + b.hours * b.rate, 0);
    },
    getTotalCashTip(items) {
      return items.filter((o) => o.status).reduce((a, b) => a + b.cashTip, 0);
    },
  },
};
</script>

<style lang="sass" scoped>
table
  border-collapse: collapse
  width: 100%
  font-size: 14px
  gap: 6px

table thead tr th
  text-align: left
  border-bottom: 1px solid black
</style>