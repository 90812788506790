<template lang="pug">
v-btn(icon, @click.stop="submit", :loading="loading")
  v-icon(:class="color", small) mdi-moped
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz"]),
    color() {
      return this.item.third_party
        ? "green--text"
        : "grey--text text--lighten-1";
    },
  },
  methods: {
    async submit() {
      if (!this.biz) return;
      if (!this.item || !this.item._id) return;
      this.loading = true;
      const value = !(this.item.third_party == true);
      const params = {
        criteria: { _id: this.biz._id, "courses._id": this.item._id },
        action: { $set: { "courses.$.third_party": value } },
      };
      const data = await this.$api.biz.update(params);
      this.$store.dispatch("setBiz", data);
      this.loading = false;
    },
  },
};
</script>
