<template lang="pug">
v-card-text
  v-progress-circular(
    v-if="loading",
    indeterminate,
    size="20",
    color="secondary"
  )
  v-checkbox(
    :label="label",
    v-model="pos.epson.print_groups_all",
    color="secondary",
    @change="toggle",
    v-else
  )
  .caption You can print items to all stations when equiped with multiple kitchen stations.
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      label: "Print Items to All Stations",
    };
  },
  computed: { ...mapGetters(["pos"]) },
  methods: {
    ...mapActions(["setPOS"]),
    async toggle(value) {
      if (!this.pos) return;
      this.loading = true;
      try {
        const action = { $set: { "epson.print_groups_all": value } };
        const result = await this.$api.pos.put(this.pos?._id, { action });
        this.setPOS(result);
      } catch (e) {
        this.pos.epson.print_groups_all = !value;
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>