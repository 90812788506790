const state = {
  combos: []
}

const getters = {
  combos: state => state.combos
}

const actions = {
  setCombos: ({ commit }, data) => { commit('setCombos', data) },
  updateCombo: ({ commit }, data) => { commit('updateCombo', data) },
  addCombo: ({ commit }, data) => { commit('addCombo', data) },
  removeCombo: ({ commit }, data) => { commit('removeCombo', data) }
}

const mutations = {
  setCombos(state, data) {
    state.combos = data
  },
  updateCombo(state, data) {
    state.combos = state.combos.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addCombo(state, data) {
    state.combos.push(data)
  },
  removeCombo(state, data) {
    state.combos = state.combos.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }