const state = {
  navState: 'biz', // biz, partner, enterprise, create
  useraccess: [], // access defined by user for biz
  userChainAccess: [], // access defined by user for chain
  bizaccess: [], // access defined by biz
  chainaccess: [], // access defined by biz
}

const getters = {
  navState: state => state.navState,
  useraccess: state => state.useraccess,
  userChainAccess: state => state.userChainAccess,
  bizaccess: state => state.bizaccess,
  chainaccess: state => state.chainaccess,
}

const actions = {
  setNavState: ({ commit }, data) => { commit('setNavState', data) },
  setUserAccesses: ({ commit }, data) => { commit('setUserAccesses', data) },
  updateUserAccess: ({ commit }, data) => { commit('updateUserAccess', data) },
  addUserAccess: ({ commit }, data) => { commit('addUserAccess', data) },
  removeUserAccess: ({ commit }, data) => { commit('removeUserAccess', data) },
  setUserChainAccesses: ({ commit }, data) => { commit('setUserChainAccesses', data) },
  updateUserChainAccess: ({ commit }, data) => { commit('updateUserChainAccess', data) },
  addUserChainAccess: ({ commit }, data) => { commit('addUserChainAccess', data) },
  removeUserChainAccess: ({ commit }, data) => { commit('removeUserChainAccess', data) },
  setBizAccesses: ({ commit }, data) => { commit('setBizAccesses', data) },
  updateBizAccess: ({ commit }, data) => { commit('updateBizAccess', data) },
  addBizAccess: ({ commit }, data) => { commit('addBizAccess', data) },
  removeBizAccess: ({ commit }, data) => { commit('removeBizAccess', data) },
  setChainAccesses: ({ commit }, data) => { commit('setChainAccesses', data) },
  updateChainAccess: ({ commit }, data) => { commit('updateChainAccess', data) },
  addChainAccess: ({ commit }, data) => { commit('addChainAccess', data) },
  removeChainAccess: ({ commit }, data) => { commit('removeChainAccess', data) },
}

const mutations = {
  setNavState(state, data) {
    state.navState = data
  },
  setUserAccesses(state, data) {
    state.useraccess = data
  },
  updateUserAccess(state, data) {
    state.useraccess = state.useraccess.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addUserAccess(state, data) {
    state.useraccess.push(data)
  },
  removeUserAccess(state, data) {
    state.useraccess = state.useraccess.filter(o => o._id !== data._id)
  },
  setUserChainAccesses(state, data) {
    state.userChainAccess = data
  },
  updateUserChainAccess(state, data) {
    state.userChainAccess = state.userChainAccess.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addUserChainAccess(state, data) {
    state.userChainAccess.push(data)
  },
  removeUserChainAccess(state, data) {
    state.userChainAccess = state.userChainAccess.filter(o => o._id !== data._id)
  },
  setBizAccesses(state, data) {
    state.bizaccess = data
  },
  updateBizAccess(state, data) {
    state.bizaccess = state.bizaccess.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addBizAccess(state, data) {
    state.bizaccess.push(data)
  },
  removeBizAccess(state, data) {
    state.bizaccess = state.bizaccess.filter(o => o._id !== data._id)
  },
  setChainAccesses(state, data) {
    state.chainaccess = data
  },
  updateChainAccess(state, data) {
    state.chainaccess = state.chainaccess.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addChainAccess(state, data) {
    if (!state.chainaccess) {
      state.chainaccess = [data]
    } else {
      state.chainaccess.push(data)
    }
  },
  removeChainAccess(state, data) {
    state.chainaccess = state.chainaccess.filter(o => o._id !== data._id)
  },
}

export default { state, getters, actions, mutations }