<template lang="pug">
div
  simple-table(v-if="sortedItems")
    thead
      tr
        th #
        th Time
        th Subtotal
        th Gift Card
        th Tax
        th Tip
        th Discount
        th.success--text Selflane Charges
        th Total
        th.success--text Processing Fee
        th.success--text Balance
    tbody
      tr(
        v-for="(item, index) in sortedItems",
        :key="index",
        @click="show(item)",
        role="button"
      )
        th {{ item.orderNumber }}
        td {{ item.created | datetime }}
        td {{ item.payment.dish | currency }}
        td {{ item.payment.gift | currency }}
        td {{ item.payment.tax | currency }}
        td {{ item.payment.tip | currency }}
        td ({{ item.payment.deduction.total | currency }})
        td {{ (item.payment.fee + item.payment.selflane.total) | currency }}
        td {{ item.payment.total | currency }}
        td ({{ (item.payment.charge.application_fee - item.payment.fee - item.payment.selflane.total) | currency }})
        td {{ (item.payment.total - item.payment.charge.application_fee) | currency }}
    tfoot
      tr
        td Sum
        td {{ sum.count }}
        td {{ sum.dish | currency }}
        td {{ sum.gift | currency }}
        td {{ sum.tax | currency }}
        td {{ sum.tip | currency }}
        td ({{ sum.deduction | currency }})
        td {{ sum.fee | currency }}
        td {{ sum.total | currency }}
        td ({{ (sum.application_fee - sum.fee) | currency }})
        td {{ (sum.total - sum.application_fee) | currency }}
  Download.my-5(:items="sortedItems", :title="title", :sum="sum")
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Download from "./Download";

export default {
  name: "OrderTableWithDetailByOrder",
  components: { Download },
  props: {
    title: { type: String, required: true },
  },
  computed: {
    ...mapGetters(["orders"]),
    sortedItems() {
      return _.sortBy(this.orders, "created");
    },
    sum() {
      return {
        count: this.orders.length,
        dish: this.orders.reduce((acc, cur) => acc + cur.payment.dish, 0),
        discount: this.orders.reduce(
          (acc, cur) => acc + cur.payment.discount,
          0
        ),
        gift: this.orders.reduce((acc, cur) => acc + cur.payment.gift, 0),
        total: this.orders.reduce((acc, cur) => acc + cur.payment.total, 0),
        tax: this.orders.reduce((acc, cur) => acc + cur.payment.tax, 0),
        tip: this.orders.reduce((acc, cur) => acc + cur.payment.tip, 0),
        fee: this.orders.reduce(
          (acc, cur) =>
            acc + cur.payment.fee + (cur.payment.selflane?.total || 0),
          0
        ),
        deduction: this.orders.reduce(
          (acc, cur) => acc + (cur.payment.deduction?.total || 0),
          0
        ),
        application_fee: this.orders.reduce(
          (acc, cur) => acc + cur.payment.charge.application_fee,
          0
        ),
      };
    },
  },
  methods: {
    show(item) {
      if (!item?._id) return;
      EventBus.$emit("show-order-detail", item._id);
    },
  },
};
</script>
