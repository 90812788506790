<template lang="pug">
v-dialog(v-model="dialog", max-width="550", @keydown.esc="dialog = false")
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Combo Form
      v-spacer
      IconBtn(@click="addItem()", title="More Item", icon="mdi-plus")
    v-divider
    v-card-text
      v-form(@submit.prevent="submit", v-if="combo")
        v-text-field(label="Name", v-model="combo.name")
        div(v-for="(item, cindex) in combo.items", :key="cindex")
          v-subheader Item {{ cindex + 1 }}
            v-btn(icon, small, @click.stop="removeItem(cindex)")
              v-icon(color="red") mdi-close
            v-btn(icon, small, @click.stop="moveItemUp(cindex)")
              v-icon(color="grey darken-2") mdi-menu-up
            v-btn(icon, small, @click.stop="moveItemDown(cindex)")
              v-icon(color="grey darken-2") mdi-menu-down
          v-autocomplete(
            label="Applicable dishes",
            v-model="item.dishes",
            multiple,
            :items="dishes",
            small-chips,
            deletable-chips,
            dense,
            outline
          )
          v-row
            v-col(cols="6")
              v-select(
                v-model="item.type",
                :items="typeItems",
                label="Pricing Type"
              )
            v-col(cols="6")
              div(v-if="item.type == 1")
                v-text-field(
                  type="number",
                  v-model="item.amount",
                  prefix="$",
                  label="Fixed Price",
                  outline,
                  hide-details
                )
              div(v-if="item.type == 2")
                v-text-field(
                  type="number",
                  v-model="item.pct",
                  prefix="-",
                  suffix="%",
                  label="Percentage Discount",
                  outline,
                  hide-details
                )
              div(v-if="item.type == 3")
                v-text-field(
                  type="number",
                  v-model="item.reduce",
                  prefix="-$",
                  label="Amount Discount",
                  outline,
                  hide-details
                ) 
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
        .caption(v-if="combo.items.length > 3") Please try to keep combo simple. You have added {{ combo.items.length }} items.
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "ComboForm",
  data() {
    return {
      dialog: false,
      combo: null,
      errors: [],
      loading: false,
      typeItems: [
        { text: "No Change", value: 0 },
        { text: "Fixed Price", value: 1 },
        { text: "Percentage Discount", value: 2 },
        { text: "Amount Discount", value: 3 },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    courses() {
      return (
        this.biz?.courses?.map((o) => {
          return { text: o.name, value: o._id };
        }) || []
      );
    },
    dishes() {
      return (
        this.biz?.dishes?.map((o) => {
          return { text: o.name, value: o._id };
        }) || []
      );
    },
  },
  mounted() {
    EventBus.$on("edit-combo", this.edit);
  },
  destroyed() {
    EventBus.$off("edit-combo", this.edit);
  },
  methods: {
    ...mapActions(["addCombo", "updateCombo"]),
    edit(input) {
      this.combo = JSON.parse(JSON.stringify(input));
      this.errors = [];
      this.dialog = true;
    },
    removeItem(index) {
      if (index < 0 && index >= this.combo.items.length) return;
      if (index == 0 && this.combo.items.length == 1) {
        this.combo.items = [
          {
            dishes: [],
            type: 0,
            amount: 0,
            pct: 0,
            reduce: 0,
          },
        ];
      } else {
        this.combo.items.splice(index, 1);
      }
    },
    addItem() {
      this.combo.items.push({
        dishes: [],
        type: 0,
        amount: 0,
        pct: 0,
        reduce: 0,
      });
    },
    moveItemUp(index) {
      if (!this.combo || !this.combo.items) return;
      if (index < this.combo.items.length && index > 0) {
        const item = JSON.parse(JSON.stringify(this.combo.items[index]));
        this.combo.items.splice(index, 1);
        this.combo.items.splice(index - 1, 0, item);
      }
    },
    moveItemDown(index) {
      if (!this.combo || !this.combo.items) return;
      if (index < this.combo.items.length - 1 && index >= 0) {
        const item = JSON.parse(JSON.stringify(this.combo.items[index]));
        this.combo.items.splice(index, 1);
        this.combo.items.splice(index + 1, 0, item);
      }
    },
    async submit() {
      if (!this.combo) return;
      this.errors = [];
      /// validate
      if (!this.combo.biz) {
        this.errors.push("Biz is not set");
      }
      if (!this.combo.name) {
        this.errors.push("Name is required");
      }
      this.combo.items.forEach((item, index) => {
        let prefix = "Item " + (index + 1) + ": ";
        if (!item.dishes || !item.dishes.length) {
          this.errors.push(prefix + "No selected dish");
        }
        if (item.type == 1) {
          if (item.amount < 0) {
            this.errors.push(prefix + "Fixed price should be a positive value");
          }
        } else if (item.type == 2) {
          if (item.pct <= 0 || item.pct > 100) {
            this.errors.push(
              prefix + "Percentage discount should be between 0 and 100"
            );
          }
        } else if (item.type == 3) {
          if (item.reduce <= 0) {
            this.errors.push(prefix + "Invalid value for Amount Discount");
          }
        }
      });
      if (this.errors.length > 0) return;
      this.loading = true;
      try {
        if (this.combo?._id) {
          const params = {
            criteria: { _id: this.combo._id },
            action: { $set: this.combo },
          };
          const result = await this.$api.menu.combo.update(params);
          this.updateCombo(result);
        } else {
          const result = await this.$api.menu.combo.create(this.combo);
          this.addCombo(result);
        }
        this.dialog = false;
      } catch (e) {
        this.errors.push(e.response?.data || e.response);
      }
      this.loading = false;
    },
  },
};
</script>
