<template lang="pug">
.mcontent
  .mtitle Process Online Orders
  .mbody Selling online is an essential part of the business. Selflane’s <b>commission-free</b> online ordering model brings this tool to you with honor and power.
  .mbody Setting up a Stripe<sup>&#174;</sup> account with Selflane is easy and fast. With proper business information provided, your account can be ready in minutes.
  .my-5
    v-btn.pl-4.text-capitalize(
      rounded,
      depressed,
      x-large,
      color="secondary",
      to="/payments/settings"
    ) Start Now
      v-icon(right) mdi-chevron-right
  .my-5
    v-btn.pl-4.text-capitalize(
      rounded,
      depressed,
      target="_blank",
      :href="doc"
    ) Learn More
      v-icon(right) mdi-chevron-right
  .mfooter
    a(
      href="mailto:accounts@selflane.com?subject=Online%20Order%20Account%20Setup"
    ) Contact us
    span.ml-2 if you have questions
</template>

<script>
export default {
  data() {
    return {
      doc: "https://docs.selflane.com/services/payments",
    };
  },
};
</script>

<style lang="sass" scoped>
.mcontent
  text-align: center
  max-width: 600px
  margin-left: auto
  margin-right: auto

.mtitle
  font-size: 28px
  font-weight: 500
  margin-top: 50px
  margin-bottom: 50px
  color: black

.mbody
  display: block
  font-size: 18px
  margin-top: 25px
  margin-bottom: 25px

.mfooter
  display: block
  font-size: 15px
  margin-top: 25px
  margin-bottom: 25px
</style>