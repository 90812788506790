<template lang="pug">
IconBtn(@click="download()", title="PDF", icon="mdi-printer")
</template>

<script>
import moment from "moment-timezone";
import _ from "underscore";
import { mapGetters } from "vuex";
import jsPDF from "jspdf";

export default {
  props: ["grouped", "isDecimal"],
  data() {
    return {
      header: [
        "Employee",
        "ID",
        "Role",
        "Clock In",
        "Clock Out",
        "Hrs",
        "Rate",
        "Pay",
        "Cash Tip",
      ],
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    download() {
      const filters = this.$options.filters;
      const isDecimal = this.isDecimal;

      let doc = new jsPDF({ orientation: "landscape", format: "letter" });
      let x = 10;
      let y = 13;
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const ncell = 9;
      const cellWidth = (pageWidth - 20) / ncell;
      // add title
      doc.setFontSize(12);
      doc.text(this.biz.name, x, y);
      nextLine();
      if (this.biz.short_name) {
        doc.text(this.biz.short_name, x, y);
      }
      nextLine();
      doc.text(moment().format("M/D/YYYY hh:mm A"), x, y);
      nextLine(4);
      addLine(8);
      addRow(this.header);
      addLine();
      doc.setFontSize(10);

      _.each(this.grouped, (group) => {
        _.each(group.byRole, (role) => {
          _.each(role.list, (card) => {
            if (!card.status) return;
            const clockIn = this.customTime(card.clockIn);
            let clockOut;
            if (card.clockOut > 0) {
              clockOut = this.customTime(card.clockOut);
            } else {
              clockOut = "-";
            }
            addRow([
              card.serverName,
              "",
              card.role,
              clockIn,
              clockOut,
              filters.hours(card.hours, isDecimal),
              filters.currency(card.rate),
              filters.currency(card.hours * card.rate),
              filters.currency(card.cashTip * card.rate),
            ]);
          });
          nextLine(2);
          addRow([
            "",
            "",
            role.name,
            "",
            "",
            filters.hours(role.totalHrs, isDecimal),
            "",
            filters.currency(role.totalPay),
            "",
          ]);
          nextLine(2);
        });
        addRow([
          group.server.slice(0, 14),
          group.employeeID,
          "",
          "",
          "",
          filters.hours(group.totalHrs, isDecimal),
          "",
          filters.currency(group.totalPay),
          "",
        ]);
        addLine();
      });
      doc.save("timecards.pdf");

      function addRow(list) {
        if (list.length != ncell) return;
        let count = 1;
        list.forEach((item) => {
          doc.text(item, x, y);
          x += cellWidth;
          if (Array.isArray(item) && item.length > count) count = item.length;
        });
        nextLine();
      }
      function addLine(height) {
        doc.line(10, y, pageWidth - 20, y);
        nextLine(height);
      }
      function nextLine(height) {
        if (height) y += height;
        else y += 5;
        x = 10;
        if (y + 10 > pageHeight) {
          doc.addPage();
          y = 13;
        }
      }
    },
    customTime(m) {
      return moment(m).format("M/D h:mm A");
    },
  },
};
</script>
