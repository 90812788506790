<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Platform
    v-spacer
    v-btn-toggle(v-model="type", dense, color="secondary")
      v-btn(
        :value="selection",
        v-for="selection in selections",
        :key="selection",
        text,
        x-small
      ) {{ selection }}
  StandardTable(:tableData="tableData")
  v-card-text
    Chart(:items="items")
  v-card-actions
    v-spacer
    TableDownload(:tableData="tableData", title="Platform Breakdown")
</template>

<script>
import _ from "underscore";
import Chart from "./Chart";

export default {
  components: { Chart },
  props: ["statements"],
  data() {
    return {
      type: "Platform",
      selections: ["Platform", "Platform + Type"],
    };
  },
  computed: {
    hasGift() {
      return this.sum.gift != 0;
    },
    hasFee() {
      return this.sum.fee != 0;
    },
    hasAdjustment() {
      return this.sum.adjustment != 0;
    },
    hasDeduction() {
      return this.sum.deduction != 0;
    },
    hasSelflane() {
      return this.sum.selflane != 0;
    },
    items() {
      const data = _.chain(this.statements)
        .pluck("grouped_summaries")
        .flatten()
        .reject((o) => o.order_status < 0)
        .groupBy((o) => {
          if (this.type == "Platform") {
            return o.platform;
          } else {
            const type = this.$options.filters.orderType(o.order_type);
            return `${o.platform}: ${type}`;
          }
        })
        .map((o, title) => {
          const count = o.reduce((a, b) => a + b.count, 0);
          const net = o.reduce(
            (a, b) => a + b.payment.dish - b.payment.saved,
            0
          );
          const tax = o.reduce((a, b) => a + b.payment.tax, 0);
          const fee = o.reduce((a, b) => a + b.payment.fee, 0);
          const gift = o.reduce((a, b) => a + b.payment.gift, 0);
          const tip = o.reduce((a, b) => a + b.payment.tip, 0);
          const adjustment = o.reduce((a, b) => {
            const amount = b.payment.adjustment_amount || b.payment.adjustment;
            return a + amount;
          }, 0);
          const deduction = o.reduce((a, b) => {
            const amount = b.payment.deduction_amount || b.payment.deduction;
            return a + amount;
          }, 0);
          const selflane = o.reduce((a, b) => {
            const amount = b.payment.selflane_amount || b.payment.selflane;
            return a + amount;
          }, 0);
          const total = o.reduce((a, b) => a + b.payment.total, 0);
          return {
            title,
            count,
            net,
            tax,
            fee,
            gift,
            tip,
            adjustment,
            deduction,
            selflane,
            total,
          };
        })
        .sortBy("title")
        .value();
      return data;
    },
    sum() {
      return {
        count: this.items.reduce((a, b) => a + b.count, 0),
        net: this.items.reduce((a, b) => a + b.net, 0),
        tax: this.items.reduce((a, b) => a + b.tax, 0),
        gift: this.items.reduce((a, b) => a + b.gift, 0),
        fee: this.items.reduce((a, b) => a + b.fee, 0),
        tip: this.items.reduce((a, b) => a + b.tip, 0),
        adjustment: this.items.reduce((a, b) => a + b.adjustment, 0),
        deduction: this.items.reduce((a, b) => a + b.deduction, 0),
        selflane: this.items.reduce((a, b) => a + b.selflane, 0),
        total: this.items.reduce((a, b) => a + b.total, 0),
      };
    },
    tableData() {
      let header = ["Name", "Subtotal", "Tax"];
      if (this.hasGift) header.push("Gift Sold");
      if (this.hasFee) header.push("Fee");
      header.push("Tip");
      if (this.hasAdjustment) header.push("Adjustment");
      if (this.hasDeduction) header.push("Deduction");
      if (this.hasSelflane) header.push("Selflane Charges");
      header.push("Total");
      let items = this.items.map((o) => {
        const title = o.title;
        let arr = [o.net, o.tax];
        if (this.hasGift) arr.push(o.gift);
        if (this.hasFee) arr.push(o.fee);
        arr.push(o.tip);
        if (this.hasAdjustment) arr.push(o.adjustment);
        if (this.hasDeduction) arr.push(o.deduction);
        if (this.hasSelflane) arr.push(o.selflane);
        arr.push(o.total);
        arr = arr.map((o) => Math.round(o * 100) / 100);
        return { title, items: arr };
      });
      let footer_items = [this.sum.net, this.sum.tax];
      if (this.hasGift) footer_items.push(this.sum.gift);
      if (this.hasFee) footer_items.push(this.sum.fee);
      footer_items.push(this.sum.tip);
      if (this.hasAdjustment) footer_items.push(this.sum.adjustment);
      if (this.hasDeduction) footer_items.push(this.sum.deduction);
      if (this.hasSelflane) footer_items.push(this.sum.selflane);
      footer_items.push(this.sum.total);
      footer_items = footer_items.map((o) => Math.round(o * 100) / 100);
      return { header, items, footer: { title: "Total", items: footer_items } };
    },
  },
};
</script>
