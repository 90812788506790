/**
 * Sudoku is a game where you have to fill a 9x9 grid with numbers from 1 to 9.
 * The grid is divided in 9 3x3 subgrids.
 * Use 0 for empty cells.
 */

import Cell from './Cell.js';

const one_missing = 1

class Sudoku {
  constructor(numbers) {
    this.original = numbers;
    this.grid = numbers.map((row, i) => row.map((value, j) => new Cell(value, i, j)));
  }

  solve() {
    this.solved = this.isSolved();
    if (this.solved) return;
    this.steps = [];
    this.newstep = true;
    let noaction = 0
    while (!this.solved && noaction < 2) {
      const length = this.steps.length;
      this.solveAll(noaction);
      this.solved = this.isSolved();
      if (length === this.steps.length) {
        noaction++
      } else {
        noaction = 0
      }
    }
    this.solvable = this.solved
  }

  // solve all rows, columns and subgrids
  solveAll(n) {
    for (let i = 0; i < 9; i++) {
      for (let j = 0; j < 9; j++) {
        this.solvedCell(i, j, n);
      }
    }
    for (let i = 0; i < 9; i++) {
      this.solveRow(i, n);
      this.solveColumn(i, n);
      this.solveSubgrid(i, n);
    }
  }

  solvedCell(i, j, n) {
    const cell = this.grid[i][j];
    if (cell.value) return
    const numbers = this.getRowNumbers(i)
      .concat(this.getColumnNumbers(j))
      .concat(this.getSubgridNumbers(this.getSubgridIndex(i, j)));
    const missing = this.findMissing(numbers);
    cell.setPossible(missing, this.steps);
    if (cell.value) return
    if (!n) return
    this.checkPossible(cell)
    if (cell.value) return
    cell.possible.forEach(value => {
      let indicies = []
      this.getRowCells(i).forEach(cell2 => {
        if (cell2.value) return
        if (cell2.possible.includes(value)) {
          indicies.push(cell2.column)
        }
      })
      const unique = new Set(indicies.map(o => Math.floor(o / 3)))
      if (unique.size === 1) {
        this.getSubgridCells(this.getSubgridIndex(i, j)).forEach(cell2 => {
          if (cell2.value) return
          if (cell2.row == i) return
          cell2.setImpossible([value], this.steps)
        })
      }
    })
    if (cell.value) return
    cell.possible.forEach(value => {
      let indicies = []
      this.getColumnCells(j).forEach(cell2 => {
        if (cell2.value) return
        if (cell2.possible.includes(value)) {
          indicies.push(cell2.row)
        }
      })
      const unique = new Set(indicies.map(o => Math.floor(o / 3)))
      if (unique.size === 1) {
        this.getSubgridCells(this.getSubgridIndex(i, j)).forEach(cell2 => {
          if (cell2.value) return
          if (cell2.column == j) return
          cell2.setImpossible([value], this.steps)
        })
      }
    })
  }

  checkPossible(cell) {
    const row = cell.row;
    let count = 0
    this.getRowCells(row).forEach(cell2 => {
      if (cell.isSuperSet(cell2)) count++
    })
    if (count === cell.possible.length) {
      this.getRowCells(row).forEach(cell2 => {
        if (cell2.value) return
        if (!cell.isSuperSet(cell2)) cell2.setImpossible(cell.possible, this.steps)
      })
    }
    if (cell.value) return
    const column = cell.column;
    count = 0
    for (let row = 0; row < 9; row++) {
      if (cell.isSuperSet(this.grid[row][column])) count++
    }
    if (count === cell.possible.length) {
      for (let row = 0; row < 9; row++) {
        const cell2 = this.grid[row][column]
        if (cell2.value) continue
        if (!cell.isSuperSet(cell2)) {
          cell2.setImpossible(cell.possible, this.steps)
        }
      }
    }
    if (cell.value) return
    const grid = this.getSubgridIndex(row, column);
    count = 0
    let rowBegin = Math.floor(grid / 3) * 3
    let columnBegin = (grid % 3) * 3
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        const cell2 = this.grid[rowBegin + i][columnBegin + j]
        if (cell.isSuperSet(cell2)) count++
      }
    }
    if (count === cell.possible.length) {
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
          const cell2 = this.grid[rowBegin + i][columnBegin + j]
          if (cell2.value) continue
          if (!cell.isSuperSet(cell2)) {
            cell2.setImpossible(cell.possible, this.steps)
          }
        }
      }
    }
    if (cell.value) return
    this.checkXYZStrategy(cell)
  }

  // XYZ-Wing Strategy is a strategy that uses three cells in a row, column or subgrid
  // where two of the cells have the same two possible values and the third cell has
  // one of those two values.  The third cell can be eliminated as a possible value.
  checkXYZStrategy(cell) {
    if (cell.value) return
    if (cell.possible.length !== 3) return
    const row = cell.row;
    const column = cell.column;
    const grid = this.getSubgridIndex(row, column);
    const gridCells = this.getSubgridCells(grid);
    const found = gridCells.find(cell2 => {
      if (cell2.value) return false
      if (cell2.possible.length !== 2) return false
      return cell.isSuperSet(cell2)
    })
    if (!found) return
    // console.log('cell', cell.row, cell.column, cell.possible.join(','))
    // console.log('found', found.row, found.column, found.possible.join(','))
    let found2
    if (found.row == cell.row) {
      const cells = this.getColumnCells(cell.column)
      found2 = cells.find(cell2 => {
        if (cell2.value) return false
        if (cell2.possible.length !== 2) return false
        const grid2 = this.getSubgridIndex(cell2.row, cell2.column);
        if (grid2 === grid) return false
        return cell.isSuperSet(cell2) && cell.possible.join('') != found.possible.join('')
      })
    } else if (found.column == cell.column) {
      const cells = this.getRowCells(cell.row)
      found2 = cells.find(cell2 => {
        if (cell2.value) return false
        if (cell2.possible.length !== 2) return false
        const grid2 = this.getSubgridIndex(cell2.row, cell2.column);
        if (grid2 === grid) return false
        return cell.isSuperSet(cell2) && cell.possible.join('') != found.possible.join('')
      })
    }
    if (!found2) return
    const shared = found.possible.find(o => found2.possible.includes(o))
    if (!shared) return
    if (found2.row == cell.row) {
      const cells = this.getRowCells(cell.row)
      const found3 = cells.find(cell2 => {
        if (cell2.value) return false
        if (cell2.possible.length !== 2) return false
        const grid2 = this.getSubgridIndex(cell2.row, cell2.column);
        if (grid2 !== grid) return false
        if (cell2.row == cell.row && cell2.column == cell.column) return false
        return cell2.possible.includes(shared)
      })
      found3?.setImpossible([shared], this.steps)
    } else if (found2.column == cell.column) {
      const cells = this.getColumnCells(cell.column)
      const found3 = cells.find(cell2 => {
        if (cell2.value) return false
        if (cell2.possible.length !== 2) return false
        const grid2 = this.getSubgridIndex(cell2.row, cell2.column);
        if (grid2 !== grid) return false
        if (cell2.row == cell.row && cell2.column == cell.column) return false
        return cell2.possible.includes(shared)
      })
      found3?.setImpossible([shared], this.steps)
    }
  }

  // solve row by row
  solveRow(row, n) {
    const numbers = this.getRowNumbers(row);
    const missing = this.findMissing(numbers);
    if (missing.length === 0) return;
    // if there is only one missing number, fill it
    if (missing.length === 1) {
      const column = numbers.indexOf(0);
      if (column < 0) return
      this.setValue(row, column, missing[0], one_missing, missing);
      return
    }
    if (!missing.length) return;
    missing.forEach(number => {
      let count = 0
      numbers.forEach((value, column) => {
        if (value === 0 && this.isAllowed(row, column, number)) count++;
      })
      if (count === 1) {
        const found = numbers.findIndex((value, column) => value === 0 && this.isAllowed(row, column, number))
        this.setValue(row, found, number, one_missing, missing);
      }
    })
    numbers.forEach((number, column) => {
      if (number === 0) {
        let possible = [];
        missing.forEach(value => {
          if (this.isAllowed(row, column, value)) possible.push(value);
        })
        this.setPossible(row, column, possible);
      }
    })
    if (!n) return
    // reduce ambiguity
    for (var i = 0; i < 9; i++) {
      if (numbers[i] == 0) {
        const possible = this.grid[row][i].possible;
        const count = possible.length;
        let indicies = [];
        for (var j = i + 1; j < 9; j++) {
          if (numbers[j] == 0 && this.grid[row][j].possible.join('') === possible.join('')) indicies.push(j);
        }
        if (indicies.length === count - 1) {
          for (var k = 0; k < 9; k++) {
            if (numbers[k] == 0 && this.grid[row][k].possible.join('') !== possible.join('')) {
              this.setImpossible(row, k, possible);
            }
          }
        }
      }
    }
    missing.forEach(number => {
      let indicies = [];
      numbers.forEach((value, column) => {
        if (value === 0 && this.isAllowed(row, column, number)) indicies.push(column);
      })
      // if all possible numbers are in the same subgrid, remove them from other subgrids
      const unique = new Set(indicies.map(index => Math.floor(index / 3)));
      if (unique.size === 1) {
        const subgrid = this.getSubgridIndex(row, indicies[0]);
        for (var i = 0; i < 9; i++) {
          const result = this.getRowColumn(subgrid, i);
          const r = result.row;
          const c = result.column;
          if (r !== row && numbers[c] == 0) {
            this.setImpossible(r, c, [number]);
          }
        }
      }
    })
  }

  // solve column by column
  solveColumn(column, n) {
    const numbers = this.getColumnNumbers(column);
    const missing = this.findMissing(numbers);
    if (missing.length === 0) return;
    // if there is only one missing number, fill it
    if (missing.length === 1) {
      const row = numbers.indexOf(0);
      if (row < 0) return
      this.setValue(row, column, missing[0], one_missing, missing);
      return
    }
    if (!missing.length) return;
    missing.forEach(number => {
      let count = 0
      numbers.forEach((value, row) => {
        if (value === 0 && this.isAllowed(row, column, number)) count++;
      })
      if (count === 1) {
        const found = numbers.findIndex((value, row) => value === 0 && this.isAllowed(row, column, number))
        this.setValue(found, column, number, one_missing, missing);
      }
    })
    numbers.forEach((number, row) => {
      if (number === 0) {
        let possible = [];
        missing.forEach(value => {
          if (this.isAllowed(row, column, value)) possible.push(value);
        })
        this.setPossible(row, column, possible);
      }
    })
    if (!n) return
    // reduce ambiguity
    for (var i = 0; i < 9; i++) {
      if (numbers[i] == 0) {
        const possible = this.grid[i][column].possible;
        const count = possible.length;
        let indicies = [];
        for (var j = i + 1; j < 9; j++) {
          if (numbers[j] == 0 && this.grid[j][column].possible.join('') === possible.join('')) indicies.push(j);
        }
        if (indicies.length === count - 1) {
          for (var k = 0; k < 9; k++) {
            if (numbers[k] == 0 && this.grid[k][column].possible.join('') !== possible.join('')) {
              this.setImpossible(k, column, possible);
            }
          }
        }
      }
    }
    missing.forEach(number => {
      let indicies = [];
      numbers.forEach((value, row) => {
        if (value === 0 && this.isAllowed(row, column, number)) indicies.push(row);
      })
      // if all possible numbers are in the same subgrid, remove them from other subgrids
      const unique = new Set(indicies.map(index => Math.floor(index / 3)));
      if (unique.size === 1) {
        const subgrid = this.getSubgridIndex(indicies[0], column);
        for (var i = 0; i < 9; i++) {
          const result = this.getRowColumn(subgrid, i);
          const r = result.row;
          const c = result.column;
          if (c !== column && numbers[r] == 0) {
            this.setImpossible(r, c, [number]);
          }
        }
      }
    })
  }

  // solve subgrid by subgrid
  solveSubgrid(index, n) {
    const numbers = this.getSubgridNumbers(index);
    const missing = this.findMissing(numbers);
    if (missing.length === 0) return;
    // if there is only one missing number, fill it
    if (missing.length === 1) {
      const index2 = numbers.indexOf(0);
      if (index2 < 0) return;
      const { row, column } = this.getRowColumn(index, index2);
      this.setValue(row, column, missing[0], one_missing, missing);
      return
    }
    if (!missing.length) return;
    missing.forEach(number => {
      let count = 0
      numbers.forEach((value, index2) => {
        if (value === 0) {
          const row = Math.floor(index2 / 3) + Math.floor(index / 3) * 3;
          const column = (index2 % 3) + index % 3 * 3;
          if (this.isAllowed(row, column, number)) count++;
        }
      })
      if (count === 1) {
        numbers.forEach((value, index2) => {
          if (value === 0) {
            const row = Math.floor(index2 / 3) + Math.floor(index / 3) * 3;
            const column = (index2 % 3) + index % 3 * 3;
            if (this.isAllowed(row, column, number)) {
              this.setValue(row, column, number, one_missing, missing);
            }
          }
        })
      }
    })
    numbers.forEach((number, index2) => {
      if (number === 0) {
        const row = Math.floor(index2 / 3) + Math.floor(index / 3) * 3;
        const column = (index2 % 3) + index % 3 * 3;
        let possible = [];
        missing.forEach(value => {
          if (this.isAllowed(row, column, value)) possible.push(value);
        })
        this.setPossible(row, column, possible);
      }
    })
    if (!n) return
    // reduce ambiguity
    for (var i = 0; i < 9; i++) {
      if (numbers[i] == 0) {
        const possible = this.grid[Math.floor(i / 3) + Math.floor(index / 3) * 3][(i % 3) + index % 3 * 3].possible;
        const count = possible.length;
        let indicies = [];
        for (var j = i + 1; j < 9; j++) {
          if (numbers[j] == 0 && this.grid[Math.floor(j / 3) + Math.floor(index / 3) * 3][(j % 3) + index % 3 * 3].possible.join('') === possible.join('')) indicies.push(j);
        }
        if (indicies.length === count - 1) {
          for (var k = 0; k < 9; k++) {
            if (numbers[k] == 0 && this.grid[Math.floor(k / 3) + Math.floor(index / 3) * 3][(k % 3) + index % 3 * 3].possible.join('') !== possible.join('')) {
              this.setImpossible(Math.floor(k / 3) + Math.floor(index / 3) * 3, (k % 3) + index % 3 * 3, possible);
            }
          }
        }
      }
    }
  }

  // add value to the grid and to the steps
  setValue(i, j, value, difficulty, missing) {
    this.grid[i][j].setValue(value, this.steps, difficulty, missing);
  }
  // set possible values to the grid
  setPossible(i, j, possible) {
    this.grid[i][j].setPossible(possible, this.steps);
  }
  setImpossible(i, j, impossible) {
    this.grid[i][j].setImpossible(impossible, this.steps);
  }

  // find the missing numbers in 9 cells
  findMissing(numbers) {
    const missing = [];
    for (let i = 1; i <= 9; i++) {
      if (!numbers.includes(i)) missing.push(i);
    }
    return missing;
  }

  // reduce missing with other two lists
  reduceMissing(missing, list1, list2) {
    return missing.filter(number => !list1.includes(number) && !list2.includes(number));
  }

  getStatus() {
    const numbers = this.original.flat();
    const unique = new Set(numbers);
    if (unique.size === 1) return 'new';
    if (this.solved) return 'solved';
    return 'unsolved'
  }
  getScore() {
    return this.steps.reduce((acc, step) => acc + step.difficulty, 0);
  }

  // get solvable
  getSolvable() {
    this.solve()
    return this.solvable
  }

  // check if the grid is solved
  // rows and columns must contain all numbers from 1 to 9
  // subgrids must contain all numbers from 1 to 9
  isSolved() {
    for (let i = 0; i < 9; i++) {
      if (!this.is9Unique(this.getRowNumbers(i))) return false;
      if (!this.is9Unique(this.getColumnNumbers(i))) return false;
      if (!this.is9Unique(this.getSubgridNumbers(i))) return false;
    }
    return true;
  }
  // check if the array contains all numbers from 1 to 9
  is9Unique(numbers) {
    if (numbers.includes(0)) return false;
    const unique = new Set(numbers);
    return unique.size === 9;
  }

  // get subgrid index with row and column
  getSubgridIndex(row, column) {
    return Math.floor(row / 3) * 3 + Math.floor(column / 3);
  }
  getRowColumn(subgrid, index) {
    const row = Math.floor(subgrid / 3) * 3 + Math.floor(index / 3);
    const column = (subgrid % 3) * 3 + (index % 3);
    if (row < 0 || row > 8 || column < 0 || column > 8) console.log('error', subgrid, index, row, column)
    return { row, column };
  }
  // get the subgrid at index as a 3x3 array
  getSubgrid(index) {
    const row = Math.floor(index / 3) * 3;
    const column = (index % 3) * 3;
    const subgrid = [];
    for (let i = row; i < row + 3; i++) {
      let row = [];
      for (let j = column; j < column + 3; j++) {
        row.push(this.grid[i][j]);
      }
      subgrid.push(row);
    }
    return subgrid;
  }
  // get subgrids as 3x3 arrays
  getSubgrids() {
    const subgrids = [];
    for (let i = 0; i < 3; i++) {
      const row = [];
      for (let j = 0; j < 3; j++) {
        row.push(this.getSubgrid(i * 3 + j));
      }
      subgrids.push(row);
    }
    return subgrids;
  }
  getRowNumbers(row) {
    return this.grid[row].map(cell => cell.value);
  }
  getColumnNumbers(column) {
    return this.grid.map(row => row[column]).map(cell => cell.value);
  }
  getSubgridNumbers(index) {
    return this.getSubgrid(index).map(row => row.map(cell => cell.value)).flat();
  }
  getRowCells(row) {
    return this.grid[row];
  }
  getColumnCells(column) {
    return this.grid.map(row => row[column]);
  }
  getSubgridCells(index) {
    return this.getSubgrid(index).flat();
  }

  // is allowed in row, column and subgrid
  isAllowed(row, column, number) {
    return this.isAllowedRow(row, number) && this.isAllowedColumn(column, number) && this.isAllowedSubgrid(this.getSubgridIndex(row, column), number);
  }
  // check if a number is allowed in a row
  isAllowedRow(row, number) {
    return !this.getRowNumbers(row).includes(number);
  }
  // check if a number is allowed in a column
  isAllowedColumn(column, number) {
    return !this.getColumnNumbers(column).includes(number);
  }
  // check if a number is allowed in a subgrid
  isAllowedSubgrid(index, number) {
    return !this.getSubgridNumbers(index).includes(number);
  }
}

export default Sudoku;