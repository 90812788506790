<template lang="pug">
simple-table
  thead
    tr
      th Time
      th Action
      th Amount
      th Authorizer
      th To
      th Note
  tbody
    tr(v-for="(item, index) in result", :key="index")
      td {{ item.created | datetime("MMM D, h:mm A") }}
      td.text-capitalized {{ item.action }}
      td {{ item.amount | currency }}
      td {{ item.staff.name }}
      td {{ item.associate.name }}
      td {{ item.note }}
</template>

<script>
export default {
  props: ["result"],
};
</script>
