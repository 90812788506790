<template lang="pug">
.table-box
  simple-table(v-if="report")
    thead
      tr
        th Period
        th #Sales
        th Subtotal
        th Gift Card
        th Tax
        th Tip
        th Discount
        th Other
        th.green--text Selflane Charges
        th Total
        th.green--text Processing Fee
        th.green--text Balance
    tbody
      tr(
        v-for="(item, index) in report",
        :key="index",
        @click="show(item)",
        role="button"
      )
        th {{ time(item) }}
        td {{ item.count }}
        td {{ item.dish | currency }}
        td {{ item.gift | currency }}
        td {{ item.tax | currency }}
        td {{ item.tip | currency }}
        td ({{ item.deduction | currency }})
        td {{ item.fee | currency }}
        td {{ item.selflane | currency }}
        td {{ item.total | currency }}
        td ({{ (item.application_fee - item.selflane) | currency }})
        td {{ (item.total - item.application_fee) | currency }}
    tfoot
      tr
        th {{ sum.period }}
        th {{ sum.count }}
        th {{ sum.dish | currency }}
        th {{ sum.gift | currency }}
        th {{ sum.tax | currency }}
        th {{ sum.tip | currency }}
        th ({{ sum.deduction | currency }})
        th {{ sum.fee | currency }}
        th {{ sum.selflane | currency }}
        th {{ sum.total | currency }}
        th ({{ (sum.application_fee - sum.selflane) | currency }})
        th {{ (sum.total - sum.application_fee) | currency }}
</template>

<script>
import moment from "moment-timezone";
import { EventBus } from "@/event-bus.js";

export default {
  props: ["report", "sum", "year"],
  methods: {
    time(item) {
      if (item.month) {
        return this.$options.filters.month(item.month);
      } else {
        const begin = moment()
          .year(this.year)
          .week(item.week + 1)
          .startOf("week")
          .format("MMM D");
        const end = moment()
          .year(this.year)
          .week(item.week + 1)
          .endOf("week")
          .format("MMM D");
        return begin + " - " + end;
      }
    },
    show(item) {
      let range;
      if (item.month) {
        const time = moment()
          .year(item.year)
          .month(item.month - 1);
        range = {
          begin: time.startOf("month").unix() * 1000,
          end: time.endOf("month").unix() * 1000,
        };
      } else {
        const time = moment()
          .year(this.year)
          .week(item.week + 1);
        range = {
          begin: time.startOf("week").unix() * 1000,
          end: time.endOf("week").unix() * 1000,
        };
      }
      EventBus.$emit("show-online-ordes-with-detail", range);
    },
  },
};
</script>

<style lang="sass" scoped>
.table-box
  max-height: 300px
  overflow: scroll
</style>
