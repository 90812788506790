<template lang="pug">
div
  v-card(outlined)
    v-toolbar(flat, dense)
      .subtitle-2 Weekly Specials
      v-spacer
      CreateBtn
    Table
  Form
</template>

<script>
import CreateBtn from "./CreateBtn";
import Table from "./Table/index";
import Form from "./Form/index";

export default {
  components: { CreateBtn, Table, Form },
};
</script>