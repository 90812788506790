<template lang="pug">
div
  Stripe
  Tax.my-10
</template>

<script>
import Stripe from "./Stripe/index";
import Tax from "./Tax/index";

export default {
  components: { Stripe, Tax },
};
</script>
