<template lang="pug">
.d-flex.flex-row.justify-center
  v-btn-toggle(v-model="content", color="secondary", rounded)
    v-btn(v-for="(item, index) in items", :key="index", :value="item.value")
      .text-center.text-notransform
        div {{ item.text }}
        div {{ item.count }}
</template>

<script>
export default {
  props: ["value", "items"],
  data() {
    return { content: this.value };
  },
  watch: {
    value() {
      this.content = this.value;
    },
    content(newval, oldval) {
      if (newval == oldval) return;
      this.$emit("input", this.content);
    },
  },
};
</script>
