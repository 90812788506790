<template lang="pug">
Page(title="Devices")
  Devices(ref="devices")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Devices from "/libs/components/POS/Devices";

export default {
  components: { Devices },
  data() {
    return { loading: false };
  },
  mounted() {
    this.$refs.devices.load();
    EventBus.$on("switch-biz", this.$refs.devices.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.$refs.devices.load);
  },
};
</script>