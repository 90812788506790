<template lang="pug">
.s-vstack
  PersonCell(
    v-for="(server, sindex) in items",
    :key="sindex",
    :server="server",
    :index="sindex",
    :isDecimal="isDecimal",
    :showWage="showWage"
  )
  BottomActions(
    :items="items",
    :isDecimal="isDecimal",
    :showWage="showWage",
    :range="range"
  )
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import BottomActions from "./BottomActions/index";
import PersonCell from "./PersonCell";

export default {
  name: "ServerReport",
  components: { BottomActions, PersonCell },
  props: ["isDecimal", "showWage", "range", "report"],
  computed: {
    ...mapGetters("hr", ["persons"]),
    items() {
      return _.chain(this.report)
        .each((o) => {
          const found = this.persons?.find((s) => s._id == o.serverId);
          if (found) {
            o.employeeID = found.employeeID;
            o.name = found.fullname;
          }
        })
        .sortBy("name")
        .value();
    },
  },
};
</script>
