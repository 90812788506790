<template lang="pug">
.items-container
  DishCell(
    v-for="(dish, index) in order.dishes",
    :key="index",
    :dish="dish",
    :biz="biz",
    divider,
    prints
  )
  div(v-for="(item, index) in order.gifts", :key="index + 'gift'")
    .item
      v-flex
        .item-name Gift Card
      div {{ item.amount | currency }}
    .sl-dash-divider
  div(v-for="(item, index) in order.vouchers", :key="index + 'voucher'")
    .item
      v-flex
        .item-name {{ item.name }}
      .red--text {{ -item.amount | currency }}
    .sl-dash-divider
</template>

<script>
import { mapGetters } from "vuex";

export default {
  filters: {
    printIcon(value) {
      const icons = [
        { icon: "mdi-silverware-fork-knife", value: 0 },
        { icon: "mdi-silverware-fork-knife", value: 2 },
        { icon: "mdi-silverware-fork-knife", value: 3 },
        { icon: "mdi-glass-wine", value: 1 },
        { icon: "mdi-sticker", value: 4 },
      ];
      return icons.find((o) => o.value == value)?.icon || "";
    },
  },
  props: ["order"],
  computed: { ...mapGetters(["biz"]) },
  methods: {
    groupName(value) {
      if (!value) return null;
      return this.biz.printingGroups.find((o) => o._id == value)?.name;
    },
  },
};
</script>

<style lang="sass" scoped>
.items-container
  padding-left: 30px
  padding-right: 30px

.item
  display: flex
  align-items: center
  margin-top: 4px
  margin-bottom: 4px

  &-name
    font-size: 17px
    font-weight: 500

  &-note
    font-size: 15px
    color: green

  &-detail
    font-size: 13px
</style>