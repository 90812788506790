<template lang="pug">
StandardChart(
  :input="chartData",
  maxWidth="100%",
  chartType="bar",
  :loading="loading",
  showDownload,
  v-if="items"
)
</template>

<script>
import moment from "moment-timezone";

export default {
  props: ["items", "loading"],
  computed: {
    chartData() {
      if (!this.items?.length) return;
      const month = moment(this.items[0].date).format("YYYY-MM");
      const title = `Guests Traffic in ${moment(this.items[0].date).format(
        "MMMM, YYYY"
      )}`;
      const monthToDates = this.$options.filters.monthToDates;
      const dates = monthToDates(month);
      const labels = dates.map((o) => moment(o).format("D"));
      const values = dates.map((o) => {
        const item = this.items.find((p) => p.date == o);
        return item?.nguests || 0;
      });
      values.forEach((o) => (o = Math.round(o * 100) / 100));
      return { title, labels, values };
    },
  },
};
</script>