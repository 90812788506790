<template lang="pug">
.table-box
  simple-table(v-if="items")
    thead
      tr
        th Created
        th Reason
        th.text-right Amount
        th 
    tbody
      tr(v-for="(item, index) in items", :key="index")
        td {{ item.created | date }}
        td {{ item.transferReason }}
          Notes(:transfer="item")
        td.text-right {{ (item.amount / 100) | currency }}
        td.text-right
          v-btn.text-capitalize(
            v-if="item.order",
            small,
            text,
            @click="showOrder(item.order)"
          ) Show Order
    tfoot
      tr
        th Total
        th
        th.text-right {{ total | currency }}
        th
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Notes from "./Notes";

export default {
  components: { Notes },
  props: {
    items: { type: Array, required: true },
    total: { type: Number, required: true },
  },
  methods: {
    showOrder(id) {
      EventBus.$emit("show-order-detail", id);
    },
  },
};
</script>

<style lang="sass" scoped>
.table-box
  max-height: 300px
  overflow: scroll
</style>
