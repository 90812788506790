<template lang="pug">
div
  v-card(outlined, :loading="loading")
    v-toolbar(dense, flat)
      .subtitle-2.mr-3 Coupons
      v-btn-toggle(
        dense,
        rounded,
        color="secondary",
        v-model="selection",
        small
      )
        v-btn(:value="true", small) Active ({{ groups.active.length }})
        v-btn(:value="false", small) Expired ({{ groups.expired.length }})
      v-spacer
      Create
    Table(:promotions="selected")
  CouponReport
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Table from "./Table/index";
import CouponReport from "./CouponReport";
import Create from "./Create";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Table, CouponReport, Create },
  data() {
    return {
      loading: false,
      chain: null,
      selection: true,
    };
  },
  computed: {
    ...mapGetters(["biz", "promotions"]),
    groups() {
      let active = [];
      let expired = [];
      const now = Date.now();
      this.promotions.forEach((o) => {
        if (o.expiry > now) active.push(o);
        else expired.push(o);
      });
      active = active.sort((a, b) => b.expiry - a.expiry);
      expired = expired.sort((a, b) => b.expiry - a.expiry);
      return { active, expired };
    },
    selected() {
      return this.selection ? this.groups.active : this.groups.expired;
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setPromotions"]),
    async load() {
      this.setPromotions([]);
      if (!this.biz) return;
      this.loading = true;
      this.chain = null;
      const params = { criteria: { bizs: this.biz._id }, select: "name" };
      try {
        this.chain = await this.$api.chain.account.retrieve(params);
        await this.loadPromotions();
      } catch (e) {
        //
      }
      this.loading = false;
    },
    async loadPromotions() {
      if (!this.biz) return;
      let criteria = { biz: this.biz._id };
      if (this.chain) {
        criteria = { $or: [{ biz: this.biz._id }, { chain: this.chain._id }] };
      }
      const result = await this.$api.promotion.list({ criteria });
      this.setPromotions(result);
    },
  },
};
</script>
