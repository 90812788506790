<template lang="pug">
v-card(v-if="combo", flat)
  v-toolbar(flat, dense)
    .subtitle-2 {{ combo.name }}
    v-spacer
    v-btn(icon, small, @click="edit")
      v-icon(color="secondary", small) mdi-pencil
    v-btn(icon, small, @click="handleRemove", :loading="loading")
      v-icon(small, color="red") mdi-delete
  simple-table
    tbody(v-for="(item, index) in combo.items", :key="index")
      tr
        th Item {{ index + 1 }}
        td.text-right(
          style="white-space: normal",
          v-html="$options.filters.dishNames(item.dishes, biz)"
        )
      tr
        td Price
        td.text-right
          span(v-if="item.type == 0") No change
          span(v-if="item.type == 1") {{ item.amount | currency }} Fixed
          span(v-if="item.type == 2") {{ item.pct }}% Off
          span(v-if="item.type == 3") {{ item.reduce | currency }} Off
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "ComboCard",
  props: { combo: { type: Object, required: true } },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    ...mapActions(["removeCombo"]),
    handleRemove() {
      const message = "Do you want to remove this combo?";
      confirm(message) && this.remove();
    },
    async remove() {
      if (!this.combo || !this.combo._id) return;
      this.loading = true;
      try {
        const result = await this.$api.menu.combo.delete(this.combo._id);
        this.removeCombo(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    edit() {
      EventBus.$emit("edit-combo", this.combo);
    },
  },
};
</script>
