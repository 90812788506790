<template lang="pug">
div(v-if="report")
  v-card.mb-3(flat)
    simple-table
      tbody
        tr
          th # Orders
          td.text-right {{ report.order_count }}
        tr
          th Subtotal
          td.text-right {{ report.subtotal | currency }}
        tr
          th Tax
          td.text-right {{ report.tax | currency }}
      tfoot
        tr
          th Total
          td.text-right {{ report.total | currency }}
  v-card.mb-3(flat)
    Charges(:charges="report.charges")
  v-card.mb-3(flat)
    simple-table
      tbody
        tr(v-for="(item, index) in report.ticketReport", :key="index")
          td {{ getItemName(item.item_id) }}
          td.text-right {{ item.count }}
</template>

<script>
import { mapGetters } from "vuex";
import Charges from "./Charges";

export default {
  components: { Charges },
  props: ["report", "eventId"],
  computed: {
    ...mapGetters(["events"]),
  },
  methods: {
    getItemName(id) {
      const event = this.events.find((o) => o._id == this.eventId);
      if (event) {
        const item = event.items.find((o) => o._id == id);
        if (item) return item.name;
      }
      return id;
    },
  },
};
</script>