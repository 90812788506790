import Main from './index.vue'
import Sales from './Home/index.vue'
import Monthly from './MonthlyReport/index.vue'
import Daily from './DailyReport/index.vue'
import Orders from './Orders/index.vue'
import Actions from './DailyAction/index.vue'
import GRT from './GRT/index.vue'
import Stats from './Stats/index.vue'
import Returns from './Returns/index.vue'
import PayInOut from './PayInOut/index.vue'

export default [{
  path: '/sales', component: Main, meta: { auth: true },
  children: [
    { path: '', redirect: 'home' },
    { path: 'home', component: Sales },
    { path: 'daily', component: Daily },
    { path: 'monthly', component: Monthly },
    { path: 'actions', component: Actions },
    { path: 'orders', component: Orders },
    { path: 'grt', component: GRT },
    { path: 'returns', component: Returns },
    { path: 'payinout', component: PayInOut },
    { path: 'stats', component: Stats }
  ]
}]