<template lang="pug">
v-list(dense)
  v-list-item(
    v-for="(item, index) in items",
    :key="index",
    @click="select(item)"
  )
    v-list-item-content
      v-list-item-title.text-truncate {{ item.name }}
      v-list-item-subtitle {{ item.organization_name }}
</template>

<script>
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  computed: {
    ...mapGetters(["salesPersons", "salesOrganizations"]),
    items() {
      const salesOrganizations = this.salesOrganizations;
      return _.chain(this.salesPersons)
        .map((o) => {
          let organization;
          if (o.organization) {
            organization = salesOrganizations.find(
              (o) => o._id == o.organization
            );
          }
          return {
            _id: o._id,
            name: o.name,
            organization_name: organization?.name || "",
          };
        })
        .sortBy("organization_name")
        .value();
    },
  },
  methods: {
    ...mapActions(["setSalesPerson", "setNavState"]),
    select(item) {
      const person = this.salesPersons.find((o) => o._id == item._id);
      if (!person) return;
      this.setSalesPerson(person);
      this.setNavState("partner");
      EventBus.$emit("switch-partner");
      this.$emit("selected");

      const path = this.$router.history.current.path;
      if (!path.startsWith("/partner")) this.$router.push("/partner");
    },
  },
};
</script>
