<template lang="pug">
v-dialog(v-model="dialog", scrollable)
  v-card(v-if="bizmodifier && bizmodifier.modifier")
    v-toolbar(dense, flat)
      .subtitle-2 {{ title }}
    v-card-text
      v-form(@submit.prevent="submit")
        v-row
          v-col(cols="3")
            v-text-field(
              label="Title",
              v-model="bizmodifier.modifier.name",
              autofocus,
              ref="focus"
            )
          v-col(cols="2")
            v-select(
              :items="items",
              v-model="bizmodifier.modifier.maxSelection",
              label="Max Selection",
              hide-details
            )
          v-col
            v-checkbox(
              label="Required",
              v-model="bizmodifier.modifier.required",
              color="secondary"
            )
        v-menu(offset-y, max-height="300")
          template(v-slot:activator="{ on }")
            v-btn.text-capitalize.mb-5(
              text,
              v-on="on",
              outlined,
              color="secondary",
              small
            ) Quick Apply to A Course
              v-icon(right) mdi-menu-down
          v-list(dense)
            v-list-item(
              v-for="(item, cindex) in biz.courses",
              :key="cindex",
              @click="applyToCourse(item)"
            )
              v-list-item-content
                v-list-item-title {{ item.name }}
        v-autocomplete(
          :items="dishes",
          v-model="bizmodifier.dishes",
          multiple,
          label="Apply to",
          small-chips,
          deletable-chips,
          hide-selected,
          hide-details
        )
        .subtitle-2.my-3 Choices
        v-text-field(
          dense,
          color="secondary",
          v-model="newChoiceNames",
          hint="Add choice names separated by a comma",
          placeholder="Option A, Option B, Option C",
          @keydown.enter.prevent="addChoices()",
          @blur="addChoices()",
          append-icon="chevron_right",
          @click:append="addChoices",
          solo
        )
        simple-table
          thead
            tr
              th
                span Name
                v-btn(
                  icon,
                  title="sort choice alphabetically",
                  @click="sortChoices()"
                )
                  v-icon mdi-sort-alphabetical-descending
              th Kit Name
              th(style="width: 90px") Ext Price
              th(
                v-if="bizmodifier.modifier.associatedChoices.length > 0",
                style="width: 100px"
              ) Max Sub-Choices
              th(
                v-if="bizmodifier.modifier.associatedChoices.length > 0",
                style="width: 100px"
              ) Free Sub-Choices
              th(
                v-if="bizmodifier.modifier.associatedChoices.length > 0",
                style="width: 90px"
              ) Tier 1
              th(
                v-if="bizmodifier.modifier.associatedChoices.length > 0",
                style="width: 90px"
              ) Tier 2
              th Default
              th Maximum
              th(v-if="bizmodifier.modifier.associatedChoices.length > 0") Hide Sub-Choices
              th
          tbody
            tr(
              v-for="(item, index) in bizmodifier.modifier.choices",
              :key="index",
              draggable="true",
              @dragstart="drag(index, $event)",
              @dragend="dragend($event)",
              @drop="drop(index, $event)",
              @dragover.prevent=""
            )
              td
                v-text-field(
                  dense,
                  v-model.trim="item.name",
                  hide-details,
                  single-line
                )
              td
                v-text-field(
                  dense,
                  v-model.trim="item.kName",
                  hide-details,
                  single-line
                )
              td
                v-text-field(
                  dense,
                  type="number",
                  v-model="item.price",
                  hide-details,
                  single-line,
                  prefix="$"
                )
              td(v-if="bizmodifier.modifier.associatedChoices.length > 0")
                v-select(
                  v-model="item.associatedChoiceMax",
                  hide-details,
                  single-line,
                  :items="items",
                  dense
                )
              td(v-if="bizmodifier.modifier.associatedChoices.length > 0")
                v-select(
                  v-model="item.freeQuantity",
                  hide-details,
                  single-line,
                  :items="freeItems",
                  dense
                )
              td(v-if="bizmodifier.modifier.associatedChoices.length > 0")
                v-text-field(
                  dense,
                  type="number",
                  v-model="item.associatedChoiceUnitPrice",
                  hide-details,
                  single-line,
                  prefix="$"
                )
              td(v-if="bizmodifier.modifier.associatedChoices.length > 0")
                v-text-field(
                  dense,
                  type="number",
                  v-model="item.associatedChoiceBoostedPrice",
                  hide-details,
                  single-line,
                  prefix="$"
                )
              td
                v-checkbox(
                  dense,
                  v-model="item.selected",
                  hide-details,
                  color="secondary"
                )
              td
                v-select(
                  dense,
                  v-model.number="item.maximum",
                  :items="max_items",
                  hide-details,
                  style="width: 60px"
                )
              td(v-if="bizmodifier.modifier.associatedChoices.length > 0")
                v-checkbox(
                  dense,
                  v-model="item.hsc",
                  color="error",
                  hide-details,
                  on-icon="close"
                )
              td
                .d-flex.flex-row.align-end.justify-end
                  v-btn(
                    icon,
                    small,
                    color="error",
                    @click.stop="removeChoice(index)"
                  )
                    v-icon(small) mdi-delete
                  v-btn(
                    icon,
                    small,
                    @click.stop="item.ol = !item.ol",
                    title="online",
                    :color="item.ol == false ? '' : 'success'"
                  )
                    v-icon(small) mdi-earth
                  v-btn(small, icon)
                    v-icon(small) mdi-menu
        v-switch(
          color="secondary",
          dense,
          v-model="hasSub",
          label="Sub Choices",
          persistent-hint,
          hint="Use subchoices for nested choices such as pizza toppings, combo choices, etc."
        )
        div(v-if="hasSub")
          .d-flex.flex-row
            v-switch.mr-5(
              color="secondary",
              dense,
              v-model="bizmodifier.modifier.associatedChoiceAllowQuantity",
              label="Allow Extra Quantity"
            )
            v-switch(
              color="secondary",
              dense,
              v-model="bizmodifier.modifier.associatedChoiceAllowHalfhalf",
              label="Allow Half/Half"
            )
          v-text-field(
            dense,
            v-model="newSubChoiceNames",
            hint="Add subchoice names separated by a comma",
            placeholder="Option A, Option B, Option C",
            @keydown.enter.prevent="addSubChoices()",
            @blur="addSubChoices()",
            append-icon="chevron_right",
            @click:append="addSubChoices",
            solo
          )
          v-row
            v-col(cols="12")
              v-chip.mr-2.mb-2(
                v-for="(item, index) in bizmodifier.modifier.associatedChoices",
                :key="index",
                small,
                :color="item.isDefault ? 'orange darken-3' : 'grey lighten-1'",
                close,
                @click:close="removeSubChoice(index)",
                @click="selectedSubChoice = item"
              )
                v-icon.mr-2(
                  v-if="item.useBoostedPrice",
                  color="green darken-2",
                  small
                ) arrow_upward
                span {{ item.name }}
                span.ml-1(v-if="item.freeMax && item.freeMax > 0") &#8804;{{ item.freeMax }}
          v-card.mb-3(outlined, v-if="selectedSubChoice")
            v-card-text
              .d-flex.flex-row
                v-text-field.mr-3(
                  v-model="selectedSubChoice.name",
                  label="Name",
                  hide-details,
                  @keydown.enter.prevent=""
                )
                v-checkbox.mr-3(
                  color="secondary",
                  v-model="selectedSubChoice.isDefault",
                  hide-details,
                  dense,
                  label="Default"
                )
                v-checkbox(
                  color="secondary",
                  v-model="selectedSubChoice.useBoostedPrice",
                  dense,
                  label="Use Tier-2 Price"
                )
              div Maximum of included selection
              v-btn-toggle(v-model="selectedSubChoice.freeMax", dense)
                v-btn(
                  v-for="(item, findex) in freeItems",
                  :key="findex",
                  :value="item.value",
                  small
                ) {{ item.text }}
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";
import { EventBus } from "@/event-bus.js";

export default {
  name: "ModifierForm",
  data() {
    return {
      bizmodifier: null,
      dialog: false,
      freeItems: [
        { text: "N/A", value: 0 },
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 },
        { text: "Any", value: 100 },
      ],
      newChoiceNames: "",
      newSubChoiceNames: "",
      hasSub: false,
      selectedSubChoice: null,
      errors: [],
      loading: false,
      draggingIndex: null,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    maxSelection() {
      if (!this.bizmodifier) return 1;
      return this.bizmodifier.modifier.maxSelection;
    },
    items() {
      return _.range(1, 101);
    },
    max_items() {
      return _.map(_.range(1, this.maxSelection + 1), (o) => ({
        text: String(o),
        value: o,
      }));
    },
    dishes() {
      if (this.biz) {
        return _.chain(this.biz.dishes)
          .map((o) => {
            const course = this.getCourseName(o.course);
            return {
              text: o.name,
              value: o._id,
              course: course,
              avatar: course,
            };
          })
          .sortBy("course")
          .sortBy("text")
          .value();
      } else {
        return [];
      }
    },
    title() {
      if (this.bizmodifier && this.bizmodifier._id) return "Edit Modifier";
      else return "Create Modifier";
    },
  },
  mounted() {
    EventBus.$on("edit-modifier", this.open);
  },
  destroyed() {
    EventBus.$off("edit-modifier", this.open);
  },
  methods: {
    ...mapActions(["addBizmodifier", "updateBizmodifier"]),
    open(val) {
      if (!val) return;
      this.bizmodifier = JSON.parse(JSON.stringify(val));
      this.hasSub = this.bizmodifier?.modifier?.associatedChoices?.length;
      _.each(this.bizmodifier.modifier.choices, (o) => {
        if (o.maximum > this.maxSelection) o.maximum = this.maxSelection;
      });
      this.selectedSubChoice = null;
      this.errors = [];
      this.dialog = true;
      this.$nextTick(() => this.$refs.focus.focus());
    },
    applyToCourse(course) {
      if (!course) return;
      let dishes = _.chain(this.biz.dishes)
        .filter((o) => o.course == course._id)
        .pluck("_id")
        .value();
      dishes = this.bizmodifier.dishes.concat(dishes);
      this.bizmodifier.dishes = _.chain(dishes).sortBy().uniq().value();
    },
    getCourseName(id) {
      if (!this.biz || id) return "";
      return this.biz.courses.find((o) => o._id === id)?.name || "";
    },
    addChoices() {
      if (!this.newChoiceNames) return;
      if (!this.bizmodifier || !this.bizmodifier.modifier) return;
      const items = _.chain(this.newChoiceNames.split(","))
        .map((o) => {
          return {
            name: o.trim(),
            kName: "",
            price: 0,
            quantity: 1,
            maximum: 1,
            associatedChoiceUnitPrice: 0,
            associatedChoiceBoostedPrice: 0,
            associatedChoiceMax: 100,
            selected: false,
            hsc: false,
          };
        })
        .filter((o) => {
          return o.name && o.name.length > 0;
        })
        .value();
      if (this.bizmodifier?.modifier?.choices) {
        this.bizmodifier.modifier.choices =
          this.bizmodifier.modifier.choices.concat(items);
      } else {
        this.bizmodifier.modifier.choices = items;
      }
      this.newChoiceNames = "";
    },
    sortChoices() {
      this.bizmodifier.modifier.choices = _.sortBy(
        this.bizmodifier.modifier.choices,
        (o) => o.name
      );
    },
    addSubChoices() {
      if (!this.newSubChoiceNames) return;
      if (!this.bizmodifier?.modifier) return;
      const knowNames = this.bizmodifier.modifier.associatedChoices.map(
        (o) => o.name
      );
      const items = _.chain(this.newSubChoiceNames.split(","))
        .map((o) => {
          return {
            name: o.trim(),
            selected: false,
            isDefault: false,
            useBoostedPrice: false,
            halfhalf: 1,
            quantity: 1,
          };
        })
        .filter((o) => o.name)
        .reject((o) => knowNames.indexOf(o.name) !== -1)
        .value();
      if (this.bizmodifier?.modifier?.associatedChoices) {
        this.bizmodifier.modifier.associatedChoices =
          this.bizmodifier.modifier.associatedChoices.concat(items);
        this.bizmodifier.modifier.associatedChoices = _.sortBy(
          this.bizmodifier.modifier.associatedChoices,
          "name"
        );
      } else {
        this.bizmodifier.modifier.associatedChoices = items;
      }
      this.newSubChoiceNames = "";
    },
    removeSubChoice(index) {
      if (
        !this.bizmodifier?.modifier?.associatedChoices ||
        index < 0 ||
        index >= this.bizmodifier.modifier.associatedChoices.length
      )
        return;
      this.bizmodifier.modifier.associatedChoices.splice(index, 1);
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: function (index) {
      if (
        !this.bizmodifier ||
        !this.bizmodifier.modifier ||
        !this.bizmodifier.modifier.choices
      )
        return;
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.bizmodifier.modifier.choices.length)
        return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.bizmodifier.modifier.choices.length
      )
        return;
      let choices = JSON.parse(
        JSON.stringify(this.bizmodifier.modifier.choices)
      );
      let tmp = JSON.parse(JSON.stringify(choices[this.draggingIndex]));
      choices.splice(this.draggingIndex, 1);
      choices.splice(index, 0, tmp);
      this.bizmodifier.modifier.choices = choices;
    },
    removeChoice(index) {
      if (!this.bizmodifier?.modifier?.choices) return;
      if (index < this.bizmodifier.modifier.choices.length && index >= 0) {
        this.bizmodifier.modifier.choices.splice(index, 1);
      }
    },
    async submit() {
      this.errors = [];
      /// validate
      if (!this.bizmodifier?.bizId || !this.bizmodifier.modifier) {
        this.errors = ["Invalid data"];
        return;
      }
      /// clean up dishes that are not in the biz
      this.bizmodifier.dishes = this.bizmodifier.dishes.filter((o) =>
        this.biz.dishes.some((d) => d._id == o)
      );
      const modifier = this.bizmodifier.modifier;
      modifier.choices = _.chain(modifier.choices)
        .reject((o) => !o.name)
        .map((o) => {
          if (o.price < 0) o.price = 0;
          if (o.associatedChoiceUnitPrice < 0) o.associatedChoiceUnitPrice = 0;
          if (o.associatedChoiceBoostedPrice < 0)
            o.associatedChoiceBoostedPrice = 0;
          return o;
        })
        .value();
      modifier.associatedChoices = modifier.associatedChoices?.map((o) => {
        if (o.isDefault) o.selected = true;
        else o.selected = false;
        return o;
      });
      if (!modifier.choices?.length) this.errors.push("Empty choices");
      if (!modifier.name) this.errors.push("Name is required");
      if (this.errors?.length) return;

      this.loading = true;
      if (this.bizmodifier?._id) {
        const params = {
          criteria: { _id: this.bizmodifier._id },
          action: { $set: this.bizmodifier },
        };
        const data = await this.$api.bizModifier.update(params);
        this.updateBizmodifier(data);
      } else {
        const data = await this.$api.bizModifier.create(this.bizmodifier);
        this.addBizmodifier(data);
      }
      this.loading = false;
      this.dialog = false;
      EventBus.$emit("modifier-edited");
    },
  },
};
</script>
