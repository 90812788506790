<template lang="pug">
v-dialog(v-model="dialog", width="560")
  v-card(v-if="ingredient")
    v-toolbar(dense, flat)
      .subtitle-2 {{ title }} an Ingredient
    v-form(@submit.prevent="submit")
      v-card-text
        v-row(wrap, dense)
          v-col(cols="6")
            v-text-field(label="Name", v-model="ingredient.name")
          v-col(cols="6")
            v-text-field(label="ID (optional)", v-model="ingredient.uid")
          v-col(cols="6")
            v-text-field(label="Unit of Measurement", v-model="ingredient.uom")
          v-col(cols="6")
            v-text-field(
              label="Price",
              v-model="ingredient.price",
              type="number",
              prefix="$",
              step="0.01"
            )
        v-btn(
          block,
          color="secondary",
          type="submit",
          :loading="loading",
          :disabled="!valid"
        ) Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MultiCourseForm",
  data() {
    return {
      ingredient: null,
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz", "menuIngredients"]),
    title() {
      if (this.ingredient._id) return "Edit";
      return "Add";
    },
    hasDuplicate() {
      if (!this.ingredient) return false;
      return this.menuIngredients?.some((o) => {
        return o._id != this.ingredient._id && o.name == this.ingredient.name;
      });
    },
    valid() {
      return (
        this.ingredient &&
        !this.hasDuplicate &&
        this.ingredient.name &&
        this.ingredient.uom &&
        this.ingredient.price > 0
      );
    },
  },
  methods: {
    ...mapActions(["addMenuIngredient", "updateMenuIngredient"]),
    open(data) {
      if (data) {
        this.ingredient = JSON.parse(JSON.stringify(data));
      } else {
        this.ingredient = {
          biz: this.biz._id,
          name: "",
          uid: "",
          uom: "",
          price: 0,
        };
      }
      this.dialog = true;
    },
    submit() {
      if (!this.ingredient) return;
      if (this.ingredient._id) this.update();
      else this.create();
    },
    async update() {
      if (!this.ingredient._id) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.ingredient._id },
        action: {
          $set: {
            name: this.ingredient.name,
            uid: this.ingredient.uid,
            uom: this.ingredient.uom,
            price: this.ingredient.price,
          },
        },
      };
      const data = await this.$api.ingredient.update(params);
      this.updateMenuIngredient(data);
      this.loading = false;
      this.dialog = false;
    },
    async create() {
      if (this.ingredient._id) return;
      this.loading = true;
      const data = await this.$api.ingredient.create(this.ingredient);
      this.addMenuIngredient(data);
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>
