const state = {
  weekly_specials: []
}

const getters = {
  weekly_specials: state => state.weekly_specials
}

const actions = {
  setWeeklySpecials: ({ commit }, data) => { commit('setWeeklySpecials', data) },
  updateWeeklySpecial: ({ commit }, data) => { commit('updateWeeklySpecial', data) },
  addWeeklySpecial: ({ commit }, data) => { commit('addWeeklySpecial', data) },
  removeWeeklySpecial: ({ commit }, data) => { commit('removeWeeklySpecial', data) }
}

const mutations = {
  setWeeklySpecials(state, data) {
    state.weekly_specials = data
  },
  updateWeeklySpecial(state, data) {
    state.weekly_specials = state.weekly_specials.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addWeeklySpecial(state, data) {
    state.weekly_specials.push(data)
  },
  removeWeeklySpecial(state, data) {
    state.weekly_specials = state.weekly_specials.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }