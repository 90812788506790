<template lang="pug">
.text-center
  span(v-if="days") Expiries (in {{ memberpromo.days }} days)
  span(v-else) No Expiry
  v-btn(icon, small, @click="edit()")
    v-icon(color="secondary", small) mdi-pencil
  v-dialog(width="600", v-model="dialog")
    v-card
      v-toolbar(dense, flat) Expiry
      v-card-text
        span The birthday/anniversary emails are sent out seven days in advance. The minimum days to expiration are 10. Or, they can be set to never expire.
        v-form(@submit.prevent="save()")
          v-layout
            v-switch(v-model="hasExpiry", label="Has Expiry")
            v-text-field(
              v-model="number",
              label="Days to Expiration",
              v-if="hasExpiry"
            )
          v-btn(block, color="secondary", type="submit") Save
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      number: 0,
      hasExpiry: false,
    };
  },
  computed: {
    ...mapGetters(["memberpromo"]),
    days() {
      if (this.memberpromo && this.memberpromo.days)
        return moment().add(this.memberpromo.days, "days").format("D MMM,YYYY");
      else return null;
    },
  },
  methods: {
    ...mapActions(["setMemberPromo"]),
    edit() {
      this.number = this.memberpromo.days;
      if (this.number > 0) this.hasExpiry = true;
      else this.hasExpiry = false;
      this.dialog = true;
    },
    async save() {
      if (!this.memberpromo) return;
      let days = this.number;
      if (!this.hasExpiry) days = 0;
      else if (days < 10) {
        alert("Expiry days have to be equal or greater than 10");
        return;
      }
      const params = {
        criteria: { _id: this.memberpromo._id },
        action: { $set: { days } },
      };
      const result = await this.$api.memberPromo.update(params);
      this.setMemberPromo(result);
      this.dialog = false;
    },
  },
};
</script>
