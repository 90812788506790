<template lang="pug">
v-card(flat, :loading="loading")
  v-toolbar(flat, dense)
    .subtitle-2 Online Throttling Setup
  v-card-text It's optional to set up automatic throttling on an hourly basis with a maximum number of orders and items.
  Table(v-if="!loading")
  Form
</template>

<script>
import Table from "./Table";
import Form from "./Form";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Table, Form },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    bizId() {
      return this.biz && this.biz._id;
    },
  },
  watch: {
    bizId(val) {
      if (val) this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBizThrottles"]),
    async load() {
      this.setBizThrottles([]);
      if (!this.biz) return;
      this.loading = true;
      const criteria = { biz: this.biz._id };
      try {
        const result = await this.$api.b.throttle.list({ criteria });
        this.setBizThrottles(result);
      } catch (e) {
        //
      }
      this.loading = false;
    },
  },
};
</script>