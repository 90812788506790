<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";
import moment from "moment-timezone";

export default {
  props: ["items", "title", "year", "sum"],
  methods: {
    download() {
      if (!this.items) return;
      const currency = this.$options.filters.currency;
      const datetime = this.$options.filters.datetime;
      const csv = new CSV();
      csv.addRow([
        "Order #",
        "Time",
        "Subtotal",
        "Gift",
        "Tax",
        "Tip",
        "Discount",
        "Selflane Charges",
        "Total",
        "Proessing Fee",
        "Balance",
      ]);
      this.items.forEach((item) => {
        csv.addRow([
          item.orderNumber,
          datetime(item.needed),
          currency(item.payment.dish),
          currency(item.payment.gift),
          currency(item.payment.tax),
          currency(item.payment.tip),
          currency(item.payment.deduction.total),
          currency(item.payment.fee + item.payment.selflane.total),
          currency(item.payment.total),
          currency(
            item.payment.charge.application_fee -
              item.payment.fee -
              item.payment.selflane.total
          ),
          currency(item.payment.total - item.payment.charge.application_fee),
        ]);
      });
      csv.addRow([
        "Sum",
        this.sum.count,
        currency(this.sum.dish),
        currency(this.sum.gift),
        currency(this.sum.tax),
        currency(this.sum.tip),
        currency(this.sum.deduction),
        currency(this.sum.fee),
        currency(this.sum.total),
        currency(this.sum.application_fee - this.sum.fee),
        currency(this.sum.total - this.sum.application_fee),
      ]);
      csv.save(this.title);
    },
    time(item) {
      if (item.month) {
        return this.$options.filters.month(item.month);
      } else {
        const begin = moment()
          .year(this.year)
          .week(item.week + 1)
          .startOf("week")
          .format("MMM D");
        const end = moment()
          .year(this.year)
          .week(item.week + 1)
          .endOf("week")
          .format("MMM D");
        return begin + " - " + end;
      }
    },
  },
};
</script>
