<template lang="pug">
IconBtn(@click="sync", icon="mdi-sync", title="Sync", :loading="loading")
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    async sync() {
      this.loading = true;
      try {
        if (!this.biz?._id) throw new Error("Invalid business ID");
        const result = await this.$api.seven.user.custom("sync", {
          bizId: this.biz._id,
        });
        this.$store.dispatch("seven/setUsers", result);
      } catch (e) {
        this.$store.dispatch("seven/setUsers", []);
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>