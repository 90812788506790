<template lang="pug">
v-dialog(v-model="dialog", max-width="550", @keydown.esc="dialog = false")
  template(v-slot:activator="{ on }")
    v-btn.text-capitalize(outlined, small, v-on="on", @click="open()") Add
      v-icon(small, right) mdi-plus
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Add Reward Program
      v-spacer
      v-btn.text-notransform(
        outlined,
        small,
        color="secondary",
        @click="addTier()"
      )
        span More Tier
        v-icon(small, right) mdi-plus
    v-form(@submit.prevent="submit", v-if="reward")
      simple-table
        thead
          tr
            th Reward Percentage
            th Range
            th Range Unit
        tbody
          tr(v-for="(t, index) in reward.tiers", :key="index")
            td
              v-text-field(
                label="Reward Percentage",
                v-model="t.pct",
                suffix="%",
                single-line,
                hide-details
              )
            td
              v-select(
                label="Span",
                v-model="t.span",
                :items="spans",
                single-line,
                hide-details
              )
            td
              v-select(
                label="Span",
                v-model="t.spanUnit",
                :items="spanUnits",
                single-line,
                hide-details
              )
            td
              v-btn(
                v-if="index !== 0",
                small,
                icon,
                @click="removeTier(index)",
                color="error"
              )
                v-icon mdi-close
      v-card-text
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
        .body-2.mt-3 We support a tiered reward program in which the next tier with less percentage and longer period.
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["input"],
  data() {
    return {
      reward: null,
      dialog: false,
      errors: [],
      loading: false,
      spans: [1, 2, 3, 4, 5, 6],
      spanUnits: ["week", "month"],
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    ...mapActions(["addReward"]),
    open() {
      this.reward = {
        biz: this.biz._id,
        tiers: [{ pct: 10, span: 2, spanUnit: "week" }],
      };
      this.errors = [];
      this.dialog = true;
    },
    addTier() {
      if (!this.reward || !this.reward.tiers || this.reward.tiers.length > 2)
        return;
      this.reward.tiers.push({
        pct: 10,
        span: 2,
        spanUnit: "week",
      });
    },
    removeTier(index) {
      if (!this.reward || !this.reward.tiers || this.reward.tiers.length <= 1)
        return;
      this.reward.tiers = this.reward.tiers.splice(index);
    },
    async submit() {
      this.errors = [];
      /// validate
      if (this.reward.tiers.length > 1) {
        if (this.reward.tiers[1].pct <= this.reward.tiers[0].pct)
          this.errors.push(
            "Reward percentage should be greater one tier after another."
          );
        const invalidSpan =
          this.reward.tiers[1].spanUnit === this.reward.tiers[0].spanUnit &&
          this.reward.tiers[1].span <= this.reward.tiers[0].span;
        const invalidSpanUnit =
          this.reward.tiers[0].spanUnit === "month" &&
          this.reward.tiers[1].spanUnit !== "month";
        if (invalidSpan || invalidSpanUnit)
          this.errors.push("Span should be greater one tier after another.");
      }
      if (this.reward.tiers.length > 2) {
        if (this.reward.tiers[2].pct <= this.reward.tiers[1].pct)
          this.errors.push(
            "Reward percentage should be greater one tier after another."
          );
        const invalidSpan =
          this.reward.tiers[2].spanUnit === this.reward.tiers[1].spanUnit &&
          this.reward.tiers[2].span <= this.reward.tiers[1].span;
        const invalidSpanUnit =
          this.reward.tiers[1].spanUnit === "month" &&
          this.reward.tiers[2].spanUnit !== "month";
        if (invalidSpan || invalidSpanUnit)
          this.errors.push("Span should be greater one tier after another.");
      }
      if (this.errors.length > 0) return;
      this.loading = true;
      try {
        const res = await this.axios.post("/reward/create", this.reward);
        this.addReward(res.data);
        this.dialog = false;
      } catch (e) {
        this.errors = [e.response.data];
      }
      this.loading = false;
    },
  },
};
</script>
