<template lang="pug">
v-btn(
  @click="handler()",
  :disabled="!visible",
  color="secondary",
  outlined,
  small,
  :loading="loading"
)
  span.text-notransform Close
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order"],
  data() {
    return { loading: false };
  },
  computed: {
    visible() {
      return this.order && this.order.status == 2;
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async handler() {
      if (this.order?.status !== 2) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.order._id },
        action: { $set: { status: 3 } },
      };
      try {
        const { data } = await this.axios.post("/orders/update", params);
        this.updateOrder(data);
        this.$toast.success("Order closed.");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>