const state = {
  bizDocuments: [],
};

const getters = {
  bizDocuments: state => state.bizDocuments,
};

const actions = {
  setBizDocuments: ({ commit }, data) => { commit("setBizDocuments", data); },
  updateBizDocument: ({ commit }, data) => { commit("updateBizDocument", data); },
  addBizDocument: ({ commit }, data) => { commit('addBizDocument', data) },
  removeBizDocument: ({ commit }, data) => { commit("removeBizDocument", data); },
};

const mutations = {
  setBizDocuments(state, data) {
    state.bizDocuments = data;
  },
  updateBizDocument(state, data) {
    state.bizDocuments = state.bizDocuments.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addBizDocument(state, data) {
    state.bizDocuments = state.bizDocuments.concat([data])
  },
  removeBizDocument(state, data) {
    state.bizDocuments = state.bizDocuments.filter(o => o._id !== data._id)
  },
};

export default { state, getters, actions, mutations };