<template lang="pug">
PartnerPage(:greeting="false", title="Agent Profile", :doc="doc")
  v-col(align="center")
    ProfilePicture
  v-card.my-5(outlined)
    v-toolbar(flat, dense)
      .subtitle-2 Profile
      v-spacer
      v-btn(icon, small, color="secondary", @click="edit")
        v-icon(small) mdi-pencil
    v-list(dense)
      v-list-item
        v-list-item-content
          v-list-item-title Name
          v-list-item-subtitle {{ name }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Phone
          v-list-item-subtitle {{ salesPerson.phone | phone }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Address
          v-list-item-subtitle {{ salesPerson.address | address }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Email
          v-list-item-subtitle {{ salesPerson.email }}
  Form
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus";
import ProfilePicture from "/libs/components/SalesForce/Person/ProfilePicture";
import Form from "/libs/components/SalesForce/Person/Form";

export default {
  components: { ProfilePicture, Form },
  data() {
    return {
      doc: "https://docs.selflane.com/partner/agent/profile.html",
    };
  },
  computed: {
    ...mapGetters(["salesPerson"]),
    name() {
      if (!this.salesPerson.name)
        return `${this.salesPerson.first_name} ${this.salesPerson.last_name}`;
      return this.salesPerson.name;
    },
  },
  methods: {
    edit() {
      EventBus.$emit("edit-sales-person", this.salesPerson);
    },
  },
};
</script>