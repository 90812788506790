<template lang="pug">
v-card(v-if="data", flat)
  Table(:items="items")
</template>

<script>
import Table from "./Table";

export default {
  components: { Table },
  props: ["data"],
  computed: {
    items() {
      return [
        { name: "Cash", amount: this.data.cash },
        { name: "Credit Card", amount: this.data.cc },
        { name: "Stripe", amount: this.data.stripe },
        { name: "Check", amount: this.data.check },
        { name: "Gift Redeemed", amount: this.data.gc },
        { name: "External Card", amount: this.data.excc },
        { name: "Selflane Online", amount: this.data.online },
        { name: "Reward", amount: this.data.reward },
        {
          name: "Total Received",
          amount: this.data.total,
          bold: true,
          required: true,
        },
      ];
    },
  },
};
</script>
