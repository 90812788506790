<template lang="pug">
v-card(flat)
  v-toolbar(dense, flat)
    .subtitle-2 Action Log
  v-data-table(:headers="headers", :items="logs", dense, mobile-breakpoint=0)
    template(v-slot:item.created="{ item }")
      span {{ item.created | datetime("M/D h:mm A") }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  name: "EnterpriseLog",
  data() {
    return {
      logs: [],
      headers: [
        { text: "Date", value: "created" },
        { text: "Action", value: "action" },
        { text: "User", value: "user_email", align: "end" },
      ],
    };
  },
  computed: { ...mapGetters("entp", ["enterprise"]) },
  mounted() {
    this.load();
    EventBus.$on("enterprise:changed", this.load);
  },
  methods: {
    async load() {
      if (!this.enterprise) return;
      try {
        const params = { criteria: { enterprise: this.enterprise._id } };
        this.logs = await this.$api.entp.log.list(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>