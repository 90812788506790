<template lang="pug">
div(v-if="stripeAccount")
  IconBtn(@click="add()", title="Add", icon="mdi-plus")
  v-dialog(v-model="dialog", max-width="400")
    v-form(@submit.prevent="submit")
      v-card(v-if="bank")
        v-toolbar(flat, dense)
          .subtitle-2 Add Bank Account
        v-card-text
          v-row(dense)
            v-col
              v-text-field(disabled, v-model="bank.country", label="Country")
            v-col
              v-text-field(disabled, v-model="bank.currency", label="Currency")
          v-text-field(
            label="Routing Number",
            v-model="bank.routing_number",
            v-if="isUS"
          )
          v-row(dense, v-else)
            v-col(cols="6")
              v-text-field(label="Transit Number", v-model="transit")
            v-col(cols="6")
              v-text-field(label="Institution Number", v-model="institution")
          v-text-field(label="Account Number", v-model="bank.account_number")
          v-text-field(
            label="Confirm Account Number",
            v-model="repeat_account_number"
          )
          .red--text {{ errors }}
          .caption Please double check the rounting and account number. The fund will be transfered to your bank account automatically every Monday.
        v-card-actions
          v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  name: "CreateBankAccount",
  data() {
    return {
      dialog: false,
      loading: false,
      transit: "",
      institution: "",
      bank: {
        country: "",
        currency: "",
        routing_number: "",
        account_number: "",
      },
      repeat_account_number: "",
      errors: "",
    };
  },
  computed: {
    ...mapGetters(["stripeAccount"]),
    isUS() {
      return this.stripeAccount.country == "US";
    },
  },
  methods: {
    add() {
      this.transit = "";
      this.institution = "";
      this.bank = {
        country: this.stripeAccount.country,
        currency: this.stripeAccount.default_currency,
        routing_number: "",
        account_number: "",
      };
      this.repeat_account_number = "";
      this.dialog = true;
    },
    validate() {
      if (!this.stripeAccount) throw new Error("No connected account");
      if (!this.bank) throw new Error("Invalid bank object");
      if (!this.bank.country) throw new Error("Invalid country");
      if (!this.bank.currency) throw new Error("Invalid currency");
      if (!this.bank.routing_number) throw new Error("Invalid routing number");
      if (!this.bank.account_number) throw new Error("Invalid account number");
      if (!this.repeat_account_number)
        throw new Error("Invalid confirm account number");

      if (this.repeat_account_number != this.bank.account_number)
        throw new Error("Confirm account number does not match");
    },
    /// get token for bank account
    async submit() {
      const stripe = window.Stripe(process.env.VUE_APP_STRIPE);
      if (!this.isUS) {
        this.bank.routing_number = `${this.transit.trim()}-${this.institution.trim()}`;
      }
      this.loading = true;
      try {
        this.validate();
        const data = await stripe.createToken("bank_account", this.bank);
        const tokenId = data?.token?.id;
        if (!tokenId) {
          this.$toast.error("Failed to generate token");
        } else {
          await this.createBank(tokenId);
          this.dialog = false;
        }
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async createBank(token) {
      if (!this.stripeAccount) return;
      const params = { accountId: this.stripeAccount.id, token: token };
      await this.$api.stripe.bankaccount.create(params);
      EventBus.$emit("reload-bank-account");
    },
  },
};
</script>
