<template lang="pug">
div
  simple-table(v-if="groupedItems")
    thead
      tr
        th Date
        th Count
        th Subtotal
        th Gift Card
        th Tax
        th Tip
        th Discount
        th.success--text Selflane Charges
        th Total
        th.success--text Processing Fee
        th.success--text Balance
    tbody
      tr(v-for="(item, index) in groupedItems", :key="index")
        th {{ item.day }}
        td {{ item.count }}
        td {{ item.dish | currency }}
        td {{ item.gift | currency }}
        td {{ item.tax | currency }}
        td {{ item.tip | currency }}
        td ({{ item.deduction | currency }})
        td {{ item.fee | currency }}
        td {{ item.total | currency }}
        td ({{ (item.application_fee - item.fee) | currency }})
        td {{ (item.total - item.application_fee) | currency }}
    tfoot
      tr
        td Sum
        td {{ sum.count }}
        td {{ sum.dish | currency }}
        td {{ sum.gift | currency }}
        td {{ sum.tax | currency }}
        td {{ sum.tip | currency }}
        td ({{ sum.deduction | currency }})
        td {{ sum.fee | currency }}
        td {{ sum.total | currency }}
        td ({{ (sum.application_fee - sum.fee) | currency }})
        td {{ (sum.total - sum.application_fee) | currency }}
  Download.my-5(:items="groupedItems", :title="title", :sum="sum")
</template>

<script>
import _ from "underscore";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Download from "./Download";

export default {
  name: "OrderTableWithDetailByOrder",
  components: { Download },
  props: {
    title: { type: String, default: "" },
  },
  computed: {
    ...mapGetters(["orders"]),
    groupedItems() {
      return _.chain(this.orders)
        .sortBy("needed")
        .groupBy((o) => {
          return moment(o.needed).format("M/D/YYYY");
        })
        .map((items, day) => {
          return {
            day: day,
            count: items.length,
            dish: items.reduce((acc, cur) => acc + cur.payment.dish, 0),
            discount: items.reduce((acc, cur) => acc + cur.payment.discount, 0),
            gift: items.reduce((acc, cur) => acc + cur.payment.gift, 0),
            total: items.reduce((acc, cur) => acc + cur.payment.total, 0),
            tax: items.reduce((acc, cur) => acc + cur.payment.tax, 0),
            tip: items.reduce((acc, cur) => acc + cur.payment.tip, 0),
            fee: items.reduce(
              (acc, cur) =>
                acc + cur.payment.fee + (cur.payment.selflane?.total || 0),
              0
            ),
            deduction: items.reduce(
              (acc, cur) => acc + (cur.payment.deduction?.total || 0),
              0
            ),
            application_fee: items.reduce(
              (acc, cur) => acc + cur.payment.charge.application_fee,
              0
            ),
          };
        })
        .value();
    },
    sum() {
      return {
        count: this.orders.length,
        dish: this.orders.reduce((acc, cur) => acc + cur.payment.dish, 0),
        discount: this.orders.reduce(
          (acc, cur) => acc + cur.payment.discount,
          0
        ),
        gift: this.orders.reduce((acc, cur) => acc + cur.payment.gift, 0),
        total: this.orders.reduce((acc, cur) => acc + cur.payment.total, 0),
        tax: this.orders.reduce((acc, cur) => acc + cur.payment.tax, 0),
        tip: this.orders.reduce((acc, cur) => acc + cur.payment.tip, 0),
        fee: this.orders.reduce(
          (acc, cur) => acc + cur.payment.fee + cur.payment.selflane.total,
          0
        ),
        deduction: this.orders.reduce(
          (acc, cur) => acc + (cur.payment.deduction?.total || 0),
          0
        ),
        application_fee: this.orders.reduce(
          (acc, cur) => acc + cur.payment.charge.application_fee,
          0
        ),
      };
    },
  },
  methods: {
    show(item) {
      if (!item?._id) return;
      EventBus.$emit("show-order-detail", item._id);
    },
  },
};
</script>
