<template lang="pug">
v-row.my-2(wrap)
  v-col(cols="12", sm="4")
    StandardChart(
      :input="barChart.countChart",
      maxWidth="100%",
      chartType="bar"
    )
      PieChart(:chartData="pieChart.countChart", v-if="pieChart")
  v-col(cols="12", sm="4")
    StandardChart(
      :input="barChart.hourChart",
      maxWidth="100%",
      chartType="bar",
      :stacked="true",
      :legend="true"
    )
      PieChart(:chartData="pieChart.hourChart", v-if="pieChart")
  v-col(cols="12", sm="4", v-if="showWage")
    StandardChart(
      :input="barChart.wageChart",
      maxWidth="100%",
      chartType="bar",
      :stacked="true",
      :legend="true"
    )
      PieChart(:chartData="pieChart.wageChart", v-if="pieChart")
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import moment from "moment-timezone";

export default {
  props: {
    startDay: { type: Number, default: 1 }, // 0 = Sunday, 1 = Monday, etc.
  },

  computed: {
    ...mapGetters(["punchcards", "biz", "useraccess"]),
    // punchcards that are not deleted
    // sorted by clockIn
    // group by server and calculate hours with extHours if hours is greater than 40
    items() {
      return _.chain(this.punchcards)
        .filter((o) => o.status)
        .sortBy("clockIn")
        .groupBy("server")
        .map((list) => {
          let hours = 0;
          _.each(list, (o) => {
            const clockIn = moment(o.clockIn);
            const clockOut = moment(o.clockOut || Date.now());
            const diff = clockOut.diff(clockIn, "hours", true);
            if (hours > 40) {
              o.extHours = diff;
              o.regHours = 0;
            } else if (hours + diff > 40) {
              o.extHours = hours + diff - 40;
              o.regHours = diff - o.extHours;
            } else {
              o.extHours = 0;
              o.regHours = diff;
            }
            o.regWage = o.regHours * o.rate;
            o.extWage = o.extHours * o.rate * 1.5;
            hours += diff;
          });
          return list;
        })
        .flatten()
        .value();
    },
    // ['Sun' ... 'Sat'] shifted by startDay
    labels() {
      return this.days.map((day) => moment().day(day).format("ddd"));
    },
    // [0, 1, 2, 3, 4, 5, 6] shifted by startDay
    days() {
      return [...Array(7).keys()].map((day) => (day + this.startDay) % 7);
    },
    access() {
      if (!this.$auth.check() || !this.biz) return null;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      return this.useraccess?.find((o) => o.user == userId && o.biz == bizId);
    },
    showWage() {
      return this.access?.privilege == 1 || this.access?.access_wage;
    },
    barChart() {
      const data = this.days.map((day) => {
        const items = this.items.filter((o) => o.day % 7 === day);
        const count = _.chain(items).pluck("server").uniq().value().length;
        let hours = items.reduce((a, b) => a + b.hours, 0);
        let regHours = items.reduce((a, b) => a + b.regHours, 0);
        let extHours = items.reduce((a, b) => a + b.extHours, 0);
        let wage = items.reduce((a, b) => a + b.hours * b.rate, 0);
        let regWage = items.reduce((a, b) => a + b.regWage, 0);
        let extWage = items.reduce((a, b) => a + b.extWage, 0);
        hours = Math.round(hours * 100) / 100; // round to 2 decimal places
        wage = Math.round(wage * 100) / 100;
        regHours = Math.round(regHours * 100) / 100;
        regWage = Math.round(regWage * 100) / 100;
        extHours = Math.round(extHours * 100) / 100;
        extWage = Math.round(extWage * 100) / 100;
        return { count, hours, regHours, extHours, wage, regWage, extWage };
      });
      let regHours = data.reduce((a, b) => a + b.regHours, 0);
      let extHours = data.reduce((a, b) => a + b.extHours, 0);
      let hours = regHours + extHours;
      let regWage = data.reduce((a, b) => a + b.regWage, 0);
      let extWage = data.reduce((a, b) => a + b.extWage, 0);
      let wage = regWage + extWage;
      regHours = Math.round(regHours * 100) / 100;
      extHours = Math.round(extHours * 100) / 100;
      hours = Math.round(hours * 100) / 100;
      regWage = Math.round(regWage * 100) / 100;
      extWage = Math.round(extWage * 100) / 100;
      wage = Math.round(wage * 100) / 100;
      const countChart = {
        title: "Head Count",
        labels: this.labels,
        values: data.map((o) => o.count),
      };
      const hourChart = {
        title: `Hours (${hours} hrs)`,
        labels: this.labels,
        datasets: [
          {
            label: `Reg (${regHours} hrs)`,
            data: data.map((o) => o.regHours),
            backgroundColor: "#7682d6",
            stack: "Stack 0",
          },
          {
            label: `OT (${extHours} hrs)`,
            data: data.map((o) => o.extHours),
            backgroundColor: "#f87979",
            stack: "Stack 0",
          },
        ],
      };
      const wageChart = {
        title: `Wage ($${wage})`,
        labels: this.labels,
        datasets: [
          {
            label: `Reg ($${regWage})`,
            data: data.map((o) => o.regWage),
            backgroundColor: "#7682d6",
            stack: "Stack 0",
          },
          {
            label: `OT ($${extWage})`,
            data: data.map((o) => o.extWage),
            backgroundColor: "#f87979",
            stack: "Stack 0",
          },
        ],
        isCurrency: true,
      };
      return { countChart, hourChart, wageChart };
    },
    pieChart() {
      if (!this.items.length) return;
      const backgroundColor = [
        "#7682d6",
        "#2cb2b9",
        "#50a948",
        "#ad4d24",
        "#a74ac3",
        "#c5d729",
      ];
      const data = _.chain(this.items)
        .groupBy("role")
        .map((items, role) => {
          let hours = items.reduce((a, b) => a + b.hours, 0);
          let wage = items.reduce((a, b) => a + b.regWage + b.extWage, 0);
          hours = Math.round(hours * 100) / 100;
          wage = Math.round(wage * 100) / 100;
          // count number of unique servers
          const count = items
            .map((o) => o.server)
            .filter((o, i, a) => a.indexOf(o) === i).length;
          return { role, count, hours, wage };
        })
        .sortBy("role")
        .value();
      const labels = data.map((o) => o.role);
      const countChart = {
        labels,
        datasets: [{ backgroundColor, data: data.map((o) => o.count) }],
      };
      const hourChart = {
        labels,
        datasets: [{ backgroundColor, data: data.map((o) => o.hours) }],
      };
      const wageChart = {
        labels,
        datasets: [{ backgroundColor, data: data.map((o) => o.wage) }],
      };
      return { countChart, hourChart, wageChart };
    },
  },
};
</script>
