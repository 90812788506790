<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-card(v-if="course")
    v-toolbar(dense, flat)
      .subtitle-2 Edit Course
    v-form(@submit.prevent="submit")
      v-card-text
        v-row(dense, wrap)
          v-col(cols="12")
            v-text-field(label="Name", v-model="course.name", autofocus)
          v-col(cols="6")
            v-select(
              label="Classification",
              v-model="course.classification",
              :items="classification_items",
              dense
            )
          v-col(cols="6")
            PNLPicker(v-model="course.grp", dense)
        v-textarea(
          label="Description (optional)",
          v-model="course.description",
          counter="200",
          rows="1",
          :auto-grow="true"
        )
        v-row(wrap, dense)
          v-col(cols="6", xs="12")
            v-switch(
              label="Available Online",
              v-model="online",
              color="secondary",
              dense
            )
          v-col(cols="6", xs="12")
            v-switch(
              label="Sync to Third Party",
              v-model="course.third_party",
              color="secondary",
              dense,
              hide-details
            )
          v-col(cols="6", xs="12")
            v-switch(
              label="Run out (86'd)",
              v-model="course.ooo",
              color="red",
              dense,
              hide-details
            )
        .red--text(v-for="(error, index) in errors", :key="index + 'index'") {{ error }}
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import CourseClassification from "/libs/json/CourseClassification.json";

export default {
  name: "CourseForm",
  data() {
    return {
      course: null,
      online: true,
      dialog: false,
      errors: [],
      loading: false,
      classification_items: JSON.parse(JSON.stringify(CourseClassification)),
    };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    EventBus.$on("edit-course", this.open);
  },
  destroyed() {
    EventBus.$off("edit-course", this.open);
  },
  methods: {
    async submit() {
      this.errors = [];
      this.course.controlled = !this.online;
      /// validate
      if (!this.biz || !this.course || !this.course._id) {
        this.errors.push("Invalid data");
        return;
      }
      if (!this.course.name) this.errors.push("Name is required");
      this.course.grp = this.course.grp?.trim();
      const found = this.biz.courses.find((o) => {
        return o._id !== this.course._id && o.name === this.course.name;
      });
      if (found) {
        this.errors.push(
          "Duplicate name. A course with the same name is found."
        );
      }
      if (this.errors?.length) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.biz._id, "courses._id": this.course._id },
        action: { $set: { "courses.$": this.course } },
      };
      const data = await this.$api.biz.update(params);
      this.$store.dispatch("setBiz", data);
      this.dialog = false;
      this.loading = false;
    },
    open(data) {
      if (!data || !data._id) return;
      this.course = JSON.parse(JSON.stringify(data));
      this.online = !this.course.controlled;
      this.errors = [];
      this.dialog = true;
    },
  },
};
</script>
