<template lang="pug">
v-card(flat, v-if="visible")
  v-toolbar(flat, dense)
    .subtitle-2 Service Items ({{ total | currency }})
  simple-table(v-if="service_items.length")
    thead
      tr
        th Name
        th.text-right Amount
    tbody
      tr(v-for="(item, index) in service_items", :key="index")
        td {{ item.name }}
        td.text-right {{ item.value | currency }}
</template>

<script>
import _ from "underscore";

export default {
  props: ["items"],
  computed: {
    service_items() {
      return _.chain(this.items)
        .map("service_items")
        .flatten()
        .groupBy("name")
        .map((list, name) => {
          const value = list.reduce((a, b) => a + b.value, 0);
          return { name, value };
        })
        .sortBy("name")
        .value();
    },
    total() {
      return this.service_items.reduce((a, b) => a + b.value, 0);
    },
    visible() {
      return this.service_items?.length;
    },
  },
};
</script>