import _ from 'underscore'

const state = {
  member: null, // current member
  members: [], // all members
  total_members: 0
}

const getters = {
  member: state => state.member,
  members: state => state.members,
  total_members: state => state.total_members
}

const actions = {
  setMember: ({ commit }, data) => { commit('setMember', data) },
  setMembers: ({ commit }, data) => { commit('setMembers', data) },
  updateMember: ({ commit }, data) => { commit('updateMember', data) },
  addMember: ({ commit }, data) => { commit('addMember', data) },
  addMembers: ({ commit }, data) => { commit('addMembers', data) },
  removeMember: ({ commit }, data) => { commit('removeMember', data) },
  setTotalMembers: ({ commit }, data) => { commit('setTotalMembers', data) }
}

const mutations = {
  setMember(state, data) {
    state.member = data
  },
  setMembers(state, data) {
    state.members = data
    state.members = _.sortBy(state.members, o => { return -o.created })
  },
  updateMember(state, data) {
    state.members = state.members.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addMember(state, data) {
    state.members.unshift(data)
    state.members = _.sortBy(state.members, o => { return -o.created })
  },
  addMembers(state, data) {
    state.members = _.union(state.members, data)
    state.members = _.sortBy(state.members, o => { return -o.created })
  },
  removeMember(state, data) {
    state.members = state.members.filter(o => o._id !== data._id)
  },
  setTotalMembers(state, data) {
    state.total_members = data
  }
}

export default { state, getters, actions, mutations }