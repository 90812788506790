<template lang="pug">
v-select(v-model="weekday", :items="items", :label="label")
</template>

<script>
export default {
  name: "WeekdaySelector",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "Weekday",
    },
  },
  data() {
    return {
      weekday: this.value,
      items: [
        { value: 0, text: "Sunday" },
        { value: 1, text: "Monday" },
        { value: 2, text: "Tuesday" },
        { value: 3, text: "Wednesday" },
        { value: 4, text: "Thursday" },
        { value: 5, text: "Friday" },
        { value: 6, text: "Saturday" },
      ],
    };
  },
  watch: {
    value() {
      this.weekday = this.value;
    },
    weekday() {
      this.$emit("input", this.weekday);
    },
  },
};
</script>
