<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    v-text-field(
      v-model="search",
      dense,
      hide-details,
      placeholder="Search by name or phone",
      clearable
    )
    v-spacer
    .link-box
      AddMember
      Download
      Upload(@refresh="$emit('refresh')")
  Table(:search="search", @show="show")
  Detail(ref="detail", @showActivity="showActivity")
  Activity(ref="activity")
</template>

<script>
import Detail from "../Detail/index";
import AddMember from "./AddMember";
import Download from "./Download";
import Table from "./Table";
import Upload from "./Upload";
import Activity from "/libs/components/Member/PointActivity";

export default {
  components: { AddMember, Download, Table, Detail, Activity, Upload },
  data() {
    return { search: "" };
  },
  methods: {
    show(item) {
      this.$refs.detail.open(item);
    },
    showActivity(member) {
      if (!member) return;
      this.$refs.activity.open(member._id);
    },
  },
};
</script>
