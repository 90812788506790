<template lang="pug">
div
  .orange-box
    v-container.py-8
      div Online Ordering
      div Made Free & Easy
  .grey-bkg
    v-container.py-5
      v-img(
        height="100",
        contain,
        src="https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/marketing%2FEmails%2FCreateBusiness%2Ficon_dollar.png?alt=media&token=77da660e-876a-4aba-85ac-4749c3fd2e4c"
      )
      .d-flex.flex-row.justify-center
        .text-block
          .mb-3 Selflane offers FREE online ordering to your business. That’s zero commissions, zero startup fee, and zero subscription fee!
          div You can be up and running today!
      a.dark(href="https://youtu.be/2nKZiLPinfc", target="_blank") Watch the setup video
  v-container.py-5
    v-img(
      height="100",
      contain,
      src="https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/marketing%2FEmails%2FCreateBusiness%2Ficon_app.png?alt=media&token=b32d080d-53d5-4e68-aef9-a98e56f06fed"
    )
    .d-flex.flex-row.justify-center
      .text-block Monitor your orders from the web or the free iPad app.
    a.dark(
      href="https://apps.apple.com/us/app/selflane-pos/id1180480166",
      target="_blank"
    ) Download the App

  v-container.py-5
    v-img(
      height="100",
      contain,
      src="https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/marketing%2FEmails%2FCreateBusiness%2Ficon_pos.png?alt=media&token=71dda500-127d-4cb3-940c-1520ebe5afbf"
    )
    .d-flex.flex-row.justify-center
      .text-block What’s the catch? We’re so confident you’ll love Selflane you will one day consider using Selflane Point of Sale at $29.99/mo/location. No obligations!
    router-link.dark(to="/info/pos") Learn More

  v-container.py-5
    v-img(
      height="100",
      contain,
      src="https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/marketing%2FEmails%2FCreateBusiness%2Ficon_restaurant.png?alt=media&token=d7af89ec-4a05-47db-a876-0606fd4fb36d"
    )
    .d-flex.flex-row.justify-center
      .text-block Selflane is leveling the playing field by bringing big restaurant technology to businesses of all sizes.
    router-link.dark(to="/") Get Started
  .text-center.my-5
    div Share this page to your friends.
    vue-goodshare-facebook(
      :page_url="currentURL",
      title_social="Facebook",
      has_icon
    )
  FAQ
  Guide
</template>

<script>
import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import FAQ from "./FAQ/index";
import Guide from "./Guides/index";

export default {
  components: { FAQ, Guide, VueGoodshareFacebook },
  computed: {
    currentURL() {
      const fullPath = this.$router.currentRoute.fullPath;
      return "https://bizs.selflane.com" + fullPath;
    },
  },
};
</script>

<style lang="sass" scoped>
.orange-box
  background-color: rgba(233, 97, 35, 1)
  color: rgba(255, 255, 255, 1)
  font-size: 42px
  font-weight: 700
  line-height: 56px
  text-align: center

.grey-bkg
  background-color: #f1f1f1

.text-block
  font-size: 22px
  padding: 32px 0px 32px 0px
  max-width: 600px
  text-align: center

.icon
  margin-left: auto
  margin-right: auto
  width: 130px
  height: 130px
  object-fit: contain

a
  display: block
  text-decoration: none
  border-radius: 6px

a.dark
  background-color: rgba(233, 97, 35, 1)
  color: white
  padding: 10px
  font-size: 22px
  font-weight: 600
  width: 260px
  max-width: 90%
  line-height: 48px
  text-align: center
  margin-left: auto
  margin-right: auto
</style>