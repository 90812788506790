<template lang="pug">
List
</template>

<script>
import List from "./List";

export default {
  components: { List },
};
</script>