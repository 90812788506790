<template lang="pug">
v-card.my-3(outlined)
  v-toolbar(flat, dense)
    .subtitle-2 Grouped Modifiers
    v-spacer
    IconBtn(@click="download()", title="CSV", icon="mdi-download")
  v-card-text.py-1
    span Stats on number of modifiers being used in selected period: {{ period }}
  v-data-table(
    :headers="headers",
    :items="items",
    sort-by="name",
    mobile-breakpoint="0",
    dense
  )
</template>

<script>
import CSV from "/libs/utils/CSV";
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  props: {
    report: { type: Array, default: null },
    period: { type: String, default: "" }, // YYYY-MM-DD to YYYY-MM-DD
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Quantity", value: "quantity", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    items() {
      if (!this.report) return [];
      const result = _.chain(this.report)
        .pluck("dishes")
        .flatten()
        .pluck("modifiers")
        .flatten()
        .groupBy("name")
        .map((items, name) => {
          const quantity = items.reduce((a, b) => a + b.quantity, 0);
          return { name, quantity };
        })
        .sortBy("name")
        .value();
      return result;
    },
  },
  methods: {
    download() {
      if (!this.items) return;
      const csv = new CSV();
      csv.addRow([this.biz.name]);
      csv.addRow(["Modifier Name", "Quantity"]);
      this.items.forEach((item) => {
        csv.addRow([item.name, item.quantity]);
      });
      csv.save(`modifiers-stats-${this.period}`);
    },
  },
};
</script>
