<template lang="pug">
Page(title="Settings", :doc="doc")
  .s-vstack
    v-col(align="center")
      ProfilePicture(:enterprise="enterprise")
    Profile
    Access
</template>

<script>
import ProfilePicture from "/libs/components/Enterprise/Setting/ProfilePicture";
import Profile from "./Profile";
import Access from "./Access";
import { mapGetters } from "vuex";

export default {
  components: { ProfilePicture, Profile, Access },
  data() {
    return {
      doc: "https://docs.selflane.com/enterprise/admin/settings/",
    };
  },
  computed: { ...mapGetters("entp", ["enterprise"]) },
};
</script>