<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 {{ index + 1 }}. {{ server.name }}
      span.ml-2(v-if="server.employeeID") ({{ server.employeeID }})
    v-spacer
    v-btn(
      @click="show",
      small,
      icon,
      color="secondary",
      v-if="server.serverId",
      :loading="loading",
      title="timecards"
    )
      v-icon(small) mdi-timetable
  simple-table(v-if="server.weekly && server.weekly.length")
    thead
      tr
        th Week
        th Role
        th.text-right REG Hrs
        th.text-right OT Hrs
        th.text-right(v-if="showWage") REG Pay
        th.text-right(v-if="showWage") OT Pay
        th.text-right(v-if="showWage") Total
    tbody(v-for="(week, windex) in server.weekly", :key="windex + 'week'")
      RoleLine(
        v-for="(role, rindex) in week.itemsByRole",
        :key="rindex + 'role'",
        :role="role",
        :week="week.week",
        :isDecimal="isDecimal",
        :showWage="showWage",
        @click="selectRole(role)"
      )
    tfoot
      tr
        th Total
        th
        th.text-right {{ server.regHours | hours(isDecimal) }}
        th.text-right {{ server.extHours | hours(isDecimal) }}
        th.text-right(v-if="showWage") {{ server.regCost | currency }}
        th.text-right(v-if="showWage") {{ server.extCost | currency }}
        th.text-right(v-if="showWage") {{ server.cost | currency }}
  List(:server="server", :showWage="showWage")
</template>

<script>
import List from "./List";
import RoleLine from "./RoleLine";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ServerReportCard",
  components: { List, RoleLine },
  props: ["server", "index", "isDecimal", "showWage"],
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    ...mapActions(["setPunchcards"]),
    async show() {
      const ids = _.chain(this.server.weekly)
        .pluck("itemsByRole")
        .flatten()
        .pluck("cards")
        .flatten()
        .value();
      this.loading = true;
      this.loadCards(ids);
      this.loading = false;
    },
    async selectRole(role) {
      if (!role?.cards) return;
      this.loadCards(role.cards);
    },
    async loadCards(ids) {
      if (!ids) return;
      const params = { criteria: { _id: { $in: ids } } };
      try {
        const result = await this.$api.punchcard.list(params);
        this.setPunchcards(result);
        EventBus.$emit("show-punchcard-dialog");
      } catch (e) {
        this.setPunchcards([]);
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.fixed-width
  width: 120px
  text-align: right
</style>