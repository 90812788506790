<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-list-item(outlined, small, color="secondary", v-on="on", @click="open")
      span Edit Price
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Edit Prices
      v-spacer
      v-btn(@click="save", color="secondary", :loading="loading") Save
    v-divider
    v-card-text
      v-row(dense, align="center")
        v-col(cols="6")
          v-select(v-model="value_type", :items="value_type_items")
        v-col(cols="3")
          v-text-field(
            v-model.number="value",
            single-line,
            :prefix="prefix",
            :suffix="suffix"
          )
        v-col(cols="3")
          v-btn(
            @click="applyChange",
            block,
            color="secondary",
            outlined,
            small
          ) Apply
    simple-table
      thead
        tr
          th Name
          th Price
          th New Price
      tbody
        tr(v-for="(item, index) in items", :key="index")
          th {{ item.name }}
          td {{ item.price | currency }}
          td
            PriceField(
              v-model.number="item.new_price",
              hide-details,
              single-line,
              dense
            )
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  props: {
    courseId: { type: String, default: null },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      items: [],
      value: 0,
      value_type: 0,
      value_type_items: [
        { text: "New Price", value: 0 },
        { text: "Adjust By Amount", value: 1 },
        { text: "Adjust By Percentage", value: 2 },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    prefix() {
      if (this.value_type == 0 || this.value_type == 1) return "$";
      return "";
    },
    suffix() {
      if (this.value_type == 0 || this.value_type == 1) return "";
      return "%";
    },
  },
  methods: {
    open() {
      this.items = _.chain(this.biz.dishes)
        .filter((o) => o.course == this.courseId)
        .map((o) => {
          return {
            _id: o._id,
            name: o.name,
            price: o.price,
            new_price: o.price,
          };
        })
        .value();
      if (this.items?.length) this.value = this.items[0].price;
    },
    applyChange() {
      _.each(this.items, (item) => {
        if (this.value_type == 0) item.new_price = this.value;
        if (this.value_type == 1) item.new_price = item.price + this.value;
        if (this.value_type == 2)
          item.new_price = Math.round(item.price * (100 + this.value)) / 100;
      });
    },
    async save() {
      if (!this.biz) return;
      this.loading = true;
      _.each(this.biz.dishes, (o) => {
        const found = this.items.find((item) => item._id == o._id);
        if (found) o.price = found.new_price;
      });
      try {
        const action = { $set: { dishes: this.biz.dishes } };
        const data = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", data);
        this.dialog = false;
        this.value = 0;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
