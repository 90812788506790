<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th {{ date }}
        th
        th.text-right {{ (total / 100) | currency }}
    tbody
      tr(v-for="(item, index) in batches", :key="index")
        th {{ item._id | time(timezone) }}
        td {{ item.tid }}
          span.ml-1(v-if="item.batchNum") ({{ item.batchNum }})
        td.text-right
          ul
            li(
              v-for="(amount, index) in item.amount",
              :key="index + 'index'",
              v-if="amount != 0"
            )
              span {{ index | amountType }} {{ (amount / 100) | currency }}
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
export default {
  filters: {
    amountType(index) {
      switch (index) {
        case 0:
          return "Credit  ";
        case 1:
          return "Debit   ";
        case 2:
          return "EBT     ";
        case 3:
          return "Gift    ";
        case 4:
          return "Loyalty ";
        case 5:
          return "Cash    ";
        case 6:
          return "Check   ";
        default:
          return "";
      }
    },
  },
  props: ["batches", "date"],
  computed: {
    ...mapGetters(["biz"]),
    timezone() {
      return this.biz?.address?.timezone;
    },
    total() {
      const data = _.chain(this.batches)
        .pluck("amount")
        .flatten()
        .reduce((memo, item) => memo + item, 0)
        .value();
      return data;
    },
  },
};
</script>

<style lang="sass" scoped>
ul
  list-style-type: none
</style>
