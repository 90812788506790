<template lang="pug">
v-card(v-if="biz", shaped)
  v-toolbar(flat, dense)
    .subtitle-2 Link
  v-divider
  v-list(dense, v-if="biz")
    v-list-item
      v-list-item-content
        v-list-item-title {{ url }}
        v-list-item-subtitle Add link to your Google Map and your own website
      v-list-item-action
        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn(icon, @click="copyURL", v-on="on")
              v-icon(small) mdi-content-copy
          span Copy to Clipboard
  v-card-text
    Facebook
</template>

<script>
import { mapGetters } from "vuex";
import Facebook from "./Facebook";

export default {
  components: { Facebook },
  computed: {
    ...mapGetters(["biz"]),
    url() {
      if (this.biz?.url) return "https://selflane.com/bizs/" + this.biz.url;
      else return "";
    },
  },
  methods: {
    copyURL() {
      this.$copyText(this.url);
    },
  },
};
</script>
