const state = {
  events: []
};

const getters = {
  events: state => state.events
};

const actions = {
  setEvents: ({ commit }, data) => { commit("setEvents", data); },
  addEvent: ({ commit }, data) => { commit('addEvent', data) },
  updateEvent: ({ commit }, data) => { commit("updateEvent", data); },
  removeEvent: ({ commit }, data) => { commit("removeEvent", data); },
};

const mutations = {
  setEvents(state, data) {
    state.events = data;
  },
  addEvent(state, data) {
    state.events.push(data)
  },
  updateEvent(state, data) {
    state.events = state.events.map(o => {
      return o._id === data._id ? data : o;
    });
  },
  removeEvent(state, data) {
    state.events = state.events.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };