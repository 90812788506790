<template lang="pug">
v-row.mb-3(dense, wrap)
  v-col(cols="12", sm="6", md="4")
    Orders(:items="items")
  v-col(cols="12", sm="6", md="4")
    Sales(:items="items")
  v-col(cols="12", sm="6", md="4")
    Payments(:items="items")
  Cards(:items="items")
  Discount(:items="items")
  v-col(cols="12")
    Hourly(:items="items")
</template>

<script>
import Orders from "/libs/components/Order/Performance/DaysReport/Orders";
import Sales from "/libs/components/Order/Performance/DaysReport/Sales";
import Payments from "/libs/components/Order/Performance/DaysReport/Payments";
import Cards from "/libs/components/Order/Performance/DaysReport/Cards";
import Discount from "/libs/components/Order/Performance/DaysReport/Discount";
import Hourly from "/libs/components/Order/Performance/DaysReport/Hourly";

export default {
  components: { Orders, Sales, Payments, Cards, Discount, Hourly },
  props: ["items"],
};
</script>