import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=545f50bc&scoped=true&lang=pug"
import script from "./Table.vue?vue&type=script&lang=js"
export * from "./Table.vue?vue&type=script&lang=js"
import style0 from "./Table.vue?vue&type=style&index=0&id=545f50bc&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545f50bc",
  null
  
)

export default component.exports