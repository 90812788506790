<template lang="pug">
v-btn-toggle.my-3(
  v-model="content",
  multiple,
  dense,
  color="secondary",
  @change="change"
)
  v-btn(
    small,
    v-for="(item, index) in items",
    :key="index",
    :value="item.value"
  ) {{ item.text }}
</template>
<script>
/// pick days in a week
/// input value should be [Int]

export default {
  name: "DaysPicker",
  props: {
    value: { type: Array, required: true },
  },
  data() {
    return {
      content: this.value || [],
      items: [
        { text: "Mon", value: 1 },
        { text: "Tue", value: 2 },
        { text: "Wed", value: 3 },
        { text: "Thu", value: 4 },
        { text: "Fri", value: 5 },
        { text: "Sat", value: 6 },
        { text: "Sun", value: 0 },
      ],
    };
  },
  watch: {
    value() {
      this.content = this.value || [];
    },
  },
  methods: {
    change() {
      this.content = this.content?.sort((a, b) => a - b);
      this.$emit("input", this.content);
    },
  },
};
</script>
