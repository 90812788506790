import Main from './index'
import Home from './Home/index'
import eGift from './eGift/index'

export default [
  {
    path: '/gift', component: Main, meta: { auth: true },
    children: [
      { path: '', redirect: 'home' },
      { path: 'home', component: Home },
      { path: 'egift', component: eGift }
    ]
  }
]