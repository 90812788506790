<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Tax Files
  Table(:items="items")
  v-card-text
    div The 1099-K file is based on all payouts deposit to your bank for a year. You should deduct the gross receipt tax you paid to the state and the tip to your staff as an expense.
</template>

<script>
import Table from "/libs/components/Payment/Tax/Table";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Table },
  data() {
    return { items: [] };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.biz) return;
      const params = { criteria: { biz: this.biz._id } };
      const { data } = await this.axios.post("/tax/taxfile/list", params);
      this.items = data;
    },
  },
};
</script>
