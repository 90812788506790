<template lang="pug">
div
  Prefixes
  Suffixes.my-5
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Prefixes from "./Prefixes";
import Suffixes from "./Suffixes";

export default {
  components: { Prefixes, Suffixes },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Classes", sortable: false },
        { text: "Items", sortable: false },
        { text: "Apply to", sortable: false },
        { text: "", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz", "matrixmodifier"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
    this.setMatrixModifier(null);
  },
  methods: {
    ...mapActions(["setMatrixModifier"]),
    async load() {
      if (!this.biz || !this.biz._id) return;
      const options = { criteria: { _id: this.biz._id } };
      const result = await this.$api.matrixModifier.retrieve(options);
      this.setMatrixModifier(result);
    },
  },
};
</script>
