<template lang="pug">
v-dialog(v-model="dialog", max-width="500", width="95%")
  v-card(v-if="member")
    v-card-title Edit Basic Info
    v-card-text
      v-form(@submit.prevent="submit")
        v-row(wrap, dense)
          v-col(cols="12", sm="6")
            v-text-field(label="First Name", v-model="name.first")
          v-col(cols="12", sm="6")
            v-text-field(label="Last Name", v-model="name.last")
          v-col(cols="12")
            v-text-field(label="Note", v-model="note")
        BirthdayPicker(v-model="bd")
        BirthdayPicker(v-model="anniv", label="Anniversary")
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      name: { first: "", last: "" },
      bd: { mm: 0, dd: 0 },
      anniv: { mm: 0, dd: 0 },
      note: "",
      loading: false,
    };
  },
  computed: { ...mapGetters(["member"]) },
  mounted() {
    EventBus.$on("edit-member-basic", this.edit);
  },
  destroyed() {
    EventBus.$off("edit-member-basic", this.edit);
  },
  methods: {
    ...mapActions(["updateMember", "setMember"]),
    edit() {
      if (this.member?.name) {
        this.name = {
          first: this.member?.name?.first || "",
          last: this.member?.name?.last || "",
        };
      }
      if (this.member?.bd) {
        this.bd = {
          mm: this.member?.bd?.mm || 0,
          dd: this.member?.bd?.dd || 0,
        };
      }
      if (this.member?.anniv) {
        this.anniv = {
          mm: this.member?.anniv?.mm || 0,
          dd: this.member?.anniv?.dd || 0,
        };
      }
      this.note = this.member?.note || "";
      this.dialog = true;
    },
    async submit() {
      if (this.member?._id == undefined) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.member._id },
        action: {
          $set: {
            name: this.name,
            bd: this.bd,
            anniv: this.anniv,
            note: this.note,
          },
        },
      };
      try {
        const result = await this.$api.member.update(params);
        this.updateMember(result);
        this.setMember(result);
        this.dialog = false;
        this.$toast.success("Saved");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>