<template lang="pug">
div
  Program.my-5
  MonthSelector.my-5(@select="load")
  v-row(wrap, v-if="result")
    v-col(cols="12", sm="6")
      Sales(:sales="result.sales")
    v-col(cols="12", sm="6")
      Redeems(:redeems="result.redeems")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Sales from "./Sales";
import Redeems from "./Redeems";
import Program from "./../Program/index";

export default {
  components: { Sales, Redeems, Program },
  data() {
    return {
      range: null,
      result: null,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load(month) {
      if (month) this.range = month.range;
      if (!this.range) return;
      if (!this.biz) return;
      this.result = null;
      const params = {
        bizId: this.biz._id,
        begin: this.range.begin,
        end: this.range.end,
      };
      const res = await this.axios.post("/orders/stats/gifts/monthly", params);
      this.result = res.data;
    },
  },
};
</script>
