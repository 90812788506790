<template lang="pug">
v-card(flat)
  v-toolbar.hidden-sm-and-up(flat, dense)
    .subtitle-2 {{ title }}
  v-divider
  v-list.pt-0.pb-10(dense, expand)
    PartnerItems(v-if="navState == 'partner'")
    BizItems(v-else-if="navState == 'biz'")
    EnterpriseItems(v-else-if="navState == 'enterprise'")
    CreateBizBtn(v-if="navState != 'create'")
    Logout
</template>

<script>
import BizItems from "./BizItems";
import PartnerItems from "./PartnerItems";
import EnterpriseItems from "./EnterpriseItems";
import CreateBizBtn from "./CreateBizBtn";
import Logout from "/libs/components/User/Logout";
import { mapGetters } from "vuex";

export default {
  components: { BizItems, PartnerItems, EnterpriseItems, CreateBizBtn, Logout },
  props: ["title"],
  computed: {
    ...mapGetters(["navState"]),
  },
  mounted() {
    const path = this.$router.history.current.path;
    if (path.startsWith("/partner"))
      this.$store.dispatch("setNavState", "partner");
    else if (path.startsWith("/enterprise"))
      this.$store.dispatch("setNavState", "enterprise");
    else this.$store.dispatch("setNavState", "biz");
  },
};
</script>
