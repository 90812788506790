<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-card
    v-card-title Discounts
    simple-table
      tbody
        AmountTableRow(
          v-for="(item, index) in items",
          :key="index",
          :name="getName(item)",
          :amount="item.amount",
          button,
          @click="showOrder(item.order)"
        )
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      dialog: false,
      items: [],
    };
  },
  mounted() {
    EventBus.$on("discount-dialog", this.open);
  },
  destroyed() {
    EventBus.$off("discount-dialog", this.open);
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.items = data.toSorted(
        (a, b) => parseInt(a.orderNumber) - parseInt(b.orderNumber)
      );
    },
    getName(item) {
      return `#${item.orderNumber} - ${item.name}`;
    },
    showOrder(id) {
      if (!id) return;
      EventBus.$emit("show-order-detail", id);
    },
  },
};
</script>