<template lang="pug">
v-btn(icon, small, @click="handleDelete()", :loading="loading")
  v-icon(small) mdi-delete
</template>

<script>
export default {
  props: {
    domain: { type: Object, required: true },
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleDelete() {
      confirm("Are you sure you want to delete this item?") && this.deleteOne();
    },
    async deleteOne() {
      this.loading = true;
      try {
        const domainId = this.domain?._id;
        const galleryId = this.item?._id;
        if (!domainId || !galleryId) {
          throw new Error("Invalid domain or gallery id.");
        }
        const result = await this.$api.b.domain.custom("deleteGalleryItem", {
          domainId,
          galleryId,
        });
        this.$toast.success("Item deleted.");
        this.$store.dispatch("setDomain", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>