<template lang="pug">
v-card(flat, v-if="bizservice")
  v-toolbar(flat, dense)
    .subtitle-2 7Shifts Integration
  v-img.my-3(:src="icon", height="50px", contain)
  Integrated(v-if="established", ref="integrated")
  StartForm(v-else)
</template>

<script>
import { mapGetters } from "vuex";
import Integrated from "./Integrated";
import StartForm from "./StartForm";

export default {
  components: { Integrated, StartForm },
  data() {
    return {
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2F7shifts_logo.svg?alt=media&token=22a7b29e-301f-42d3-9e65-915c21190e54",
    };
  },
  computed: {
    ...mapGetters(["bizservice"]),
    status() {
      return this.bizservice?.apps?.find((o) => o.name === "7shifts")?.status;
    },
    established() {
      return this.status === "established";
    },
  },
};
</script>