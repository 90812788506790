<template lang="pug">
IconBtn(@click="download", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";
import _ from "underscore";

export default {
  props: ["result"],
  methods: {
    download() {
      const datetime = this.$options.filters.datetime;
      const currency = this.$options.filters.currency;

      const csv = new CSV();
      csv.addRow(["Time", "Action", "Amount", "Authorizer", "To", "Note"]);
      _.each(this.result, (o) => {
        csv.addRow([
          datetime(o.created, "MMM D, h:mm A"),
          o.action,
          currency(o.amount),
          o.staff.name,
          o.associate.name,
          o.note,
        ]);
      });
      csv.save("pay_in_out");
    },
  },
};
</script>
