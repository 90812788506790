<template lang="pug">
div
  .link-box.mb-3
    v-text-field(
      v-model="search",
      label="Search for an employee",
      solo,
      flat,
      dense,
      outlined,
      hide-details,
      clearable,
      color="secondary",
      append-icon="mdi-magnify"
    )
    .select-box
      v-select(
        v-model="biz_sel",
        :items="biz_items",
        label="Business",
        solo,
        flat,
        dense,
        outlined,
        hide-details,
        color="secondary",
        item-color="secondary"
      )
    .select-box
      v-select(
        v-model="role",
        :items="roles",
        label="Role",
        solo,
        flat,
        dense,
        outlined,
        hide-details,
        color="secondary",
        item-color="secondary"
      )
    .select-box
      v-select(
        v-model="status",
        :items="status_items",
        label="Status",
        solo,
        flat,
        dense,
        outlined,
        hide-details,
        color="secondary",
        item-color="secondary"
      )
    .select-box
      v-select(
        v-model="access",
        :items="access_items",
        label="Access",
        solo,
        flat,
        dense,
        outlined,
        hide-details,
        color="secondary",
        item-color="secondary"
      )
    v-chip {{ count }}
  v-card.mb-3(flat)
    v-list.py-0(two-line, dense)
      div(v-for="(item, index) in present", :key="index")
        v-divider(v-if="index != 0")
        Cell(
          :item="item",
          :has7Shifts="has7Shifts",
          @click="$emit('edit', item)"
        )
  v-pagination(
    v-model="page",
    :length="pages",
    circle,
    flat,
    color="secondary",
    :total-visible="5",
    v-if="pages > 1"
  )
</template>

<script>
import Cell from "./Cell";

export default {
  components: { Cell },
  props: {
    items: { type: Array, default: () => [] },
    bizs: { type: Array, default: () => [] },
    showWage: { type: Boolean, default: true },
    has7Shifts: { type: Boolean, default: false },
  },
  data() {
    return {
      biz_sel: this.$store.getters.biz?._id,
      role: "All roles",
      search: "",
      page: 1,
      itemsPerPage: 15,
      status: true,
      status_items: [
        { text: "Active", value: true },
        { text: "Deactivated", value: false },
      ],
      access: "-",
      access_items: [
        { text: "No specified access", value: "-" },
        { text: "Advanced Access", value: "a" },
        { text: "Full Access", value: "f" },
      ],
    };
  },
  computed: {
    count() {
      const length = this.items.filter((o) => o.status).length;
      if (this.status) {
        if (this.filtered.length === length) return length;
        return `${this.filtered.length} of ${length}`;
      }
      return this.filtered.length;
    },
    // items can be filtered by search, role, status, and access level
    // search text is case-insensitive and would match name, email, and phone
    filtered() {
      return this.items
        .filter((o) => {
          if (this.search) {
            const search = this.search.toLowerCase();
            const found =
              o.first_name?.toLowerCase()?.includes(search) ||
              o.last_name?.toLowerCase()?.includes(search) ||
              o.name?.toLowerCase()?.includes(search) ||
              o.email?.toLowerCase()?.includes(search) ||
              o.phone?.toLowerCase()?.includes(search);
            if (!found) return false;
          }
          if (this.biz_sel) {
            if (!o.bizs.includes(this.biz_sel)) return false;
          }
          if (
            this.role !== "All roles" &&
            !o.roles.some((r) => r.name === this.role)
          ) {
            return false;
          }
          if (this.access == "a" && !o.isAdmin) return false;
          if (this.access == "f" && !o.isMaster) return false;
          return this.status == o.status;
        })
        .sort((a, b) => {
          return a.first_name.localeCompare(b.first_name);
        });
    },
    // items to present on the current page
    present() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filtered.slice(start, end);
    },
    // number of pages
    pages() {
      return Math.ceil(this.filtered.length / this.itemsPerPage);
    },
    roles() {
      const names = this.items.flatMap((o) => o.roles).map((o) => o.name);
      let unique = [...new Set(names)];
      unique.sort();
      return ["All roles", ...unique];
    },
    biz_items() {
      // add a -All- option if there are multiple businesses
      const data = this.bizs
        .map((o) => ({
          text: o.short_name || o.name,
          value: o._id,
        }))
        .toSorted((a, b) => a.text.localeCompare(b.text));
      if (data.length > 1) {
        data.unshift({ text: "- All -", value: null });
      }
      return data;
    },
  },
};
</script>

<style lang="sass" scoped>
.select-box
  max-width: 160px
</style>