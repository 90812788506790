<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on", @click="open()")
  v-card(v-if="delivery && zone")
    v-toolbar(flat, dense)
      .subtitle-2 Self-Delivery Settings
    v-card-text
      v-form(@submit.prevent="submit")
        v-switch(
          v-model="delivery.status",
          color="secondary",
          label="Delivery",
          dense
        )
        div(v-if="delivery.status")
          v-row(dense, wrap)
            v-col(cols="6")
              v-select(
                v-model="delivery.beforehand",
                :items="items",
                label="Lead time",
                menu-props="auto"
              )
            v-col(cols="6")
              v-text-field(label="Fee", v-model="delivery.price", prefix="$")
            v-col(cols="6")
              v-text-field(
                label="Minumum Purchase",
                v-model="delivery.minimum_purchase",
                prefix="$"
              )
          v-row(dense, wrap)
            v-col(cols="6")
              .d-flex.flex-align.align-center
                v-switch(
                  v-model="zone.status",
                  color="secondary",
                  label="Use Zone"
                )
                v-spacer
                v-btn(
                  icon,
                  @click="resetPolygon()",
                  v-if="zoneStatus",
                  title="Reset Zone Polygon"
                )
                  v-icon mdi-refresh
            v-col(cols="6")
              v-text-field(
                :label="label",
                v-model="delivery.radius",
                v-if="!zoneStatus"
              )
        #map(ref="map")
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
        .mt-2 Turn this on if your business does self-deliveries. You can charge a flat fee for the delivery.
</template>

<script>
import { mapGetters } from "vuex";
import TimeItems from "@/components/JSON/TimeItems.json";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      delivery: null,
      zone: null,
      items: TimeItems,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    isCanada() {
      return this.biz?.address?.country == "CA";
    },
    label() {
      if (this.isCanada) return "Max Radius (km)";
      return "Max Radius (miles)";
    },
    center() {
      return this.biz.address.geometry;
    },
    distance() {
      if (!this.delivery) return 0;
      if (this.isCanada) return this.delivery.radius * 1000;
      return this.delivery.radius * 1609.34;
    },
    deliveryStatus() {
      if (this.delivery) return this.delivery.status;
      return false;
    },
    zoneStatus() {
      if (this.zone) return this.zone.status;
      return false;
    },
  },
  watch: {
    deliveryStatus() {
      this.drawPolygon();
    },
    zoneStatus() {
      this.drawPolygon();
    },
    distance() {
      this.drawPolygon();
    },
  },
  methods: {
    async open() {
      if (!this.biz) return;
      this.delivery = JSON.parse(JSON.stringify(this.biz.orderType.delivery));
      if (this.biz.zone) this.zone = JSON.parse(JSON.stringify(this.biz.zone));
      if (!this.zone) this.zone = { status: false, detail: "", polygon: [] };

      const initMap = this.initMap;
      await this.wait(500);
      initMap();
    },
    async wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    updatePolygon() {
      if (!this.zoneStatus) return;
      this.zone.polygon = [];
      this.polygon.getPath().forEach((o) => {
        this.zone.polygon.push({ lat: o.lat(), lng: o.lng() });
      });
    },
    resetPolygon() {
      this.zone.polygon = [];
      this.drawPolygon();
    },
    async submit() {
      this.updatePolygon();
      if (!this.biz) return;
      if (this.delivery?.status) {
        if (this.delivery.price < 0 || this.delivery.price > 100) {
          alert("Delivery price should be between $0 and $100");
          return;
        }
        if (
          this.delivery.minimum_purchase < 10 ||
          this.delivery.minimum_purchase > 50
        ) {
          alert("Minimum purchase should be between $10 and $50");
          return;
        }
        if (!this.zone.status) {
          if (this.delivery.radius < 1 || this.delivery.radius > 20) {
            alert("Max radius should be between 1 and 20");
            return;
          }
        }
      }
      this.loading = true;
      try {
        const action = {
          $set: { "orderType.delivery": this.delivery, zone: this.zone },
        };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.$toast.success("Saved");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update");
      }
      this.loading = false;
    },
    drawPolygon() {
      if (!this.zone || !this.zone.polygon) return;
      if (this.polygon) this.polygon.setMap(null);
      if (this.circle) this.circle.setMap(null);
      if (!this.delivery.status) return;
      if (this.zoneStatus) {
        // eslint-disable-next-line no-undef
        this.polygon = new google.maps.Polygon({
          path: this.zone.polygon,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          draggable: true,
          editable: true,
          map: this.map,
        });
        return;
      }
      // eslint-disable-next-line no-undef
      this.circle = new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: this.map,
        center: this.center,
        radius: this.distance,
      });
    },
    addZone(geoPoint) {
      if (!this.zone) return;
      if (!this.zone.polygon) this.zone.polygon = [];
      this.zone.status = true;
      this.zone.polygon.push({ lat: geoPoint.lat(), lng: geoPoint.lng() });
      this.drawPolygon();
    },
    initMap() {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: this.center,
        zoom: 12,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      });
      this.map.addListener("click", (data) => {
        if (data?.latLng) this.addZone(data.latLng);
      });
      // eslint-disable-next-line no-undef
      new google.maps.Marker({ position: this.center, map: this.map });
      this.drawPolygon();
    },
  },
};
</script>

<style lang="sass" scoped>
#map
  height: 300px
  width: 100%
  background: gray
  margin-bottom: 10px
</style>