<template lang="pug">
v-dialog(v-model="dialog", width="450")
  v-card(v-if="shift")
    v-toolbar(flat, dense)
      .subtitle-2 {{ title }} Shift
    v-divider
    v-card-text
      v-form.pa-3(@submit.prevent="submit")
        PersonPicker(v-model="shift.server", :disabled="!!shift._id")
        DaysPicker(v-model="shift.days")
        v-switch(
          v-model="shift.doesEnd",
          label="Has end date",
          color="secondary"
        )
        v-row
          v-col(cols="6")
            DateFieldPicker(label="Start Date", v-model="start")
          v-col(cols="6", v-if="shift.doesEnd")
            DateFieldPicker(label="End Date", v-model="end")
        v-row
          v-col(cols="6")
            TimeSelector(v-model="shift.startHH", title="Start")
          v-col(cols="6")
            TimeSelector(v-model="shift.endHH", title="End")
        v-row
          v-col(cols="6")
            v-select(
              :items="frequencies",
              v-model="freq",
              label="Repeat",
              dense
            )
          v-col(cols="6")
            v-select(:items="roles", v-model="shift.role", label="Role", dense)
        v-btn(block, type="submit", color="secondary", :loading="loading") save
      .red--text(v-for="(e, index) in errors", :key="index") {{ e }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import PersonPicker from "/libs/components/HR/Person/Picker";

export default {
  components: { PersonPicker },
  props: ["input"],
  data() {
    return {
      dialog: false,
      shift: null,
      loading: false,
      errors: [],
      roles: [
        "Bartender",
        "Busser",
        "Cashier",
        "Chef",
        "Cook",
        "Dishwasher",
        "Driver",
        "Host",
        "Manager",
        "Office",
        "Server",
      ],
      frequencies: ["Weekly", "Biweekly", "Triweekly", "Quadweekly"],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      if (this.input && this.input._id) return "Edit";
      else return "Create";
    },
    freq: {
      get: function () {
        if (!this.shift) return "";
        const frequency = this.shift.frequency;
        if (!frequency?.n || !frequency?.unit) return "";
        if (frequency.n === 1) return "Weekly";
        if (frequency.n === 2) return "Biweekly";
        if (frequency.n === 3) return "Triweekly";
        if (frequency.n === 4) return "Quadweekly";
        return "";
      },
      set: function (val) {
        this.shift.frequency.unit = "week";
        switch (val) {
          case "Weekly":
            this.shift.frequency.n = 1;
            break;
          case "Biweekly":
            this.shift.frequency.n = 2;
            break;
          case "Triweekly":
            this.shift.frequency.n = 3;
            break;
          case "Quadweekly":
            this.shift.frequency.n = 4;
            break;
          default:
            break;
        }
      },
    },
    start: {
      get: function () {
        if (!this.shift) return "";
        return moment(String(this.shift.startDay), "YYYYMMDD").format(
          "YYYY-MM-DD"
        );
      },
      set: function (val) {
        this.shift.startDay = parseInt(moment(val).format("YYYYMMDD"));
      },
    },
    end: {
      get: function () {
        if (!this.shift) return "";
        return moment(String(this.shift.endDay), "YYYYMMDD").format(
          "YYYY-MM-DD"
        );
      },
      set: function (val) {
        this.shift.endDay = parseInt(moment(val).format("YYYYMMDD"));
      },
    },
  },
  watch: {
    input(val) {
      if (val) {
        if (!val.endDay) val.endDay = parseInt(moment().format("YYYYMMDD"));
        this.shift = val;
        this.dialog = true;
      }
    },
    dialog(val) {
      if (!val) this.$emit("close");
    },
  },
  mounted() {
    this.loadRoles();
  },
  methods: {
    ...mapActions(["updateServershift", "addServershift"]),
    async loadRoles() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      try {
        const { data } = await this.axios.post("/pos/getRoles", params);
        if (data?.roles) this.roles = data.roles;
      } catch (e) {
        //
      }
    },
    async submit() {
      if (!this.biz) return;
      this.errors = [];
      if (!this.shift.biz) this.shift.biz = this.biz._id;
      if (!this.shift.server) this.errors.push("Please select a server");
      if (!this.shift.role) this.errors.push("Please select a role");
      if (!this.shift.days || this.shift.days.length === 0)
        this.errors.push("Please select days");
      if (!this.shift.startHH) this.errors.push("Please select a start time");
      if (!this.shift.endHH) this.errors.push("Please select a end time");
      if (
        this.shift.startHH &&
        this.shift.endHH &&
        this.shift.endHH <= this.shift.startHH
      )
        this.errors.push("End time should be later than start time");
      if (this.shift.doesEnd && !this.shift.endDay)
        this.errors.push("Please select a end date");
      if (this.errors.length > 0) return;
      this.loading = true;
      try {
        if (this.shift._id) {
          const params = {
            criteria: { _id: this.shift._id },
            action: { $set: this.shift },
          };
          const result = await this.$api.serverShift.update(params);
          this.updateServershift(result);
        } else {
          const result = await this.$api.serverShift.create(this.shift);
          this.addServershift(result);
        }
        this.dialog = false;
        this.$emit("close");
        EventBus.$emit("edit-server-shift");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
