<template lang="pug">
Page(title="Balance", :doc="doc")
  .d-flex.flex-row.align-center
    YearSelector(@select="selectYear")
    v-btn-toggle.ml-3(v-model="selection", @change="load", color="secondary")
      v-btn(:value="1", small) 13 4-week
      v-btn(:value="0", small) Monthly
  Charts.my-3(:items="items", :year="year")
  Table.my-3(:items="items", :loading="loading")
  Download.my-5(:items="items", :year="year")
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Download from "./Download";
import Charts from "./Charts";
import moment from "moment-timezone";
import Table from "./Table";

export default {
  components: { Download, Charts, Table },
  data() {
    return {
      sales: null,
      expenses: null,
      payroll: null,
      loading: false,
      year: null,
      selection: 1, /// 0: monthly, 1: 13-4 week
      doc: "https://docs.selflane.com/biz/performance/balance.html",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    items() {
      const month = this.$options.filters.month;
      if (this.selection === 0) {
        const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        return months.map((m) => {
          const sales = this.sales?.find((item) => item.month === m);
          const orderValue = sales?.sum || 0;
          const nguests = sales?.nguests || 0;
          const expenseValue =
            this.expenses?.find((item) => item.month === m)?.sum || 0;
          const punchcard = this.payroll?.find((item) => item.month === m);
          const payrollValue = punchcard?.sum || 0;
          const balance = orderValue - expenseValue - payrollValue;
          return {
            period: month(m, true),
            orderValue: Math.round(orderValue * 100) / 100,
            nguests,
            expenseValue: Math.round(expenseValue * 100) / 100,
            payrollValue: Math.round(payrollValue * 100) / 100,
            balance: Math.round(balance * 100) / 100,
          };
        });
      }
      // 13-4 week
      const begin = moment([this.year]).startOf("isoWeek").subtract(1, "days");
      const indices = [...Array(13).keys()];
      return indices.map((p) => {
        const sales = this.sales?.find((item) => item.period === p);
        const orderValue = sales?.sum || 0;
        const nguests = sales?.nguests || 0;
        const expense = this.expenses?.find((item) => item.period === p);
        const expenseValue = expense?.sum || 0;
        const punchcard = this.payroll?.find((item) => item.period === p);
        const payrollValue = punchcard?.sum || 0;
        const period = begin.add(4, "weeks").format("MMM D");
        const balance = orderValue - expenseValue - payrollValue;
        return {
          period,
          orderValue: Math.round(orderValue * 100) / 100,
          nguests,
          expenseValue: Math.round(expenseValue * 100) / 100,
          payrollValue: Math.round(payrollValue * 100) / 100,
          balance: Math.round(balance * 100) / 100,
        };
      });
    },
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async getSales() {
      if (!this.biz || !this.biz._id) return;
      const params = { bizId: this.biz._id, year: this.year };
      try {
        if (this.selection == 0) {
          this.sales = await this.$api.order.custom("statement", params);
        } else {
          this.sales = await this.$api.order.custom("statement13", params);
        }
      } catch (e) {
        //
      }
    },
    async getExpenses() {
      if (!this.biz || !this.biz._id) return;
      const params = { bizId: this.biz._id, year: this.year };
      this.loading = true;
      try {
        if (this.selection == 0) {
          this.expenses = await this.$api.expense.custom("statement", params);
        } else {
          this.expenses = await this.$api.expense.custom("statement13", params);
        }
      } catch (e) {
        //
      }
    },
    async getPayroll() {
      if (!this.biz || !this.biz._id) return;
      const params = { bizId: this.biz._id, year: this.year };
      this.loading = true;
      try {
        if (this.selection == 0) {
          this.payroll = await this.$api.punchcard.custom("statement", params);
        } else {
          this.payroll = await this.$api.punchcard.custom(
            "statement13",
            params
          );
        }
      } catch (e) {
        //
      }
    },
    selectYear(year) {
      this.year = year;
      this.load();
    },
    async load() {
      this.loading = true;
      await this.getSales();
      await this.getExpenses();
      await this.getPayroll();
      this.loading = false;
    },
  },
};
</script>
