<template lang="pug">
PartnerPage(title="CRM", :doc="doc")
</template>

<script>
export default {
  data() {
    return {
      doc: "https://docs.selflane.com/partner/crm/",
    };
  },
};
</script>