<template lang="pug">
v-layout(align-center)
  v-flex
    span(
      v-if="memberpromo && memberpromo.description && memberpromo.description.length"
    ) {{ memberpromo.description }}
    span(v-else) [Please provide a description description here]
  v-btn(icon, small, @click="edit()")
    v-icon(color="secondary", small) mdi-pencil
  v-dialog(width="600", v-model="dialog")
    v-card
      v-toolbar(dense, flat) Promotion description
      v-card-text
        span Please include information regarding discount, coupon the customer will receive.
        v-form
          v-textarea(
            v-model="description",
            label="Description",
            required,
            autofocus
          )
          v-btn(
            block,
            color="secondary",
            :disabled="!description.length",
            @click="save()"
          ) Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      description: "",
    };
  },
  computed: {
    ...mapGetters(["memberpromo"]),
  },
  methods: {
    ...mapActions(["setMemberPromo"]),
    edit() {
      this.description = this.memberpromo.description || "";
      this.dialog = true;
    },
    async save() {
      if (!this.memberpromo || !this.description.length) return;
      const params = {
        criteria: { _id: this.memberpromo._id },
        action: { $set: { description: this.description } },
      };
      const result = await this.$api.memberPromo.update(params);
      this.setMemberPromo(result);
      this.dialog = false;
    },
  },
};
</script>
