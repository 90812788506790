<template lang="pug">
Activities(title="Point Earn Activities", :items="result", unit="pts")
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Activities from "./Activities";

export default {
  components: { Activities },
  data() {
    return { result: null };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      try {
        const url = "/member/dashboard/sales";
        const { data } = await this.axios.post(url, params);
        this.result = data;
      } catch (e) {
        //
      }
    },
  },
};
</script>
