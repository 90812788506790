<template lang="pug">
v-dialog(v-model="dialog", max-width="550", @keydown.esc="dialog = false")
  v-card
    v-card-title
    v-card-text
      v-form(@submit.prevent="submit")
        v-select(
          :items="roles",
          v-model="serverchecklist.roles",
          label="Role",
          multiple
        )
        simple-table
          tbody
            tr(
              v-for="(item, index) in serverchecklist.checklist",
              :key="index"
            )
              td
                v-text-field(
                  v-model="serverchecklist.checklist[index]",
                  single-line,
                  label="Checklist Item",
                  hide-details
                )
              td.text-right
                v-btn(
                  icon,
                  small,
                  @click.stop="moveItemUp(index)",
                  tabindex="-1"
                )
                  v-icon(color="grey darken-2") mdi-menu-up
                v-btn(
                  icon,
                  small,
                  @click.stop="moveItemDown(index)",
                  tabindex="-1"
                )
                  v-icon(color="grey darken-2") mdi-menu-down
                v-btn(
                  icon,
                  small,
                  @click.stop="removeItem(index)",
                  tabindex="-1"
                )
                  v-icon(color="red lighten-1") mdi-close
                v-btn(
                  icon,
                  small,
                  @click.stop="addItem()",
                  tabindex="-1",
                  v-if="index == serverchecklist.checklist.length - 1"
                )
                  v-icon(color="secondary") mdi-plus
        v-btn.mt-5(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ServerChecklistForm",
  data() {
    return {
      dialog: false,
      errors: [],
      serverchecklist: {
        biz: undefined,
        roles: [],
        checklist: [],
      },
      roles: [
        "Bartender",
        "Busser",
        "Cashier",
        "Chef",
        "Cook",
        "Dishwasher",
        "Driver",
        "Host",
        "Manager",
        "Office",
        "Server",
      ],
      loading: false,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.loadRoles();
    EventBus.$on("edit-server-checklist", this.edit);
  },
  destroyed() {
    EventBus.$off("edit-server-checklist", this.edit);
  },
  methods: {
    ...mapActions(["updateServerChecklist"]),
    async loadRoles() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      try {
        const result = await this.$api.pos.custom("getRoles", params);
        if (result?.roles) this.roles = result.roles;
      } catch (e) {
        //
      }
    },
    edit(input) {
      if (input) this.serverchecklist = JSON.parse(JSON.stringify(input));
      else this.serverchecklist = { biz: undefined, roles: [], checklist: [] };
      if (this.serverchecklist.checklist.length == 0)
        this.serverchecklist.checklist = [""];
      this.dialog = true;
      this.errors = [];
    },
    moveItemUp(index) {
      if (!this.serverchecklist.checklist) return;
      if (index < this.serverchecklist.checklist.length && index > 0) {
        const item = this.serverchecklist.checklist[index];
        this.serverchecklist.checklist.splice(index, 1);
        this.serverchecklist.checklist.splice(index - 1, 0, item);
      }
    },
    moveItemDown(index) {
      if (!this.serverchecklist.checklist) return;
      if (index < this.serverchecklist.checklist.length - 1 && index >= 0) {
        const item = this.serverchecklist.checklist[index];
        this.serverchecklist.checklist.splice(index, 1);
        this.serverchecklist.checklist.splice(index + 1, 0, item);
      }
    },
    removeItem(index) {
      if (!this.serverchecklist.checklist) return;
      if (index < this.serverchecklist.checklist.length && index >= 0) {
        this.serverchecklist.checklist.splice(index, 1);
      }
    },
    addItem() {
      this.serverchecklist.checklist.push("");
    },
    async submit() {
      this.errors = [];
      /// validate
      if (!this.serverchecklist || !this.biz) return;
      this.serverchecklist.biz = this.biz._id;
      this.serverchecklist.checklist = this.serverchecklist.checklist.filter(
        (o) => o.trim()
      );
      if (!this.serverchecklist.roles?.length) this.errors.push("Select Roles");
      if (!this.serverchecklist.checklist?.length) {
        this.errors.push("Empty Checklist");
        this.serverchecklist.checklist = [""];
      }
      if (this.errors.length > 0) return;
      this.loading = true;
      try {
        if (this.serverchecklist?._id) {
          const params = {
            criteria: { _id: this.serverchecklist._id },
            action: { $set: this.serverchecklist },
          };
          const result = await this.$api.serverChecklist.update(params);
          this.updateServerChecklist(result);
        } else {
          const result = await this.$api.serverChecklist.create(
            this.serverchecklist
          );
          this.updateServerChecklist(result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
