<template lang="pug">
v-card(v-if="data", flat)
  v-toolbar(flat, dense)
    .subtitle-2 Breakdown by Server
  v-data-table(:headers="headers", :items="data", dense, mobile-breakpoint="0")
    template(v-slot:item.net="{ item }")
      span {{ item.net | currency }}
    template(v-slot:item.total="{ item }")
      span {{ item.total | currency }}
    template(v-slot:item.balance="{ item }")
      span {{ item.balance | currency }}
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "#Orders", value: "count" },
        { text: "Net Sales", value: "net" },
        { text: "Gross Sales", value: "total" },
        { text: "Cash/Tip Balance", value: "balance", align: "end" },
      ],
    };
  },
};
</script>
