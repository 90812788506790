<template lang="pug">
div(v-if="!loading")
  .d-flex.flex-row.align-center.mb-3
    v-icon.mr-2 mdi-wallet-membership
    .subtitle-2 Member Program
      span.ml-1(v-if="bizservice") | {{ bizservice.member | memberLevel }}
  SharedBizs(:program="program")
  div(v-if="bizservice && bizservice.member == 1")
    span Do you want to upgrade to Active Marketing Program
    v-btn.ml-1(small, rounded, color="secondary", @click="upgrade(1)") Yes
  div(v-if="!bizservice || bizservice.member == 0")
    span Do you want to turn on the Membership Program?
    v-btn.ml-1(small, rounded, color="secondary", @click="upgrade(0)") Yes
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import SharedBizs from "./SharedBizs";

export default {
  filters: {
    memberLevel(val) {
      if (!val) return "Off";
      if (val == 1) return "Basic Program";
      if (val == 2) return "Active Marketing Program";
      return "Unknown";
    },
  },
  components: { SharedBizs },
  data() {
    return {
      loading: false,
      program: null,
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fmembership.svg?alt=media&token=1a48e975-3e0a-4aab-be90-3c4ac285f761",
    };
  },
  computed: {
    ...mapGetters(["biz", "bizservice"]),
    isSharedProgram() {
      return this.program?.bizs && this.program.bizs.length > 1;
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.biz) return;
      this.$store.dispatch("setBizService", null);
      this.loading = true;
      const params = { criteria: { biz: this.biz._id } };
      const result = await this.$api.service.account.retrieve(params);
      this.$store.dispatch("setBizService", result);
      await this.loadProgram();
      this.loading = false;
    },
    async loadProgram() {
      if (!this.biz) return;
      const params = { criteria: { bizs: this.biz._id } };
      try {
        this.program = await this.$api.memberProgram.retrieve(params);
      } catch (e) {
        this.program = null;
      }
    },
    upgrade(member) {
      EventBus.$emit("upgrade-member", this.biz._id, member);
    },
  },
};
</script>
