<template lang="pug">
v-card-text
  .my-5
    p <b>7shifts</b> is the complete team management platform for restaurants. 7shifts provides tools that help restaurateurs make more profitable decisions, improve team retention, and get operations in order.
    p You can learn more about the integration at the
      a.ml-1(
        href="https://docs.selflane.com/biz/employee/seven_shifts.html",
        target="_blank"
      ) documentation page.
  v-dialog(v-model="dialog", max-width="500px")
    template(v-slot:activator="{ on }")
      v-btn(color="secondary", text, v-on="on", block, @click="open") Get Started
    v-card(:loading="listing")
      v-card-title 7Shifts Integration
      v-card-text
        Stepper(ref="stepper", @done="done")
</template>

<script>
import Stepper from "./Stepper";

export default {
  components: { Stepper },
  data() {
    return {
      dialog: false,
      message: "",
      requests: [],
      listing: false,
      loading: false,
    };
  },
  methods: {
    async open() {
      await this.wait(); // wait for the component to be mounted
      this.$refs.stepper.open();
    },
    async wait() {
      await new Promise((resolve) => setTimeout(resolve, 200));
    },
    async done() {
      this.dialog = false;
      this.$emit("updated");
    },
  },
};
</script>