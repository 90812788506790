<template lang="pug">
.link-box.link-box-right
  a(
    v-for="(item, index) in social_items",
    :key="index + 'social'",
    :href="item.href",
    target="_blank"
  )
    img.icon-size(:src="item.icon")
  a(
    v-for="(item, index) in appLinks",
    :key="index + 'app'",
    :href="item.url",
    target="_blank"
  )
    img.app-link(:src="item.icon")
</template>

<script>
const facebook_icon =
  "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Ffacebook_icon.svg?alt=media&token=61993d1d-4249-47f6-8156-dd988c265c61";
const instagram_icon =
  "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fins_icon.svg?alt=media&token=2e1b8991-064e-41f8-b6b6-ccaa6903d264";
const youtube_icon =
  "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fyoutube_icon.svg?alt=media&token=b1008553-ccc4-456f-bb89-b5e60993437f";
const appstore_icon =
  "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fappstore_icon%402x.png?alt=media&token=5332b552-31ac-4bc1-9c5b-800ca64e5d57";
export default {
  data() {
    return {
      social_items: [
        { href: "https://www.facebook.com/selflane", icon: facebook_icon },
        {
          href: "https://www.instagram.com/selflane4foodies",
          icon: instagram_icon,
        },
        {
          href: "https://www.youtube.com/channel/UC9kElo6N2PB2_YYe45avY1Q",
          icon: youtube_icon,
        },
      ],
      appLinks: [
        {
          url: "https://itunes.apple.com/us/app/selflane/id1142403428?mt=8",
          icon: appstore_icon,
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
img.app-link
  object-fit: contain
  height: 30px
  width: fit-content
</style>