<template lang="pug">
div
  Title
  MonthSelector.my-3(@select="load")
  Table.my-5(:result="result", :loading="loading")
  .d-flex.flex-row
    Download.mr-3(:result="result", :range="range")
    .mr-3
      NEOrders(:range="range")
    OnlineOrders(:range="range")
</template>

<script>
import { mapGetters } from "vuex";
import Title from "./Title";
import Table from "./Table";
import Download from "./Download";
import NEOrders from "@/components/Order/NEOrders";
import OnlineOrders from "@/components/Order/OnlineOrders";

export default {
  components: { Title, Table, Download, NEOrders, OnlineOrders },
  data() {
    return {
      result: {},
      range: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    async load(month) {
      if (!month) return;
      this.loading = true;
      this.range = month.range;
      const params = {
        bizId: this.biz._id,
        month: month.name,
        begin: month.range.begin,
        end: month.range.end,
      };
      try {
        const { data } = await this.axios.post("/orders/finance/tax", params);
        this.result = data;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
