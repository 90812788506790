<template lang="pug">
/// DO NOT REMOVE THIS LINE
div
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  name: "LoadPOS",
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.$auth.check() || !this.biz) return;
      const params = { criteria: { biz: this.biz._id } };
      try {
        const data = await this.$api.pos.retrieve(params);
        if (!data) this.create();
        else this.$store.dispatch("setPOS", data);
      } catch (e) {
        this.create();
      }
    },
    async create() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      const data = await this.$api.pos.create(params);
      this.$store.dispatch("setPOS", data);
    },
  },
};
</script>