<template lang="pug">
div
  GeneralSalesReport
  ItemSalesReport.my-10
</template>

<script>
import GeneralSalesReport from "./GeneralSalesReport/index";
import ItemSalesReport from "./ItemSalesReport/index";

export default {
  components: { GeneralSalesReport, ItemSalesReport },
};
</script>