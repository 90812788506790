<template lang="pug">
td
  v-btn(
    small,
    text,
    color="secondary",
    v-for="(o, index) in day",
    :key="index",
    @click="edit(o.id)"
  ) {{ o.startHH | minutesToTime }} - {{ o.endHH | minutesToTime }}
</template>

<script>
export default {
  props: ["day"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async edit(id) {
      if (!id) return;
      this.loading = true;
      const params = { criteria: { _id: id } };
      try {
        const result = await this.$api.serverShift.retrieve(params);
        this.$emit("edit", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
