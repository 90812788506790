<template lang="pug">
simple-table
  thead
    tr
      th Year
      th.text-right Type
      th
  tbody
    Cell(v-for="(item, index) in items", :key="index", :item="item")
</template>

<script>
import Cell from "./Cell";

export default {
  components: { Cell },
  props: ["items"],
};
</script>
