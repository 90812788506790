<template lang="pug">
v-list-item(@click="create")
  v-list-item-action
    v-icon mdi-bank-plus
  v-list-item-content
    v-list-item-title Create New Business
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["setNavState"]),
    create() {
      this.$router.push("/create");
    },
  },
};
</script>
