<template lang="pug">
Page(title="7Shifts Integration", :doc="doc")
  simple-table
    thead
      tr
        th Name
        th Address
        th Connect
    tbody
      tr(v-for="biz in bizs", :key="biz._id")
        th {{ biz.name }}
        td {{ biz.address | address }}
        td
          span(v-if="biz.connect")
            v-icon(color="success") mdi-check
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      doc: "https://docs.selflane.com/biz/employee/seven_shifts.html",
      loading: false,
      connect: [],
      bizs: [],
    };
  },
  computed: {
    ...mapGetters("entp", ["enterprise"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        await this.loadConnect();
        await this.loadBizs();
      } catch (e) {
        // fail silently
      }
      this.loading = false;
    },
    async loadBizs() {
      if (!this.enterprise) return;
      const criteria = { _id: { $in: this.enterprise.bizs } };
      const select = "name address";
      this.bizs = await this.$api.biz.list({ criteria, select });
      // sort biz by name
      this.bizs?.sort((a, b) => a.name.localeCompare(b.name));
      // assign connect to biz
      this.bizs.forEach((biz) => {
        biz.connect = this.connect.find((o) => o.biz === biz._id);
      });
    },
    async loadConnect() {
      if (!this.enterprise) return;
      const criteria = { biz: { $in: this.enterprise.bizs } };
      this.connect = await this.$api.seven.connect.list({ criteria });
    },
  },
};
</script>