const state = {
  promotions: []
}

const getters = {
  promotions: state => state.promotions
}

const actions = {
  setPromotions: ({ commit }, data) => { commit('setPromotions', data) },
  updatePromotion: ({ commit }, data) => { commit('updatePromotion', data) },
  addPromotion: ({ commit }, data) => { commit('addPromotion', data) }
}

const mutations = {
  setPromotions(state, data) {
    state.promotions = data
  },
  updatePromotion(state, data) {
    state.promotions = state.promotions.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addPromotion(state, data) {
    state.promotions.push(data)
  }
}

export default { state, getters, actions, mutations }