<template lang="pug">
div
  .title Credit Card Returns
  .body-2 Processed credit card returns. Some credit card returns may only mean to return overcharged credit cards.
  List.my-8(:range="range")
</template>

<script>
import List from "./List";

export default {
  components: { List },
  props: ["range"],
};
</script>
