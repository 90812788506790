/// Register global components here

import BizPicker from "@/components/basic/BizPicker";
import BizStartHour from "@/components/basic/BizStartHour";
import OrderHeader from "@/components/basic/OrderHeader";
import AmountTableRow from '/libs/components/Basic/AmountTableRow';
import SimpleTable from "/libs/components/Basic/SimpleTable";
import StandardTable from '/libs/components/Basic/StandardTable';
import TableDownload from '/libs/components/Basic/TableDownload';
import BirthdayPicker from "/libs/components/BirthdayPicker";
import BizAddress from '/libs/components/Biz/Address';
import BizBasic from '/libs/components/Biz/Basic';
import DayHours from '/libs/components/Biz/DayHours';
import BizEmail from '/libs/components/Biz/Email';
import BizLogo from '/libs/components/Biz/Logo';
import DishCell from '/libs/components/Biz/Menu/DishCell';
import DishTags from '/libs/components/Biz/Menu/DishTags';
import PNLPicker from '/libs/components/Biz/Menu/PNLPicker';
import BizPhone from '/libs/components/Biz/Phone';
import PriceLevelPicker from '/libs/components/Biz/PriceLevelPicker';
import BizSelector from '/libs/components/Biz/Selector';
import ServiceItems from '/libs/components/Biz/ServiceItems';
import BizTaxRates from '/libs/components/Biz/TaxRates';
import PieChart from "/libs/components/Chart/PieChart";
import StandardChart from "/libs/components/Chart/StandardChart";
import ClickToCopy from '/libs/components/ClickToCopy';
import EmptyView from '/libs/components/EmptyView';
import MarkerMap from "/libs/components/Google/MarkerMap";
import GoogleAddressField from "/libs/components/GoogleAddressField";
import GroupTiles from '/libs/components/GroupTiles';
import LaborReport from '/libs/components/HR/LaborReport';
import IconBtn from "/libs/components/IconBtn";
import PriceField from '/libs/components/Inputs/PriceField';
import RoleAvatar from '/libs/components/Inputs/RoleAvatar';
import NavGroups from '/libs/components/NavGroups';
import Page from '/libs/components/Page';
import PartnerPage from '/libs/components/PartnerPage';
import PhoneField from "/libs/components/PhoneField";
import PayrollSettings from '/libs/components/POS/PayrollSettings';
import PricingGuard from '/libs/components/PricingGuard';
import TogoBreakdown from '/libs/components/Sales/TogoBreakdown';
import AccountLinkCreate from '/libs/components/Stripe/AccountLink/Create';
import AccountLinkUpdate from '/libs/components/Stripe/AccountLink/Update';
import StripeCardForm from '/libs/components/Stripe/CardForm';
import DateFieldPicker from '/libs/components/Time/DateFieldPicker';
import DateRangePicker from '/libs/components/Time/DateRangePicker';
import DateTimePicker from '/libs/components/Time/DateTimePicker';
import DaySelector from '/libs/components/Time/DaySelector';
import DaysPicker from '/libs/components/Time/DaysPicker';
import HHMMPicker from '/libs/components/Time/HHMMPicker';
import MonthSelector from '/libs/components/Time/MonthSelector';
import SchedulePicker from '/libs/components/Time/SchedulePicker';
import TimeRangePicker from '/libs/components/Time/TimeRangePicker';
import TimeSelector from '/libs/components/Time/TimeSelector';
import WeekdaySelector from '/libs/components/Time/WeekdaySelector';
import WeekSelector from '/libs/components/Time/WeekSelector';
import YearSelector from '/libs/components/Time/YearSelector';

function handler(Vue) {
  Vue.component('BizStartHour', BizStartHour)
  Vue.component('OrderHeader', OrderHeader)
  Vue.component('BizPicker', BizPicker)
  // libs
  Vue.component('SimpleTable', SimpleTable)
  Vue.component('BirthdayPicker', BirthdayPicker)
  Vue.component('IconBtn', IconBtn)
  Vue.component('PhoneField', PhoneField)
  Vue.component('Page', Page)
  Vue.component('PricingGuard', PricingGuard)
  Vue.component('PartnerPage', PartnerPage)
  Vue.component('GroupTiles', GroupTiles)
  Vue.component('ClickToCopy', ClickToCopy)
  Vue.component('GoogleAddressField', GoogleAddressField)
  Vue.component('MarkerMap', MarkerMap)
  Vue.component('EmptyView', EmptyView)
  Vue.component('NavGroups', NavGroups)
  Vue.component('PriceField', PriceField)
  Vue.component('RoleAvatar', RoleAvatar)
  Vue.component('TimeSelector', TimeSelector)
  Vue.component('TimeRangePicker', TimeRangePicker)
  Vue.component('DaySelector', DaySelector)
  Vue.component('DaysPicker', DaysPicker)
  Vue.component('MonthSelector', MonthSelector)
  Vue.component('YearSelector', YearSelector)
  Vue.component('DateFieldPicker', DateFieldPicker)
  Vue.component('DateTimePicker', DateTimePicker)
  Vue.component('DateRangePicker', DateRangePicker)
  Vue.component('SchedulePicker', SchedulePicker)
  Vue.component('WeekSelector', WeekSelector)
  Vue.component('HHMMPicker', HHMMPicker)
  Vue.component('WeekdaySelector', WeekdaySelector)
  Vue.component('StandardChart', StandardChart)
  Vue.component('PieChart', PieChart)
  Vue.component('BizSelector', BizSelector)
  Vue.component('PriceLevelPicker', PriceLevelPicker)
  Vue.component('BizBasic', BizBasic)
  Vue.component('BizPhone', BizPhone)
  Vue.component('BizAddress', BizAddress)
  Vue.component('DayHours', DayHours)
  Vue.component('BizLogo', BizLogo)
  Vue.component('ServiceItems', ServiceItems)
  Vue.component('BizTaxRates', BizTaxRates)
  Vue.component('BizEmail', BizEmail)
  Vue.component('DishCell', DishCell)
  Vue.component('PNLPicker', PNLPicker)
  Vue.component('DishTags', DishTags)
  Vue.component('LaborReport', LaborReport)
  Vue.component('PayrollSettings', PayrollSettings)
  Vue.component('StripeCardForm', StripeCardForm)
  Vue.component('AccountLinkUpdate', AccountLinkUpdate)
  Vue.component('AccountLinkCreate', AccountLinkCreate)
  Vue.component('TableDownload', TableDownload)
  Vue.component('StandardTable', StandardTable)
  Vue.component('AmountTableRow', AmountTableRow)
  Vue.component('TogoBreakdown', TogoBreakdown)
}

export default handler