const state = {
  bizAccounts: [],
  chainAccounts: [],
  biz_throttles: [],
};

const getters = {
  bizAccounts: state => state.bizAccounts,
  chainAccounts: state => state.chainAccounts,
  biz_throttles: state => state.biz_throttles
};

const actions = {
  setBizAccounts: ({ commit }, data) => { commit("setBizAccounts", data); },
  addBizAccounts: ({ commit }, data) => { commit('addBizAccounts', data) },
  addBizAccount: ({ commit }, data) => { commit('addBizAccount', data) },
  updateBizAccount: ({ commit }, data) => { commit("updateBizAccount", data); },
  removeBizAccount: ({ commit }, data) => { commit("removeBizAccount", data); },
  setChainAccounts: ({ commit }, data) => { commit("setChainAccounts", data); },
  addChainAccount: ({ commit }, data) => { commit('addChainAccount', data) },
  updateChainAccount: ({ commit }, data) => { commit("updateChainAccount", data); },
  removeChainAccount: ({ commit }, data) => { commit("removeChainAccount", data); },
  setBizThrottles: ({ commit }, data) => { commit("setBizThrottles", data); },
  addBizThrottle: ({ commit }, data) => { commit('addBizThrottle', data) },
  updateBizThrottle: ({ commit }, data) => { commit("updateBizThrottle", data); },
  removeBizThrottle: ({ commit }, data) => { commit("removeBizThrottle", data); },
};

const mutations = {
  setBizAccounts(state, data) {
    state.bizAccounts = data;
  },
  // push or replace data
  addBizAccounts(state, data) {
    data?.forEach(o => {
      let index = state.bizAccounts?.findIndex(o => o._id === o._id);
      if (index === -1) {
        state.bizAccounts.push(o);
      } else {
        state.bizAccounts.splice(index, 1, o);
      }
    });
  },
  addBizAccount(state, data) { state.bizAccounts.push(data) },
  updateBizAccount(state, data) {
    state.bizAccounts = state.bizAccounts.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  removeBizAccount(state, data) {
    state.bizAccounts = state.bizAccounts.filter(o => o._id !== data._id);
  },
  setChainAccounts(state, data) { state.chainAccounts = data; },
  addChainAccount(state, data) { state.chainAccounts.push(data) },
  updateChainAccount(state, data) {
    state.chainAccounts = state.chainAccounts.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  removeChainAccount(state, data) {
    state.chainAccounts = state.chainAccounts.filter(o => o._id !== data._id);
  },
  setBizThrottles(state, data) { state.biz_throttles = data; },
  addBizThrottle(state, data) { state.biz_throttles.push(data) },
  updateBizThrottle(state, data) {
    state.biz_throttles = state.biz_throttles.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  removeBizThrottle(state, data) {
    state.biz_throttles = state.biz_throttles.filter(o => o._id !== data._id);
  },
};

export default { state, getters, actions, mutations };