<template lang="pug">
v-data-table(
  :headers="headers",
  :items="data",
  @click:row="edit",
  role="button",
  sort-by="name",
  dense
)
  template(v-slot:item.days="{ item }")
    WeekdayTile(:days="item.days")
  template(v-slot:item.time="{ item }")
    span {{ item.startHH | minutesToTime }} - {{ item.endHH | minutesToTime }}
  template(v-slot:item.hours="{ item }")
    span {{ (((item.endHH - item.startHH) / 60) * item.days.length) | number(1) }}
  template(v-slot:item.repeat="{ item }")
    span {{ formatFrequency(item.frequency) }}
  template(v-slot:item.note="{ item }")
    span(v-if="item.startDay > today") Start on {{ item.startDay }}
    span(v-if="item.doesEnd") End on {{ item.endDay }}
  template(v-slot:item.actions="{ item }")
    v-btn.ma-0(icon, small, @click.stop="clone(item)")
      v-icon(small) mdi-content-copy
    v-btn.ma-0(icon, small, @click.stop="handleRemove(item)")
      v-icon(small, color="error") mdi-delete
</template>

<script>
import WeekdayTile from "@/components/basic/WeekdayTile.vue";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { WeekdayTile },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Role", value: "role" },
        { text: "Days", value: "days", sortable: false },
        { text: "Time", value: "time", sortable: false },
        { text: "Hrs", value: "hours", sortable: false },
        { text: "Repeat", value: "repeat", sortable: false },
        { text: "Note", value: "note", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      rowsPerPage: [15, 30, { text: "All", value: -1 }],
      shifts: [],
      today: parseInt(moment().format("YYYYMMDD")),
    };
  },
  computed: {
    ...mapGetters(["biz", "servershifts"]),
    ...mapGetters("hr", ["persons"]),
    data() {
      return this.servershifts.map((o) => {
        const found = this.persons?.find((s) => s._id === o.server);
        if (found) o.name = found.fullname;
        return o;
      });
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["removeServershift", "setServershifts"]),
    async load() {
      if (!this.biz || !this.biz._id) return;
      var options = { criteria: { biz: this.biz._id } };
      const result = await this.$api.serverShift.list(options);
      this.setServershifts(result);
    },
    formatFrequency(frequency) {
      if (!frequency || !frequency.n || !frequency.unit) return "";
      if (frequency.n === 1) return "Weekly";
      if (frequency.n === 2) return "Biweekly";
      if (frequency.n === 3) return "Triweekly";
      if (frequency.n === 4) return "Quadweekly";
      return "";
    },
    edit(item) {
      let clone = JSON.parse(JSON.stringify(item));
      this.$emit("edit", clone);
    },
    clone(item) {
      let clone = JSON.parse(JSON.stringify(item));
      delete clone._id;
      this.edit(clone);
    },
    handleRemove(item) {
      confirm("Remove this shift?") && this.remove(item);
    },
    async remove(item) {
      const options = { criteria: { _id: item._id } };
      await this.$api.serverShift.delete(options);
      this.removeServershift(item);
    },
  },
};
</script>
