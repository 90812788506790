import Main from './index.vue'
import Person from './Person/index.vue'
import crm from './CRM/route'
import organization from './Organization/route'

export default [
  { path: '/partner', component: Main, meta: { auth: true }, },
  { path: '/partner/person', component: Person, meta: { auth: true }, },
  ...crm,
  ...organization,
]