<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Gross Receipts Tax ({{ total | currency }})
  simple-table(v-if="tax_items.length")
    thead
      tr
        th Name
        th.text-right Tax
        th.text-right Net Sales
        th.text-right Gross Sales
        th.text-right Tax Rate
    tbody
      tr(v-for="(item, index) in tax_items", :key="index")
        td {{ item.name }}
        td.text-right {{ item.value | currency }}
        td.text-right {{ item.net | currency }}
        td.text-right {{ (item.net + item.value) | currency }}
        td.text-right {{ item.percentage }}%
</template>

<script>
import _ from "underscore";

export default {
  props: ["items"],
  computed: {
    tax_items() {
      return _.chain(this.items)
        .map("tax_items")
        .flatten()
        .groupBy("name")
        .map((list, name) => {
          const net = list.reduce((a, b) => a + b.net, 0);
          const value = list.reduce((a, b) => a + b.value, 0);
          return {
            name,
            net,
            value,
            percentage: list[0].percentage,
            action_type: list[0].action_type,
          };
        })
        .sortBy("name")
        .value();
    },
    total() {
      return this.tax_items.reduce((a, b) => a + b.value, 0);
    },
  },
};
</script>