<template lang="pug">
div(v-if="salesPerson")
  v-list-group(
    v-for="(item, index) in items",
    v-model="item.active",
    :key="index",
    :prepend-icon="item.action",
    no-action
  )
    template(v-slot:activator)
      v-list-item-title {{ item.title }}
    v-list-item(
      v-for="subItem in item.items",
      :key="subItem.title",
      :to="subItem.to"
    )
      v-list-item-content
        v-list-item-title {{ subItem.title }}
</template>

<script>
import { mapGetters } from "vuex";
import PartnerNav from "@/components/basic/PartnerNav.json";

export default {
  data() {
    return {
      navItems: JSON.parse(JSON.stringify(PartnerNav)),
    };
  },
  computed: {
    ...mapGetters(["salesPerson"]),
    isOwner() {
      return this.salesPerson?.org_access == 1;
    },
    items() {
      if (this.isOwner) {
        return this.navItems;
      } else {
        return this.navItems.filter((item) => item.privilege == 2);
      }
    },
  },
};
</script>
