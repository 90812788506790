const state = {
  vouchers: []
}

const getters = {
  vouchers: state => state.vouchers
}

const actions = {
  setVouchers: ({ commit }, data) => { commit('setVouchers', data) },
  updateVoucher: ({ commit }, data) => { commit('updateVoucher', data) },
  addVoucher: ({ commit }, data) => { commit('addVoucher', data) },
  removeVoucher: ({ commit }, data) => { commit('removeVoucher', data) }
}

const mutations = {
  setVouchers(state, data) {
    state.vouchers = data
  },
  updateVoucher(state, data) {
    state.vouchers = state.vouchers.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addVoucher(state, data) {
    state.vouchers.push(data)
  },
  removeVoucher(state, data) {
    state.vouchers = state.vouchers.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }