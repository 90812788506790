<template lang="pug">
div
  Account.my-10
  div(v-if="stripeAccount")
    Persons.my-10(v-if="isCompany", :id="stripeAccount.id")
    Banks.my-10(:id="stripeAccount.id")
    Payouts.my-10(:id="stripeAccount.id")
</template>

<script>
import { mapGetters } from "vuex";
import Account from "./Account/index";
import Persons from "/libs/components/Stripe/Persons/index";
import Banks from "/libs/components/Stripe/BankAccounts/index";
import Payouts from "./Payouts/index";

export default {
  components: { Account, Persons, Banks, Payouts },
  computed: {
    ...mapGetters(["biz", "stripeAccount"]),
    isCompany() {
      return this.stripeAccount?.business_type != "individual";
    },
  },
};
</script>