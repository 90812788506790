<template lang="pug">
IconBtn(@click="download", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";

export default {
  props: ["result"],
  methods: {
    download() {
      const datetime = this.$options.filters.datetime;
      const currency = this.$options.filters.currency;

      const csv = new CSV();
      csv.addRow(["Time", "Amount", "Name", "Last4", "Auth Code"]);
      this.result.forEach((item) => {
        csv.addRow([
          datetime(item.created, "MMM D, h:mm A"),
          currency(item.amount),
          item.name,
          item.last4,
          item.approve,
        ]);
      });
      csv.save("credit_card_return");
    },
  },
};
</script>
