<template lang="pug">
v-card(flat, :loading="loading")
  v-text-field.ma-3(
    v-model="searchText",
    clearable,
    hide-details,
    label="Search Name, Ticket, Phone, Email"
  )
  v-card-text
    v-data-table(
      :items="items",
      :headers="headers",
      :search="searchText",
      @click:row="show",
      role="button",
      dense
    )
      template(v-slot:item.phone="{ item }")
        span {{ item.phone | phone }}
  v-card-actions
    Download(:items="items")
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import Download from "./Download";

export default {
  components: { Download },
  props: ["tickets", "loading"],
  data() {
    return {
      searchText: "",
      headers: [
        { text: "Ticket #", value: "ticket_number" },
        { text: "Item", value: "item_name" },
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["events"]),
    items() {
      return this.tickets.map((o) => {
        let name, phone, email;
        if (o.user) {
          name = o.user.name;
          phone = o.user.phone;
          email = o.user.email;
        }
        const item = this.events
          ?.find((event) => event._id == o.event_id)
          ?.items?.find((item) => item._id == o.item_id);
        return {
          _id: o._id,
          order_id: o.order_id,
          ticket_number: o.ticket_number,
          item_name: item?.name,
          name: name,
          phone: phone,
          email: email,
          status: o.status != false,
        };
      });
    },
  },
  methods: {
    show(item) {
      if (!item) return;
      EventBus.$emit("show-event-order", item.order_id);
    },
  },
};
</script>