<template lang="pug">
div
  div We can help you integrate 7Shifts with your business. Or you can sign up an account with 7Shifts from this Link.
  v-btn.my-3(color="secondary", outlined, :href="ref_link", target="_blank") Sign Up
  .subtitle-2 Or, Let Us Help You
  v-progress-linear(v-if="listing", indeterminate, color="secondary")
  div(v-else-if="match")
    .subtitle-2 Request has been made at {{ match.created | datetime }}. The status is {{ match.status }}.
  v-form(@submit.prevent="submit", v-else)
    v-textarea(
      v-model="message",
      label="Message",
      rows="2",
      :auto-grow="true",
      :counter="300"
    )
    v-btn(block, color="secondary", type="submit", :loading="loading") Make a Request
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      message: "",
      requests: [],
      listing: false,
      loading: false,
      ref_link:
        "https://www.7shifts.com/partner/referral/?utm_source=selflane-pos&utm_medium=partner&utm_campaign=ac-partner-referral",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    match() {
      return this.requests.find((r) => r.title.startsWith("7Shifts"));
    },
  },
  methods: {
    async open() {
      if (!this.biz) return;
      this.message = "";
      this.listing = true;
      const begin = moment().add(-1, "year").valueOf();
      const params = {
        criteria: { biz: this.biz._id, created: { $gte: begin } },
      };
      try {
        this.requests = await this.$api.b.request.list(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.listing = false;
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      const params = {
        biz: this.biz._id,
        title: "7Shifts Integration Request",
        message: this.message,
      };
      try {
        const result = await this.$api.b.request.create(params);
        this.requests.push(result);
        this.$toast.success("Request is made.");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>