<template lang="pug">
v-card(v-if="data", flat)
  v-card-title Open Orders
  simple-table
    tbody
      tr(v-for="(item, index) in data", :key="index")
        th <b>{{ item.name }}</b>
        td.text-right
          span.mr-2(
            v-for="(order, index) in item.items",
            :key="index + 'order'"
          )
            a(@click="show(order)") {{ order.orderNumber }}
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: ["data"],
  methods: {
    show(order) {
      EventBus.$emit("show-order-detail", order._id);
    },
  },
};
</script>
