<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import _ from "underscore";
import CSV from "/libs/utils/CSV";

export default {
  name: "DailyReportDownload",
  props: ["report"],
  methods: {
    setCounts(csv) {
      if (!this.report) return;
      const counts = this.report.counts;
      csv.addNewRow();
      csv.addRow(["Total", counts.total]);
      csv.addRow(["Closed", counts.closed]);
      if (counts.open > 0) csv.addRow(["Open", counts.open]);
      if (counts.canceled > 0) csv.addRow(["Canceled", counts.canceled]);
    },
    setSales(csv) {
      if (!this.report) return;
      const data = this.report.sales;
      const currency = this.$options.filters.currency;
      csv.addNewRow();
      csv.addRow(["Total Sales", currency(data.total)]);
      data.breakdown.forEach((item) => {
        csv.addRow([
          "",
          "",
          "",
          "Net Sales / " + item.name,
          "",
          currency(item.total),
        ]);
      });
      data.typebreakdown.forEach((item) => {
        csv.addRow([
          "",
          "",
          "",
          "Net Sales / " + item.name,
          "",
          currency(item.total),
        ]);
      });
      csv.addRow(["Net Sales", currency(data.net)]);
      const found = data.typebreakdown.find((o) => o.name == "dinein");
      if (data.people && found) {
        csv.addRow(["Total Guests", data.people]);
        csv.addRow(["Net Dine In/Guest", currency(found.total / data.people)]);
      }
      if (data.tax_items && data.tax_items.length) {
        _.each(data.tax_items, (item) => {
          let note = "";
          if (item.type == 1) note = "Inclusive";
          let percentage = "";
          if (item.percentage) percentage = item.percentage + "%";
          csv.addRow([
            "",
            "",
            "",
            item.name,
            percentage,
            currency(item.value),
            note,
          ]);
        });
      }
      csv.addRow(["Tax", currency(data.tax)]);
      if (data.gift > 0) csv.addRow(["Gift Sales", currency(data.gift)]);
      if (data.fee > 0) csv.addRow(["Fee", currency(data.fee)]);
      if (data.tip > 0) csv.addRow(["Tip", currency(data.tip)]);
      if (data.adjustment > 0)
        csv.addRow(["Adjustment", currency(data.adjustment)]);
      if (data.selflane > 0)
        csv.addRow(["Selflane Charges", currency(data.selflane)]);
    },
    setPayments(csv) {
      if (!this.report) return;
      const data = this.report.payments;
      const currency = this.$options.filters.currency;
      csv.addNewRow();
      csv.addRow(["Total Paid", currency(data.total)]);
      const items = [
        { name: "Cash", amount: data.cash },
        { name: "Credit Card", amount: data.cc },
        { name: "Stripe", amount: data.stripe },
        { name: "Check", amount: data.check },
        { name: "Gift Redeemed", amount: data.gc },
        { name: "External Card", amount: data.excc },
        { name: "Selflane Online", amount: data.online },
        { name: "Reward", amount: data.reward },
      ];
      items.forEach((item) => {
        if (item.amount) {
          csv.addRow([item.name, currency(item.amount)]);
        }
      });
      csv.addRow(["Total Tip", currency(data.totaltip)]);
      if (data.cctip > 0) {
        csv.addRow(["CC Tip", currency(data.cctip)]);
      }
      if (data.stripetip > 0) {
        csv.addRow(["Stripe Tip", currency(data.stripetip)]);
      }
      if (data.checktip > 0) csv.addRow(["Check Tip", currency(data.checktip)]);
      if (data.gctip > 0) csv.addRow(["Gift Tip", currency(data.gctip)]);
      if (data.onlineTip > 0)
        csv.addRow(["Online Tip", currency(data.onlineTip)]);
      data.ccbreakdown.forEach((item) => {
        csv.addRow([item.name, currency(item.total)]);
      });
      data.exccbreakdown.forEach((item) => {
        csv.addRow([item.name, currency(item.total)]);
      });
      data.cashByStation.forEach((item) => {
        csv.addRow([item.name, currency(item.total)]);
      });
      csv.addRow(["Deposit (Cash - Tip)", currency(data.cash - data.totaltip)]);
    },
    setBalances(csv) {
      if (!this.report) return;
      const data = this.report.balances;
      const currency = this.$options.filters.currency;
      csv.addNewRow();
      csv.addRow(["Balances by Server", ""]);
      data.forEach((item) => {
        csv.addRow([item.name, currency(item.balance)]);
      });
    },
    setPLBreakdown(csv) {
      if (!this.report) return;
      const data = this.report.plbreakdown;
      const currency = this.$options.filters.currency;
      csv.addNewRow();
      csv.addRow(["P&L Breakdown", ""]);
      data.forEach((item) => {
        csv.addRow([item.name, currency(item.total)]);
      });
    },
    setDiscounts(csv) {
      if (!this.report) return;
      const data = this.report.discounts;
      const currency = this.$options.filters.currency;
      csv.addNewRow();
      const total = data.reduce((a, b) => a + b.value, 0);
      csv.addRow(["Discounts", currency(total)]);
      data.forEach((item) => {
        const name =
          "#" + item.orderNumber + " -" + item.pctOff + "% " + item.name;
        csv.addRow([name, currency(item.value)]);
      });
    },
    setPayInOut(csv) {
      if (!this.report) return;
      const data = this.report.cashdrawer;
      const currency = this.$options.filters.currency;
      csv.addNewRow();
      csv.addRow(["Pay In/Out", currency(data.balance)]);
      data.associates.forEach((item) => {
        csv.addRow([item.name, currency(item.total)]);
      });
    },
    download() {
      if (!this.report) return;
      const csv = new CSV();
      csv.addRow(["Date", this.report.date]);
      this.setCounts(csv);
      this.setSales(csv);
      this.setPayments(csv);
      this.setBalances(csv);
      this.setPLBreakdown(csv);
      this.setDiscounts(csv);
      this.setPayInOut(csv);
      csv.save("daily_sales_" + this.report.date);
    },
  },
};
</script>
