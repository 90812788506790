<template lang="pug">
v-btn(v-if="valid", small, text, color="secondary", block, @click="open") Pay Order
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  props: {
    order: { type: Object, required: true },
    checkId: { type: String, default: null },
  },
  computed: {
    ...mapGetters(["biz"]),
    managed_account() {
      return this.biz?.payments?.managed_account;
    },
    currency() {
      if (this.biz.address?.country == "CA") return "cad";
      return "usd";
    },
    payment() {
      if (this.checkId) {
        return this.order.splitChecks.find((o) => o._id == this.checkId)
          ?.payment;
      }
      return this.order.payment;
    },
    total() {
      return this.payment?.total || 0;
    },
    paidAmount() {
      let paid = 0;
      const amount = this.payment?.charge?.amount;
      if (amount) paid += amount;
      this.payment?.charges?.forEach((o) => {
        if (o.amount) paid += o.amount;
      });
      return paid;
    },
    valid() {
      return (
        this.total > 0 &&
        this.total > this.paidAmount + 0.01 &&
        this.order.status >= 0
      );
    },
  },
  methods: {
    async open() {
      if (!this.managed_account) {
        this.$toast.error("No payment account");
        return;
      }
      const amount = Math.round((this.total - this.paidAmount) * 100) / 100;
      const params = {
        amount: amount,
        currency: this.currency,
        managed_account: this.managed_account,
        orderId: this.order._id,
        bizId: this.biz._id,
        checkId: this.checkId,
      };
      EventBus.$emit("open-virtual-terminal", params);
    },
  },
};
</script>