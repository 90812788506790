<template lang="pug">
v-card.mb-3(max-width="500", flat, outlined)
  v-toolbar(flat, dense)
    .subtitle-2 Location: {{ location.display_name }}
    v-spacer
    v-btn(color="secondary", @click="handleCreateReader", x-small, outlined) Create Reader
  Readers(:readers="filtered_readers")
  CreateReader(
    :location="location.terminal_id",
    ref="createReader",
    @created="$emit('created')"
  )
</template>

<script>
import _ from "underscore";
import Readers from "./Readers";
import CreateReader from "/libs/components/Stripe/Reader/Create";

export default {
  components: { Readers, CreateReader },
  props: ["location", "readers"],
  computed: {
    filtered_readers() {
      return _.filter(
        this.readers,
        (o) => o.location == this.location.terminal_id
      );
    },
  },
  methods: {
    handleCreateReader() {
      this.$refs.createReader.open();
    },
  },
};
</script>