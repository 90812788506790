<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  template(v-slot:activator="{ on }")
    IconBtn(@click="open")
  v-card(v-if="catering && catering.time")
    v-toolbar(dense, flat)
      .subtitle-2 Edit Catering Settings
    v-form(@submit.prevent="submit")
      v-card-text
        v-checkbox(v-model="catering.status", label="Status", dense)
        v-row(dense)
          v-col(cols="12", sm="6")
            PriceField(
              v-model.number="catering.minimum_purchase",
              label="Minimum Purchase"
            )
          v-col(cols="12", sm="6")
            PriceField(
              v-model.number="catering.effective_purchase",
              label="Effective Purchase"
            )
          v-col(cols="4")
            v-select(
              v-model="catering.time.value",
              :items="value_items",
              label="Lead time"
            )
          v-col(cols="4")
            v-select(v-model="catering.time.unit", :items="unit_items")
          v-col(cols="4", v-if="catering.time.unit == 'day'")
            v-select(
              v-model="catering.time.cutoff",
              :items="cutoff_items",
              label="Cutoff Hour"
            )
          v-col(cols="4")
            v-select(
              v-model="catering.max_days",
              :items="days_items",
              label="Ahead up to"
            )
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      catering: {},
      dialog: false,
      loading: false,
      unit_items: ["day", "hour"],
      cutoff_items: _.range(8, 25).map((value) => {
        return { value, text: value + ":00" };
      }),
      days_items: _.range(7, 22).map((value) => {
        return { value, text: value + " days" };
      }),
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    value_items() {
      let unit = this.catering.time && this.catering.time.unit;
      if (unit == "day") {
        return _.range(1, 3);
      } else {
        return _.range(2, 11);
      }
    },
  },
  methods: {
    open() {
      if (!this.biz) return;
      this.dialog = true;
      this.catering = JSON.parse(JSON.stringify(this.biz.catering));
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      try {
        const action = { $set: { catering: this.catering } };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
