<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="purchase")
    v-toolbar(dense, flat)
      .subtitle-2 Date: {{ purchase.created | date }}
    simple-table
      thead
        tr
          th Item
          th Price
          th.text-right Quantity
      tbody
        tr(v-for="(item, index) in purchase.items", :key="index")
          td
            .subtitle-2 {{ item.name }}
          td {{ item.price | currency }}
          td.text-right {{ item.quantity }}
    v-card-text
      table.payment-table.text-right
        AmountTableRow(name="Subtotal", :amount="purchase.payment.subtotal")
        AmountTableRow(name="Tax", :amount="purchase.payment.tax")
        AmountTableRow(name="Total", :amount="purchase.payment.total", bold)
        AmountTableRow(
          :name="paymentName",
          :amount="purchase.payment.charge.amount",
          v-if="isPaid",
          bold
        )
      .text-right.subtitle-2(v-if="isPaid") Payment was posted on {{ purchase.payment.charge.time | date }}
      .text-right.subtitle-2.red--text(v-else) Not Yet Paid
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      purchase: null,
    };
  },
  computed: {
    isPaid() {
      return this.purchase?.payment?.charge?.amount;
    },
    paymentName() {
      return `Paid (${this.purchase?.payment?.charge?.method})`;
    },
  },
  methods: {
    ...mapActions(["updatePurchase"]),
    async load(id) {
      const params = { criteria: { _id: id } };
      const { data } = await this.axios.post("/bizOrder/retrieve", params);
      this.purchase = data;
      this.updatePurchase(data);
      this.dialog = true;
    },
  },
};
</script>

<style lang="sass" scoped>
.payment-table
  width: 100%
</style>