<template lang="pug">
Page(title="Member", :items="items", :doc="doc")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Dashboard", url: "/member/dashboard" },
        { title: "Manage", url: "/member/manage" },
        { title: "Points Shop", url: "/member/points" },
        { title: "Active Marketing", url: "/member/active_marketing" },
      ],
      doc: "https://docs.selflane.com/biz/customer/member/",
    };
  },
};
</script>
