<template lang="pug">
Page(title="Share", :doc="doc")
  URL.mb-10(:url="url")
  .d-flex.flex-row.align-center.my-5
    v-chip.mr-3(outlined, small) 1
    .subtitle-2 Add to
      a.ml-1.secondary--text(:href="googleSearch", target="_blank") Google My Business
      span ,
      a.secondary--text.ml-1(
        href="https://mapsconnect.apple.com/",
        target="_blank"
      ) Apple Maps Connect
      span , and
      a.secondary--text.ml-1(
        href="https://www.bingplaces.com/",
        target="_blank"
      ) Bing Places for Business
  .d-flex.flex-row.align-center.my-5
    v-chip.mr-3(outlined, small) 2
    .subtitle-2 Add an Order Online button on your website
  .d-flex.flex-row.align-center.my-5
    v-chip.mr-3(outlined, small) 3
    .subtitle-2 Share on your Facebook page
    vue-goodshare-facebook.elevation-2.ml-2(
      :page_url="url",
      title_social="Facebook",
      has_icon
    )
  .d-flex.flex-row.align-center.my-5
    v-chip.mr-3(outlined, small) 4
    .subtitle-2 Order with QR Code
  v-card(max-width="300", dark, flat, color="#3B5998")
    v-card-text.text-center
      .mb-5.font-weight-medium Order & Pay from Your Phone
      v-row(justify="center")
        QrcodeVue#qrcode.mb-3(
          :value="url",
          size="150",
          renderAs="canvas",
          level="H",
          ref="qrCodeUrl"
        )
      .title {{ biz.name }}
      .subtitle-2 {{ biz.address | address }}
  .my-3
    IconBtn(@click="downloadQRCode()", title="PDF", icon="mdi-printer")
  .caption Print the QR Code with a normal printer or a label printer such as Dymo Label Printer
</template>

<script>
import { mapGetters } from "vuex";
import URL from "./URL";
import QrcodeVue from "qrcode.vue";
import jsPDF from "jspdf";
import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";

export default {
  components: { URL, QrcodeVue, VueGoodshareFacebook },
  data() {
    return {
      doc: "https://docs.selflane.com/biz/settings/share.html",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    googleSearch() {
      if (!this.biz) return "";
      let text =
        this.biz.name +
        " " +
        this.biz.address.city +
        " " +
        this.biz.address.state;
      text = encodeURIComponent(text.trim());
      return "https://www.google.com/search?q=" + text;
    },
    url() {
      if (!this.biz) return null;
      return "https://selflane.com/bizs/" + this.biz.url;
    },
  },
  methods: {
    getQRCodeImg() {
      const el = this.$refs.qrCodeUrl.$refs["qrcode-vue"];
      const img = el.toDataURL("image/png");
      // console.log(img);
      return img;
    },
    downloadQRCode() {
      if (!this.biz) return;
      const formatter = this.$options.filters.address;
      const bizName = this.biz.name;
      const bizStreet = formatter(this.biz.address, "street");
      const bizCityState = formatter(this.biz.address, "city, state");

      let doc = new jsPDF({
        format: "a6", // a6: 105X148
      });
      let x = 52.5;
      let y = 20;
      const img = this.getQRCodeImg();
      addTitle();

      nextLine();

      doc.save("business_qrcode_order.pdf");

      function addTitle() {
        doc.setTextColor(242, 162, 65);
        doc.text("Order & Pay from Your Phone", x, y, { align: "center" });
        nextLine();
        doc.addImage(img, "PNG", 22.5, y, 60, 60, { align: "center" });
        nextLine(75);
        doc.text(bizName, x, y, { align: "center" });
        nextLine(10);
        doc.setFontSize(12);
        doc.text(bizStreet, x, y, { align: "center" });
        nextLine(6);
        doc.text(bizCityState, x, y, { align: "center" });
        nextLine(18);
        doc.text("Powered by Selflane.com ®", x, y, { align: "center" });
      }
      function nextLine(height) {
        if (height) y += height;
        else y += 15;
        x = 52.5;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
#qrcode-box
  background: linear-gradient(90deg, rgba(89, 146, 238, 1) 0%, rgba(69, 123, 213, 1) 100%)
  color: white

#qrcode
  border-radius: 6px
  overflow: hidden
</style>
