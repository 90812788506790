import Team from './Team/index.vue'
import Staff from './Team/Staff'
import Roles from './Team/Roles'
import Checklist from './Team/Checklist'

import Scheduling from './Scheduling/index.vue'
import Timesheet from './Timesheet/index.vue'
import Day from './Timesheet/Day/index.vue'
import Week from './Timesheet/Week.vue'
import Payroll from './Payroll/index.vue'

export default [
  {
    path: '/hr/staff', component: Team, meta: { auth: true },
    children: [
      { path: '', redirect: 'home' },
      { path: 'home', component: Staff },
      { path: 'roles', component: Roles },
      { path: 'checklist', component: Checklist }
    ]
  },
  { path: '/hr/scheduling', component: Scheduling, meta: { auth: true } },
  {
    path: '/hr/timesheet', component: Timesheet, meta: { auth: true },
    children: [
      { path: '', redirect: 'day' },
      { path: 'day', component: Day },
      { path: 'week', component: Week }
    ]
  },
  { path: '/hr/payroll', component: Payroll, meta: { auth: true } }
]