<template lang="pug">
.event-container(
  @click="$emit('click')",
  :class="{ 'event-container-selected': eventId == item._id }"
)
  .event-title {{ item.title }}
</template>

<script>
export default {
  props: ["item", "eventId"],
};
</script>

<style lang="sass" scoped>
.event-container
  cursor: pointer
  border-radius: 6px
  padding: 10px
  border: 2px solid #E0E0E0
  height: 100%
.event-container-selected
  border: 2px solid #4CAF50
.event-title
  font-size: 17px
  font-weight: 700
  color: #39364F
</style>