<template lang="pug">
div
  v-btn(color="secondary", block, outlined, @click="open") Push Hours to Selected Bizs
  v-dialog(v-model="dialog", width="500")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Sync Hours
      v-card-text
        div(v-if="loading") Syncing {{ processed }} / {{ items.length }}
        div(v-else)
          div Do you want to push hours to the selected businesses?
          v-row
            v-col(cols="6")
              v-switch(
                v-model="regular",
                label="Regular Hours",
                color="secondary"
              )
            v-col(cols="6")
              v-switch(
                v-model="special",
                label="Special Hours",
                color="secondary"
              )
        v-btn(@click="submit", color="secondary", :loading="loading") Push
</template>

<script>
export default {
  props: ["items", "schedule"],
  data() {
    return {
      dialog: false,
      regular: true,
      special: true,
      loading: false,
      processed: 0,
    };
  },
  methods: {
    open() {
      if (!this.items?.length) {
        this.$toast.error("Please select target business first");
        return;
      }
      this.dialog = true;
      this.processed = 0;
    },
    async submit() {
      if (!this.schedule) return;
      let action;
      if (this.regular && this.special) {
        action = { schedule: this.schedule };
      } else if (this.regular) {
        action = { "schedule.weekdays": this.schedule.weekdays };
      } else if (this.special) {
        action = { "schedule.specialdays": this.schedule.specialdays };
      } else {
        this.$toast.error("Please select at least one type of hours");
        return; // not allowed
      }
      this.loading = true;
      for (const bizId of this.items) {
        this.processed++;
        await this.handlePush(bizId, action);
      }
      this.$toast.success("Hours pushed successfully");
      this.loading = false;
      this.dialog = false;
    },
    async handlePush(bizId, action) {
      try {
        await this.$api.biz.put(bizId, { $set: action });
      } catch (e) {
        // fail silently
      }
    },
  },
};
</script>