<template lang="pug">
v-row(justify="center")
  v-card.mb-10.my-3(
    max-width="500",
    width="100%",
    v-if="posEditable",
    flat,
    :loading="loading"
  )
    v-form(@submit.prevent="submit")
      .title.pa-3 General
      v-list.pa-0(dense)
        v-list-item
          v-list-item-content
            v-list-item-title Biz Day Start Hour
          v-list-item-action
            v-select(
              v-model="posEditable.startHour",
              :items="hours",
              dense,
              hide-details,
              style="width: 120px"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Start Day
          v-list-item-action
            v-select(
              v-model="posEditable.startDay",
              :items="days",
              dense,
              hide-details,
              style="width: 120px"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Cash drawer initial fund
          v-list-item-action
            PriceField(
              v-model.number="posEditable.initCash",
              dense,
              :step="10",
              hide-details,
              single-line,
              style="width: 120px"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Present Items Alphabetically
            v-list-item-subtitle In Make Order Screen
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.sortAlphabetical")
        v-list-item
          v-list-item-content
            v-list-item-title Require clock in
              v-tooltip(right)
                template(v-slot:activator="{ on }")
                  v-icon.ml-2(small, v-on="on") mdi-information
                span In default, server should clock in before putting in order
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.clockin")
        v-list-item
          v-list-item-content
            v-list-item-title Require photo clock in
              v-tooltip(right)
                template(v-slot:activator="{ on }")
                  v-icon.ml-2(small, v-on="on") mdi-information
                span Turn on if you require camera capture in clocking in.
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.photo_clockin")
        v-list-item
          v-list-item-content
            v-list-item-title Clock out anytime
              v-tooltip(right)
                template(v-slot:activator="{ on }")
                  v-icon.ml-2(small, v-on="on") mdi-information
                span In default, server should close all orders under his/her name before clocking out
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.clockout")
        v-list-item
          v-list-item-content
            v-list-item-title Tip details in report
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.report.cctip")
        v-list-item
          v-list-item-content
            v-list-item-title Hide fee&tip in online order
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.hidefee")
        v-list-item
          v-list-item-content
            v-list-item-title Tip cc processing fee apply to employee
          v-list-item-action
            v-text-field(
              v-model="posEditable.report.ccfee",
              type="number",
              min="0",
              style="width: 100px",
              suffix="%"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Discount details in report
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.report.voucher")
        v-list-item
          v-list-item-content
            v-list-item-title Auto open drawer in cash payment
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.epson.autoDrawer")
        v-list-item
          v-list-item-content
            v-list-item-title Allow any staff to open drawer
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.epson.looseDrawer"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Enable multiple shifts for daily operation
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.report.shifts")
        v-list-item(v-if="posEditable.report.shifts")
          v-list-item-content
            v-list-item-title Enable closing shift by station
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.report.shiftByStation"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Print all charges separated by station (default is cash charges only)
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.report.chargesByStation"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Preauthorize credit card
            v-list-item-subtitle Allow open tab
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.preauth")
        v-list-item
          v-list-item-content
            v-list-item-title Must select a required modifier
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.forceRequired")
        v-list-item
          v-list-item-content
            v-list-item-title Pop dish editor only if with required modifier
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.forceEditor")
        v-list-item
          v-list-item-content
            v-list-item-title Skip select seat for dine-in order
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.skipSeat")
        v-list-item(v-if="!posEditable.skipSeat")
          v-list-item-content
            v-list-item-title Default Party Size
            v-list-item-subtitle Set to 0 to mandatory select seat
          v-list-item-action
            v-select(
              color="secondary",
              v-model="posEditable.defaultPartySize",
              :items="[0, 1, 2]",
              style="width: 100px"
            )
        v-list-item
          v-list-item-content
            v-list-item-title Don't allow cancel online order from POS
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.noCancelOnline")
        v-list-item
          v-list-item-content
            v-list-item-title Don't allow exempt tax
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.noTaxExempt")
        v-list-item
          v-list-item-content
            v-list-item-title Allow send
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.allow_send")
        v-list-item
          v-list-item-content
            v-list-item-title Allow send and pay
          v-list-item-action
            v-switch(
              color="secondary",
              v-model="posEditable.allow_send_and_pay"
            )
      v-row.pa-3(row, v-if="dirty", justify-space-between)
        v-col(cols="8")
          v-btn.white--text(
            block,
            color="indigo",
            type="submit",
            :loading="loading"
          ) Save
        v-col(cols="3")
          v-btn(text, block, @click="cancel()") Cancel
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      hours: [
        { text: "Mid night", value: 0 },
        { text: "1 AM", value: 1 },
        { text: "2 AM", value: 2 },
        { text: "3 AM", value: 3 },
        { text: "4 AM", value: 4 },
        { text: "5 AM", value: 5 },
        { text: "6 AM", value: 6 },
        { text: "7 AM", value: 7 },
      ],
      days: [
        { text: "Monday", value: 1 },
        { text: "Tuesday", value: 2 },
        { text: "Wednesday", value: 3 },
        { text: "Thursday", value: 4 },
        { text: "Friday", value: 5 },
        { text: "Saturday", value: 6 },
        { text: "Sunday", value: 0 },
      ],
      tipTiers: [15, 20, 25],
      headSpaces: [
        { text: "Regular", value: 0 },
        { text: "Extra", value: 1 },
        { text: "Long", value: 2 },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["pos", "posEditable"]),
    dirty() {
      return JSON.stringify(this.posEditable) !== JSON.stringify(this.pos);
    },
  },
  methods: {
    ...mapActions(["setPOS", "resetEditable"]),
    validate() {
      if (!this.posEditable.initCash || this.posEditable.initCash < 0) {
        this.posEditable.initCash = 150;
      }
      if (
        !this.posEditable.allow_send &&
        !this.posEditable.allow_send_and_pay
      ) {
        throw new Error(
          "At least one of Allow send and Allow send and pay should be enabled"
        );
      }
      if (this.posEditable.ccfee > 4 || this.posEditable.ccfee < 0) {
        throw new Error("Credit card processing fee should be less than 4%");
      }
    },
    async submit() {
      this.loading = true;
      try {
        this.validate();
        const action = { $set: this.posEditable };
        const result = await this.$api.pos.put(this.pos?._id, { action });
        this.setPOS(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    cancel() {
      this.resetEditable();
    },
  },
};
</script>
