import { render, staticRenderFns } from "./BizCell.vue?vue&type=template&id=2f8d65e7&scoped=true&lang=pug"
import script from "./BizCell.vue?vue&type=script&lang=js"
export * from "./BizCell.vue?vue&type=script&lang=js"
import style0 from "./BizCell.vue?vue&type=style&index=0&id=2f8d65e7&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f8d65e7",
  null
  
)

export default component.exports