<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import moment from "moment-timezone";
import _ from "underscore";
import { mapGetters } from "vuex";
import CSV from "/libs/utils/CSV";

export default {
  props: ["items", "isDecimal", "showWage", "dates"],
  computed: { ...mapGetters(["biz"]) },
  methods: {
    download() {
      const csv = new CSV();
      csv.addRow([this.biz.name]);
      if (this.biz.short_name) {
        csv.addRow([this.biz.short_name]);
      }
      csv.addRow([moment().format("M/D/YYYY hh:mm A")]);
      csv.addNewRow();
      csv.addRow([
        this.dates,
        "Employee ID",
        "Week",
        "Role",
        "REG Hrs",
        "OT Hrs",
        "REG Pay",
        "OT Pay",
        "Total",
      ]);
      const rows = this.getRows();
      rows.forEach((row) => {
        csv.addRow(row);
      });
      csv.save("payroll");
    },
    getRows() {
      const isDecimal = this.isDecimal || false;
      const filters = this.$options.filters;
      let rows = [];
      _.each(this.items, (o) => {
        rows.push([o.name, o.employeeID]);
        _.each(o.weekly, (week) => {
          week.itemsByRole.forEach((item) => {
            rows.push([
              "",
              "",
              `Week ${week.week}`,
              item.name,
              filters.hours(item.regHours, isDecimal),
              filters.hours(item.extHours, isDecimal),
              filters.currency(item.regCost),
              filters.currency(item.extCost),
            ]);
          });
        });
        rows.push([
          "",
          "",
          "Total",
          "",
          filters.hours(o.regHours, isDecimal),
          filters.hours(o.extHours, isDecimal),
          filters.currency(o.regCost),
          filters.currency(o.extCost),
          filters.currency(o.cost),
        ]);
        if (o.tip) {
          rows.push([
            "",
            "",
            "CC Tip",
            "",
            "",
            "",
            "",
            filters.currency(o.tip),
          ]);
        }
        if (o.cashTip > 0) {
          rows.push([
            "",
            "",
            "Cash Tip",
            "",
            "",
            "",
            "",
            filters.currency(o.cashTip),
          ]);
        }
        const totalPay = o.cost + (o.tip || 0) + (o.cashTip || 0);
        rows.push([
          "",
          "",
          "Total",
          "",
          "",
          "",
          "",
          filters.currency(totalPay),
        ]);
        if (o.sales > 0) {
          const pct = (totalPay / o.sales) * 100;
          rows.push([
            "",
            "",
            "Net Sales",
            "",
            "",
            "",
            "",
            "",
            "",
            filters.currency(o.sales),
            filters.number(pct) + "%",
          ]);
        }
        _.each(o.tax_items, (item) => {
          rows.push([
            "",
            "",
            item.name,
            item.percentage + "%",
            "",
            "",
            "",
            "",
            "",
            filters.currency(item.value),
          ]);
        });
        rows.push([]);
      });
      return rows;
    },
  },
};
</script>
