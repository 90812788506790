<template lang="pug">
Page(title="Dashboard")
  Stats
  Charts.mt-5.mb-10
</template>

<script>
import Charts from "./Charts";
import Stats from "./Stats";

export default {
  components: { Stats, Charts },
};
</script>
