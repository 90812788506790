<template lang="pug">
v-btn(v-if="visible", icon, @click="handleSearch()")
  v-icon mdi-magnify
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  computed: {
    ...mapGetters(["corpaccess"]),
    visible() {
      const userId = this.$auth.user()?._id;
      if (!userId) return false;
      return this.corpaccess && this.corpaccess.user == userId;
    },
  },
  methods: {
    handleSearch() {
      if (this.visible) EventBus.$emit("search-biz-corp");
    },
  },
};
</script>
