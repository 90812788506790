<template lang="pug">
v-card(v-if="data", flat)
  Table(:items="items", @click="$emit('showOpen')")
</template>

<script>
import Table from "./Table";

export default {
  components: { Table },
  props: ["data"],
  computed: {
    items() {
      return [
        { name: "Closed", amount: this.data.closed, filter: "number" },
        { name: "Open", amount: this.data.open, click: true, filter: "number" },
        { name: "Canceled", amount: this.data.canceled, filter: "number" },
        {
          name: "Total Orders",
          amount: this.data.total,
          bold: true,
          required: true,
          filter: "number",
        },
      ];
    },
  },
};
</script>
