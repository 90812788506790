<template lang="pug">
v-card(flat, :loading="loading")
  v-toolbar(flat, dense)
    .subtitle-2 Settings
  v-list(dense)
    v-list-item
      v-list-item-content
        v-list-item-title Time for Auto Clockout
        v-list-item-subtitle System will clock out all open timecards at the selected time
      v-list-item-action
        v-select(
          v-model="clockouthh",
          :items="items",
          @change="update",
          dense,
          style="width: 120px"
        )
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      clockouthh: 3,
      items: [
        { text: "9:30 PM", value: 21 },
        { text: "10:30 PM", value: 22 },
        { text: "11:30 PM", value: 23 },
        { text: "12:30 AM", value: 0 },
        { text: "1:30 AM", value: 1 },
        { text: "2:30 AM", value: 2 },
        { text: "3:30 AM", value: 3 },
        { text: "4:30 AM", value: 4 },
        { text: "Never", value: -1 },
      ],
    };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.biz) return;
      this.loading = true;
      try {
        const result = await this.$api.hr.setting.get(this.biz._id);
        this.clockouthh = result.clockouthh;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async update() {
      this.loading = true;
      const action = { $set: { clockouthh: this.clockouthh } };
      try {
        const result = await this.$api.hr.setting.put(this.biz._id, action);
        this.clockouthh = result.clockouthh;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
