<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on")
  StripeCardForm(@saved="saved", :url="url", :params="params")
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      url: "/salesforce/organizations/createCard",
    };
  },
  computed: {
    ...mapGetters(["salesOrganization"]),
    params() {
      return { organization_id: this.salesOrganization?._id };
    },
  },
  methods: {
    ...mapActions(["setSalesOrganization"]),
    async saved(data) {
      this.setSalesOrganization(data);
      this.dialog = false;
      this.$emit("done");
    },
  },
};
</script>
