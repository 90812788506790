<template lang="pug">
v-card(flat, v-if="id", :loading="loading")
  v-toolbar(dense, flat)
    .subtitle-2 Bank Accounts
    v-spacer
    CreateBank
  List
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";
import CreateBank from "./CreateBank";
import List from "./List";

export default {
  components: { List, CreateBank },
  props: {
    id: { type: String, default: null }, // stripe connected account id
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    id() {
      this.load();
    },
  },
  mounted() {
    this.load();
    EventBus.$on("reload-bank-account", this.load);
  },
  destroyed() {
    EventBus.$off("reload-bank-account", this.load);
  },
  methods: {
    ...mapActions(["setStripeBanks"]),
    async load() {
      if (!this.id) {
        this.setStripeBanks([]);
        return;
      }
      this.loading = true;
      try {
        const params = { accountId: this.id };
        const result = await this.$api.stripe.bankaccount.list(params);
        this.setStripeBanks(result);
      } catch (e) {
        this.setStripeBanks([]);
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
