<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-form
    v-card(v-if="biz && plan")
      v-toolbar(dense, flat)
        .subtitle-2 {{ title }} a Special Influencer Program for {{ biz.name }}
      v-divider
      v-card-text
        v-row(dense)
          v-col(cols="6")
            v-select(v-model="plan.type", :items="types", label="Type")
          v-col(cols="6")
            div(v-if="plan.type == 'pct'")
              v-text-field(v-model="plan.pct", label="Discount %", suffix="%")
              v-text-field(
                v-model="plan.limit",
                label="Upper Limit",
                prefix="$"
              )
            div(v-else)
              v-text-field(v-model="plan.fix", label="Discount $", prefix="$")
        v-row(dense)
          v-col(cols="6")
            v-select(
              v-model="plan.influencer",
              label="Influencer",
              :items="influencer_items",
              :disabled="plan._id"
            )
          v-col(cols="6", v-if="plan.influencer")
            v-select(
              v-model="plan.commission_rate",
              label="Commission Rate",
              suffix="%",
              :items="commission_rate_items"
            )
        v-alert(dark, color="success") {{ commision_description }}
        v-slider.mt-10(
          color="secondary",
          v-model="plan.minimum",
          min="5",
          max="20",
          label="Minimum Purchase",
          always-dirty,
          thumb-label="always",
          :thumb-size="24"
        )
        .text-center.my-3
          .subtitle-2 Select Expiration Date
          v-date-picker.my-3(
            v-model="expiry",
            :min="minDate",
            color="secondary",
            no-title
          )
          v-checkbox(
            v-model="agreed",
            color="secondary",
            label="By creating this plan, you agree to our terms of use for influencer programs."
          )
          .body-2
      v-card-actions
        v-btn.text-notransform(
          block,
          color="secondary",
          :loading="loading",
          :disabled="!agreed",
          @click="save"
        ) Save
</template>

<script>
import moment from "moment-timezone";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      agreed: false,
      loading: false,
      minDate: moment().format("YYYY-MM-DD"),
      plan: null,
      expiry: null,
      types: [
        { text: "Fixed", value: "fix" },
        { text: "Percentage", value: "pct" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz", "influencers"]),
    title() {
      if (this.plan?._id) return "Edit";
      else return "Create";
    },
    influencer_items() {
      return (
        this.influencers?.map((o) => {
          return { text: o.name, value: o._id };
        }) || []
      );
    },
    commission_rate_items() {
      const found = this.influencers?.find(
        (o) => o._id == this.plan.influencer
      );
      if (!found) return [];
      return _.map(found.commission_tiers, (tier) => {
        return {
          text: tier.commission_rate + "%",
          value: tier.commission_rate,
          commission_rate: tier.commission_rate,
          description: tier.description,
        };
      });
    },
    commision_description() {
      const found = this.commission_rate_items?.find(
        (o) => o.commission_rate == this.plan.commission_rate
      );
      return found?.description || "";
    },
  },
  mounted() {
    EventBus.$on("edit-influencer-special-plan", this.open);
  },
  destroyed() {
    EventBus.$off("edit-influencer-special-plan", this.open);
  },
  methods: {
    ...mapActions([
      "addInfluencerPlan",
      "updateInfluencerPlan",
      "setInfluencers",
    ]),
    open(data) {
      if (!this.biz) return;
      this.plan = JSON.parse(JSON.stringify(data));
      if (!this.plan._id) {
        this.expiry = moment().add(12, "months").format("YYYY-MM-DD");
      }
      if (!this.influencers?.length) this.loadInfluencers();
      this.dialog = true;
    },
    async loadInfluencers() {
      const params = { criteria: { level: 2 } };
      const result = await this.$api.influencer.account.list(params);
      this.setInfluencers(result);
    },
    save() {
      if (!this.biz) return;
      if (this.plan.type == "pct") {
        if (this.plan.pct < 5 || this.plan.pct > 50) {
          alert("Discount should be between 5% and 50%");
          return;
        }
      }
      if (this.plan.total < 50) {
        alert("Total coupons should be greater than 50");
        return;
      }
      if (this.plan.commission_rate < 5 || this.plan.commission_rate > 50) {
        alert("Commission Rate should be between 5% and 50%");
        return;
      }
      this.plan.biz = this.biz._id;
      this.plan.expiry = moment(this.expiry).endOf("day").unix() * 1000;
      if (this.plan._id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      if (!this.plan || this.plan._id) return;
      this.loading = true;
      try {
        const result = await this.$api.influencer.plan.create(this.plan);
        this.addInfluencerPlan(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async update() {
      if (!this.plan || !this.plan._id) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.plan._id },
        action: { $set: this.plan },
      };
      try {
        const result = await this.$api.influencer.plan.update(params);
        this.updateInfluencerPlan(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
