<template lang="pug">
table
  thead
    tr
      th Ticket Name
      th Price
      th Quantity
  tbody
    tr(v-for="(item, index) in items", :key="index")
      td {{ item.name }}
      td {{ item.price | currency }}
      td {{ item.total_quantity }}
</template>

<script>
export default {
  props: ["items"],
};
</script>

<style lang="sass" scoped>
table
  font-size: 15px
  border-collapse: collapse
  margin-top: 12px
  margin-bottom: 12px

table td, th
  min-width: 120px
  height: 40px
  border: 1px solid grey
  text-align: center
  padding: 6px
</style>