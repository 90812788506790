<template lang="pug">
div
  h1 Domain
  p This is the domain page.
  Gallery
</template>

<script>
import Gallery from "./Gallery";

export default {
  components: { Gallery },
};
</script>