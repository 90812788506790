<template lang="pug">
v-dialog(v-model="dialog", width="700", scrollable)
  template(v-slot:activator="{ on }")
    IconBtn(@click="open()", title="Upload", icon="mdi-upload")
  v-card
    v-card-title Upload Staff
    v-card-text
      div Please ensure the CSV file has the following columns and the 1st row is the header:
      ul
        li First Name
        li Last Name
        li Receipt Display Name
        li Employee ID (optional)
        li Phone (optional)
        li Email (optional)
        li DOB (Date of Birth, optional)
        li Role
        li Wage (optional)
        li Passcode
      v-btn(@click="downloadSample()", text, color="secondary", small) Download Sample
      v-row
        v-col(cols="4")
          v-select(v-model="delimiter", :items="delimiters", label="Delimiter")
      input(type="file", accept=".csv", @change="upload($event)")
      .caption Please upload a CSV file.
    simple-table(
      v-if="valid",
      style="max-height: 300px !important; overflow-y: auto"
    )
      thead
        tr
          th
          th(v-for="(item, index) in header", :key="index") {{ item }}
          th
      tbody
        tr(v-for="(item, index) in items", :key="index")
          td {{ index + 1 }}
          td(v-for="(value, index) in item", :key="index") {{ value }}
          td
            .link-box
              v-btn(@click="remove(index)", icon, small, color="error")
                v-icon(small) mdi-delete
    v-card-actions(v-if="valid")
      v-btn(@click="cancel", text) Cancel
      v-btn(@click="submit", color="secondary", :loading="loading") Upload
      .subtitle-2.ml-2(v-if="count") {{ count }} of {{ items.length }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CSV from "/libs/utils/CSV";

export default {
  data() {
    return {
      dialog: false,
      delimiter: ",",
      delimiters: [",", ";", "|"],
      header: [],
      items: [],
      indices: {
        first_name: -1,
        last_name: -1,
        name: -1,
        employeeID: -1,
        phone: -1,
        email: -1,
        dob: -1,
        role: -1,
        wage: -1,
        pass: -1,
      },
      count: 0,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz", "pos"]),
    ...mapGetters("hr", ["persons"]),
    valid() {
      return this.items.length > 0;
    },
  },
  methods: {
    ...mapActions(["setPOS"]),
    open() {
      this.count = 0;
      this.dialog = true;
    },
    downloadSample() {
      // create sample data
      const csv = new CSV();
      const header = [
        "First Name",
        "Last Name",
        "Receipt Display Name",
        "Employee ID",
        "Phone",
        "Email",
        "DOB",
        "Role",
        "Wage",
        "Passcode",
      ];
      const items = [
        [
          "John",
          "Doe",
          "John",
          "123456",
          "5055555555",
          "john@appleseed.com",
          "01/01/1990",
          "Server",
          "2.13",
          "1234",
        ],
        [
          "Jane",
          "Doe",
          "Jane",
          "123457",
          "5055555556",
          "",
          "01/01/1990",
          "Server",
          "2.13",
          "1234",
        ],
      ];
      csv.addRow(header);
      items.forEach((row) => csv.addRow(row));
      csv.save("sample");
    },
    upload(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const lines = text.split("\n");
        if (!lines?.length) return;
        const header = lines[0].split(this.delimiter);
        const items = lines.slice(1).map((line) => {
          const values = line.split(this.delimiter);
          return header.reduce((obj, key, index) => {
            obj[key] = values[index];
            return obj;
          }, {});
        });
        this.header = header;
        // filter items with no values
        this.items = items.filter((item) =>
          Object.values(item).some((value) => value)
        );
        // find indices
        this.indices.first_name = this.getIndex("first name");
        this.indices.last_name = this.getIndex("last name");
        this.indices.name = this.getIndex("receipt display name");
        this.indices.employeeID = this.getIndex("employee id");
        this.indices.phone = this.getIndex("phone");
        this.indices.email = this.getIndex("email");
        this.indices.dob = this.getIndex("dob");
        this.indices.role = this.getIndex("role");
        this.indices.wage = this.getIndex("wage");
        this.indices.pass = this.getIndex("passcode");
      };
      reader.readAsText(file);
    },
    remove(index) {
      this.items.splice(index, 1);
    },
    cancel() {
      // reset input file
      const input = document.querySelector("input[type=file]");
      input.value = "";
      this.header = [];
      this.items = [];
      this.dialog = false;
    },
    async submit() {
      if (!this.biz?._id) return;
      let staff = [];
      for (const item of this.items) {
        const first_name = this.getValue(item, this.indices.first_name);
        const last_name = this.getValue(item, this.indices.last_name);
        const name = this.getValue(item, this.indices.name);
        let employeeID = this.getValue(item, this.indices.employeeID);
        if (employeeID == "0") employeeID = "";
        if (employeeID) employeeID = employeeID.padStart(4, "0");
        const phone = this.getValue(item, this.indices.phone);
        const email = this.getValue(item, this.indices.email);
        const dob = this.getValue(item, this.indices.dob);
        const role = this.getValue(item, this.indices.role);
        let wage = this.getValue(item, this.indices.wage, 0);
        // convert wage from currency to number, remove $ or other non-numeric characters
        wage = wage.replace(/[^0-9.]/g, "");
        wage = parseFloat(wage);
        const pass = this.getValue(item, this.indices.pass, "1234");
        const role_id = this.pos?.jobs?.find((o) => o.name === role)?._id;

        const fullname = `${first_name} ${last_name}`;
        const person = {
          enterprise: this.biz.enterprise,
          biz: this.biz._id,
          bizs: [this.biz._id],
          employeeID,
          name,
          fullname,
          first_name,
          last_name,
          type: "employee",
          phone,
          email,
          dob,
          roles: [
            {
              name: role,
              role_id: role_id,
              rate: wage,
              wage_type: "hourly",
              biz: this.biz._id,
            },
          ],
          pass,
          status: true,
        };
        const found = this.findPerson(person);
        person._id = found?._id;
        staff.push(person);
      }
      this.loading = true;
      this.count = 0;
      await this.uploadRoles(staff);
      for (const person of staff) {
        this.count++;
        await this.processServer(person);
      }
      this.dialog = false;
      this.loading = false;
    },
    async processServer(person) {
      try {
        if (person._id) {
          const action = { $set: person };
          const result = await this.$api.hr.person.put(person._id, action);
          this.$store.dispatch("hr/updatePerson", result);
        } else {
          const result = await this.$api.hr.person.create(person);
          this.$store.dispatch("hr/addPerson", result);
        }
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    findPerson(person) {
      return this.persons.find(
        (o) =>
          (o.employeeID &&
            o.employeeID != "0" &&
            o.employeeID === person.employeeID) ||
          (o.first_name === person.first_name &&
            o.last_name === person.last_name)
      );
    },
    getIndex(value) {
      return this.header.findIndex((o) => o.toLowerCase().trim() === value);
    },
    getValue(item, index, defaultValue = "") {
      if (index < 0 || !item) return defaultValue;
      const values = Object.values(item);
      if (values.length > index) return values[index].trim();
      return defaultValue;
    },
    async uploadRoles(staff) {
      const roles = staff.flatMap((o) => o.roles).map((o) => o.name);
      const unique = [...new Set(roles)];
      const jobs = this.pos?.jobs?.map((o) => o.name);
      const missing = unique.filter((o) => !jobs.includes(o));
      for (const name of missing) {
        const params = { bizId: this.biz._id, job: { name, order: true } };
        try {
          const result = await this.$api.posJob.create(params);
          this.setPOS(result);
        } catch (e) {
          // silently fail
        }
      }
    },
  },
};
</script>