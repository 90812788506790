<template lang="pug">
v-row(wrap, dense)
  v-col(v-for="(item, index) in items", :key="index", cols="12", md="4")
    Cell(:item="item", :eventId="eventId", @click="$emit('select', item._id)")
</template>

<script>
import { mapGetters } from "vuex";
import Cell from "./Cell/index";

export default {
  components: { Cell },
  props: ["eventId"],
  computed: {
    ...mapGetters(["events"]),
    // sort the events by the event_time.begin
    items() {
      return this.events?.toSorted((a, b) => {
        const abegin = a.event_time?.begin || 0;
        const bbegin = b.event_time?.begin || 0;
        return bbegin - abegin;
      });
    },
  },
};
</script>