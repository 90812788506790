<template lang="pug">
v-card(flat, v-if="items?.length")
  v-toolbar(flat, dense)
    .subtitle-2 Not Synced Roles in the 7Shifts
    v-spacer
    .subtitle-2 {{ items?.length }}
  simple-table
    thead
      tr
        th Name
        th.text-right Department
        th.text-right Action
    tbody
      tr(v-for="(role, index) in items", :key="index")
        th {{ role.name }}
        td.text-right {{ getDepartment(role.department_id) }}
        td.text-right
          .btn-box
            ImportRole(:role="role", :biz="biz")
            DeleteRole(:role="role", :biz="biz")
</template>

<script>
import { mapGetters } from "vuex";
import DeleteRole from "./DeleteRole";
import ImportRole from "./ImportRole";

export default {
  components: { ImportRole, DeleteRole },
  props: {
    biz: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(["bizservice"]),
    ...mapGetters("seven", ["departments", "roles"]),
    ...mapGetters(["pos"]),
    app() {
      return this.bizservice?.apps?.find((r) => r.name === "7shifts");
    },
    company_id() {
      return parseInt(this.app?.company_id || 0);
    },
    location_id() {
      return parseInt(this.app?.store_id || 0);
    },
    jobs() {
      return this.pos?.jobs;
    },
    // roles that are not in jobs
    items() {
      return this.roles
        ?.filter((r) => r.company_id === this.company_id)
        ?.filter((r) => r.location_id === this.location_id)
        ?.filter((r) => !this.jobs?.some((j) => j.external_id === r.id))
        .toSorted((a, b) => a.sort - b.sort);
    },
  },
  methods: {
    getDepartment(id) {
      return this.departments?.find((x) => x.id === id)?.name || "Not Assigned";
    },
  },
};
</script>

<style lang="sass" scoped>
.btn-box
  display: flex
  justify-content: end
  gap: 6px
</style>