<template lang="pug">
Page(:prefix="prefix", title="Partner", :doc="doc")
  GroupTiles(:groups="items")
</template>

<script>
import PartnerNav from "@/components/basic/PartnerNav.json";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      prefix: "Selflane Partner",
      doc: "https://docs.selflane.com/partner",
      navItems: JSON.parse(JSON.stringify(PartnerNav)),
    };
  },
  computed: {
    ...mapGetters(["salesPerson"]),
    isOwner() {
      return this.salesPerson?.org_access == 1;
    },
    items() {
      if (this.isOwner) {
        return this.navItems;
      } else {
        return this.navItems.filter((item) => item.privilege == 2);
      }
    },
  },
};
</script>