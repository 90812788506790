<template lang="pug">
IconBtn(
  @click="submit()",
  title="All Timecards",
  icon="mdi-timetable",
  :loading="loading"
)
</template>

<script>
import { EventBus } from "@/event-bus";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    range: { type: Array, required: true },
  },
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    ...mapActions(["setPunchcards"]),
    async submit() {
      if (!this.range || this.range.length != 2) return;
      const begin = moment(this.range[0]).startOf("day").valueOf();
      const end = moment(this.range[1]).endOf("day").valueOf();
      this.loading = true;
      try {
        const criteria = {
          biz: this.biz._id,
          clockIn: { $gte: begin, $lte: end },
        };
        const result = await this.$api.punchcard.list({ criteria });
        this.setPunchcards(result);
        EventBus.$emit("show-punchcard-dialog");
      } catch (e) {
        this.setPunchcards([]);
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
