<template lang="pug">
v-row(dense, wrap, justify-center)
  v-col(cols="12", md="2")
  v-col(cols="12", md="4")
    StandardChart(
      :input="countChart",
      maxWidth="100%",
      chartType="bar",
      showDownload
    )
  v-col(cols="12", md="4")
    StandardChart(
      :input="valueChart",
      maxWidth="100%",
      chartType="bar",
      showDownload
    )
  v-col(cols="12", md="2")
</template>

<script>
import _ from "underscore";

export default {
  props: {
    items: { type: Array, default: () => [] },
  },
  computed: {
    hours() {
      return _.range(0, 24);
    },
    // get 0-23 hours in 12 am - 11 pm format
    labels() {
      return this.hours.map((o) => {
        const hour = o % 12 || 12;
        const ampm = o < 12 ? "am" : "pm";
        return `${hour} ${ampm}`;
      });
    },
    hourData() {
      if (!this.items?.length) return null;
      const items = this.items
        .flatMap((o) => o.grouped_summaries)
        .filter((o) => o.order_status >= 0);
      return _.chain(items)
        .groupBy("hour")
        .map((value) => {
          const amount = value.reduce((a, b) => a + b.payment.net, 0);
          const count = value.reduce((a, b) => a + b.count, 0);
          return { amount, count, hour: value[0].hour };
        })
        .value();
    },
    countChart() {
      if (!this.hourData?.length) return null;
      const values = this.hours.map((o) => {
        const total = this.hourData?.find((p) => p.hour === o)?.count || 0;
        return Math.round((total / this.items.length) * 10) / 10;
      });
      return {
        title: "# Orders / Day",
        labels: this.labels,
        values,
      };
    },
    valueChart() {
      if (!this.hourData?.length) return null;
      const values = this.hours.map((o) => {
        const total = this.hourData?.find((p) => p.hour === o)?.amount || 0;
        return Math.round((total / this.items.length) * 100) / 100;
      });
      return {
        title: "Net Sales / Day",
        labels: this.labels,
        values,
        isCurrency: true,
      };
    },
  },
};
</script>