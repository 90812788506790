<template lang="pug">
div
  v-card.my-3(outlined)
    v-toolbar(flat, dense)
      .subtitle-2 Discounts
      v-spacer
      IconBtn(@click="download()", title="CSV", icon="mdi-download")
    v-card-text.py-1
      span Discounts applied in the selected period: {{ period }}
    v-data-table(
      :headers="headers",
      :items="items",
      mobile-breakpoint="0",
      dense,
      v-if="items"
    )
      template(v-slot:item.amount="{ item }")
        span {{ -item.amount | currency }}
      template(v-slot:item.orderIds="{ item }")
        v-btn.text-capitalize(
          small,
          rounded,
          depressed,
          @click="showOrders(item.orderIds)"
        ) Show Orders
</template>

<script>
import CSV from "/libs/utils/CSV";

import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  props: {
    items: { type: Array, default: null },
    period: { type: String, default: "" }, // YYYY-MM-DD to YYYY-MM-DD
  },
  data() {
    return {
      headers: [
        { text: "Type", value: "type" },
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount", align: "end" },
        { text: "Orders", value: "orderIds", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    showOrders(ids) {
      const options = {
        criteria: { _id: { $in: ids } },
        select: "orderer needed created orderNumber status payment",
      };
      EventBus.$emit("show-orders-dialog", options);
    },
    download() {
      if (!this.items) return;
      const csv = new CSV();
      csv.addRow([this.biz.name]);
      csv.addRow(["Type", "Name", "Amount"]);
      this.items.forEach((item) => {
        csv.addRow([item.type, item.name, item.amount]);
      });
      csv.save(`discounts-stats-${this.period}`);
    },
  },
};
</script>
