<template lang="pug">
v-select(
  v-model="content",
  :items="items",
  label="Lead Time",
  menu-props="auto",
  dense,
  hide-details
)
</template>

<script>
import TimeItems from "@/components/JSON/TimeItems.json";

export default {
  props: {
    value: { type: Number, default: 0 },
  },
  data() {
    return {
      content: this.value,
      items: TimeItems,
    };
  },
  watch: {
    content(val) {
      this.$emit("input", val);
    },
  },
};
</script>