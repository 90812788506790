<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";

export default {
  props: ["charges", "sum"],
  methods: {
    download() {
      const currency = this.$options.filters.currency;
      const datetime = this.$options.filters.datetime;
      const csv = new CSV();
      csv.addRow(["Order", "Server", "Method", "Base", "Tip", "Total", "Time"]);
      this.charges.forEach((item) => {
        csv.addRow([
          item.orderNumber,
          item.server,
          item.method,
          currency(item.base),
          currency(item.tip),
          currency(item.amount),
          datetime(item.time),
        ]);
      });
      csv.addRow([
        "Sum",
        "",
        "",
        currency(this.sum.base),
        currency(this.sum.tip),
        currency(this.sum.total),
        "",
      ]);
      csv.save("payment_list");
    },
  },
};
</script>
