import Transactions from './index'
import Store from './Store/index.vue'
import Batch from './Batch/index.vue'

export default [
  {
    path: '/txn', component: Transactions, meta: { auth: true },
    children: [
      { path: '', redirect: 'store' },
      { path: 'store', component: Store },
      { path: 'batch', component: Batch }
    ]
  }
]