<template lang="pug">
v-card(v-if="data", flat)
  div(v-if="data.exccbreakdown && data.exccbreakdown.length")
    Table(:items="data.exccbreakdown")
      template(v-slot:header)
        thead
          tr
            th Excard
            th.text-right Amount
  div(v-if="data.giftbreakdown && data.giftbreakdown.length")
    Table(:items="data.giftbreakdown")
      template(v-slot:header)
        thead
          tr
            th Gift Redeemed
            th.text-right Amount
  Table(:items="data.chargesByStation")
    template(v-slot:header)
      thead
        tr
          th Stations
          th.text-right Amount
</template>

<script>
import Table from "./Table";

export default {
  components: { Table },
  props: ["data"],
};
</script>
