<template lang="pug">
v-btn(@click="open", color="secondary", x-small, depressed, :loading="loading") Import
</template>

<script>
export default {
  props: {
    biz: { type: Object, required: true },
    user: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async open() {
      this.loading = true;
      try {
        const roleAssignments = await this.getRoleAssignment();
        const userWage = await this.getUserWage();
        const roles = roleAssignments?.map((o) => {
          const rate =
            userWage?.current_wages?.find((w) => w.role_id === o.role_id)
              ?.wage_cents || 0;
          return {
            name: o.name,
            rate: rate / 100,
            wage_type: "hourly",
            department_id: o.department_id,
            external_id: o.role_id,
          };
        });
        const person = {
          biz: this.biz._id,
          employeeID: this.user.employee_id,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          name: this.user.preferred_first_name || this.user.first_name,
          email: this.user.email,
          phone: this.user.mobile_number,
          dob: this.user.birth_date,
          roles: roles,
          status: true,
        };
        this.$emit("import", person);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async getRoleAssignment() {
      if (!this.biz) return this.$toast.error("No business selected");
      if (!this.user?.id) return this.$toast.error("No user selected");
      const params = { bizId: this.biz._id, userId: this.user.id };
      const result = await this.$api.seven.roleAssignment.list(params);
      return result;
    },
    async getUserWage() {
      if (!this.biz) return this.$toast.error("No business selected");
      if (!this.user?.id) return this.$toast.error("No user selected");
      const params = { bizId: this.biz._id, userId: this.user.id };
      const result = await this.$api.seven.userWage.list(params);
      return result;
    },
  },
};
</script>