<template lang="pug">
v-data-table(
  :headers="headers",
  :items="items",
  :search="search",
  dense,
  mobile-breakpoint="0"
)
  template(v-slot:top)
    .d-flex.flex-row.align-center.px-4
      v-text-field.mr-4(v-model="search", label="Search", clearable)
      CSVDownload(:items="csvData", file-name="product_mix_by_staff")
  template(v-slot:item.amount="{ item }")
    span {{ item.amount | currency }}
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import CSVDownload from "@/components/basic/CSVDownload";

export default {
  components: { CSVDownload },
  props: {
    data: { type: Array, required: true },
    dateRange: { type: String, required: true },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Quantity", value: "quantity" },
        { text: "Total", value: "amount" },
        { text: "Staff", value: "staff_name" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    items() {
      return _.chain(this.data)
        .sortBy((o) => -o.amount)
        .sortBy((o) => o.staff_id + o.staff_name)
        .value();
    },
    csvData() {
      if (!this.items || !this.items.length || !this.biz) return [];
      const currency = this.$options.filters.currency;
      let data = [_.pluck(this.headers, "text")];
      _.each(this.items, (item) => {
        data.push([
          item.name,
          item.quantity,
          currency(item.amount),
          item.staff_name,
        ]);
      });
      data.push([]); // spacing row
      data.push([this.dateRange]);
      data.push([this.biz.name]);
      return data;
    },
  },
  methods: {
    getMenuName(val) {
      return this.biz.menus.find((o) => o._id == val)?.name || "N/A";
    },
    getCourseName(val) {
      return this.biz.courses.find((o) => o._id == val)?.name || "N/A";
    },
  },
};
</script>
