<template lang="pug">
.d-flex.flex-row.justify-center(v-if="posEditable")
  v-card(max-width="500", width="100%", flat, :loading="loading")
    v-toolbar(flat, dense)
      .subtitle-2 Customize Discount Names
      v-spacer
      v-btn(outlined, small, @click="more()")
        span Add
        v-icon(small, right) mdi-plus
    v-form(@submit.prevent="submit")
      simple-table
        tbody
          tr(v-for="(item, index) in posEditable.discounts", :key="index")
            td
              v-text-field(
                v-model.trim="item.name",
                autofocus,
                ref="focus",
                hide-details,
                single-line,
                @keydown.down="more()"
              )
            td.text-right
              v-btn(icon, @click="less(index)", small, color="error")
                v-icon(small) mdi-delete
      v-card-actions(v-if="dirty")
        v-btn(color="secondary", type="submit", :loading="loading") Save
        v-spacer
        v-btn(text, @click="cancel()") Cancel
</template>

<script>
import _ from "underscore";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["pos", "posEditable"]),
    dirty() {
      return JSON.stringify(this.posEditable) !== JSON.stringify(this.pos);
    },
  },
  methods: {
    ...mapActions(["setPOS", "resetEditable"]),
    more() {
      if (!this.posEditable.discounts) {
        this.posEditable = _.extend(this.posEditable, {
          discounts: { name: "" },
        });
      }
      this.posEditable.discounts.push({ name: "" });
    },
    less(index) {
      if (
        this.posEditable.discounts &&
        this.posEditable.discounts.length > index
      ) {
        this.posEditable.discounts.splice(index, 1);
      }
    },
    async submit() {
      this.loading = true;
      try {
        const action = {
          $set: {
            discounts: this.posEditable.discounts,
          },
        };
        const result = await this.$api.pos.put(this.pos?._id, { action });
        this.setPOS(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    cancel() {
      this.resetEditable();
    },
  },
};
</script>
