<template lang="pug">
v-card(flat)
  v-card-text.text-center
    h1.mb-3.secondary--text {{ total_members }}
    h2.my-3 Members
    .caption.my-3.font-weight-medium Loaded {{ members.length }}
    v-btn(
      @click="more",
      color="primary",
      small,
      depressed,
      v-if="members.length < total_members",
      :loading="loading"
    )
      span.text-notransform Load More
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz", "members", "total_members"]),
  },
  mounted() {
    this.reset();
    EventBus.$on("switch-biz", this.reset);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.reset);
  },
  methods: {
    ...mapActions(["setMembers", "addMembers", "setTotalMembers"]),
    more() {
      if (this.members.length >= this.total_members) return;
      this.load(false);
    },
    async load(fresh) {
      if (!this.biz) return;
      let end = fresh ? Date.now() : _.last(this.members).created;
      this.loading = true;
      const params = {
        criteria: { biz: this.biz._id, created: { $lt: end } },
        limit: 500,
        sort_method: "-created",
      };
      const members = await this.$api.member.list(params);
      if (fresh) this.setMembers(members);
      else {
        this.addMembers(members);
        this.$toast.success(`Loaded ${members?.length} more members`);
      }
      this.loading = false;
    },
    async reset() {
      if (!this.biz) return;
      const params = { criteria: { biz: this.biz._id } };
      this.loading = true;
      try {
        const { count } = await this.$api.member.count(params);
        this.setTotalMembers(count);
        this.load(true);
      } catch (e) {
        this.setMembers([]);
        this.setTotalMembers(0);
      }
      this.loading = false;
    },
  },
};
</script>
