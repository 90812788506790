<template lang="pug">
v-card(v-if="data", flat)
  simple-table
    thead
      tr
        th Discounts
        th.text-right {{ total | currency }}
    tbody
      tr(v-for="(item, index) in data", :key="index", @click="show(item.id)")
        td \#{{ item.orderNumber }} {{ item.pctOff | pctOff }}{{ item.name }}
        td.text-right {{ item.value | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  filters: {
    pctOff(value) {
      if (value && value > 0) return "-" + value + "% ";
      return "";
    },
  },
  props: ["data"],
  computed: {
    total() {
      return this.data?.reduce((acc, cur) => acc + cur.value, 0);
    },
  },
  methods: {
    show(id) {
      if (!id) return;
      EventBus.$emit("show-order-detail", id);
    },
  },
};
</script>
