<template lang="pug">
v-row(justify="center")
  v-card(max-width="400", width="100%", flat)
    v-card-text
      Status
      Program
</template>

<script>
import Status from "./Status";
import Program from "./Program";

export default {
  components: { Status, Program },
};
</script>