<template lang="pug">
div
  v-card(outlined)
    v-toolbar(flat, dense)
      .subtitle-2 Special Influencer Program
      v-spacer
      v-btn.text-notransform(outlined, small, @click="open()")
        span Add
        v-icon(small, right) mdi-plus
    v-card-text
      List(:plans="plans")
      div In this program, premium influencers are allowed to promote your business from their channels and bring you sales. They will earn commission on the promoted sales.
  Create
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import List from "./List";
import Create from "./Create";
import moment from "moment-timezone";
import { EventBus } from "@/event-bus.js";

export default {
  components: { List, Create },
  computed: {
    ...mapGetters(["biz", "influencerPlans"]),
    plans() {
      return _.filter(this.influencerPlans, (o) => !o.is_open && o.influencer);
    },
  },
  methods: {
    ...mapActions(["setInfluencers"]),
    async loadInfluencers() {
      const params = { criteria: { level: 2 } };
      const result = await this.$api.influencer.account.list(params);
      this.setInfluencers(result);
    },
    open() {
      const plan = {
        biz: this.biz._id,
        type: "fix",
        pct: 5,
        fix: 5,
        minimum: 10,
        limit: 100,
        commission_rate: 5,
        is_open: false,
        expiry: moment().add(12, "months").format("YYYY-MM-DD"),
      };
      EventBus.$emit("edit-influencer-special-plan", plan);
    },
  },
};
</script>
