<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card
    v-card-title {{ title }}
    form(@submit.prevent="save")
      v-card-text
        v-row
          v-col(cols="6")
            v-text-field(v-model="item.name", label="Name")
          v-col(cols="6")
            v-text-field(
              v-model.number="item.price",
              label="Price",
              type="number"
            )
        v-text-field(v-model="item.description", label="Description")
      v-card-actions
        v-btn(color="primary", type="submit") Save
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      item: { name: "", price: 0, description: "" },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      return this.item._id ? "Edit Option" : "Add Option";
    },
  },
  methods: {
    open(input) {
      if (input) this.item = JSON.parse(JSON.stringify(input));
      else this.item = { name: "", price: 0, description: "" };
      this.dialog = true;
    },
    validate() {
      this.item.name = this.item.name?.trim();
      if (!this.item.name) throw new Error("Name is required");
      if (!this.item.price) throw new Error("Price is required");
      if (this.item.price < 0) throw new Error("Price cannot be negative");
    },
    async save() {
      this.loading = true;
      try {
        this.validate();
        if (this.item._id) {
          const criteria = { _id: this.item._id };
          const action = {
            $set: {
              name: this.item.name,
              price: this.item.price,
              description: this.item.description,
            },
          };
          const result = await this.$api.b.rsvtoption.update({
            criteria,
            action,
          });
          this.$store.dispatch("updateRsvtOption", result);
        } else {
          const data = {
            biz: this.biz._id,
            name: this.item.name,
            price: this.item.price,
            description: this.item.description,
          };
          const result = await this.$api.b.rsvtoption.create(data);
          this.$store.dispatch("addRsvtOption", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>