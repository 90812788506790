<template lang="pug">
div
  v-progress-linear(indeterminate, v-if="loading", color="secondary")
  .link-box
    WeekSelector(@select="load", :day="startDay", :timezone="timezone")
    PayrollSettings
  Charts(:startDay="startDay")
  LaborReport(ref="laborReport", max-width="100%", default-mode="employees")
  List
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters, mapActions } from "vuex";
import Charts from "./Charts";
import List from "@/components/HR/Punchcard/List/index";
import moment from "moment-timezone";

export default {
  components: { Charts, List },
  data() {
    return {
      loading: false,
      begin: null, // unix timestamp
      end: null,
    };
  },
  computed: {
    ...mapGetters(["biz", "pos"]),
    startDay() {
      return this.pos?.startDay || 0;
    },
    startHour() {
      return this.pos?.startHour || 0;
    },
    beginDate() {
      return moment.tz(this.begin, this.timezone).format("YYYY-MM-DD");
    },
    endDate() {
      return moment.tz(this.end, this.timezone).format("YYYY-MM-DD");
    },
    timezone() {
      return this.biz?.address?.timezone || moment.tz.guess();
    },
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
    this.setPunchcards([]);
  },
  methods: {
    ...mapActions(["setPunchcards"]),
    async load({ range }) {
      if (range) {
        this.begin =
          moment.tz(range[0], this.timezone).startOf("day").unix() * 1000;
        this.end =
          moment.tz(range[1], this.timezone).endOf("day").unix() * 1000;
      }
      if (!this.begin || !this.end) return;
      this.loading = true;
      const shift = this.startHour * 3600 * 1000;
      const params = {
        criteria: {
          biz: this.biz._id,
          clockIn: {
            $gte: this.begin + shift,
            $lte: this.end + shift,
          },
        },
      };
      const result = await this.$api.punchcard.list(params);
      this.setPunchcards(result);
      const params2 = {
        bizId: this.biz._id,
        beginDate: this.beginDate,
        endDate: this.endDate,
      };
      this.$refs.laborReport?.load(params2);
      this.loading = false;
    },
  },
};
</script>
