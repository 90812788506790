<template lang="pug">
simple-table
  thead
    tr
      th Name
      th Value
      th.nowrap Applied Types
      th
  tbody
    tr(
      v-for="(item, index) in biz.service_items",
      :key="index",
      @click="edit(item)",
      role="button",
      draggable="true",
      @dragstart="drag(index, $event)",
      @dragend="dragend($event)",
      @drop="drop(index, $event)",
      @dragover.prevent=""
    )
      td
        .d-flex.flex-row.align-center
          v-icon.mr-2(x-small) mdi-menu
          span.nowrap {{ item.name }}
      td {{ getAmount(item) }}
      td {{ getOrderTypes(item.orderTypes) }}
      td.text-right
        DeleteOne(:item="item")
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import DeleteOne from "./DeleteOne";

export default {
  components: { DeleteOne },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    getOrderTypes(items) {
      return items?.join(",") || "All";
    },
    getAmount(item) {
      if (item.type == 1) return this.$options.filters.currency(item.amount);
      else return `${item.percentage}%`;
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: async function (index) {
      if (index == this.draggingIndex) return;
      if (!this.biz || !this.biz.service_items) return;
      if (index < 0 || index >= this.biz.service_items.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.biz.service_items.length
      )
        return;
      let service_items = JSON.parse(JSON.stringify(this.biz.service_items));
      let tmp = JSON.parse(JSON.stringify(service_items[this.draggingIndex]));
      service_items.splice(this.draggingIndex, 1);
      service_items.splice(index, 0, tmp);
      const action = { $set: { service_items } };
      const result = await this.$api.biz.put(this.biz._id, action);
      this.$store.dispatch("setBiz", result);
    },
    edit(item) {
      EventBus.$emit("edit-service-item", item);
    },
  },
};
</script>