<template lang="pug">
v-card(v-if="biz", flat)
  v-toolbar(dense, flat)
    .subtitle-2 Online Ordering
    v-spacer
    Form
  simple-table
    tbody
      Leadtime(title="Pick Up", :data="biz.orderType.pickup")
      Leadtime(title="Curbside", :data="biz.orderType.curbside")
      Leadtime(title="Dine In", :data="biz.orderType.dinein")
      tr(v-if="biz.orderType.limited_access?.status")
        th Limited Access
        td.text-right
          span {{ biz.orderType.limited_access?.status ? "Yes" : "No" }}
      GiftCard
      AllowTip
  v-card-text
    .link-box
      a(:href="url", target="_blank") Business Online Store Page
      ClickToCopy(:text="url", icon)
</template>

<script>
import { mapGetters } from "vuex";
import AllowTip from "./AllowTip";
import Form from "./Form";
import GiftCard from "./GiftCard";
import Leadtime from "./Leadtime";

export default {
  components: { Leadtime, GiftCard, AllowTip, Form },
  computed: {
    ...mapGetters(["biz"]),
    url() {
      if (!this.biz?.url) return null;
      return "https://selflane.com/bizs/" + this.biz.url;
    },
  },
};
</script>
