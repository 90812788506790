<template lang="pug">
.my-5
  v-container
    .text-center.my-10
      .display-1.my-3 Selflane Business
      .subtitle-2 Setup, Manage, Monitor. Anywhere, Anytime.
      v-btn.text-capitalize.mt-5(
        x-large,
        color="secondary",
        rounded,
        depressed,
        to="/login"
      ) Login to My Account
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
#main-body
  background: linear-gradient( 90deg, rgba(236, 243, 248, 1) 0%, rgba(213, 228, 243, 1) 100% )
</style>
