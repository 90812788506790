<template lang="pug">
.cell-container
  .label-block
    .text--secondary {{ title }}
  .value-block
    slot
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
  },
};
</script>

<style lang="sass" scoped>
.cell-container
  display: flex
  flex-direction: column
  gap: 0.5rem
  padding: 1rem
  flex-grow: 1
.label-block
  display: flex
  flex-direction: row
  font-size: 1rem
  font-weight: 500
  gap: 0.5rem
.value-block
  font-size: 2.5rem
</style>