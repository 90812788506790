<template lang="pug">
div(v-if="visible")
  v-alert(type="warning") You can permanently delete this employee if there is no associated timecard.
  v-checkbox(
    v-model="confirm",
    label="I understand that this action is irreversible.",
    color="error"
  )
  v-btn(
    color="error",
    block,
    @click="submit",
    :loading="loading",
    :disabled="!confirm"
  ) Delete Employee
</template>

<script>
export default {
  props: ["server"],
  data() {
    return {
      confirm: false,
      loading: false,
    };
  },
  computed: {
    visible() {
      return this.server?._id && !this.server.status;
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const result = await this.$api.hr.person.delete(this.server._id);
        this.$store.dispatch("hr/removePerson", result);
        this.$emit("deleted");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.confirm = false;
      this.loading = false;
    },
  },
};
</script>
