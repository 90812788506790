<template lang="pug">
Page(title="Seating", :doc="doc")
  template(v-slot:header)
    MultiForm
  .s-vstack
    List
    .link-box
      v-chip(
        v-for="(item, index) in indicators",
        :key="index",
        :color="item.color",
        text-color="white",
        small
      )
        v-avatar
          v-icon(small) {{ item.icon }}
        span {{ item.text }}
  Edit
</template>

<script>
import Edit from "./Edit";
import List from "./List/index";
import MultiForm from "./MultiForm";

export default {
  components: { List, Edit, MultiForm },
  data() {
    return {
      doc: "https://docs.selflane.com/biz/settings/seating.html",
      indicators: [
        { color: "green", icon: "mdi-earth", text: "available online" },
        { color: "red", icon: "mdi-delete", text: "delete" },
      ],
    };
  },
};
</script>
