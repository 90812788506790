<template lang="pug">
.d-flex.flex-row.align-center
  v-menu(offset-y)
    template(v-slot:activator="{ on }")
      v-text-field.flex-box(
        v-on="on",
        :label="date_label",
        v-model="dateText",
        readonly=""
      )
    v-date-picker(v-model="date")
  span.mx-1
  v-menu(:close-on-content-click="false", offset-y)
    template(v-slot:activator="{ on }")
      v-text-field.flex-box-right(
        v-on="on",
        :label="time_label",
        v-model="ampm",
        readonly=""
      )
    v-time-picker(v-model="time")
</template>

<script>
/// pick a datetime
/// input value should be unix timestamp

import moment from "moment-timezone";

export default {
  name: "DatetimePicker",
  props: {
    value: { type: Number, required: true }, // unix timestamp
    date_label: { type: String, default: "Date" },
    time_label: { type: String, default: "Time" },
    timezone: { type: String, default: () => moment.tz.guess() },
  },
  data() {
    return {
      date: moment(this.value).tz(this.timezone).format("YYYY-MM-DD"),
      time: moment(this.value).tz(this.timezone).format("HH:mm"), // 24 hour
    };
  },
  computed: {
    dateText() {
      return moment
        .tz(this.date, "YYYY-MM-DD", this.timezone)
        .format("MMM D, YYYY");
    },
    ampm() {
      return moment
        .tz(`${this.date} ${this.time}`, "YYYY-MM-DD HH:mm", this.timezone)
        .format("h:mm A");
    },
  },
  watch: {
    value() {
      if (!this.value) return;
      this.date = moment(this.value).tz(this.timezone).format("YYYY-MM-DD");
      this.time = moment(this.value).tz(this.timezone).format("HH:mm");
    },
    date() {
      const datetime = moment.tz(
        `${this.date} ${this.time}`,
        "YYYY-MM-DD HH:mm",
        this.timezone
      );
      this.$emit("input", datetime.valueOf());
    },
    time() {
      const datetime = moment.tz(
        `${this.date} ${this.time}`,
        "YYYY-MM-DD HH:mm",
        this.timezone
      );
      this.$emit("input", datetime.valueOf());
    },
  },
};
</script>

<style lang="sass" scoped>
.flex-box
  flex: 2
  &-right
    flex: 1
</style>
