const state = {
  biz: null,
  domain: null,
  rsvtsetting: null,
  rsvtoptions: [],
  bookedups: [],
}

const getters = {
  biz: state => state.biz,
  domain: state => state.domain,
  rsvtsetting: state => state.rsvtsetting,
  rsvtoptions: state => state.rsvtoptions,
  bookedups: state => state.bookedups,
}

const actions = {
  setBiz: ({ commit }, data) => { commit('setBiz', data) },
  setDomain: ({ commit }, data) => { commit('setDomain', data) },
  setRsvtSetting: ({ commit }, data) => { commit('setRsvtSetting', data) },
  setRsvtOptions: ({ commit }, data) => { commit('setRsvtOptions', data) },
  updateRsvtOption: ({ commit }, data) => { commit('updateRsvtOption', data) },
  addRsvtOption: ({ commit }, data) => { commit('addRsvtOption', data) },
  removeRsvtOption: ({ commit }, data) => { commit('removeRsvtOption', data) },
  setBookedups: ({ commit }, data) => { commit('setBookedups', data) },
  updateBookedup: ({ commit }, data) => { commit('updateBookedup', data) },
  addBookedup: ({ commit }, data) => { commit('addBookedup', data) },
  removeBookedup: ({ commit }, data) => { commit('removeBookedup', data) },
}

const mutations = {
  setBiz(state, data) { state.biz = data },
  setDomain(state, data) { state.domain = data },
  setRsvtSetting(state, data) { state.rsvtsetting = data },
  setRsvtOptions(state, data) { state.rsvtoptions = data },
  updateRsvtOption(state, data) {
    state.rsvtoptions = state.rsvtoptions.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addRsvtOption(state, data) { state.rsvtoptions.push(data) },
  removeRsvtOption(state, data) {
    state.rsvtoptions = state.rsvtoptions.filter(o => {
      return (o._id !== data._id)
    })
  },
  setBookedups(state, data) { state.bookedups = data },
  updateBookedup(state, data) {
    state.bookedups = state.bookedups.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addBookedup(state, data) { state.bookedups.push(data) },
  removeBookedup(state, data) {
    state.bookedups = state.bookedups.filter(o => {
      return (o._id !== data._id)
    })
  },
}

export default { state, getters, actions, mutations }