<template lang="pug">
Page(title="Influencer Programs", :items="items", :doc="doc")
</template>

<script>
export default {
  data() {
    return {
      doc: "https://docs.selflane.com/biz/marketing/influencer.html",
      items: [{ title: "Settings", url: "/marketing/influencer/settings" }],
    };
  },
};
</script>
