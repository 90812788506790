<template lang="pug">
div
  .link-box.mb-3
    div(style="max-width: 250px")
      v-text-field(
        v-model="searchText",
        clearable,
        prepend-inner-icon="search",
        hide-details,
        solo,
        dense,
        placeholder="Search Name"
      )
    .select-box
      v-select(
        v-model="filterType",
        :items="filterItems",
        label="Filter",
        solo,
        flat,
        dense,
        outlined,
        hide-details,
        color="secondary",
        item-color="secondary"
      )
    .select-box
      v-select(
        v-model="status",
        :items="status_items",
        label="Status",
        solo,
        flat,
        dense,
        outlined,
        hide-details,
        color="secondary",
        item-color="secondary"
      )
    v-spacer
    IconBtn.mr-3(@click="create()", title="Add", icon="mdi-plus")
    Download
  List(:items="filtered", :searchText="searchText")
  Form
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Download from "./Download";
import List from "./List";
import Form from "./Form";

export default {
  components: { Download, List, Form },
  data() {
    return {
      searchText: "",
      filterType: 0,
      filterItems: [
        { text: "All", value: 0 },
        { text: "Required", value: 1 },
        { text: "Optional", value: 2 },
        { text: "Online", value: 3 },
        { text: "Offline", value: 4 },
      ],
      status: true, // used to filter items with existing dishes
      status_items: [
        { text: "Active", value: true },
        { text: "Not Used", value: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz", "bizmodifiers"]),
    filtered() {
      const items = this.bizmodifiers.filter((o) => {
        if (this.status) return o.dishes.length > 0;
        return o.dishes.length == 0;
      });
      if (this.filterType == 1) {
        return items.filter((o) => o.modifier?.required);
      } else if (this.filterType == 2) {
        return items.filter((o) => !o.modifier?.required);
      } else if (this.filterType == 3) {
        return items.filter((o) => o.modifier?.ol);
      } else if (this.filterType == 4) {
        return items.filter((o) => !o.modifier?.ol);
      }
      return items;
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
    this.setBizmodifiers([]);
  },
  methods: {
    ...mapActions(["setBizmodifiers"]),
    create() {
      if (!this.biz || !this.biz._id) return;
      const data = {
        bizId: this.biz._id,
        dishes: [],
        modifier: {
          name: "",
          maxSelection: 1,
          choices: [],
          associatedChoices: [],
        },
      };
      EventBus.$emit("edit-modifier", data);
    },
    async load() {
      if (!this.biz || !this.biz._id) return;
      const params = { criteria: { bizId: this.biz._id } };
      const result = await this.$api.bizModifier.list(params);
      this.setBizmodifiers(result);
    },
  },
};
</script>

<style lang="sass" scoped>
.select-box
  max-width: 160px
</style>