<template lang="pug">
v-card(v-if="redeems", flat)
  simple-table
    thead
      tr
        th Gift Card Redeemed
        th.text-right {{ redeems.amount | currency }}
    tbody
      tr(v-for="(item, index) in redeems.items", :key="index")
        td {{ item.type }} ({{ item.count }})
        td.text-right {{ item.amount | currency }}
</template>

<script>
export default {
  props: ["redeems"],
};
</script>
