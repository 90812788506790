<template lang="pug">
div
  .mb-2 Access Token is a unique key that allows you to connect your 7Shifts account with Biz. Please follow the poped window to generate your Access Token.
  v-btn(
    color="secondary",
    outlined,
    block,
    @click="popWindow",
    v-if="!clicked"
  ) Connect to 7Shifts Account
  v-form(v-else)
    v-text-field(
      label="7Shifts Access Token",
      v-model="guid",
      placeholder="Enter your 7Shifts Access Token here"
    )
    v-btn(
      color="secondary",
      outlined,
      block,
      @click="connect",
      :disabled="!guid",
      :loading="loading"
    ) Connect
</template>

<script>
export default {
  data() {
    return {
      client_id: "8a0d286a559a651064f3366e98c3b7c839be607c",
      clicked: false,
      guid: "",
      loading: false,
    };
  },
  methods: {
    popWindow() {
      const url = `https://app.7shifts.com/generate_token?client_id=${this.client_id}`;
      window.open(url, "_blank");
      this.clicked = true;
    },
    async connect() {
      if (!this.guid) return;
      this.loading = true;
      try {
        const companies = await this.$api.seven.company.list({
          guid: this.guid,
        });
        const data = {
          guid: this.guid,
          companies,
        };
        this.$emit("connect", data);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>