const state = {
  order: null,
  orders: [],
  booked_tables: []
}

const getters = {
  order: state => state.order,
  orders: state => state.orders,
  booked_tables: state => state.booked_tables
}

const actions = {
  setOrder: ({ commit }, data) => { commit('setOrder', data) },
  setOrders: ({ commit }, data) => { commit('setOrders', data) },
  updateOrder: ({ commit }, data) => { commit('updateOrder', data) },
  updateManyOrders: ({ commit }, data) => { commit('updateManyOrders', data) },
  setBookedTables: ({ commit }, data) => { commit('setBookedTables', data) },
  addBookedTable: ({ commit }, data) => { commit('addBookedTable', data) },
  updateBookedTable: ({ commit }, data) => { commit('updateBookedTable', data) }
}

const mutations = {
  setOrder(state, data) {
    state.order = data
  },
  setOrders(state, data) {
    state.orders = data
  },
  updateOrder(state, data) {
    state.order = data
    state.orders = state.orders.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  updateManyOrders(state, list) {
    state.orders = state.orders.map(o => {
      const found = list.find(item => item._id == o._id)
      return found || o
    })
  },
  setBookedTables(state, data) {
    state.booked_tables = data
  },
  addBookedTable(state, data) {
    state.booked_tables.push(data)
  },
  updateBookedTable(state, data) {
    state.booked_tables = state.booked_tables.map(o => {
      if (o._id === data._id) return data
      return o
    })
  }
}

export default { state, getters, actions, mutations }