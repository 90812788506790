<template lang="pug">
#sudoku.grid-container
  .subtitle-2 Sudoku
  .grid-wrapper
    .grid-row(v-for="(gridrow, i) in subgrids", :key="i + 'grids'")
      table(v-for="(grid, j) in gridrow", :key="j + 'grid'")
        tbody
          tr(v-for="(row, k) in grid", :key="k + 'row'")
            Cell(
              v-for="(cell, l) in row",
              :key="l + 'cell'",
              :cell="cell",
              @save="edit($event, i, j, k, l)",
              :hide_hint="hide_hint"
            )
  .subtitle {{ status }} {{ score }}
  .link-box
    v-btn(
      @click="create",
      color="primary",
      dark,
      small,
      v-if="status == 'new'"
    ) Create
    v-btn(@click="reset", color="primary", dark, small, v-else) Reset
    v-btn(
      @click="solve",
      color="secondary",
      dark,
      small,
      v-if="status == 'unsolved'"
    ) Solve
  .link-box
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        IconBtn(v-on="on", icon="mdi-menu-down", title="Sample")
      v-list
        v-list-item(@click="setEasy") Easy
        v-list-item(@click="setHard") Hard
        v-list-item(@click="setEvil") Evil
  v-switch(v-model="hide_hint", label="Hide Hint", color="secondary")
  v-btn(@click="handlePrint") Print
</template>

<script>
import Cell from "./Cell";
import Sudoku from "/games/sudoku/main.js";

export default {
  name: "Sudoku",
  components: { Cell },
  data() {
    return {
      // grid is a 9x9 array of integers filled with 0s
      grid: Array.from({ length: 9 }, () => Array.from({ length: 9 }, () => 0)),
      sudoku: null,
      subgrids: [],
      solvable: false,
      status: "new",
      score: 0,
      hide_hint: true,
    };
  },
  mounted() {
    this.create();
  },
  methods: {
    edit(value, i, j, k, l) {
      const row = i * 3 + k;
      const col = j * 3 + l;
      this.grid[row][col] = parseInt(value);
    },
    async create() {
      this.sudoku = new Sudoku(this.grid);
      this.subgrids = this.sudoku.getSubgrids();
      this.solvable = this.sudoku.getSolvable();
      this.status = this.sudoku.getStatus();
      this.score = this.sudoku.getScore();
    },
    solve() {
      this.sudoku.solve();
      this.subgrids = this.sudoku.getSubgrids();
      this.status = this.sudoku.getStatus();
      this.score = this.sudoku.getScore();
    },
    reset() {
      this.grid = Array.from({ length: 9 }, () =>
        Array.from({ length: 9 }, () => 0)
      );
      this.create();
    },
    setEasy() {
      this.grid = [
        [0, 0, 8, 0, 0, 5, 9, 0, 0],
        [4, 2, 9, 0, 6, 0, 1, 8, 0],
        [5, 0, 1, 0, 0, 8, 0, 7, 4],
        [6, 1, 0, 0, 4, 9, 8, 0, 0],
        [0, 5, 0, 0, 0, 0, 6, 0, 9],
        [9, 0, 4, 0, 0, 2, 0, 0, 1],
        [1, 0, 6, 0, 8, 0, 7, 5, 0],
        [7, 4, 0, 0, 0, 0, 0, 1, 0],
        [0, 0, 0, 0, 0, 7, 4, 9, 6],
      ];
      this.create();
    },
    setHard() {
      this.grid = [
        [0, 2, 3, 0, 7, 0, 6, 8, 0],
        [0, 8, 0, 0, 0, 0, 0, 0, 4],
        [7, 4, 0, 5, 0, 0, 0, 0, 9],
        [0, 0, 0, 0, 1, 0, 0, 0, 0],
        [9, 5, 0, 6, 0, 7, 0, 0, 0],
        [6, 3, 8, 0, 5, 0, 1, 0, 0],
        [8, 0, 0, 3, 0, 0, 0, 2, 0],
        [0, 9, 0, 0, 0, 6, 0, 0, 0],
        [0, 0, 0, 8, 0, 0, 4, 0, 0],
      ];
      this.create();
    },
    setEvil() {
      this.grid = [
        [1, 4, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 2, 0, 0, 0, 0],
        [3, 0, 0, 1, 0, 7, 9, 0, 0],
        [0, 0, 0, 0, 5, 0, 8, 0, 0],
        [2, 0, 0, 0, 9, 0, 0, 0, 0],
        [0, 3, 0, 8, 0, 2, 0, 4, 0],
        [7, 0, 0, 3, 0, 8, 1, 0, 0],
        [0, 0, 1, 0, 0, 0, 0, 9, 0],
        [0, 0, 0, 5, 0, 0, 0, 0, 0],
      ];
      this.grid = [
        [0, 0, 0, 0, 6, 0, 0, 0, 0],
        [0, 0, 5, 0, 0, 0, 0, 9, 4],
        [3, 4, 0, 1, 0, 0, 5, 0, 0],
        [0, 9, 0, 0, 5, 8, 1, 3, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 6, 8, 7, 1, 0, 0, 4, 0],
        [0, 0, 3, 0, 0, 6, 0, 5, 1],
        [2, 7, 0, 0, 0, 0, 3, 0, 0],
        [0, 0, 0, 0, 2, 0, 0, 0, 0],
      ];
      this.grid = [
        [8, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 3, 6, 0, 0, 0, 0, 0],
        [0, 7, 0, 0, 9, 0, 2, 0, 0],
        [0, 5, 0, 0, 0, 7, 0, 0, 0],
        [0, 0, 0, 0, 4, 5, 7, 0, 0],
        [0, 0, 0, 1, 0, 0, 0, 3, 0],
        [0, 0, 1, 0, 0, 0, 0, 6, 8],
        [0, 0, 8, 5, 0, 0, 0, 1, 0],
        [0, 9, 0, 0, 0, 0, 4, 0, 0],
      ];
      this.create();
    },
    handlePrint() {
      const prtHtml = document.getElementById("sudoku").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
};
</script>

<style lang="sass" scoped>
.grid-container
  display: flex
  flex-direction: column
  align-items: center
  gap: 1rem
  margin-top: 2rem
  margin-bottom: 2rem
.grid-wrapper
  display: inline-block
  border: 1px solid black
.grid-row
  display: flex

table
  display: inline-block
  border-collapse: collapse
  border: 1px solid black
  td
    border: 1px solid grey
</style>