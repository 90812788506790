const state = {
  menuIngredients: [],
  menuItemLimits: []
}

const getters = {
  menuIngredients: state => state.menuIngredients,
  menuItemLimits: state => state.menuItemLimits,
}

const actions = {
  setMenuIngredients: ({ commit }, data) => { commit('setMenuIngredients', data) },
  updateMenuIngredient: ({ commit }, data) => { commit('updateMenuIngredient', data) },
  addMenuIngredient: ({ commit }, data) => { commit('addMenuIngredient', data) },
  removeMenuIngredient: ({ commit }, data) => { commit('removeMenuIngredient', data) },
  setMenuItemLimits: ({ commit }, data) => { commit('setMenuItemLimits', data) },
  updateMenuItemLimit: ({ commit }, data) => { commit('updateMenuItemLimit', data) },
  addMenuItemLimit: ({ commit }, data) => { commit('addMenuItemLimit', data) },
  removeMenuItemLimit: ({ commit }, data) => { commit('removeMenuItemLimit', data) },
}

const mutations = {
  setMenuIngredients(state, data) { state.menuIngredients = data },
  updateMenuIngredient(state, data) {
    state.menuIngredients = state.menuIngredients.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addMenuIngredient(state, data) { state.menuIngredients.push(data) },
  removeMenuIngredient(state, data) {
    state.menuIngredients = state.menuIngredients.filter(o => o._id !== data._id)
  },
  setMenuItemLimits(state, data) { state.menuItemLimits = data },
  updateMenuItemLimit(state, data) {
    state.menuItemLimits = state.menuItemLimits.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addMenuItemLimit(state, data) { state.menuItemLimits.push(data) },
  removeMenuItemLimit(state, data) {
    state.menuItemLimits = state.menuItemLimits.filter(o => o._id !== data._id)
  },
}

export default { state, getters, actions, mutations }