<template lang="pug">
IconBtn(@click="create()", title="Add", icon="mdi-plus")
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  methods: {
    create() {
      EventBus.$emit("edit-event");
    },
  },
};
</script>
