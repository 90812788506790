<template lang="pug">
v-data-table(
  :headers="headers",
  :items="menuIngredients",
  :search="searchText",
  @click:row="add",
  role="button",
  sort-by="name",
  dense
)
  template(v-slot:item.price="{ item }")
    span {{ item.price | currency }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    searchText: { type: String, default: "" },
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "ID", value: "uid" },
        { text: "Unit", value: "uom" },
        { text: "Price/Unit", value: "price", align: "end" },
      ],
    };
  },
  computed: { ...mapGetters(["menuIngredients"]) },
  methods: {
    add(item) {
      this.$emit("addItem", item);
    },
  },
};
</script>
