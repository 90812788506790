<template lang="pug">
v-card(outlined)
  v-toolbar(flat, dense)
    .subtitle-2 Transfers
    v-spacer
    Download(:items="items", :year="year", :total="total")
  Table(:items="items", :total="total")
</template>

<script>
import { mapGetters } from "vuex";
import Table from "./Table";
import Download from "./Download";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Table, Download },
  props: ["year"],
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapGetters(["biz", "chainAccounts"]),
    chain() {
      return this.chainAccounts.find((o) => o.bizs.includes(this.biz._id));
    },
    total() {
      return this.items.reduce((acc, cur) => acc + cur.amount / 100, 0);
    },
  },
  watch: {
    year() {
      this.load();
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.chain) return;
      const begin = new Date(this.year, 0, 1).getTime();
      const end = new Date(this.year + 1, 0, 1).getTime();
      const params = {
        criteria: {
          biz: { $in: this.chain.bizs },
          created: { $gte: begin, $lt: end },
        },
      };
      this.items = await this.$api.bizTransfer.list(params);
    },
  },
};
</script>
