<template lang="pug">
div
  Doc.my-8
  SelfDeliv.my-8
  Tiers.my-8
</template>

<script>
import { mapGetters } from "vuex";
import Doc from "./Doc";
import SelfDeliv from "./SelfDeliv/index";
import Tiers from "./Tiers";

export default {
  components: { Doc, SelfDeliv, Tiers },
  computed: {
    ...mapGetters(["biz"]),
  },
};
</script>
