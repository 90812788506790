<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Monthly Bills
    v-progress-circular.ml-2(
      indeterminate,
      size="15",
      color="secondary",
      v-if="loading"
    )
  List(:items="items")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import List from "/libs/components/Billing/Invoice/List";

export default {
  components: { List },
  props: ["year"],
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz", "invoices"]),
    items() {
      return this.invoices?.filter((o) => !o.org_invoice);
    },
  },
  watch: {
    year(val) {
      if (val) this.load();
    },
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setInvoices"]),
    async load() {
      this.setInvoices([]);
      const bizId = this.biz?._id;
      if (!bizId || !this.year) return;
      this.loading = true;
      const params = {
        criteria: { "biz.id": bizId, "period.year": this.year },
        select: "period payment status org_invoice",
      };
      const { data } = await this.axios.post("/invoices/list", params);
      this.setInvoices(data);
      this.loading = false;
    },
  },
};
</script>
