<template lang="pug">
v-card(max-width="500", v-if="items && items.length == 3", outlined)
  v-toolbar(flat, dense, dark, color="pink darken-1")
    .subtitle-2 {{ title }}
  v-card-text.text-center
    p.indigo--text.font-weight-medium Last 30 Days
    .title {{ items[0] | toLocaleString }} {{ unit }}
    .d-flex.flex-row.align-center.justify-center.my-1(v-if="diff >= 0")
      v-icon(color="success") mdi-menu-up
      span.ml-1.green--text {{ diff | toLocaleString }} {{ unit }}
    .d-flex.flex-row.align-center.justify-center.my-1(v-else)
      v-icon(color="error") mdi-menu-down
      span.ml-1.red--text {{ diff | toLocaleString }} {{ unit }}
    .caption.grey--text.text--darken-2 compare to previous 30 days
    p.indigo--text.font-weight-medium.mt-3 Lifetime
    .title {{ items[2] | toLocaleString }} {{ unit }}
</template>

<script>
export default {
  props: ["title", "items", "unit"],
  computed: {
    diff() {
      if (this.items && this.items.length == 3)
        return this.items[0] - this.items[1];
      return 0;
    },
  },
};
</script>
