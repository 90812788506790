<template lang="pug">
v-btn(icon, @click="handleRemove()", :loading="loading", color="error")
  v-icon(small) mdi-delete
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DeleteBank",
  props: ["bankAccount"],
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["stripeAccount"]) },
  methods: {
    ...mapActions(["removeStripeBank"]),
    handleRemove() {
      confirm("Do you want to delete this bank account?") && this.remove();
    },
    async remove() {
      if (!this.stripeAccount || !this.bankAccount) return;
      this.loading = true;
      const params = {
        accountId: this.stripeAccount.id,
        bankId: this.bankAccount.id,
      };
      try {
        await this.$api.stripe.bankaccount.delete(params);
        this.removeStripeBank(this.bankAccount);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
