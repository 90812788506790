<template lang="pug">
div
  v-btn.my-3.text-notransform(rounded, color="secondary", @click="load()") Manage Member Tier
  v-dialog(v-model="dialog", width="500")
    v-card(v-if="tier")
      v-card-text
        v-form(@submit.prevent="update()")
          .sl-row
            .sl-title Basic Tier
          .sl-row
            .body-2 Benefits
            v-spacer
            v-btn(icon, @click="add(tier.tier_0.benefits)")
              v-icon(color="secondary") mdi-plus
          .sl-row(v-for="(item, index_0) in tier.tier_0.benefits")
            v-text-field(v-model="tier.tier_0.benefits[index_0]", hide-details)
            v-btn(
              icon,
              @click="remove(tier.tier_0.benefits, index_0)",
              single-line
            )
              v-icon(color="red", small) mdi-delete
          .sl-dash-divider.my-3
          .sl-row
            .sl-title Gold Tier
            v-spacer
            v-switch(label="Status", v-model="tier.tier_1.status")
          div(v-if="tier.tier_1.status")
            .sl-row
              v-text-field.mr-3(
                label="Price",
                v-model="tier.tier_1.price",
                type="number",
                hide-details,
                prefix="$"
              )
              v-select(
                :items="month_choices",
                v-model="tier.tier_1.duration_in_months",
                hide-details,
                dense
              )
            .sl-row
              .body-2 Benefits
              v-spacer
              v-btn(icon, @click="add(tier.tier_1.benefits)")
                v-icon(color="secondary") mdi-plus
            .sl-row(v-for="(item, index_0) in tier.tier_1.benefits")
              v-text-field(
                v-model="tier.tier_1.benefits[index_0]",
                hide-details
              )
              v-btn(
                icon,
                @click="remove(tier.tier_1.benefits, index_0)",
                single-line
              )
                v-icon(color="red", small) mdi-delete
          .sl-dash-divider.my-3
          .sl-row
            .sl-title Platinum Tier
            v-spacer
            v-switch(label="Status", v-model="tier.tier_2.status")
          div(v-if="tier.tier_2.status")
            .sl-row
              v-text-field.mr-3(
                label="Price",
                v-model="tier.tier_2.price",
                type="number",
                hide-details,
                prefix="$"
              )
              v-select(
                :items="month_choices",
                v-model="tier.tier_2.duration_in_months",
                hide-details,
                dense
              )
            .sl-row
              .body-2 Benefits
              v-spacer
              v-btn(icon, @click="add(tier.tier_2.benefits)")
                v-icon(color="secondary") mdi-plus
          .sl-row(v-for="(item, index_0) in tier.tier_2.benefits")
            v-text-field(v-model="tier.tier_2.benefits[index_0]", hide-details)
            v-btn(
              icon,
              @click="remove(tier.tier_2.benefits, index_0)",
              single-line
            )
              v-icon(color="red", small) mdi-delete
          v-btn(block, color="secondary", :loading="loading", type="submit") Save
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      tier: null,
      loading: false,
      month_choices: [
        { value: 6, text: "Six Months" },
        { value: 12, text: "A Year" },
        { value: 24, text: "Two Years" },
        { value: 0, text: "Never Expire" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    async load() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      try {
        const result = await this.$api.memberTier.retrieve(params);
        if (result) {
          this.tier = result;
          this.dialog = true;
          return;
        }
      } catch (e) {
        //
      }
      confirm(
        "Do you want to create a tiered member program for your business?"
      ) && this.create();
    },
    async create() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      try {
        this.tier = await this.$api.memberTier.create(params);
        this.dialog = true;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async update() {
      if (!this.tier || !this.tier._id) return;
      this.tier.tier_0.benefits = this.tier.tier_0.benefits.filter(
        (o) => o.length
      );
      this.tier.tier_1.benefits = this.tier.tier_1.benefits.filter(
        (o) => o.length
      );
      this.tier.tier_2.benefits = this.tier.tier_2.benefits.filter(
        (o) => o.length
      );
      this.loading = true;
      const params = {
        criteria: { _id: this.tier._id },
        action: { $set: this.tier },
      };

      try {
        this.tier = await this.$api.memberTier.update(params);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    add(benefits) {
      benefits.push("");
    },
    remove(benefits, index) {
      benefits.splice(index, 1);
    },
  },
};
</script>
