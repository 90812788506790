<template lang="pug">
v-container
  .text-center.title.my-5 Guides
  v-row(small, justify="center")
    v-col.text-center(
      cols="6",
      sm="4",
      md="3",
      lg="2",
      v-for="(item, index) in items",
      :key="index"
    )
      v-img.mb-5(:src="item.cover", contain)
      a(:href="item.src", download, target="_blank") Download PDF
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          cover:
            "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/restaurant%2Fguides%2Fcreate_business_selflane_cover.png?alt=media&token=ad28938d-b6e8-4c04-b0d6-93faeeba4344",
          src: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/restaurant%2Fguides%2Fcreate_business_selflane.pdf?alt=media&token=1a8541c0-769f-44dc-b610-abcd3021bece",
        },
        {
          cover:
            "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/restaurant%2Fguides%2Fuse_selflane_online_ordering_cover.png?alt=media&token=0e9e24ec-3362-4aa7-ac6b-e3de3dfcabd7",
          src: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/restaurant%2Fguides%2Fuse_selflane_online_ordering.pdf?alt=media&token=98f07d76-55be-4551-afad-0efa6fed2a24",
        },
      ],
    };
  },
};
</script>