<template lang="pug">
v-card(flat)
  v-toolbar(dense, flat)
    .subtitle-2 Prefixes
    v-spacer
    IconBtn(@click="edit()", title="Edit", icon="mdi-pencil")
  simple-table(v-if="matrixmodifier")
    thead
      tr
        th Name
        th.text-right Scale Factor
    tbody
      tr(
        v-for="(item, index) in matrixmodifier.prefixes",
        :key="index",
        draggable="true",
        @dragstart="drag(index, $event)",
        @dragend="dragend($event)",
        @drop="drop(index, $event)",
        @dragover.prevent=""
      )
        th
          v-icon.mr-2(small) mdi-menu
          span {{ item.name }}
          span.ml-1(v-if="item.kName") ({{ item.kName }})
        td.text-right &#215;{{ item.factor }}
  Form
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Form from "./PrefixesForm";

export default {
  components: { Form },
  data() {
    return {
      draggingIndex: null,
    };
  },
  computed: {
    ...mapGetters(["matrixmodifier", "biz"]),
  },
  methods: {
    ...mapActions(["setMatrixModifier"]),
    edit() {
      let data = [
        { name: "No", factor: 0 },
        { name: "Lite", factor: 0 },
        { name: "Add", factor: 1 },
        { name: "Extra", factor: 1 },
        { name: "On Side", factor: 1 },
      ];
      if (
        this.matrixmodifier &&
        this.matrixmodifier.prefixes &&
        this.matrixmodifier.prefixes.length > 0
      ) {
        data = this.matrixmodifier.prefixes;
      }
      EventBus.$emit("edit-matrix-modifier-prefixes", data);
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    async drop(index) {
      if (index == this.draggingIndex) return;
      if (!this.matrixmodifier || !this.matrixmodifier.prefixes) return;
      if (index < 0 || index >= this.matrixmodifier.prefixes.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.matrixmodifier.prefixes.length
      )
        return;
      let prefixes = JSON.parse(JSON.stringify(this.matrixmodifier.prefixes));
      let tmp = JSON.parse(JSON.stringify(prefixes[this.draggingIndex]));
      prefixes.splice(this.draggingIndex, 1);
      prefixes.splice(index, 0, tmp);
      const params = {
        criteria: { _id: this.biz._id },
        action: { $set: { prefixes: prefixes } },
      };
      const data = await this.$api.matrixModifier.update(params);
      this.setMatrixModifier(data);
    },
  },
};
</script>
