<template lang="pug">
div
  MonthSelector(@select="load")
  v-row.my-5
    v-col(cols="12", md="4", v-for="(group, gkey) in groups", :key="gkey")
      Table(:batches="group", :date="gkey")
  .caption.my-10
    div Only batches manually closed would be recorded by POS.
    div We recommend close batch manually at the end of the day. It's a good practice to cross check with the deposit you received from the processor.
    div Batches autoclosed will be collected by the processor but won't be recorded in the POS.
    div Heartland<sup>&#174;</sup> practices differently in closing batch. In closing one machine, it would close batch on all. With one machine closed, it would close batch on all machines. In such a case, the batch in POS may only show partial fund, from a single machine. The processor, however, has the full amount of closed fund.
</template>
<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import _ from "underscore";
import { mapGetters } from "vuex";
import Table from "./Table";

export default {
  components: { Table },
  data() {
    return {
      batches: [],
      begin: null,
      end: null,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    groups() {
      return _.groupBy(this.batches, (o) => o.date);
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load(data) {
      if (!this.biz || !this.biz._id) return;
      this.batches = [];
      if (data) {
        this.begin = data.range.begin;
        this.end = data.range.end;
      }
      if (!this.begin || !this.end) return;
      const params = {
        criteria: {
          biz: this.biz._id,
          created: { $gte: this.begin, $lte: this.end },
        },
      };
      const result = await this.$api.transaction.batch.list(params);
      this.batches = result.map((o) => {
        const time = new Date(parseInt(o._id.substring(0, 8), 16) * 1000);
        const date = moment(time).format("MMM D, YYYY");
        if (o.amount && !o.amount.length) {
          o.amount = [
            o.creditAmount || 0,
            o.debitAmount || 0,
            o.ebtAmount || 0,
            o.giftAmount || 0,
            o.loyaltyAmount || 0,
            o.cashAmount || 0,
            o.checkAmount || 0,
          ];
        }
        return { ...o, date: date };
      });
    },
  },
};
</script>
