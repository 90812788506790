<template lang="pug">
v-container
  span {{ message }}
</template>

<script>
export default {
  props: {
    email: { type: String, required: true },
    code: { type: String, required: true },
  },
  data() {
    return { message: "" };
  },
  mounted() {
    this.load();
  },
  methods: {
    handleError() {
      this.message = "No valid information was provided.";
    },
    toLogin() {
      this.$router.push("/login");
    },
    async load() {
      if (!this.email || !this.code) {
        this.handleError();
        return;
      }
      this.message = "Verifying " + this.email;
      const params = "?a=" + this.email + "&b=" + this.code;
      try {
        await this.axios.get("/users/verify/" + params);
        this.message = "Success";
        this.toLogin();
      } catch (e) {
        if (e.response) {
          if (e.response.data == "Verified user") this.toLogin();
          else this.message = e.response.data;
        }
      }
    },
  },
};
</script>
