<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import moment from "moment-timezone";
import _ from "underscore";
import CSV from "/libs/utils/CSV";
import { mapGetters } from "vuex";

export default {
  props: ["grouped", "isDecimal"],
  computed: { ...mapGetters(["biz"]) },
  methods: {
    download() {
      const filters = this.$options.filters;
      const isDecimal = this.isDecimal;
      const csv = new CSV();
      csv.addRow([this.biz.name]);
      if (this.biz.short_name) {
        csv.addRow([this.biz.short_name]);
      }
      csv.addRow([moment().format("M/D/YYYY hh:mm A")]);
      csv.addRow([
        "Employee",
        "ID",
        "Role",
        "Clock In",
        "Clock Out",
        "Hrs",
        "Rate",
        "Pay",
        "Cash Tip",
      ]);
      _.each(this.grouped, (group) => {
        _.each(group.byRole, (role) => {
          _.each(role.list, (card) => {
            if (!card.status) return;
            const clockIn = this.customTime(card.clockIn);
            let clockOut;
            if (card.clockOut > 0) {
              clockOut = this.customTime(card.clockOut);
            } else {
              clockOut = "-";
            }
            csv.addRow([
              card.serverName,
              "",
              card.role,
              clockIn,
              clockOut,
              filters.hours(card.hours, isDecimal),
              filters.currency(card.rate),
              filters.currency(card.hours * card.rate),
              filters.currency(card.cashTip * card.rate),
            ]);
          });
          csv.addRow([
            "",
            "",
            role.name,
            "",
            "",
            "",
            filters.hours(role.totalHrs, isDecimal),
            "",
            filters.currency(role.totalPay),
            "",
          ]);
        });
        csv.addRow([
          group.server,
          group.employeeID,
          "",
          "",
          "",
          "",
          filters.hours(group.totalHrs, isDecimal),
          "",
          filters.currency(group.totalPay),
          "",
        ]);
        csv.addRow([]);
      });
      csv.save("timecards");
    },
    customTime(m) {
      return moment(m).format("M/D h:mm A");
    },
  },
};
</script>
