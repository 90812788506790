<template lang="pug">
.cell-container
  .label-block
    v-icon(v-if="icon", small) {{ icon }}
    .text--secondary {{ title }}
    v-spacer
    span(v-if="diff !== null")
      span.success--text(v-if="diff >= 0") + {{ diff }}
      span.error--text(v-else-if="diff") {{ diff }}
  .value-block {{ value }}
</template>

<script>
export default {
  props: {
    icon: { type: String, default: "" },
    title: { type: String, default: "" },
    value: { type: Number, default: 0 },
    diff: { type: Number, default: null },
  },
};
</script>

<style lang="sass" scoped>
.cell-container
  display: flex
  flex-direction: column
  gap: 0.5rem
  padding: 1rem
  flex-grow: 1
  background: #fff
.label-block
  display: flex
  flex-direction: row
  font-size: 1rem
  font-weight: 500
  gap: 0.5rem
.value-block
  font-size: 2.5rem
</style>