const state = {
  persons: [], // Modal servers
  departments: [], // Sevenshifts Departments
}

const getters = {
  persons: state => state.persons,
  departments: state => state.departments,
}

const actions = {
  setPersons: ({ commit }, data) => { commit('setPersons', data) },
  updatePerson: ({ commit }, data) => { commit('updatePerson', data) },
  addPerson: ({ commit }, data) => { commit('addPerson', data) },
  removePerson: ({ commit }, data) => { commit('removePerson', data) },
  setDepartments: ({ commit }, data) => { commit('setDepartments', data) },
  updateDepartment: ({ commit }, data) => { commit('updateDepartment', data) },
  addDepartment: ({ commit }, data) => { commit('addDepartment', data) },
  removeDepartment: ({ commit }, data) => { commit('removeDepartment', data) },
}

const mutations = {
  setPersons(state, data) {
    state.persons = data
  },
  updatePerson(state, data) {
    state.persons = state.persons.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addPerson(state, data) {
    state.persons.push(data)
  },
  removePerson(state, data) {
    state.persons = state.persons.filter(o => {
      return (o._id !== data._id)
    })
  },
  setDepartments(state, data) {
    state.departments = data
  },
  updateDepartment(state, data) {
    state.departments = state.departments.map(o => {
      if (o.id === data.id) return data
      return o
    })
  },
  addDepartment(state, data) {
    state.departments.push(data)
  },
  removeDepartment(state, data) {
    state.departments = state.departments.filter(o => {
      return (o.id !== data.id)
    })
  },
}

export default { namespaced: true, state, getters, actions, mutations }