<template lang="pug">
PartnerPage(:greeting="false", title="Users & Access", :doc="doc")
  template(v-slot:header)
    CreatePerson
  List.my-3(:items="items")
  Form
</template>

<script>
import CreatePerson from "/libs/components/SalesForce/Person/CreatePerson";
import Form from "/libs/components/SalesForce/Person/Form";
import List from "/libs/components/SalesForce/Person/List";
import { mapGetters } from "vuex";

export default {
  components: { CreatePerson, Form, List },
  data() {
    return {
      doc: "https://docs.selflane.com/partner/admin/access/",
    };
  },
  computed: {
    ...mapGetters(["salesPersons", "salesOrganization", "salesOrganizations"]),
    // return salesPersons with organization matching salesOrganization
    items() {
      return this.salesPersons.filter(
        (p) => p.organization === this.salesOrganization._id
      );
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const ids = this.salesOrganizations.map((o) => o._id);
      if (!ids?.length) {
        this.$store.dispatch("setSalesPersons", []);
        return;
      }
      const params = { criteria: { organization: { $in: ids } } };
      const result = await this.$api.salesforce.person.list(params);
      this.$store.dispatch("setSalesPersons", result);
    },
  },
};
</script>
