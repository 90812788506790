<template lang="pug">
v-card(flat, height="100%")
  simple-table
    thead
      AmountTableRow(name="Total Received", :amount="total", bold, mandatory)
    tbody
      AmountTableRow(
        v-for="(item, index) in charges",
        :key="index",
        :name="item.title",
        :amount="item.amount"
      )
</template>

<script>
import _ from "underscore";

export default {
  props: ["items"],
  computed: {
    total() {
      return this.items?.reduce((a, b) => a + b.charge.total, 0);
    },
    charges() {
      const items = this.items?.flatMap((o) => o.charge.items) || [];
      return _.chain(items)
        .groupBy("method")
        .map((value, key) => {
          const method = key;
          const title = value[0].title;
          const amount = value.reduce((a, b) => a + b.amount, 0);
          return { method, title, amount };
        })
        .sortBy("title")
        .value();
    },
  },
};
</script>