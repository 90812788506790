<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    v-btn.text-capitalize.mr-3(
      :class="{ accent: openOnly }",
      @click="load(true)",
      rounded,
      depressed,
      small
    ) Open
    v-btn.text-capitalize.mr-3(
      :class="{ accent: !openOnly }",
      @click="load(false)",
      rounded,
      depressed,
      small
    ) All
    YearSelector(@select="selectYear", v-if="!openOnly")
  simple-table
    thead
      tr
        th Created
        th Amount
        th Balance
    tbody
      tr(
        v-for="(item, index) in giftcards",
        :key="index",
        @click="showDetail(item)",
        role="button"
      )
        td {{ item.created | date }}
        td {{ item.amount | currency }}
        td {{ item.balance | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      openOnly: true,
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters(["giftcards", "biz"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setGiftCards", "updateGiftCard"]),
    selectYear(year) {
      this.year = year;
      if (!this.openOnly) this.load(false);
    },
    async load(openOnly) {
      this.openOnly = openOnly;
      if (!this.biz) return;
      let criteria;
      if (openOnly) {
        criteria = { issuer: this.biz._id, balance: { $gte: 0.01 } };
      } else {
        const begin = moment().year(this.year).startOf("year").unix() * 1000;
        const end = moment().year(this.year).endOf("year").unix() * 1000;
        criteria = { issuer: this.biz._id, created: { $gte: begin, $lt: end } };
      }
      try {
        const result = await this.$api.giftcard.list({ criteria });
        this.setGiftCards(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    showDetail(gc) {
      EventBus.$emit("show-gift-card", gc._id);
    },
  },
};
</script>
