<template lang="pug">
v-dialog(v-model="dialog", width="800", scrollable)
  v-card(v-if="payout", :loading="loading")
    v-toolbar(flat, dense)
      .subtitle-2 Payout Transactions
      v-spacer
      .subtitle-2.mr-3 {{ (payout.amount / 100) | currency }}
      Download(:items="items")
    v-data-table(:headers="headers", :items="items", dense)
      template(v-slot:item.created="{ item }")
        span {{ (item.created * 1000) | datetime }}
      template(v-slot:item.amount="{ item }")
        span {{ (item.amount / 100) | currency }}
      template(v-slot:item.fee="{ item }")
        span {{ (item.fee / 100) | currency }}
      template(v-slot:item.net="{ item }")
        span {{ (item.net / 100) | currency }}
    v-subheader Summary
    Summary(:items="items", :loading="loading")
    v-subheader By Day
    ByDay(:items="items", :loading="loading")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapGetters } from "vuex";
import ByDay from "./ByDay";
import Download from "./Download";
import Summary from "./Summary";

export default {
  components: { Download, Summary, ByDay },
  data() {
    return {
      dialog: false,
      payout: null,
      transactions: [],
      loading: false,
      headers: [
        { text: "Date", value: "created" },
        { text: "Total", value: "amount" },
        { text: "Fees", value: "fee" },
        { text: "Net", value: "net" },
        { text: "Type", value: "type", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    stripeAccountID() {
      return this.biz?.payments?.managed_account;
    },
    items() {
      return this.transactions.filter((o) => o.type != "payout");
    },
  },
  mounted() {
    EventBus.$on("show-payout-transactions", this.open);
  },
  destroyed() {
    EventBus.$off("show-payout-transactions", this.open);
  },
  methods: {
    open(payout) {
      this.payout = payout;
      if (!this.payout) return;
      this.dialog = true;
      this.transactions = [];
      this.load();
    },
    async load() {
      this.loading = true;
      let params = {
        stripeAccount: this.stripeAccountID,
        payout: this.payout.id,
        limit: 100,
      };
      if (this.transactions && this.transactions.length > 0) {
        const last_trans = _.last(this.transactions);
        params = _.extend(params, {
          starting_after: last_trans.id,
        });
      }
      const result = await this.$api.stripe.balance.custom(
        "list_transactions",
        params
      );
      this.transactions = this.transactions.concat(result.data);
      if (result.has_more) {
        await this.load();
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
