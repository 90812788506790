<template lang="pug">
span {{ tax }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    course: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(["biz"]),
    tax_items() {
      const some = this.biz?.tax_items?.some(
        (o) => o.courses?.includes(this.course._id) && o.status
      );
      if (some) {
        return this.biz?.tax_items?.filter(
          (o) => o.courses?.includes(this.course._id) && o.status
        );
      } else {
        return this.biz?.tax_items?.filter(
          (o) => !o.courses?.length && o.status
        );
      }
    },
    tax() {
      return this.tax_items
        ?.map((o) => {
          let text = `${o.name} ${o.percentage}%`;
          if (o.excludeOrderTypes?.length) {
            text += ` (Excl. ${o.excludeOrderTypes.join(", ")})`;
          }
          if (o.type == 1) return text + " (Inc.)";
          return text;
        })
        .join(", ");
    },
  },
};
</script>