<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="invoice")
    v-toolbar(dense, flat)
      .subtitle-2 {{ invoice.biz.name }}
      v-spacer
      .subtitle-2 Period: {{ invoice.period.year }}/{{ invoice.period.month }}
    simple-table
      thead
        tr
          th Item
          th Price
          th Quantity
      tbody
        tr(v-for="(item, index) in invoice.items", :key="index")
          td
            .subtitle-2 {{ item.name }}
            .caption {{ item.detail }}
          td {{ item.amount | currency }}
          td.text-right {{ item.quantity }}
    v-card-text
      table.payment-table.text-right
        tr
          td Subtotal
          td {{ invoice.payment.subtotal | currency }}
        tr
          td Tax
          td {{ invoice.payment.tax | currency }}
        tr
          td.subtitle-2 Total
          td.subtitle-2 {{ invoice.payment.total | currency }}
        tr(v-if="isPaid")
          td.subtitle-2 Paid ({{ invoice.payment.charge.method }})
          td.subtitle-2 {{ invoice.payment.charge?.amount | currency }}
      div(v-if="!invoice.billing_organization")
        .text-right.subtitle-2(v-if="isPaid") Payment was posted on {{ invoice.payment.charge?.time | date }}
        .text-right.subtitle-2.red--text(v-else) Not Yet Paid
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      invoice: null,
    };
  },
  computed: {
    isPaid() {
      return this.invoice?.status == 1;
    },
  },
  mounted() {
    EventBus.$on("show-invoice", this.load);
  },
  destroyed() {
    EventBus.$off("show-invoice", this.load);
  },
  methods: {
    ...mapActions(["updateInvoice"]),
    async load(id) {
      this.dialog = true;
      const params = { criteria: { _id: id } };
      this.invoice = await this.$api.invoice.retrieve(params);
      this.updateInvoice(this.invoice);
    },
  },
};
</script>

<style lang="sass" scoped>
.payment-table
  width: 100%
</style>