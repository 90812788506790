<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Hardware & Service Bills
    v-progress-circular.ml-2(
      indeterminate,
      size="15",
      color="secondary",
      v-if="loading"
    )
  Table
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import Table from "./Table/index";

export default {
  components: { Table },
  props: ["year"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["salesOrganization"]),
  },
  watch: {
    year(val) {
      if (val) this.load();
    },
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setPurchases"]),
    async load() {
      const id = this.salesOrganization?._id;
      if (!id || !this.year) return;
      this.loading = true;
      const begin = moment().year(this.year).startOf("year").valueOf();
      const end = moment().year(this.year).endOf("year").valueOf();
      this.orders = [];
      const params = {
        criteria: { organization: id, created: { $gte: begin, $lt: end } },
      };
      const result = await this.$api.bizOrder.list(params);
      this.setPurchases(result);
      this.loading = false;
    },
  },
};
</script>
