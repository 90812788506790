<template lang="pug">
div
  .d-flex.flex-row.justify-end
    MonthSelector(@select="load")
  CreditReturns(:range="range")
</template>

<script>
import CreditReturns from "./CreditReturns/index";

export default {
  components: { CreditReturns },
  data() {
    return { range: null };
  },
  methods: {
    load(month) {
      this.range = month.range;
    },
  },
};
</script>