<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card
    v-card-title Edit Booked Table
    v-form(@submit.prevent="submit")
      v-card-text
        v-row
          v-col(cols="4")
            v-text-field(label="Name", v-model="name", required)
          v-col(cols="4")
            PhoneField(v-model="phone")
          v-col(cols="4")
            v-text-field(label="Size", v-model="party_size")
        DateTimePicker(v-model="time", :timezone="timezone")
        v-text-field(label="Note", v-model="note")
        v-checkbox(
          v-model="confirm",
          label="Confirm to edit",
          color="secondary"
        )
      v-card-actions
        v-btn(
          type="submit",
          color="secondary",
          :loading="loading",
          :disabled="!confirm"
        ) Save
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      booked_table: null,
      name: "",
      phone: "",
      party_size: "",
      time: "",
      note: "",
      confirm: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    timezone() {
      return this.biz?.address?.timezone;
    },
  },
  methods: {
    async open(booked_table) {
      this.booked_table = booked_table;
      this.name = booked_table.customer.name;
      this.phone = booked_table.customer.phone;
      this.party_size = booked_table.party_size;
      this.time = booked_table.time;
      this.note = booked_table.note;
      this.confirm = false;
      this.dialog = true;
    },
    async submit() {
      this.loading = true;
      try {
        const result = await this.$api.rs.book.custom("edit", {
          bookedId: this.booked_table._id,
          name: this.name,
          phone: this.phone,
          party_size: this.party_size,
          time: this.time,
          note: this.note,
        });
        this.$emit("update", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>