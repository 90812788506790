<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import _ from "underscore";
import CSV from "/libs/utils/CSV";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["bizmodifiers"]),
  },
  methods: {
    download() {
      const filters = this.$options.filters;
      const csv = new CSV();
      csv.addRow(["Name", "Choice", "Price", "Sub Choice"]);
      _.each(this.bizmodifiers, (bizmod) => {
        const item = bizmod.modifier;
        const maxIndex = Math.max(
          item.choices.length,
          item.associatedChoices.length
        );
        for (var i = 0; i < maxIndex; i++) {
          let name = "";
          let choiceName = "";
          let choicePrice = "";
          let subChoiceName = "";
          if (i == 0) name = item.name;
          if (i < item.choices.length) {
            choiceName = item.choices[i].name;
            choicePrice = filters.currency(item.choices[i].price);
          }
          if (i < item.associatedChoices.length) {
            subChoiceName = item.associatedChoices[i].name;
          }
          csv.addRow([name, choiceName, choicePrice, subChoiceName]);
        }
      });
      csv.save("modifiers");
    },
  },
};
</script>
