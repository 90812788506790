<template lang="pug">
v-dialog(v-model="dialog", width="700", scrollable)
  v-card
    v-card-title
    OrderTable(:loading="loading") 
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OrderTable from "@/components/Order/Table/index";
import { EventBus } from "@/event-bus.js";

export default {
  components: { OrderTable },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    EventBus.$on("show-orders-dialog", this.load);
  },
  destroyed() {
    EventBus.$off("show-orders-dialog", this.load);
  },
  methods: {
    ...mapActions(["setOrders"]),
    async load(params) {
      if (!params) return;
      this.dialog = true;
      this.loading = true;
      const result = await this.$api.order.list(params);
      this.setOrders(result);
      this.loading = false;
    },
  },
};
</script>
