<template lang="pug">
table.info-table.my-10
  tbody(v-if="payroll")
    tr
      td(width="60") REG
      td Regular
    tr
      td OT
      td Overtime
  tbody
    tr(v-for="(item, index) in items", :key="index")
      td
        .dot(:style="{ backgroundColor: item.color }")
      td {{ item.text }}
</template>

<script>
export default {
  props: ["payroll"],
  data() {
    return {
      items: [
        { color: "#66BB6A", text: "The employee is not yet clocked out" },
        {
          color: "#FFB74D",
          text: "The employee's working hour exceeds 10 hrs (may forgot to clock out on time)",
        },
        { color: "#EF5350", text: "The time card is canceled" },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.dot
  height: 16px
  width: 16px
  border-radius: 8px
</style>