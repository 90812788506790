<!--
  Payroll related settings
  @param {Number} startDay
  @param {Number} boostRate
-->

<template lang="pug">
v-dialog(v-model="dialog", width="500px")
  template(v-slot:activator="{ on }")
    v-btn(color="secondary", dark, v-on="on", @click="open", icon, small)
      v-icon(small) mdi-cog
  v-card
    v-card-title Payroll Settings
    v-form(@submit.prevent="submit()")
      v-card-text
        v-row
          v-col(cols="6")
            WeekdaySelector(v-model="startDay", label="Start Day")
          v-col(cols="6")
            v-text-field(
              label="Boost Rate",
              type="number",
              v-model="boostRate",
              step="1",
              prefix="+",
              suffix="%"
            )
        .caption Boost rate is the percentage of the base wage that is added to the wage for hours worked over 40 hours in a week.
      v-card-actions
        v-btn(
          color="secondary",
          block,
          text,
          type="submit",
          :loading="loading"
        ) Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      startDay: 0,
      boostRate: 50,
      loading: false,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["pos"]),
  },
  methods: {
    ...mapActions(["setPOS"]),
    open() {
      this.startDay = this.pos?.startDay || 0;
      // allow 0-100% boost rate. Only assign if it is defined. Value of 0 is valid.
      if (this.pos?.boostRate !== undefined) {
        this.boostRate = this.pos?.boostRate;
      } else {
        this.boostRate = 50;
      }
    },
    async submit() {
      if (!this.pos) return;
      if (!this.boostRate < 0 || this.boostRate > 100) {
        this.$toast.error("Boost rate must be between 0 and 100%");
        return;
      }
      if (
        this.boostRate == this.pos?.boostRate &&
        this.startDay == this.pos?.startDay
      ) {
        this.dialog = false;
        return;
      }
      this.loading = true;
      try {
        const params = {
          action: {
            $set: { startDay: this.startDay, boostRate: this.boostRate },
          },
          notification: false,
        };
        const result = await this.$api.pos.put(this.pos._id, params);
        this.setPOS(result);
        this.$emit("updated");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>