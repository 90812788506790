<template lang='pug'>
div
  #home
    Brand
  #what
    WhatWeDo
  #about
    About
  #clients
    Clients
  #contact
    Contact
  v-btn(fixed, dark, fab, bottom, right, color="#5D472B", href="#home")
    v-icon keyboard_arrow_up
</template>

<script>
import About from "./About";
import Brand from "./Brand";
import Clients from "./Clients";
import WhatWeDo from "./WhatWeDo";
import Contact from "/libs/components/CRM/POSInquiry";

export default {
  name: "App",
  components: { Brand, WhatWeDo, About, Clients, Contact },
};
</script>
