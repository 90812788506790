<template lang="pug">
div
  Status.mb-3
  v-row(align="center", justify="center", wrap)
    v-col(cols="10", sm="4")
      Member
    v-col(cols="10", sm="4")
      Sales
    v-col(cols="10", sm="4")
      Redeem
  Upgrade
  Tier
  URL.my-3
</template>

<script>
import Member from "./Member";
import Redeem from "./Redeem";
import Sales from "./Sales";
import Status from "./Status";
import Tier from "./Tier";
import Upgrade from "./Upgrade";
import URL from "./URL";

export default {
  components: { URL, Status, Tier, Member, Sales, Redeem, Upgrade },
};
</script>
