<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";

export default {
  props: {
    items: { type: Array, required: true },
    year: { type: Number, default: null },
    total: { type: Number, default: null },
  },
  methods: {
    download() {
      if (!this.items) return;
      const currency = this.$options.filters.currency;
      const date = this.$options.filters.date;
      const csv = new CSV();
      csv.addRow(["Created", "Reason", "Amount"]);
      this.items.forEach((item) => {
        csv.addRow([
          date(item.created),
          item.transferReason,
          currency(item.amount / 100),
        ]);
      });
      csv.addRow(["Total", "", currency(this.total)]);
      csv.save(this.year + " Transfers");
    },
  },
};
</script>
