<template lang="pug">
tr(@click="$emit('edit')", role="button")
  td {{ item.date }}
  td {{ range }}
  td {{ selection }}
  td.text-right
    DeleteOne(:item="item")
</template>

<script>
import { mapGetters } from "vuex";
import DeleteOne from "./DeleteOne";

export default {
  components: { DeleteOne },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(["rsvtsetting"]),
    selection() {
      if (!this.item.items?.length) return "All items";
      return this.rsvtsetting?.items
        ?.filter((o) => this.item.items?.includes(o._id))
        ?.map((o) => o.name)
        ?.join(", ");
    },
    range() {
      if (this.item.start == 0 && this.item.end == 1440) return "All day";
      const minutesToTime = this.$options.filters.minutesToTime;
      return `${minutesToTime(this.item.start)} - ${minutesToTime(
        this.item.end
      )}`;
    },
  },
};
</script>