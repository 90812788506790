<template lang="pug">
.s-vstack(v-if="has7Shifts")
  h3 Departments
  v-card(flat)
    v-toolbar(flat, dense)
      Sync
      IconBtn.mx-2(@click="handleAdd", icon="mdi-plus", title="Add")
      v-spacer
      .subtitle-2 {{ items.length }}
    simple-table
      thead
        tr
          th Name
          th # Roles
          th.text-right Default
      tbody
        tr(
          v-for="(department, index) in items",
          :key="index",
          @click="handleEdit(department)",
          role="button"
        )
          th {{ department.name }}
          td {{ getRoles(department.id) }}
          td.text-right {{ department.default ? "Yes" : "No" }}
  Form(ref="form", :biz="biz")
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";
import Sync from "./Sync";

export default {
  components: { Form, Sync },
  props: {
    biz: { type: Object, required: true },
    bizservice: { type: Object, required: true },
  },
  computed: {
    ...mapGetters("seven", ["departments", "roles"]),
    ...mapGetters(["pos"]),
    jobs() {
      return this.pos?.jobs || [];
    },
    app() {
      return this.bizservice?.apps?.find((r) => r.name == "7shifts");
    },
    company_id() {
      return parseInt(this.app?.company_id || 0);
    },
    location_id() {
      return parseInt(this.app?.store_id || 0);
    },
    items() {
      // if default is true, put it at the top
      // otherwise sort by name
      return (
        this.departments
          ?.filter((r) => r.company_id === this.company_id)
          ?.filter((r) => r.location_id === this.location_id)
          ?.filter((r) => !r.deleted)
          .toSorted((a, b) => {
            if (a.default) return -1;
            if (b.default) return 1;
            return a.name.localeCompare(b.name);
          }) || []
      );
    },
    has7Shifts() {
      const app = this.bizservice?.apps?.find((a) => a.name == "7shifts");
      return app?.status == "established";
    },
  },
  methods: {
    getRoles(department_id) {
      return this.jobs?.filter((r) => r.department_id === department_id)
        ?.length;
    },
    handleAdd() {
      this.$refs.form.open();
    },
    handleEdit(item) {
      this.$refs.form.open(item);
    },
  },
};
</script>