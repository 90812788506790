<template lang="pug">
v-dialog(v-model="dialog", width="1000", scrollable)
  v-card(v-if="server", :loading="initializing")
    Header(:server="server")
    v-card-text.pt-2
      .s-vstack
        v-form(@submit.prevent="submit", v-if="server.status")
          v-row(wrap)
            v-col(cols="12", md="6")
              v-row(dense, wrap)
                v-col(cols="4")
                  v-text-field(
                    label="First Name",
                    v-model.trim="server.first_name",
                    :error-messages="firstNameErrors",
                    @blur="$v.server.first_name.$touch()"
                  )
                v-col(cols="4")
                  v-text-field(
                    label="Last Name",
                    v-model.trim="server.last_name",
                    :error-messages="lastNameErrors",
                    @blur="$v.server.last_name.$touch()"
                  )
                v-col(cols="4")
                  v-text-field(
                    label="Receipt Display Name",
                    v-model.trim="server.name",
                    hint="Name on receipt",
                    :error-messages="nameErrors",
                    @blur="$v.server.name.$touch()"
                  )
                v-col(cols="6")
                  v-text-field(
                    label="Passcode",
                    v-model="server.pass",
                    :error-messages="passErrors",
                    @blur="$v.server.pass.$touch()",
                    required,
                    hint="Numbers only"
                  )
                v-col(cols="6")
                  v-text-field(
                    label="Employee ID",
                    placeholder="optional",
                    v-model="server.employeeID"
                  )
                v-col(cols="6")
                  v-text-field(
                    label="Email",
                    placeholder="optional",
                    v-model="server.email",
                    :error-messages="emailErrors",
                    @blur="$v.server.email.$touch()"
                  )
                v-col(cols="6")
                  PhoneField(v-model="server.phone", placeholder="optional")
              v-row(dense, wrap)
                v-col(cols="6")
                  v-switch(
                    color="secondary",
                    dense,
                    hide-details,
                    label="Advanced Access",
                    v-model="server.isAdmin"
                  )
                v-col(cols="6")
                  v-switch(
                    color="secondary",
                    dense,
                    hide-details,
                    label="Full Access",
                    v-model="server.isMaster"
                  )
                v-col(cols="6")
                  v-switch(
                    color="secondary",
                    dense,
                    hide-details,
                    label="Turn Off Clock In",
                    v-model="server.noClockIn"
                  )
            v-col(
              cols="12",
              md="6",
              style="max-height: 800px; overflow-y: auto"
            )
              v-subheader Role and Wage
              v-row(dense, wrap)
                v-col(
                  cols="12",
                  v-for="(group, index) in groupedRoles",
                  :key="index"
                )
                  v-checkbox(
                    v-model="group.selected",
                    :label="group.name",
                    hide-details
                  )
                  simple-table(v-if="group.selected")
                    tbody
                      tr(
                        v-for="(role, rindex) in group.roles",
                        :key="rindex + 'r'"
                      )
                        td
                          v-checkbox(
                            v-model="role.selected",
                            :label="role.name",
                            hide-details,
                            dense
                          )
                        td
                          PriceField(
                            v-model.number="role.rate",
                            v-if="showWage && role.selected",
                            single-line,
                            dense,
                            hide-details
                          )
          v-btn.mt-3(
            block,
            color="secondary",
            type="submit",
            :loading="loading",
            :disabled="$v.$invalid"
          ) Save
          .red--text(v-for="(error, index) in errors", :key="index + 'index'") {{ error }}
        ToggleStatus(:server="server", @done="open")
        DeleteOne(:server="server", @deleted="dialog = false")
        Info
        div
          .caption(v-if="server.created", style="text-align: center") Created at {{ server.created | datetime }}
          .caption(v-if="server._id", style="text-align: center") ID: {{ server._id }}
</template>

<script>
import { validationMixin } from "vuelidate";
import { email, numeric, required } from "vuelidate/lib/validators";
import Info from "../Info";
import DeleteOne from "./DeleteOne";
import Header from "./Header";
import ToggleStatus from "./ToggleStatus";

export default {
  name: "StaffForm",
  components: { Header, ToggleStatus, DeleteOne, Info },
  mixins: [validationMixin],
  props: {
    biz: { type: Object, required: true },
    enterprise: { type: Object, default: null },
    bizs: { type: Array, default: () => [] },
    pos_list: { type: Array, default: () => [] },
    showWage: { type: Boolean, default: true },
  },
  validations: {
    server: {
      first_name: { required },
      last_name: { required },
      name: { required },
      pass: { required, numeric },
      email: { email },
    },
  },
  data() {
    return {
      server: null,
      groupedRoles: [],
      dialog: false,
      errors: [],
      initializing: false,
      loading: false,
    };
  },
  computed: {
    firstNameErrors() {
      const errors = [];
      if (!this.$v.server.first_name.$dirty) return errors;
      if (!this.$v.server.first_name.required) errors.push("Required");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.server.last_name.$dirty) return errors;
      if (!this.$v.server.last_name.required) errors.push("Required");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.server.name.$dirty) return errors;
      if (!this.$v.server.name.required) errors.push("Required");
      return errors;
    },
    passErrors() {
      const errors = [];
      if (!this.$v.server.pass.$dirty) return errors;
      !this.$v.server.pass.required && errors.push("Passcode is required");
      !this.$v.server.pass.numeric && errors.push("Passcode should be numbers");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.server.email.$dirty) return errors;
      !this.$v.server.email.email && errors.push("Invalid email");
      return errors;
    },
  },
  methods: {
    async open(data, remote = true) {
      if (!this.biz) return;
      this.errors = [];
      this.dialog = true;
      this.initializing = true;
      if (!data) {
        this.server = {
          biz: this.biz._id,
          bizs: [this.biz._id],
          name: "",
          first_name: "",
          last_name: "",
          employeeID: "",
          phone: "",
          email: "",
          roles: [],
          isAdmin: false,
          isMaster: false,
          pass: "",
          noClockIn: false,
          status: true,
        };
        this.setGroupedRoles(this.server);
        return;
      } else {
        if (data._id && remote) {
          // reload from server
          const result = await this.$api.hr.person.retrieve({
            criteria: { _id: data._id },
          });
          this.$store.dispatch("hr/updatePerson", result);
          this.server = JSON.parse(JSON.stringify(result));
        } else {
          this.server = JSON.parse(JSON.stringify(data));
        }
      }

      const name_items = this.server.fullname?.split(" ", 2);
      if (!this.server.first_name) {
        if (name_items?.length) this.server.first_name = name_items[0];
      }
      if (!this.server.last_name) {
        if (name_items?.length && name_items.length > 1)
          this.server.last_name = name_items[1];
      }
      this.setGroupedRoles(this.server);
      this.initializing = false;
    },
    setGroupedRoles(server) {
      const result = this.bizs.map((biz) => {
        const biz_id = biz._id;
        const biz_name = biz.name;
        const biz_short_name = biz.short_name;
        let name = biz_name;
        // name take prefix of name upto 20 characters
        if (biz_short_name)
          name = `${biz_short_name} - ${biz_name.substring(0, 20)}`;
        const selected = server.bizs?.includes(biz_id);
        let roles = this.pos_list
          .find((o) => o.biz === biz_id)
          ?.jobs?.map((job) => {
            const found = server.roles.find((r) => r.role_id === job._id);
            return {
              role_id: job._id,
              name: job.name,
              rate: found?.rate || 0,
              wage_type: found?.wage_type || "hourly",
              biz: biz_id,
              external_id: job.external_id,
              department_id: job.department_id,
              selected: !!found,
            };
          });
        // sort by name
        roles?.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        return {
          biz_id,
          name,
          biz_name,
          biz_short_name,
          roles,
          selected,
        };
      });
      // sort by selected then by name
      this.groupedRoles = result.sort((a, b) => {
        if (a.selected && !b.selected) return -1;
        if (!a.selected && b.selected) return 1;
        return a.biz_name.localeCompare(b.biz_name);
      });
    },
    async setRoles() {
      // convert selected values groupedRoles to roles
      this.server.bizs = this.groupedRoles
        .filter((o) => o.selected)
        .map((o) => o.biz_id);
      const roles = [];
      this.groupedRoles.forEach((group) => {
        if (!group.selected) return;
        group.roles?.forEach((role) => {
          if (role.selected) {
            roles.push({
              role_id: role.role_id,
              name: role.name,
              rate: role.rate,
              biz: role.biz,
              wage_type: role.wage_type,
              external_id: role.external_id,
              department_id: role.department_id,
            });
          }
        });
      });
      this.server.roles = roles;
    },
    validate() {
      if (!this.server.first_name) throw new Error("First Name is required");
      if (!this.server.last_name) throw new Error("Last Name is required");
      if (!this.server.name) {
        throw new Error("Receipt Display Name is required");
      }
      this.server.fullname = `${this.server.first_name} ${this.server.last_name}`;
      if (!this.server.pass) throw new Error("Passcode is required");
      if (!this.server.pass.match(/^\d+$/)) {
        throw new Error("Passcode should be numbers");
      }
      if (!this.server.bizs?.length) {
        throw new Error("At least one business is required");
      }
      // at least one role is required
      if (!this.server.roles?.length) {
        throw new Error("At least one role is required");
      }
      // if email is provided, transform to lowercase
      if (this.server.email) {
        this.server.email = this.server.email.toLowerCase();
      }
    },
    async submit() {
      /// validate
      this.loading = true;
      try {
        this.setRoles();
        this.validate();
        const server = {
          enterprise: this.biz?.enterprise,
          biz: this.biz._id,
          bizs: this.server.bizs,
          external_id: this.server.external_id,
          name: this.server.name,
          fullname: this.server.fullname,
          first_name: this.server.first_name,
          last_name: this.server.last_name,
          employeeID: this.server.employeeID,
          phone: this.server.phone,
          email: this.server.email,
          roles: this.server.roles,
          isAdmin: this.server.isAdmin,
          isMaster: this.server.isMaster,
          pass: this.server.pass,
          noClockIn: this.server.noClockIn,
        };
        if (this.server.photo) server.photo = this.server.photo;
        if (this.server._id) {
          const action = { $set: server };
          const result = await this.$api.hr.person.put(this.server._id, action);
          this.$store.dispatch("hr/updatePerson", result);
        } else {
          const result = await this.$api.hr.person.create(this.server);
          this.$store.dispatch("hr/addPerson", result);
        }
        this.$emit("updated");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
