<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Pay In/Out
    v-spacer
    MonthSelector(@select="load")
  List(:result="result")
  v-card-actions
    Download(:result="result")
</template>

<script>
import List from "./List";
import Download from "./Download";
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  components: { List, Download },
  data() {
    return { range: null, result: null };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load(month) {
      if (month) this.range = month.range;
      if (!this.range) return;
      const params = {
        criteria: {
          biz: this.biz._id,
          created: { $gt: this.range.begin, $lte: this.range.end },
        },
      };
      this.result = await this.$api.cashdrawer.list(params);
    },
  },
};
</script>
