import { render, staticRenderFns } from "./ClaimedChart.vue?vue&type=template&id=5e0c53a4&lang=pug"
import script from "./ClaimedChart.vue?vue&type=script&lang=js"
export * from "./ClaimedChart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports