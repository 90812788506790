<template lang="pug">
.table-box
  simple-table(v-if="report")
    thead
      tr
        th Period
        th #Sales
        th Subtotal
        th Gift Card
        th Tax
        th Tip
        th Discount
        th Other
        th.green--text Selflane Charges
        th Total
        th.green--text Processing Fee
        th.green--text Balance
    tbody
      tr(
        v-for="(item, index) in report",
        :key="index",
        @click="show(item)",
        role="button"
      )
        td {{ time(item) }}
        td {{ item.count }}
        td {{ item.dish | currency }}
        td {{ item.gift | currency }}
        td {{ item.tax | currency }}
        td {{ item.tip | currency }}
        td ({{ item.deduction | currency }})
        td {{ item.fee | currency }}
        td {{ item.selflane | currency }}
        td {{ item.total | currency }}
        td ({{ (item.application_fee - item.selflane) | currency }})
        td {{ (item.total - item.application_fee) | currency }}
    tfoot
      tr
        th {{ sum.period }}
        th {{ sum.count }}
        th {{ sum.dish | currency }}
        th {{ sum.gift | currency }}
        th {{ sum.tax | currency }}
        th {{ sum.tip | currency }}
        th ({{ sum.deduction | currency }})
        th {{ sum.fee | currency }}
        th {{ sum.selflane | currency }}
        th {{ sum.total | currency }}
        th ({{ (sum.application_fee - sum.selflane) | currency }})
        th {{ (sum.total - sum.application_fee) | currency }}
</template>

<script>
import moment from "moment-timezone";
import { EventBus } from "@/event-bus.js";

export default {
  props: ["report", "sum", "year"],
  computed: {
    // for year with Jan 1 being Sunday, no shift
    // otherwise, shift by 1
    week_shift() {
      if (moment().year(this.year).startOf("year").day() == 0) return 0;
      return 1;
    },
  },
  methods: {
    time(item) {
      const month = this.$options.filters.month;
      if (item.month) {
        return month(item.month, true);
      }
      // weekly
      const begin = this.getBegin(this.year, item.week).format("MMM D");
      const end = this.getEnd(this.year, item.week).format("MMM D");
      if (item.year != this.year) {
        return begin + " - " + end + ", " + item.year;
      } else {
        return begin + " - " + end;
      }
    },
    show(item) {
      let range;
      if (item.month) {
        const time = moment()
          .year(item.year)
          .month(item.month - 1);
        range = {
          begin: time.startOf("month").unix() * 1000,
          end: time.endOf("month").unix() * 1000,
        };
      } else {
        range = {
          begin: this.getBegin(this.year, item.week).unix() * 1000,
          end: this.getEnd(this.year, item.week).unix() * 1000,
        };
      }
      EventBus.$emit("show-online-ordes-with-detail", range);
    },
    getBegin(year, week) {
      if (week == 0) return moment().year(year).startOf("year");
      return moment()
        .year(year)
        .week(week + this.week_shift)
        .startOf("week");
    },
    getEnd(year, week) {
      const result = moment()
        .year(year)
        .week(week + this.week_shift)
        .endOf("week");
      if (result.year() > year) return moment().year(year).endOf("year");
      return result;
    },
  },
};
</script>

<style lang="sass" scoped>
.table-box
  max-height: 300px
  overflow: scroll
</style>
