<template lang="pug">
div
  .subtitle-2 Direct Link to Your Business
  .text--secondary.my-3 {{ url }}
  .d-flex.flex-row
    v-btn.text-capitalize(
      small,
      outlined,
      rounded,
      color="secondary",
      @click="copyURL"
    ) Click & Copy URL
    v-btn.text-capitalize.ml-3(
      small,
      outlined,
      rounded,
      color="secondary",
      :href="url",
      target="_blank"
    ) Show Page
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
  },
  methods: {
    copyURL() {
      if (!this.url) return;
      this.$copyText(this.url);
    },
  },
};
</script>
