<template lang="pug">
v-card(v-if="data", flat)
  simple-table
    thead
      tr
        th To-go Type
        th #Orders
        th.text-right Net Sales
    tbody
      tr(v-for="(item, index) in data", :key="index")
        th {{ item.name }}
        td {{ item.count }}
        td.text-right {{ item.net | currency }}
</template>

<script>
export default {
  props: ["data"],
};
</script>
