<template lang="pug">
div
  v-card(flat)
    v-toolbar(dense, flat)
      .subtitle-2 Point Items
      v-chip.ml-2(small, outlined, v-if="items") {{ items.length }}
      v-spacer
      IconBtn(@click="createMulti()", title="Add", icon="mdi-plus")
    simple-table
      tbody
        tr(
          v-for="(item, index) in items",
          :key="index",
          @click="edit(item)",
          role="button"
        )
          td
            v-avatar(@click.stop="editPic(item)", role="button")
              v-img(:src="mediaHost + item.pic", v-if="item.pic")
              v-icon(v-else) mdi-image
          td {{ item.price }} PTS
          td {{ item.name }}
          td.text-right
            v-btn.my-0(icon, small, @click.stop="handleRemove(item)")
              v-icon(small, color="red") mdi-delete
  Form(:input="toEdit", @done="closeForm")
  MultiForm(:input="toCreate", @done="closeMultiForm")
  v-dialog(v-model="picDialog", max-width="400px")
    v-card
      v-toolbar(flat, dense)
        span Edit Picture
        v-spacer
        span(v-if="itemPic") {{ itemPic.name }}
      v-card-text
        v-layout(row, align-center, justify-center)
          croppa(v-model="myCroppa", :width="168", :height="168", :quality="2")
          v-btn(@click.stop="upload", :loading="loadingPic") save
        .text-center
          .caption Use mouse to move and zoom.
</template>

<script>
import Form from "./SingleForm";
import MultiForm from "./MultiForm";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Form, MultiForm },
  data() {
    return {
      items: [],
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      picDialog: false,
      myCroppa: {},
      itemPic: null,
      loadingPic: false,
      toEdit: null,
      toCreate: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    create() {
      if (!this.biz || !this.biz._id) return;
      this.toEdit = {
        name: "",
        price: 10,
      };
    },
    createMulti() {
      this.toCreate = true;
    },
    closeMultiForm(items) {
      this.toCreate = false;
      if (!this.biz) return;
      if (!items || !items.length) return;
      const options = {
        criteria: { biz: this.biz._id },
        action: { $push: { items: { $each: items } } },
      };
      this.handleUpdate(options);
    },
    edit(item) {
      this.toEdit = item;
    },
    closeForm(item) {
      this.toEdit = null;
      if (!this.biz || !item) return;
      const params = {
        criteria: { biz: this.biz._id, "items._id": item._id },
        action: { $set: { "items.$": item } },
      };
      this.handleUpdate(params);
    },
    editPic(item) {
      this.picDialog = true;
      this.itemPic = item;
    },
    upload() {
      if (!this.biz?._id || !this.itemPic?._id) return;
      const bizId = this.biz._id;
      const itemId = this.itemPic._id;

      this.myCroppa.generateBlob(
        (blob) => {
          if (!blob) return;
          this.loadingPic = true;
          const fd = new FormData();
          fd.append("file", blob);
          fd.append("path", "public/img/biz/pointShop/" + bizId + "-" + itemId);
          fd.append("acl", "public-read");
          fd.append("name", "upload.jpeg");
          this.$api.picture.create(fd).then((data) => {
            this.myCroppa.remove();
            const files = data.files;
            if (!files || files.length === 0) return;
            const options = {
              criteria: { biz: bizId, "items._id": itemId },
              action: { $set: { "items.$.pic": files[0] } },
            };
            this.handleUpdate(options);
          });
        },
        "image/jpeg",
        0.8
      );
    },
    async handleUpdate(options) {
      if (!options) return;
      const result = await this.$api.pointShop.update(options);
      this.items = result?.items;
      this.loadingPic = false;
      this.picDialog = false;
    },
    handleRemove(item) {
      confirm("Do you want to delete this point item?") && this.remove(item);
    },
    remove(item) {
      if (!item || !item._id || !this.biz) return;
      const options = {
        criteria: { biz: this.biz._id },
        action: { $pull: { items: { _id: item._id } } },
      };
      this.handleUpdate(options);
    },
    async load() {
      if (!this.biz) return;
      this.items = [];
      const params = { criteria: { biz: this.biz._id } };
      const result = await this.$api.pointShop.retrieve(params);
      this.items = result?.items;
    },
  },
};
</script>
