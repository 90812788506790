<template lang="pug">
v-btn(icon, small, @click.stop="handleRemove()", :loading="loading")
  v-icon.red--text(small) mdi-delete
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  methods: {
    handleRemove() {
      const message = "Do you want to remove this point voucher?";
      confirm(message) && this.deleteOne();
    },
    async deleteOne() {
      if (!this.item?._id) return;
      this.loading = true;
      try {
        await this.$api.pointVoucher.delete(this.item._id);
        this.$store.dispatch("removePointVoucher", this.item);
        this.$toast.success("Point voucher removed");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>