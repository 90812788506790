<template lang="pug">
v-btn(
  @click="deleteRole(role)",
  color="error",
  x-small,
  depressed,
  :loading="loading"
) Delete
</template>

<script>
export default {
  props: {
    biz: { type: Object, required: true },
    role: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteRole(role) {
      confirm("Are you sure you want to delete this role?") &&
        this.handleDelete(role);
    },
    async handleDelete(role) {
      if (!this.biz) return this.$toast.error("No business selected");
      this.loading = true;
      try {
        const params = { bizId: this.biz._id, roleId: role.id };
        await this.$api.seven.role.delete(params);
        this.$store.dispatch("seven/removeRole", role);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>