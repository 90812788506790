<template lang="pug">
Page(title="Timesheet", :items="items", :doc="doc")
  template(v-slot:header)
    IconBtn(@click="create", title="Add", icon="mdi-plus")
  Hints
  Form(ref="form")
</template>

<script>
import Form from "/libs/components/HR/Punchcard/Form";
import Hints from "@/components/HR/Hints";

export default {
  components: { Form, Hints },
  data() {
    return {
      items: [
        { title: "Day", url: "/hr/timesheet/day" },
        { title: "Week", url: "/hr/timesheet/week" },
      ],
      doc: "https://docs.selflane.com/biz/employee/timesheet.html",
    };
  },
  methods: {
    create() {
      this.$refs.form.create();
    },
  },
};
</script>
