import Main from './index.vue'
import Scheduled from './Scheduled/index.vue'
import Voucher from './Voucher/index.vue'
import Combo from './Combo/index.vue'

export default [
  {
    path: '/discount', component: Main, meta: { auth: true },
    children: [
      { path: '', redirect: 'scheduled' },
      { path: 'scheduled', component: Scheduled },
      { path: 'voucher', component: Voucher },
      { path: 'combo', component: Combo }
    ]
  }
]