<template lang="pug">
div
  div(v-if="!$auth.check()")
    Login
    Blocks
    Create
    YouTube
    Contact
  Guide
</template>

<script>
import YouTube from "./YouTube";
import Login from "./Login";
import Blocks from "./Blocks";
import Create from "./Create";
import Contact from "/libs/components/CRM/POSInquiry";
import Guide from "./Guide";

export default {
  components: { YouTube, Login, Blocks, Create, Contact, Guide },
  metaInfo() {
    const title = process.env.VUE_APP_NAME || "Selflane";
    const description =
      "Selflane is a food ordering and delivery platform. We connect customers with the best restaurants around them. We help restaurants grow their business by providing them with a free online ordering system, delivery management software, customer loyalty program, and an integrated marketing suite.";
    return {
      title,
      meta: [{ name: "description", content: description }],
    };
  },
};
</script>
