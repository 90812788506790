<template lang="pug">
div
  Client(v-if="stripeAccount")
  NewClient(v-else)
</template>

<script>
import { mapGetters } from "vuex";
import Client from "./Client";
import NewClient from "./NewClient";

export default {
  components: { Client, NewClient },
  computed: {
    ...mapGetters(["stripeAccount"]),
  },
};
</script>