<template lang='pug'>
.my-box(v-if="payment")
  div(v-if="payment.charge && payment.charge.amount > 0") Online: {{ payment.charge.amount | currency }}
  ChargeItem(
    v-for="(item, index) in payment.charges",
    :key="index",
    :item="item",
    @refund="$emit('refund', $event)"
  )
</template>

<script>
import ChargeItem from "./ChargeItem";
export default {
  components: { ChargeItem },
  props: ["payment"],
};
</script>

<style lang="sass" scoped>
.my-box
  font-size: 13px
</style>
