// Toggle Dish Online Availability
<template lang="pug">
v-btn(
  icon,
  small,
  @click.stop="toggle(item)",
  v-if="item",
  title="Third Party Sync",
  :loading="loading"
)
  v-icon(small, :color="color") mdi-moped
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    color() {
      if (this.item && this.item.third_party) return "success";
      else return "grey lighten-1";
    },
  },
  methods: {
    async toggle() {
      if (!this.biz) return;
      if (!this.item || !this.item._id) return;
      const value = !(this.item.third_party == true);
      const params = {
        criteria: { _id: this.biz._id, "dishes._id": this.item._id },
        action: { $set: { "dishes.$.third_party": value } },
      };
      this.loading = true;
      const data = await this.$api.biz.update(params);
      this.$store.dispatch("setBiz", data);
      this.loading = false;
    },
  },
};
</script>
