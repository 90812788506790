<template lang="pug">
tr(@click="$emit('edit')", role="button")
  th
    .d-flex.flex-row.align-center
      v-icon(color="green", v-if="item.match") mdi-circle-small
      span {{ item.name }}
  td.text-right(v-if="has7Shifts") {{ department }}
  td.text-right {{ count ? count : "-" }}
  td.text-right
    v-icon(small, :color="getColor(item.order)") {{ getIcon(item.order) }}
  td.text-right
    v-icon(small, :color="getColor(item.manage)") {{ getIcon(item.manage) }}
  td.text-right
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        v-btn(icon, v-on="on")
          v-icon mdi-dots-horizontal
      v-list.py-0(dense)
        v-list-item(@click.stop="$emit('remove')")
          v-list-item-content
            v-list-item-title Delete
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
    has7Shifts: { type: Boolean, required: false },
  },
  computed: {
    ...mapGetters("hr", ["persons"]),
    ...mapGetters("seven", ["departments", "roles"]),
    department() {
      if (this.item.department_id) {
        return (
          this.departments?.find((x) => x.id === this.item.department_id)
            ?.name || "Not Assigned"
        );
      }
      return "Not Assigned";
    },
    count() {
      return this.persons
        ?.filter((x) => x.status)
        ?.filter((x) => x.roles?.some((r) => r.role_id === this.item._id))
        ?.length;
    },
  },
  methods: {
    getColor(value) {
      return value ? "success" : "gray";
    },
    getIcon(value) {
      return value ? "mdi-check-circle" : "mdi-minus-circle";
    },
  },
};
</script>
