<!--
  Navigation Groups filtered depending on the user's role
-->
<template lang="pug">
v-list-item(:to="item.to")
  v-list-item-content
    v-list-item-title {{ item.title }}
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
};
</script>
