<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card(v-if="order && charge")
    v-card-title Void Charge
    v-form(@submit.prevent="submit")
      v-card-text
        p Do you want to void this charge?
        p(v-if="isStripe && isLessThan24Hours") This charge is less than 24 hours old and can be voided.
        p(v-else) This charge is more than 24 hours old or not made by Stripe and cannot be voided here.
        v-text-field(
          v-model="confirmText",
          label="Type VOID CHARGE to confirm",
          required
        )
      v-card-actions
        v-btn(@click="dialog = false") Cancel
        v-btn(type="submit", color="secondary", :loading="loading") Void Charge
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      order: null,
      charge: null,
      confirmText: "",
      loading: false,
    };
  },
  computed: {
    isStripe() {
      return this.charge?.method === "stripe";
    },
    isLessThan24Hours() {
      const cutoff = Date.now() - 48 * 60 * 60 * 1000;
      return this.charge?.time && this.charge.time > cutoff;
    },
  },
  mounted() {
    EventBus.$on("void-order-charge", this.handleOpen);
  },
  destroyed() {
    EventBus.$off("void-order-charge", this.handleOpen);
  },
  methods: {
    handleOpen({ order, charge }) {
      if (!order || !charge)
        return this.$toast.error("Invalid order or charge");
      this.order = order;
      this.charge = charge;
      this.confirmText = "";
      this.dialog = true;
    },
    async submit() {
      if (this.confirmText !== "VOID CHARGE") {
        this.$toast.error("Please type VOID CHARGE to confirm");
        return;
      }
      if (!this.isStripe || !this.isLessThan24Hours) {
        this.$toast.error("This charge cannot be voided");
        return;
      }
      this.loading = true;
      const stripeId = this.charge.transId;
      try {
        this.$api.stripe.paymentIntent.custom("cancel", {
          stripeId,
        });
        const params = { orderId: this.order._id, chargeId: this.charge._id };
        const result = await this.$api.order.custom("voidCharge", params);
        this.$store.dispatch("updateOrder", result);
        this.$toast.success("Charge voided");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>