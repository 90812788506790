<template lang="pug">
.d-flex.flex-row.align-center
  v-icon.mr-1(x-small) mdi-menu
  v-icon.mr-1(x-small, color="red", title="run out", v-if="item.ooo") mdi-cancel
  div
    span {{ item.name }}
    .caption.text--secondary {{ item.abbr }}
    .caption.text--secondary(v-if="item.servings > 1") Servings: {{ item.servings }}
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
};
</script>
