<template lang="pug">
simple-table(v-if="readers")
  tbody
    tr(
      v-for="(item, index) in readers",
      :key="index",
      @click="show(item)",
      role="button"
    )
      th {{ index + 1 }}. {{ item.device_type }}
      td {{ item.serial_number }}
      td.text-right {{ item.status }}
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: ["readers"],
  methods: {
    show(reader) {
      EventBus.$emit("show-reader-detail", reader);
    },
  },
};
</script>