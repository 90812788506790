<template lang="pug">
div
  div(v-if="giftProgram")
    simple-table
      tbody
        tr
          td Minimum Purchase
          td.text-right.subtitle-2 {{ giftProgram.minimum_purchase | currency }}
        tr
          td Discount
          td.text-right.subtitle-2
            span.success--text(v-if="giftProgram.discount.status") -{{ giftProgram.discount.pct }}% (>{{ giftProgram.discount.minimum | currency }})
            v-icon(v-else, color="error") mdi-close
    .text-center.mb-3(v-if="giftProgram.discount.status") {{ giftProgram.discount.desc }}
    .text-center
      IconBtn(@click="edit()", title="Edit", icon="mdi-pencil")
  ProgramForm
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import ProgramForm from "./ProgramForm";

export default {
  components: { ProgramForm },
  computed: {
    ...mapGetters(["biz", "giftProgram"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setGiftProgram"]),
    async load() {
      if (!this.biz) return;
      const params = { criteria: { biz: this.biz._id } };
      const { data } = await this.axios.post("/gift/program/retrieve", params);
      if (data) this.setGiftProgram(data);
      else this.touch();
    },
    async touch() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      const { data } = await this.axios.post("/gift/program/touch", params);
      this.setGiftProgram(data);
    },
    edit() {
      EventBus.$emit("edit-gift-program");
    },
  },
};
</script>
