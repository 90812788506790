<template lang="pug">
.s-vstack
  h3 Roles
  SevenRoles(v-if="has7Shifts", :biz="biz")
  v-card(flat, v-if="pos")
    v-toolbar(flat, dense)
      Sync.mr-2(v-if="has7Shifts")
      IconBtn(@click="handleAdd", title="Add", icon="mdi-plus")
      v-spacer
      .subtitle-2 {{ items.length }}
    simple-table
      thead
        tr
          th Name
          th.text-right(v-if="has7Shifts") Department
          th.text-right # Employees
          th.text-right(style="width: 110px") Can Order
          th.text-right(style="width: 110px") Can Manage
          th.text-right(style="width: 90px")
      tbody
        Row(
          v-for="(item, index) in items",
          :key="index",
          :item="item",
          :has7Shifts="has7Shifts",
          @edit="handleEdit(item)",
          @remove="handleRemove(item)"
        )
  RemoveRole(ref="removeRole", @removed="loadRoles")
  Form(
    ref="form",
    :biz="biz",
    :has7Shifts="has7Shifts",
    :bizservice="bizservice",
    @saved="loadRoles"
  )
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";
import RemoveRole from "./RemoveRole";
import Row from "./Row";
import SevenRoles from "./SevenRoles";
import Sync from "./Sync";

export default {
  components: { SevenRoles, RemoveRole, Form, Row, Sync },
  props: {
    biz: { type: Object, required: true },
    bizservice: { type: Object, required: true },
  },
  computed: {
    ...mapGetters("seven", ["departments", "roles"]),
    ...mapGetters(["pos"]),
    items() {
      const mapped = this.pos.jobs.map((o) => {
        const found = this.roles.find((r) => r.id == o.external_id);
        const match = !!found;
        const sort = found?.sort || 0;
        return { ...o, sort, match };
      });
      return mapped.toSorted((a, b) => {
        if (a.sort == b.sort) return a.name.localeCompare(b.name);
        return a.sort - b.sort;
      });
    },
    has7Shifts() {
      const app = this.bizservice?.apps?.find((a) => a.name == "7shifts");
      return app?.status == "established";
    },
  },
  methods: {
    handleRemove(role) {
      this.$refs.removeRole.handleRemove(role);
    },
    handleEdit(role) {
      this.$refs.form.open(role);
    },
    handleAdd() {
      this.$refs.form.open({ name: "", order: false, manage: false });
    },
    async loadRoles() {
      if (!this.biz) return;
      if (!this.has7Shifts) {
        this.$store.dispatch("seven/setRoles", []);
        return;
      }
      const params = { bizId: this.biz._id };
      try {
        const result = await this.$api.seven.role.list(params);
        this.$store.dispatch("seven/setRoles", result);
      } catch (e) {
        this.$store.dispatch("seven/setRoles", []);
        // fail silently
      }
    },
  },
};
</script>
