<template lang="pug">
.d-flex.flex-row.align-center
  v-icon(small) mdi-menu
  img.gallery-img(:src="fullurl")
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
    };
  },
  computed: {
    fullurl() {
      return this.mediaHost + this.item.url;
    },
  },
};
</script>

<style lang="sass" scoped>
.gallery-img
  height: 100px
  width: 150px
  object-fit: cover
  margin: 0.5rem
  border-radius: 0.5rem
</style>