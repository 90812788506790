<template lang="pug">
div
  v-data-table(
    :items="purchases",
    :headers="headers",
    sort-by="created",
    sort-desc,
    mobile-breakpoint=0,
    @click:row="showPurchase",
    role="button"
  )
    template(v-slot:item.created="{ item }")
      span {{ item.created | date }}
    template(v-slot:item.payment.total="{ item }")
      span {{ item.payment.total | currency }}
    template(v-slot:item.action="{ item }")
      v-btn.mr-3(
        x-small,
        outlined,
        color="error",
        @click.stop="showPay(item)",
        v-if="!item.payment.charge?.id"
      ) pay
      v-btn(x-small, outlined, color="secondary", @click.stop="download(item)") PDF
  Detail(ref="detail")
  Pay(ref="pay")
</template>

<script>
import { mapGetters } from "vuex";
import FileSaver from "file-saver";
import Detail from "./Detail";
import Pay from "./Pay";

export default {
  components: { Detail, Pay },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Date", value: "created" },
        { text: "Amount", value: "payment.total" },
        { text: "", value: "action", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz", "purchases"]),
  },
  methods: {
    async download(item) {
      if (!item) return;
      const params = { orderId: item._id };
      try {
        const file = await this.axios.post("/bizOrder/createPDF", params, {
          responseType: "blob",
        });
        FileSaver.saveAs(file.data, "purchase-" + item._id + ".pdf");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    showPay(item) {
      this.$refs.pay.open(item);
    },
    showPurchase(id) {
      this.$refs.detail.load(id);
    },
  },
};
</script>
