<template lang="pug">
v-container.py-5
  v-container
    .text-center.font-weight-regular.title OUR
    .text-center.font-weight-medium.display-2.py-2.orange--text.text--lighten-1 SERVICES
    .verticalspacer
  v-container
    v-layout(align-center, justify-space-around, wrap)
      v-flex(sm4, xs12)
        v-layout(column, align-center)
          .hexwrapper
            .heximg
              v-img(
                src="https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/icons/emv.png",
                contain,
                height="inherit"
              )
            .hexagon1
            .hexagon2
            .hexagon3
            .hexindex 01
      v-flex.px-3(sm5, xs12)
        .pointtitle Point-of-Sale
        ul
          li $29.99/month, per business
          li Unlimited register stations
          li Compatible with all major payment processors
          li Easy navigation and smooth processing
    .verticalspacer
    v-layout(align-center, justify-space-around, wrap)
      v-flex(sm4, xs12)
        v-layout(column, align-center)
          .hexwrapper
            .heximg
              v-img(
                src="https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/icons/report.png",
                contain,
                height="inherit"
              )
            .hexagon1
            .hexagon2
            .hexagon3
            .hexindex 02
      v-flex.px-3(sm5, xs12)
        .pointtitle Smart Management
        ul
          li Manage your business from your laptop, iPhone, and iPad, anywhere and anytime.
          li Review detailed sales and expense reports.
          li Edit menus, employee rosters, timecards, schedules, and more.

    .verticalspacer
    v-layout(align-center, justify-space-around, wrap)
      v-flex(sm4, xs12)
        v-layout(column, align-center)
          .hexwrapper
            .heximg
              v-img(
                src="https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/icons/reservation.png",
                contain,
                height="inherit"
              )
            .hexagon1
            .hexagon2
            .hexagon3
            .hexindex 03
      v-flex.px-3(sm5, xs12)
        .pointtitle Online Ordering
        ul
          li FREE online ordering platform.
          li 100% commission-free.
          li Automatically print orders.
          li Make reservations.

    .verticalspacer
    v-layout(align-center, justify-space-around, wrap)
      v-flex(sm4, xs12)
        v-layout(column, align-center)
          .hexwrapper
            .heximg
              v-img(
                src="https://s3-us-west-2.amazonaws.com/media.selflane.com/public/img/icons/monitor.png",
                contain,
                height="inherit"
              )
            .hexagon1
            .hexagon2
            .hexagon3
            .hexindex 04
      v-flex.px-3(sm5, xs12)
        .pointtitle Kitchen Display System
        ul
          li Detailed order and table numbers show up on the kitchen display.
          li Automatically notify servers of order status (ready to run).
          li Check off individual dishes on orders.
          li See finished orders.
</template>

<script>
export default {
  name: "WhatWeDo",
};
</script>

<style lang="sass" scoped>
.verticalspacer
  border: 1px #bdbdbd
  border-style: none dashed none none
  height: 150px
  width: 1px
  top: 36px
  margin-left: auto
  margin-right: auto
  margin-top: 36px
  margin-bottom: 36px

.hexwrapper
  height: 250px
  width: 150px
  margin-top: 36px
  margin-bottom: 36px

.hexindex
  position: relative
  top: -70px
  left: -50px
  font-weight: bold
  font-size: 95px
  color: #ffa726

.heximg
  position: absolute
  height: 250px
  width: 144.3px
  transition: 0.3s ease

.hexagon1
  position: absolute
  height: 250px
  width: 144.3px
  border: 3px #bdbdbd
  border-style: solid none solid none
  transform: rotate(60deg)

.hexagon2
  position: absolute
  height: 250px
  width: 144.3px
  border: 3px #bdbdbd
  border-style: solid none solid none
  transform: rotate(-60deg)

.hexagon3
  position: absolute
  height: 250px
  width: 144.3px
  border: 3px #bdbdbd
  border-style: solid none solid none

.pointtitle
  text-transform: uppercase
  margin-left: 18px
  margin-bottom: 18px
  font-size: 26px
  font-weight: 500
  color: #757575

li
  margin-bottom: 8px
  font-size: 16px
  font-weight: 500
</style>
