<template lang="pug">
v-container(fluid)
  .display-2.text-center.orange--text.text--lighten-1.my-3 About
  v-container.white--text
    v-row.orange.lighten-1(align="center", wrap)
      v-col.text-center.abouttitle(cols="12", md="6")
        span We
        br
        span Are
      v-col.pa-5.title(cols="12", md="6")
        p a startup company built with customers and restaurant owners in mind.
        p We built our company in 2017, and we've gone through many updates and improvements--but we're still growing and getting even better.
        p We believe in fair and transparent pricing, communication, and service.
        p No gimmicks, just good business.
</template>

<script>
export default {
  name: "About",
};
</script>


<style lang="sass" scoped>
.abouttitle
  font-size: 100px
  font-weight: 500
  text-transform: uppercase
</style>
