<template lang="pug">
div
  openPlan
  SpecialPlans.my-5
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OpenPlan from "./OpenPlan/index";
import SpecialPlans from "./SpecialPlans/index";
import { EventBus } from "@/event-bus.js";

export default {
  components: { OpenPlan, SpecialPlans },
  computed: {
    ...mapGetters(["biz", "influencerPlans"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setInfluencerPlans"]),
    async load() {
      if (!this.biz) return;
      let params = { criteria: { biz: this.biz._id } };
      const result = await this.$api.influencer.plan.list(params);
      this.setInfluencerPlans(result);
    },
  },
};
</script>
