<template lang="pug">
v-dialog(v-model="dialog", max-width="400")
  v-card(v-if="member")
    v-card-title Edit Phone
    v-card-text
      v-form(@submit.prevent="submit")
        PhoneField(v-model="phone")
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      phone: "",
      loading: false,
    };
  },
  computed: { ...mapGetters(["member"]) },
  mounted() {
    EventBus.$on("edit-member-phone", this.edit);
  },
  destroyed() {
    EventBus.$off("edit-member-phone", this.edit);
  },
  methods: {
    ...mapActions(["updateMember", "setMember"]),
    edit() {
      this.phone = this.member?.phone;
      this.dialog = true;
    },
    async submit() {
      if (this.member?._id == undefined) return;
      if (this.phone == this.member.phone) {
        this.dialog = false;
        return;
      }
      this.loading = true;
      const params = { id: this.member._id, phone: this.phone };
      try {
        const res = await this.axios.post("/member/edit/phone", params);
        this.updateMember(res.data);
        this.setMember(res.data);
        this.dialog = false;
        this.$toast.success("Saved");
      } catch (e) {
        this.$toast.error(e?.response?.data || "Error");
      }
      this.loading = false;
    },
  },
};
</script>