<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 {{ item.name }}
    v-spacer
    SyncMenu(:item="item")
  v-card-text
    table
      tr
        td.subtitle-2 Last Update
        td {{ item.third_party.last_sync | datetime }}
      tr
        td.subtitle-2 API Key
        td {{ item.third_party.chowly_id }}
      tr
        td.subtitle-2 Test URL
        td {{ item.third_party.test_url }}
        td
          v-btn(
            icon,
            @click.stop="",
            :href="item.third_party.test_url",
            target="_blank",
            v-if="item.third_party.test_url"
          )
            v-icon(small) mdi-link
</template>

<script>
import SyncMenu from "/libs/components/Biz/Chowly/SyncMenu";

export default {
  components: { SyncMenu },
  props: ["item"],
};
</script>
