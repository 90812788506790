<template lang="pug">
div
  .d-flex.flex-row.align-center.justify-space-between.mb-3
    BizStartHour
    DaySelector(@select="load")
  v-progress-linear.my-10(indeterminate, v-if="loading", color="secondary")
  Report(
    :result="result",
    :pointearn-report="pointearn_report",
    :pointredeem-report="pointredeem_report"
  )
  Shifts(:shifts="shifts", v-if="!loading")
  .text-center.caption
    span Data on this page matches the printed daily report from the POS in real-time.
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import { EventBus } from "@/event-bus.js";
import Report from "./Report";
import Shifts from "./Shifts";

export default {
  components: { Report, Shifts },
  data() {
    return {
      loading: false,
      date: Date.now(),
      result: null,
      shifts: [],
      pointearn_report: null,
      pointredeem_report: null,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    formatted_date() {
      return moment(this.date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load(date) {
      if (!this.biz) return;
      this.result = null;
      if (date) this.date = date;

      const params = { bizId: this.biz._id, date: this.formatted_date };
      this.loading = true;
      const { data } = await this.axios.post("/orders/bizdailyreport", params);
      this.result = data;
      await this.loadShifts();
      await this.loadPointEarn();
      await this.loadPointRedeem();
      this.loading = false;
    },
    async loadShifts() {
      this.shifts = [];
      const begin = moment(this.date).startOf("day").valueOf();
      const end = moment(this.date).endOf("day").valueOf();
      const params = {
        criteria: { biz: this.biz._id, created: { $gt: begin, $lt: end } },
      };
      const { data } = await this.axios.post("/pos/shifts/list", params);
      for (const o of data) {
        await this.loadShiftReport(o);
      }
    },
    async loadShiftReport(shift) {
      if (!shift) return;
      const params = { shiftId: shift._id };
      const { data } = await this.axios.post("/orders/bizshiftreport", params);
      this.shifts.push({ shift: shift, report: data });
    },
    async loadPointEarn() {
      if (!this.biz) return;
      const params = {
        bizId: this.biz._id,
        begin_date: this.formatted_date,
        end_date: this.formatted_date,
      };
      try {
        const { data } = await this.axios.post("/pointearn/report", params);
        this.pointearn_report = data;
      } catch (e) {
        //
      }
    },
    async loadPointRedeem() {
      if (!this.biz) return;
      const params = {
        bizId: this.biz._id,
        begin_date: this.formatted_date,
        end_date: this.formatted_date,
      };
      try {
        const { data } = await this.axios.post("/pointredeem/report", params);
        this.pointredeem_report = data;
      } catch (e) {
        //
      }
    },
  },
};
</script>
