const state = {
  influencerPlans: []
}

const getters = {
  influencerPlans: state => state.influencerPlans
}

const actions = {
  setInfluencerPlans: ({ commit }, data) => { commit('setInfluencerPlans', data) },
  updateInfluencerPlan: ({ commit }, data) => { commit('updateInfluencerPlan', data) },
  addInfluencerPlan: ({ commit }, data) => { commit('addInfluencerPlan', data) },
  removeInfluencerPlan: ({ commit }, data) => { commit('removeInfluencerPlan', data) },
}

const mutations = {
  setInfluencerPlans(state, data) {
    state.influencerPlans = data
  },
  updateInfluencerPlan(state, data) {
    state.influencerPlans = state.influencerPlans.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addInfluencerPlan(state, data) {
    state.influencerPlans.push(data)
  },
  removeInfluencerPlan(state, data) {
    state.influencerPlans = state.influencerPlans.filter(o => o._id !== data._id)
  },
}

export default { state, getters, actions, mutations }