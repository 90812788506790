<template lang="pug">
v-card(v-if="biz", shaped)
  v-toolbar(flat, dense)
    .subtitle-2 Setup Online Order in 30 Minutes
  v-divider
  v-timeline(align-top, dense)
    v-timeline-item(
      small,
      color="success",
      v-for="(item, index) in items",
      :key="index"
    )
      v-row
        v-col
          span {{ item.title }}
        v-col(cols="3")
          v-btn(icon, small, :to="item.to")
            v-icon(small, v-if="!item.status", color="secondary") mdi-pencil
            v-icon(small, v-else, color="success") mdi-check
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz"]),
    items() {
      return [
        {
          title: "Create business with name and address",
          status: this.hasBiz,
          to: "/create",
        },
        {
          title: "Edit profile and picture",
          status: this.hasProfile,
          to: "/profile/basic",
        },
        {
          title: "Edit schedule",
          status: this.hasSchedule,
          to: "/profile/hours",
        },
        {
          title: "Edit menus",
          status: this.hasMenu,
          to: "/menu/menus",
        },
        {
          title: "Edit courses",
          status: this.hasCourse,
          to: "/menu/courses",
        },
        {
          title: "Edit dishes",
          status: this.hasDish,
          to: "/menu/dishes",
        },
        {
          title: "Edit tax payer information",
          status: this.hasManagedAccount,
          to: "/payments",
        },
      ];
    },
    hasBiz() {
      return this.biz != null;
    },
    hasProfile() {
      return this.biz?.logo && this.biz.orderType?.pickup?.status;
    },
    hasSchedule() {
      return this.biz?.schedule?.weekdays?.some((day) => day.status);
    },
    hasMenu() {
      return this.biz?.menus?.length;
    },
    hasCourse() {
      return this.biz?.courses?.length;
    },
    hasDish() {
      return this.biz?.dishes?.length;
    },
    hasManagedAccount() {
      return this.biz?.payments?.managed_account;
    },
  },
};
</script>
