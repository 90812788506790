<template lang="pug">
simple-table(v-if="report")
  thead
    tr
      th Name
      th Roles
      th REG Hrs
      th OT Hrs
      th.text-right(v-if="showWage") Pay
  tbody
    tr(v-for="(item, index) in items", :key="index")
      td {{ item.name | truncate }}
      td {{ item.roles }}
      td {{ item.regHours | hours(isDecimal) }}
      td {{ item.extHours | hours(isDecimal) }}
      td.text-right(v-if="showWage") {{ item.cost | currency }}
  tfoot
    tr
      th Total
      th
      th {{ report.regHours | hours(isDecimal) }}
      th {{ report.extHours | hours(isDecimal) }}
      th.text-right(v-if="showWage") {{ report.cost | currency }}
    tr(v-if="tip")
      th Total Tip
      th
      th
      th(v-if="showWage")
      th.text-right {{ tip | currency }}
</template>

<script>
export default {
  props: {
    report: { required: true },
    tip: { type: Number, default: 0 }, // total tips over the report period
    isDecimal: { type: Boolean, default: false },
    showWage: { type: Boolean, default: true },
  },
  computed: {
    // filter out servers with no hours
    items() {
      return this.report.servers
        .filter((s) => s.regHours > 0 || s.extHours > 0)
        .toSorted((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>
