<template lang="pug">
v-form
  v-select(
    :items="companies",
    item-text="name",
    item-value="id",
    v-model="selected"
  )
  v-btn(
    color="secondary",
    outlined,
    block,
    @click="connect",
    :disabled="!selected",
    :loading="loading"
  ) Select Company
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    guid: { type: String, default: "" },
    companies: { type: Array, default: () => [] },
  },
  data() {
    return {
      selected: null, // company id
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    async connect() {
      if (!this.selected || !this.guid) return;
      this.loading = true;
      try {
        const app = {
          name: "7shifts",
          company_id: this.selected,
          access_token: this.guid,
          status: "initiated",
        };
        const result = await this.$api.service.account.custom("connect", {
          bizId: this.biz?._id,
          app,
        });
        this.$store.dispatch("updateBizService", result);
        const locations = await this.$api.seven.location.list({
          bizId: this.biz._id,
        });
        const data = {
          company_id: this.selected,
          locations: locations,
        };
        this.$emit("select", data);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>