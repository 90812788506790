<template lang="pug">
div
  .link-box.my-3
    v-btn(
      small,
      rounded,
      depressed,
      @click="load(1)",
      :class="{ primary: activeType == 1 }"
    ) New Member Promotion
    v-btn(
      small,
      rounded,
      depressed,
      @click="load(2)",
      :class="{ primary: activeType == 2 }"
    ) Birthday Promotion
    v-btn(
      small,
      rounded,
      depressed,
      @click="load(3)",
      :class="{ primary: activeType == 3 }"
    ) Anniversary Promotion
    v-btn(
      small,
      rounded,
      depressed,
      @click="load(5)",
      :class="{ primary: activeType == 5 }"
    ) 30 Day Promotion
    v-btn(
      small,
      rounded,
      depressed,
      @click="load(6)",
      :class="{ primary: activeType == 6 }"
    ) 60 Day Promotion
    v-btn(
      small,
      rounded,
      depressed,
      @click="load(7)",
      :class="{ primary: activeType == 7 }"
    ) 90 Day Promotion
    //- v-btn(depressed @click='load(3)' :class='{"primary": activeType==4}') New Member Promotion
  .text-center
    p New Member Promotion is sent at signing up. Birthday/Anniversary Promotion is set 7 days in advance.
    p A 8 digit code would be generated automatically to each member/promotion.
  Email
</template>

<script>
import Email from "./EmailTemplate/index";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { Email },
  computed: {
    ...mapGetters(["biz", "memberpromo"]),
    activeType() {
      return this.memberpromo?.type || 0;
    },
  },
  mounted() {
    this.load(1);
  },
  methods: {
    ...mapActions(["setMemberPromo"]),
    async load(type) {
      if (!type || !this.biz) return;
      const params = { bizId: this.biz._id, type: type };
      const result = await this.$api.memberPromo.retrieve(params);
      this.setMemberPromo(result);
    },
  },
};
</script>
