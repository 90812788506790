<template lang="pug">
Page(title="Payroll", :doc="doc")
  template(v-slot:header)
    IconBtn(@click="create", title="Add", icon="mdi-plus")
    PayrollSettings(@updated="load")
  .d-flex.flex-row.justify-center.my-3
    DateRangePicker(v-model="range", @select="load()", :loading="loading")
  .d-flex.flex-row.justify-center.my-3
    HHMMPicker(v-model="isDecimal")
  v-row.my-2(justify="center")
    LaborReport(:isDecimal="isDecimal", :tip="totalTip", ref="laborReport")
  ServerReport(
    :isDecimal="isDecimal",
    :showWage="showWage",
    :range="range",
    :report="report"
  )
  Hints(:payroll="true")
  TimecardList(:isDecimal="isDecimal", :showWage="showWage")
  PunchcardForm(ref="punchcardForm")
</template>

<script>
import Hints from "@/components/HR/Hints";
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import ServerReport from "./ServerReport/index";
import TimecardList from "./TimecardList/index";
import PunchcardForm from "/libs/components/HR/Punchcard/Form";

export default {
  components: {
    Hints,
    ServerReport,
    TimecardList,
    PunchcardForm,
  },
  data() {
    return {
      range: [],
      isDecimal: false,
      report: null,
      loading: false,
      doc: "https://docs.selflane.com/biz/employee/payroll.html",
    };
  },
  computed: {
    ...mapGetters(["biz", "pos", "useraccess", "corpaccess"]),
    access() {
      if (!this.$auth.check() || !this.biz) return null;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      return this.useraccess?.find((o) => o.user == userId && o.biz == bizId);
    },
    showWage() {
      if (this.corpaccess) return true;
      return this.access?.privilege == 1 || this.access?.access_wage;
    },
    totalTip() {
      if (!this.report) return 0;
      return this.report.reduce(
        (a, b) => a + (b.tip || 0) + (b.cashTip || 0),
        0
      );
    },
  },
  mounted() {
    const start = moment().day(this.pos?.startDay || 0);
    if (start.isAfter(moment())) start.subtract(1, "week");
    this.range = [start.format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    create() {
      this.$refs.punchcardForm.create();
    },
    async load() {
      if (!this.biz) return;
      if (!this.range || this.range.length < 2) return;
      const begin = moment(this.range[0]).startOf("day").valueOf();
      const end = moment(this.range[1]).endOf("day").valueOf();
      // ensure end is after begin and the difference is less than 6 months
      // 6 months = 6 * 30 * 24 * 60 * 60 * 1000 = 15552000000
      if (end < begin || end - begin > 15552000000) {
        this.$toast.error("Please select a date range less than 6 months");
        return;
      }
      const params = {
        bizId: this.biz._id,
        begin,
        end,
        beginDate: this.range[0],
        endDate: this.range[1],
      };

      this.loading = true;
      this.$refs.laborReport?.load(params);
      // load report
      try {
        this.report = await this.$api.punchcard.custom("serverReport", params);
      } catch (e) {
        //
      }
      await this.loadSales(params);
      this.loading = false;
    },
    async loadSales(params) {
      try {
        const { data } = await this.axios.post("/orders/summarizeTip", params);
        data?.forEach((o) => {
          const found = this.report?.find((sr) => sr.serverId === o._id);
          if (found) {
            found.tip = o.tip;
            found.sales = o.sales || 0;
            found.tax_items = o.tax_items;
          } else {
            this.report.push({
              name: o.name || " N/A",
              tip: o.tip,
              cashTip: o.cashTip || 0,
              sales: o.sales,
              tax_items: [],
              total: 0,
            });
          }
        });
      } catch {
        //
      }
    },
  },
};
</script>
