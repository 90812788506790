const state = {
  pricings: []
}

const getters = {
  pricings: state => state.pricings
}

const actions = {
  setPricings: ({ commit }, data) => { commit('setPricings', data) },
  updatePricing: ({ commit }, data) => { commit('updatePricing', data) },
  addPricing: ({ commit }, data) => { commit('addPricing', data) },
  removePricing: ({ commit }, data) => { commit('removePricing', data) }
}

const mutations = {
  setPricings(state, data) {
    state.pricings = data
  },
  updatePricing(state, data) {
    state.pricings = state.pricings.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addPricing(state, data) {
    state.pricings.push(data)
  },
  removePricing(state, data) {
    state.pricings = state.pricings.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }