<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";

export default {
  props: {
    report: { type: Array, default: null },
    period: { type: String, default: "" }, // YYYY-MM-DD to YYYY-MM-DD
  },
  methods: {
    download() {
      if (!this.report) return;
      const currency = this.$options.filters.currency;
      const csv = new CSV();
      csv.addRow([
        "P&L Group",
        "Course",
        "Item",
        "Quantity",
        "Amount",
        "Modifier",
        "Modifier Quantity",
      ]);
      for (const group of this.report) {
        for (const i in group.dishes) {
          const dish = group.dishes[i];
          let pl = group.course.grp;
          let course = group.course.name;
          let amount = currency(dish.amount);
          if (dish.modifiers?.length) {
            for (const j in dish.modifiers) {
              const modifier = dish.modifiers[j];
              let item = "";
              let quantity = "";
              if (j == 0) {
                item = dish.name;
                quantity = dish.quantity;
              } else {
                amount = "";
              }
              csv.addRow([
                pl,
                course,
                item,
                quantity,
                amount,
                modifier.name,
                modifier.quantity,
              ]);
            }
          } else {
            csv.addRow([pl, course, dish.name, dish.quantity, amount]);
          }
        }
        // add empty row to separate groups
        csv.addRow(["", "", "", "", "", "", ""]);
      }
      csv.save(`itemized-sales-${this.period}`);
    },
  },
};
</script>
