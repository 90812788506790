<template lang="pug">
v-tabs(show-arrows)
  v-tab(v-for="(group, index) in groups", :key="index") {{ group.title }}
  v-tab-item(v-for="(group, index) in groups", :key="index")
    .pa-1.my-tab-box
      v-btn.mb-1(
        v-if="group.enterprise",
        small,
        text,
        color="secondary",
        block,
        @click="select(group.enterprise)"
      ) Manage Enterprise
      div(v-for="subgroup in group.subgroups", :key="subgroup.title")
        .my-subheader-box
          v-icon(small) mdi-menu-right
          div {{ subgroup.title }}
        ItemList(:items="subgroup.items", @selected="$emit('selected')")
      //- ItemList(:items="group.items", @selected="$emit('selected')")
</template>

<script>
import _ from "underscore";
import ItemList from "./ItemList";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { ItemList },
  props: {
    bizs: { type: Array, required: true },
    isSearch: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters("entp", ["enterprises", "regions"]),
    // grouped by enterprise
    enterpriseGroups() {
      let result = _.chain(this.enterprises)
        .sortBy("name")
        .map((o) => {
          const title = o.name;
          const items = _.chain(o.bizs)
            .map((o) => this.bizs.find((b) => b._id == o))
            .compact()
            .sortBy("name")
            .value();
          // group by regions
          const regions = this.regions.filter((r) => r.enterprise == o._id);
          let subgroups = _.chain(regions)
            .map((o) => {
              return {
                title: o.name,
                items: items.filter((b) => o.bizs.includes(b._id)),
              };
            })
            .compact()
            .sortBy("title")
            .value();
          // get no region bizs
          const noRegionBizs = items.filter(
            (b) => !regions.flatMap((o) => o.bizs).includes(b._id)
          );
          if (noRegionBizs.length)
            subgroups = subgroups.concat({
              title: "No Region",
              items: noRegionBizs,
            });
          return { title, items, subgroups, enterprise: o };
        })
        .value();
      // filter out empty subgroups if using Corporate Search
      if (this.isSearch) {
        return result.filter((o) => o.subgroups.some((o) => o.items.length));
      }
      return result;
    },
    // grouped by country and state
    stateGroups() {
      const ids = this.enterprises.flatMap((o) => o.bizs);
      const bizs = this.bizs.filter((o) => !ids.includes(o._id));
      return _.chain(bizs)
        .sortBy("name")
        .groupBy((o) => {
          const country = o.address?.country || "No Country";
          const state = o.address?.state || "No State";
          return country + "-" + state;
        })
        .map((items, k) => {
          let country, state;
          const arr = k.split("-");
          if (arr.length == 2) {
            country = arr[0];
            state = arr[1];
          }
          // subgroup by city
          const subgroups = _.chain(items)
            .groupBy((o) => o.address.city || "No City")
            .map((items, title) => {
              return { title, items };
            })
            .sortBy("title")
            .value();
          return { country, state, items, subgroups, title: state };
        })
        .sortBy((o) => {
          if (o.country == "US") return "  " + o.state;
          return o.country + o.state;
        })
        .value();
    },
    groups() {
      if (!this.enterpriseGroups?.length) return this.stateGroups;
      // combine enterprise groups and biz groups
      return _.chain(this.enterpriseGroups).concat(this.stateGroups).value();
    },
  },
  methods: {
    ...mapActions(["setNavState"]),
    select(enterprise) {
      this.$store.dispatch("entp/setEnterprise", enterprise);
      this.setNavState("enterprise");
      EventBus.$emit("enterprise:changed");
      this.$emit("selected");
      const path = this.$router.history.current.path;
      if (!path.startsWith("/enterprise")) this.$router.push("/enterprise");
    },
  },
};
</script>

<style lang="sass" scoped>
.my-subheader-box
  display: flex
  gap: 10px
  align-items: center
  background: #eee
  font-size: 12px
  font-weight: 400
  padding-top: 2px
  padding-bottom: 2px
  color: #666
  margin-top: 4px
  margin-bottom: 4px
  border-radius: 4px
.my-tab-box
  max-height: 300px
  overflow-y: auto
</style>