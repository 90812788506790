const state = {
  expenses: []
}

const getters = {
  expenses: state => state.expenses
}

const actions = {
  setExpenses: ({ commit }, data) => { commit('setExpenses', data) },
  updateExpense: ({ commit }, data) => { commit('updateExpense', data) },
  addExpense: ({ commit }, data) => { commit('addExpense', data) },
  removeExpense: ({ commit }, data) => { commit('removeExpense', data) }
}

const mutations = {
  setExpenses(state, data) {
    state.expenses = data
  },
  updateExpense(state, data) {
    state.expenses = state.expenses.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addExpense(state, data) {
    state.expenses.push(data)
  },
  removeExpense(state, data) {
    state.expenses = state.expenses.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }