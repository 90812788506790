<template lang="pug">
.s-vstack
  .subtitle-2.info--text Turn On Online Ordering
  div
    v-btn.text-notransform(
      small,
      color="secondary",
      :loading="loading",
      @click="turnOn()",
      v-if="!isOnline"
    ) Turn On Online Ordering
  Online
</template>

<script>
import { mapGetters } from "vuex";
import Online from "/libs/components/Biz/Online";

export default {
  name: "TurnOnOnline",
  components: { Online },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    isOnline() {
      return this.biz?.status?.byAdmin === 1;
    },
  },
  methods: {
    async turnOn() {
      if (!this.biz._id) return;
      this.loading = true;
      // in miliseconds
      const now = new Date();
      try {
        const params = { bizId: this.biz._id, online_start: now.getTime() };
        const result = await this.$api.b.action.custom("turnOnOnline", params);
        this.$store.dispatch("setBiz", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>