<template lang="pug">
v-row(justify="center", wrap)
  CSV(:items="items", :isDecimal="isDecimal", :dates="dates", v-if="showWage")
  PDF.mx-3(
    :items="items",
    :isDecimal="isDecimal",
    :dates="dates",
    v-if="showWage"
  )
  Timecards(:range="range")
</template>

<script>
import CSV from "./CSV";
import PDF from "./PDF";
import Timecards from "./Timecards";

export default {
  name: "ServerReportDownload",
  components: { CSV, PDF, Timecards },
  props: ["items", "isDecimal", "showWage", "range"],
  computed: {
    dates() {
      return this.range.join(" ~ ");
    },
  },
};
</script>
