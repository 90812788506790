<template lang="pug">
v-dialog(v-model="dialog", max-width="400px")
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on", :title="title", icon="mdi-delete", color="error")
  v-card(v-if="order")
    v-card-title Cancel Order
    v-card-text
      OrderHeader(:order="order")
    v-card-text
      .red--text(v-if="error") Not allowed. {{ error }}
      div(v-else)
        span In canceling this order, you agree to refund the customer if there was any payment associated with this order. For online orders, we would refund customer's online payments.
        v-form(@submit.prevent="submit")
          v-text-field(v-model="reason", label="Reason", required, autofocus)
          v-row(justify="space-between")
            v-btn(text, @click="dialog = false") Cancel
            v-btn(
              type="submit",
              text,
              color="secondary",
              :loading="loading",
              :disabled="!reason.length"
            ) Proceed
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";

export default {
  props: ["isOwner", "order"],
  data() {
    return {
      dialog: false,
      reason: "",
      loading: false,
      // 30 days
      cutoff: Date.now() - 2592000000,
    };
  },
  computed: {
    title() {
      if (this.order?.orderer.type == 0) return "Cancel & Full Refund";
      return "Cancel Order";
    },
    error() {
      if (!this.order) return "Order not found.";
      if (this.order.status < 0) return "Order already cancelled.";
      if (!this.isOwner) return "You are not the owner of this business.";
      if (this.order.orderer.type == 0 && this.order.needed < this.cutoff)
        return "Online Order has passed 30 days.";
      return null;
    },
  },
  mounted() {
    EventBus.$on("cancel-order", this.open);
  },
  destroyed() {
    EventBus.$off("cancel-order", this.open);
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async submit() {
      if (!this.order || !this.order._id || !this.reason) return;
      this.loading = true;
      const note =
        this.reason +
        " | Performed from Selflane Business by " +
        this.$auth.user().email +
        " | " +
        moment().format("M/D/YYYY h:mm A");
      const params = { criteria: { _id: this.order._id }, cancelNote: note };
      try {
        const res = await this.axios.post("/orders/cancel", params);
        this.updateOrder(res.data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>