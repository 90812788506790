<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="recipe")
    v-toolbar(flat, dense)
      .subtitle-2 Edit Ingredients | {{ dish.name }}
      v-spacer
      v-btn(
        outlined,
        small,
        :loading="loading",
        color="secondary",
        @click="submit"
      ) save
    v-divider
    simple-table
      thead
        tr
          th Name
          th Unit
          th Quantity
          th.text-right Cost
          th 
      tbody
        tr(
          v-for="(item, index) in items",
          :key="index",
          draggable="true",
          @dragstart="drag(index, $event)",
          @dragend="dragend($event)",
          @drop="drop(index, $event)",
          @dragover.prevent=""
        )
          th
            v-icon.mr-2(small) mdi-menu
            span {{ item.name }}
          td {{ item.uom }}
          td(style="width: 100px")
            v-text-field(
              hide-details,
              dense,
              v-model="recipe.ingredients[index].quantity",
              type="number",
              min=0,
              step=0.1
            )
          td.text-right {{ item.total | currency }}
          td.text-right
            v-btn(icon, small, @click="removeAt(index)")
              v-icon(small, color="error") mdi-delete
    v-card-text
      .subtitle-2.my-2 Select ingredient below.
      v-text-field(
        v-model="searchText",
        label="Search",
        solo,
        hide-details,
        clearable
      )
    Choices(@addItem="add", :searchText="searchText")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Choices from "./Choices";

export default {
  components: { Choices },
  data() {
    return {
      dish: null,
      recipe: null,
      dialog: false,
      loading: false,
      searchText: "",
    };
  },
  computed: {
    ...mapGetters(["biz", "menuIngredients"]),
    items() {
      return this.recipe.infredients.map((o) => {
        const found = this.menuIngredients?.find((i) => i._id == o.id);
        if (found) {
          return {
            name: found.name,
            uom: found.uom,
            price: found.price,
            quantity: o.quantity,
            total: found.price * o.quantity,
          };
        } else {
          return {
            name: "",
            uom: "",
            price: 0,
            quantity: o.quantity,
            total: 0,
          };
        }
      });
    },
  },
  mounted() {
    this.loadIngredients();
  },
  methods: {
    ...mapActions(["setMenuIngredients"]),
    async open(data) {
      this.recipe = null;
      this.dish = JSON.parse(JSON.stringify(data));
      if (!this.dish || !this.biz) return;
      const params = { criteria: { dish: this.dish } };
      try {
        this.recipe = await this.$api.recipe.retrieve(params);
      } catch (e) {
        //
      }
      if (!this.recipe) {
        this.recipe = { dish: this.dish._id, ingredients: [] };
      }
      this.searchText = "";
      this.dialog = true;
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: function (index) {
      if (index == this.draggingIndex) return;
      if (!this.recipe.ingredients) return;
      if (index < 0 || index >= this.recipe.ingredients.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.recipe.ingredients.length
      )
        return;
      let ingredients = JSON.parse(JSON.stringify(this.recipe.ingredients));
      let tmp = JSON.parse(JSON.stringify(ingredients[this.draggingIndex]));
      ingredients.splice(this.draggingIndex, 1);
      ingredients.splice(index, 0, tmp);
      this.recipe.ingredients = ingredients;
    },
    add(item) {
      const some = this.recipe.ingredients.some((o) => o.id == item._id);
      if (some) return;
      const data = { id: item._id, quantity: 1 };
      this.recipe.ingredients.push(data);
    },
    removeAt(index) {
      this.recipe.ingredients.splice(index, 1);
    },
    async submit() {
      if (!this.recipe) return;
      this.loading = true;
      try {
        if (this.recipe._id) {
          const action = {
            $set: {
              dish: this.recipe.dish,
              ingredients: this.recipe.ingredients,
            },
          };
          this.recipe = await this.$api.recipe.put(this.recipe._id, action);
        } else {
          this.recipe = await this.$api.recipe.create(this.recipe);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to save");
      }
      this.loading = false;
    },
    async loadIngredients() {
      if (!this.biz) return;
      const params = { criteria: { biz: this.biz._id } };
      const data = await this.$api.ingredient.list(params);
      this.setMenuIngredients(data);
    },
  },
};
</script>
