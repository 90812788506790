<template lang="pug">
.list-group
  BizCell(
    v-for="(item, index) in items",
    :key="index",
    :item="item",
    @selected="$emit('selected')"
  )
</template>

<script>
import BizCell from "./BizCell";

export default {
  components: { BizCell },
  props: {
    items: { type: Array, required: true },
  },
};
</script>

<style lang="sass" scoped>
.list-group
  display: flex
  flex-direction: column
  gap: 2px
</style>
