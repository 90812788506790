<template lang="pug">
.s-vstack
  .d-flex.flex-row.align-center
    h2 Options
    v-spacer
    IconBtn(
      @click="edit()",
      color="secondary",
      title="Option",
      icon="mdi-plus",
      :outlined="false"
    )
  p Options are similar to modifiers that can be attached to a reservation choice.
  v-simple-table
    thead
      tr
        th Name
        th Price
        th Description
    tbody
      tr(
        v-for="(option, index) in rsvtoptions",
        :key="index",
        @click="edit(option)",
        role="button"
      )
        td {{ option.name }}
        td {{ option.price | currency }}
        td {{ option.description }}
  Form(ref="form")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  computed: {
    ...mapGetters(["biz", "rsvtoptions"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.biz?._id) return;
      const criteria = { biz: this.biz._id };
      try {
        const result = await this.$api.b.rsvtoption.list({ criteria });
        this.$store.dispatch("setRsvtOptions", result);
      } catch (e) {
        this.$store.dispatch("setRsvtOptions", []);
        this.$toast.error(e.response?.data || e.message);
      }
    },
    edit(option) {
      this.$refs.form.open(option);
    },
  },
};
</script>