<template lang="pug">
simple-table
  thead
    tr
      th Platform
      th Price Strategy
      th
  tbody
    tr(
      v-for="(item, index) in items",
      :key="index",
      @click="edit(item)",
      role="button"
    )
      th {{ item.platform | platform }}
      td {{ getStrategy(item) }}
      td.text-right
        DeleteOne(:item="item")
</template>

<script>
import DeleteOne from "./DeleteOne";

export default {
  components: { DeleteOne },
  filters: {
    platform(val) {
      if (val == "third_party") return "Third Parties";
      return "Selflane";
    },
  },
  props: {
    items: { type: Array, default: () => [] },
  },
  methods: {
    getStrategy(item) {
      if (item.use_percentage) {
        return `+${item.percentage}%`;
      }
      return `+$${item.amount}`;
    },
    edit(item) {
      this.$emit("edit", item);
    },
    handleRemove(item) {
      this.$emit("remove", item);
    },
  },
};
</script>