<template lang="pug">
tr
  th {{ title }}
  td.text-right
    span(v-if="data.status") {{ data.beforehand | leadtime }}
    v-icon(v-else, color="red lighten-2") mdi-cancel
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    data: { type: Object, required: true },
  },
};
</script>
