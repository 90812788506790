import DishPrintItems from "@/components/basic/DishPrintItems.json";

const state = {
  printItems: JSON.parse(JSON.stringify(DishPrintItems)),
  pos: null,
  posEditable: null
}

const getters = {
  printItems: state => state.printItems,
  pos: state => state.pos,
  posEditable: state => state.posEditable
}

const actions = {
  setPOS: ({ commit }, data) => { commit('setPOS', data) },
  resetEditable: ({ commit }) => { commit('resetEditable') },
}

const mutations = {
  setPOS(state, data) {
    if (!data) {
      state.pos = null
      return
    }
    if (!data.roles) data.roles = []
    state.pos = data
    state.posEditable = JSON.parse(JSON.stringify(data))
    state.printItems = JSON.parse(JSON.stringify(DishPrintItems))
    if (data?.epson) {
      state.printItems = state.printItems.map(o => {
        if (o.value == 2) o.title = data.epson.kitchen2 || o.title
        if (o.value == 3) o.title = data.epson.kitchen3 || o.title
        if (o.value == 14) o.title = data.epson.kitchen4 || o.title
        return o
      })
    }
  },
  resetEditable(state) {
    state.posEditable = JSON.parse(JSON.stringify(state.pos))
  },
}

export default { state, getters, actions, mutations }