<template lang="pug">
div
  v-list-item
    v-list-item-content
      v-list-item-title {{ item.name }}
    v-list-item-action
      v-btn(:loading="loading", color="secondary", @click="confirmUpgrade") Upgrade
  v-divider
</template>

<script>
export default {
  props: {
    item: { type: Object, default: null },
    bizservice: { type: Object, default: null },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    confirmUpgrade() {
      confirm(`Are you sure you want to upgrade to ${this.item?.name} plan?`) &&
        this.handleUpgrade();
    },
    async handleUpgrade() {
      if (!this.bizservice?._id) return;
      this.loading = true;
      const params = {
        bizId: this.bizservice.biz,
        plan_level: this.item.level,
      };
      try {
        const result = await this.$api.service.account.custom(
          "upgrade",
          params
        );
        this.$store.dispatch("setBizService", result);
        this.$toast.success("Plan upgraded");
        this.$emit("upgraded");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>