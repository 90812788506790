<template lang="pug">
v-md-preview(:text="text")
</template>

<script>
import moment from "moment-timezone";
export default {
  props: {
    title: { type: String, default: null },
    time: { type: Number, default: 0 }, //
    content: { type: String, default: null },
  },
  computed: {
    text() {
      let text = "";
      if (this.title) text += "## " + this.title + "\n";
      if (this.time) {
        text += "*" + moment(this.time).format("M/D/YYYY") + "*\n\n";
      }
      if (this.content) text += this.content;
      return text;
    },
  },
};
</script>