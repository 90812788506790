<template lang="pug">
Page(title="Expenses", :doc="doc")
  template(v-slot:header)
    IconBtn(@click="create", icon="mdi-plus", title="Add")
  .d-flex.flex-row.justify-end.mb-3
    MonthSelector(@select="load")
  Table
  Form
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import Form from "./Form";
import Table from "./Table";

export default {
  components: { Form, Table },
  data() {
    return {
      begin: 0,
      end: 0,
      doc: "https://docs.selflane.com/biz/performance/expense.html",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setExpenses"]),
    async load(month) {
      if (month) {
        this.begin = month.range.begin;
        this.end = month.range.end;
      }
      if (!this.biz?._id || !this.begin || !this.end) return;
      const criteria = {
        biz: this.biz._id,
        date: { $gte: this.begin, $lt: this.end },
      };
      const result = await this.$api.expense.entry.list({ criteria });
      this.setExpenses(result);
    },
    create() {
      EventBus.$emit("create-expense");
    },
  },
};
</script>
