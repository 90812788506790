<template lang="pug">
v-menu(offset-y)
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on", title="Bulk Edit", icon="mdi-dots-horizontal")
  v-list.py-0(dense)
    EditBulkPrice(:courseId="courseId")
    EditBulkMenus(:courseId="courseId")
    EditBulkCourse(:courseId="courseId")
    EditBulkPrints(:courseId="courseId")
    EditBulkPrintingGroup(:courseId="courseId")
    EditBulk3rdParty(:courseId="courseId")
    EditBulkUpcharge(:courseId="courseId")
</template>

<script>
import EditBulkPrice from "./EditBulkPrice";
import EditBulkMenus from "./EditBulkMenus";
import EditBulkCourse from "./EditBulkCourse";
import EditBulkPrints from "./EditBulkPrints";
import EditBulkPrintingGroup from "./EditBulkPrintingGroup";
import EditBulk3rdParty from "./EditBulk3rdParty";
import EditBulkUpcharge from "./EditBulkUpcharge";

export default {
  components: {
    EditBulkPrice,
    EditBulkMenus,
    EditBulkCourse,
    EditBulkPrints,
    EditBulkPrintingGroup,
    EditBulk3rdParty,
    EditBulkUpcharge,
  },
  props: {
    courseId: { type: String, default: null },
  },
};
</script>
