const state = {
  membertier: null,
}

const getters = {
  membertier: state => state.membertier,
}

const actions = {
  setMemberTier: ({
    commit
  }, membertier) => {
    commit('setMemberTier', membertier)
  }
}

const mutations = {
  setMemberTier(state, membertier) {
    state.membertier = membertier
  }
}

export default { state, getters, actions, mutations }