<template lang="pug">
PartnerPage(:greeting="false", title="Settings", :doc="doc")
  v-col(align="center")
    ProfilePicture(:organization="salesOrganization")
  v-card.my-5(outlined)
    v-toolbar(flat, dense)
      .subtitle-2 Organization
      v-spacer
      v-btn(icon, small, color="secondary", @click="open")
        v-icon(small) mdi-pencil
    v-list(dense)
      v-list-item
        v-list-item-content
          v-list-item-title Name
          v-list-item-subtitle {{ salesOrganization.name }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Phone
          v-list-item-subtitle {{ salesOrganization.phone | phone }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Address
          v-list-item-subtitle {{ salesOrganization.address | address }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Email
          v-list-item-subtitle {{ salesOrganization.email }}
  Form(ref="form")
</template>

<script>
import ProfilePicture from "/libs/components/SalesForce/Organization/ProfilePicture";
import Form from "/libs/components/SalesForce/Organization/Form";
import { mapGetters } from "vuex";

export default {
  components: { ProfilePicture, Form },
  data() {
    return {
      doc: "https://docs.selflane.com/partner/admin/settings/",
    };
  },
  computed: {
    ...mapGetters(["salesOrganization"]),
  },
  methods: {
    open() {
      this.$refs.form.open(this.salesOrganization);
    },
  },
};
</script>