<template lang="pug">
div
  v-row(wrap)
    v-col(cols="12", md="4")
      StandardChart(:input="totalData")
    v-col(cols="12", md="4")
      StandardChart(:input="countData")
    v-col(cols="12", md="4")
      StandardChart(:input="impressionData")
  Table.my-5(
    :report="result",
    title="Weekly Online Sales Summary",
    :year="year"
  )
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import _ from "underscore";
import { mapGetters } from "vuex";
import Table from "./Table/index";

export default {
  components: { Table },
  props: ["year"],
  data() {
    return {
      result: [],
      impressionResult: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    totalData() {
      if (!this.result) return null;
      const weeks = _.map(this.result, (o) =>
        moment()
          .year(o.year)
          .week(o.week + 1)
          .endOf("week")
          .format("MMM D")
      );
      return {
        title: this.year + " Weekly Sales",
        labels: weeks,
        values: this.result.map((o) => Math.round(o.total * 100) / 100),
        isCurrency: true,
      };
    },
    countData() {
      if (!this.result) return null;
      const weeks = _.map(this.result, (o) =>
        moment()
          .year(o.year)
          .week(o.week + 1)
          .endOf("week")
          .format("MMM D")
      );
      return {
        title: "Weekly #Sales",
        labels: weeks,
        values: this.result.map((o) => o.count),
        isCurrency: false,
      };
    },
    impressionData() {
      if (!this.impressionResult) return null;
      const weeks = _.map(this.impressionResult, (o) =>
        moment()
          .year(o.year)
          .week(o.week + 1)
          .endOf("week")
          .format("MMM D")
      );
      return {
        title: "Weekly Impression",
        labels: weeks,
        values: this.impressionResult.map((o) => o.count),
        isCurrency: false,
      };
    },
  },
  watch: {
    year() {
      this.load();
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    load() {
      if (!this.biz) return;
      const params = {
        bizId: this.biz._id,
        year: this.year,
      };
      this.loadSales(params);
      this.loadImpression(params);
    },
    async loadSales(params) {
      const { data } = await this.axios.post("/orders/online/weekly", params);
      this.result = _.chain(data)
        .sortBy((o) => o.week)
        .sortBy((o) => o.year)
        .value();
    },
    async loadImpression(params) {
      const { data } = await this.axios.post("/bizs/impression/weekly", params);
      this.impressionResult = _.chain(data)
        .sortBy((o) => o.week)
        .sortBy((o) => o.year)
        .value();
    },
  },
};
</script>
