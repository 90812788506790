<template lang="pug">
.text-center
  .my-5 Share on Facebook. Boost Online Sales
  vue-goodshare-facebook(:page_url="url", title_social="Facebook", has_icon)
  .my-5
    v-btn.text-capitalize(to="/share") Learn more about share
</template>

<script>
import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import { mapGetters } from "vuex";

export default {
  name: "BizFBShare",
  components: { VueGoodshareFacebook },
  computed: {
    ...mapGetters(["biz"]),
    url() {
      if (!this.biz) return "";
      return "https://selflane.com/bizs/" + this.biz.url;
    },
  },
};
</script>

<style lang="sass" scoped>
a
  text-decoration: none
</style>
