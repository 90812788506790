<template lang="pug">
v-dialog(v-model="dialog", width="450")
  template(v-slot:activator="{ on }")
    v-btn(
      v-on="on",
      @click="open()",
      :disabled="!isValid",
      color="secondary",
      outlined,
      small,
      :loading="loading"
    )
      span.text-notransform Partial Refund
  v-card
    v-card-title
    v-card-text
      OrderHeader(:order="order")
      v-form(@submit.prevent="submit()")
        v-text-field(
          v-model="refundAmount",
          type="number",
          label="Refund Amount",
          prefix="$",
          hint="Uncheck (before tax) if refund exact amount",
          persistent-hint
        )
        v-checkbox(v-model="calculate_tax", color="secondary")
          template(v-slot:label) Before Tax
        v-text-field(v-model="reason", label="Reason")
        v-checkbox(v-model="agreed", color="secondary")
          template(v-slot:label) {{ agreement }}
        v-btn(
          type="submit",
          :disabled="!refundAmount || !agreed",
          block,
          color="secondary",
          :loading="loading"
        ) Submit
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "PartialRefund",
  props: ["order"],
  data: () => ({
    dialog: false,
    loading: false,
    refundAmount: 0,
    calculate_tax: true,
    charge_id: null,
    payment_intent_id: null,
    reason: "",
    agreed: false,
    agreement:
      "You agreed to refund the above amount to the customer. We will issue the refund to the customer's original payment right away. This action is not redoable.",
  }),
  computed: {
    isValid() {
      const found = this.order.payment.charges.find(
        (o) => o.method == "stripe"
      );
      return (
        this.order &&
        (this.order.orderer.type == 0 || found) &&
        this.order.payment.total > 2 &&
        this.order.status >= 0
      );
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    open() {
      this.refundAmount = 0;
      this.calculate_tax = true;
      this.reason = "";
      this.charge_id = null;
      this.payment_intent_id = null;
      this.agreed = false;
    },
    async submit() {
      if (!this.order) return;
      if (!this.refundAmount) return;
      if (this.refundAmount > this.order.payment.total) {
        alert("Maximum is " + this.order.payment.total);
        return;
      }
      this.loading = true;
      const params = this.getParams();
      try {
        const res = await this.axios.post("/orders/partialRefund", params);
        this.updateOrder(res.data);
        this.$toast.success("Refund success.");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    getParams() {
      const params = {
        orderId: this.order._id,
        amount: this.refundAmount,
        calculate_tax: this.calculate_tax,
        reason: this.reason,
      };
      if (this.order.payment?.charge?.id) {
        params.charge_id = this.order.payment.charge.id;
      } else {
        const found = this.order.payment.charges?.find(
          (o) => o.method == "stripe"
        );
        if (found) params.payment_intent_id = found.transId;
      }
      return params;
    },
  },
};
</script>
