import Main from './index.vue'
import Menus from './Menus/index.vue'
import Courses from './Courses/index.vue'
import Dishes from './Dishes/index.vue'
import Modifiers from './Modifiers/index.vue'
import MatrixModifier from './MatrixModifier/index.vue'
import Ingredients from './Ingredients/index.vue'

export default [
  {
    path: '/menu', component: Main, meta: { auth: true },
    children: [
      { path: '', redirect: 'menus' },
      { path: 'menus', component: Menus },
      { path: 'courses', component: Courses },
      { path: 'dishes', component: Dishes },
      { path: 'modifiers', component: Modifiers },
      { path: 'matrix', component: MatrixModifier },
      { path: 'ingredients', component: Ingredients }
    ]
  }
]