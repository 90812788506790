<template lang="pug">
v-row(wrap, v-if="biz")
  v-col(cols="12", sm="6", xl="3")
    ThirtyDay(:bizId="bizId")
  v-col(cols="12", sm="6", xl="3")
    WeeklyData(
      title="Weekly Sales",
      :bizId="bizId",
      url="/orders/compareWeekly",
      @loaded="setSales"
    )
  v-col(cols="12", sm="6", xl="3")
    WeeklyData(
      title="Weekly Payroll",
      :bizId="bizId",
      url="/punchcards/compareWeekly",
      @loaded="setPayroll"
    )
  v-col(cols="12", sm="6", xl="3")
    WeekChart(
      title="Weekly Payroll %",
      :weekData="ratioData",
      :isCurrency="false",
      postTick="%"
    )
</template>

<script>
import { mapGetters } from "vuex";
import WeekChart from "./WeekChart";
import WeeklyData from "./WeeklyData";
import ThirtyDay from "/libs/components/Order/Performance/ThirtyDay";

export default {
  components: { ThirtyDay, WeeklyData, WeekChart },
  data() {
    return {
      weeklySales: [],
      weeklyPayroll: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    bizId() {
      return this.biz?._id;
    },
    ratioData() {
      if (this.weeklyPayroll?.length != 2 || this.weeklySales?.length != 2)
        return [];
      const data = [
        this.getRatio(this.weeklyPayroll[0], this.weeklySales[0]),
        this.getRatio(this.weeklyPayroll[1], this.weeklySales[1]),
      ];
      return data;
    },
  },
  methods: {
    getRatio(list1, list2) {
      if (list1.length !== list2.length) return [];
      return list1.map((o, i) => {
        if (o > 0 && list2[i] > 0) {
          return Math.round((o / list2[i]) * 1000) / 10;
        } else return 0;
      });
    },
    setSales(data) {
      this.weeklySales = data;
    },
    setPayroll(data) {
      this.weeklyPayroll = data;
    },
  },
};
</script>
