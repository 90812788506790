<template lang="pug">
div
  DaySelector(@select="load")
  DailySummary.my-5(:groups="groups", :loading="loading", :biz="biz")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import DailySummary from "/libs/components/Transaction/DailySummary";

export default {
  components: { DailySummary },
  data: () => ({
    selectedDate: null,
    loading: false,
    groups: [],
  }),
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load(date) {
      if (!this.biz) return;
      if (date) this.selectedDate = date;
      this.loading = true;
      const params = {
        bizId: this.biz._id,
        date: moment(this.selectedDate).format("YYYY-MM-DD"),
      };
      const { data } = await this.axios.post("/paxTrans/dailysummary", params);
      this.groups = data;
      this.loading = false;
    },
  },
};
</script>
