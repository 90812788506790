<script>
/// Load staff of a business
/// A base component to to be extended

import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async loadStaff() {
      if (!this.biz?._id) return;
      let params = { criteria: { bizs: this.biz._id } };
      if (this.biz.enterprise) {
        params = { criteria: { enterprise: this.biz.enterprise } };
      }
      const result = await this.$api.hr.person.list(params);
      this.$store.dispatch("hr/setPersons", result);
    },
    async load() {
      await this.loadStaff();
    },
  },
};
</script>
