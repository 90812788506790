<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    IconBtn(title="Delete", icon="mdi-delete", color="error", v-on="on")
  v-card
    v-card-title Delete Member
    v-card-text
      | Do you want to remove this member? It would remove all earning and redeeming history of this member.
    v-card-actions
      v-spacer
      v-btn(@click="dialog = false", text) Cancel
      v-btn(@click="submit()", color="error", text, :loading="loading") Delete
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return { dialog: false, loading: false };
  },
  computed: { ...mapGetters(["member"]) },
  methods: {
    ...mapActions(["removeMember", "setMember"]),
    async submit() {
      if (!this.member) return;
      this.loading = true;
      const params = { id: this.member._id };
      try {
        const member = await this.$api.member.delete(params);
        this.removeMember(member);
        this.setMember();
        this.dialog = false;
        this.$emit("deleted");
        this.$toast.success("Member deleted");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>