<template lang="pug">
.d-flex.flex-row.align-center
  v-select(
    v-model="server",
    :items="items",
    :label="label",
    :disabled="disabled",
    :loading="loading"
  )
  IconBtn(
    @click.stop="load()",
    icon="mdi-refresh",
    title="",
    :loading="loading"
  )
</template>


<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  props: {
    value: { type: String, default: null }, // server._id
    disabled: { type: Boolean, default: false },
    label: { type: String, default: "Server" },
  },
  data() {
    return {
      server: this.value,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("hr", ["persons"]),
    ...mapGetters(["biz"]),
    items() {
      return this.persons
        .filter((i) => i.bizs?.includes(this.biz._id))
        .map((i) => {
          let items = [];
          if (i.first_name) items.push(i.first_name);
          if (i.last_name) items.push(i.last_name);
          if (!items.length) items.push(i.fullname);
          const text = items.join(" ");
          return { text, value: i._id };
        })
        .toSorted((a, b) => a.text.localeCompare(b.text));
    },
  },
  watch: {
    value(v) {
      this.server = v;
    },
    server(v) {
      this.$emit("input", v);
    },
  },
  mounted() {
    if (!this.items?.length) this.load();
    EventBus.$on("switch-biz", this.load);
  },
  beforeDestroy() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      const bizId = this.biz?._id;
      if (!bizId) return;
      this.loading = true;
      try {
        const params = { criteria: { biz: bizId } };
        const result = await this.$api.hr.person.list(params);
        this.$store.dispatch("hr/setPersons", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load persons");
      }
      this.loading = false;
    },
  },
};
</script>