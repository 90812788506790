<template lang="pug">
div
  .d-flex.flex-row.align-center.mb-3
    .subtitle-2 Ingredients
    v-spacer
    IconBtn(@click="create()", title="Add", icon="mdi-plus")
  div(style="max-width: 350px")
    v-text-field(label="Search", v-model="searchText", solo)
  v-data-table(
    :headers="headers",
    :items="menuIngredients",
    :search="searchText",
    @click:row="edit",
    role="button",
    sort-by="name",
    dense
  )
    template(v-slot:item.price="{ item }")
      span {{ item.price | currency }}
    template(v-slot:item.action="{ item }")
      v-btn(icon, small, color="error", @click.stop="handleRemove(item)")
        v-icon(small) mdi-delete
  EditForm(ref="form")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import EditForm from "./EditForm";

export default {
  components: { EditForm },
  data() {
    return {
      searchText: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "ID", value: "uid" },
        { text: "Unit of Measurement", value: "uom" },
        { text: "Price/Unit", value: "price", align: "end" },
        { text: "", value: "action", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz", "menuIngredients"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setMenuIngredients", "removeMenuIngredient"]),
    async load() {
      if (!this.biz) return;
      const params = { criteria: { biz: this.biz._id } };
      const data = await this.$api.ingredient.list(params);
      this.setMenuIngredients(data);
    },
    create() {
      this.$refs.form.open();
    },
    edit(item) {
      this.$refs.form.open(item);
    },
    handleRemove(item) {
      confirm("Do you really want to delete this ingredient?") &&
        this.remove(item);
    },
    async remove(item) {
      const params = { id: item._id };
      const data = await this.$api.ingredient.delete(params);
      this.removeMenuIngredient(data);
    },
  },
};
</script>