<template lang="pug">
.overall-container
  Cell(title="Sold")
    span {{ result?.count || 0 }}
  v-divider(vertical)
  Cell(title="Revenue")
    span {{ result?.amount | currency }}
  v-divider(vertical)
  Cell(title="Remaining Balance")
    span {{ result?.balance | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Cell from "./Cell";

export default {
  components: { Cell },
  data() {
    return { result: null };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id, begin: 0, end: Date.now() };
      this.result = await this.$api.giftcard
        .custom("reports/overall", params)
        .catch(() => null);
    },
  },
};
</script>


<style lang="sass" scoped>
.overall-container
  display: flex
  align-items: center
  background: white
  border-radius: 0.5rem
</style>