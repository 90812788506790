<template lang="pug">
.mx-3
  table
    tbody(v-if="showWage")
      ListItem(title="CC Tip", :value="server.tip", :secondary="true")
      ListItem(title="Cash Tip", :value="server.cashTip")
      ListItem(title="Total Tip", :value="total")
    tbody
      ListItem(title="Net Sales", :value="server.sales", :detail="tip_pct")
      ListItem(
        v-for="(item, index) in server.tax_items",
        :key="index",
        :title="tax_title(item)",
        :value="item.value",
        :secondary="true"
      )
</template>

<script>
import ListItem from "./ListItem";
export default {
  components: { ListItem },
  props: ["server", "showWage"],
  computed: {
    total() {
      return this.server.totalPay + this.server.tip + this.server.cashTip;
    },
    tip_pct() {
      if (this.server.sales == 0) return "";
      const pct = Math.round((this.total / this.server.sales) * 100);
      if (pct) return `(${pct}%)`;
      return "";
    },
  },
  methods: {
    tax_title(item) {
      let text = item.name + " " + item.percentage + "%";
      if (item.type == 1) text += " (Inclu.)";
      return text;
    },
  },
};
</script>

<style lang="sass" scoped>
table
  width: 100%
  font-size: 13px
</style>