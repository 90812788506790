<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";

export default {
  props: ["items"],
  methods: {
    download() {
      if (!this.items?.length) {
        this.$toast.error("No tickets to download");
        return;
      }
      const csv = new CSV();
      csv.addRow(["Ticket #", "Item", "Name", "Phone", "Email"]);
      this.items?.forEach((o) => {
        csv.addRow([o.ticket_number, o.item_name, o.name, o.phone, o.email]);
      });
      csv.save("tickets");
    },
  },
};
</script>
