<template lang="pug">
v-dialog(v-model="dialog", width="450")
  template(v-slot:activator="{ on }")
    v-list-item(outlined, small, color="secondary", v-on="on")
      span Edit Upcharge
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Edit All Items to a Same Upcharge
    v-divider
    v-form(@submit.prevent="save")
      v-card-text
        Note(:courseId="courseId")
        PriceField(v-model.number="onlineUpcharge", label="Online Upcharge")
        PriceField(
          v-model.number="thirdPartyUpcharge",
          label="3rd Party Upcharge"
        )
        v-btn(type="submit", block, color="secondary", :loading="loading") Save
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Note from "./Note";

export default {
  components: { Note },
  props: {
    courseId: { type: String, default: null },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      onlineUpcharge: 0,
      thirdPartyUpcharge: 0,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    async save() {
      if (!this.biz) return;
      this.loading = true;
      _.each(this.biz.dishes, (o) => {
        if (o.course == this.courseId) {
          o.onlineUpcharge = this.onlineUpcharge;
          o.thirdPartyUpcharge = this.thirdPartyUpcharge;
        }
      });
      try {
        const action = { $set: { dishes: this.biz.dishes } };
        const data = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", data);
        this.dialog = false;
        this.onlineUpcharge = 0;
        this.thirdPartyUpcharge = 0;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
