<template lang="pug">
div(v-if="!isNew")
  v-divider
  v-btn(text, :color="color", block, @click="submit", :loading="loading") {{ title }}
  v-divider
</template>

<script>
export default {
  props: ["server"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isNew() {
      return !this.server?._id;
    },
    color() {
      return this.server.status ? "error" : "success";
    },
    title() {
      return this.server.status ? "Deactivate" : "Activate";
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      const action = { $set: { status: !this.server.status } };
      try {
        const result = await this.$api.hr.person.put(this.server._id, action);
        this.$toast.success(`${this.title}d`);
        this.$store.dispatch("hr/updatePerson", result);
        this.$emit("done", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
