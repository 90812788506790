<template lang="pug">
v-dialog(v-model="dialog", max-width="400")
  v-card(v-if="member")
    v-card-title Edit Email
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(label="Email", v-model="email")
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      email: "",
      loading: false,
    };
  },
  computed: { ...mapGetters(["member"]) },
  mounted() {
    EventBus.$on("edit-member-email", this.edit);
  },
  destroyed() {
    EventBus.$off("edit-member-email", this.edit);
  },
  methods: {
    ...mapActions(["updateMember", "setMember"]),
    edit() {
      this.email = this.member?.email;
      this.dialog = true;
    },
    async submit() {
      if (this.member?._id == undefined) return;
      if (!this.email) return;
      if (this.email == this.member.email) {
        this.dialog = false;
        return;
      }
      this.loading = true;
      const params = { id: this.member._id, email: this.email };
      try {
        const { data } = await this.axios.post("/member/edit/email", params);
        this.updateMember(data);
        this.setMember(data);
        this.dialog = false;
        this.$toast.success("Saved");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>