<template lang="pug">
div
  TypeSelector(v-model="orderType", :items="type_items")
  v-card.my-5(flat)
    v-card-text
      v-switch(
        label="Select Multiple",
        v-model="showSelect",
        hide-details,
        color="secondary"
      )
      IconBtn.my-5(
        v-if="showSelect",
        @click="cancelMultiple()",
        title="Cancel Multiple",
        icon="mdi-delete",
        color="error"
      )
      v-text-field(
        v-model="searchText",
        label="Search order #, server",
        single-line,
        hide-details,
        clearable
      )
    v-data-table(
      :search="searchText",
      v-model="selected",
      :show-select="showSelect",
      item-key="_id",
      :headers="headers",
      :items="items",
      :loading="loading",
      dense,
      @click:row="showDetail",
      role="button",
      mobile-breakpoint="0",
      :footer-props="{ itemsPerPageOptions: [50, 100, -1] }"
    )
      template(v-slot:item.time="{ item }")
        span.nowrap {{ item.needed | time }}
      template(v-slot:item.tax="{ item }")
        span {{ item.payment.tax | currency }}
      template(v-slot:item.payment.total="{ item }")
        span {{ item.payment.total | currency }}
        v-icon.ml-1(small, color="success") {{ method(item.payment) }}
      template(v-slot:item.status="{ item }")
        span(:class="{ 'red--text': item.status === -1 }") {{ item.status | orderStatus }}
      template(v-slot:item.device.id="{ item }")
        span {{ item.device?.id | truncate(8) }}
  CancelMultiple
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapGetters } from "vuex";
import CancelMultiple from "./CancelMultiple";
import TypeSelector from "./TypeSelector";

export default {
  name: "OrderTable",
  components: {
    TypeSelector,
    CancelMultiple,
  },
  props: ["loading"],
  data: () => ({
    searchText: "",
    orderType: 0,
    selected: [],
    showSelect: false,
  }),
  computed: {
    ...mapGetters(["orders"]),
    headers() {
      let items = [
        { text: "#", value: "orderNumber" },
        { text: "Time", value: "time", filterable: false },
        { text: "Server", value: "orderer.name" },
        { text: "Tax", value: "tax", filterable: false },
        { text: "Fee", value: "payment.fee" },
        { text: "Gift Sold", value: "payment.gift" },
        { text: "Total", value: "payment.total" },
        { text: "Status", value: "status", filterable: false, align: "end" },
        { text: "Device", value: "device.id", filterable: true, align: "end" },
      ];
      if (!this.hasFee) {
        items = items.filter((item) => item.value != "payment.fee");
      }
      if (!this.hasGift) {
        items = items.filter((item) => item.value != "payment.gift");
      }
      return items;
    },
    all() {
      return this.orders;
    },
    hasFee() {
      return this.orders.some((order) => order.payment.fee > 0);
    },
    hasGift() {
      return this.orders.some((order) => order.payment.gift > 0);
    },
    online() {
      return this.orders.filter((order) => order.orderer?.type != 1);
    },
    cash() {
      return this.orders.filter((order) => {
        return (
          order.status >= 0 &&
          order.payment.charges?.some((charge) => charge.method == "cash")
        );
      });
    },
    zero_tax() {
      return this.orders.filter(
        (order) => order.payment.total > 0 && order.payment.tax == 0
      );
    },
    underpaid() {
      return this.groups.find((group) => group.name == "underpaid");
    },
    overpaid() {
      return this.groups.find((group) => group.name == "overpaid");
    },
    paid() {
      return this.groups.find((group) => group.name == "paid");
    },
    /// orders grouped by payment status
    groups() {
      return _.chain(this.orders)
        .groupBy((order) => {
          if (order.status < 0) return "paid";
          let paid = 0;
          order.payment.charges?.forEach((charge) => {
            paid += charge.amount;
          });
          order.splitChecks?.forEach((check) => {
            check.payment.charges?.forEach((charge) => {
              paid += charge.amount;
            });
          });
          paid += order.payment.charge.amount;
          if (paid - order.payment.total > 1) {
            return "overpaid";
          } else if (paid - order.payment.total < -1) {
            return "underpaid";
          } else {
            return "paid";
          }
        })
        .map((value, key) => {
          return { name: key, orders: value };
        })
        .value();
    },
    type_items() {
      let data = [{ value: 0, text: "All", count: this.all.length }];
      if (this.online.length) {
        data.push({ value: 1, text: "Online", count: this.online.length });
      }
      if (this.cash.length) {
        data.push({ value: 2, text: "Cash", count: this.cash.length });
      }
      if (this.zero_tax.length) {
        data.push({ value: 3, text: "Zero Tax", count: this.zero_tax.length });
      }
      if (this.underpaid) {
        data.push({
          value: 4,
          text: "Not Paid",
          count: this.underpaid.orders.length,
        });
      }
      if (this.overpaid) {
        data.push({
          value: 5,
          text: "Overpaid",
          count: this.overpaid.orders.length,
        });
      }
      return data;
    },
    items() {
      let data = [];
      if (this.orderType == 0) data = this.all;
      else if (this.orderType == 1) data = this.online;
      else if (this.orderType == 2) data = this.cash;
      else if (this.orderType == 3) data = this.zero_tax;
      else if (this.orderType == 4) data = this.underpaid.orders;
      else if (this.orderType == 5) data = this.overpaid.orders;
      else data = this.all;
      return _.sortBy(data, "created");
    },
    totalSelected() {
      return this.selected.reduce((a, b) => a + b.payment.total, 0);
    },
  },
  methods: {
    method(payment) {
      if (!payment) return "";
      if (payment.charges.some((charge) => charge.method == "cash")) {
        return "fa fa-money-bill";
      }
    },
    showDetail(item) {
      if (item && item._id) {
        EventBus.$emit("show-order-detail", item._id);
      }
    },
    cancelMultiple() {
      if (!this.selected || !this.selected.length) {
        return this.$toast.info("Please select orders to cancel.");
      }
      EventBus.$emit("cancel-multiple-orders", this.selected);
    },
  },
};
</script>
