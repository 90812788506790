<template lang="pug">
div
  v-card(v-if="hasDelivery", flat)
    v-toolbar(flat)
      .subtitle-2.mr-5 Tiered Delivery Discounts
      v-spacer
      IconBtn(@click="edit()")
    simple-table
      tbody
        tr(v-for="(item, index) in biz.delivTiers", :key="index")
          th >{{ item.threshold | currency }}
          td.text-right {{ (price - item.deduction) | currency }}
  v-dialog(v-model="dialog", width="500")
    v-card
      v-toolbar(flat)
        .subtitle-2 Edit Tiered Discounts
      v-card-text
        v-form(@submit.prevent="save()")
          .text-center.mb-3
            v-btn-toggle(v-model="ntier", dense, color="secondary")
              v-btn(
                v-for="item in ntierItems",
                :key="item.title",
                :value="item.value"
              ) {{ item.title }}
          simple-table.mb-3
            thead
              tr
                td Menu Items Value
                td Delivery Discount
                td Delivery Cost
            tbody
              tr
                td
                  v-select(
                    v-model="firstThreshold",
                    :items="firstThresholdItems",
                    prefix="$",
                    label="Tier 1 Threshold",
                    hide-details
                  )
                td
                  v-select(
                    v-model="firstDeduction",
                    :items="firstDeductionItems",
                    prefix="$",
                    label="Tier 1 Discount",
                    hide-details
                  )
                td.text-right {{ (price - firstDeduction) | currency }}
              tr(v-if="ntier == 2")
                td
                  v-select(
                    v-model="secondThreshold",
                    :items="secondThresholdItems",
                    prefix="$",
                    label="Tier 2 Threshold",
                    hide-details
                  )
                td
                  v-select(
                    v-model="secondDeduction",
                    :items="secondDeductionItems",
                    prefix="$",
                    label="Tier 2 Discount",
                    hide-details
                  )
                td.text-right {{ (price - secondDeduction) | currency }}
          .error--text.mb-2(v-for="(item, eindex) in errors", :index="eindex") {{ item }}
          v-btn(color="secondary", type="submit", block) Save
          .body-2.mt-3 Delivery discount only applies when the selected menu item value (before tax, tip, etc.) exceeds the designed value.
          .body-2.mt-3 For instance, you can define a double tier
          .body-2.mt-3 1) when purchase over $25 menu items, the delivery fee is discounted by $2
          .body-2.mt-3 2) when purchase over $75 menu items, delivery is complimentary.
          .body-2.mt-3 For partner deliveries, the discount means restaurants cover a part of the cost of delivery.
          v-btn(
            color="error",
            @click="remove()",
            block,
            :loading="removing",
            outlined,
            small
          ) Remove Tiered Discounts
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      ntier: 1,
      ntierItems: [
        { title: "Single Tier", value: 1 },
        { title: "Double Tiers", value: 2 },
      ],
      firstThreshold: 0,
      firstDeduction: 0,
      secondThreshold: 0,
      secondDeduction: 0,
      errors: [],
      removing: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    price() {
      if (this.biz?.orderType?.delivery?.deliv) {
        return 2.99;
      }
      return this.biz.orderType.delivery.price;
    },
    firstThresholdItems() {
      return _.range(0, 205, 5);
    },
    firstDeductionItems() {
      const maxInt = Math.floor(this.price);
      let data = _.range(1, maxInt + 1, 1);
      data.push(this.price);
      return data;
    },
    secondThresholdItems() {
      return _.range(0, 105, 5);
    },
    secondDeductionItems() {
      const maxInt = Math.floor(this.price);
      let data = _.range(1, maxInt + 1, 1);
      data.push(this.price);
      return data;
    },
    hasDelivery() {
      return this.biz?.orderType?.delivery?.status == true;
    },
  },
  methods: {
    edit() {
      this.errors = [];
      if (!this.biz) return;
      if (!this.biz.delivTiers) {
        this.firstThreshold = 0;
        this.firstDeduction = 1;
        this.secondThreshold = 0;
        this.secondDeduction = 1;
        this.ntier = 2;
        return;
      }
      this.ntier = 1;
      if (this.biz.delivTiers.length > 0) {
        this.firstThreshold = this.biz.delivTiers[0].threshold;
        this.firstDeduction = this.biz.delivTiers[0].deduction;
      } else {
        this.firstThreshold = 0;
        this.firstDeduction = 1;
      }
      if (this.biz.delivTiers.length > 1) {
        this.ntier = 2;
        this.secondThreshold = this.biz.delivTiers[1].threshold;
        this.secondDeduction = this.biz.delivTiers[1].deduction;
      } else {
        this.secondThreshold = 0;
        this.secondDeduction = 1;
      }
      this.dialog = true;
    },
    async save() {
      this.errors = [];
      if (this.firstThreshold < 0) {
        this.errors.push("Tier 1 threshold should be non-negative");
      }
      if (this.firstDeduction < 1) {
        this.errors.push("Tier 1 deduction should be positive");
      }
      if (this.ntier == 2) {
        if (this.secondThreshold <= this.firstThreshold) {
          this.errors.push(
            "Tier 2 threshold should be greater than tier 1 threshold"
          );
        }
        if (this.secondDeduction <= this.firstDeduction) {
          this.errors.push(
            "Tier 2 deduction should be greater than tier 1 deduction"
          );
        }
      }
      if (this.errors?.length) return;
      let delivTiers = [];
      delivTiers.push({
        threshold: this.firstThreshold,
        deduction: this.firstDeduction,
      });
      if (this.ntier == 2) {
        delivTiers.push({
          threshold: this.secondThreshold,
          deduction: this.secondDeduction,
        });
      }
      const action = { $set: { delivTiers } };
      const data = await this.$api.biz.put(this.biz._id, action);
      this.$store.dispatch("setBiz", data);
      this.dialog = false;
    },
    async remove() {
      if (!this.biz) return;
      this.removing = true;
      const action = { $unset: { delivTiers: "" } };
      const data = await this.$api.biz.put(this.biz._id, action);
      this.$store.dispatch("setBiz", data);
      this.dialog = false;
      this.removing = false;
    },
  },
};
</script>