<template lang="pug">
.items-box
  img.icon-size(:src="item.icon", v-for="(item, index) in items", :key="index")
</template>

<script>
export default {
  data() {
    return {
      on: false,
      items: [
        {
          name: "United States",
          abbr: "US",
          icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2FCountries%2Funited-states.svg?alt=media&token=f4d33ec6-6482-490a-952c-ba86fece0d72",
        },
        {
          name: "Canada",
          abbr: "CA",
          icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2FCountries%2Fcanada.svg?alt=media&token=e6b06d3b-43cd-4210-b80c-27b1950f5110",
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.items-box
  display: flex
  flex-wrap: nowrap
  gap: 12px
</style>