<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import _ from "underscore";
import moment from "moment-timezone";
import CSV from "/libs/utils/CSV";
import { mapGetters } from "vuex";

export default {
  computed: { ...mapGetters(["members"]) },
  methods: {
    download() {
      const date_filter = this.$options.filters.date;
      const phone_filter = this.$options.filters.phone;
      const csv = new CSV();
      csv.addRow(["Created", "Name", "Phone", "Balance", "Lifetime", "Orders"]);

      _.each(this.members, (item) => {
        const date = date_filter(item.created);
        const name = item.name?.last + ", " + item.name?.first;
        const phone = phone_filter(item.phone);
        const data = [date, name, phone, item.balance, item.amount, item.count];
        csv.addRow(data);
      });
      const today = moment().format("YYYY_MM_DD");
      csv.save("member_one_page_" + today);
    },
  },
};
</script>
