<template lang="pug">
div
  .d-flex.flex-row.align-center.mb-3
    .title.mr-3 General Sales Stats
    v-btn-toggle(rounded, color="secondary", v-model="unit", @change="load")
      v-btn.text-capitalize(small, value="month") Monthly
      v-btn.text-capitalize(small, value="week") Weekly
    v-spacer
    YearSelector(@select="loadYear")
  v-row(wrap, dense)
    v-col(cols="12", md="6")
      StandardChart(
        :input="totalData",
        chartType="bar",
        maxWidth="100%",
        :loading="loading"
      )
    v-col(cols="12", md="6")
      StandardChart(
        :input="countData",
        chartType="bar",
        maxWidth="100%",
        :loading="loading"
      )
  Table.my-3(:report="result", title="Weekly Sales Summary", :year="year")
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import moment from "moment-timezone";
import Table from "./Table";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Table },
  data() {
    return {
      unit: "month",
      year: new Date().getFullYear(),
      result: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    labels() {
      if (!this.result?.length) return [];
      if (this.unit == "month") {
        const month = this.$options.filters.month;
        return this.result.map((o) => month(o.month, true));
      } else if (this.result[0].week != undefined) {
        return _.map(this.result, (o) =>
          moment()
            .year(o.year)
            .isoWeek(o.week)
            .startOf("isoWeek")
            .format("MMM D")
        );
      }
      return [];
    },
    totalData() {
      if (!this.result?.length || this.loading) return null;
      if (this.loading) return null;
      return {
        title: "Sales",
        labels: this.labels,
        values: this.result.map((o) => Math.round(o.total * 100) / 100),
        isCurrency: true,
      };
    },
    countData() {
      if (!this.result?.length || this.loading) return null;
      return {
        title: "#Orders",
        labels: this.labels,
        values: this.result.map((o) => o.count),
      };
    },
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    loadYear(year) {
      this.year = year;
      this.load();
    },
    async load() {
      if (!this.biz || !this.year || !this.unit) return;
      this.loading = true;
      const params = {
        bizId: this.biz._id,
        year: this.year,
        period: this.unit,
      };
      this.result = await this.$api.order.custom("stats/period", params);
      this.loading = false;
    },
  },
};
</script>