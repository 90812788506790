import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home/index.vue'
import Guide from './../components/Guide/index.vue'
import Create from '@/pages/Create/index.vue'

import OrderPublic from '@/components/Order/Detail/Public.vue'

// import routes
import login from '@/components/User/Login/route.js'
import performance from '@/components/Performance/route'
import settings from '@/components/Settings/route'
import customer from '@/components/Customer/route'
import hr from '@/components/HR/route'
import marketing from '@/components/Marketing/route'
import owner from '@/components/Owner/route'
import info from '@/components/SelflaneServices/route'
import corp from '@/components/Corporation/route'

// sales force
import partner from '@/components/Partner/route'
import enterprise from '@/pages/enterprise/route'

import redirect from './redirectRoute'
import company from '/libs/components/Company/route'
import sudoku from '/libs/components/Games/Sudoku/route'

import dev from '@/components/Dev/route'

Vue.use(Router)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/guide', component: Guide, meta: { auth: true } },
  { path: '/create', component: Create, meta: { auth: true } },
  { path: '/public/order/:orderId', component: OrderPublic, props: true },
  ...login,
  ...performance,
  ...settings,
  ...customer,
  ...hr,
  ...marketing,
  ...owner,
  ...info, ...corp,
  ...partner, ...enterprise,
  ...redirect, ...company,
  ...sudoku,
  ...dev,
  { path: '*', redirect: '/' }
]

export default new Router({
  mode: 'history',
  linkActiveClass: 'is-active',
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})