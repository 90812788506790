import Main from './index.vue'
import Settings from './Settings'
import Togo from './Togo'
import Printing from './Printing'
import QSR from './QSR'
import Gift from './Gift'
import Discounts from './Discounts'
import CashDiscount from './CashDiscount'
import Payment from './Payment'

export default [
  {
    path: '/pos', component: Main, meta: { auth: true }, children: [
      { path: '', redirect: 'settings' },
      { path: 'settings', component: Settings },
      { path: 'togo', component: Togo },
      { path: 'printing', component: Printing },
      { path: 'gift', component: Gift },
      { path: 'qsr', component: QSR },
      { path: 'discounts', component: Discounts },
      { path: 'cash_discount', component: CashDiscount },
      { path: 'payment', component: Payment }
    ]
  }
]