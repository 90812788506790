<template lang="pug">
div
  .pa-3.s-vstack(v-if="unpaid?.length")
    .title.red--text.text-center You have unpaid invoices.
    Payment
    InvoiceList(:items="unpaid")
  div(v-else)
    slot
</template>

<script>
import Payment from "@/components/Owner/Billing/Payment/index";
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import InvoiceList from "/libs/components/Billing/Invoice/List";

export default {
  name: "InvoiceGuard",
  components: { Payment, InvoiceList },
  computed: {
    ...mapGetters(["navState", "biz", "invoices"]),
    // invoices that are not paid and not associated with a billing organization
    unpaid() {
      if (this.navState != "biz") return [];
      if (!this.biz?._id) return [];
      return this.invoices.filter((i) => {
        return (
          i.biz?.id == this.biz._id && i.status != 1 && !i.billing_organization
        );
      });
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.biz?._id) return;
      const cutoff = moment().subtract(3, "years").valueOf();
      const criteria = { "biz.id": this.biz._id, created: { $gte: cutoff } };
      try {
        const result = await this.$api.invoice.list({ criteria });
        this.$store.dispatch("addInvoices", result);
      } catch (e) {
        this.$store.dispatch("addInvoices", []);
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>