const state = {
  corpaccess: []
}

const getters = {
  corpaccess: state => state.corpaccess,
}

const actions = {
  setCorpAccess: ({ commit }, data) => { commit('setCorpAccess', data) },
}

const mutations = {
  setCorpAccess(state, data) {
    state.corpaccess = data
  },
}

export default { state, getters, actions, mutations }