<template lang="pug">
v-card(v-if="items", flat)
  v-toolbar(flat, dense)
    .subtitle-2 Voided Orders {{ items.length }}
    v-spacer
    Download(:items="items")
  v-data-table(
    v-if="items.length",
    :headers="headers",
    :items="items",
    dense,
    @click:row="showDetail",
    role="button",
    mobile-breakpoint="0"
  )
    template(v-slot:item.needed="{ item }")
      span {{ item.needed | time }}
    template(v-slot:item.amount="{ item }")
      span(v-if="item.amount") {{ item.amount | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Download from "./Download";

export default {
  components: { Download },
  props: ["data"],
  data() {
    return {
      headers: [
        { text: "ID", value: "orderNumber" },
        { text: "Time", value: "needed" },
        { text: "Server", value: "server" },
        { text: "Value", value: "amount", sortable: false },
        { text: "Reason", value: "cancelNote", sortable: false },
      ],
    };
  },
  computed: {
    items() {
      return this.data.map((item) => {
        let server = "";
        if (item.orderer) server = item.orderer.name;
        return {
          _id: item._id,
          needed: item.needed,
          orderNumber: item.orderNumber,
          server: server,
          amount: item.payment.total,
          cancelNote: item.cancelNote,
        };
      });
    },
  },
  methods: {
    showDetail(item) {
      if (!item) return;
      EventBus.$emit("show-order-detail", item._id);
    },
  },
};
</script>
