<template lang="pug">
.s-vstack
  MonthSelector(@select="selectMonth")
  Summary(:items="items", :loading="loading")
  Nguests(:items="items", :loading="loading")
  ServiceItems(:items="items")
  GrossReceiptTax(:items="items")
  Platform(:statements="items")
  .d-flex.flex-row.align-center
    Recreate(@done="load()", :month="month")
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Summary from "./Summary";
import Nguests from "./Nguests";
import ServiceItems from "/libs/components/Order/Performance/ServiceItems";
import GrossReceiptTax from "/libs/components/Order/Performance/GrossReceiptTax";
import Platform from "./Platform";
import Recreate from "./Recreate";

export default {
  components: {
    Summary,
    Nguests,
    ServiceItems,
    GrossReceiptTax,
    Platform,
    Recreate,
  },
  data: () => ({
    month: "", // YYYY-MM
    items: [],
    loading: false,
  }),
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    selectMonth(month) {
      this.month = month.month;
      this.load();
    },
    async load() {
      if (!this.biz || !this.biz._id || !this.month) return;
      const monthToDates = this.$options.filters.monthToDates;
      const dates = monthToDates(this.month);
      const params = {
        criteria: { biz: this.biz._id, date: { $in: dates } },
      };
      this.loading = true;
      const url = "/bizs/sales_daily_report/list";
      try {
        const { data } = await this.axios.post(url, params);
        this.items = data;
      } catch (e) {
        // fail silently
      }
      this.loading = false;
    },
  },
};
</script>
