import { EventBus } from "@/event-bus.js";

const state = {
  stripeAccount: null
}

const getters = {
  stripeAccount: state => state.stripeAccount,
}

const actions = {
  setStripeAccount: ({ commit }, data) => { commit('setStripeAccount', data) }
}

const mutations = {
  setStripeAccount(state, data) {
    state.stripeAccount = data
    EventBus.$emit('stripe-account-change')
  }
}

export default { state, getters, actions, mutations }