<template lang="pug">
v-dialog(v-model="dialog", max-width="300", @keydown.esc="dialog = false")
  v-card
    v-toolbar(dense, flat)
      .overline Edit Point Voucher
    v-card-text
      v-form(@submit.prevent="submit", v-if="pointvoucher")
        v-text-field(
          label="Name",
          v-model="pointvoucher.name",
          placeholder="eg. 100 Points for $5",
          focus,
          ref="focus"
        )
        v-layout(wrap, align-center)
          v-flex(xs5)
            v-text-field(
              type="number",
              label="Points",
              v-model="pointvoucher.pts",
              suffix=" PTS"
            )
          v-flex.text-center(xs2)
            v-icon mdi-arrow-right-bold
          v-flex(xs5)
            v-text-field(
              type="number",
              label="Amount",
              v-model="pointvoucher.amount",
              prefix="$"
            )
        v-btn.white--text(
          block,
          color="indigo",
          type="submit",
          :loading="loading"
        ) Save Point Voucher
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["input"],
  data() {
    return {
      pointvoucher: null,
      dialog: false,
      errors: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  watch: {
    input: {
      handler(val) {
        if (!val) return;
        this.pointvoucher = JSON.parse(JSON.stringify(val));
        this.errors = [];
        this.dialog = true;
        this.$nextTick(() => this.$refs.focus.focus());
      },
      deep: true,
    },
    dialog(val) {
      if (!val) this.$emit("done");
    },
  },
  methods: {
    ...mapActions(["addPointVoucher", "updatePointVoucher"]),
    async submit() {
      this.errors = [];
      this.pointvoucher.pts = Math.round(this.pointvoucher.pts);
      this.pointvoucher.amount = Math.round(this.pointvoucher.amount);
      /// validate
      if (!this.pointvoucher.name) this.errors.push("Name is required");
      if (this.pointvoucher.pts < 10)
        this.errors.push("The minimum of points is 10");
      if (this.pointvoucher.amount < 1)
        this.errors.push("The minimum of value is 1");
      if (this.errors?.length) return;
      // passed
      this.loading = true;
      try {
        if (this.pointvoucher?._id) {
          const params = {
            criteria: { _id: this.pointvoucher._id },
            action: { $set: this.pointvoucher },
          };
          const data = await this.$api.pointVoucher.update(params);
          this.updatePointVoucher(data);
        } else {
          const data = await this.$api.pointVoucher.create(this.pointvoucher);
          this.addPointVoucher(data);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
