<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download", v-if="items")
</template>

<script>
import CSV from "/libs/utils/CSV";

export default {
  name: "Balancedownloader",
  props: ["items", "year"],
  methods: {
    download() {
      if (!this.items) return;
      const currency = this.$options.filters.currency;
      const csv = new CSV();
      csv.addRow(["Period", "Net Sales", "Expense", "Payroll", "Balance"]);
      this.items.forEach((item) => {
        csv.addRow([
          item.period,
          currency(item.orderValue),
          currency(item.expenseValue),
          currency(item.payrollValue),
          currency(item.balance),
        ]);
      });
      csv.save(this.year + " Statements");
    },
  },
};
</script>
