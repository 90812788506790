import Home from './index.vue'
import Dashboard from './Dashboard'
import Activities from './Activities'
import Contacts from './Contacts'
import ServiceLogs from './ServiceLogs'
import Histories from './Histories'

export default [
  { path: '/partner/crm', component: Home, meta: { auth: true } },
  { path: '/partner/crm/dashboard', component: Dashboard, meta: { auth: true } },
  { path: '/partner/crm/activities', component: Activities, meta: { auth: true } },
  { path: '/partner/crm/contacts', component: Contacts, meta: { auth: true } },
  { path: '/partner/crm/service-logs', component: ServiceLogs, meta: { auth: true } },
  { path: '/partner/crm/histories', component: Histories, meta: { auth: true } },
]