<template lang="pug">
v-container(fluid )
  v-row(wrap)
    v-col(cols='12' md='6' style='min-height: 400px')
      v-card(flat shaped height="100%" style='background: linear-gradient(135deg, rgba(250,250,249,1) 0%, rgba(249,245,220,1) 100%);')
        v-card-text
          .text-center
            .title.mb-5 Online Ordering
            .subtitle-1 Commission Free; unlike other services you keep all your profit
            .subtitle-1 So easy you can do it yourself today
            .subtitle-1 Features include curbside pick-up
          .d-flex.flex-row.justify-center.align-end.mt-5
            div.text-center.mx-5
              v-icon(x-large color='blue lighten-1') mdi-tablet
              div.subtitle-2.blue--text.text--lighten-1 POS
            div.text-center.mx-5
              v-icon(x-large color='blue lighten-1') mdi-email
              div.subtitle-2.blue--text.text--lighten-1 Email
            div.text-center.mx-5
              v-icon(x-large color='blue lighten-1') mdi-phone
              div.subtitle-2.blue--text.text--lighten-1 Phone
          .text-center.mt-5
            .title.primary--text.mb-3 $0 - Totally free with no obligations
            v-btn(outlined to='/info/online').text-capitalize Learn More
            //- CreateBusinessTutorial
    v-col(cols='12' md='6' style='min-height: 400px')
      v-card(flat shaped dark height="100%" style='background: linear-gradient(135deg, rgba(96,90,83,1) 0%, rgba(60,40,29,1) 100%;')
        v-card-text
          .text-center
            .title.mb-5 Point of Sale
            .subtitle-1 Online orders print directly to your kitchen
            .subtitle-1 Manage business anywhere anytime
            .subtitle-1 Fully scalable as your business grows!
          .d-flex.flex-row.justify-center.align-end.mt-5
            div.text-center.mx-5
              v-icon(x-large color='blue lighten-3') mdi-table-chair
              div.subtitle-2.blue--text.text--lighten-3 Sit Down
            div.text-center.mx-5
              v-icon(x-large color='blue lighten-3') mdi-account-group
              div.subtitle-2.blue--text.text--lighten-3 Quick Service
            div.text-center.mx-5
              v-icon(x-large color='blue lighten-3') mdi-glass-mug-variant
              div.subtitle-2.blue--text.text--lighten-3 Brewery
            div.text-center.mx-5
              v-icon(x-large color='blue lighten-3') mdi-car-connected
              div.subtitle-2.blue--text.text--lighten-3 Drive Thru
          .text-center.mt-5
            .title.primary--text.mb-3 Starting at $29.99/month/location
            v-btn(outlined to='/info/pos').text-capitalize Learn More
</template>

<script>
import CreateBusinessTutorial from "./CreateBusinessTutorial";
export default {
  components: { CreateBusinessTutorial },
};
</script>
