<template lang="pug">
v-switch(
  v-model="biz.orderType.gc.status",
  color="secondary",
  @change="toggle",
  label="eGift Card Program",
  :loading="loading",
  v-if="biz"
)
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    async toggle(value) {
      if (!this.biz) return;
      this.loading = true;
      const action = { $set: { "orderType.gc.status": value } };
      try {
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>