<template lang="pug">
.d-flex.flex-row.my-3.align-center(v-if="isShared")
  .subtitle-2 This member program is shared by {{ program.bizs.length }} businesses.
  v-dialog(width="400", scrollable)
    template(v-slot:activator="{ on }")
      v-btn.ml-3.text-capitalize(
        x-small,
        outlined,
        color="secondary",
        v-on="on",
        @click="load()"
      ) show
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Shared Member Program
        v-spacer
        .subtitle-2 {{ program.bizs.length }}
      v-divider
      v-list.py-0(dense)
        v-list-item(v-for="(item, index) in bizs", :key="index")
          v-list-item-content
            v-list-item-title {{ item.name }}
              span.ml-1(v-if="item.short_name") ({{ item.short_name }})
            v-list-item-subtitle {{ item.address | address }}
</template>

<script>
export default {
  props: ["program"],
  data() {
    return { bizs: [] };
  },
  computed: {
    isShared() {
      return this.program && this.program.bizs && this.program.bizs.length > 1;
    },
  },
  methods: {
    async load() {
      if (!this.program) return;
      const params = {
        criteria: { _id: { $in: this.program.bizs } },
        select: "name short_name address",
      };
      try {
        this.bizs = await this.$api.biz.list(params);
      } catch (e) {
        //
      }
    },
  },
};
</script>
