<template lang="pug">
v-btn(icon, @click="handleRemove()", :loading="loading", color="error")
  v-icon(small) mdi-delete
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DeletePerson",
  props: ["person"],
  data() {
    return {
      loading: false,
    };
  },
  computed: { ...mapGetters(["stripeAccount"]) },
  methods: {
    ...mapActions(["removeStripePerson"]),
    handleRemove() {
      confirm("Do you want to delete this owner?") && this.remove();
    },
    async remove() {
      if (!this.stripeAccount || !this.person) return;
      this.loading = true;
      const params = {
        accountId: this.stripeAccount.id,
        personId: this.person.id,
      };
      try {
        await this.$api.stripe.person.delete(params);
        this.removeStripePerson(this.person);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
