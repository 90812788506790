<template lang="pug">
Page(:title="title", :doc="doc")
  .body-2 Only use this to manage multiple businesses with same menus, hours, or settings.
  .subtitle-2.warning--text This action can't be undone.
  Table
  Backup
  ThirdParty.my-10
</template>

<script>
import Table from "./Table";
import Backup from "./Backup";
import ThirdParty from "./ThirdParty/index";

export default {
  components: { Table, Backup, ThirdParty },
  data() {
    return {
      title: "Sync Menus and Hours Among Businesses",
      doc: "https://docs.selflane.com/biz/owner/sync.html",
    };
  },
};
</script>
