<template lang="pug">
div
  .subtitle-2.text-center.my-5 Menus are organized in a three-tier structure
  .d-flex.flex-row.justify-center.align-center
    v-card(min-width="120", shaped)
      v-toolbar(dense, flat)
        .overline Menus
      v-list.py-0(dense)
        v-list-item(
          v-for="(item, index) in menus",
          :key="index",
          @click="selectMenu(item)"
        )
          v-list-item-content
            v-list-item-title {{ item.name }}
    v-icon.mx-3 mdi-arrow-right-bold-outline
    v-card(min-width="120", shaped)
      v-toolbar(dense, flat)
        .overline Courses
      v-list.py-0(dense)
        v-list-item(
          v-for="(item, index) in courses",
          :key="index",
          @click="selectCourse(item)"
        )
          v-list-item-content
            v-list-item-title {{ item.name }}
    v-icon.mx-3 mdi-arrow-right-bold-outline
    v-card(min-width="120", shaped)
      v-toolbar(dense, flat)
        .overline Dishes
      v-list.py-0(dense)
        v-list-item(v-for="(item, index) in dishes", :key="index")
          v-list-item-content
            v-list-item-title {{ item }}
  .text-center.subtitle-2.my-5 Please go to the following page to add your menus.
  v-divider.my-5
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          name: "Main",
          courses: [
            {
              name: "Appetizer",
              dishes: ["Cheese Fries", "Chicken Nachos", "Steak Pountine"],
            },
            {
              name: "Salad",
              dishes: ["Black Berry Bacon", "Cranberry Apple", "Chicken Salad"],
            },
            {
              name: "Soup",
              dishes: [
                "Albondigas",
                "Green Chike Chicken Stew",
                "Soup of the Day",
              ],
            },
            {
              name: "Entree",
              dishes: [
                "Chicken Caprese",
                "Fire Braised Ribs",
                "Grilled Hawaiian Wahoo",
              ],
            },
          ],
        },
        {
          name: "Lunch",
          courses: [
            {
              name: "Lunch Special",
              dishes: ["Carnitas", "Ham & Asparagus", "Fresh Veggie Omelettes"],
            },
            {
              name: "Lunch Dessert",
              dishes: [
                "Chocolate Cake",
                "Gooey Cookie",
                "Bourbon Pecan Pudding",
              ],
            },
          ],
        },
      ],
      courses: [],
      dishes: [],
    };
  },
  mounted() {
    this.selectMenu(this.menus[0]);
  },
  methods: {
    selectMenu(menu) {
      this.courses = menu.courses;
      if (menu.courses.length > 0) this.dishes = menu.courses[0].dishes;
    },
    selectCourse(course) {
      this.dishes = course.dishes;
    },
  },
};
</script>
