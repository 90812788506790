<template lang="pug">
v-card-title.link-box
  v-avatar(size="60", @click="showPhoto", role="button")
    v-img(:src="avatar", v-if="avatar")
    v-icon(v-else) mdi-account
  .subtitle-2.black--text {{ title }}
  .subtitle-2.red--text(v-if="!server.status") Deactivated
  PhotoDialog(ref="photo")
</template>

<script>
import PhotoDialog from "/libs/components/Basic/PhotoDialog";

export default {
  components: { PhotoDialog },
  props: {
    server: { type: Object, default: null },
  },
  computed: {
    avatar() {
      return this.server?.photo;
    },
    title() {
      let items = [];
      if (this.server?.first_name) items.push(this.server.first_name);
      if (this.server?.last_name) items.push(this.server.last_name);
      return items.join(" ");
    },
  },
  methods: {
    showPhoto() {
      if (!this.avatar) return;
      this.$refs.photo.open({ photo: this.avatar, title: this.title });
    },
  },
};
</script>
