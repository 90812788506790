<template lang="pug">
Page(title="Online Sales", :doc="doc")
  template(slot="header")
    YearSelector(@select="load")
  Sales.my-5(:year="year")
  Transfers.my-5(ref="transfers")
  Payouts.my-5(:id="managed_account")
  Note.my-5
</template>

<script>
import Payouts from "@/components/Owner/Payments/Settings/Stripe/Payouts/index";
import { mapGetters } from "vuex";
import Note from "./Note";
import Sales from "./Sales/index";
import Transfers from "./Transfers/index";

export default {
  components: { Sales, Transfers, Payouts, Note },
  data() {
    return {
      unit: "monthly",
      year: new Date().getFullYear(),
      doc: "https://docs.selflane.com/biz/performance/online-sales.html",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    managed_account() {
      return this.biz?.payments?.managed_account;
    },
  },
  methods: {
    load(year) {
      this.year = year;
      this.$refs.transfers.load(year);
    },
  },
};
</script>

<style lang="sass" scoped>
.sticky
  position: -webkit-sticky
  position: sticky
  top: 64px
  z-index: 4
  background: #F0F2F5
  display: flex
  padding-bottom: 12px
</style>