<template lang="pug">
Page(title="Action Log")
  Log
</template>

<script>
import Log from "/libs/components/Biz/Log";

export default {
  components: { Log },
};
</script>
