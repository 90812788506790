<template lang="pug">
v-card(outlined)
  v-toolbar(flat, dense)
    .subtitle-2 
    v-spacer
    v-btn.text-notransform(outlined, small, @click="open()")
      span Edit
      v-icon(small, right) mdi-pencil
  v-card-text
    .text-center
      .title {{ influencer_name }}
      .title Discount:
        span.ml-2(v-if="plan.type == 'pct'") {{ -plan.pct }}%
        span.ml-2(v-else) {{ -plan.fix | currency }}
      .title Expires on {{ plan.expiry | date }}
      v-alert(color="error", dark, v-if="isExpired") Expired
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  props: ["plan"],
  computed: {
    ...mapGetters(["influencers"]),
    isExpired() {
      return this.plan.expiry < Date.now();
    },
    influencer_name() {
      const found = this.influencers?.find(
        (o) => this.plan.influencer == o._id
      );
      return found?.name || "";
    },
  },
  methods: {
    open() {
      EventBus.$emit("edit-influencer-special-plan", this.plan);
    },
  },
};
</script>
