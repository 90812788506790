<template lang="pug">
v-dialog(v-model="dialog", max-width="550")
  v-card(v-if="table")
    v-toolbar(dense, flat)
      .subtitle-2 Edit Table
    v-card-text
      v-form(@submit.prevent="submit")
        v-row(dense)
          v-col(cols="3")
            v-text-field(
              label="ID",
              v-model="table.id",
              autofocus,
              ref="focus"
            )
          v-col(cols="3")
            v-select(
              label="Min",
              v-model="table.min_capacity",
              :items="capacity_items",
              hide-details
            )
          v-col(cols="3")
            v-select(
              label="Max",
              v-model="table.max_capacity",
              :items="capacity_items",
              hide-details
            )
          v-col(cols="3")
            v-combobox(label="Room", v-model="table.room", :items="rooms")
        v-row(dense)
          v-col(cols="4")
            v-text-field(
              label="X",
              v-model="table.location.x",
              hide-details,
              :readonly="!edit_location"
            )
          v-col(cols="4")
            v-text-field(
              label="Y",
              v-model="table.location.y",
              hide-details,
              :readonly="!edit_location"
            )
          v-col(cols="4")
            v-btn(
              icon,
              small,
              :color="edit_location ? 'secondary' : 'grey'",
              @click="edit_location = !edit_location"
            )
              v-icon(small) mdi-pencil
        p {{ table.width }} x {{ table.height }}
        p {{ table.round ? "Round" : "Square" }}
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      table: null,
      dialog: false,
      errors: [],
      loading: false,
      capacity_items: _.range(1, 21),
      edit_location: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    rooms() {
      if (!this.biz) return ["main"];
      const data = _.chain(this.biz.tables).pluck("room").uniq().value();
      if (data?.length) return data;
      else return ["main"];
    },
  },
  mounted() {
    EventBus.$on("edit-table", this.open);
  },
  destroyed() {
    EventBus.$off("edit-table", this.open);
  },
  methods: {
    open(data) {
      this.edit_location = false;
      if (data) {
        this.table = JSON.parse(JSON.stringify(data));
        if (!this.table.room) {
          const rooms = this.rooms;
          if (rooms.length > 0) this.table.room = rooms[0];
        }
      }
      this.errors = [];
      this.dialog = true;
      this.$nextTick(() => this.$refs.focus.focus());
    },
    async submit() {
      if (!this.table || !this.biz) return;
      if (this.table.location.x < 0 || this.table.location.y < 0) {
        this.errors.push("Location should be positive values");
        return;
      }
      this.errors = [];
      /// validate
      if (!this.table.id) {
        this.errors.push("ID is required");
      } else {
        const some = this.biz.tables.some(
          (o) => o._id !== this.table._id && o.id === this.table.id
        );
        if (some) this.errors.push("ID is existing");
      }
      if (
        !this.table.min_capacity ||
        !this.table.max_capacity ||
        this.table.max_capacity < this.table.min_capacity
      ) {
        this.errors.push("Min/Max is required; Max should be greater than min");
      }
      if (!this.table.room) this.errors.push("Select a room");
      if (this.errors.length > 0) return;

      this.loading = true;
      let params;
      if (this.table._id) {
        params = {
          criteria: { _id: this.biz._id, "tables._id": this.table._id },
          action: { $set: { "tables.$": this.table } },
        };
      } else {
        params = {
          criteria: { _id: this.biz._id },
          action: { $push: { tables: this.table } },
        };
      }
      try {
        const data = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>