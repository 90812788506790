<template lang="pug">
Page(title="Dashboard")
  div(v-for="(group, index) in groups", :key="index")
    .subtitle-2.my-3 {{ group.region.name }}
    v-row(wrap)
      v-col(
        cols="12",
        md="6",
        lg="4",
        v-for="(biz, i) in group.bizs",
        :key="i"
      )
        v-card(flat)
          v-toolbar(flat, dense)
            .subtitle-2 {{ biz.short_name || biz.name }}
          ThirtyDay(:bizId="biz._id")
</template>

<script>
import { mapGetters } from "vuex";
import ThirtyDay from "/libs/components/Order/Performance/ThirtyDay";

export default {
  components: { ThirtyDay },
  data() {
    return { bizs: [] };
  },
  computed: {
    ...mapGetters("entp", ["enterprise", "accesses", "regions"]),
    myId() {
      return this.$auth.user()?._id;
    },
    myAccess() {
      return this.accesses?.find(
        (o) => o.enterprise == this.enterprise._id && o.user == this.myId
      );
    },
    myRegions() {
      return this.regions?.filter((o) => o.enterprise == this.enterprise._id);
    },
    // group bizs by region
    groups() {
      if (!this.bizs?.length) return [];
      let groups = [];
      this.myRegions?.forEach((region) => {
        let bizs = this.bizs.filter((biz) => region.bizs.includes(biz._id));
        if (bizs.length) {
          groups.push({ region, bizs });
        }
      });
      groups.sort((a, b) => a.region.name.localeCompare(b.region.name));
      // add no region bizs
      let items = this.bizs.filter(
        (biz) => !this.myRegions?.flatMap((o) => o.bizs).includes(biz._id)
      );
      if (items.length) {
        groups.push({ region: { name: "No Region" }, bizs: items });
      }
      return groups;
    },
  },
  watch: {
    myAccess: {
      handler() {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    // load bizs
    async load() {
      if (!this.enterprise || !this.myAccess) return;
      let ids;
      if (this.myAccess.privilege == 1) {
        ids = this.enterprise.bizs;
      } else {
        let myRegions = this.regions.filter((o) =>
          this.myAccess.regions.includes(o._id)
        );
        ids = myRegions.flatMap((o) => o.bizs);
      }
      if (!ids?.length) return;
      try {
        const params = {
          criteria: { _id: { $in: ids } },
          select: "name short_name address",
        };
        const result = await this.$api.biz.list(params);
        this.bizs = result;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>