<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="department")
    v-card-title {{ title }} a Department
    v-form(@submit.prevent="submit")
      v-card-text
        v-text-field(
          v-model="department.name",
          label="Name",
          required,
          :hint="id"
        )
        v-switch(
          v-model="department.default",
          label="Is Default",
          color="secondary",
          hide-details
        )
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
      v-card-text(v-if="department.id")
        v-checkbox(
          v-model="confirmDelete",
          label="Confirm Delete",
          color="error",
          hide-details
        )
        v-btn(
          text,
          color="error",
          @click="deleteOne",
          :loading="deleting",
          :disabled="!confirmDelete"
        )
          v-icon.left(small) mdi mdi-delete
          span Remove
</template>

<script>
export default {
  props: { biz: { type: Object, required: true } },
  data() {
    return {
      dialog: false,
      department: null,
      confirmDelete: false,
      loading: false,
      deleting: false,
    };
  },
  computed: {
    id() {
      return this.department?.id;
    },
    title() {
      return this.department?.id ? "Edit" : "Add";
    },
  },
  methods: {
    open(data) {
      if (!data) {
        this.department = { name: "", default: false, id: null };
      } else {
        this.department = JSON.parse(JSON.stringify(data));
      }
      this.confirmDelete = false;
      this.dialog = true;
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      const params = {
        bizId: this.biz._id,
        name: this.department?.name,
        isDefault: this.department?.default,
        departmentId: this.department?.id,
      };
      try {
        if (this.department.id) {
          const data = await this.$api.seven.department.update(params);
          this.$store.dispatch("seven/updateDepartment", data);
        } else {
          const data = await this.$api.seven.department.create(params);
          this.$store.dispatch("seven/addDepartment", data);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async deleteOne() {
      if (!this.department?.id) return;
      if (!this.biz?._id) return;
      this.deleting = true;
      const params = {
        bizId: this.biz._id,
        departmentId: this.department.id,
      };
      try {
        await this.$api.seven.department.delete(params);
        this.$store.dispatch("seven/removeDepartment", this.department);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.deleting = false;
    },
  },
};
</script>