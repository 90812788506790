<template lang="pug">
v-form(@submit.prevent="submit")
  v-card-text
    v-row(dense)
      v-col(cols=6)
        v-select(
          v-model="posEditable.cash_discount.type",
          :items="items",
          label="Program"
        )
      v-col(cols=6)
        v-text-field(
          label="Percentage",
          v-model="posEditable.cash_discount.pct",
          suffix="%",
          v-if="discounted"
        )
    v-alert(v-if="discounted && overPct", type="warning") Usually the maximum allowed surcharge is 4%.
  v-card-actions(v-if="dirty")
    v-btn(color="secondary", type="submit", :loading="loading") Save
    v-btn(text, @click="cancel()") Cancel
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      items: [
        { text: "No Discount/Surcharge", value: 0 },
        { text: "True-Cash Discount", value: 1 },
        { text: "Non-Cash Adjustment", value: 2 },
        { text: "Surcharge", value: 3 },
        { text: "Service Fee", value: 4 },
        { text: "Convenience Fee", value: 5 },
        { text: "Dual Pricing", value: 6 },
      ],
    };
  },
  computed: {
    ...mapGetters(["pos", "posEditable"]),
    dirty() {
      return JSON.stringify(this.posEditable) !== JSON.stringify(this.pos);
    },
    discounted() {
      return this.posEditable?.cash_discount?.type;
    },
    overPct() {
      return (
        this.posEditable?.cash_discount &&
        this.posEditable.cash_discount.pct > 4
      );
    },
  },
  methods: {
    ...mapActions(["setPOS", "resetEditable"]),
    async submit() {
      this.loading = true;
      const action = {
        $set: { cash_discount: this.posEditable.cash_discount },
      };
      try {
        const result = await this.$api.pos.put(this.pos?._id, { action });
        this.setPOS(result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to save POS settings");
      }
      this.loading = false;
    },
    cancel() {
      this.resetEditable();
    },
  },
};
</script>
