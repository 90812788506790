<template lang="pug">
v-btn(
  @click="select()",
  elevation="0",
  text,
  block,
  :color="active ? 'secondary' : ''",
  large
)
  .vertical-flex
    .cell-title {{ item.name | truncate(45) }}
    .caption {{ subtitle }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(["biz"]),
    subtitle() {
      return this.item.short_name?.trim() || this.item.address?.line1;
    },
    active() {
      return this.item._id == this.biz?._id;
    },
  },
  methods: {
    ...mapActions(["setNavState"]),
    async select() {
      const bizId = this.item._id;
      if (!bizId) return;
      try {
        const biz = await this.$api.biz.retrieve({ criteria: { _id: bizId } });
        this.$store.dispatch("setBiz", biz);
        const service = await this.$api.service.account.retrieve({
          criteria: { biz: biz._id },
        });
        this.$store.dispatch("setBizService", service);
        this.setNavState("biz");
        EventBus.$emit("switch-biz");
        this.$emit("selected");
        // check if the current path is not biz path
        const path = this.$router.history.current.path;
        if (
          path.startsWith("/partner") ||
          path.startsWith("/enterprise") ||
          path.startsWith("/create")
        ) {
          this.$router.push("/");
        }
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.vertical-flex
  display: flex
  flex-direction: column
  width: 100%
  align-items: flex-start
  text-transform: none
.cell-title
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  font-size: 14px
  font-weight: 500
</style>
