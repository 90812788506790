<template lang="pug">
.link-box
  IconBtn(@click="downloadCSV()", title="CSV", icon="mdi-download", v-if="csv")
  IconBtn(@click="downloadPDF()", title="PDF", icon="mdi-download", v-if="pdf")
  DailyTable(:items="items", v-if="daily")
  slot
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import DailyTable from "./DailyTable";
import CSV from "/libs/utils/CSV";
import PDF from "/libs/utils/PDF";

export default {
  components: { DailyTable },
  props: {
    items: { type: Array, default: () => [] },
    csv: { type: Boolean, default: true },
    pdf: { type: Boolean, default: true },
    daily: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    downloadCSV() {
      if (!this.items?.length) return;
      const csv = new CSV();
      const currency = this.$options.filters.currency;

      const begin = this.items[0].date;
      const end = this.items[this.items.length - 1].date;
      if (begin !== end) {
        csv.addRow(["Date", begin + " ~ " + end]);
      } else {
        csv.addRow(["Date", begin]);
      }
      csv.addNewRow();
      getCounts(this.items).forEach((line) => addLine(line, currency));
      csv.addNewRow();
      getSales(this.items).forEach((line) => addLine(line, currency));
      csv.addNewRow();
      getPayments(this.items).forEach((line) => addLine(line, currency));
      csv.addNewRow();
      getDiscounts(this.items).forEach((line) => addLine(line, currency));
      csv.addNewRow();
      getProfitLoss(this.items, this.biz).forEach((line) =>
        addLine(line, currency)
      );
      csv.addNewRow();
      const cards = addCards(this.items);
      if (cards.length) {
        cards.forEach((line) => addLine(line, currency));
        csv.addNewRow();
      }
      csv.addRow(["Gross Receipts Tax"]);
      csv.addRow(["Name", "Tax", "Net Sales", "Gross Sales", "Tax Rate"]);
      getTaxItems(this.items).forEach((line) => {
        const { name, value, net, percentage } = line;
        csv.addRow([
          name,
          currency(value),
          currency(net),
          currency(value + net),
          `${percentage}%`,
        ]);
      });
      csv.addNewRow();
      csv.addRow(["Created", new Date().toLocaleString()]);
      csv.save("sales_report");

      function addLine(item, currency) {
        if (!item.amount && !item.mandatory) return;
        let amount = Math.round(item.amount * 100) / 100;
        if (item.currency !== false) {
          csv.addRow([item.name, currency(amount)]);
        } else {
          csv.addRow([item.name, amount]);
        }
      }
    },
    downloadPDF() {
      if (!this.items?.length) return;
      const currency = this.$options.filters.currency;
      let doc = new PDF();
      // Title
      const begin = this.items[0].date;
      const end = this.items[this.items.length - 1].date;
      const dates = begin === end ? begin : begin + " ~ " + end;
      doc.addTitle(`Sales Report (${dates})`);
      // Content
      doc.addLine();
      getCounts(this.items).forEach((item) => addItem(item, currency));
      doc.addLine();
      getSales(this.items).forEach((item) => addItem(item, currency));
      doc.addLine();
      getPayments(this.items).forEach((item) => addItem(item, currency));
      const cards = addCards(this.items);
      if (cards.length) {
        doc.addLine();
        cards.forEach((item) => addItem(item, currency));
      }
      // Discount
      doc.addLine();
      getDiscounts(this.items).forEach((item) => addItem(item, currency));
      doc.addLine();
      // Profit & Loss
      doc.nextLine();
      getProfitLoss(this.items, this.biz).forEach((item) =>
        addItem(item, currency)
      );
      doc.addLine();
      // Service Items
      const service_items = getServiceItems(this.items);
      if (service_items.length) {
        doc.nextLine();
        doc.addContent("Service Items", { fontWeight: "bold" });
        doc.nextLine(10);
        doc.addItems(["Name", "Total"], { fontWeight: "bold" });
        service_items.forEach((item) => {
          doc.addItems([item.name, currency(item.total)]);
        });
        doc.addLine();
      }
      // Gross Receipts Tax
      doc.addContent("Gross Receipts Tax", { fontWeight: "bold" });
      doc.nextLine(10);
      doc.addItems(["Name", "Tax", "Net Sales", "Gross Sales", "Tax Rate"], {
        fontWeight: "bold",
      });
      getTaxItems(this.items).forEach((item) => {
        let list = [];
        list.push(item.name);
        list.push(currency(item.value));
        list.push(currency(item.net));
        list.push(currency(item.value + item.net));
        list.push(`${item.percentage}%`);
        doc.addItems(list);
      });
      // Ending
      doc.addLine(10);
      doc.addContent("Created: " + new Date().toLocaleString());
      doc.save("sales_report");

      function addItem(item, currency) {
        if (!item.amount && !item.mandatory) return;
        let amount = Math.round(item.amount * 100) / 100;
        let text = currency(amount);
        if (item.currency === false) text = String(amount);
        const fontWeight = item.bold ? "bold" : "normal";
        doc.addItems([item.name, text], { fontWeight });
      }
    },
  },
};

function getCounts(items) {
  const total = items?.reduce((a, b) => a + b.orders.total, 0);
  const open = items?.reduce((a, b) => a + b.orders.open, 0);
  const closed = items?.reduce((a, b) => a + b.orders.closed, 0);
  const canceled = items?.reduce((a, b) => a + b.orders.canceled, 0);
  let result = [
    {
      name: "Total Orders",
      amount: total,
      bold: true,
      currency: false,
      mandatory: true,
    },
  ];
  result.push({ name: "Open", amount: open, currency: false });
  result.push({ name: "Closed", amount: closed, currency: false });
  result.push({ name: "Canceled", amount: canceled, currency: false });
  return result;
}
function getSales(items) {
  const total = items?.reduce((a, b) => a + b.sales.total, 0);
  const net = items?.reduce((a, b) => a + b.sales.net, 0);
  const tax = items?.reduce((a, b) => a + b.sales.tax, 0);
  const tip = items?.reduce((a, b) => a + b.sales.tip, 0);
  const fee = items?.reduce((a, b) => a + b.sales.fee, 0);
  const third_party = items?.reduce((a, b) => a + b.sales.third_party, 0);
  const gift = items?.reduce((a, b) => a + b.sales.gift, 0);
  const adjustment = items?.reduce((a, b) => a + b.sales.adjustment, 0);
  const deduction = items?.reduce((a, b) => a + b.sales.deduction, 0);
  const selflane = items?.reduce((a, b) => a + b.sales.selflane, 0);
  let result = [
    { name: "Total Receivables", amount: total, bold: true, mandatory: true },
  ];
  result.push({ name: "Net Sales", amount: net });
  result.push({ name: "Tax", amount: tax });
  result.push({ name: "Tip", amount: tip });
  result.push({ name: "Fee (in-store)", amount: fee });
  result.push({ name: "Third Party", amount: third_party });
  result.push({ name: "Gift Sold", amount: gift });
  result.push({ name: "Cash Discount Adjustment", amount: adjustment });
  result.push({ name: "Deduction", amount: deduction });
  result.push({ name: "Selflane Charges", amount: selflane });
  return result;
}
function getProfitLoss(items, biz) {
  const mapped = items.flatMap((o) => o.product_mix?.courses);
  const groups = _.chain(mapped)
    .map((o) => {
      const course = biz?.courses?.find((c) => c._id === o.id);
      return {
        name: course?.grp || "Unknown",
        amount: o.amount,
      };
    })
    .groupBy("name")
    .map((value, name) => {
      const amount = value.reduce((a, b) => a + b.amount, 0);
      return { name, amount };
    })
    .sortBy("name")
    .value();
  const total = groups.reduce((a, b) => a + b.amount, 0);
  groups.unshift({
    name: "Profit & Loss",
    amount: total,
    bold: true,
    mandatory: true,
  });
  return groups;
}
function getDiscounts(items) {
  const total = items?.reduce((a, b) => a + b.discount.total, 0);
  const mapped = items.flatMap((o) => o.discount.items);
  const groups = _.chain(mapped)
    .groupBy("name")
    .map((value, name) => {
      const amount = value.reduce((a, b) => a + b.amount, 0);
      const count = value.length;
      const title = `${count} x ${name}`;
      return { name, amount, title };
    })
    .sortBy("name")
    .value();

  let result = [
    { name: "Total Discount", amount: total, bold: true, mandatory: true },
  ];
  groups.forEach((item) => {
    result.push({ name: item.title, amount: item.amount });
  });
  return result;
}
function getPayments(items) {
  const total = items?.reduce((a, b) => a + b.charge.total, 0);
  let result = [
    { name: "Total Received", amount: total, bold: true, mandatory: true },
  ];
  const flattened = items.flatMap((o) => o.charge.items);
  const charges = _.chain(flattened)
    .groupBy("method")
    .map((value, key) => {
      const method = key;
      const title = value[0].title;
      const amount = value.reduce((a, b) => a + b.amount, 0);
      return { method, title, amount };
    })
    .sortBy("title")
    .value();
  charges.forEach((charge) => {
    result.push({ name: charge.title, amount: charge.amount });
  });
  const cash = items?.reduce((a, b) => a + b.charge.cash, 0);
  const tip = items?.reduce((a, b) => a + b.charge.tip_total, 0);
  const diff = cash - tip;
  result.push({ name: "Deposit (Cash - Tip)", amount: diff, bold: true });
  return result;
}
function addCards(items) {
  const total = items?.reduce((a, b) => a + b.charge.card, 0);
  if (!total) return [];
  let result = [
    { name: "Received Cards", amount: total, bold: true, mandatory: true },
  ];
  const flattened = items.flatMap((o) => o.charge.brands);
  const cards = _.chain(flattened)
    .groupBy("method")
    .map((value, title) => {
      const amount = value.reduce((a, b) => a + b.amount, 0);
      return { title, amount };
    })
    .sortBy("title")
    .value();
  cards.forEach((card) => {
    result.push({ name: card.title, amount: card.amount });
  });
  const count = flattened.reduce((a, b) => a + b.count, 0);
  const perSwipe = Math.round((total / count) * 100) / 100;
  result.push({ name: "Swipes", amount: count, currency: false });
  result.push({ name: "Per Swipe", amount: perSwipe });
  return result;
}
function getServiceItems(items) {
  return _.chain(items)
    .map("service_items")
    .flatten()
    .groupBy("name")
    .map((list, name) => {
      const total = list.reduce((a, b) => a + b.value, 0);
      return { name, total };
    })
    .sortBy("name")
    .value();
}
function getTaxItems(items) {
  return _.chain(items)
    .map("tax_items")
    .flatten()
    .groupBy("name")
    .map((list, name) => {
      const net = list.reduce((a, b) => a + b.net, 0);
      const value = list.reduce((a, b) => a + b.value, 0);
      return {
        name,
        net,
        value,
        percentage: list[0].percentage,
        action_type: list[0].action_type,
      };
    })
    .sortBy("name")
    .value();
}
</script>