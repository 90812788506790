<template lang="pug">
.pa-3
  .sl-row
    img.mr-3(:src="icon", height="44")
    .title eGift Card
  .d-flex.flex-row.align-center(v-if="!biz.orderType.gc.status")
    .mr-3 Do you want to turn on eGift Card?
    v-btn(
      rounded,
      color="secondary",
      @click="submit()",
      small,
      :loading="loading"
    ) Yes
  div(v-else)
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      icon: "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fgift-card.png?alt=media&token=83d2a0ff-1048-4c87-9f5d-76d2e3406e35",
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    async submit() {
      if (!this.biz) return;
      const action = { $set: { "orderType.gc": { status: true } } };
      this.loading = true;
      try {
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
