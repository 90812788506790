<template lang="pug">
v-card.my-3(:width="maxWidth", flat, :loading="loading")
  v-toolbar(dense, flat)
    .subtitle-2 Labor Report
    v-spacer
    .subtitle-2(v-if="spmh") SMPH {{ spmh | currency }}
    v-btn-toggle(v-model="mode", dense, rounded, color="secondary")
      v-btn(
        :value="item",
        v-for="(item, index) in mode_items",
        :key="index",
        x-small
      ) {{ item }}
  ByRoles(
    v-if="mode == 'roles'",
    :report="report",
    :tip="tip",
    :isDecimal="isDecimal",
    :showWage="showWage"
  )
  ByEmoloyees(
    v-else,
    :report="report",
    :tip="tip",
    :isDecimal="isDecimal",
    :showWage="showWage"
  )
</template>

<script>
import { mapGetters } from "vuex";
import ByEmoloyees from "./ByEmployees";
import ByRoles from "./ByRoles";

export default {
  name: "LaborReport",
  components: { ByRoles, ByEmoloyees },
  props: {
    sales: { type: Number, default: 0 }, // total net sales over the report period
    tip: { type: Number, default: 0 }, // total tips over the report period
    isDecimal: { type: Boolean, default: false },
    maxWidth: { type: String, default: "500" },
    defaultMode: { type: String, default: "roles" },
  },
  data() {
    return {
      report: null,
      loading: false,
      mode: this.defaultMode || "roles",
      mode_items: ["roles", "employees"],
    };
  },
  computed: {
    ...mapGetters(["useraccess", "biz"]),
    access() {
      if (!this.$auth.check() || !this.biz) return null;
      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      return this.useraccess?.find((o) => o.user == userId && o.biz == bizId);
    },
    showWage() {
      return this.access?.privilege == 1 || this.access?.access_wage;
    },
    spmh() {
      if (!this.sales || !this.report?.hours) return 0;
      return this.sales / this.report.hours;
    },
  },
  methods: {
    async load(params) {
      if (!params) return;
      this.loading = true;
      this.report = await this.$api.punchcard.custom("laborReport", params);
      this.loading = false;
    },
  },
};
</script>
