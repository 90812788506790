<template lang="pug">
v-col(:cols="cols", :sm="sm", :md="md")
  v-card.overflow-y-auto(flat, max-height="220px", height="100%")
    simple-table
      thead
        tr
          th Profit & Loss
          th.text-right {{ total | currency }}
      tbody
        tr(v-for="(item, index) in groups", :key="index + 'group'")
          th {{ item.name }}
          td.text-right {{ item.amount | currency }}
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  props: {
    items: { type: Array, default: () => [] },
    cols: { type: Number, default: 12 },
    md: { type: Number, default: 4 },
    sm: { type: Number, default: 6 },
  },
  computed: {
    ...mapGetters(["biz"]),
    groups() {
      const items = this.items.flatMap((o) => o.product_mix?.courses);
      return _.chain(items)
        .map((o) => {
          const course = this.biz.courses.find((c) => c._id === o.id);
          return {
            name: course?.grp || "Unknown",
            amount: o.amount,
          };
        })
        .groupBy("name")
        .map((value, name) => {
          const amount = value.reduce((a, b) => a + b.amount, 0);
          return { name, amount };
        })
        .sortBy("name")
        .value();
    },
    total() {
      return this.groups.reduce((a, b) => a + b.amount, 0);
    },
  },
};
</script>