const state = {
  pos_devices: [], // access defined by user
}

const getters = {
  pos_devices: state => state.pos_devices,
}

const actions = {
  setPOSDevices: ({
    commit
  }, data) => {
    commit('setPOSDevices', data)
  },
}

const mutations = {
  setPOSDevices(state, data) {
    state.pos_devices = data
  },
}

export default { state, getters, actions, mutations }