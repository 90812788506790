<template lang="pug">
v-card(v-if="biz && biz.catering", flat)
  v-toolbar(dense, flat)
    .subtitle-2 Catering
    v-spacer
    Form
  simple-table
    tbody
      tr
        th Status
        td.text-right {{ biz.catering.status ? "On" : "Off" }}
      tr
        th Minimum Purchase
        td.text-right {{ biz.catering.minimum_purchase | currency }}
      tr
        th Effective Purchase
        td.text-right {{ biz.catering.effective_purchase | currency }}
      tr
        th Lead time
        td.text-right {{ biz.catering.time.value }} {{ biz.catering.time.unit }}
          span.ml-1(v-if="biz.catering.time.unit == 'day'") before {{ biz.catering.time.cutoff }}:00
      tr
        th Max days
        td.text-right {{ biz.catering.max_days }} Days
  v-card-text
    div Any order with subtotal (before tip and tax) above the effective purchase value would be automatically categorized as a catering order. This would prevent any big order coming in without enough time to work on them.
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  computed: { ...mapGetters(["biz"]) },
};
</script>