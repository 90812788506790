<template lang="pug">
v-data-table(
  :headers="headers",
  :items="members",
  @click:row="show",
  role="button",
  dense,
  :search="search",
  sort-by="created",
  sort-desc
)
  template(v-slot:item.created="{ item }")
    span {{ item.created | date }}
  template(v-slot:item.name="{ item }")
    span {{ item.name | fullname }}
  template(v-slot:item.phone="{ item }")
    span {{ item.phone | phone }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["search"],
  data() {
    return {
      headers: [
        { text: "Created", value: "created", filtable: false },
        { text: "Last N.", value: "name.last" },
        { text: "First N.", value: "name.first" },
        { text: "Phone", value: "phone" },
        { text: "Balance", value: "balance", align: "end", filtable: false },
        { text: "Lifetime", value: "amount", align: "end", filtable: false },
        { text: "Orders", value: "count", align: "end", filtable: false },
      ],
    };
  },
  computed: { ...mapGetters(["members"]) },
  methods: {
    ...mapActions(["setMember"]),
    show(item) {
      this.$emit("show", item);
    },
  },
};
</script>
