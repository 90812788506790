import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import biz from './modules/biz'
import bizmodifiers from './modules/bizmodifiers'
import expSource from './modules/expSource'
import matrixmodifier from './modules/matrixmodifier'
import pricings from './modules/pricings'
import promotions from './modules/promotions'
import punchcards from './modules/punchcards'
import rewards from './modules/rewards'
import vouchers from './modules/vouchers'
import weeklyspecial from './modules/weeklyspecial'

import pos from './modules/pos'
import refunds from './modules/refunds'
import reviews from './modules/reviews'
import servershifts from './modules/servershifts'

import events from './modules/Event/events'

import combos from './modules/combos'
import memberpromo from './modules/memberpromo'
import members from './modules/members'
import membertier from './modules/membertier'
import pointvoucher from './modules/pointvoucher'
import serverchecklist from './modules/serverchecklist'

import giftcards from './modules/giftcards'

import access from './modules/Business/access'
import bizAccounts from './modules/Business/accounts'
import posDevices from './modules/Business/devices'
import bizDocuments from './modules/Business/documents'
import bizExpenses from './modules/Business/expenses'
import bizPurchases from './modules/Business/purchases'

/// Stripe
import stripeAccount from './modules/Stripe/account'
import stripeBanks from './modules/Stripe/banks'
import stripePersons from './modules/Stripe/persons'
import stripeSources from './modules/Stripe/sources'

import giftProgram from './modules/Gift/program'

import influencerAccounts from './modules/Influencer/accounts'
import influencerPlans from './modules/Influencer/plans'

import corp from './modules/Corp/index'
import menu from './modules/Menu/index'

import announcement from './modules/announcement'

/// libs
import orders from '/libs/store/biz/orders'
import bizservices from '/libs/store/bizservices'
import press_reports from '/libs/store/company/press_reports'
import crm from '/libs/store/crm'
import dev from '/libs/store/dev'
import entp from '/libs/store/entp'
import hr from '/libs/store/hr'
import invoices from '/libs/store/invoices'
import conversation from '/libs/store/order/conversation'
import sales_force from '/libs/store/sales_force/index'
import seven from '/libs/store/seven'

Vue.use(Vuex)

let modules = {
  orders,
  expSource,
  biz,
  punchcards,
  bizmodifiers,
  matrixmodifier,
  pricings,
  vouchers,
  promotions,
  rewards,
  weeklyspecial,
  servershifts,
  pos,
  reviews,
  refunds,
  events,
  members,
  membertier,
  pointvoucher,
  combos,
  serverchecklist,
  memberpromo,
  giftcards,
  bizExpenses,
  bizPurchases,
  access,
  posDevices,
  bizAccounts,
  bizDocuments,
  stripeSources,
  stripeAccount,
  stripeBanks,
  stripePersons,
  giftProgram,
  influencerPlans,
  influencerAccounts,
  menu,
  corp,
  announcement,
  conversation,
  // libs
  press_reports, sales_force, crm, dev,
  hr, entp, invoices, seven,
  bizservices
}

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules
})
export default store