<template lang="pug">
v-data-table.my-3(
  :headers="headers",
  :items="items",
  :search="searchText",
  sort-by="modifier.name",
  @click:row="edit",
  role="button",
  mobile-breakpoint="0",
  :items-per-page="itemsPerPage"
)
  template(v-slot:item.choices="{ item }")
    span {{ item.modifier | modifierChoices }}
  template(v-slot:item.apply="{ item }")
    span(v-html="$options.filters.dishNames(item.dishes, biz)")
  template(v-slot:item.actions="{ item }")
    .d-flex.flex-row
      v-btn(icon, small, @click.stop="toggleRequired(item)", title="required")
        v-icon.green--text(small, v-if="item.modifier.required") mdi-check
        v-icon.grey--text.text--lighten-1(small, v-else) mdi-check
      v-btn(
        icon,
        small,
        @click.stop="toggleOL(item)",
        title="online availability"
      )
        v-icon.green--text(small, v-if="item.modifier.ol") mdi-earth
        v-icon.grey--text.text--lighten-1(small, v-else) mdi-earth
    .d-flex.flex-row
      v-btn(icon, small, @click.stop="clone(item)", title="duplicate")
        v-icon.grey--text.text--darken-2(small) mdi-content-copy
      v-btn(icon, small, @click.stop="handleRemove(item)")
        v-icon.red--text(small) mdi-delete
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  props: {
    items: { type: Array, required: true },
    searchText: { type: String, default: "" },
  },
  data() {
    return {
      itemsPerPage: 20,
      headers: [
        { text: "Title", value: "modifier.name" },
        { text: "Choices", value: "choices", sortable: false },
        { text: "Apply to", value: "apply", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz", "bizmodifiers"]),
  },
  methods: {
    ...mapActions(["updateBizmodifier", "removeBizmodifier"]),
    async toggleRequired(item) {
      if (!item?._id) return;
      const value = !(item.modifier?.required === true);
      const params = {
        criteria: { _id: item._id },
        action: { $set: { "modifier.required": value } },
      };
      const data = await this.$api.bizModifier.update(params);
      this.updateBizmodifier(data);
    },
    async toggleOL(item) {
      if (!item?._id) return;
      const value = !(item.modifier?.ol === true);
      const params = {
        criteria: { _id: item._id },
        action: { $set: { "modifier.ol": value } },
      };
      const data = await this.$api.bizModifier.update(params);
      this.updateBizmodifier(data);
    },
    handleRemove(item) {
      confirm("Do you want to delete this modifier?") && this.remove(item);
    },
    async remove(item) {
      if (!item?._id) return;
      const params = { criteria: { _id: item._id } };
      try {
        const result = await this.$api.bizModifier.delete(params);
        this.removeBizmodifier(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    edit(item) {
      EventBus.$emit("edit-modifier", item);
    },
    clone(item) {
      const clone = JSON.parse(JSON.stringify(item));
      delete clone._id;
      if (clone?.modifier?._id) delete clone.modifier._id;
      EventBus.$emit("edit-modifier", clone);
    },
  },
};
</script>
