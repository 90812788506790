<template lang="pug">
v-card(:loading="loading", flat)
  simple-table
    thead
      tr
        th Period
        th Net Sales
        th Expense
        th Payroll
        th.text-right Balance
    tbody
      tr(v-for="(item, index) in items", :key="index")
        th {{ item.period }}
        td {{ item.orderValue | currency }}
        td {{ item.expenseValue | currency }}
        td {{ item.payrollValue | currency }}
        td.text-right(:class="{ 'red--text': item.balance < 0 }") {{ item.balance | currency }}
</template>

<script>
export default {
  props: ["items", "loading"],
};
</script>
