import Seating from './Seating/index'

import profile from './Profile/route'
import menu from './Menu/route'
import discount from './Discount/route'
import pos from './POS/route'
import share from './Share/route'

export default [
  ...profile, ...menu,
  ...discount,
  { path: '/tables', component: Seating, meta: { auth: true } },
  ...pos,
  ...share
]