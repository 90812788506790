<template lang="pug">
Page(title="Online Sales")
  template(v-slot:header)
    YearSelector(@select="load")
  Sales.my-5(:year="year")
  Transfers.my-5(ref="transfers")
  Payouts.my-5(:id="stripeAccountId")
  Note.my-5
</template>

<script>
import Sales from "./Sales/index";
import Transfers from "./Transfers/index";
import Payouts from "@/components/Owner/Payments/Settings/Stripe/Payouts/index";
import Note from "./Note";
import { mapGetters } from "vuex";

export default {
  components: { Sales, Transfers, Payouts, Note },
  data() {
    return {
      unit: "monthly",
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    stripeAccountId() {
      return this.biz?.payments?.managed_account;
    },
  },
  methods: {
    load(year) {
      this.year = year;
      this.$refs.transfers.load(year);
    },
  },
};
</script>
