<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card(v-if="expense")
    v-toolbar(flat, dense)
      .subtitle-2 {{ title }} Expense
      v-spacer
      .subtitle-2 Total: {{ total | currency }}
    v-divider
    v-form(@submit.prevent="submit")
      v-card-text
        v-row
          v-col(cols="6")
            DateFieldPicker(v-model="dateFormatted")
          v-col(cols="6")
            v-text-field(
              label="Source",
              v-model="expense.source",
              required,
              :error-messages="sourceErrors",
              @blur="$v.expense.source.$touch()"
            )
      simple-table
        thead
          tr
            th Category
            th Sub-Category
            th Amount
            th
        tbody
          tr(
            wrap,
            align="center",
            v-for="(item, index) in expense.breakdown",
            :key="index + 'index'"
          )
            td
              v-text-field(dense, v-model="item.category", hide-details)
            td
              v-text-field(dense, v-model="item.subcate", hide-details)
            td
              v-text-field(
                dense,
                v-model="item.amount",
                type="number",
                min="0",
                step="0.01",
                prefix="$",
                hide-details
              )
            td
              v-btn(
                icon,
                small,
                v-if="expense.breakdown.length > 1",
                @click="removeBreakdown(index)"
              )
                v-icon(small, color="error") mdi-minus
              v-btn(
                icon,
                small,
                @click="addBreakdown()",
                v-if="index === expense.breakdown.length - 1"
              )
                v-icon(small, color="success") mdi-plus
      v-card-text
        v-btn(
          color="secondary",
          type="submit",
          block,
          :disabled="invalid || loading || errors.length > 0"
        ) save {{ total | currency }}
    .red--text(v-for="(item, index) in errors", :key="index + 'index'") {{ item }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  props: ["input"],
  validations: {
    expense: {
      source: {
        required,
        maxLength: maxLength(20),
      },
    },
  },
  data() {
    return {
      dialog: false,
      expense: {},
      dateMenu: false,
      loading: false,
      dateFormatted: "",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      if (this.input?._id) return "Edit";
      return "Create";
    },
    total() {
      return (
        this.expense?.breakdown?.reduce((a, b) => a + Number(b.amount), 0) || 0
      );
    },
    sourceErrors() {
      const errors = [];
      if (!this.$v.expense.source.$dirty) return errors;
      !this.$v.expense.source.required && errors.push("Required");
      !this.$v.expense.source.maxLength &&
        errors.push("Should be less than 20 charactors");
      return errors;
    },
    invalid() {
      return this.$v.$invalid || this.total <= 0;
    },
    errors() {
      const data = [];
      if (!this.expense) {
        data.push("Null expense");
        return data;
      }
      if (!this.expense.date) data.push("Missing date");
      if (!this.expense.biz) data.push("Missing biz");
      if (!this.expense.bookkeeper || !this.expense.bookkeeper.id)
        data.push("Missing bookkeeper");
      const found = this.expense?.breakdown?.find(
        (o) => o.amount > 0 && !o.category
      );
      if (found) data.push("Missing category for amount $" + found.amount);
      return data;
    },
  },
  mounted() {
    EventBus.$on("edit-expense", this.open);
    EventBus.$on("create-expense", this.open);
  },
  destroyed() {
    EventBus.$off("edit-expense", this.open);
    EventBus.$off("create-expense", this.open);
  },
  methods: {
    ...mapActions(["addExpense", "updateExpense"]),
    open(data) {
      const user = this.$auth.user();
      if (!user || !this.biz) return;
      if (!data) {
        this.expense = {
          biz: this.biz._id,
          date: moment().unix() * 1000,
          bookkeeper: { id: user._id, name: user.name.preferred },
          source: "",
          total: 0,
          breakdown: [{ amount: 0, category: "", subcate: "" }],
        };
      } else {
        this.expense = JSON.parse(JSON.stringify(data));
      }
      this.dateFormatted = moment(this.expense.date).format("YYYY-MM-DD");
      this.dialog = true;
      this.$v.$reset();
    },
    addBreakdown() {
      this.expense.breakdown.push({
        amount: 0,
        category: "",
        subcate: "",
      });
    },
    removeBreakdown(index) {
      this.expense.breakdown.splice(index, 1);
    },
    async submit() {
      if (!this.expense) return;
      this.loading = true;
      this.expense.date = moment(this.dateFormatted).valueOf();
      this.expense.total = this.total;
      this.expense.breakdown = this.expense.breakdown.filter((o) => !o.amount);

      try {
        if (this.expense._id) {
          const params = {
            criteria: { _id: this.expense._id },
            action: { $set: this.expense },
          };
          const result = await this.$api.expense.entry.update(params);
          this.updateExpense(result);
        } else {
          const result = await this.$api.expense.entry.create(this.expense);
          this.addExpense(result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
