<template lang="pug">
div
  Cell.mb-3(:item="item", v-for="(item, index) in sorted", :key="index")
</template>

<script>
import { mapGetters } from "vuex";
import Cell from "./Cell/index";

export default {
  components: { Cell },
  computed: {
    ...mapGetters(["events"]),
    sorted() {
      return this.events?.toSorted(
        (a, b) => b.event_time?.begin - a.event_time?.begin
      );
    },
  },
};
</script>