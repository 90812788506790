<template lang="pug">
div
  v-card(flat)
    v-toolbar(dense, flat)
      IconBtn(@click="edit()", title="Checklist", icon="mdi-plus")
    simple-table
      tbody
        tr(
          v-for="(item, index) in serverchecklists",
          :key="index",
          @click.stop="edit(item)",
          role="button"
        )
          td {{ item.roles.join(", ") }}
          td
            ul
              li(v-for="(item, index) in item.checklist", :key="index") {{ item }}
          td.text-right
            v-btn(icon, small, @click.stop="handleRemove(item)")
              v-icon(small, color="error") mdi-delete
    v-card-text Upon <b>clocking out</b> on the POS app, staff members are presented with pop-up <b>checklists</b>. These checklists serve as worksheets, guiding the staff to complete specific tasks before concluding their shifts and leaving their jobs.
  Form
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Roles", sortable: false },
        { text: "Checklist", sortable: false },
        { text: "Action", sortable: false },
      ],
    };
  },
  computed: { ...mapGetters(["biz", "serverchecklists"]) },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setServerChecklists", "removeServerChecklist"]),
    async load() {
      if (!this.biz) return;
      this.loading = true;
      const params = { criteria: { biz: this.biz._id } };
      try {
        const result = await this.$api.serverChecklist.list(params);
        this.setServerChecklists(result);
      } catch (e) {
        //
      }
      this.loading = false;
    },
    edit(item) {
      EventBus.$emit("edit-server-checklist", item);
    },
    handleRemove(item) {
      confirm("Do you want to remove this checklist?") && this.remove(item);
    },
    async remove(item) {
      const params = { criteria: { _id: item._id } };
      try {
        await this.$api.serverChecklist.delete(params);
        this.removeServerChecklist(item);
      } catch (e) {
        //
      }
    },
  },
};
</script>
