// Set Printers
<template lang="pug">
.dish-print
  v-btn-toggle(
    dense,
    v-model="item.prints",
    color="secondary",
    multiple,
    @change="toggle(item)"
  )
    v-btn(
      x-small,
      @click.stop,
      v-for="(item, index) in printItems",
      :key="index",
      :value="item.value",
      :title="item.title"
    )
      v-icon(small) {{ item.icon }}
      span.ml-1(v-if="item.text") {{ item.text }}
  PrintingGroup(:item="item")
  v-btn(
    icon,
    small,
    @click.stop="toggleUnderline(item)",
    v-if="item",
    title="Print with Underline",
    :loading="loading"
  )
    v-icon(small, :color="color") mdi-format-underline
</template>

<script>
import { mapGetters } from "vuex";
import PrintingGroup from "./PrintingGroup";

export default {
  components: { PrintingGroup },
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz", "printItems"]),
    color() {
      if (this.item?.ul) return "success";
      return "grey lighten-1";
    },
  },
  methods: {
    async toggle() {
      if (!this.biz || !this.item?._id) return;
      const params = {
        criteria: { _id: this.biz._id, "dishes._id": this.item._id },
        action: { $set: { "dishes.$.prints": this.item.prints } },
      };
      const data = await this.$api.biz.update(params);
      this.$store.dispatch("setBiz", data);
    },
    async toggleUnderline() {
      if (!this.biz || !this.item?._id) return;
      const value = !(this.item.ul == true);
      const params = {
        criteria: { _id: this.biz._id, "dishes._id": this.item._id },
        action: { $set: { "dishes.$.ul": value } },
      };
      this.loading = true;
      const data = await this.$api.biz.update(params);
      this.$store.dispatch("setBiz", data);
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.dish-print
  display: flex
  flex-direction: row
  align-items: center
  gap: 0.3rem
</style>