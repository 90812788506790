const state = {
  bizmodifiers: []
}

const getters = {
  bizmodifiers: state => state.bizmodifiers
}

const actions = {
  setBizmodifiers: ({ commit }, data) => { commit('setBizmodifiers', data) },
  updateBizmodifier: ({ commit }, data) => { commit('updateBizmodifier', data) },
  addBizmodifier: ({ commit }, data) => { commit('addBizmodifier', data) },
  removeBizmodifier: ({ commit }, data) => { commit('removeBizmodifier', data) }
}

const mutations = {
  setBizmodifiers(state, data) {
    state.bizmodifiers = data
  },
  updateBizmodifier(state, data) {
    state.bizmodifiers = state.bizmodifiers.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addBizmodifier(state, data) {
    state.bizmodifiers.push(data)
  },
  removeBizmodifier(state, data) {
    state.bizmodifiers = state.bizmodifiers.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }