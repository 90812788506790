<template lang="pug">
Page(title="Point of Sale", :items="items", :doc="doc")
  LoadPOS
</template>

<script>
import LoadPOS from "./LoadPOS";

export default {
  components: { LoadPOS },
  data() {
    return {
      items: [
        { title: "Settings", url: "/pos/settings" },
        { title: "To Go", url: "/pos/togo" },
        { title: "Printing", url: "/pos/printing" },
        { title: "Gift", url: "/pos/gift" },
        { title: "QSR", url: "/pos/qsr" },
        { title: "Discounts", url: "/pos/discounts" },
        { title: "Cash Discount", url: "/pos/cash_discount" },
        { title: "Payment", url: "/pos/payment" },
      ],
      doc: "https://docs.selflane.com/biz/settings/pos/",
    };
  },
};
</script>
