<template lang="pug">
.dish-price
  div {{ item.price | currency }}
  .success--text.caption.font-weight-medium(v-if="item.onlineUpcharge") +{{ item.onlineUpcharge | currency }}
  .secondary--text.caption.font-weight-medium(v-if="item.thirdPartyUpcharge") +{{ item.thirdPartyUpcharge | currency }}
  div(v-if="item.uom") /{{ item.uom }}
</template>

<script>
export default {
  props: { item: { type: Object, required: true } },
};
</script>

<style lang="sass" scoped>
.dish-price
  display: flex
  flex-direction: row
  gap: 0.3rem
</style>
