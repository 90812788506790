<template lang="pug">
div
  v-progress-linear(v-if="loading", indeterminate, color="secondary")
  v-list(dense)
    v-list-item(v-for="(item, index) in stripeSources", :key="index")
      v-list-item-content {{ item.brand }} ...{{ item.last4 }} {{ item.name }}
      v-list-item-action Exp {{ item.exp_month }}/{{ item.exp_year }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    id: { type: String, required: true },
  },
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["stripeSources"]) },
  methods: {
    ...mapActions(["setStripeSources"]),
    async load() {
      if (!this.id) {
        this.setStripeSources([]);
        return;
      }
      this.loading = true;
      const params = { customerId: this.id };
      const result = await this.$api.stripe.source.list(params);
      this.setStripeSources(result);
      this.loading = false;
    },
  },
};
</script>
