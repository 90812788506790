<template lang="pug">
div
  div(v-if="accessible")
    slot
  div(v-else)
    v-card(flat)
      v-card-text
        .subtitle-2 You need to upgrade your plan to access this page
        .subtitle-2 Your current plan: {{ current_level }}
        v-dialog(v-model="dialog", width="500")
          template(v-slot:activator="{ on }")
            v-btn.my-8(v-on="on", color="secondary") Upgrade Plan
          v-card
            v-card-title Upgrade Plan
            v-card-text
              p.subtitle-2 You need to upgrade your plan to access this page
              p.subtitle-2 Your current plan: {{ current_level }}
              p The minimum plan required to access this page is <b>{{ min_level_name }}</b> plan.
              p You can upgrade your plan to any of the following plans:
              p To learn more about our plans, visit our
                a.ml-1(:href="url") pricing page
            v-list
              UpgradeCell(
                v-for="level in next_levels",
                :key="level.level",
                :item="level",
                :bizservice="bizservice",
                @upgraded="dialog = false"
              )
        p To learn more about our plans, visit our
          a.ml-1(:href="url") pricing page
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import UpgradeCell from "./UpgradeCell";

export default {
  components: { UpgradeCell },
  props: {
    min_level: { type: Number, default: 0 }, // minimum level required to access this page
  },
  data() {
    return {
      dialog: false,
      url: "https://next.selflane.com/products/pricing",
      level_names: [
        { level: 1, name: "POS" },
        { level: 2, name: "Essential" },
        { level: 3, name: "Power" },
        { level: 4, name: "Premium" },
      ],
    };
  },
  computed: {
    ...mapGetters(["bizservice", "biz"]),
    accessible() {
      if (this.min_level === 0) return true;
      if (!this.bizservice?.plan_level) return false;
      if (this.biz?.standing == "test") return true;
      return this.bizservice.plan_level >= this.min_level;
    },
    current_level() {
      const name = this.level_names.find(
        (x) => x.level === this.bizservice?.plan_level
      )?.name;
      if (name) return name;
      if (!this.bizservice?.pos?.status) return "POS";
      return "None";
    },
    next_levels() {
      return this.level_names.filter((x) => x.level >= this.min_level);
    },
    min_level_name() {
      return this.level_names.find((x) => x.level === this.min_level)?.name;
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  methods: {
    async load() {
      const bizId = this.biz?._id;
      if (!bizId) return;
      try {
        const service = await this.$api.service.account.retrieve({
          criteria: { biz: bizId },
        });
        this.$store.dispatch("setBizService", service);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>