<template lang="pug">
v-card(flat, v-if="logs && logs.length")
  v-toolbar(flat, dense)
    .subtitle-2 Back-office Editing
  simple-table
    thead
      tr
        th Time
        th Action
        th Employee
        th Time Entries
    tbody
      tr(v-for="(item, index) in logs", :key="index")
        td {{ item.time | datetime }}
        td(style="white-space: normal") {{ item.text }}
        td {{ item.employee }}
        td {{ item.clockIn | time }} - {{ item.clockOut | time }}
</template>

<script>
import _ from "underscore";
export default {
  props: ["items"],
  computed: {
    logs() {
      return _.chain(this.items)
        .map((o) => {
          return o.log.map((item) => {
            item.employee = o.employee;
            item.clockIn = o.clockIn;
            item.clockOut = o.clockOut;
            return item;
          });
        })
        .flatten()
        .value();
    },
  },
};
</script>
