
<template lang="pug">
simple-table(v-if="tableData")
  thead
    tr
      th.nowrap(v-for="(item, index) in tableData.header", :key="index + 'h'") {{ item }}
  tbody
    tr(v-for="(row, index) in tableData.items", :key="index + 'r'")
      td.nowrap {{ row.title }}
      td(v-for="(item, index) in row.items", :key="index + 'i'") {{ item | currency }}
  tfoot
    tr
      th.nowrap {{ tableData.footer.title }}
      th(v-for="(item, index) in tableData.footer.items", :key="index + 'f'") {{ item | currency }}
</template>

<script>
// import SimpleTable from "./SimpleTable";
export default {
  // components: { SimpleTable },
  props: {
    tableData: { type: Object, default: () => ({}) },
  },
};
</script>