<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="member")
    v-toolbar(flat, dense)
      .subtitle-2 Member Info
      v-spacer
      v-btn(
        @click="$emit('showActivity', member)",
        small,
        depressed,
        color="secondary"
      ) Activities
    simple-table
      tbody
        tr
          th Name
          td.text-right {{ member.name | fullname }}
        tr
          th Phone
          td.text-right {{ member.phone | phone }}
        tr
          th Email
          td.text-right {{ member.email | partialemail }}
        tr
          th Birthday
          td.text-right
            span(v-if="member.bd?.mm") {{ member.bd?.mm | month(true) }} {{ member.bd?.dd }}
        tr
          th Anniversary
          td.text-right
            span(v-if="member.anniv?.mm") {{ member.anniv?.mm | month(true) }} {{ member.anniv?.dd }}
        tr
          th Note
          td.text-right {{ member.note }}
        tr
          th Balance Points
          td.text-right {{ member.balance }} PTS
        tr
          th Lifetime Points
          td.text-right {{ member.amount }} PTS
        tr
          th # Orders
          td.text-right {{ member.count }}
        tr
          th Last Visit
          td.text-right {{ member.last | date({ dynamic: true }) }}
        tr
          th Created
          td.text-right {{ member.created | date({ dynamic: true }) }}
            v-chip.ml-2(x-small, v-if="member.user") S
    v-card-text
      ClickToCopy(:text="member._id")
    v-card-actions
      .link-box
        IconBtn(@click="editBasic()", title="Basic Info", icon="mdi-pencil")
        IconBtn(@click="editPhone()", title="Phone", icon="mdi-pencil")
        IconBtn(@click="editEmail()", title="Email", icon="mdi-pencil")
        v-spacer
        DeleteBtn(@deleted="dialog = false")
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions, mapGetters } from "vuex";
import DeleteBtn from "./DeleteBtn";

export default {
  components: { DeleteBtn },
  data() {
    return { dialog: false, deleteDialog: false };
  },
  computed: { ...mapGetters(["member"]) },
  methods: {
    ...mapActions(["removeMember", "setMember", "updateMember"]),
    open(input) {
      if (!input) return;
      this.setMember(input);
      this.dialog = true;
    },
    editEmail() {
      EventBus.$emit("edit-member-email");
    },
    editPhone() {
      EventBus.$emit("edit-member-phone");
    },
    editBasic() {
      EventBus.$emit("edit-member-basic");
    },
    handleDelete() {
      this.deleteDialog = true;
    },
    async confirmDelete() {
      if (!this.member) return;
      const params = { id: this.member._id };
      try {
        const member = await this.$api.member.delete(params);
        this.removeMember(member);
        this.setMember();
        this.deleteDialog = false;
        this.dialog = false;
        this.$toast.success("Member deleted");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>