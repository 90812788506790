<template lang="pug">
.d-flex.flex-row.justify-center(v-if="posEditable")
  v-card(max-width="500", width="100%", flat)
    v-form(@submit.prevent="submit")
      v-toolbar(flat, dense)
        .subtitle-2 Gift Certificate Types
        v-spacer
        v-btn(outlined, small, @click="more()")
          span Add
          v-icon(small, right) mdi-plus
      simple-table
        thead
          tr
            th Name
            th Require ID
            th 
        tbody
          tr(v-for="(item, index) in posEditable.gift", :key="index")
            td
              v-text-field(
                v-model.trim="item.name",
                autofocus,
                ref="focus",
                hide-details,
                single-line,
                @keydown.down="more()"
              )
            td
              v-checkbox(
                color="secondary",
                v-model="item.requireID",
                dense,
                hide-details
              )
            td.text-right
              v-btn(icon, @click="less(index)", small)
                v-icon(color="error") mdi-close
      v-card-actions(v-if="dirty")
        v-btn.white--text(color="secondary", type="submit", :loading="loading") Save
        v-spacer
        v-btn(text, @click="cancel()") Cancel
</template>

<script>
import _ from "underscore";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Name", sortable: false },
        { text: "Require ID", sortable: false },
        { text: "", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["pos", "posEditable"]),
    dirty() {
      return JSON.stringify(this.posEditable) !== JSON.stringify(this.pos);
    },
  },
  methods: {
    ...mapActions(["setPOS", "resetEditable"]),
    more() {
      if (!this.posEditable.gift) {
        this.posEditable = _.extend(this.posEditable, {
          gift: { name: "", requireID: false },
        });
      }
      this.posEditable.gift.push({ name: "", requireID: false });
    },
    less(index) {
      if (this.posEditable.gift && this.posEditable.gift.length > index) {
        this.posEditable.gift.splice(index, 1);
      }
    },
    async submit() {
      this.loading = true;
      try {
        const action = {
          $set: { gift: this.posEditable.gift },
        };
        const result = await this.$api.pos.put(this.pos?._id, { action });
        this.setPOS(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    cancel() {
      this.resetEditable();
    },
  },
};
</script>
