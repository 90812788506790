<template lang="pug">
v-col(:cols="cols", :sm="sm", :md="md", v-if="total")
  v-card(flat, height="100%")
    simple-table
      thead
        AmountTableRow(name="Received Cards", :amount="total", bold, mandatory)
      tbody
        AmountTableRow(
          v-for="(item, index) in charges",
          :key="index",
          :name="item.method",
          :amount="item.amount"
        )
        AmountTableRow(name="Swipes", :amount="count", :currency="false")
        AmountTableRow(name="Per Swipe", :amount="perSwipe")
</template>

<script>
import _ from "underscore";

export default {
  props: {
    items: { type: Array, default: () => [] },
    cols: { type: Number, default: 12 },
    md: { type: Number, default: 4 },
    sm: { type: Number, default: 6 },
  },
  computed: {
    total() {
      return this.items?.reduce((a, b) => a + b.charge.card, 0);
    },
    count() {
      const brands = this.items.flatMap((o) => o.charge.brands);
      return brands.reduce((a, b) => a + b.count, 0);
    },
    perSwipe() {
      return Math.round((this.total / this.count) * 100) / 100;
    },
    charges() {
      const items = this.items.flatMap((o) => o.charge.brands);
      return _.chain(items)
        .groupBy("method")
        .map((value, method) => {
          const amount = value.reduce((a, b) => a + b.amount, 0);
          return { method, amount };
        })
        .sortBy("method")
        .value();
    },
  },
};
</script>