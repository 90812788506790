<template lang="pug">
v-dialog(v-model="dialog", max-width="550", @keydown.esc="dialog = false")
  v-card
    v-card-text
      v-form(@submit.prevent="submit", v-if="pricing")
        .d-flex.flex-row.align-center
          .sl-title Type
          v-spacer
          div(style="width: 250px")
            v-select(v-model="pricing.type", :items="types")
        .sl-dash-divider.mb-2
        div(v-if="pricing.type == 0")
          .d-flex.flex-row.align-center
            .sl-title Date/Time
            v-spacer
            v-col(align="end")
              DaysPicker(v-model="pricing.date")
              .d-flex.flex-row.mt-5
                TimeSelector(v-model="pricing.start", title="Start")
                TimeSelector(v-model="pricing.end", title="End")
          .sl-dash-divider.my-2
        .d-flex.flex-row.align-center
          .sl-title Price
          v-layout(column, align-end)
            div(style="width: 200px")
              v-select(v-model="type", :items="priceTypes")
            div(style="width: 200px")
              div(v-if="type == 1")
                v-text-field(
                  type="number",
                  v-model="pricing.amount",
                  prefix="$",
                  label="Fixed Price",
                  hide-details
                )
              div(v-if="type == 2")
                v-text-field(
                  type="number",
                  v-model="pricing.pct",
                  prefix="-",
                  suffix="%",
                  label="Percentage Discount",
                  hide-details
                )
              div(v-if="type == 3")
                v-text-field(
                  type="number",
                  v-model="pricing.reduce",
                  prefix="-$",
                  label="Amount Discount",
                  hide-details
                )
        .sl-dash-divider.my-2
        .d-flex.flex-row.align-center
          .sl-title Items
          v-layout(column, align-end)
            v-autocomplete(
              label="Applicable dishes",
              v-model="pricing.dishes",
              multiple,
              :items="dishes",
              small-chips,
              deletable-chips,
              dense,
              hide-details
            )
            v-select.my-2(
              label="Applicable courses",
              v-model="pricing.courses",
              multiple,
              :items="courses",
              small-chips,
              deletable-chips,
              dense,
              hide-details
            )
            .caption Don't select unless applying to an entire course
            v-select.my-2(
              label="Applicable menus",
              v-model="pricing.menus",
              multiple,
              :items="menus",
              small-chips,
              deletable-chips,
              dense,
              hide-details
            )
            .caption Don't select unless applying to an entire menu
        .sl-dash-divider.my-2
        .d-flex.flex-row.align-center
          .sl-title Exclude
          v-layout(column, align-end)
            div(style="width: 250px")
              v-select(
                label="Types",
                v-model="pricing.voids",
                multiple,
                :items="orderTypes",
                small-chips,
                deletable-chips,
                dense
              )
        .text-xs-right e.g., not applicable to pick up and delivery orders
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";
import { EventBus } from "@/event-bus.js";

export default {
  name: "ScheduledDiscountForm",
  data() {
    return {
      pricing: null,
      dialog: false,
      errors: [],
      loading: false,
      type: 1,
      types: [
        { text: "Auto", value: 0 },
        { text: "Manager Approve", value: 1 },
        { text: "Member/Auto", value: 2 },
        { text: "Member/Manager Approve", value: 3 },
      ],
      orderTypes: [
        { text: "Pick up", value: 1 },
        { text: "Delivery", value: 2 },
        { text: "Dine In", value: 0 },
      ],
      priceTypes: [
        { text: "Fixed Price", value: 1 },
        { text: "Percentage Discount", value: 2 },
        { text: "Amount Discount", value: 3 },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    endItems() {
      if (!this.pricing || !this.pricing.start) return this.items;
      const minutesToTime = this.$options.filters.minutesToTime;
      return _.map(_.range(this.pricing.start, 1470, 30), (o) => {
        return { value: o, text: minutesToTime(o) };
      });
    },
    menus() {
      return (
        this.biz?.menus?.map((o) => {
          return { text: o.name, value: o._id };
        }) || []
      );
    },
    courses() {
      return (
        this.biz?.courses?.map((o) => {
          return { text: o.name, value: o._id };
        }) || []
      );
    },
    dishes() {
      return (
        this.biz?.dishes?.map((o) => {
          return { text: o.name, value: o._id };
        }) || []
      );
    },
  },
  mounted() {
    EventBus.$on("edit-scheduled-discount", this.edit);
  },
  destroyed() {
    EventBus.$off("edit-scheduled-discount", this.edit);
  },
  methods: {
    ...mapActions(["addPricing", "updatePricing"]),
    edit(input) {
      this.pricing = JSON.parse(JSON.stringify(input));
      if (this.pricing.isPct) this.type = 2;
      else if (this.pricing.isReduce) this.type = 3;
      else this.type = 1;
      this.errors = [];
      this.dialog = true;
    },
    async submit() {
      this.errors = [];
      /// validate
      if (this.pricing.type == 0) {
        // if (!this.pricing.date || this.pricing.date.length === 0)
        //   this.errors.push("Select Date");
        if (this.pricing.start < 0 || this.pricing.end < this.pricing.start) {
          this.errors.push("Invalid start/end time");
        }
      }
      if (this.type == 1) {
        this.pricing.isPct = false;
        this.pricing.isReduce = false;
        if (this.pricing.amount <= 0) {
          this.errors.push("Fixed price should be a positive value");
        }
      } else if (this.type == 2) {
        this.pricing.isPct = true;
        this.pricing.isReduce = false;
        if (this.pricing.pct < -100 || this.pricing.pct > 100) {
          this.errors.push("Percentage discount should be between 0 and 100");
        }
      } else if (this.type == 3) {
        this.pricing.isPct = false;
        this.pricing.isReduce = true;
        if (this.pricing.reduce == 0) {
          this.errors.push("Invalid value for Amount Discount");
        }
      }
      if (this.errors.length > 0) return;
      this.loading = true;
      try {
        if (this.pricing._id) {
          const params = {
            criteria: { _id: this.pricing._id },
            action: { $set: this.pricing },
          };
          const res = await this.$api.pricing.update(params);
          this.updatePricing(res);
        } else {
          const res = await this.$api.pricing.create(this.pricing);
          this.addPricing(res);
        }
        this.dialog = false;
      } catch (e) {
        this.errors = [e.response?.data || e.message];
      }
      this.loading = false;
    },
  },
};
</script>
