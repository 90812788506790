<template lang="pug">
IconBtn(@click="downloadCSV()", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";

export default {
  props: {
    title: { type: String, default: "data" },
    tableData: { type: Object, default: () => ({}) },
  },
  methods: {
    downloadCSV() {
      const csv = new CSV();
      csv.addRow(this.tableData.header);
      this.tableData.items.forEach((row) => {
        const data = [row.title, ...row.items];
        csv.addRow(data);
      });
      const footer = [
        this.tableData.footer.title,
        ...this.tableData.footer.items,
      ];
      csv.addRow(footer);
      csv.save(this.title);
    },
  },
};
</script>

