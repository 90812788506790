<template lang="pug">
div(v-if="biz")
  v-list-item(
    to="/guide",
    v-if="access && access.privilege < 3 && access.privilege > 0"
  )
    v-list-item-action
      v-icon mdi-book-open-outline
    v-list-item-content
      v-list-item-title Guide
  v-list-group(
    v-for="(item, index) in items",
    v-model="item.active",
    :key="index",
    :prepend-icon="item.action",
    no-action
  )
    template(v-slot:activator)
      v-list-item-title {{ item.title }}
    v-list-item(
      v-for="subItem in item.items",
      :key="subItem.title",
      :to="subItem.to"
    )
      v-list-item-content
        v-list-item-title {{ subItem.title }}
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import NavGroups from "/libs/json/BizNav.json";
import NavChainGroups from "/libs/json/ChainNav.json";

export default {
  data() {
    return {
      bizGroups: JSON.parse(JSON.stringify(NavGroups)),
      chainGroups: JSON.parse(JSON.stringify(NavChainGroups)),
    };
  },
  computed: {
    ...mapGetters(["biz", "useraccess", "chainAccounts", "corpaccess"]),
    access() {
      if (!this.$auth.check() || !this.biz) return null;
      if (this.corpaccess?.user) return this.corpaccess;

      const userId = this.$auth.user()._id;
      const bizId = this.biz._id;
      return this.useraccess.find((o) => o.user == userId && o.biz == bizId);
    },
    chain() {
      return this.chainAccounts?.find((o) => o.bizs.includes(this.biz._id));
    },
    items() {
      if (!this.access) return [];
      const privilege = this.access.privilege;
      let clone = JSON.parse(JSON.stringify(this.bizGroups));
      const bizItems = _.chain(clone)
        .each((group) => {
          group.items = _.filter(group.items, (o) => {
            if (privilege < 3) return o.priv >= privilege;
            if (privilege == 4) {
              return this.access.items.some((a) => a.name == o.name);
            }
            return false;
          });
        })
        .filter((group) => {
          if (privilege < 3) return group.priv >= privilege;
          return group.items?.length;
        })
        .value();
      if (this.chain) {
        return bizItems.concat(this.chainGroups);
      }
      return bizItems;
    },
  },
};
</script>
