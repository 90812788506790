<template lang="pug">
div
  div(v-for="(item, index) in locations", :key="index")
    Location(:location="item", :readers="readers", @created="load")
  Transactions
  ReaderDetail
</template>

<script>
import { mapGetters } from "vuex";
import Location from "./Location";
import ReaderDetail from "./ReaderDetail";
import Transactions from "./Transactions";

export default {
  components: { Location, ReaderDetail, Transactions },
  data() {
    return { locations: [], readers: [] };
  },
  computed: {
    ...mapGetters(["stripeAccount", "biz"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.biz) return;
      try {
        const params = { bizId: this.biz._id };
        this.locations = await this.$api.stripe.terminalLocation.list(params);
      } catch (e) {
        //
      }
      for (var i = 0; i < this.locations.length; i++) {
        const params = {
          location: this.locations[i].terminal_id,
        };
        try {
          const result = await this.$api.stripe.terminalReader.list(params);
          this.readers = this.readers.concat(result);
        } catch (e) {
          this.$toast.error(e.response?.data || e.message);
        }
      }
    },
  },
};
</script>