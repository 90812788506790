<template lang="pug">
table
  tbody
    tr
      td Week starts on
      th {{ startDay | day }}
    tr
      td Day starts/ends at
      th {{ (startHour * 60) | minutesToTime }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["pos"]),
    startDay() {
      return this.pos?.startDay;
    },
    startHour() {
      return this.pos?.startHour || 0;
    },
  },
};
</script>

<style lang="sass" scoped>
table
  font-size: 13px
  th
    text-align: left
    padding: 0 0.5rem
</style>