<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Product Mix Report
    v-spacer
    v-btn-toggle(v-model="tab", color="secondary", dense)
      v-btn.text-capitalize(
        small,
        v-for="(item, index) in tab_items",
        :key="index",
        :value="item"
      ) {{ item }}
  Item(:data="data", :dateRange="dateRange", v-if="tab == 'dish'")
  Course(:data="data", :dateRange="dateRange", v-if="tab == 'course'")
  Menu(:data="data", :dateRange="dateRange", v-if="tab == 'menu'")
  Staff(:data="data", :dateRange="dateRange", v-if="tab == 'staff'")
</template>

<script>
import { mapGetters } from "vuex";
import Item from "./Item";
import Staff from "./Staff";
import Course from "./Course";
import Menu from "./Menu";

export default {
  name: "SaleByCate",
  components: { Item, Staff, Course, Menu },
  props: {
    data: { type: Array, required: true },
    range: { type: Array, required: true }, // [YYYY-MM-DD, YYYY-MM-DD]
    // beginDate: { type: String, required: true },
    // endDate: { type: String, required: true },
  },
  data() {
    return {
      tab: "dish",
      tab_items: ["dish", "course", "menu", "staff"],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    dateRange() {
      return this.range.join(" - ");
    },
  },
};
</script>
