<template lang="pug">
v-card(outlined)
  v-toolbar(flat, dense)
    .subtitle-2 Membership Self Enrollment
    v-spacer
    v-tooltip(bottom)
      template(v-slot:activator="{ on }")
        v-btn(icon, @click="copyURL", v-on="on")
          v-icon(small) mdi-content-copy
      span Copy to Clipboard
  v-card-text
    a(:href="url", target="_blank") {{ url }}
    .mt-3
      span.mr-1 Add this link to your own website,
      a(href="https://www.google.com/business/") Google My Business
      span.mr-1 ,
      a.mr-1(href="https://mapsconnect.apple.com/") Apple Maps Connect
      span.mr-1 and
      a(href="https://www.bingplaces.com/") Bing Places
      span .
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz"]),
    url() {
      if (this.biz && this.biz.url)
        return "https://www.selflane.com/bizs/" + this.biz.url + "/member";
      else return "";
    },
  },
  methods: {
    copyURL() {
      if (!this.biz || !this.biz.url) return;
      const text = "https://www.selflane.com/bizs/" + this.biz.url + "/member";
      this.$copyText(text);
    },
  },
};
</script>
