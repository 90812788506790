<template lang="pug">
div
  .tile(
    v-for="item in items",
    :key="item.value",
    :class="{ tileactive: item.active }"
  ) {{ item.text }}
</template>

<script>
export default {
  props: {
    days: { type: Array, required: true },
  },
  computed: {
    items() {
      let data = [
        { text: "M", value: 1, active: false },
        { text: "T", value: 2, active: false },
        { text: "W", value: 3, active: false },
        { text: "T", value: 4, active: false },
        { text: "F", value: 5, active: false },
        { text: "S", value: 6, active: false },
        { text: "S", value: 0, active: false },
      ];
      return data.map((item) => {
        item.active = this.days.indexOf(item.value) != -1;
        return item;
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.tile
  text-align: center
  font-size: 12px
  font-weight: 500
  color: black
  height: 18px
  width: 18px
  background-color: #f0f2f5
  border-radius: 12px
  display: inline-block
  margin-right: 2px

  &active
    color: white
    background-color: #42a5f5
</style>