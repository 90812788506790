<template lang="pug">
PartnerPage(:prefix="prefix", title="Subscription & Billing", :doc="doc")
  Payment.mb-5
  YearSelector(@select="selectYear")
  List.my-5(:items="org_invoices")
  Purchases.my-5(:year="year")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Payment from "./Payment/index";
import List from "/libs/components/Billing/OrgInvoice/List";
import moment from "moment-timezone";
import Purchases from "./Purchases/index";

export default {
  components: { Payment, List, Purchases },
  data() {
    return {
      prefix: "Selflane Partner",
      year: null,
      doc: "https://docs.selflane.com/partner/admin/billing/",
    };
  },
  computed: {
    ...mapGetters(["salesOrganization", "org_invoices"]),
  },
  methods: {
    ...mapActions(["setOrgInvoices"]),
    selectYear(year) {
      this.year = year;
      this.load();
    },
    async load() {
      if (!this.salesOrganization || !this.year) return;
      const begin = moment().year(this.year).startOf("year").valueOf();
      const end = moment().year(this.year).endOf("year").valueOf();
      const params = {
        criteria: {
          "organization.id": this.salesOrganization._id,
          created: { $gte: begin, $lt: end },
        },
      };
      const result = await this.$api.salesforce.invoice.list(params);
      this.setOrgInvoices(result);
    },
  },
};
</script>