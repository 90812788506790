<template lang="pug">
v-btn(icon, @click.stop="handleRemove", :loading="loading")
  v-icon.red--text(small) mdi-delete
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    handleRemove() {
      confirm("Do you want to delete this menu?") && this.remove();
    },
    async remove() {
      if (!this.item || !this.item._id || !this.biz) return;
      this.loading = true;
      const action = { $pull: { menus: { _id: this.item._id } } };
      const data = await this.$api.biz.put(this.biz._id, action);
      this.$store.dispatch("setBiz", data);
      this.loading = false;
    },
  },
};
</script>
