<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";

export default {
  props: {
    items: { type: Array, default: () => [] },
    fileName: { type: String, default: "download" },
  },
  methods: {
    download() {
      if (!this.items?.length || !this.fileName) return;
      const csv = new CSV();
      for (const item of this.items) {
        csv.addRow(item);
      }
      csv.save(this.fileName);
    },
  },
};
</script>
