<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card(v-if="reader")
    v-card-title Reader Detail
    simple-table
      tbody
        tr
          th Device Type
          td {{ reader.device_type }}
        tr
          th Label
          td {{ reader.label }}
        tr
          th Serial Number
          td {{ reader.serial_number }}
        tr
          th Status
          td {{ reader.status }}
        tr
          th IP Address
          td {{ reader.ip_address }}
        tr
          th Software Version
          td {{ reader.device_sw_version }}
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      dialog: false,
      reader: null,
    };
  },
  mounted() {
    EventBus.$on("show-reader-detail", this.load);
  },
  destroyed() {
    EventBus.$off("show-reader-detail", this.load);
  },
  methods: {
    load(data) {
      if (!data) return;
      this.reader = data;
      this.dialog = true;
    },
  },
};
</script>