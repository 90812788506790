<template lang="pug">
div
  .d-flex.flex-row.my-4
    DateRangePicker(
      v-model="range",
      @select="load()",
      :selector="true",
      :loading="loading"
    )
  BizStartHour.my-3
  OpsReport(:items="statements")
  v-row(wrap, justify="center")
    v-col(cols="12", sm="8")
      LaborReport(:sales="net", max-width="100%", ref="laborReport")
    v-col(cols="12", sm="8", v-if="report")
      ProductMixReport(:data="report.sales", :range="range")
  v-col(align="center")
    Download.my-3(:range="range", :report="report")
    Notice.my-3
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import OpsReport from "./OpsReport";
import ProductMixReport from "./ProductMixReport/index";
import Download from "./Download";
import Notice from "./Notice";
import { EventBus } from "@/event-bus.js";

export default {
  components: { ProductMixReport, Download, Notice, OpsReport },
  data() {
    return {
      range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      report: null,
      loading: false,
      statements: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    net() {
      const charge = this.report?.charge;
      if (charge) return charge.dish + charge.fee + charge.gift;
      return 0;
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    showOrders(canceled) {
      const begin = moment(this.range[0]).startOf("day").valueOf();
      const end = moment(this.range[1]).endOf("day").valueOf();
      const select = "orderer needed created orderNumber status payment";
      let params;
      if (canceled) {
        params = {
          criteria: {
            "seller.id": this.biz._id,
            needed: { $gte: begin, $lte: end },
            status: -1,
          },
          select,
        };
      } else {
        params = {
          criteria: {
            "seller.id": this.biz._id,
            needed: { $gte: begin, $lte: end },
            status: { $gte: 0, $lt: 3 },
          },
          select,
        };
      }
      EventBus.$emit("show-orders-dialog", params);
    },
    async load() {
      if (!this.biz || !this.biz._id) return;
      this.loading = true;
      await this.loadOps();
      const params = {
        bizId: this.biz._id,
        beginDate: this.range[0],
        endDate: this.range[1],
      };
      this.$refs.laborReport?.load(params);
      this.report = await this.$api.order.custom("report", params);
      this.loading = false;
    },
    async loadOps() {
      let begin = moment(this.range[0]);
      let end = moment(this.range[1]);
      const dates = [];
      while (!begin.isAfter(end)) {
        dates.push(begin.format("YYYY-MM-DD"));
        begin = begin.add(1, "days");
      }
      const params = {
        criteria: { biz: this.biz._id, date: { $in: dates } },
      };
      try {
        const url = "sales_daily_report/list";
        this.statements = await this.$api.biz.custom(url, params);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
