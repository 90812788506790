<template lang="pug">
v-card(v-if="items && items.length", outlined)
  simple-table
    tbody
      tr
        th Count
        td.text-right {{ filtered.length }}
      tr
        th Total Amount
        td.text-right {{ (amount / 100) | currency }}
      tr
        th Total Fee
        td.text-right {{ (fee / 100) | currency }}
      tr
        th Total Net
        td.text-right {{ (net / 100) | currency }}
  v-divider
  simple-table
    thead
      tr
        th Order #
        th Amount
        th Fee
        th Net
        th.text-right Time
    tbody
      tr(
        v-for="(item, index) in filtered",
        :key="index",
        @click="showOrder(item.orderId)",
        role="button",
        :class="{ orange: item.dup }"
      )
        th {{ item.orderNumber }}
        td {{ (item.amount / 100) | currency }}
        td {{ (item.application_fee_amount / 100) | currency }}
        td {{ ((item.amount - item.application_fee_amount) / 100) | currency }}
        td.text-right {{ (item.created * 1000) | time }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  props: {
    items: { type: Array, default: () => [] },
    orders: { type: Array, default: () => [] },
  },
  computed: {
    processed() {
      return this.items.filter((o) => o.status >= 0);
    },
    filtered() {
      return _.chain(this.processed)
        .sortBy("created")
        .map((item) => {
          const order = this.orders.find((o) => o._id === item.orderId);
          let dup = false;
          if (order) {
            dup =
              this.processed.filter((o) => o.orderId === order._id).length > 1;
          }
          return { ...item, orderNumber: order?.orderNumber, dup };
        })
        .sortBy("orderNumber")
        .value();
    },
    amount() {
      return this.processed.reduce((acc, cur) => acc + cur.amount, 0);
    },
    fee() {
      return this.processed.reduce((a, b) => a + b.application_fee_amount, 0);
    },
    net() {
      return this.processed.reduce(
        (a, b) => a + b.amount - b.application_fee_amount,
        0
      );
    },
  },
  methods: {
    showOrder(id) {
      if (!id) return;
      EventBus.$emit("show-order-detail", id);
    },
  },
};
</script>