<template lang="pug">
v-card
  v-form(@submit.prevent="submit")
    v-toolbar(flat)
      .subtitle-2 Add Credit/Debit Card
      v-spacer
      v-btn(
        type="submit",
        :loading="loading",
        :disabled="!complete",
        color="secondary"
      ) Save
    v-divider.mb-5
    v-card-text
      StripeElements(
        :stripe-key="stripeKey",
        :instance-options="instanceOptions",
        :elements-options="elementsOptions",
        #default="{ elements }",
        ref="elms"
      )
        StripeElement(
          type="card",
          :elements="elements",
          :options="cardOptions",
          ref="card",
          @change="complete = $event.complete",
          @ready="handleReady"
        )
      .caption.mt-5
        span.mr-1 Secured by
        a(href="http://www.stripe.com", target="_blank") Stripe
        span .
</template>

<script>
import { StripeElement, StripeElements } from "vue-stripe-elements-plus";

export default {
  components: { StripeElements, StripeElement },
  props: {
    url: { type: String, required: true },
    params: { type: Object, required: true },
  },
  data() {
    return {
      complete: false,
      stripeKey: process.env.VUE_APP_STRIPE,
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      cardOptions: {
        // reactive
        // remember about Vue 2 reactivity limitations when dealing with options
        value: {
          postalCode: "",
        },
        classes: {
          base: "stripe-card",
          complete: "stripe-card.complete",
        },
        // https://stripe.com/docs/stripe.js#element-options
      },
      loading: false,
    };
  },
  methods: {
    handleReady() {
      const cardComponent = this.$refs.card;
      if (!cardComponent) return;
      // Get stripe element
      const cardElement = cardComponent.stripeElement;
      if (cardElement) cardElement.focus();
    },
    async submit() {
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      // ref in template
      if (this.loading) return; // prevent double entry
      this.loading = true;
      const groupComponent = this.$refs.elms;
      const cardComponent = this.$refs.card;
      // Get stripe element
      const cardElement = cardComponent.stripeElement;

      const data = await groupComponent.instance.createToken(cardElement);
      if (data?.token?.id) {
        await this.save(data.token);
        cardElement.clear();
      } else {
        this.$toast.error(data?.error?.message || "Failed to create token");
      }
      this.loading = false;
    },
    async save(token) {
      const params = { token: token.id, ...this.params };
      try {
        const { data } = await this.axios.post(this.url, params);
        this.$emit("saved", data);
        this.$toast.success("Card saved");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

<style>
.stripe-card {
  width: 100%;
  border: 1px solid grey;
  border-radius: 4px;
  background-color: white;
  padding: 10px 12px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.stripe-card.complete {
  border-color: green;
}
</style>