<template lang="pug">
v-btn(icon, color="secondary", @click="create", :loading="loading")
  v-icon(small) mdi-refresh
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  props: {
    month: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    async create() {
      this.loading = true;
      try {
        // get all dates in the month
        const dates = Array.from(
          { length: moment(this.month, "YYYY-MM").daysInMonth() },
          (_, i) =>
            moment(this.month, "YYYY-MM")
              .date(i + 1)
              .format("YYYY-MM-DD")
        );
        let params = { bizId: this.biz?._id, dates: dates };
        await this.$api.rs.bookedDate.custom("recreate", params);
        this.$emit("refresh");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>