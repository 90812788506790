<template lang="pug">
v-dialog(v-model="dialog", max-width="800px", scrollable)
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on", title="Daily Data", icon="mdi-table")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Daily Table
      v-spacer
      IconBtn(@click="download()", title="CSV", icon="mdi-download")
    simple-table
      thead
        tr
          th(v-for="(item, index) in header", :key="index") {{ item }}
      tbody
        tr(v-for="(day, index) in days", :key="index + 'd'")
          td.nowrap(v-for="(item, index) in day", :key="index + 'i'") {{ item }}
</template>

<script>
import _ from "underscore";
import CSV from "/libs/utils/CSV.js";

export default {
  props: ["items"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    sum() {
      if (!this.items?.length) return;
      const sales = {
        net: this.items.reduce((a, b) => a + b.sales.net, 0),
        tax: this.items.reduce((a, b) => a + b.sales.tax, 0),
        gift: this.items.reduce((a, b) => a + b.sales.gift, 0),
        fee: this.items.reduce((a, b) => a + b.sales.fee, 0),
        tip: this.items.reduce((a, b) => a + b.sales.tip, 0),
        third_party: this.items.reduce((a, b) => a + b.sales.third_party, 0),
        deduction: this.items.reduce((a, b) => a + b.sales.deduction, 0),
        adjustment: this.items.reduce((a, b) => a + b.sales.adjustment, 0),
        selflane: this.items.reduce((a, b) => a + b.sales.selflane, 0),
        total: this.items.reduce((a, b) => a + b.sales.total, 0),
      };
      const charge = {
        total: this.items.reduce((a, b) => a + b.charge.total, 0),
        items: _.chain(this.items)
          .pluck("charge")
          .pluck("items")
          .flatten()
          .groupBy("title")
          .map((list, title) => {
            const amount = list.reduce((a, b) => a + b.amount, 0);
            return { title, amount };
          })
          .sortBy("title")
          .value(),
      };
      return {
        count: this.items.reduce((a, b) => a + b.orders.total, 0),
        sales,
        charge,
      };
    },
    header() {
      if (!this.sum) return [];
      let items = ["Date"];
      if (this.sum.sales.net) items.push("Net Sales");
      if (this.sum.sales.tax) items.push("Tax");
      if (this.sum.sales.gift) items.push("Gift Sold");
      if (this.sum.sales.fee) items.push("Fee (in-store)");
      if (this.sum.sales.tip) items.push("Tip");
      if (this.sum.sales.third_party) items.push("Third Party");
      if (this.sum.sales.deduction) items.push("Deduction");
      if (this.sum.sales.adjustment) items.push("Cash Discount Adjustment");
      if (this.sum.sales.selflane) items.push("Selflane Charges");
      items.push("Total Receivables");
      items.push("");
      this.sum.charge.items.forEach((item) => {
        items.push(item.title);
      });
      items.push("Total Received");
      return items;
    },
    days() {
      if (!this.items?.length) return [];
      if (!this.sum) return [];
      const currency = this.$options.filters.currency;
      return this.items.map((item) => {
        let arr = [item.date];
        if (this.sum.sales.net) arr.push(item.sales.net);
        if (this.sum.sales.tax) arr.push(item.sales.tax);
        if (this.sum.sales.gift) arr.push(item.sales.gift);
        if (this.sum.sales.fee) arr.push(item.sales.fee);
        if (this.sum.sales.tip) arr.push(item.sales.tip);
        if (this.sum.sales.third_party) arr.push(item.sales.third_party);
        if (this.sum.sales.deduction) arr.push(item.sales.deduction);
        if (this.sum.sales.adjustment) arr.push(item.sales.adjustment);
        if (this.sum.sales.selflane) arr.push(item.sales.selflane);
        arr.push(item.sales.total);
        arr.forEach((item, index) => {
          if (index > 0) arr[index] = currency(item);
        });
        arr.push("");
        this.sum.charge.items.forEach((o) => {
          const found = item.charge.items.find((i) => i.title === o.title);
          arr.push(found ? currency(found.amount) : "");
        });
        arr.push(currency(item.charge.total));
        return arr;
      });
    },
  },
  methods: {
    download() {
      const csv = new CSV();
      csv.addRow(this.header);
      this.days.forEach((day) => {
        csv.addRow(day);
      });
      csv.save("daily-report");
    },
  },
};
</script>