<template lang="pug">
tr
  td
    v-icon(:color="color", small) {{ icon }}
  td
    slot
</template>

<script>
export default {
  props: {
    color: { type: String, default: "success" },
    icon: { type: String, required: true },
  },
};
</script>