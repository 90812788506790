<template lang="pug">
v-card-text(v-if="app")
  .s-vstack
    .subtitle-2 Location: {{ store_id }} - {{ app.store_name }}
    .subtitle-2 Connection is {{ status }}
    div
      Webhook
    div
      Disconnect
</template>

<script>
import { mapGetters } from "vuex";
import Webhook from "../Webhook";
import Disconnect from "./Disconnect";

export default {
  components: { Webhook, Disconnect },
  computed: {
    ...mapGetters(["bizservice"]),
    app() {
      return this.bizservice?.apps?.find((a) => a.name == "7shifts");
    },
    store_id() {
      return this.app?.store_id;
    },
    status() {
      return this.app?.status;
    },
  },
};
</script>