<template lang="pug">
div
  .d-flex.flex-row.justify-end.mb-3
    DaySelector(@select="load")
  v-progress-circular.my-0(indeterminate, v-if="loading")
  ActionView(:items="docs", :orders="orders", v-if="isUpgraded")
  div(v-if="result")
    VoidOrders.my-8(:data="result.canceled", v-if="!isUpgraded")
    VoidItems.my-8(:data="result.voids", v-if="!isUpgraded")
    DiscountedOrders.my-8(:data="result.discounted")
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import VoidOrders from "./VoidOrders/index";
import VoidItems from "./VoidItems/index";
import DiscountedOrders from "./DiscountedOrders/index";
import ActionView from "./ActionView/index";
import { EventBus } from "@/event-bus.js";

export default {
  components: { VoidOrders, VoidItems, DiscountedOrders, ActionView },
  data() {
    return {
      loading: false,
      date: null,
      docs: [],
      orders: [],
      result: null,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    // check if date is greater than 3/15/2024
    isUpgraded() {
      return moment(this.date).isAfter("2024-03-15");
    },
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load(date) {
      this.result = null;
      if (date) this.date = date;

      if (!this.biz?._id || !this.date) return;
      this.loading = true;
      const params = {
        bizId: this.biz._id,
        date: moment(this.date).format("YYYY-MM-DD"),
      };
      try {
        const res = await this.axios.post("/orders/bizdailyaction", params);
        this.result = res.data;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      await this.loadActions(this.date);
      this.loading = false;
    },
    async loadActions(date) {
      const begin = moment(date).startOf("day").valueOf();
      const end = moment(date).endOf("day").valueOf();
      this.loading = true;
      const criteria = {
        biz: this.biz._id,
        created: { $gte: begin, $lte: end },
      };
      try {
        this.docs = await this.$api.orderAction.action.list({ criteria });
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      await this.loadOrders();
      this.loading = false;
    },
    async loadOrders() {
      let ids = this.docs.map((doc) => doc.order);
      ids = Array.from(new Set(ids));
      if (!ids.length) return;
      const criteria = { _id: { $in: ids } };
      const select = "orderNumber created";
      try {
        this.orders = await this.$api.order.list({ criteria, select });
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
