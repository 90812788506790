// Show Online Orders in Selected Month
<template lang="pug">
v-dialog(v-model="dialog", width="700")
  template(v-slot:activator="{ on }")
    v-btn.text-capitalize(
      @click="load()",
      color="secondary",
      outlined,
      small,
      v-on="on"
    ) Online Orders
  v-card
    v-card-title
    OrderTable(:loading="loading") 
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import OrderTable from "@/components/Order/Table/index";

export default {
  name: "OnlineOrders",
  components: { OrderTable },
  props: ["range"],
  data() {
    return {
      loading: false,
      dialog: false,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    ...mapActions(["setOrders"]),
    async load() {
      if (!this.range || !this.biz) return;
      const params = {
        criteria: {
          "seller.id": this.biz._id,
          needed: { $gte: this.range.begin, $lt: this.range.end },
          status: { $gte: 0 },
          "orderer.type": 0,
        },
        select: "orderer needed created orderNumber status payment",
      };
      this.loading = true;
      const { data } = await this.axios.post("/orders/list", params);
      this.setOrders(data);
      this.loading = false;
    },
  },
};
</script>
