<template lang="pug">
PartnerPage(title="Editing Histories", :doc="doc")
  MonthSelector(@select="load")
  List.my-3(:items="logs")
</template>

<script>
import List from "/libs/components/CRM/Log/List";
import { mapGetters } from "vuex";

export default {
  components: { List },
  data() {
    return {
      doc: "https://docs.selflane.com/partner/crm/histories/",
    };
  },
  computed: {
    ...mapGetters(["salesOrganization"]),
    ...mapGetters("crm", ["logs"]),
  },
  methods: {
    async load({ range }) {
      const { begin, end } = range;
      const organization = this.salesOrganization._id;
      if (!begin || !end || !organization) {
        this.$store.dispatch("crm/setLogs", []);
        return;
      }
      const criteria = {
        organization,
        createdAt: { $gte: new Date(begin), $lte: new Date(end) },
      };
      const result = await this.$api.crm.log.list({ criteria });
      this.$store.dispatch("crm/setLogs", result);
    },
  },
};
</script>