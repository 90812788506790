<template lang="pug">
.d-flex.flex-row.justify-center(v-if="posEditable")
  v-card(max-width="500", width="100%", flat)
    v-toolbar(flat, dense)
      .subtitle-2 Quick Service Mode Settings
    v-divider
    v-form(@submit.prevent="submit")
      v-list.pa-0(dense)
        v-list-item
          v-list-item-content
            v-list-item-title Require Tag Number
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.qsr.tag")
        v-list-item
          v-list-item-content
            v-list-item-title Custom Tag Name
          v-list-item-action
            v-text-field(
              v-model="posEditable.qsr.tagName",
              style="width: 100px",
              dense,
              hide-details
            )
        v-list-item
          v-list-item-content
            v-list-item-title Add Tip Line
          v-list-item-action
            v-switch(color="secondary", v-model="posEditable.qsr.allowTip")
      v-card-actions(v-if="dirty")
        v-row(justify="space-between")
          v-col(cols="8")
            v-btn(block, color="secondary", type="submit", :loading="loading") Save
          v-col(cols="3")
            v-btn(text, block, @click="cancel()") Cancel
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["pos", "posEditable"]),
    dirty() {
      return JSON.stringify(this.posEditable) !== JSON.stringify(this.pos);
    },
  },
  methods: {
    ...mapActions(["setPOS", "resetEditable"]),
    async submit() {
      this.loading = true;
      try {
        const action = {
          $set: { qsr: this.posEditable.qsr },
        };
        const result = await this.$api.pos.put(this.pos?._id, { action });
        this.setPOS(result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to save POS settings");
      }
      this.loading = false;
    },
    cancel() {
      this.resetEditable();
    },
  },
};
</script>
