<template lang="pug">
v-dialog(width="900")
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on", title="Payment List", icon="mdi-cash-register")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Payment List
      v-spacer
      Download(:charges="charges", :sum="sum")
    simple-table
      thead
        tr
          th Order
          th Server
          th Method
          td Last4
          th Auth
          th Base
          th Tip
          th Total
          th.text-right Time
          th.text-right Station
      tbody
        tr(
          v-for="(item, index) in charges",
          :key="index",
          :class="getColor(item)"
        )
          td {{ item.orderNumber }}
          td {{ item.server }}
          td {{ item.method }}
          td {{ item.last4 }}
          td {{ item.approve }}
          td {{ item.base | currency }}
          td {{ item.tip | currency }}
          td {{ item.amount | currency }}
          td.text-right {{ item.time | time }}
          td.text-right {{ item.sta }}
      tfoot
        tr
          td Sum
          td
          td
          td
          td
          td {{ sum.base | currency }}
          td {{ sum.tip | currency }}
          td {{ sum.amount | currency }}
          td
          td
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Download from "./Download";

export default {
  components: { Download },
  computed: {
    ...mapGetters(["orders"]),
    sum() {
      if (!this.charges) return { base: 0, tip: 0, amount: 0 };
      const sum_base = this.charges.reduce((a, b) => a + b.base, 0);
      const sum_tip = this.charges.reduce((a, b) => a + b.tip, 0);
      const sum_amount = this.charges.reduce((a, b) => a + b.amount, 0);
      return { base: sum_base, tip: sum_tip, amount: sum_amount };
    },
    charges() {
      return _.chain(this.orders)
        .sortBy("orderNumber")
        .reject((o) => o.status < 0)
        .map((o) => {
          let charges = [];
          if (o.payment.charge?.id) {
            const amount = o.payment.charge?.amount || 0;
            const tip = o.payment.tip || 0;
            const base = amount - tip;
            charges = [{ method: "Selflane", base, tip, amount }];
          }
          charges.push(...o.payment.charges);
          for (const check of o.splitChecks) {
            charges.push(...check.payment.charges);
          }
          for (const charge of o.payment.charges) {
            charge.orderNumber = o.orderNumber;
            charge.server = o.orderer.name;
            if (!charge.time) charge.time = o.created;
          }
          return charges;
        })
        .flatten()
        .value();
    },
  },
  methods: {
    getColor(item) {
      if (item.method == "card") return "blue-text";
      if (item.method == "cash") return "green-text";
    },
  },
};
</script>

<style lang="sass" scoped>
tr.green-text
  color: #4caf50
tr.blue-text
  color: #2196f3
</style>
