<template lang="pug">
v-dialog(v-model="dialog", max-width="500px")
  v-card(v-if="booked_table")
    v-card-title Cancel Reservation
    v-form(@submit.prevent="submit()")
      v-card-text
        h3 {{ booked_table.customer.name }}
        p Please enter the reason for cancellation. This action cannot be undone and please make sure that cancel and refund are the right action.
        v-text-field(label="Reason", v-model="reason", required)
        v-checkbox(
          color="secondary",
          v-model="confirm",
          label="I understand that this action cannot be undone."
        )
      v-card-actions
        v-btn(
          color="secondary",
          type="submit",
          :loading="loading",
          :disabled="!confirm"
        ) Cancel and Full Refund
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      booked_table: null,
      reason: "",
      confirm: false,
      loading: false,
    };
  },
  methods: {
    open(booked_table) {
      this.booked_table = booked_table;
      this.reason = "";
      this.confirm = false;
      this.dialog = true;
    },
    async submit() {
      const params = {
        bookedId: this.booked_table._id,
        reason: this.reason,
      };
      this.loading = true;
      try {
        const result = await this.$api.rs.book.custom("cancel", params);
        this.$emit("update", result);
        this.$toast.success("Cancellation successful");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    validate() {
      this.reason = this.reason.trim();
      if (!this.reason) return "Reason is required";
    },
  },
};
</script>